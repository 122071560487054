import React, { Component, Fragment } from 'react';
import structure_store from '../api/structure_store';
import { isHovering, isSelected, mouseOutElement, mouseOverElement, onClickElement, onRightClickElement } from './element_events';
import StructureHolder from './structure_holder';

export default class Structure extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelected: true,
            isHovering: false
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [structure_store.subscribe(this.handleStructureStoreChange.bind(this))];
        const { type } = this.props;

        if (type === 'panel') {
            /*$('#tools_structure_holder')
                .resizable({
                    minHeight: 400,
                    minWidth: 243,
                    alsoResize: '#tools_structure',
                    handles: 'se'
                });*/
        }
        /*$('.sortable_solo')
            .sortable({
                containment: '#tools_structure',
                connectWith: '.sortable_grid_sequence:not(:has(.sortable_element)),.sortable_solo',
                items: '.sortable_element',
                placeholder: 'structure_sortable_placeholder',
                handle: '.structure_element'
            });
        $('.sortable_grid_sequence')
            .sortable({
                containment: '#tools_structure',
                connectWith: '.sortable_grid_sequence:not(:has(.sortable_element)),.sortable_solo',
                items: '.sortable_element',
                placeholder: 'structure_sortable_placeholder_grid_sequence',
                handle: '.structure_element'
            });*/
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleStructureStoreChange() {
        const state = structure_store.getState();

        this.setState({
            isSelected: isSelected('root', 0, state),
            isHovering: isHovering('root', 0, state),
        });
    }

    renderStructure() {
        const { Content } = this.props;
        const { isSelected, isHovering } = this.state;

        return (
            <Fragment>
                <div className="structure_root_holder">
                    <div
                        id="structure_root_0"
                        className={
                            'structure_root structure_element_can_hold_sub' +
                            (isSelected ? ' structure_select_2' :
                                (isHovering ? ' structure_highlight_1' : '')
                            )
                        }
                        onMouseOver={mouseOverElement('root', 0)}
                        onMouseOut={mouseOutElement('root', 0)}
                        onClick={onClickElement('root', 0)}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            if (e.which === 3) {
                                onRightClickElement('root', 0, e.pageX, e.pageY);
                            }
                        }}
                    >
                        <div className="structure_icon_root"></div>
                        <p
                            id="structure_txt_root_0"
                            className="structure_txt_element"
                        >Body</p>
                    </div>
                </div>
                <div className="structure_root_distance"></div>
                {Content.filter(Item => Item.el_type === 'ground').map((Item, i) =>
                    <StructureHolder
                        key={i}
                        holder_el_type=""
                        el_type={Item.el_type}
                        el_idx={Item.el_idx}
                        Content=""
                    />
                )}
                {Content.filter(Item => Item.el_type !== 'ground').map((Item, i) =>
                    <StructureHolder
                        key={i}
                        holder_el_type=""
                        el_type={Item.el_type}
                        el_idx={Item.el_idx}
                        Content={Item.Content}
                    />
                )}
            </Fragment>
        );
    }

    render() {
        const { type } = this.props;

        return (
            <div id="tools_structure_holder">
                <div id="tools_structure" className={'tools_structure' + (type === 'bar' ? '_bar' : '') + ' sortable_solo'}>
                    {type === 'panel' ?
                        <div className="tools_structure_set">
                            {this.renderStructure()}
                        </div> :
                        this.renderStructure()
                    }
                </div>
            </div>
        );
    }
}