import deepmerge from 'deepmerge';
import layer_store from '../../api/layer_store';
import resolution_store from '../../api/resolution_store';
import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class ResolutionManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [resolution_store.subscribe(this.handleResolutionStoreChange(changeCurrentData, getCurrentData))];
    }

    handleResolutionStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = resolution_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Resolutions = clone(Current_Data.Resolutions);

            if (state.action === 'add') {
                Changed_Resolutions.push(state.New_Resolution);
            }
            if (state.action === 'update') {
                Changed_Resolutions = Changed_Resolutions.map(Resolution => {
                    if (parseInt(Resolution.id, 10) === parseInt(state.Updated_Resolution.id, 10)) {
                        return state.Updated_Resolution;
                    }
                    return Resolution;
                });
            }
            if (state.action === 'remove') {
                const index = findIndexArray(
                    Changed_Resolutions,
                    Resolution => parseInt(Resolution.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Resolutions.splice(index, 1);
            }

            changeCurrentData({ Resolutions: Changed_Resolutions });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Resolutions
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text' },
                    { text: 'Resolution scopes', style: 'subtitle' }
                ],
                [
                    { type: 'text' },
                    {
                        text:
                            'Resolution scopes can be used to hide/show elements based on the current <br>' +
                            'browser window size. Per element one can specify to show it either for any <br>' +
                            'resolution, or only for a specific resolution.<br>' +
                            'Hereby these resolution-scopes provide certain responsive <br>' +
                            'effects: when opening a page or when resizing the browser window, the <br>' +
                            'elements are automatically hiding/shown.<br>',
                        style: 'normal_grey'
                    }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'resolution_management', id: 'resolution_scope_id' },
                    { Resolutions: Current_Data.Resolutions }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'add_new_resolution',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [114, 16],
                        text: 'Add Resolution',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_resolution_panel',
                                            frm_name: 'resolution_settings',
                                            resolution_scope_id: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'create'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}