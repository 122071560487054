import deepmerge from 'deepmerge';
import form_data_store from '../../../api/form_data_store';

export default class Border {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return deepmerge(
            Load_Data,
            {
                Corner: Action_Data.data_action_type === 'create' || Load_Data.Mod === 0 ?
                    {
                        symmetric: 'true',
                        curvature: 'equal',
                        radius_all: 0,
                        radius_all_2: 0,
                        radius_lt: 0,
                        radius_lt_2: 0,
                        radius_rt: 0,
                        radius_rt_2: 0,
                        radius_lb: 0,
                        radius_lb_2: 0,
                        radius_rb: 0,
                        radius_rb_2: 0
                    } :
                    Load_Data.Mod
            }
        );
    }

    getQueryFragment(Action_Data) {
        return `Mod {
            symmetric
            curvature
            radius_all
            radius_all_2
            radius_lt
            radius_lt_2
            radius_rt
            radius_rt_2
            radius_lb
            radius_lb_2
            radius_rb
            radius_rb_2
        }`;
    }

    getQuery(Action_Data) {
        return null;
        /*return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
            ` + this.getQueryFragment(Action_Data) + `
            }`;*/
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        const max_corner = 9999;//TODO: depends on width/height of element... need to supply from "get data"
        const {
            symmetric, curvature,
            radius_all, radius_all_2, radius_lt, radius_rt, radius_lt_2, radius_rt_2,
            radius_lb, radius_rb, radius_lb_2, radius_rb_2
        } = Current_Data.Corner;

        return [
            [
                { type: 'text' },
                { text: 'Same or different radius for each corner?', style: 'bold' }
            ],
            [
                { type: 'image_picker', id: 'symmetric', colspan: 2 },
                {
                    value: symmetric,
                    Options: [
                        { label: 'Same', filename: 'projects/rootflex/img/form/corner_symmetry.png', value: 'true', left: 0 }, //Similar
                        { label: 'Different', filename: 'projects/rootflex/img/form/corner_symmetry.png', value: 'false', left: 41 }//Dissimilar
                    ],
                    width: 40,
                    height: 35,
                    onChange: value => value === 'false' ? {
                        Corner: {
                            radius_lt: radius_all,
                            radius_rt: radius_all,
                            radius_lb: radius_all,
                            radius_rb: radius_all,
                            radius_lt_2: radius_all_2,
                            radius_rt_2: radius_all_2,
                            radius_lb_2: radius_all_2,
                            radius_rb_2: radius_all_2,
                        }
                    } : {}
                }
            ],
            [
                { type: 'space', colspan: 2, height: 23 }
            ],
            [
                { type: 'text' },
                { text: 'Curve corners equal (circular) or unequal (elliptic)?', style: 'bold' }
            ],
            [
                { type: 'image_picker', id: 'curvature', colspan: 2 },
                {
                    value: curvature,
                    Options: [
                        { label: 'Equal', filename: 'projects/rootflex/img/form/curvature.png', value: 'equal', left: 0 },
                        { label: 'Unequal', filename: 'projects/rootflex/img/form/curvature.png', value: 'unequal', left: 36 }
                    ],
                    width: 36,
                    height: 40,
                    onChange: value => value === 'unequal' ? {
                        Corner: {
                            radius_all_2: radius_all,
                            radius_lt_2: radius_lt,
                            radius_rt_2: radius_rt,
                            radius_lb_2: radius_lb,
                            radius_rb_2: radius_rb,
                        }
                    } : {}
                }
            ],
            [
                { type: 'space', colspan: 2, height: 23 }
            ],
            [
                { type: 'text' },
                { text: 'Radius', style: 'bold' }
            ],
            symmetric === 'true' ? [
                { type: 'row' },
                {
                    Content: [
                        [
                            { type: 'number', id: 'radius_all' },
                            {
                                value: radius_all,
                                min_val: 0,
                                max_val: max_corner,
                                unit: 'px',
                                onChange: value => ({
                                    Corner: {
                                        radius_lt: value,
                                        radius_rt: value,
                                        radius_lb: value,
                                        radius_rb: value,
                                    }
                                })
                            }
                        ],
                        [
                            { type: curvature === 'unequal' ? 'number' : 'hidden', id: 'radius_all_2', label: '2nd' },
                            {
                                value: radius_all_2,
                                min_val: 0,
                                max_val: max_corner,
                                unit: 'px',
                                onChange: value => ({
                                    Corner: {
                                        radius_lt_2: value,
                                        radius_rt_2: value,
                                        radius_lb_2: value,
                                        radius_rb_2: value,
                                    }
                                })
                            }
                        ]
                    ]
                }
            ] : [
                    { type: 'grid' },
                    {
                        Content: [[
                            [
                                { type: 'number', id: 'radius_lt', label: 'Left top' },
                                { value: radius_lt, min_val: 0, max_val: max_corner, unit: 'px' }
                            ],
                            [
                                { type: curvature === 'unequal' ? 'number' : 'hidden', id: 'radius_rt', label: 'Right top' },
                                { value: radius_rt, min_val: 0, max_val: max_corner, unit: 'px' }
                            ]
                        ], [
                            [
                                { type: 'number', id: 'radius_lt_2', label: 'Left top 2nd' },
                                { value: radius_lt_2, min_val: 0, max_val: max_corner, unit: 'px' }
                            ],
                            [
                                { type: curvature === 'unequal' ? 'number' : 'hidden', id: 'radius_rt_2', label: 'Right top 2nd' },
                                { value: radius_rt_2, min_val: 0, max_val: max_corner, unit: 'px' }
                            ]
                        ], [
                            [
                                { type: 'number', id: 'radius_lb', label: 'Left bottom' },
                                { value: radius_lb, min_val: 0, max_val: max_corner, unit: 'px' }
                            ],
                            [
                                { type: curvature === 'unequal' ? 'number' : 'hidden', id: 'radius_rb', label: 'Right bottom' },
                                { value: radius_rb, min_val: 0, max_val: max_corner, unit: 'px' }
                            ]
                        ], [
                            [
                                { type: 'number', id: 'radius_lb_2', label: 'Left bottom 2nd' },
                                { value: radius_lb_2, min_val: 0, max_val: max_corner, unit: 'px' }
                            ],
                            [
                                { type: curvature === 'unequal' ? 'number' : 'hidden', id: 'radius_rb_2', label: 'Right bottom 2nd' },
                                { value: radius_rb_2, min_val: 0, max_val: max_corner, unit: 'px' }
                            ]
                        ]]
                    }
                ]
        ];
    }

    getSubmitEvent(frm_id, submit_action, Function_Data, Action_Data, frm_id_int, Current_Data, updateActionData) {
        return holder_type => {
            form_data_store.dispatch({
                type: 'SUBMIT',
                submitData: {
                    frm_id,
                    holder_type,
                    submit_action,
                    Function_Data,
                    process_tag: 'Saving...',
                    onSubmitted: Result_Data => {
                        //TODO
                    }
                }
            });
        };
    }
}