import React, { Component, Fragment } from 'react';
import {
    getElementActions, getElementActionsSubscriptions,
    checkPasteAllowed, checkMoveAllowed
} from './element_events.js';
import { Tools_Action_Simple, Tools_Action_Paneled } from './tools_action';
import structure_store from '../api/structure_store';

export default class Element_Tools extends Component {
    constructor(props) {
        super(props);

        this.Element_Actions_Subscriptions = getElementActionsSubscriptions();
        this.state = {
            structure_store_state: structure_store.getState()
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [structure_store.subscribe(this.handleStructureStoreChange.bind(this))];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleStructureStoreChange() {
        this.setState({
            structure_store_state: structure_store.getState()
        });
    }

    renderElementAction(Element_Action, Actions_Subscription) {
        const { Function_Data } = this.props;

        return (
            Array.isArray(Element_Action[3]) ?
                <Tools_Action_Paneled
                    key={Element_Action[0]}
                    given_style="plain_list"
                    Function_Data={Function_Data}
                    id={Element_Action[0]}
                    icon={Element_Action[1]}
                    color={Element_Action[2]}
                    Buttons={Element_Action[3].filter((Button, index) => Actions_Subscription[index])}
                /> :
                <Tools_Action_Simple
                    key={Element_Action[0]}
                    Function_Data={Function_Data}
                    id={Element_Action[0] + '_action_group'}
                    icon={Element_Action[1]}
                    color={Element_Action[2]}
                    action={Element_Action[3]}
                    label={Element_Action[4]}
                />
        );
    }

    isElementActionVisible(Actions_Subscription, Element_Action, index) {
        if (Actions_Subscription[index].length > 1) {
            return Actions_Subscription[index].filter(Action_Subscription => Action_Subscription).length > 0;
        } else if (Actions_Subscription[index] &&
            (Element_Action !== 'paste' || checkPasteAllowed()) &&
            ((Element_Action !== 'move_up' && Element_Action !== 'move_down') || checkMoveAllowed(Element_Action))
        ) {
            return true;
        }
        return false;
    }

    renderActions(Element_Actions, Actions_Subscription, offset) {
        return Element_Actions
            .map((Element_Action, index) => {
                return {
                    Element_Action: Element_Action,
                    Action_Subscription: Actions_Subscription[offset + index + 1]
                };
            })
            .filter((Element_Action, index) =>
                this.isElementActionVisible(Actions_Subscription, Element_Action.Element_Action, offset + index + 1)
            )
            .map(Element_Action =>
                this.renderElementAction(Element_Action.Element_Action, Element_Action.Action_Subscription)
            );
    }

    render() {
        const Element_Actions = getElementActions(false);
        const top_row_amount = Element_Actions[0].length;
        const Actions_Subscription = this.Element_Actions_Subscriptions
            .filter(Element_Actions_Subscription => Element_Actions_Subscription[0] === this.state.structure_store_state.selected_el_type)[0];

        if (!Actions_Subscription) {
            return (
                <div>
                    {'Can\'t find Actions Subscription'}
                </div>
            );
        }

        return (
            <Fragment>
                <div className="element_actions">
                    {this.renderActions(Element_Actions[0], Actions_Subscription, 0)}
                    {this.renderActions(Element_Actions[1], Actions_Subscription, top_row_amount)}
                </div>
                <div className="grey_line2"></div>
            </Fragment>
        );
    }
}