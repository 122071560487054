import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import scroll_zone_store from '../../api/scroll_zone_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class ScrollZoneSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Scroll_Zone: {
                    use_min_position: 'true',
                    min_position: 0,
                    use_max_position: 'true',
                    max_position: 0
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Scroll_Zone {
                    use_min_position
                    min_position
                    use_max_position
                    max_position
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Scroll_Zone', [
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'grid' },
                    {
                        Content: [[
                            [
                                { type: 'checkbox', id: 'use_min_position', label: 'Minimum' },
                                { value: Current_Data.Scroll_Zone.use_min_position, styling_type: 'switch' }
                            ],
                            [
                                { type: Current_Data.Scroll_Zone.use_min_position === 'true' ? 'number' : 'hidden', id: 'min_position' },
                                { value: Current_Data.Scroll_Zone.min_position, min_val: 0, max_val: 9999, unit: 'px' }
                            ]
                        ], [
                            [
                                { type: 'checkbox', id: 'use_max_position', label: 'Maximum' },
                                { value: Current_Data.Scroll_Zone.use_max_position, styling_type: 'switch' }
                            ],
                            [
                                { type: Current_Data.Scroll_Zone.use_max_position === 'true' ? 'number' : 'hidden', id: 'max_position' },
                                { value: Current_Data.Scroll_Zone.max_position, min_val: 0, max_val: 9999, unit: 'px' }
                            ]
                        ]]
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_scroll_zone' : 'save_scroll_zone',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Adding...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            if (Result_Data) {
                                const New_Scroll_Zone = deepmerge(
                                    Current_Data.Scroll_Zone,
                                    Result_Data
                                );
                                if (Action_Data.data_action_type === 'create') {
                                    scroll_zone_store.dispatch({
                                        type: 'ADD',
                                        New_Scroll_Zone: New_Scroll_Zone
                                    });
                                } else {
                                    scroll_zone_store.dispatch({
                                        type: 'UPDATE',
                                        Updated_Scroll_Zone: New_Scroll_Zone
                                    });
                                }
                            }
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Add' : 'Save')
        ];
    }
}