import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import form_data_store from '../api/form_data_store';
import { buildFormElementID } from '../utils/form';
import { clone } from '../utils/object';
import ColumnType from './column_type';

export default class PanelFormContent extends Component {
    constructor(props) {
        super(props);
        const { Function_Data, Form_Model, is_public_form, frm_id_int, Load_Data, Action_Data } = props;
        this.state = {//TODO: add hooks to redux store
            Current_Data: Form_Model.convertLoadDataToCurrentData ?
                Form_Model.convertLoadDataToCurrentData(Load_Data, Action_Data, Function_Data) :
                clone(Load_Data)
        };
        this.frm_id = (is_public_form ? 'public_' : '') + 'form_' + frm_id_int;
        form_data_store.dispatch({
            type: 'UPDATE',
            Current_Form_Data: this.state.Current_Data,
            frm_id: this.frm_id,
            Data_Groups: []
        });
        this.unsubscribes = [];
    }

    componentDidMount() {
        const { Form_Model, reSizeAndReposition } = this.props;
        this.unsubscribes = [
            form_data_store.subscribe(this.handleFormDataStoreChange().bind(this))
        ];

        if (Form_Model.subscribeToStores) {
            Form_Model.subscribeToStores(
                this.changeCurrentData.bind(this),
                this.getCurrentData.bind(this)
            ).forEach(subscription => this.unsubscribes.push(subscription));
        }

        reSizeAndReposition();
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    changeCurrentData(New_Data) {
        form_data_store.dispatch({
            type: 'UPDATE',
            Current_Form_Data: deepmerge(this.state.Current_Data, New_Data, { arrayMerge: (dest, src) => src }),
            frm_id: this.frm_id,
            Data_Groups: []
        });
    }

    getCurrentData() {
        return this.state.Current_Data;
    }

    handleFormDataStoreChange() {
        return () => {
            const state = form_data_store.getState();
            if (state.action === 'update' && Object.keys(state.Current_Form_Data[this.frm_id]).length) {
                this.setState({
                    Current_Data: clone(state.Current_Form_Data[this.frm_id])
                });
            }
        };
    }

    getContentFields() {
        const {
            Function_Data, Action_Data, tab, reloadForm, updateActionData,
            Form_Model, frm_id_int, navigate, holder_type,
            holderSize, form_h, reSizeAndReposition
        } = this.props;

        return Form_Model
            .getContent(
                Function_Data,
                Action_Data,
                this.frm_id,
                frm_id_int,
                this.state.Current_Data,
                updateActionData,
                this.changeCurrentData.bind(this),
                navigate,
                holder_type
            )
            .filter(Form_Item => Form_Item[0].type !== 'hidden')
            .map((Form_Item, index) => (
                <tr
                    key={index}
                    id={Form_Item[0].id ? 'row_' + buildFormElementID(this.frm_id, [], Form_Item[0].id) : null}
                >
                    <ColumnType
                        Function_Data={Function_Data}
                        Action_Data={Action_Data}
                        frm_id={this.frm_id}
                        Data_Groups={[]}
                        tab={(tab + index)}
                        Data={Form_Item}
                        reloadForm={reloadForm}
                        updateActionData={updateActionData}
                        changeCurrentData={this.changeCurrentData.bind(this)}
                        holderSize={holderSize}
                        form_h={form_h}
                        reSizeAndReposition={reSizeAndReposition}
                    />
                </tr>
            ));
    }

    render() {
        const { holder_type } = this.props;
        const Content = this.getContentFields();

        return (
            <form
                id={this.frm_id}
                style={{
                    height: '100%',
                    display: holder_type === 'overlay' ? 'table-row' : undefined
                }}
            >
                <table
                    className="full_width"
                    style={{ height: '100%' }}
                >
                    <tbody>
                        {Content.length ? Content : (
                            <tr>
                                <td />
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        );
    }
}
