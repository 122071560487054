import $ from 'jquery';
import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';

export default class MenuStructure extends Component {
    componentDidMount() {
        const { frm_id, Data_Groups, El_Data } = this.props;
        const { Pages, menu_structure_string_fld_id } = El_Data;
        const sortable_ids = Pages.map((Page, index) => '#sortable' + (index + 1)).join(',');

        $(sortable_ids).sortable({
            connectWith: '.connectedSortable',
            placeholder: 'placeholder_item',
            stop: () => {
                var Menu_Structure = [];
                for (var i = 0; i < Pages.length + 1; i++) {
                    var result = $('#sortable' + (i + 1)).sortable('toArray');
                    for (var j = 0; j < result.length; j++) {
                        Menu_Structure.push({
                            page_id: parseInt(result[j].replace('page_id_', ''), 10),
                            menu_parent_page_id: parseInt((j === 0 ? 0 : result[0].replace('page_id_', '')), 10),
                            sequence: (j === 0 ? i : j)
                        });
                    }
                }

                $('#' + buildFormElementID(frm_id, Data_Groups, menu_structure_string_fld_id))
                    .val(JSON.stringify(Menu_Structure).replace(/"/g, '\''));
            }
        }).disableSelection();
    }

    renderMenuItem(page_id, type, title) {
        return (
            <li
                id={'page_id_' + page_id}
                className="ui-state-default"
                style={{ margin: '4px', padding: 0, border: 0, height: '26px' }}
            >
                <div
                    className={'list_item ' + (type === 'menu_group' ? 'dark_grey' : 'light_grey')}
                    style={{ width: '180px', height: '26px' }}
                >
                    <p className={'drag_drop_text_style ' + (type === 'menu_group' ? 'menu_group' : 'menu_item')}>
                        {title}
                    </p>
                </div>
            </li>
        );
    }

    render() {
        const { Pages } = this.props.El_Data;

        //TODO: make feature: small buttons to edit/remove page by click
        return (
            <div
                className="drag_drop_area"
                style={{ width: ((Pages.length + 1) * 204) + 'px' }}
            >
                {Pages.map((Page, index) =>
                    <ul
                        key={index}
                        id={'sortable' + (index + 1)}
                        className="connectedSortable drag_drop_list"
                    >
                        {this.renderMenuItem(Page.id, 'menu_group', Page.title)}
                        {Page.Sub_Pages.map(Sub_Page =>
                            this.renderMenuItem(Sub_Page.id, 'menu_item', Sub_Page.title)
                        )}
                    </ul>
                )}
                <ul
                    id={'sortable' + (Pages.length + 1)}
                    className="connectedSortable drag_drop_list"
                ></ul>
            </div>
        );
    }
}