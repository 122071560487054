import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import layer_store from '../api/layer_store';
import layout_store from '../api/layout_store';
import lightbox_store from '../api/lightbox_store';
import { buildEvents } from '../utils/events';
import { buildCustomImagePath, buildDesktopSizeImagePath, buildOriginImagePath } from '../utils/path';

export default class Image extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();

        this.state = {
            imgWidth: undefined
        };
        this.unsubscribes = [];

        this.lightboxImage;
    }

    componentDidMount() {
        const { width_type, height_type } = this.props.Element.Layout;

        if (width_type === 'fit' && height_type === '100') {
            this.unsubscribes = [
                layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
            ];
        }

        this.setState({
            imgWidth: this.getImageWidth()
        });
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());

        if (this.lightboxImage) {
            lightbox_store.dispatch({
                type: 'REMOVE',
                Images: [this.lightboxImage]
            });
        }
    }

    handleLayoutStoreChange() {
        const { action } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.setState({ imgWidth: this.getImageWidth() });
        }
    }

    getImageWidth() {
        const { el_idx, Element } = this.props;
        const { Layout, El_Data } = Element;
        const { width_type, height_type } = Layout;
        const { width, height } = El_Data.Image_Origin_Data;
        //TODO: use this.imgRef instead of getElementById

        return width_type === 'fit' && height_type === '100' ?
            (document.getElementById('image_' + el_idx).offsetHeight / height) * width :
            undefined;
    }

    renderImage(Events) {
        const { project, site_id, el_type, el_idx, Element, use_service, signed_in, Corrections } = this.props;
        const { Layout, El_Data } = Element;
        const isCustomPixelSized = Layout.width_type === 'cus' && Layout.hor_amount_type === 'px' &&
            Layout.height_type === 'cus' && Layout.ver_amount_type === 'px';
        const isCustomPercentageSized = (Layout.width_type === 'cus' && Layout.hor_amount_type === 'per') ||
            (Layout.height_type === 'cus' && Layout.ver_amount_type === 'per');

        const style = {
            minHeight: Corrections && Corrections.full_height_correction ?
                Corrections.full_height_correction.height :
                undefined,
            width: this.state.imgWidth ? this.state.imgWidth + 'px' : undefined
        };

        return isCustomPixelSized || isCustomPercentageSized ? (
            <img
                ref={this.imgRef}
                id={[el_type, el_idx].join('_')}
                src={isCustomPixelSized ?
                    buildCustomImagePath(project, site_id, use_service) + el_idx + '.' + El_Data.file_type :
                    buildOriginImagePath(project, site_id, use_service) + El_Data.resource_id + '.' + El_Data.file_type
                }
                alt={typeof (El_Data.description) !== 'undefined' && El_Data.description !== '' ?
                    El_Data.description :
                    null
                }
                style={style}
                onClick={!Events.href && Events.onClick && !signed_in ?
                    e => { e.preventDefault(); Events.onClick(); } :
                    null
                }
            />
        ) : (//TODO: check support types: 'fit' & '100'
                <div
                    ref={this.imgRef}
                    id={[el_type, el_idx].join('_')}
                    style={deepmerge(style, {
                        backgroundImage: 'url(' +
                            buildOriginImagePath(project, site_id, use_service) +
                            El_Data.resource_id + '.' +
                            El_Data.file_type +
                            ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%'
                    })}
                    title={Events.tooltip && !signed_in ? Events.tooltip : null}
                    onClick={!Events.href && Events.onClick && !signed_in ? Events.onClick : null}
                />
            );
    }

    render() {
        const { project, site_id, Navigation_Data, Element, use_service, signed_in, navigate } = this.props;
        const { El_Data, Behavior } = Element;
        const { resource_id, file_type } = El_Data;

        let Events;
        let href;
        let lightbox;
        if (Behavior.on_click === 'enlarge' && resource_id && file_type) {
            lightbox = true;
            href = buildDesktopSizeImagePath(project, site_id, use_service) + resource_id + '.' + file_type;
            if (!this.lightboxImage) {
                this.lightboxImage = {
                    id: resource_id,
                    href,
                    title: Behavior.tooltip
                };
            }
            lightbox_store.dispatch({
                type: 'ADD',
                Images: [this.lightboxImage]
            });
            Events = {
                href: href,
                onClick: () => {
                    layer_store.dispatch({
                        type: 'SHOW_LIGHTBOX',
                        active_image: resource_id
                    });
                }
            };
        } else {
            Events = buildEvents(Behavior, Navigation_Data, navigate);
        }

        return Events.href ?
            <a
                href={Events.href}
                rel={lightbox ? 'lightbox' : null}
                target={Events.new_tab ? '_blank' : null}
                title={Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={signed_in ? e => e.preventDefault() : (
                    Events.onClick ? e => { e.preventDefault(); Events.onClick(); } : null
                )}
            >{this.renderImage(Events)}</a> :
            this.renderImage(Events);
    }
}