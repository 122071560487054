import React, { Component } from 'react';
import { Tools_Action_Paneled } from './tools_action';

export default class Site_Tools extends Component {
    handleChange(event) {
        const { Function_Data, navigate } = this.props;
        navigate(
            Function_Data.default_language_type,
            Function_Data.language_type,
            Function_Data.device_type,
            Function_Data.base_url,
            event.target.value,
            [],
        );
    }

    render() {
        const { Function_Data, Sites, tab } = this.props;

        const Buttons = [
            ['edit_site', 'Settings', 'site_options'],
            ['manage_images', 'Images', 'images'],
            ['manage_videos', 'Videos', 'videos'],
            ['manage_files', 'Files', 'files'],
            //['manage_colors', 'Colors', 'colors'],
            ['manage_gradients', 'Gradients', 'gradients'],
            ['manage_fonts', 'Font-Families', 'fonts'],
            ['manage_text_style_presets', 'Text Styles', 'text_style']//,
            //['manage_texts', 'Manage Texts', 'texts'],
            //['manage_links', 'Manage Links', 'links'],
            //['manage_elements', 'Manage Elements', 'elements'],
            //['manage_presets', 'Presets', 'locks']
        ];

        return (
            <div className="tools_dropdown_container_holder">
                <div className="main_actions">
                    <Tools_Action_Paneled
                        given_style="button_list"
                        Function_Data={Function_Data}
                        id="site_action_group"
                        icon="cog"
                        color="black"
                        Buttons={Buttons}
                    />
                </div>
                <div className="tools_dropdown_container">
                    <div className="styled_select_holder black tools_dropdown_selector_holder">
                        <div>
                            <select
                                id="open_site_select"
                                name="open_site_select"
                                tabIndex={tab}
                                className="tools_dropdown_selector"
                                value={Function_Data.site_url}
                                onChange={(e) => this.handleChange(e)}
                            >
                                {Sites.map(Site => {
                                    return (
                                        <option
                                            key={Site.site_url}
                                            className="tools_dropdown_selector_option"
                                            value={Site.site_url}
                                        >
                                            {Site.title}
                                        </option>
                                    );
                                })}
                            </select>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}