import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class VideoSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ? {
            Video:
            {
                autoplay: 'false',
                loop: 'false',
                controls: 'false',
                sound: 'false',
                preload: 'false',
            }
        } : Load_Data;
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Video: {
                autoplay: String
                loop: String
                controls: String
                sound: String
                preload: String
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Video', [
                [
                    { type: 'checkbox', id: 'autoplay', label: 'Auto start' },
                    {
                        value: Current_Data.Video.autoplay,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: '"Auto start" is ignored while signed in.' },
                ],
                [
                    { type: 'checkbox', id: 'loop', label: 'Auto replay' },
                    {
                        value: Current_Data.Video.loop,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'checkbox', id: 'controls', label: 'Show controls' },
                    {
                        value: Current_Data.Video.controls,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'checkbox', id: 'sound', label: 'Sound' },
                    {
                        value: Current_Data.Video.sound,
                        styling_type: 'switch',
                    },
                ],
                [
                    {
                        type: Current_Data.Video.autoplay === 'false' ? 'checkbox' : 'hidden',
                        id: 'preload',
                        label: 'Load before start',
                    },
                    {
                        value: Current_Data.Video.preload,
                        styling_type: 'switch',
                    },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    updateActionData({
                        access: 'authorized',
                        frm_name: 'layout_settings',
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_video_settings',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save')
        ];
    }
}
