import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColButtonSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            deepmerge(Load_Data, {
                Form_Col_Button:
                {
                    click_script_id: '',
                    submit_script_id: '',
                    text_size: 'normal',
                    name: '',
                    colspan: 1,
                    h_align: 'center',
                    v_align: 'middle',
                    padding_left: 0,
                    padding_right: 0,
                    label: '',
                    tag: '',
                    color: 'green',
                    responsive: 'false',
                    width_type: 'cus',
                    width: 150,
                    height: 30,
                    margin_top: 0,
                    margin_left: 0,
                    button_type: 'submit',
                    button_size: 'normal',
                    submit_action_id: 0
                },
            }) : clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create'
            ? `query DataQuery($urls: String!) {
                Submit_Actions
            }`
            : `query DataQuery($urls: String!) {
                Submit_Actions
                Form_Col_Button {
                    click_script_id: String
                    submit_script_id: String
                    text_size: String
                    name: String
                    colspan
                    h_align: String
                    v_align: String
                    padding_left
                    padding_right
                    label: String
                    tag: String
                    color: String
                    responsive: String
                    width_type: String
                    width
                    height
                    margin_top
                    margin_left
                    button_type
                    button_size
                    submit_action_id
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Button', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    {
                        value: Current_Data.Form_Col_Button.name,
                        max: 2,
                        required: true,
                    },
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    {
                        value: Current_Data.Form_Col_Button.colspan,
                        min_val: 1,
                        max_val: 2,
                    },
                ],
                [
                    { type: 'dropdown', id: 'h_align', label: 'Horizontal align' },
                    {
                        value: Current_Data.Form_Col_Button.h_align,
                        Options: [
                            ['left', 'Left'],
                            ['center', 'Center'],
                            ['right', 'Right'],
                        ],
                    },
                ],
                [
                    { type: 'dropdown', id: 'v_align', label: 'Vertical align' },
                    {
                        value: Current_Data.Form_Col_Button.v_align,
                        Options: [
                            ['top', 'Top'],
                            ['middle', 'Center'],
                            ['bottom', 'Bottom'],
                        ],
                    },
                ],
                [
                    { type: 'number', id: 'padding_left', label: 'Padding left' },
                    {
                        value: Current_Data.Form_Col_Button.padding_left,
                        min_val: 0,
                        max_val: 100,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'number', id: 'padding_right', label: 'Padding right' },
                    {
                        value: Current_Data.Form_Col_Button.padding_right,
                        min_val: 0,
                        max_val: 100,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Button.label, max: 2 },
                ],
                [
                    { type: 'field', id: 'tag', label: 'Tag' },
                    {
                        value: Current_Data.Form_Col_Button.tag,
                        max: 2,
                        required: true,
                    },
                ],
                [
                    { type: 'dropdown', id: 'color', label: 'Color' },
                    {
                        value: Current_Data.Form_Col_Button.color,
                        Options: [
                            ['green', 'Green'],
                            ['red', 'Red'],
                            ['blue', 'Blue'],
                            ['light_blue', 'Light Blue'],
                            ['yellow', 'Yellow'],
                            ['black', 'Black'],
                            ['grey', 'Grey'],
                        ],
                    },
                ],
                [
                    { type: 'checkbox', id: 'responsive', label: 'Responsive' },
                    {
                        value: Current_Data.Form_Col_Button.responsive,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'dropdown', id: 'width_type', label: 'Width' },
                    {
                        value: Current_Data.Form_Col_Button.width_type,
                        Options: [['cus', 'Custom'], ['100', 'Full']],
                    },
                ],
                [
                    {
                        type:
                            Current_Data.Form_Col_Button.width_type === 'cus'
                                ? 'number'
                                : 'hidden',
                        id: 'width',
                        label: 'Width',
                    },
                    {
                        value: Current_Data.Form_Col_Button.width,
                        min_val: 1,
                        max_val: 999,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'number', id: 'height', label: 'Height' },
                    {
                        value: Current_Data.Form_Col_Button.height,
                        min_val: 1,
                        max_val: 999,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'number', id: 'margin_top', label: 'Margin top' },
                    {
                        value: Current_Data.Form_Col_Button.margin_top,
                        min_val: 0,
                        max_val: 100,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'number', id: 'margin_left', label: 'Margin left' },
                    {
                        value: Current_Data.Form_Col_Button.margin_left,
                        min_val: 0,
                        max_val: 100,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'dropdown', id: 'button_type', label: 'Button type' },
                    {
                        value: Current_Data.Form_Col_Button.button_type,
                        Options: [['button', 'Normal'], ['submit', 'Submit']],
                    },
                ],
                [
                    { type: 'dropdown', id: 'button_size', label: 'Button size' },
                    {
                        value: Current_Data.Form_Col_Button.button_size,
                        Options: [['normal', 'Normal'], ['big', 'Big']],
                    },
                ],
                [
                    {
                        type: Current_Data.Form_Col_Button.button_type === 'submit' ? 'dropdown' : 'hidden',
                        id: 'submit_action_id',
                        label: 'Submit action',
                    },
                    {
                        value: Current_Data.Form_Col_Button.submit_action_id,
                        Options: [{ id: '', name: 'None' }].concat(
                            Current_Data.Submit_Actions.map(Submit_Action => [
                                Submit_Action.id,
                                Submit_Action.name,
                            ])
                        ),
                    },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_button_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save'),
        ];
    }
}
