import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { replaceDiacritics } from '../../utils/string';

export default class PageSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const Default_Language = Load_Data.Languages.find(Language => Language.default.toString() === 'true');

        if (!Default_Language) {
            alert('Error: Could not find default language.');
        }

        const default_title = Load_Data.Page_Titles
            .filter(Page_Title => parseInt(Page_Title.language_id, 10) === parseInt(Default_Language.id, 10))
            .map(Page_Title => Page_Title.title)
            .find(title => title) || '';

        const Language_Fields = {};
        Load_Data.Languages.forEach((Language, i) => {
            Language_Fields['language_' + i] = Language.id;
            Language_Fields['title_' + i] = ['create', 'copy'].includes(Action_Data.data_action_type) ?
                '' :
                (Language.default.toString() !== 'true' && Load_Data.Page.same_title_all_languages.toString() === 'true' ?
                    default_title :
                    Load_Data.Page_Titles.filter(Page_Title => parseInt(Page_Title.language_id, 10) === parseInt(Language.id, 10))[0].title
                );
        });

        const default_page_id = Load_Data.Pages.length > 0 ? Load_Data.Pages[0].page_id : 0;

        return ['create', 'copy'].includes(Action_Data.data_action_type) ?
            deepmerge(Load_Data,
                {
                    Page: deepmerge({
                        status: '1',
                        same_title_all_languages: 'true',
                        base_url_on_title: 'true',
                        page_url: '',
                        is_child_of_page: 'false',
                        parent_page_id: null,
                        template: '0',
                        template_id: '0',
                        amount_of_page_titles: Load_Data.Languages.length,
                        parent_page_id: default_page_id/*,
                        sync_navi_with_url_hierarchy: 'true'*/
                    }, Language_Fields),
                    Page_Titles: Load_Data.Languages.map(Language => {
                        return {
                            id: '',
                            language_id: Language.id,
                            page_id: '',
                            site_id: '',
                            title: ''
                        };
                    }),
                    Default_Language: Default_Language
                }
            ) :
            deepmerge(Load_Data,
                {
                    Page: deepmerge({
                        template: Load_Data.Page.template === '' ? '0' : '',
                        is_child_of_page: Load_Data.Page.parent_page_id !== null,
                        amount_of_page_titles: Load_Data.Languages.length,
                        parent_page_id: Load_Data.Page.parent_page_id || default_page_id
                    }, Language_Fields),
                    Default_Language: Default_Language
                }
            );
    }

    getQuery(Action_Data) {
        return ['create', 'copy'].includes(Action_Data.data_action_type) ?
            `query DataQuery($urls: String!) {
                Languages
                Pages
            }`:
            `query DataQuery($urls: String!) {
                Page {
                    id
                    site_id
                    type
                    status
                    template
                    template_id
                    same_title_all_languages
                    base_url_on_title
                    page_url
                    parent_page_id
                    template
                    template_id
                }
                Languages
                Page_Titles
                Pages
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Page', [
                [
                    { type: Current_Data.Page.type === 'home_page' ? 'text' : 'hidden', colspan: 2 },
                    { text: 'The title & status of the homepage cannot be changed.', style: 'bold' }
                ],
                [
                    { type: Current_Data.Page.type !== 'home_page' ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: (Action_Data.data_action_type === 'create' ? 'Create new page' :
                            (Action_Data.data_action_type === 'copy' ? 'Copy current page to new page' : 'Current page')
                        ),
                        style: 'subtitle'
                    }
                ],
                [
                    {
                        type: Current_Data.Page.type !== 'home_page' ? 'dropdown' : 'hidden',
                        id: 'status',
                        label: 'Status'
                    },
                    {
                        value: Current_Data.Page.status,
                        Options: [
                            ['1', 'Live (public)'],
                            ['3', 'Hidden (not in public menu)'],
                            ['2', 'Not Live (need to be signed in & have proper rights)']
                        ]
                    }
                ],
                [
                    {
                        type: Current_Data.Page.type !== 'home_page' ? 'text' : 'hidden',
                        colspan: 2
                    },
                    { text: 'Page title' + (Current_Data.Languages.length > 1 ? 's (several language variants)' : ''), style: 'bold' }
                ],
                [
                    {
                        type: Current_Data.Page.type !== 'home_page' && Current_Data.Languages.length > 1 ?
                            'checkbox' : 'hidden',
                        id: 'same_title_all_languages',
                        label: 'Use same title any language'
                    },
                    { value: Current_Data.Page.same_title_all_languages, styling_type: 'switch' }
                ]
            ]
                .concat([].concat.apply([], Current_Data.Languages.map((Language, i) => [
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' ? 'field' : 'hidden',
                            id: 'title_' + i,
                            label: Language.name
                        },
                        {
                            value: Current_Data.Page['title_' + i],
                            max: 2,
                            readonly: (
                                Language.default.toString() !== 'true' &&
                                Current_Data.Page.same_title_all_languages.toString() === 'true'
                            ),
                            onChange: value => ({
                                Page_Titles: Current_Data.Page_Titles.map(Page_Title => deepmerge(Page_Title, {
                                    title: parseInt(Page_Title.language_id, 10) === parseInt(Language.id, 10) ?
                                        value : Page_Title.title
                                }))
                            })
                        }
                    ]
                ])))
                .concat([
                    [
                        { type: Current_Data.Page.type !== 'home_page' ? 'text' : 'hidden', colspan: 2 },
                        { text: 'URL', style: 'bold' }
                    ],
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' ? 'checkbox' : 'hidden',
                            id: 'base_url_on_title',
                            label: 'Base URL on title' +
                                (Current_Data.Languages.length > 1 ? ' (' + Current_Data.Default_Language.name + ')' : '')
                        },
                        {
                            value: Current_Data.Page.base_url_on_title,
                            styling_type: 'switch',
                            onChange: value => (value.toString() === 'true' ? {
                                page_url: replaceDiacritics(
                                    Current_Data.Page_Titles
                                        .filter(Page_Title => parseInt(Page_Title.language_id, 10) === parseInt(Current_Data.Default_Language.id, 10))
                                        .map(Page_Title => Page_Title.title)
                                        .find(title => title) || ''
                                )
                                    .replace(/[&_,~\\\\/\-.*+|]|\s/g, '-')
                                    .toLowerCase()
                                    .replace(/[^a-z0-9-]/gi, '')
                            } : {})
                        }
                    ],
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' ? 'field' : 'hidden',
                            id: 'page_url',
                            label: 'URL'
                        },
                        {
                            value: Current_Data.Page.page_url,
                            max: 2,
                            readonly: Current_Data.Page.base_url_on_title.toString() === 'true'

                        }
                    ],
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' ? 'checkbox' : 'hidden',
                            id: 'is_child_of_page',
                            label: 'Sub-URL of other page'
                        },
                        { value: Current_Data.Page.is_child_of_page, styling_type: 'switch' }
                    ],
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' &&
                                Current_Data.Page.is_child_of_page.toString() === 'true' ?
                                'dropdown' : 'hidden',
                            id: 'parent_page_id',
                            label: 'Parent page'
                        },
                        {
                            value: Current_Data.Page.parent_page_id,
                            Options: Current_Data.Pages.map(Page =>
                                [Page.page_id, Page.title]
                            )
                        }
                    ],
                    /*[
                        {type: Current_Data.Page.type !== 'home_page' ? 'text' : 'hidden', colspan: 2},
                        {text: 'Navigation schemas', Style: 'bold'}
                    ],
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' ? 'checkbox' : 'hidden',
                            id: 'sync_navi_with_url_hierarchy',
                            label: 'Synchronize with URL hierarchy'
                        },
                        {value: Current_Data.Page.sync_navi_with_url_hierarchy, styling_type: 'switch'}
                    ],*/
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' ? 'dropdown' : 'hidden',
                            id: 'template',
                            label: 'Template Group'
                        },
                        {
                            value: Current_Data.Page.template,
                            Options: [
                                ['0', 'No template group'],
                                ['start', 'Start (only for rootfact)'],
                                ['flex', 'Flex (only for rootfact)']
                            ]
                        }
                    ],
                    [
                        {
                            type: Current_Data.Page.type !== 'home_page' &&
                                Current_Data.Page.template.toString() !== '0' ?
                                'dropdown' : 'hidden',
                            id: 'template_id',
                            label: 'Template Id'
                        },
                        {
                            value: Current_Data.Page.template_id,
                            Options: [
                                ['0', 'No template ID']
                            ]
                        }
                    ]
                ])
            ),
            Current_Data.Page.type === 'home_page' ?
                getSingleSubmitSet(frm_id,) :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_page' :
                                (Action_Data.data_action_type === 'copy' ? 'copy_page' : 'save_page'),
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                navigate(
                                    Function_Data.default_language_type,
                                    Function_Data.language_type,
                                    Function_Data.device_type,
                                    Function_Data.base_url,
                                    Function_Data.site_url,
                                    Result_Data.Page_URLs
                                );
                            }
                        }
                    });
                }, Action_Data.data_action_type === 'create' ? 'Create' :
                    (Action_Data.data_action_type === 'copy' ? 'Copy' : '')
                )
        ];
    }
}