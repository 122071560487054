import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class OsFontManagement {
    getQuery() {
        return `query DataQuery($urls: String!) {
            OS_Fonts
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text' },
                    {
                        text: 'These are standard Fonts, which are frequently including in operating systems.<br>\
                        The actual support differs between operating systems, thus they need proper fallbacks.', style: 'bold'
                    }
                ],
                [
                    { type: 'os_fonts_management', id: 'font_overview' },
                    { OS_Fonts: Current_Data.OS_Fonts }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}