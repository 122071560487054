import React, { Component } from 'react';
import ColumnType from '../column_type';
import { buildFormElementID } from '../../utils/form';

export default class Row extends Component {
    render() {
        const {
            Function_Data, Action_Data, frm_id, Data_Groups, fld_id,
            tab, El_Data, reloadForm, updateActionData, changeCurrentData, parent_disabled
        } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return (
            <table className="full_width">
                <tbody>
                    <tr id={id ? 'row_' + id : null}>
                        {El_Data.Content.map((ContentItem, index) =>
                            <ColumnType
                                key={index}
                                Function_Data={Function_Data}
                                Action_Data={Action_Data}
                                frm_id={frm_id}
                                Data_Groups={Data_Groups}
                                tab={tab}
                                Data={ContentItem}
                                reloadForm={reloadForm}
                                updateActionData={updateActionData}
                                changeCurrentData={changeCurrentData}
                                parent_disabled={parent_disabled}
                            />
                        )}
                    </tr>
                </tbody>
            </table>
        );
    }
}