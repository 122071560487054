import deepmerge from 'deepmerge';
import $ from 'jquery';
import React, { Component } from 'react';
import form_data_store from '../api/form_data_store';
import layout_store from '../api/layout_store';
import { getSize } from '../utils/browser';
import { getModByPresetOrDirect } from '../utils/mod';
import { clone } from '../utils/object';
import { isStringOrNumber } from '../utils/types';
import { Form_Router } from './element_router';

export default class Form extends Component {
    constructor(props) {
        super(props);
        const { Presets, Element } = props;
        const { Background, El_Data, Content, el_idx } = Element;
        const { data_group } = El_Data;
        this.Data_Groups = data_group ? [data_group] : [];//TODO: make dynamic?
        this.Content_Types = ['section', 'grid', 'row'];
        this.Data_Types = [
            'checkbox', 'color_field', 'date_field', 'dropdown', 'field', 'hidden',
            'image_text_select', 'radio_select', 'slider_field', 'textarea'
        ];

        /*TODO: Get rid of this file entirely
         Instead use redux store in place of state...
         ... redux is better because there is no use of <form> as a
         tag, it's ancient html functionality long superceded by XMLHttpRequest.
         It's also better to manage data on a generic level, as opposed to managing
         it by its html-hierarchy.
         The only hierarchy should be by "data-groups", which should be available
         inside the properties of each field/dropdown/checkbox/etc
         Instead of given a field a value and a name, you should give it a subscription
         to a certain data-value.
         On a site level, you should be able to define data-schemas. Each schema has a
         name and can have a further nested hierarchy or direct key-value pairs inside it.
         These key-value pairs each have a name and a value. The value is then either linked to a
         data-schema from an api call, or only has a start/default-value.
         Api-calls should be allowed to rely on input-data (get-properties), which can be other
         defaults-values, or other api-call-results. Due the latter, it should be able to define
         api-calls nested from each other, in which cases the nested one can take in the other's data.
         Getting the main-page-data (inside app.js), there should be 2 new properties:
         * "data-api-calls": It tells the renderer which calls should be made to fill the values
         of the fields present on the page.
         * "default-data": It holds the default data for fields that don't rely on a api-call.
         All the neccesary calls should be made before page-rendering. Then all the api-data and
         the default-data should be stored in the redux-store
         When rendering, each field-component should then pick the right value from the redux-store.
         This way, non-field-component can also start using the redux-store to dynamically
         alter their properties: hide/show the entire component, change style, change text...
         Later on, charts can also be added and dynamically changed according to data-changes.
        */

        const Functional_Data = El_Data.Functional_Fields.reduce((New_Data, Functional_Field) => {
            New_Data[Functional_Field.El_Data.name] = Functional_Field.El_Data.value;
            return New_Data;
        }, {});

        this.state = {
            Current_Data: deepmerge(
                El_Data.data_group ? { [El_Data.data_group]: Functional_Data } : Functional_Data,
                this.getDataFromContent(data_group, Content)
            ),
            backgroundSize: undefined
        };
        const isPublic = true;//TODO: make dynamic?
        this.frm_id = `${isPublic ? 'public_' : ''}form_${el_idx}`;

        form_data_store.dispatch({
            type: 'UPDATE',
            Current_Form_Data: this.state.Current_Data,
            frm_id: this.frm_id,
            Data_Groups: this.Data_Groups
        });

        /*const types = [
            'section', 'grid', 'row', 'box_text', 'button',
            'checkbox', 'color_field', 'date_field', 'dropdown',
            'field', 'hidden_field', 'image', 'image_button',
            'icon_link', 'image_text_select', 'line', 'radio_select',
            'slider_field', 'space', 'text', 'textarea'
        ];*/
        this.unsubscribes = [];

        this.myRef;

        this.BackgroundMod = Background ? getModByPresetOrDirect(Background, Presets) : undefined;
    }

    componentDidMount() {
        this.unsubscribes = [
            form_data_store.subscribe(this.handleFormDataStoreChange().bind(this)),
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
        this.resizeElementBackground();

        const { load_script } = this.props.Element.El_Data;

        if (load_script !== '' && typeof (load_script) === 'string') {
            Function(
                `${'"use strict";\n' +
                'const $ = arguments[0];\n' +
                'const frm_id="'}${this.frm_id}";\n` +
                `const Data_Groups=${JSON.stringify(this.Data_Groups)};\n` +
                'const buildFormElementID = (frm_id, Data_Groups, fld_id, non_prefixed_id = false) => {' +
                'return non_prefixed_id ? fld_id :' +
                '(frm_id ? [frm_id] : [])' +
                '.concat(Data_Groups)' +
                '.concat(fld_id ? [fld_id] : [])' +
                '.map(part => part.toLowerCase())' +
                '.join("_");' +
                `};${load_script}`
            ).call(this, $);
        }
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    setRef(el) {
        this.myRef = el;
    }

    handleLayoutStoreChange() {
        const { action } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.resizeElementBackground();
        }
    }

    resizeElementBackground() {
        if (this.BackgroundMod && this.myRef) {
            const { type, width, height, fit } = this.BackgroundMod;
            if (type === 'image' && ['c', 'e'].includes(fit)) {
                let element_ratio = getSize(this.myRef).w / getSize(this.myRef).h;
                let img_ratio = width / height;
                this.setState({
                    backgroundSize: (fit === 'e' && element_ratio < img_ratio) || (fit === 'c' && element_ratio > img_ratio) ?
                        '100% auto' :
                        'auto 100%'
                });
            }
        }
    }

    getType(Element) {
        return Element.el_type.substring('form_col_'.length);
    }

    getDataFromContent(data_group, Content) {
        let Data = {};
        Content
            .filter(Element => this.Data_Types.includes(this.getType(Element)))
            .forEach(Element => {
                Data[Element.El_Data.name] = Element.El_Data.value;
            });

        Content
            .filter(Element => this.Content_Types.includes(this.getType(Element)))
            .forEach(Element => {
                if (Element.Content) {
                    Data = deepmerge(Data, this.getDataFromContent(Element.data_group, Element.Content));
                }
            });

        return data_group ? { [data_group]: Data, } : Data;
    }

    updateContentWithCurrentData(Data_Groups, Content) {
        let Values_In_Group = this.state.Current_Data;
        Data_Groups.forEach(data_group => {
            Values_In_Group = Values_In_Group[data_group];
        });

        Content
            .filter(Element => this.Data_Types.includes(this.getType(Element)))
            .forEach(Element => {
                Element.El_Data.value = Values_In_Group[Element.El_Data.name];
            });

        Content
            .filter(Element => this.Content_Types.includes(this.getType(Element)))
            .forEach(Element => {
                if (Element.Content) {
                    Element.Content = this.updateContentWithCurrentData(
                        Data_Groups.concat(Element.data_group ? [Element.data_group] : []),
                        Element.Content);
                }
            });

        return Content;
    }

    changeCurrentData(New_Data) {
        form_data_store.dispatch({
            type: 'UPDATE',
            Current_Form_Data: deepmerge(this.state.Current_Data, New_Data, { arrayMerge: (dest, src) => src }),
            frm_id: this.frm_id,
            Data_Groups: this.Data_Groups
        });
    }

    handleFormDataStoreChange() {
        return () => {
            const state = form_data_store.getState();
            if (state.action === 'update' && Object.keys(state.Current_Form_Data[this.frm_id]).length) {
                this.setState({
                    Current_Data: clone(state.Current_Form_Data[this.frm_id])
                });
            }
        };
    }

    render() {
        const {
            Element, Corrections, tab, project, site_id, show_tools, use_service, requiresNewEffect, reloadForm,
            updateActionData, parent_disabled, Navigation_Data, page_id, user_role
        } = this.props;
        const { Background, Border, Corner, Shadow, Layout, Content } = Element;

        const style = {};

        if (Corrections && Corrections.full_height_correction) {
            style.minHeight = Corrections.full_height_correction.height;
        }

        if (this.state.backgroundSize) {
            style.backgroundSize = this.state.backgroundSize;
        }

        const { width_type, hor_amount_type, width_pixel, width_percentage } = Layout;
        let form_style = {};

        if (width_type === 'fit') {
            form_style.float = 'left';
        }

        if (width_type === 'cus') {
            form_style.width = hor_amount_type === 'px' ? `${width_pixel}px` : `${width_percentage}%`;
        }

        const cssclass = (Background && isStringOrNumber(Background) ? `background_${Background}` : '') +
            (Border && isStringOrNumber(Border) ? ` border_${Border}` : '') +
            (Corner && isStringOrNumber(Corner) ? ` corner_${Corner}` : '') +
            (Shadow && isStringOrNumber(Shadow) ? ` shadow_${Shadow}` : '');
        const Active_Content = this.updateContentWithCurrentData(this.Data_Groups, Content);

        return (
            <div style={Object.keys(style).length ? style : null}>
                <div
                    id={this.frm_id}
                    className={cssclass}
                    style={Object.keys(form_style).length ? form_style : null}
                >
                    {Array.isArray(Active_Content) &&
                        <table className={Layout.width_type !== 'fit' ? 'full_width' : ''}><tbody>
                            {Active_Content
                                .filter(Col_Data => Col_Data.el_type !== 'form_col_hidden')
                                .map((Col_Data, index) => <tr
                                    key={index}
                                    id={`row_${Col_Data.el_type}_${Col_Data.el_idx}`}
                                    style={Col_Data.El_Data.hidden && Col_Data.El_Data.hidden === 'true' ? { display: 'none' } : null}
                                >
                                    <Form_Router
                                        project={project}
                                        site_id={site_id}
                                        frm_id={this.frm_id}
                                        Data_Groups={this.Data_Groups}
                                        tab={tab}
                                        Element={Col_Data}
                                        show_tools={show_tools}
                                        use_service={use_service}
                                        requiresNewEffect={requiresNewEffect}
                                        reloadForm={reloadForm}
                                        updateActionData={updateActionData}
                                        changeCurrentData={this.changeCurrentData.bind(this)}
                                        parent_disabled={parent_disabled}
                                        Navigation_Data={Navigation_Data}
                                        page_id={page_id}
                                        user_role={user_role}
                                    />
                                </tr>)}
                        </tbody></table>
                    }
                </div>
            </div>
        );
    }
}
