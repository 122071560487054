import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { ucWords } from '../../utils/string';
import { renderGrid, renderRow } from './management_grid';

export default class FontFamiliesManagement extends Component {
    onClickEdit(Font_Family) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'edit_font_family_panel',
                    frm_name: 'font_family_settings',
                    font_family_id: Font_Family.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Font_Family) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_font_family_panel',
                    frm_name: 'remove_font_family',
                    font_family_id: Font_Family.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.Font_Families.length === 0 ?
            (<p className="gp">No font-families available.</p>) :
            renderGrid(
                id + '_font_list',
                [
                    { label: 'Main Font' },
                    { label: 'Fallback 1' },
                    { label: 'Fallback 2' },
                    { label: 'Fallback 3' },
                    { label: 'Fallback 4' },
                    { label: 'Type' },
                    { label: '' },
                    { label: '' },
                ],
                this.props.El_Data.Font_Families.map((Font_Family, index) =>
                    renderRow(
                        id + '_font_row_' + Font_Family.id,
                        index,
                        [
                            { type: 'text', value: ucWords(Font_Family.main) },
                            { type: 'text', value: ucWords(Font_Family.fallback_1) },
                            { type: 'text', value: ucWords(Font_Family.fallback_2) },
                            { type: 'text', value: ucWords(Font_Family.fallback_3) },
                            { type: 'text', value: ucWords(Font_Family.fallback_4) },
                            { type: 'text', value: ucWords(Font_Family.generic_type) },
                            { type: 'action', icon: 'wrench', onClick: this.onClickEdit(Font_Family) },
                            { type: 'action', icon: 'delete', onClick: this.onClickDelete(Font_Family) }
                        ]
                    )
                )
            );
    }
}