import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { upload } from '../../api/upload_query';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils';

//TODO: merge this class with "video_management" !!!!

export default class VideoSelect {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return deepmerge(Load_Data, {
            Selected_Video: {
                resource_id: Action_Data.data_action_type === 'create' ? 0 : parseInt(Load_Data.resource_id, 10),
                file_type: 'jpg'
            }
        });
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            resource_id
            total_amount
            Videos
            max_file_uploads
            post_max_size
            upload_max_filesize
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', full_width: true, id: 'Selected_Video' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [
                        [
                            { type: 'row' },
                            {
                                Content: [
                                    [
                                        { type: 'button_image', id: 'upload_new_video', h_align: 'left', v_align: 'center' },
                                        {
                                            name: 'upload_new_video',
                                            button_style: [110, 32, 0, 4, 0, 4, 8],
                                            text: 'Upload',
                                            img_url: getImageURL('rootflex', 'choose_file'),
                                            img_style: [0, 37, 35, 0],
                                            file_upload: true,
                                            filetype_restriction: 'video/*',
                                            onChange: Files => {
                                                upload(
                                                    Function_Data,
                                                    frm_id,
                                                    ['Selected_Video'],
                                                    'upload_new_video',
                                                    Files,
                                                    {},
                                                    'upload_videos',
                                                    Current_Data.max_file_uploads,
                                                    Current_Data.upload_max_filesize,
                                                    Current_Data.post_max_size,
                                                    (Result_Data) => {
                                                        //$('#' + (buildFormElementID(frm_id, Data_Groups, 'resource_id')) + '_' + 'image_picker')
                                                        //    .trigger('reloadMediaCollectionAfterActions', {media_type: 'video', Result_Data: Result_Data});
                                                    }
                                                );
                                            }
                                        }
                                    ],
                                    [
                                        { type: 'text', padding_left: 10 },
                                        { text: 'The uploads will be added to the collection below.<br>The filetype has to be "mp4" and has to be H.264<br>encoded to properly display in all browsers.', style: 'bold' }
                                    ],
                                    [
                                        {
                                            type: 'image',
                                            id: 'resource_id_selected_preview',
                                            colspan: 2,
                                            h_align: 'right',
                                            padding_left: 10,
                                            padding_right: 10,
                                            padding_top: 3,
                                            padding_bottom: 3
                                        },
                                        {
                                            src: Current_Data.Selected_Video.resource_id === 0 ?
                                                'projects/rootflex/img/form/no_video_selected.jpg' :
                                                'projects/rootflex/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/video/w150h100/' + Current_Data.Selected_Video.resource_id + '.jpg',
                                            show_transparency_background: true
                                        }
                                    ]
                                ]
                            }
                        ]
                    ]
                }
            ],
            wrapSectionSubs('Selected_Video', [
                [
                    { type: 'text' },
                    { text: 'Click on an video to select', style: 'bold' }
                ],
                [
                    { type: 'media_selector', id: 'resource_id' },
                    {
                        Items: Current_Data.Videos,
                        Selected_Item: {
                            id: Current_Data.Selected_Video.resource_id,
                            file_type: 'jpg'
                        },
                        media_type: 'video',
                        lightbox_path: 'projects/rootflex/files/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/video/placeholder/',
                        thumbnail_path: 'projects/rootflex/cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/video/w150h100/',
                        width: 150,
                        height: 100,
                        start: 1,
                        total_amount: Current_Data.total_amount,
                        column_amount: 5
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                const resource_id = Current_Data.Selected_Video.resource_id;

                if ((isNaN(resource_id) || resource_id === 0)) {
                    alert('Cannot save, because no video was selected.');
                } else {
                    if (Action_Data.data_action_type === 'create') {
                        // TODO store form values to be used in later post-submit to back-end
                        updateActionData({
                            access: 'authorized',
                            frm_name: 'video_settings',
                            resource_id: resource_id
                        });
                    } else if (Action_Data.el_type !== 'video') {
                        //submit_action = 'select_video';
                        // $('body').trigger('reload_video_fields', {
                        //     action: 'select',
                        //     selected_resource_id: resource_id,
                        //     selected_file_type: 'jpg'
                        // });

                        layer_store.dispatch({
                            type: 'CLOSE_PANEL',
                        });
                    } else {
                        form_data_store.dispatch({
                            type: 'SUBMIT',
                            submitData: {
                                frm_id,
                                submit_action: 'switch_video',
                                Function_Data,
                                process_tag: 'Saving...',
                                onSubmitted: Result_Data => {
                                    /*var now = new Date();
                                    $('#highlight_video_' + Action_Data.el_idx).css({
                                    width: Result_Data.Layout.width-4,
                                    height: Result_Data.Layout.height-4
                                    });
                                    $('#select_video_' + Action_Data.el_idx).css({
                                    width: Result_Data.Layout.width-4,
                                    height: Result_Data.Layout.height-4
                                    });
                                    $('#element_video_' + Action_Data.el_idx).css({
                                    width: Result_Data.Layout.width,
                                    height: Result_Data.Layout.height
                                    });
                                    $('#video_' + Action_Data.el_idx).attr({
                                    src: Function_Data.base_url + 'cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/shared/img/custom/' + Action_Data.el_idx + '.' + Result_Data.file_type + '?' + now.getTime(),
                                    width: Result_Data.Layout.width,
                                    height: Result_Data.Layout.height
                                    });*/
                                    //TODO: also change element & select/highlight div's position
                                }
                            }
                        });
                    }
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' :
                (Action_Data.el_type !== 'video' ? 'Select' : 'Save')
            )
        ];
    }
}