import React, { Component, Fragment } from 'react';

export default class Line extends Component {
    render() {
        const { space_1, color, thickness, space_2 } = this.props.El_Data;

        return (
            <Fragment>
                <div style={{ height: (space_1 || 5) + 'px' }} />
                <div style={{
                    backgroundColor: '#' + (color || 'd3d3d3'),
                    height: (thickness || 1) + 'px',
                    display: 'block',
                    fontSize: 0,
                    lineHeight: 0
                }} />
                <div style={{ height: (space_2 || 5) + 'px' }} />
            </Fragment>
        );
    }
}