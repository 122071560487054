import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../utils/form';

//SHOULD GET RID OF THIS FILE, USE CLASSES INSIDE "LAYER/COLUMN"

export class InputSliderColumn extends Component {
    handleChange(e) {
        const { changeCurrentData, onChange, Data_Groups, name } = this.props;
        const New_Data = onChange ? onChange(e.target.value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([name]), e.target.value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    render() {
        const { frm_id, name, Data_Groups, El_Data, tab, show_tools, parent_disabled, responsive = false } = this.props;
        const value = El_Data.value || '';
        const readonly = Boolean(show_tools || parent_disabled || El_Data.readonly === 'true');
        //const onChange = El_Data.onChange || null;

        let className = 'fld_input',
            maxlength = 11,
            width = 28,
            width_type = 'cus',
            min = El_Data.min,
            max = El_Data.max;

        const style = {};
        style.width = '360px';

        /*if (!(
            /^\d+$/.test(min) ||
            /^\d+$/.test(max) ||
            /^\d+$/.test(value)
            )) {
            alert('Cannot build slider, because required min/max/value are not integers.');
        } else {
            min = parseInt(min, 10);
            max = parseInt(max, 10);
            value = parseInt(value, 10);
            var $slider = $el.parent().next();

            $slider
                .slider({
                    range: 'min',
                    value: value,
                    min: min,
                    max: max,
                    slide: (e, ui) => {
                        $el.val(ui.value);
                    }
                });
            if ($el.attr('readonly') === 'readonly') {
                $slider.slider('disable');
            }
            $el
                .val($slider.slider('value'))
                .blur(() => {
                    var value = $el.val();
                    if (value > max) {
                        $el.val(max);
                        value = max;
                    } else if (value < min) {
                        $el.val(min);
                        value = min;
                    }
                    var percentage = (100 * ((value - min) / (max - min))) + '%';
                    $slider
                        .children().eq(0).width(percentage)
                        .children().eq(1).css('left', percentage);
                });
        }*/

        const style2 = {
            width: width_type === 'cus' && width ? `${width}px` : (width_type === '100' ? '100%' : undefined),
            fontSize: responsive ? '180%' : undefined
        };

        return (
            <div
                className="form_field_box inpt"
                style={Object.keys(style).length > 0 ? style : null}
            >
                <div className="left">
                    <input
                        type="text"
                        id={buildFormElementID(frm_id, Data_Groups, name)}
                        name={name && buildFormElementName(Data_Groups, name, 'text')}
                        className={className + (readonly ? ' fld_input_disabled' : '')}
                        style={Object.keys(style2).length > 0 ? style2 : null}
                        maxLength={maxlength ? maxlength : null}
                        tabIndex={tab}
                        value={value}
                        onChange={this.handleChange.bind(this)}
                        readOnly={readonly}
                        disabled={readonly}
                    />
                </div>
                <div
                    id={`${buildFormElementID(frm_id, Data_Groups, name)}_slider`}
                    className="form_field_slider"
                    title={`${min}-${max}`}
                />
            </div>
        );
    }
}

export class InputColumn extends Component {
    constructor(props) {
        super(props);

        const { readonly, autocomplete } = this.props;

        this.state = {
            hasFocus: false,
            blockAutocompleteOnInit: Boolean(readonly || !autocomplete)
        };
    }

    handleBlur() {
        this.setState({ hasFocus: false });
    }

    handleFocus() {
        this.setState({ hasFocus: true });
    }

    handleKeyUp(event) {
        const { trigger_click_button_name } = this.props;
        if (trigger_click_button_name && event.which === 13) {
            //document.getElementById('' + trigger_click_button_name).click();
            //console.log(trigger_click_button_name);
        }
    }

    handleChange(e) {
        const { Data_Groups, name, changeCurrentData, onChange } = this.props;
        const New_Data = onChange ? onChange(e.target.value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([name]), e.target.value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    componentDidMount() {
        if (this.state.blockAutocompleteOnInit) {
            const that = this;
            setTimeout(() => {
                that.setState({ blockAutocompleteOnInit: false });
            }, 500);
        }
    }

    render() {
        const { frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, parent_disabled, changeCurrentData } = this.props;
        const value = El_Data.value || '';
        const type = El_Data.type;
        const readonly = Boolean(show_tools || parent_disabled || El_Data.readonly === 'true');
        //const onChange = El_Data.onChange || null;

        let className,
            maxlength,
            inner_label,
            field_type,
            width = 0,
            h_align = 'left',
            autocomplete = false,
            responsive = false,
            width_type = 'cus',
            non_prefixed_id = false;

        if (type === 'simple_field' && (El_Data.styling_type === 'quality' || El_Data.styling_type === 'quality_big')) {
            className = 'q_fld';
            maxlength = El_Data.maxlength;
            inner_label = El_Data.placeholder;
            field_type = El_Data.field_type || 'text';
            width = El_Data.width;
            h_align = Col_Settings.h_align;
            autocomplete = El_Data.autocomplete === 'true';
            responsive = El_Data.responsive === 'true';
            width_type = El_Data.width_type || 'cus';
            non_prefixed_id = El_Data.non_prefixed_id === 'true';
        } else {
            responsive = false;
            width_type = 'cus';
            field_type = 'text';
            if (type === 'simple_field' && (El_Data.styling_type === 'normal' || El_Data.styling_type === 'small')) {
                className = `fld_input${El_Data.styling_type === 'small' ? ' fld_input_small' : ''}`;
                field_type = El_Data.field_type || 'text';
                width = 0;
                maxlength = El_Data.maxlength;
            }
        }
        const style = {};
        if (type === 'simple_field' && El_Data.styling_type === 'small') {
            style.float = 'right';
        }

        /*
        const id = buildFormElementID(frm_id, Data_Groups, El_Data.name);
        const trigger_click_button_name = buildFormElementID(frm_id, Data_Groups, El_Data.trigger_click_button_name);
        var el_class_focus;
        var $el = $('#' + id);

        if (El_Data.styling_type && El_Data.styling_type === 'small') {
            el_class_focus = 'fld_input_small_focus';
        } else {
            el_class_focus = 'fld_input_focus';
        }
        if (trigger_click_button_name) {
            $('#' + id)
                .keyup((e) => {
                    if (e.which === 13) {
                        document.getElementById('' + trigger_click_button_name).click();
                    }
                });
        }

        if (el_class_focus) {
            $el
                .blur(() => {
                    $(this).removeClass(el_class_focus);
                })
                .focus(() => {
                    $(this).addClass(el_class_focus);
                });
        }
        if (onChange && typeof(onChange) === 'function') {
            $el
                .change(() => {
                    onChange.call(this);
                });
        }*/

        if (type === 'simple_field' && ['quality', 'quality_big'].includes(El_Data.styling_type)) {
            const qstyle = {
                width: width_type === 'cus' && width ? `${width}px` : (width_type === '100' ? '100%' : undefined),
                float: width_type === 'cus' && h_align !== 'center' ? h_align : undefined
            };
            //TODO: trigger_click_button_name ???
            return (
                <div className={responsive ?
                    'q_fld_holder_responsive' :
                    (`q_fld_holder${El_Data.styling_type === 'quality_big' ?
                        ' big' :
                        ''}`)
                }>
                    <input
                        type={field_type}
                        id={buildFormElementID(frm_id, Data_Groups, name, non_prefixed_id)}
                        name={name ? buildFormElementName(Data_Groups, name, field_type) : null}
                        className={
                            responsive ? 'q_fld_responsive' : `q_fld${El_Data.styling_type === 'quality_big' ? ' big' : ''
                                }${readonly ? ' fld_input_disabled' : ''
                                }${this.state.hasFocus ? ' q_fld-focus' : ' q_fld-filled'}`
                        }
                        style={Object.keys(qstyle).length > 0 ? qstyle : null}
                        maxLength={maxlength || null}
                        tabIndex={tab}
                        value={value}
                        onChange={this.handleChange.bind(this)}
                        readOnly={readonly || this.state.blockAutocompleteOnInit}
                        disabled={readonly}
                        autoComplete={autocomplete ? 'on' : 'off'}
                        placeholder={inner_label !== '' ? inner_label : null}
                        onKeyUp={this.handleKeyUp.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        onFocus={this.handleFocus.bind(this)}
                    />
                </div>
            );
        }
        const style2 = {
            width: width_type === 'cus' && width ? `${width}px` : (width_type === '100' ? '100%' : undefined),
            fontSize: responsive ? '180%' : undefined
        };
        return (
            <div
                className="form_field_box inpt"
                style={Object.keys(style).length > 0 ? style : null}
            >
                <input
                    type={field_type}
                    id={buildFormElementID(frm_id, Data_Groups, name)}
                    name={name && buildFormElementName(Data_Groups, name, field_type)}
                    className={className + (readonly ? ' fld_input_disabled' : '')}
                    style={Object.keys(style2).length > 0 ? style2 : null}
                    maxLength={maxlength && ['text', 'password'].includes(field_type) ? maxlength : null}
                    tabIndex={tab}
                    value={value}
                    onChange={this.handleChange.bind(this)}
                    readOnly={readonly && ['text', 'password', 'number'].includes(field_type)}
                    disabled={readonly && ['text', 'password', 'number'].includes(field_type)}
                />
            </div>
        );
    }
}

export class InputDateColumn extends Component {
    render() {
        const { frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, parent_disabled, changeCurrentData } = this.props;
        const { readonly, onChange, value } = El_Data;

        return (
            <DateEl
                tab={tab}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                fld_id={name}
                Col_Settings={Col_Settings}
                changeCurrentData={changeCurrentData}
                parent_disabled={parent_disabled}
                El_Data={{
                    value: value || '',
                    disabled: show_tools ? true : readonly === 'true',
                    readonly: show_tools ? true : readonly === 'true',
                    onChange: onChange || null,
                    direction: 'hor'
                }}
            />
        );
    }
}

export class InputNumberColumn extends Component {
    handleChange(e) {
        const { changeCurrentData, onChange, Data_Groups, name } = this.props;
        const New_Data = onChange ? onChange(e.target.value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([name]), e.target.value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    render() {
        const { frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, parent_disabled, changeCurrentData } = this.props;
        const value = El_Data.value || '';
        const readonly = Boolean(show_tools || parent_disabled || El_Data.readonly === 'true');
        const direction = El_Data.direction || 'hor';
        const unit = El_Data.unit || '';
        //const onChange = El_Data.onChange || null;

        let maxlength = false;

        /*const id = buildFormElementID(frm_id, Data_Groups, El_Data.name);
        var $el = $('#' + id);

        var tryChangeNumberValue = (change_value) => {
            if (!$el.prop('readonly')) {
                value = parseInt($el.val(), 10) + change_value;
                if ((min === null || value >= min) && (max === null || value <= max)) {
                    $el
                        .val(value)
                        .trigger('change');
                }
            }
        },
        tryCheckMinMax = () => {
            value = parseInt($el.val(), 10);
            if (isNaN(value)) {
                value = min;
                $el.val(value);
            }
            if ((min !== null && value < min) || (max !== null && value > max)) {
                value = min !== null && value < min ? min : max;
                $el.val(value);
            }
            if ((min !== null && value === min && direction === 'hor') || (max !== null && value === max && direction === 'ver')) {
                $el.prev()
                    .addClass('fld_number_disable');
            } else {
                $el.prev()
                    .removeClass('fld_number_disable');
            }
            if ((max !== null && value === max && direction === 'hor') || (min !== null && value === min && direction === 'ver')) {
                $el.next()
                    .addClass('fld_number_disable');
            } else {
                $el.next()
                    .removeClass('fld_number_disable');
            }
        };

        $el
            .change(() => {
                if (!$el.prop('readonly')) {
                    tryCheckMinMax();
                }
            })
            .on('mousewheel', (e) => {
                e.preventDefault();

                tryChangeNumberValue((e.originalEvent.wheelDelta > 0 ? 1 : -1));
            })
            .keyup((e) => {
                if (e.which === 38) {//cursor up
                    tryChangeNumberValue(1);
                } else if (e.which === 40) {//cursor down
                    tryChangeNumberValue(-1);
                }
            })
            .prev().click(() => {
            tryChangeNumberValue((direction === 'hor' ? -1 : 1));
        })
            .next().next().click(() => {
            tryChangeNumberValue((direction === 'hor' ? 1 : -1));
        });

        tryCheckMinMax();
        el_class_focus = ' fld_input_focus_number';

        $el
            .blur(() => {
                $(this).removeClass(el_class_focus);
            })
            .focus(() => {
                $(this).addClass(el_class_focus);
            });
        if (onChange && typeof(onChange) === 'function') {
            $el
                .change(() => {
                    onChange.call(this);
                });
        }*/

        const className = `form_field_box inpt${direction === 'ver' ? ' inpt_ver' : ''}`;
        const style = {};
        if (Col_Settings.h_align === 'right') {
            style.float = 'right';
        }
        if (direction !== 'ver') {
            style.width = direction === 'ver' ? '42' : '88';
        }

        const style2 = {
            fontSize: responsive ? '180%' : (height ? `${height}px` : undefined)
        };

        return (
            <div
                className={className}
                style={Object.keys(style).length > 0 ? style : null}
            >
                {unit && <div className="fld_number_unit_' + direction + '" unselectable="on">
                    {unit}
                </div>}
                <div
                    className={`unselectable${direction === 'ver' ? ' fld_input_number_plus fld_input_number_plus_ver' : ' fld_input_number_minus'}`}
                    unselectable="on"
                >
                    {direction === 'ver' ? '+' : '-'}
                </div>
                <input
                    type="text"
                    id={buildFormElementID(frm_id, Data_Groups, name)}
                    name={name && buildFormElementName(Data_Groups, name, 'text')}
                    className={className + (readonly ? ' fld_input_disabled' : '')}
                    style={Object.keys(style2).length > 0 ? style2 : null}
                    tabIndex={tab}
                    value={value}
                    onChange={this.handleChange.bind(this)}
                    readOnly={readonly}
                    disabled={readonly}
                />
                <div
                    className={`unselectable${direction === 'ver' ? ' fld_input_number_minus fld_input_number_minus_ver' : ' fld_input_number_plus'}`}
                    unselectable="on"
                >
                    {direction === 'ver' ? '-' : '+'}
                </div>
            </div>
        );
    }
}

export class InputColorColumn extends Component {
    handleChange(e) {
        const { changeCurrentData, onChange, Data_Groups, name } = this.props;
        const New_Data = onChange ? onChange(e.target.value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([name]), e.target.value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    render() {
        const { frm_id, name, Data_Groups, El_Data, tab, show_tools, parent_disabled, changeCurrentData } = this.props;
        const value = El_Data.value || 'ffffff';
        const readonly = Boolean(show_tools || parent_disabled || El_Data.readonly === 'true');
        //const onChange = El_Data.onChange || null;

        let className = 'color_picker_field',
            width = 0,
            width_type = 'cus';

        const style = {};

        //const id = buildFormElementID(frm_id, Data_Groups, name);
        /*var el_class_focus;
        var $el = $('#' + id);

        $el
            .parent()
            .ColorPicker({
                id: id,
                hex_color: '#' + value,
                onChange: (hex) => {
                    $el
                        .val(hex)
                        .trigger('change')
                        .next().next()
                        .css('backgroundColor', '#' + hex);
                }
            });
        if ($el.attr('readonly') === 'readonly') {
            $el.parent().ColorPickerSetReadonly('on');
        }

        if (el_class_focus) {
            $el
                .blur(() => {
                    $(this).removeClass(el_class_focus);
                })
                .focus(() => {
                    $(this).addClass(el_class_focus);
                });
        }
        if (onChange && typeof(onChange) === 'function') {
            $el
                .change(() => {
                    onChange.call(this);
                });
        }*/

        return (
            <div
                className="form_field_box inpt"
                style={Object.keys(style).length > 0 ? style : null}
            >
                <input
                    type="text"
                    id={buildFormElementID(frm_id, Data_Groups, name)}
                    name={name && buildFormElementName(Data_Groups, name, 'text')}
                    className={className + (readonly ? ' fld_input_disabled' : '')}
                    maxLength={6}
                    tabIndex={tab}
                    value={value}
                    onChange={this.handleChange.bind(this)}
                    readOnly={readonly}
                    disabled={readonly}
                />
                <div
                    id={`${buildFormElementID(frm_id, Data_Groups, name)}_holder`}
                    className="color_picker_holder"
                >
                    <div
                        id={`${buildFormElementID(frm_id, Data_Groups, name)}_selector`}
                        className="color_picker_selector"
                        style={{ backgroundColor: `#${value}` }}
                    />
                </div>
            </div>
        );
    }
}

export class TextareaColumn extends Component {
    constructor(props) {
        super(props);
        this.max_length = props.El_Data.max || 300;
        this.state = {
            hasFocus: false,
            isExceedingMax: props.El_Data.value.length > this.max_length,
            maxText: this.getMaxText(props.El_Data.value.length)
        };
    }

    handleBlur() {
        this.setState({ hasFocus: false });
    }

    handleFocus() {
        this.setState({ hasFocus: true });
    }

    // handleChange(event) {
    //     this.setState({ value: event.target.value });
    //     this.updateCounter(event.target.value.length);
    // }

    handleChange(e) {
        const { Data_Groups, name, changeCurrentData, onChange } = this.props;
        const New_Data = onChange ? onChange(e.target.value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([name]), e.target.value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
        this.updateCounter(e.target.value.length);
    }

    getMaxText(amount) {
        const { language } = this.props.El_Data;
        const maxTextsTranslations = {
            dutch: {
                remaining: 'tekens resterend',
                tooMuch: 'tekens teveel'
            },
            english: {
                remaining: 'characters remaining',
                tooMuch: 'characters too much'
            }
        };

        const maxTexts = maxTextsTranslations[language] || maxTextsTranslations.english;
        return amount === 0 ?
            `${this.max_length} max.` :
            (amount > this.max_length ?
                `${amount - this.max_length} ${maxTexts.tooMuch}` :
                `${this.max_length - amount} ${maxTexts.remaining}`
            );
    }

    updateCounter(amount) {
        this.setState({
            isExceedingMax: amount > this.max_length,
            maxText: this.getMaxText(amount)
        });
    }

    render() {
        const { frm_id, name, Data_Groups, El_Data, tab, show_tools, parent_disabled, changeCurrentData } = this.props;
        const value = El_Data.value;
        const styling_type = El_Data.styling_type;
        const placeholder = El_Data.placeholder;
        const width = El_Data.width;
        const height = El_Data.height || 0;
        const responsive = El_Data.responsive === 'true';
        const readonly = El_Data.readonly === 'true';
        const width_type = El_Data.width_type;
        const rows = El_Data.rows || 0;
        const style = width_type === '100' ? { width: '100%' } : null;

        /*NOTE: Everything in-between the tags <textarea></textarea> will be placed in the actual textarea, including spaces*/

        const textareastyle = {};

        if (width_type === '100') {
            textareastyle.width = '100%';
        } else if (width_type === 'cus') {
            textareastyle.width = `${width || 300}px`;
        }
        if (height) {
            textareastyle.height = `${height}px`;
        } else if (!rows) {
            textareastyle.height = `${150}px`;
        }

        return (
            <Fragment>
                <div
                    className={`form_field_box${responsive ? ' txtr_responsive' : ' txtr'}`}
                    style={style}
                >
                    <textarea
                        id={buildFormElementID(frm_id, Data_Groups, name)}
                        name={name ? buildFormElementName(Data_Groups, name) : null}
                        placeholder={placeholder || null}
                        wrap="soft"
                        className={`fld_text${responsive ?
                            ' fld_text_responsive' :
                            (styling_type === 'quality' || styling_type === 'quality_big' ? ' quality' : '')
                            }${styling_type === 'quality_big' ? ' big' : ''
                            }${this.state.hasFocus ? ' fld_text_focus' : ''}`
                        }
                        style={Object.keys(textareastyle).length > 0 ? textareastyle : null}
                        rows={rows}
                        tabIndex={tab}
                        readOnly={show_tools || readonly}
                        value={value}
                        onChange={(e) => this.handleChange(e)}
                        onBlur={this.handleBlur.bind(this)}
                        onFocus={this.handleFocus.bind(this)}
                    />
                </div>
                <div className={
                    `form_field_box${responsive ? ` counter_responsive${styling_type === 'quality_big' ? ' big' : ''}` :
                        ' non'}`}>
                    <p
                        id={`${buildFormElementID(frm_id, Data_Groups, name)}_counter`}
                        className={`gp${responsive ? ` counter_text_responsive${['quality', 'quality_big'].includes(El_Data.styling_type) ?
                            ' fld_text_counter white quality' :
                            ''}` :
                            ''
                            }${this.state.isExceedingMax ? ' red_txt' : ''}`
                        }
                    >
                        {this.state.maxText}
                    </p>
                </div>
            </Fragment>
        );
    }
}
