export const convertBytesToFileSizeString = (bytes, type = 'long') => {
    //NOTE: values are "floored" to be on the safe side for the "maximum" allowed filesize
    bytes = parseInt(bytes, 10);
    let s = bytes;
    const Long_Sizes = [
        'byte',
        'kilobyte',
        'Megabyte',
        'Gigabyte',
        'Terabyte',
        'Petabyte',
        'Exabyte',
        'Zettabyte',
        'Yottabyte'
    ];

    const Short_Sizes = [
        'b',
        'kB',
        'MB',
        'GB',
        'TB',
        'PB',
        'EB',
        'ZB',
        'YB'
    ];

    (type === 'long' ? Long_Sizes : Short_Sizes).forEach((size, index) => {
        if (index === 0) {
            s = bytes + ' ' + size;
        } else {
            const p = index;
            if (bytes > Math.pow(1000, p) - 1) {
                const raw = bytes / Math.pow(1000, p);
                const decimal = raw < 100 ?
                    parseInt(raw.toString().split('.')[1][0], 10) :
                    0;

                s = Math.floor(raw) + (decimal ? ',' + decimal : '') + ' ' + size;
            }
        }
    });

    return s;
};