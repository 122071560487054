import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_id: '',
    New_Scope_Group: {},
    Updated_Scope_Group: {}
    /*
    A Scope_Group now consists out of:
    {
        idx: '',
        page_sub: false,
        pages_amount: 0,
        site_id: ''
    }
    but 'page_sub' should not be here, it's page_specific... there should be an array of subscriptions on the Page object.
    The Page Object itself should also have its own store (perhaps only when the user is signed in... ).
     */
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD':
            state.New_Scope_Group = action.New_Scope_Group;
            return state;
        case 'UPDATE':
            state.Updated_Scope_Group = action.Updated_Scope_Group;
            return state;
        case 'REMOVE':
            state.removed_id = action.removed_id;
            return state;
        default:
            return state;
    }
});

export default store;