import React, { Component } from 'react';
import ColumnType from '../column_type';
import { buildFormElementID } from '../../utils/form';

export default class Grid extends Component {
    render() {
        const {
            frm_id, Data_Groups, Function_Data, Action_Data, tab,
            El_Data, reloadForm, updateActionData, changeCurrentData, parent_disabled
        } = this.props;

        return (
            <table className="full_width"><tbody>
                {El_Data.Content
                    .filter(ContentItem => ContentItem.length > 0 && typeof (ContentItem) !== 'string')
                    .map((ContentItem, index) => {
                        const row_id = index > 0 && typeof (El_Data.Content[index - 1]) === 'string' ?
                            'row_' + buildFormElementID(frm_id, Data_Groups, El_Data.Content[index - 1]) :
                            null;

                        return (
                            <tr
                                key={index}
                                id={row_id}
                            >
                                {ContentItem
                                    .map((ContentColumnItem, jndex) =>
                                        <ColumnType
                                            key={jndex}
                                            Function_Data={Function_Data}
                                            Action_Data={Action_Data}
                                            frm_id={frm_id}
                                            Data_Groups={Data_Groups}
                                            tab={tab}
                                            Data={ContentColumnItem}
                                            reloadForm={reloadForm}
                                            updateActionData={updateActionData}
                                            changeCurrentData={changeCurrentData}
                                            parent_disabled={parent_disabled}
                                        />
                                    )
                                }
                            </tr>
                        );
                    })
                }
            </tbody></table>
        );
    }
}