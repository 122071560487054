import React, { Component } from 'react';
import { addJSLibrary } from '../utils/head';

export default class Map extends Component {
    constructor(props) {
        super(props);
        addJSLibrary('googlemaps', '//maps.googleapis.com/maps/api/js?key=AIzaSyDCbcLC6Y6eQwvc3vmS7OFIDNaTQhv_2C4'); // TODO: move key to props -> DB
    }

    componentDidMount() {
        const { project, site_id, el_type, el_idx, Element, Navigation_Data } = this.props;
        const { latitude, longitude, zoom, Map_Markers } = Element.El_Data;

        const loadMap = () => {
            var map = new google.maps.Map(document.getElementById([el_type, el_idx].join('_')), {
                center: { lat: (parseInt(latitude, 10) / 10000000), lng: (parseInt(longitude, 10) / 10000000) },
                zoom: parseInt(zoom, 10),
                disableDefaultUI: true,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, '']
                },
                mapTypeId: 'roadmap',
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }]
                    },
                    {
                        featureType: 'all',
                        elementType: 'all',
                        stylers: [
                            { saturation: -100 }
                        ]
                    }
                ]
            });
            var map_marker_settings, Map_Marker;
            for (var i = 0; i < Map_Markers.length; i++) {
                Map_Marker = Map_Markers[i];
                map_marker_settings = {
                    position: { lat: (parseInt(Map_Marker.latitude, 10) / 10000000), lng: (parseInt(Map_Marker.longitude, 10) / 10000000) },
                    map: map,
                    icon: {
                        url: Navigation_Data.base_url + 'projects/' + project + '/files/user/' + site_id + '/img/upload/' + Map_Marker.resource_id + '.' + Map_Marker.file_type
                    }
                };
                if (Map_Marker.custom_size === 'true') {
                    map_marker_settings.icon.scaledSize = new google.maps.Size(parseInt(Map_Marker.width, 10), parseInt(Map_Marker.height, 10));
                }
                new google.maps.Marker(map_marker_settings);
            }
        };

        const checkGoogleMapsLibraryLoaded = () => {
            if (typeof (google) === 'undefined') {
                setTimeout(() => {
                    checkGoogleMapsLibraryLoaded();
                }, 1000);
            } else {
                loadMap();
            }
        };

        checkGoogleMapsLibraryLoaded();
    }

    render() {
        const { el_type, el_idx, Corrections } = this.props;
        const style = Corrections && Corrections.full_height_correction ?
            { minHeight: Corrections.full_height_correction.height } :
            null;

        return (
            <div
                id={[el_type, el_idx].join('_')}
                style={style}
            />
        );
    }
}