import deepmerge from 'deepmerge';
import layer_store from '../../api/layer_store';
import scroll_zone_store from '../../api/scroll_zone_store';
import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class ScrollZoneManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [scroll_zone_store.subscribe(this.handleScrollZoneStoreChange(changeCurrentData, getCurrentData))];
    }

    handleScrollZoneStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = scroll_zone_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Scroll_Zones = clone(Current_Data.Scroll_Zones);

            if (state.action === 'add') {
                Changed_Scroll_Zones.push(state.New_Scroll_Zone);
            }
            if (state.action === 'update') {
                Changed_Scroll_Zones = Changed_Scroll_Zones.map(Scroll_Zone => {
                    if (parseInt(Scroll_Zone.id, 10) === parseInt(state.Updated_Scroll_Zone.id, 10)) {
                        return state.Updated_Scroll_Zone;
                    }
                    return Scroll_Zone;
                });
            }
            if (state.action === 'remove') {
                const index = findIndexArray(
                    Changed_Scroll_Zones,
                    Scroll_Zone => parseInt(Scroll_Zone.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Scroll_Zones.splice(index, 1);
            }

            changeCurrentData({ Scroll_Zones: Changed_Scroll_Zones });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Scroll_Zones
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text' },
                    { text: 'Scroll zone', style: 'subtitle' }
                ],
                [
                    { type: 'text' },
                    {
                        text:
                            'A scroll zone can be used to hide/show elements based on the current <br>' +
                            'browser window scroll position. Per element one can specify to show <br>' +
                            'it either regardless of the scroll position, or only when the scroll <br>' +
                            'position is within a specific scroll zone.<br>',
                        style: 'normal_grey'
                    }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'scroll_zone_management', id: 'scroll_zone_id' },
                    { Scroll_Zones: Current_Data.Scroll_Zones }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'add_new_scroll_zone',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [114, 16],
                        text: 'Add Scroll Zone',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_scroll_zone_panel',
                                            frm_name: 'scroll_zone_settings',
                                            scroll_zone_id: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'create'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}