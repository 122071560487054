import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_idx: '',
    replacement_idx: ''
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'REMOVE':
            state.removed_idx = action.removed_idx;
            state.replacement_idx = action.replacement_idx;
            return state;
        default:
            return state;
    }
});

export default store;