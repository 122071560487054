import deepmerge from 'deepmerge';
import form_data_store from '../../../api/form_data_store';
import layer_store from '../../../api/layer_store';
import { getImageURL } from '../../../form/utils';

export default class Background {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const Current_Data = deepmerge(
            Load_Data,
            {
                Background: Action_Data.data_action_type === 'create' || Load_Data.Mod === 0 ?
                    {
                        id: '',
                        opacity: 100,
                        type: 'color',
                        color: 'ffffff',
                        color_residu: 'true',
                        resource_id: 0,
                        gradient_idx: 0,
                        fit: 'c',
                        align: 'cc'
                    } :
                    Load_Data.Mod
            },
        );

        Current_Data.Gradients = Current_Data.Gradients ? Current_Data.Gradients.map(Gradient => {
            Gradient.idx = parseInt(Gradient.idx, 10);
            return Gradient;
        }) : [];

        Current_Data.Selected_Gradient = Current_Data.Gradients ? (
            Current_Data.Gradients.find(Gradient => Gradient.idx === parseInt(Current_Data.Background.gradient_idx, 10)) ||
            Current_Data.Gradients[0]
        ) : null;

        Current_Data.Background.gradient_idx = Current_Data.Selected_Gradient ? Current_Data.Selected_Gradient.idx : 0;

        return Current_Data;
    }

    getQueryFragment(Action_Data) {
        return `Mod {
            id
            opacity
            type
            color
            color_residu
            resource_id
            gradient_idx
            fit
            align
            file_type
        }`;
    }

    checkModule(Current_Data) {
        if (Current_Data.Background.type === 'gradient') {
            const gradient_idx = parseInt(Current_Data.Background.gradient_idx, 10);
            return isNaN(gradient_idx) || gradient_idx === 0 ?
                'Cannot proceed, because no valid gradient was selected.' :
                '';
        }
        if (['image', 'tile'].includes(Current_Data.Background.type)) {
            const resource_id = parseInt(Current_Data.Background.resource_id, 10);
            return isNaN(resource_id) || resource_id === 0 ?
                'Cannot proceed, because no image was selected.' :
                '';
        }

        return '';
    }

    getQuery(Action_Data) {
        return null;
        /*return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
            ` + this.getQueryFragment(Action_Data) + `
            }`;*/
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        const { Gradients, Selected_Gradient } = Current_Data;
        const { type, opacity, gradient_idx, resource_id, file_type, fit, color_residu, color, align } = Current_Data.Background;

        return [
            [
                { type: 'image_picker', id: 'type', colspan: 2 },
                {
                    value: type,
                    Options: [
                        { label: 'Color', filename: 'projects/rootflex/img/form/bg_type.png', value: 'color', left: 0, top: 0 },
                        { label: 'Gradient', filename: 'projects/rootflex/img/form/bg_type.png', value: 'gradient', left: 49, top: 0 },
                        { label: 'Image', filename: 'projects/rootflex/img/form/bg_type.png', value: 'image', left: 0, top: 49 },
                        { label: 'Tiles', filename: 'projects/rootflex/img/form/bg_type.png', value: 'tile', left: 49, top: 49 }
                    ],
                    width: 49,
                    height: 49,
                    column_amount_max: 4,
                }
            ],
            [
                {
                    type: ['color', 'image', 'tile'].includes(type) ? 'number' : 'hidden',
                    id: 'opacity',
                    label: 'Opacity'
                },
                { value: opacity, min_val: 0, max_val: 100, unit: '%' }
            ],
            [
                {
                    type: type === 'gradient' ? 'dropdown' : 'hidden',
                    id: 'gradient_idx',
                    label: 'Gradient'
                },
                {
                    value: gradient_idx,
                    Options: Gradients ? Gradients.map(Gradient => [Gradient.idx, Gradient.name]) : [],
                    width: 260,
                    onChange: value => ({ Selected_Gradient: Gradients.find(Gradient => Gradient.idx === value) })
                }
            ],
            [
                { type: type === 'gradient' ? 'row' : 'hidden', id: 'gradient_1', label: ' ' },
                {
                    Content: [
                        [
                            {
                                type: 'button_image',
                                id: 'manage_gradients',
                                label: ' ',
                                h_align: 'left',
                                v_align: 'center',
                                padding_right: 10
                            },
                            {
                                name: 'open_select_panel',
                                button_style: [115, 35, 2],
                                text: 'Manage',
                                img_url: getImageURL('rootflex', 'manage_gradient'),
                                img_style: [0, 40, 32, 0],
                                onClick: () => {
                                    layer_store.dispatch({
                                        type: 'OPEN_PANEL',
                                        panel_data: {
                                            Function_Data,
                                            Action_Data: deepmerge(
                                                Action_Data,
                                                {
                                                    access: 'authorized',
                                                    panel_type: 'manage_gradients_panel',
                                                    frm_name: 'gradient_management',
                                                    data_action_type: 'manage'
                                                }
                                            )
                                        },
                                    });
                                }
                            }
                        ],
                        [
                            {
                                type: 'gradient',
                                id: 'gradient_preview',
                                label: ' ',
                                h_align: 'center',
                                padding_top: 3,
                                padding_bottom: 3
                            },
                            {
                                Data: Selected_Gradient,
                                width: 180,
                                height: 180,
                                show_transparency_background: true
                            }
                        ]
                    ]
                }
            ],
            [
                { type: ['image', 'tile'].includes(type) ? 'row' : 'hidden', id: 'image_1', label: ' ' },
                {
                    Content: [
                        [
                            {
                                type: 'button_image',
                                id: 'select_image',
                                label: ' ',
                                h_align: 'left',
                                v_align: 'center',
                                padding_right: 5
                            },
                            {
                                name: 'open_select_panel',
                                button_style: [115, 35, 2],
                                text: 'Select',
                                img_url: getImageURL('rootflex', 'upload_new'),
                                img_style: [0, 40, 32, 0],
                                onClick: () => {
                                    layer_store.dispatch({
                                        type: 'OPEN_PANEL',
                                        panel_data: {
                                            Function_Data,
                                            Action_Data: deepmerge(
                                                Action_Data,
                                                {
                                                    access: 'authorized',
                                                    panel_type: 'background_switch_upload_panel',
                                                    frm_name: 'image_select',
                                                    resource_id: parseInt(resource_id, 10),
                                                    data_action_type: 'select',
                                                    amount: 15,
                                                    offset: 0
                                                }
                                            )
                                        },
                                    });
                                }
                            }
                        ],
                        [
                            {
                                type: 'image',
                                id: 'thumbnail',
                                colspan: 2,
                                h_align: 'center',
                                padding_top: 3,
                                padding_bottom: 3
                            },
                            {
                                src: isNaN(parseInt(resource_id), 10) ||
                                    parseInt(resource_id, 10) === 0 ?
                                    'projects/rootflex/img/form/none_selected.jpg' :
                                    'projects/' + Function_Data.project + '/cache/' +
                                    (Function_Data.use_service ? 'service' : 'user') +
                                    '/' + Function_Data.site_id + '/img/w150h100/' +
                                    resource_id + '.' + file_type,
                                show_transparency_background: true
                            }
                        ]
                    ]
                }
            ],
            [
                {
                    type: type === 'image' && ['o', 'e', 'w', 'h'].includes(fit) ?
                        'checkbox' :
                        'hidden',
                    id: 'color_residu',
                    label: 'Color residu'
                },
                { value: color_residu, styling_type: 'switch' }
            ],
            [
                {
                    type: type === 'color' ||
                        (type === 'image' && ['o', 'e', 'w', 'h'].includes(fit)) ?
                        'color' :
                        'hidden',
                    id: 'color',
                    label: 'Color'
                },
                { value: color }
            ],
            [
                {
                    type: type === 'image' ?
                        'dropdown' :
                        'hidden',
                    id: 'fit',
                    label: 'How to fit'
                },
                {
                    value: fit,
                    Options: [
                        ['o', 'Original size, residu cut or colored'],
                        ['s', 'Stretch, not origin aspect ratio'],
                        ['c', 'Fit either width or height, residu cut'],
                        ['e', 'Fit both width & height, residu colored'],
                        ['w', 'Fit width, residu cut or colored'],
                        ['h', 'Fit height, residu cut or colored']
                    ],
                    width: 260
                }
            ],
            [
                {
                    type: type === 'image' && ['o', 'c', 'e', 'w', 'h'].includes(fit) ?
                        'image_picker' :
                        'hidden',
                    id: 'align',
                    label: ' ',
                    colspan: 2
                },
                {
                    value: align,
                    Options: [
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'lt' },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'ct', left: 100 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'rt', left: 200 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'lc', top: 61 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'cc', top: 61, left: 100 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'rc', top: 61, left: 200 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'lb', top: 122 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'cb', top: 122, left: 100 },
                        { filename: 'projects/rootflex/img/form/img_map.png', value: 'rb', top: 122, left: 200 }
                    ],
                    width: 100,
                    height: 61,
                    column_amount_max: 3
                }
            ]
        ];
    }

    getSubmitEvent(frm_id, submit_action, Function_Data, Action_Data, frm_id_int, Current_Data, updateActionData) {
        return holder_type => {
            const error_message = this.checkModule ? this.checkModule(Current_Data) : '';

            if (error_message) {
                alert(error_message);
            } else if (Action_Data.data_action_type === 'create' && Action_Data.el_type !== 'ground') {
                // TODO store form values to be used in later post-submit to back-end
                updateActionData({
                    access: 'authorized',
                    frm_name: 'border_settings'
                });
            } else {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        holder_type,
                        submit_action,
                        Function_Data,
                        process_tag: 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }
        };
    }
}