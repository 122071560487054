import deepmerge from 'deepmerge';
import form_data_store from '../api/form_data_store';
import layer_store from '../api/layer_store';
import tools_store from '../api/tools_store';

export const getSingleSubmitSet = (frm_id, submit_tag) => {
    return [
        {
            type: 'section',
            full_width: true,
            id: 'Submit_Buttons',
            h_align: 'center',
            v_align: 'top'
        },
        {
            Settings: getSubmitButtonsSectionSettings(),
            Content: [
                [
                    { type: 'button', id: 'submit', h_align: 'center', padding_left: 5 },
                    {
                        name: 'submit_action',
                        value: '',
                        tag: submit_tag || 'Close',
                        color: 'green',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'CLOSE_PANEL',
                            });

                            form_data_store.dispatch({
                                type: 'DISCARD',
                                frm_id
                            });
                        }
                    }
                ]
            ]
        }
    ];
};

export const getDoubleSubmitSet = (frm_id, submitEvent, submit_tag, holder_type) => {
    return [
        {
            type: 'section',
            full_width: true,
            id: 'Submit_Buttons',
            h_align: 'center',
            v_align: 'top'
        },
        {
            Settings: getSubmitButtonsSectionSettings(),
            Content: [[
                { type: 'row' },
                {
                    Content: [
                        [
                            {
                                type: 'button',
                                id: 'submit',
                                h_align: 'center',
                                padding_right: 5,
                                width: '50%'
                            },
                            {
                                name: 'submit_action',
                                value: '',
                                tag: submit_tag || 'Save',
                                color: 'green',
                                onClick: () => {
                                    if (typeof (submitEvent) === 'function') {
                                        submitEvent(holder_type);
                                    }
                                }
                            }
                        ],
                        [
                            {
                                type: 'button',
                                id: 'cancel',
                                h_align: 'center',
                                padding_left: 5,
                                width: '50%'
                            },
                            {
                                name: 'submit_action',
                                value: '',
                                tag: 'Cancel',
                                color: 'red',
                                onClick: () => {
                                    //TODO: in case the form was preceded by other forms (e.g. "Next" button) those have to be discard as well
                                    form_data_store.dispatch({
                                        type: 'DISCARD',
                                        frm_id
                                    });

                                    if (holder_type && holder_type === 'overlay') {
                                        tools_store.dispatch({
                                            type: 'CLOSE_OVERLAY_TOOLS',
                                        });
                                    } else {
                                        layer_store.dispatch({
                                            type: 'CLOSE_PANEL',
                                        });
                                    }
                                }
                            }
                        ]
                    ]
                }
            ]]
        }
    ];
};

const getSubmitButtonsSectionSettings = () => {
    return {
        bg_color: 'f0f0f0',
        border_top: [1, 'B1B1B1'],
        padding_left: 8,
        padding_right: 8,
        padding_top: 5,
        padding_bottom: 8,
        corner_br: 5
    };
};

export const wrapSectionSubs = (data_group, Section_Subs, Special_Settings, readonly, readonly_alert_text) => {
    return [
        {
            type: 'section',
            id: data_group,
            shrinkable: true,
            full_width: true//OK?
        },
        {
            Settings: deepmerge(
                {
                    padding_left: 10,
                    padding_right: 31,
                    padding_top: 10,
                    padding_bottom: 10
                },
                (Special_Settings || {})
            ),
            Content: Section_Subs,
            readonly: readonly,
            readonly_alert_text: readonly_alert_text
        }
    ];
};

export const getImageURL = (project, image_type) => {
    return (project === 'shared' ? '' : 'projects/') +
        project + '/img/form/' + image_type + '.png';
};