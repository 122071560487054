import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';
import Color from './color';
import Number from './number';
import { clone } from '../../utils/object';

export default class ShadowEditor extends Component {
    constructor(props) {
        super(props);
        const { frm_id, Data_Groups, fld_id } = props;
        this.id = buildFormElementID(frm_id, Data_Groups, fld_id);
    }

    onClickAddShadowRow() {
        const { El_Data, changeCurrentData } = this.props;

        changeCurrentData({
            Active_Text_Style: {
                Shadows: El_Data.Shadows.concat([{
                    id: '',//neccesary?
                    color: 'ffffff',
                    opacity: '100',
                    fuzziness: '0',
                    right: '0',
                    bottom: '0'
                }])
            }
        });
    }

    onClickDeleteShadowRow(shadow_index) {
        const { El_Data, changeCurrentData } = this.props;

        return () => {
            const Shadows = clone(El_Data.Shadows);
            Shadows.splice((shadow_index - 1), 1);

            changeCurrentData({
                Active_Text_Style: {
                    Shadows: Shadows
                }
            });
        };
    }

    renderShadowRow(Shadow, index) {
        const { frm_id, Data_Groups, tab, parent_disabled } = this.props;
        const shadow_index = index + 1;

        return (
            <tr
                key={index}
                className="shadow_row"
            >
                <td>
                    <p
                        className="gp"
                        style={{ color: '#000', paddingLeft: '8px', fontWeight: 'bold' }}
                    >
                        {shadow_index}
                    </p>
                </td>
                <td>
                    <Color
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'color_' + shadow_index}
                        tab={tab}
                        El_Data={{ value: Shadow.color }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td>
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'opacity_' + shadow_index}
                        tab={tab}
                        El_Data={{ value: Shadow.opacity, min_val: 0, max_val: 100, unit: '%' }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td>
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'fuzziness_' + shadow_index}
                        tab={tab}
                        El_Data={{ value: Shadow.fuzziness, min_val: 0, max_val: 999, unit: '%' }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td>
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'right_' + shadow_index}
                        tab={tab}
                        El_Data={{ value: Shadow.right, min_val: -999, max_val: 999, unit: '%' }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td>
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'bottom_' + shadow_index}
                        tab={tab}
                        El_Data={{ value: Shadow.bottom, min_val: -999, max_val: 999, unit: '%' }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td style={{ paddingLeft: '2px', paddingRight: '10px' }}>
                    <div
                        className="delete delete_shadow"
                        style={{ float: 'left', width: '16px', height: '16px', cursor: !parent_disabled ? 'pointer' : '' }}
                        onClick={!parent_disabled ? this.onClickDeleteShadowRow(shadow_index) : null}
                    />
                </td>
            </tr>
        );
    }

    changeCurrentData_Customized(New_Data) {
        //TODO: try to get rid of this
        const { El_Data, changeCurrentData } = this.props;
        const { Shadows } = El_Data;
        const fieldAndIndex = Object.keys(New_Data.Active_Text_Style)[0];
        const splitIndex = fieldAndIndex.lastIndexOf('_');
        const shadow_index = parseInt(fieldAndIndex.substr(splitIndex + 1, 10) - 1);
        const field = fieldAndIndex.substr(0, splitIndex);
        const value = New_Data.Active_Text_Style[fieldAndIndex];

        Shadows[shadow_index][field] = value;
        changeCurrentData({
            Active_Text_Style: {
                Shadows: Shadows
            }
        }
        );
    }

    render() {
        const { El_Data, parent_disabled } = this.props;
        return (
            <div
                id={this.id + '_shadow_list'}
                style={{ float: 'left', paddingLeft: '4px' }}
            >
                <table style={{ border: '1px solid #999' }}>
                    <thead>
                        <tr style={{ textAlign: 'left' }}>
                            {[
                                [25, '#'],
                                [50, 'Color'],
                                [110, 'Opacity'],
                                [110, 'Fuzziness'],
                                [110, 'Right'],
                                [110, 'Bottom'],
                            ].map((Head, index) =>
                                <th
                                    key={index}
                                    style={{ width: Head[0] + 'px' }}
                                >
                                    <p
                                        className="gp"
                                        style={{ color: '#000', paddingLeft: '8px', fontWeight: 'bold' }}
                                    >
                                        {Head[1]}
                                    </p>
                                </th>
                            )}
                            <th></th>
                        </tr>
                    </thead><tbody>
                        {El_Data.Shadows.map((Shadow, index) =>
                            this.renderShadowRow(Shadow, index)
                        )}
                        <tr className="add_shadow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                                id={this.id + '_add_shadow'}
                                style={{ padding: '5px' }}
                            >
                                <div
                                    className="add"
                                    style={{ float: 'left', width: '16px', height: '16px', cursor: 'pointer' }}
                                    onClick={!parent_disabled ? this.onClickAddShadowRow.bind(this) : null}
                                >
                                </div>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
        );
    }
}