import React, { Component, Fragment } from 'react';
import { buildURL } from '../utils/path';
import { formatStringLetters } from '../utils/string';

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Item_Visibilities: this.buildStuff([], props.Element.El_Data.Pages, 'visibility'),
            Item_Hovers: this.buildStuff([], props.Element.El_Data.Pages, 'hover')
        };
        this.delay = 500;
        this.menu_hide_timer = null;
        this.currentHover = null;
    }

    componentWillUnmount() {
        clearTimeout(this.menu_hide_timer);
    }

    buildStuff(Indexes, Pages, type) {
        let Settings = {};

        for (var i = 0; i < Pages.length; i++) {
            Settings[this.getId(Indexes.concat(i))] = type === 'hover' || Indexes.length > 0 ? false : true;
            if (Pages[i].Sub_Pages) {
                Settings = Object.assign({}, Settings, this.buildStuff(Indexes.concat(i), Pages[i].Sub_Pages, type));
            }
        }

        return Settings;
    }

    checkActivePageInTree(page_id, Page) {
        return parseInt(page_id) === parseInt(Page.page_id) ?
            true :
            (Page.Sub_Pages ?
                Page.Sub_Pages.filter(Sub_Page => this.checkActivePageInTree(page_id, Sub_Page)).length > 0 :
                false
            );
    }

    buildMenu(depth, Pages, Parent_Position, Indexes) {
        const { page_id, Element } = this.props;
        const { El_Data } = Element;
        const { Menu_Items } = El_Data;
        const Els = Pages.map((Page, index) => {
            const Current_Indexes = Indexes.concat([index]);

            const direction = depth > 0 ? 'ver' : El_Data.direction;
            const total_width = this.determineBiggestSize(Menu_Items[depth], 'hor');
            const total_height = this.determineBiggestSize(Menu_Items[depth], 'ver');
            const last_index = Current_Indexes[Current_Indexes.length - 1];
            const top = (direction === 'ver' ? (last_index * total_height) : 0) +
                Parent_Position.top;
            const left = (direction === 'hor' ? (last_index * total_width) : 0) +
                Parent_Position.left;

            return [
                this.buildMenuItemHTML(
                    depth,
                    this.checkActivePageInTree(page_id, Page),
                    Current_Indexes,
                    top,
                    left,
                    Page.Page_URLs,
                    Page.title,
                    El_Data.letter_format
                )
            ].concat(
                Page.Sub_Pages && parseInt(El_Data.depth) > depth + 1 ?
                    this.buildMenu(
                        depth + 1,
                        Page.Sub_Pages,
                        {
                            top: (direction === 'hor' ? total_height : 0) + top,
                            left: (direction === 'ver' ? total_width : 0) + left
                        },
                        Current_Indexes
                    ) : []
            );
        }).reduce((a, b) => a.concat(b), []);

        return Els;
    }

    determineBiggestSize(Menu_Set, direction) {
        const { out, over, active } = Menu_Set;
        return Math.max(
            this.getSize(out, direction),
            this.getSize(over, direction),
            this.getSize(active, direction)
        );
    }

    getSize(type, direction) {
        return this.getLayoutSize(type, direction) + this.getBorderSize(type, direction);
    }

    getLayoutSize(Menu_Item, direction) {
        const { Layout_Box } = Menu_Item;
        const {
            width_pixel, height_pixel, space_outside_hor_pixel_1,
            space_outside_hor_pixel_2, space_outside_ver_pixel_1,
            space_outside_ver_pixel_2
        } = Layout_Box;
        return direction === 'hor' ?
            parseInt(width_pixel) + parseInt(space_outside_hor_pixel_1) + parseInt(space_outside_hor_pixel_2) :
            parseInt(height_pixel) + parseInt(space_outside_ver_pixel_1) + parseInt(space_outside_ver_pixel_2);
    }

    getBorderSize(Menu_Item, direction) {
        const { Border } = Menu_Item;
        const { symmetric, size_all, size_left, size_right, size_top, size_bottom } = Border;
        return Border ? (symmetric === 'true' ?
            2 * parseInt(size_all) :
            (
                direction === 'hor' ?
                    parseInt(size_left) + parseInt(size_right) :
                    parseInt(size_top) + parseInt(size_bottom)
            )) : 0;
    }

    buildMenuItemHTML(depth, is_active, Indexes, top, left, Page_URLs, title, letter_format) {
        const { el_idx, Navigation_Data, show_tools, navigate } = this.props;
        const { default_language_type, language_type, device_type, base_url, site_url } = Navigation_Data;
        const href = buildURL(
            default_language_type,
            language_type,
            device_type,
            base_url,
            site_url,
            Page_URLs
        );
        const clickBehavior = (e) => {
            e.preventDefault();
            navigate(
                default_language_type, language_type, device_type, base_url,
                site_url, Page_URLs
            );
        };

        const style = {
            top: top + 'px',
            left: left + 'px',
            display: this.state.Item_Visibilities[this.getId(Indexes)] ? 'block' : 'none'
        };

        const is_hover = this.state.Item_Hovers[this.getId(Indexes)];

        const El = <div
            id={'e' + el_idx + '_' + Indexes.join('_') + 'o'}
            className={'m' + el_idx + 'b' + depth + (is_active ? 'active' : (is_hover ? 'over' : 'out'))}
            onMouseOver={this.onMouseOver.bind(this, Indexes)}
            onMouseOut={this.onMouseOut.bind(this, Indexes)}
            style={style}>
            <div className={'m' + el_idx + 't' + depth + (is_active ? 'active' : (is_hover ? 'over' : 'out'))}>
                {formatStringLetters(title, letter_format)}
            </div>
        </div>;

        return (
            <Fragment key={el_idx + '_' + Indexes.join('_')}>
                {!show_tools ?
                    <a
                        href={href}
                        target="_self"
                        onClick={clickBehavior}
                    >
                        {El}
                    </a> :
                    El
                }
            </Fragment>
        );
    }

    onMouseOver(Indexes) {
        clearTimeout(this.menu_hide_timer);

        if (
            this.currentHover &&
            (
                this.currentHover.Indexes.length !== Indexes.length ||
                this.currentHover.Indexes[this.currentHover.Indexes.length - 1] !== Indexes[Indexes.length - 1]
            )
        ) {
            this.tryHideSelectively(Indexes);
        }

        this.currentHover = {
            Indexes: Indexes
        };

        if (!this.isSelected(this.getItem(Indexes))) {
            let Item_Hovers = this.state.Item_Hovers;
            Item_Hovers[this.getId(Indexes)] = true;
            this.setState({ Item_Hovers: Item_Hovers });
        }
        this.showChildrenOfItem(Indexes);
    }

    onMouseOut(Indexes) {
        if (!this.isSelected(this.getItem(Indexes))) {
            let Item_Hovers = this.state.Item_Hovers;
            Item_Hovers[this.getId(Indexes)] = false;
            this.setState({ Item_Hovers: Item_Hovers });
        }

        const that = this;
        this.menu_hide_timer = setTimeout(
            () => {
                that.tryHide(Indexes);
            },
            that.delay
        );
    }

    getId(Indexes) {
        return Indexes.join('_');
    }

    getItem(Indexes) {
        const { Element } = this.props;
        const { Pages } = Element.El_Data;

        return [Pages].concat(Indexes).reduce((accumulator, currentValue, currentIndex) => {
            return currentIndex === 1 ?
                accumulator[currentValue] :
                accumulator.Sub_Pages[currentValue];
        });
    }

    tryHide(Indexes) {
        this.hideChildrenOfItem(Indexes);
        if (Indexes.length > 1) {
            for (var i = 0; i < Indexes.length; i++) {
                this.hideChildrenOfItem(Indexes.slice(0, Indexes.length - i - 1));
            }
        }
        this.currentHover = null;
    }

    tryHideSelectively(Indexes) {
        for (var i = 0; i < this.currentHover.Indexes.length; i++) {
            if (typeof (Indexes[this.currentHover.Indexes.length - i - 1]) === 'undefined' || this.currentHover.Indexes[this.currentHover.Indexes.length - i - 1] !== Indexes[this.currentHover.Indexes.length - i - 1]) {
                this.hideChildrenOfItem(this.currentHover.Indexes.slice(0, this.currentHover.Indexes.length - i));
            }
        }
    }

    hideChildrenOfItem(Indexes) {
        var Item = this.getItem(Indexes);
        if (Item.Sub_Pages) {
            let Item_Visibilities = this.state.Item_Visibilities;
            for (var i = 0; i < Item.Sub_Pages.length; i++) {
                Item_Visibilities[this.getId(Indexes.concat(i))] = false;
            }
            this.setState({ Item_Visibilities: Item_Visibilities });
        }
    }

    showChildrenOfItem(Indexes) {
        var Item = this.getItem(Indexes);
        if (Item.Sub_Pages) {
            let Item_Visibilities = this.state.Item_Visibilities;
            for (var i = 0; i < Item.Sub_Pages.length; i++) {
                Item_Visibilities[this.getId(Indexes.concat(i))] = true;
            }
            this.setState({ Item_Visibilities: Item_Visibilities });
        }
    }

    hasSelectedChildPage(Page) {
        return Page.Sub_Pages && (
            Page.Sub_Pages.filter(
                Sub_Page => parseInt(Sub_Page.page_id) === parseInt(this.props.page_id)
            ).length > 0 ||
            Page.Sub_Pages.filter(
                Sub_Page => this.hasSelectedChildPage(Sub_Page)
            ).length > 0
        );
    }

    isSelected(Page) {
        return parseInt(Page.page_id) === parseInt(this.props.page_id) ||
            this.hasSelectedChildPage(Page);
    }

    render() {
        const { Pages } = this.props.Element.El_Data;

        return (
            <Fragment>
                {Pages.length > 0 &&
                    this.buildMenu(0, Pages, { top: 0, left: 0 }, [])
                }
            </Fragment>
        );
    }
}