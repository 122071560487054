import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';
import deepmerge from 'deepmerge';

export default class FormColTextareaSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Textarea: {
                    name: '',
                    colspan: 2,
                    label: '',
                    value: '',
                    placeholder: '',
                    styling_type: 'quality',
                    maxlength: 100,
                    required: 'false',
                    readonly: 'false',
                    responsive: 'false',
                    width_type: 'cus',
                    width: 200,
                    height: 200,
                    rows: 2,
                    language: 'english'
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Textarea {
                    name: String
                    colspan
                    label: String
                    value: String
                    placeholder: String
                    styling_type: String
                    maxlength
                    required: String
                    readonly: String
                    responsive: String
                    width_type: String
                    width
                    height
                    rows
                    language: String
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Textarea', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Textarea.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Textarea.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Textarea.label, max: 2 }
                ],
                [
                    { type: 'field', id: 'value', label: 'Initial value' },
                    { value: Current_Data.Form_Col_Textarea.value, max: 2 }
                ],
                [
                    { type: 'field', id: 'placeholder', label: 'Placeholder' },
                    { value: Current_Data.Form_Col_Textarea.placeholder, max: 2 }
                ],
                [
                    { type: 'dropdown', id: 'styling_type', label: 'Size' },
                    {
                        value: Current_Data.Form_Col_Textarea.styling_type,
                        Options: [
                            ['normal', 'Small'],
                            ['quality', 'Normal'],
                            ['quality_big', 'Big']
                        ]
                    }
                ],
                [
                    { type: 'number', id: 'maxlength', label: 'Maximum length' },
                    { value: Current_Data.Form_Col_Textarea.maxlength, min_val: 1, max_val: 10000 }
                ],
                [
                    { type: 'checkbox', id: 'required', label: 'Required' },
                    { value: Current_Data.Form_Col_Textarea.required, styling_type: 'switch' }
                ],
                [
                    { type: 'checkbox', id: 'readonly', label: 'Readonly' },
                    { value: Current_Data.Form_Col_Textarea.readonly, styling_type: 'switch' }
                ],
                [
                    { type: 'checkbox', id: 'responsive', label: 'Responsive' },
                    { value: Current_Data.Form_Col_Textarea.responsive, styling_type: 'switch' }
                ],
                [
                    { type: 'dropdown', id: 'width_type', label: 'Width type' },
                    {
                        value: Current_Data.Form_Col_Textarea.width_type,
                        Options: [
                            ['cus', 'Custom'],
                            ['100', 'Full']
                        ]
                    }
                ],
                [
                    {
                        type: Current_Data.Form_Col_Textarea.width_type === 'cus' ? 'number' : 'hidden',
                        id: 'width',
                        label: 'Width'
                    },
                    { value: Current_Data.Form_Col_Textarea.width, min_val: 50, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'checkbox', id: 'specific_height', label: 'Specific height' },
                    { value: (Current_Data.Form_Col_Textarea.height === null ? 'false' : 'true'), styling_type: 'switch' }
                ],
                [
                    {
                        type: Current_Data.Form_Col_Textarea.specific_height === 'true' ? 'number' : 'hidden',
                        id: 'height',
                        label: 'Height'
                    },
                    { value: (Current_Data.Form_Col_Textarea.height === null ? 50 : Current_Data.Form_Col_Textarea.height), min_val: 50, max_val: 999, unit: 'px' }
                ],
                [
                    {
                        type: Current_Data.Form_Col_Textarea.specific_height === 'false' ? 'number' : 'hidden',
                        id: 'rows',
                        label: 'Amount of rows'
                    },
                    { value: (Current_Data.Form_Col_Textarea.rows === null ? 2 : Current_Data.Form_Col_Textarea.rows), min_val: 2, max_val: 100 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_textarea_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}