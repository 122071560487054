import React, { Component } from 'react';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { clone } from '../../utils/object';
import { ucWords } from '../../utils/string';
import { renderButton, renderGrid, renderRow } from './management_grid';

export default class ScopeManagement extends Component {
    onClickEdit(Scope_Group) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'scope_settings_group_panel',
                    frm_name: 'scope_group_settings',
                    scope_group_id: Scope_Group.idx,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Scope_Group) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_scope_group_panel',
                    frm_name: 'remove_scope_group',
                    scope_group_id: Scope_Group.idx,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    onClickSubscribeButton(Scope_Group) {
        const { frm_id, Function_Data, changeCurrentData, El_Data } = this.props;

        return (e) => {
            changeCurrentData({
                scope_group_id: Scope_Group.idx
            });

            form_data_store.dispatch({
                type: 'SUBMIT',
                submitData: {
                    frm_id,
                    submit_action: Scope_Group.page_sub ? 'unsubscribe_page_from_scope_group' : 'subscribe_page_to_scope_group',
                    Function_Data,
                    process_tag: 'Saving...',
                    onSubmitted: isSuccesful => {
                        if (isSuccesful) {
                            changeCurrentData({
                                Scope_Groups: clone(El_Data.Scope_Groups).map(Scope_Group2 => {
                                    if (parseInt(Scope_Group2.idx, 10) === parseInt(Scope_Group.idx, 10)) {
                                        Scope_Group2.page_sub = Scope_Group.page_sub ? false : true;
                                        Scope_Group2.pages_amount = Scope_Group2.pages_amount + (Scope_Group.page_sub ? -1 : 1);
                                    }

                                    return Scope_Group2;
                                })
                            });
                        }
                    },
                    isPanelRequiredToStayOpen: true
                }
            });
        };
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab, changeCurrentData, Function_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        //TODO: check if this fix for respect of the old Forms_Post_Data is really neccesary:
        /*changeCurrentData({
            site_id: Function_Data.site_id,
            page_id: Function_Data.page_id
        });*/

        return El_Data.Scope_Groups.length === 0 ?
            (
                <p className="gp">No scope groups available.</p>
            ) :
            renderGrid(
                id + '_scope_group_list',
                [
                    { label: 'Group Name' },
                    { label: 'Pages Amount' },
                    { label: 'Current Page' },
                    { label: '' },
                    { label: '' }
                ],
                this.props.El_Data.Scope_Groups.map((Scope_Group, index) => {
                    return renderRow(
                        id + '_scope_group_row_' + Scope_Group.id,
                        index,
                        [
                            { type: 'text', value: ucWords(Scope_Group.name) },
                            { type: 'text', value: Scope_Group.pages_amount },
                            {
                                type: 'custom',
                                content: renderButton(
                                    (Scope_Group.page_sub ? 'unsubscribe_page_from_scope_group' : 'subscribe_page_to_scope_group'),
                                    (Scope_Group.page_sub ? 'Unsubscribe' : 'Subscribe'),
                                    this.onClickSubscribeButton(Scope_Group),
                                    [(Scope_Group.page_sub ? 95 : 78), 17], frm_id, Data_Groups, tab, index
                                )
                            },
                            {
                                type: 'action',
                                icon: 'wrench',
                                onClick: this.onClickEdit(Scope_Group)
                            },
                            {
                                type: (Scope_Group.pages_amount === 0 ? 'action' : 'text'),
                                value: '',
                                icon: 'delete',
                                onClick: this.onClickDelete(Scope_Group)
                            }
                        ]
                    );
                })
            );
    }
}