import crap_store from './crap_store';
import { getData } from './get_query';
import main_store from './main_store';

export const loadMainIntoCache = (Navigation_Data, callback) => {
    getData(Navigation_Data, 'PageQuery').subscribe(result => {
        callback(result.data);
        main_store.dispatch({
            type: 'CACHE_MAIN',
            data: result.data
        });
    });
};

export const loadCrapIntoCache = (Data, callback) => {
    getData(Data, 'DataQuery').subscribe(result => {
        callback(result.data);
        crap_store.dispatch({
            type: 'CACHE_CRAP',
            data: result.data
        });
    });
};

export const loadCrap2IntoCache = (Data, callback) => {
    getData(Data, 'DataQuery').subscribe(result => {
        callback(result.data);
        crap_store.dispatch({
            type: 'CACHE_CRAP2',
            data: result.data
        });
    });
};
