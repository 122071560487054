import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class SequenceSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Sequence: {
                    direction: 'left'
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Sequence {
                    direction: String
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Sequence', [
                [
                    { type: 'image_picker', id: 'direction', label: 'Direction', colspan: 2 },
                    {
                        value: Current_Data.Sequence.direction,
                        Options: [
                            { label: 'Left', filename: 'projects/rootflex/img/form/sequence_direction.png', value: 'left', left: 0 },
                            { label: 'Right', filename: 'projects/rootflex/img/form/sequence_direction.png', value: 'right', left: 39 }
                        ],
                        width: 55,
                        height: 39
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        access: 'authorized',
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_sequence_settings',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save')
        ];
    }
}