import React, { Component } from 'react';
import { buildFormElementID, buildFormElementName } from '../../utils/form';

export default class ButtonImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false
        };
    }

    onMouseOverButton() {
        this.setState({ isHovering: true });//Neccesary?
    }

    onMouseOutButton() {
        this.setState({ isHovering: false });//Neccesary?
    }

    onClickButton(e) {
        this.props.El_Data.onClick.call(this);
    }

    onChangeFilesHandler(e) {
        const { onChange } = this.props.El_Data;
        if (onChange) {
            onChange(e.target.files);
        }
    }

    render() {
        const { Function_Data, frm_id, Data_Groups, fld_id, tab, El_Data, parent_disabled } = this.props;

        const id = buildFormElementID(frm_id, Data_Groups, fld_id),
            onClick = El_Data.onClick,
            isDisabled = parent_disabled || El_Data.disabled || false;

        let total_width = 76 + 4, //default from css
            total_height = 39 + 5;//default from css

        const button_style = {};
        if (El_Data.button_style) {
            const b_s = El_Data.button_style;
            if (b_s[0]) {
                button_style.width = b_s[0] + 'px';
                total_width = b_s[0] + (2 * 1/*border_width*/);
            }
            if (b_s[1]) {
                button_style.height = b_s[1] + 'px';
                total_height = b_s[1] + (2 * 1/*border_height*/);
            }
            if (b_s[2]) {
                button_style.padding = b_s[2] + 'px';
                total_width += 2 * b_s[2];
                total_height += 2 * b_s[2];
            } else {
                if (b_s[6]) {
                    button_style.paddingLeft = b_s[6] + 'px';
                    total_width += b_s[6];
                }
                if (b_s[4]) {
                    button_style.paddingRight = b_s[4] + 'px';
                    total_width += b_s[4];
                }
                if (b_s[3]) {
                    button_style.paddingTop = b_s[3] + 'px';
                    total_height += b_s[3];
                }
                if (b_s[5]) {
                    button_style.paddingBottom = b_s[5] + 'px';
                    total_height += b_s[5];
                }
            }
        }

        const img_class = El_Data.img_style ? El_Data.img_style[0] : null;

        const img_style = {};
        if (El_Data.img_style) {
            const i_s = El_Data.img_style;

            if (i_s[1]) {
                img_style.width = i_s[1] + 'px';
            }
            if (i_s[2]) {
                img_style.height = i_s[2] + 'px';
            }
            if (El_Data.img_url) {
                img_style.backgroundImage = 'url(' + Function_Data.base_url + El_Data.img_url + ')';
            }
            if (i_s[3] || i_s[4]) {
                img_style.backgroundPosition = '-' + (i_s[3] || 0) + 'px -' + (i_s[4] || 0) + 'px';
            }
        }

        const className = 'clickable_input fld_input_invisible fld_pointer' +
            (isDisabled ? ' fld_input_disabled' : '');

        return (
            <div
                className="relative"
                style={{
                    width: total_width + 'px',
                    height: total_height + 'px'
                }}
                onMouseOver={!isDisabled && this.onMouseOverButton.bind(this)}
                onMouseOut={!isDisabled && this.onMouseOutButton.bind(this)}
                onClick={!isDisabled && onClick ? this.onClickButton.bind(this) : null}
            >
                {El_Data.file_upload ?
                    <input
                        type="file"
                        id={id}
                        name={buildFormElementName(Data_Groups, El_Data.name) + '[]'}
                        tabIndex={tab}
                        className="clickable_input fld_input_invisible fld_pointer"
                        style={{
                            width: total_width + 'px',
                            height: total_height + 'px'
                        }}
                        accept={El_Data.filetype_restriction ? El_Data.filetype_restriction : ''}
                        multiple={true}
                        onChange={!isDisabled ? this.onChangeFilesHandler.bind(this) : null}
                    /> :
                    <input
                        type="button"
                        id={id}
                        name={El_Data.name ? buildFormElementName(Data_Groups, El_Data.name, 'button') : null}
                        value=""
                        className={className}
                        style={{ width: total_width + 'px', height: total_height + 'px' }}
                        tabIndex={tab ? tab : null}
                        disabled={isDisabled}
                        onClick={onClick ? e => { e.preventDefault(); onClick(e); } : null}
                    />
                }
                <div
                    className={(El_Data.button_class || 'image_button') + (this.state.isHovering ? ' hover' : '')}
                    style={button_style}
                >
                    <div
                        className={img_class}
                        style={img_style}
                    ></div>
                    <p>
                        {El_Data.text || ''}
                    </p>
                </div>
            </div>
        );
    }
}