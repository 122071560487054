/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-key */
// @ts-nocheck
import deepmerge from 'deepmerge';
import crap_store from '../../../api/crap_store.js';
import form_data_store from '../../../api/form_data_store';
import layer_store from '../../../api/layer_store';
import { loadCrap2IntoCache } from '../../../api/main_service.js';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../../form/utils';
import { clone } from '../../../utils/object';
import { ucFirst, ucWords } from '../../../utils/string';

export default class ModForm {
    constructor(Form_Data, mod_type) {
        this.Form_Data = Form_Data;
        this.mod_type = mod_type;
        this.tools_section_id = `Mod_Tools_${ucWords(this.mod_type, true)}`;
        this.tools_variant_section_id = `${'Mod_Variant_Tools' + '_'}${ucWords(this.mod_type, true)}`;
        //TODO: move all properties on Current_Data that don't need to be send back to back-end to "this."
        // e.g. All_List, Preset_Values, Module_Variants, isPresetEditing, Presets_Options, All_Options
        this.data = null;

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.unsubscribes = [
            crap_store.subscribe(this.handleMainStoreChange.bind(this))
        ];
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        //const query = this.Form_Model.getQuery(Action_Data);
        loadCrap2IntoCache(Object.assign({}, Function_Data, Action_Data), result => {
            this.data = result;
            this.setState({
                isLoading: false
            }, () => {
            });
        });
    }

    componentWillUnmount() {
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleMainStoreChange() {
        const { action } = crap_store.getState();
        if (action === 'CACHE_CRAP2') {
            this.forceUpdate();
        }
    }

    refetch() {
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        loadCrap2IntoCache(Object.assign({}, Function_Data, Action_Data), result => {
            this.data = result;
            this.forceUpdate();
        });
    }

    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const { save_type, All_List, Preset_Values, Module_Variants, mod_idx } = Load_Data;
        const Mod_Load_Data = this.Form_Data.convertLoadDataToCurrentData(Load_Data, Action_Data);
        const isPresetIdxValid = this.isPresetIdxValid(mod_idx, Preset_Values);

        return deepmerge(
            Mod_Load_Data,
            {
                mod_type: this.mod_type,
                [this.tools_variant_section_id]: {
                    Module_Variants,
                    mod_variant_idx: '0'
                },
                [this.tools_section_id]: {
                    isPresetEditing: false,
                    save_type: save_type === 'preset' && !isPresetIdxValid ? 'none' : save_type,
                    preset_mod_idx: save_type === 'preset' && isPresetIdxValid ?
                        mod_idx :
                        (Preset_Values.length > 0 ? parseInt(Preset_Values[0].idx, 10) : ''),
                    preset_name: '',
                    Presets_Options: Preset_Values.map(Preset_Value => [Preset_Value.idx, Preset_Value.name]),
                    All_Options: All_List.map(All_ID => [All_ID.idx, `${ucWords(Action_Data.el_type, true)}: ${All_ID.idx}`]),
                    selected_mod_idx: All_List.length > 0 ? All_List[0].idx : '',
                },
                Default_Mod: Mod_Load_Data.Mod
            }
        );
    }

    isPresetIdxValid(mod_idx, Preset_Values) {
        return Preset_Values.map(Preset_Value => parseInt(Preset_Value.idx, 10)).includes(parseInt(mod_idx, 10));
    }

    getQuery(Action_Data) {
        return `query DataQuery($urls: String!) {
            mod_idx
            save_type
            Preset_Values
            All_List
            optional_module
            Module_Variants
            ${this.Form_Data.getQueryFragment(Action_Data)}
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate, holder_type) {
        //TODO: instead of calling a query (for getting All_Values, Preset_Values),
        // could also build some sort of "silent caching querying" after the main form-query on the mod...
        // That would also be a useful pattern for other forms and even for pages
        // (cache all pages, or cache pages one could directly navigate to from the active page)
        const query = this.Form_Data.getQuery(Action_Data);
        const { isLoading } = this.state;

        return [
            this.buildModuleVariantToolsFields(Current_Data, Function_Data, Action_Data, frm_id),
            this.buildModToolsFields(Current_Data, Function_Data, Action_Data, changeCurrentData, frm_id),
        ].concat(query !== null ?
            (!isLoading ? [
                { type: 'loadable' },
                this.renderModContent(
                    deepmerge(
                        Current_Data,
                        this.data,
                    ),
                    Function_Data, Action_Data, updateActionData, changeCurrentData, navigate,
                    frm_id, frm_id_int, holder_type
                )
            ] : []) :
            this.renderModContent(
                Current_Data, Function_Data, Action_Data, updateActionData, changeCurrentData,
                navigate, frm_id, frm_id_int, holder_type
            )
        );
    }

    renderModContent(Current_Data, Function_Data, Action_Data, updateActionData, changeCurrentData, navigate, frm_id, frm_id_int, holder_type) {
        const Content = this.Form_Data.getContent(
            Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate
        );
        const formSectionSubmitEvent = this.Form_Data.getSubmitEvent(
            frm_id, Action_Data.data_action_type === 'create' ? 'add_element' : `save_${this.mod_type}`,
            Function_Data, Action_Data, frm_id_int, Current_Data, updateActionData
        );
        const Mod_Tools = Current_Data[this.tools_section_id];
        const { save_type, isPresetEditing } = Mod_Tools;

        return [
            wrapSectionSubs(
                ucFirst(this.mod_type),
                save_type === 'none' ? [] : Content,
                {},
                (save_type === 'preset' && !isPresetEditing) || save_type === 'all',
                `You cannot edit here!\n${save_type === 'preset' ? 'To edit the preset -> click on "Edit".\n' : ''
                }${save_type === 'all' ? 'To convert this style to a preset -> click on "Add Preset".\n' : ''
                }To edit as a custom -> Switch to "Custom".`
            ),
            getDoubleSubmitSet(frm_id,
                formSectionSubmitEvent,
                Action_Data.data_action_type === 'create' ? 'Create' : 'Save',
                holder_type
            )
        ];
    }

    buildModuleVariantToolsFields(Current_Data, Function_Data, Action_Data, frm_id) {
        const Variant_Tools = Current_Data[this.tools_variant_section_id];
        const { Default_Mod, save_type, mod_idx, Preset_Values } = Current_Data;
        const { Module_Variants, mod_variant_idx } = Variant_Tools;

        return [
            { type: 'section', full_width: true, id: this.tools_variant_section_id },
            {
                Settings: {
                    bg_color: 'E0E0E0',
                    border_bottom: [1, 'B1B1B1'],
                    padding_left: 15,
                    padding_right: 15,
                    padding_top: 10,
                    padding_bottom: 10
                },
                Content: [
                    [
                        { type: 'text' },
                        { text: 'Conditional Style Variants', style: 'bold' }
                    ],
                    [
                        { type: Module_Variants.length > 0 ? 'radio' : 'hidden', id: 'mod_variant_idx', colspan: 2 },
                        {
                            value: mod_variant_idx,
                            Options: [
                                ['0', 'Default']
                            ].concat(Module_Variants.map(Module_Variant => [Module_Variant.idx, Module_Variant.name])),
                            width: 40,
                            height: 32,
                            direction: 'ver',
                            onChange: value => {
                                let preset_mod_idx = mod_idx,
                                    new_save_type = save_type,
                                    Mod = Default_Mod;

                                if (value !== '0') {
                                    const Variant = clone(Module_Variants.find(Module_Variant =>
                                        parseInt(Module_Variant.idx, 10) === parseInt(value, 10)
                                    ));
                                    preset_mod_idx = Variant.mod_idx;
                                    new_save_type = Variant.save_type;
                                    Mod = Variant.Mod ? Variant.Mod : Default_Mod;
                                }

                                const isPresetIdxValid = this.isPresetIdxValid(preset_mod_idx, Preset_Values);

                                return {
                                    [this.tools_section_id]: {
                                        isPresetEditing: false,
                                        preset_mod_idx: new_save_type === 'preset' && isPresetIdxValid ?
                                            preset_mod_idx :
                                            (Preset_Values.length > 0 ? parseInt(Preset_Values[0].idx, 10) : ''),
                                        save_type: new_save_type === 'preset' && !isPresetIdxValid ? 'none' : new_save_type,
                                    },
                                    [ucWords(this.mod_type)]: Mod
                                };
                            }
                        }
                    ],
                    [
                        {
                            type: 'icon_link',
                            id: 'add_module_variant',
                            h_align: 'left',
                            v_align: 'center',
                            padding_left: 10,
                            padding_right: 0,
                            padding_top: 5
                        },
                        {
                            Style: [114, 16],
                            text: 'Add Variant',
                            img_name: 'add',
                            onClick: () => {
                                layer_store.dispatch({
                                    type: 'OPEN_PANEL',
                                    panel_data: {
                                        Function_Data,
                                        Action_Data: deepmerge(
                                            Action_Data,
                                            {
                                                access: 'authorized',
                                                panel_type: 'module_variant_panel',
                                                frm_name: 'styling_module_variant_settings',
                                                new_mod_variant: true,
                                                mod_type: this.mod_type,
                                                parent_panel_type: Action_Data.panel_type,
                                                parent_frm_name: Action_Data.frm_name
                                            }
                                        )
                                    },
                                });
                            }
                        }
                    ]
                ]
            }
        ];
    }

    buildModToolsFields(Current_Data, Function_Data, Action_Data, changeCurrentData, frm_id) {
        const { Default_Mod, optional_module, Preset_Values } = Current_Data;
        const Mod_Tools = Current_Data[this.tools_section_id];
        const {
            save_type, isPresetEditing, preset_mod_idx, preset_name, Presets_Options,
            All_Options, selected_mod_idx
        } = Mod_Tools;

        return [
            { type: 'section', full_width: true, id: this.tools_section_id },
            {
                Settings: {
                    bg_color: 'f0f0f0',
                    border_bottom: [1, 'B1B1B1'],
                    padding_left: 15,
                    padding_right: 15,
                    padding_top: 10,
                    padding_bottom: 10
                },
                Content: [
                    [
                        { type: 'text' },
                        { text: 'Style Type', style: 'bold' }
                    ],
                    [
                        { type: 'row', id: 'module_view' },
                        {
                            Content: [
                                [
                                    { type: 'image_picker', id: 'save_type', colspan: 2 },
                                    {
                                        value: save_type,
                                        Options: []
                                            .concat(
                                                optional_module ?
                                                    [{ label: 'None', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'none', left: 0, top: 0 }] :
                                                    []
                                            ).concat([
                                                { label: 'Custom', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'custom', left: 41, top: 0 }
                                            ]).concat(Presets_Options.length ? [
                                                { label: 'Presets', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'preset', left: 81, top: 0 }
                                            ] : []).concat(All_Options.length ? [
                                                { label: 'Others', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'all', left: 322, top: 0 }
                                            ] : []),
                                        width: 40,
                                        height: 32,
                                        column_amount_max: 4,
                                        onChange: value => {
                                            const mod_idx = value === 'preset' &&
                                                !this.isPresetIdxValid(preset_mod_idx, Preset_Values) ?
                                                parseInt(Preset_Values[0].idx, 10) :
                                                preset_mod_idx;

                                            const Mod = value === 'preset' ?
                                                Preset_Values.find(Preset_Value =>
                                                    parseInt(Preset_Value.idx, 10) === parseInt(mod_idx, 10)
                                                ) :
                                                (value === 'all' ?
                                                    Default_Mod : //TODO find in all values.
                                                    Default_Mod
                                                );

                                            const New_Data = {
                                                [this.tools_section_id]: {
                                                    isPresetEditing: false,
                                                    preset_mod_idx: mod_idx,
                                                }
                                            };

                                            return ['preset', 'all'].includes(value) ?
                                                deepmerge(New_Data, {
                                                    [ucWords(this.mod_type)]: Mod
                                                }) :
                                                New_Data;
                                        }
                                    }
                                ],
                                [
                                    {
                                        type: save_type === 'custom' && (
                                            !this.Form_Data.checkModule || !this.Form_Data.checkModule(Current_Data)
                                        ) ? 'button_image' : 'hidden',
                                        id: 'add_preset',
                                        h_align: 'right', v_align: 'center', padding_left: 5
                                    },
                                    {
                                        name: 'open_add_preset_panel',
                                        button_style: [136, 33, 2],
                                        text: 'Add Preset',
                                        img_url: getImageURL('rootflex', 'custom_preset_all'),
                                        img_style: [0, 32, 32, 125],
                                        onClick: () => {
                                            // TODO: refetch after addition?
                                            layer_store.dispatch({
                                                type: 'OPEN_PANEL',
                                                panel_data: {
                                                    Function_Data,
                                                    Action_Data: deepmerge(
                                                        Action_Data,
                                                        {
                                                            access: 'authorized',
                                                            panel_type: 'preset_settings_panel',
                                                            frm_name: 'preset_settings',
                                                            parent_frm_id: frm_id,
                                                            mod_type: this.mod_type
                                                        }
                                                    )
                                                },
                                            });
                                        }
                                    }
                                ]
                            ]
                        }
                    ],
                    [
                        { type: save_type === 'preset' ? 'row' : 'hidden' },
                        {
                            Content: [
                                [
                                    { type: isPresetEditing ? 'field' : 'hidden', id: 'preset_name', label: 'Preset name' },
                                    { value: preset_name }
                                ],
                                [
                                    {
                                        type: isPresetEditing ? 'button_image' : 'hidden',
                                        id: 'save_preset',
                                        h_align: 'right',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'save_preset',
                                        button_style: [79, 33, 2],
                                        text: 'Save',
                                        img_url: getImageURL('rootflex', 'custom_preset_all'),
                                        img_style: [0, 32, 32, 249],
                                        onClick: () => {
                                            const error_message = this.Form_Data.checkModule ?
                                                this.Form_Data.checkModule(Current_Data) :
                                                '';

                                            if (error_message) {
                                                alert(error_message);
                                            } else {
                                                form_data_store.dispatch({
                                                    type: 'SUBMIT',
                                                    submitData: {
                                                        frm_id,
                                                        submit_action: 'save_preset',
                                                        Function_Data,
                                                        process_tag: 'Saving...',
                                                        onSubmitted: Result_Data => {
                                                            const New_Preset_Values = Preset_Values.concat(Result[ucWords(this.mod_type)]);
                                                            changeCurrentData({
                                                                [this.tools_section_id]: {
                                                                    isPresetEditing: false,
                                                                    preset_mod_idx: Result.mod_idx,
                                                                    Preset_Values: New_Preset_Values,
                                                                    Presets_Options: New_Preset_Values.map(Preset_Value => [Preset_Value.idx, Preset_Value.name]),
                                                                }
                                                            });
                                                        },
                                                        isPanelRequiredToStayOpen: true
                                                    }
                                                });
                                            }
                                        }
                                    }
                                ],
                                [
                                    {
                                        type: isPresetEditing ? 'button_image' : 'hidden',
                                        id: 'cancel_preset',
                                        h_align: 'right',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'exit_preset_editing_mode',
                                        button_style: [88, 33, 2],
                                        text: 'Cancel',
                                        img_url: getImageURL('rootflex', 'custom_preset_all'),
                                        img_style: [0, 29, 32, 290],
                                        onClick: () => {
                                            changeCurrentData({
                                                [this.tools_section_id]: {
                                                    isPresetEditing: false
                                                }
                                            });
                                        }
                                    }
                                ],
                                [
                                    { type: !isPresetEditing ? 'dropdown' : 'hidden', id: 'preset_mod_idx' },
                                    {
                                        value: preset_mod_idx,
                                        Options: Presets_Options,
                                        margin_top: 8,
                                        onChange: value => {
                                            const Mod = clone(Preset_Values.find(Preset_Value =>
                                                parseInt(Preset_Value.idx, 10) === parseInt(value, 10)
                                            ));

                                            //TODO: perhaps instead of deleting the properties, copy the desired properties by using Current_Data.Fields
                                            if (Mod) {
                                                delete Mod.site_id;
                                                delete Mod.idx;
                                            }

                                            return { [ucWords(this.mod_type)]: Mod };
                                        }
                                    }
                                ],
                                [
                                    {
                                        type: !isPresetEditing ? 'button_image' : 'hidden',
                                        id: 'edit_preset',
                                        h_align: 'right',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'enter_preset_editing_mode',
                                        button_style: [70, 33, 2],
                                        text: 'Edit',
                                        img_url: getImageURL('rootflex', 'custom_preset_all'),
                                        img_style: [0, 33, 32, 166],
                                        onClick: () => {
                                            changeCurrentData({
                                                [this.tools_section_id]: {
                                                    isPresetEditing: true,
                                                    preset_name: Preset_Values.find(Preset_Value =>
                                                        parseInt(Preset_Value.idx, 10) === parseInt(preset_mod_idx, 10)
                                                    ).name
                                                }
                                            });
                                        }
                                    }
                                ],
                                [
                                    {
                                        type: !isPresetEditing ? 'button_image' : 'hidden',
                                        id: 'delete_preset',
                                        h_align: 'right',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'open_delete_preset_panel',
                                        button_style: [90, 33, 2],
                                        text: 'Delete',
                                        img_url: getImageURL('rootflex', 'custom_preset_all'),
                                        img_style: [0, 32, 32, 207],
                                        onClick: () => {
                                            // TODO: refetch after deletion?
                                            layer_store.dispatch({
                                                type: 'OPEN_PANEL',
                                                panel_data: {
                                                    Function_Data,
                                                    Action_Data: deepmerge(
                                                        Action_Data,
                                                        {
                                                            access: 'authorized',
                                                            panel_type: 'remove_preset_panel',
                                                            frm_name: 'remove_preset',
                                                            mod_idx: preset_mod_idx,
                                                            parent_frm_id: frm_id
                                                        }
                                                    )
                                                },
                                            });
                                        }
                                    }
                                ]
                            ]
                        }
                    ],
                    [
                        { type: save_type === 'all' ? 'row' : 'hidden', id: 'preset_settings_view' },
                        {
                            Content: [
                                [
                                    { type: 'dropdown', id: 'selected_mod_idx' },
                                    {
                                        value: selected_mod_idx,
                                        Options: All_Options,
                                        margin_top: 8
                                    }
                                ],
                                [
                                    {
                                        type: !this.Form_Data.checkModule || !this.Form_Data.checkModule(Current_Data) ? 'button_image' : 'hidden',
                                        id: 'create_preset_from_existing_module',
                                        h_align: 'right',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'create_preset_from_existing_module',
                                        button_style: [136, 33, 2],
                                        text: 'Add Preset',
                                        img_url: getImageURL('rootflex', 'custom_preset_all'),
                                        img_style: [0, 32, 32, 124],
                                        onClick: () => {
                                            alert('this does not work yet');

                                            /*
                                            form_data_store.dispatch({
                                                type: 'SUBMIT',
                                                submitData: {
                                                    frm_id,
                                                    submit_action: 'create_preset_from_existing_module',
                                                    Function_Data,
                                                    process_tag: 'Saving...',
                                                    onSubmitted: Result_Data => {
                                                        // TODO: refetch? or... use returned data to update refresh Presets_Options and Preset_Values
                                                    },
                                                    isPanelRequiredToStayOpen: true
                                                }
                                            });
                                            */
                                        }
                                    }
                                ]
                            ]
                        }
                    ]
                ]
            }
        ];
    }
}
