import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import { clone } from '../utils/object';

export default class ChoicesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Current_Data: clone(props.Load_Data)
        };
    }

    componentDidMount() {
        const { reSizeAndReposition } = this.props;
        reSizeAndReposition();
    }

    changeCurrentData(New_Data) {
        this.setState({
            Current_Data: deepmerge(this.state.Current_Data, New_Data, { arrayMerge: (dest, src) => src })
        });
    }

    getUrl(icon) {
        const { Function_Data } = this.props;
        return Function_Data.base_url + 'projects/' + Function_Data.project + '/img/choices/' + icon + '.png';
    }

    renderChoiceButton(index, ContentItem) {
        const { onClick, icon, label } = ContentItem;
        return (
            <td
                key={index}
                align="center"
            >
                <div
                    className="choice_element"
                    onClick={onClick}
                >
                    <div>
                        <img
                            src={this.getUrl(icon)}
                            alt=""
                        />
                    </div>
                    <div>
                        <p className="gp grey_txt">
                            {label}
                        </p>
                    </div>
                </div>
            </td>
        );
    }

    render() {
        const { Form_Model, Function_Data, Action_Data, frm_id_int, updateActionData } = this.props;
        const Choices = Form_Model
            .getContent(
                Function_Data,
                Action_Data,
                frm_id_int,
                this.state.Current_Data,
                updateActionData,
                this.changeCurrentData.bind(this)
            )
            .map((Item, index) => this.renderChoiceButton(index, Item));

        return (
            <div className="choice_element_holder">
                <table><tbody>
                    <tr>
                        {Choices.length ? Choices : <td />}
                    </tr>
                </tbody></table>
            </div>
        );
    }
}