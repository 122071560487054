import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import scope_group_store from '../../api/scope_group_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class ScopeGroupSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Scope_Group: {
                    idx: '',
                    name: ''
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Scope_Group {
                    idx
                    name
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Scope_Group', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Scope_Group.name, max: 2 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_scope_group' : 'save_scope_group',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Adding...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            if (Result_Data) {
                                const New_Scope_Group = deepmerge(
                                    Current_Data.Scope_Group,
                                    Result_Data
                                );
                                if (Action_Data.data_action_type === 'create') {
                                    scope_group_store.dispatch({
                                        type: 'ADD',
                                        New_Scope_Group: deepmerge(
                                            {
                                                idx: '',
                                                page_sub: false,
                                                pages_amount: 0,
                                                site_id: Function_Data.site_id
                                            },
                                            New_Scope_Group
                                        )
                                    });
                                } else {
                                    scope_group_store.dispatch({
                                        type: 'UPDATE',
                                        Updated_Scope_Group: New_Scope_Group
                                    });
                                }
                            }
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Add' : 'Save')
        ];
    }
}