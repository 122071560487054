import { createStore } from 'redux';

const initialState = {
    action: '',
    selected_el_type: 'root',
    selected_el_idx: 0,
    selected_row_el_idx: 0,
    selected_column_el_idx: 0,
    hovering_el_type: null,
    hovering_el_idx: null,
    hovering_row_el_idx: null,
    hovering_column_el_idx: null,
    cut_copy_action: null,
    cut_copy_element: null,
    cut_copy_id: null,
    Hidden_Elements: []
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'CLICK_ELEMENT':
        case 'RIGHT_CLICK_ELEMENT':
            if (!isActionElementUnique('select', state, action.data)) {
                state.selected_el_type = 'root';
                state.selected_el_idx = 0;
                state.selected_row_el_idx = 0;
                state.selected_column_el_idx = 0;
            } else {
                state.selected_el_type = action.data.el_type;
                state.selected_el_idx = action.data.el_idx;
                state.selected_row_el_idx = action.data.row_el_idx;
                state.selected_column_el_idx = action.data.column_el_idx;
            }
            return state;
        case 'OVER_ELEMENT':
            if (isActionElementUnique('hover', state, action.data)) {
                state.hovering_el_type = action.data.el_type;
                state.hovering_el_idx = action.data.el_idx;
                state.hovering_row_el_idx = action.data.row_el_idx;
                state.hovering_column_el_idx = action.data.column_el_idx;
            }
            return state;
        case 'OUT_ELEMENT':
            if (isActionElementUnique('hover', state, action.data)) {
                state.hovering_el_type = null;
                state.hovering_el_idx = null;
                state.hovering_row_el_idx = null;
                state.hovering_column_el_idx = null;
            }
            return state;
        case 'CUT_COPY':
            state.cut_copy_action = action.data.cut_copy_action;
            state.cut_copy_element = action.data.cut_copy_element;
            state.cut_copy_id = action.data.cut_copy_id;
            return state;
        case 'REMOVE_ELEMENT':
            state.remove_el_type = action.data.el_type;
            state.remove_el_idx = action.data.el_idx;
            return state;
        case 'TOGGLE_VISIBILITY':
            if (state.Hidden_Elements
                .filter(El =>
                    El.el_type === action.data.el_type &&
                    parseInt(El.el_idx, 10) === parseInt(action.data.el_idx, 10)
                ).length === 0
            ) {
                state.Hidden_Elements.push({
                    el_type: action.data.el_type,
                    el_idx: action.data.el_idx
                });
                //$('#element_' + el_type + '_' + el_idx + ',#' + el_type + '_' + el_idx + ',#highlight_' + el_type + '_' + el_idx + ',#select_' + el_type + '_' + el_idx).hide();
            } else {
                state.Hidden_Elements = state.Hidden_Elements.filter(El =>
                    El.el_type !== action.data.el_type ||
                    parseInt(El.el_idx, 10) !== parseInt(action.data.el_idx, 10)
                );
                //$('#element_' + el_type + '_' + el_idx + ',#' + el_type + '_' + el_idx + ',#highlight_' + el_type + '_' + el_idx + ',#select_' + el_type + '_' + el_idx).show();
            }
            return state;
        default:
            return state;
    }
});

const isActionElementUnique = (type, state, data) => {
    const prefix = (type === 'hover' ? 'hovering' : 'selected');
    const { el_type, el_idx, row_el_idx, column_el_idx } = data;
    const action_el_type = state[prefix + 'el_type'];
    const action_el_idx = state[prefix + 'el_idx'];
    const action_row_el_idx = state[prefix + 'row_el_idx'];
    const action_column_el_idx = state[prefix + 'column_el_idx'];

    return action_el_type !== el_type ||
        action_el_idx !== el_idx || (
            action_el_type === 'grid_cell' && (
                action_row_el_idx !== row_el_idx ||
                action_column_el_idx !== column_el_idx
            )
        );
};

export default store;
