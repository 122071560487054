import React, { Component } from 'react';
import Color from './color';
import Number from './number';
import { clone } from '../../utils/object';

export default class GradientEditor extends Component {
    onClickAddPoint() {
        const { El_Data, changeCurrentData } = this.props;

        changeCurrentData({
            Active_Gradient: {
                Points: El_Data.Points.concat([
                    {
                        idx: '', //neccesary?
                        color: 'ffffff',
                        opacity: '100',
                        coordinate: '0',
                    },
                ]),
            },
        });
    }

    onClickDeletePoint(point_index) {
        const { El_Data, changeCurrentData } = this.props;

        return () => {
            const Points = clone(El_Data.Points);
            Points.splice(point_index - 1, 1);

            changeCurrentData({
                Active_Gradient: {
                    Points: Points,
                },
            });
        };
    }

    renderGradientPointRow(Point, index, allowPointDeletion) {
        const { frm_id, Data_Groups, tab, parent_disabled } = this.props;
        const point_index = index + 1;

        return (
            <tr key={index} className="gradient_point_row">
                <td>
                    <p
                        className="gp"
                        style={{ color: '#000', paddingLeft: '8px', fontWeight: 'bold' }}
                    >
                        {point_index}
                    </p>
                </td>
                <td>
                    <Color
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'color_' + point_index}
                        tab={tab}
                        El_Data={{ value: Point.color }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td>
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'opacity_' + point_index}
                        tab={tab}
                        El_Data={{
                            value: Point.opacity,
                            min_val: 0,
                            max_val: 100,
                            unit: '%',
                        }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                <td>
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={'coordinate_' + point_index}
                        tab={tab}
                        El_Data={{ value: Point.coordinate, min_val: 0, max_val: 100 }}
                        Col_Settings={{}}
                        changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                        parent_disabled={parent_disabled}
                    />
                </td>
                {allowPointDeletion && (
                    <td style={{ paddingLeft: '2px', paddingRight: '10px' }}>
                        <div
                            className="delete delete_gradient_point"
                            style={{
                                float: 'left',
                                width: '16px',
                                height: '16px',
                                cursor: !parent_disabled ? 'pointer' : '',
                            }}
                            onClick={
                                !parent_disabled ? this.onClickDeletePoint(point_index) : null
                            }
                        ></div>
                    </td>
                )}
            </tr>
        );
    }

    changeCurrentData_Customized(New_Data) {
        //TODO: try to get rid of this
        const { El_Data, changeCurrentData } = this.props;
        const { Points } = El_Data;
        const fieldAndIndex = Object.keys(New_Data.Active_Gradient)[0];
        const splitIndex = fieldAndIndex.lastIndexOf('_');
        const point_index = parseInt(fieldAndIndex.substr(splitIndex + 1, 10) - 1);
        const field = fieldAndIndex.substr(0, splitIndex);
        const value = New_Data.Active_Gradient[fieldAndIndex];

        Points[point_index][field] = value;
        changeCurrentData({
            Active_Gradient: {
                Points: Points,
            },
        });
    }

    render() {
        const { El_Data, parent_disabled } = this.props;
        const { Points } = El_Data;
        if (Points.length === 0) {
            return <p className="gp bold">No gradient points available.</p>;
        }

        const allowPointDeletion = Points.length > 2;

        return (
            <div style={{ float: 'left', paddingLeft: '4px' }}>
                <table style={{ border: '1px solid #999', borderCollapse: 'separate' }}>
                    <thead>
                        <tr style={{ textAlign: 'left' }}>
                            {[
                                [25, '#'],
                                [86, 'Color'],
                                [110, 'Opacity'],
                                [110, 'Coordinate'],
                            ].map((Head, index) => (
                                <th key={index} style={{ width: Head[0] + 'px' }}>
                                    <p
                                        className="gp"
                                        style={{
                                            color: '#000',
                                            paddingLeft: '8px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {Head[1]}
                                    </p>
                                </th>
                            ))}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Points.map((Point, index) =>
                            this.renderGradientPointRow(Point, index, allowPointDeletion)
                        )}
                        <tr className="add_gradient_point">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ padding: '5px' }}>
                                <div
                                    className="add"
                                    style={{
                                        float: 'left',
                                        width: '16px',
                                        height: '16px',
                                        cursor: !parent_disabled ? 'pointer' : '',
                                    }}
                                    onClick={
                                        !parent_disabled ? this.onClickAddPoint.bind(this) : null
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
