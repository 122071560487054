import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class UserManagement {
    getQuery() {
        return null;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', h_align: 'center' },
                    { text: 'Cannot manage users yet...', style: 'bold' }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}