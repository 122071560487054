import file_store from '../../api/file_store';
import { upload } from '../../api/upload_query';
import { getImageURL, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class FileManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [file_store.subscribe(this.handleMediaItemStoreChange(changeCurrentData, getCurrentData))];
    }

    handleMediaItemStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = file_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Files = clone(Current_Data.Files);

            if (state.action === 'remove') {
                //TODO: also unselect if media-item was selected.
                const index = findIndexArray(
                    Changed_Files,
                    File => parseInt(File.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Files.splice(index, 1);
            }

            changeCurrentData({ Files: Changed_Files });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Files
            max_file_uploads
            upload_max_filesize
            post_max_size
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', id: 'Upload_File' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [
                        [
                            { type: 'row' },
                            {
                                Content: [
                                    [
                                        { type: 'button_image', id: 'upload_new_file', h_align: 'left', v_align: 'center' },
                                        {
                                            name: 'upload_new_file',
                                            button_style: [110, 32, 0, 4, 0, 4, 8],
                                            text: 'Upload',
                                            img_url: getImageURL('rootflex', 'choose_file'),
                                            img_style: [0, 37, 35, 0],
                                            file_upload: true,
                                            filetype_restriction: '.pdf, .doc, .docx, .txt'/*, .xls, .xlsx, .jpg, .gif, .tif, .tiff, .psd, .rar, .zip*/,
                                            onChange: Files => {
                                                upload(
                                                    Function_Data,
                                                    frm_id,
                                                    ['Upload_File'],
                                                    'upload_new_file',
                                                    Files,
                                                    {},
                                                    'upload_files',
                                                    Current_Data.max_file_uploads,
                                                    Current_Data.upload_max_filesize,
                                                    Current_Data.post_max_size,
                                                    (Result_Data) => {
                                                        changeCurrentData({
                                                            Files: clone(Current_Data.Files).concat(
                                                                Result_Data.Uploaded_Files.map(File =>
                                                                    Object.assign({}, File, {
                                                                        site_id: Function_Data.site_id
                                                                    })
                                                                )
                                                            )
                                                        });
                                                    }
                                                );
                                            }
                                        }
                                    ],
                                    [
                                        { type: 'text', padding_left: 10 },
                                        {
                                            text: 'Supported file-extensions: pdf, doc, docx, txt<br>' +
                                                'The files will be added to the collection below.', style: 'bold'
                                        }
                                    ]
                                ]
                            }
                        ]

                    ]
                }
            ],
            wrapSectionSubs('Upload_File', [
                [
                    { type: 'files_management', id: 'file_id' },
                    { Files: Current_Data.Files }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}