import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';
import { ucWords } from '../../utils/string';
import { renderGrid, renderRow } from './management_grid';

export default class OsFontsManagement extends Component {
    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.OS_Fonts.length === 0 ?
            (<p className="gp">No OS fonts added yet.</p>) :
            renderGrid(
                id + '_font_list',
                [
                    { label: 'Used' },
                    { label: 'Name' }
                ],
                this.props.El_Data.OS_Fonts.map((OS_Font, index) =>
                    renderRow(
                        id + '_font_row_' + OS_Font.id,
                        index,
                        [
                            { type: 'text', value: (OS_Font.in_use ? 'Yes' : 'No') },
                            { type: 'text', value: ucWords(OS_Font.name) }
                        ]
                    )
                )
            );
    }
}