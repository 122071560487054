import React, { Component } from 'react';
import layout_store from '../api/layout_store';
import { getSize } from '../utils/browser';
import { getModByPresetOrDirect } from '../utils/mod';

export default class Ground extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundSize: undefined,
            content_holder_pos: 0
        };
        this.unsubscribes = [];

        const { Presets, Element } = props;
        const { Background } = Element;

        this.BackgroundMod = Background ? getModByPresetOrDirect(Background, Presets) : undefined;
    }

    componentDidMount() {
        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
        this.resizeElementBackground();
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    setRef(el) {
        this.myRef = el;
    }

    handleLayoutStoreChange() {
        const { action, content_holder_pos } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.resizeElementBackground();
        }

        if (action === 'set_content_holder_scroll_position') {
            this.setState({
                content_holder_pos: content_holder_pos
            });
        }
    }

    resizeElementBackground() {
        if (this.BackgroundMod && this.myRef) {
            const { type, width, height, fit } = this.BackgroundMod;
            if (type === 'image' && ['c', 'e'].includes(fit)) {
                var element_ratio = getSize(this.myRef).w / getSize(this.myRef).h;
                var img_ratio = width / height;
                this.setState({
                    backgroundSize: (fit === 'e' && element_ratio < img_ratio) || (fit === 'c' && element_ratio > img_ratio) ?
                        '100% auto' :
                        'auto 100%'
                });
            }
        }
    }

    render() {
        const { minimum_device_width, el_idx, Element, requiresNewEffect, effectClass, show_tools } = this.props;
        const newClass = (requiresNewEffect ? 'groundNewContainer ' + effectClass : '') +
            (Element.Background && !typeof (Element.Background) === 'object' ? ' background_' + Element.Background : '');
        const style = {};

        if (this.state.backgroundSize) {
            style.backgroundSize = this.state.backgroundSize;
        }

        if (show_tools) {
            style.position = 'absolute';
            style.top = this.state.content_holder_pos + 'px';
            style.minWidth = minimum_device_width + 'px';
        }

        return (
            <div
                id={'ground_' + el_idx}
                ref={this.setRef.bind(this)}
                style={Object.keys(style).length ? style : null}
                className={newClass || null}
            />
        );
    }
}