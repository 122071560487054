import deepmerge from 'deepmerge';
import moment from 'moment';
import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class DateEl extends Component {
    constructor(props) {
        super(props);
        const { frm_id, Data_Groups, fld_id, El_Data } = props;

        this.state = { hasFocus: false };
        this.myRef;

        this.id = buildFormElementID(frm_id, Data_Groups, fld_id);
        this.lastValidDate = El_Data.value;
    }

    setRef(el) {
        this.myRef = el;
    }

    onClickPicker() {
        const { El_Data } = this.props;
        layer_store.dispatch({
            type: 'SHOW_DATEPICKER',
            options: {
                value: El_Data.value || '',
                format: 'DD/MM/YYYY',
                el: this.myRef,
                onChange: this.onChangeByValue.bind(this)
            }
        });
    }

    onBlur() {
        const { El_Data } = this.props;
        const { value, format } = El_Data;
        if (value) {
            const used_format = format && this.isValidFormat(format) ? format : 'DD/MM/YYYY';
            const parsed_value = moment(value, used_format).format(used_format);
            if (value !== parsed_value) {
                const new_value = parsed_value === 'Invalid date' ? this.lastValidDate : parsed_value;
                this.lastValidDate = new_value;
                this.onChangeByValue(new_value);
            }
        }
        this.setState({ hasFocus: false });
    }

    onFocus() {
        this.setState({ hasFocus: true });
    }

    onChange(e) {
        this.onChangeByValue(e.target.value);
    }

    onChangeByPicker(value) {
        this.lastValidDate = value;
        this.onChangeByValue(value);
    }

    onChangeByValue(value) {
        const { Data_Groups, fld_id, El_Data, changeCurrentData } = this.props;
        const New_Data = El_Data.onChange ? El_Data.onChange(value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    isValidFormat(format) {
        //This could be improved to check if pattern doesn't have duplicate parts, and not have mix of -/. characters
        return format.toUpperCase().match(/^(YY|YYYY|MM|DD)[-/.](YY|YYYY|MM|DD)[-/.](YY|YYYY|MM|DD)$/g);
    }

    render() {
        const { Data_Groups, fld_id, El_Data, Col_Settings, tab, parent_disabled } = this.props,
            isDisabled = parent_disabled || El_Data.disabled,
            isReadonly = parent_disabled || El_Data.readonly,
            { direction, value, Style } = El_Data;

        const style = {};

        if (Style) {
            ['width', 'height', 'paddingRight']
                .map((prop, index) => ({ prop, value: Style[index] }))
                .filter(set => set.value && !isNaN(set.value))
                .forEach(set => {
                    style[set.prop] = set.value + 'px';
                });
        }

        const className = 'fld_input fld_input_size_short' +
            (isDisabled ? '' : ' fld_pointer') +
            (this.state.hasFocus ? ' fld_input_focus' : '') +
            (isReadonly ? ' fld_input_readonly' : '') +
            (isDisabled ? ' fld_input_disabled' : '');

        return (
            <div
                className={
                    'form_field_box inpt' +
                    (direction === 'ver' ? ' inpt_ver' : '')
                }
                style={Col_Settings.h_align === 'right' ? { float: 'right' } : null}
                ref={this.setRef.bind(this)}
            >
                <input
                    type="text"
                    id={this.id}
                    name={fld_id ? buildFormElementName(Data_Groups, fld_id, 'text') : null}
                    value={value ? value : ''}
                    className={className}
                    style={style}
                    maxLength={10}
                    tabIndex={tab ? tab : null}
                    readOnly={isReadonly}
                    disabled={isDisabled}
                    onClick={e => { e.preventDefault(); }}
                    onChange={!isReadonly && !isDisabled ? this.onChange.bind(this) : () => { }}
                    onBlur={!isReadonly && !isDisabled ? this.onBlur.bind(this) : null}
                    onFocus={!isReadonly && !isDisabled ? this.onFocus.bind(this) : null}
                />
                <div
                    className={'date_icon' + (isDisabled ? '' : ' fld_pointer')}
                    onClick={isDisabled || isReadonly ? null : this.onClickPicker.bind(this)}
                />
            </div>
        );
    }
}