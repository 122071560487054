import React, { Component } from 'react';
import { buildEvents } from '../utils/events';

export default class Icon extends Component {
    renderIcon(Events) {
        const { el_type, el_idx, Element, signed_in } = this.props;
        const { type } = Element.El_Data;

        return (
            <div
                id={[el_type, el_idx].join('_')}
                className={'fa fa-' + type}
                title={Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={!Events.href && Events.onClick && !signed_in ? Events.onClick : null}
            ></div>
        );
    }

    render() {
        const { Navigation_Data, Element, signed_in, navigate } = this.props;
        const Events = buildEvents(Element.Behavior, Navigation_Data, navigate);

        return Events.href ?
            <a
                href={Events.href}
                target={Events.new_tab ? '_blank' : null}
                title={Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={signed_in ? e => e.preventDefault() : (
                    Events.onClick ? e => { e.preventDefault(); Events.onClick(); } : null
                )}
            >{this.renderIcon(Events)}</a> :
            this.renderIcon(Events);
    }
}