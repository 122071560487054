import $ from 'jquery';
import structure_store from '../api/structure_store';
import { getViewport } from '../utils/browser';

export const isSelected = (el_type, el_id, state) => {
    return el_type === state.selected_el_type &&
        parseInt(el_id, 10) === parseInt(state.selected_el_idx, 10);
};

export const isHovering = (el_type, el_id, state) => {
    return el_type === state.hovering_el_type &&
        parseInt(el_id, 10) === parseInt(state.hovering_el_idx, 10);
};

export const isHidden = (el_type, el_idx, state) => {
    return state.Hidden_Elements
        .filter(El => El.el_type === el_type && parseInt(El.el_idx, 10) === parseInt(el_idx, 10))
        .length === 1;
};

export const mouseOverElement = (el_type, el_idx) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    //const state = structure_store.getState();
    structure_store.dispatch({
        type: 'OVER_ELEMENT',
        data: {
            el_type,
            el_idx,
            row_el_idx: null,
            column_el_idx: null
        },
    });



    /*if (!(
        state.selected_el_type === el_type &&
        state.selected_el_idx === el_idx
    )) {
        if (el_type === 'column') {
            $('.structure_' + el_type + '_' + el_idx).addClass('structure_highlight_1');
        } else {
            $('#structure_' + el_type + '_' + el_idx).addClass('structure_highlight_1');
        }
    }
    if (el_type !== 'root' && el_type !== 'ground' && el_type !== 'row' && el_type !== 'column') {
        $('#highlight_' + el_type + '_' + el_idx)
            .css({
                opacity: '1'
            });
    }*/
};

export const mouseOutElement = (el_type, el_idx) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    //const state = structure_store.getState();
    structure_store.dispatch({
        type: 'OUT_ELEMENT',
        data: {
            el_type,
            el_idx,
            row_el_idx: null,
            column_el_idx: null
        },
    });



    /*if (!(
        state.selected_el_type === el_type &&
        state.selected_el_idx === el_idx
    )) {
        if (el_type === 'column') {
            $('.structure_' + el_type + '_' + el_idx).removeClass('structure_highlight_1');
        } else {
            $('#structure_' + el_type + '_' + el_idx).removeClass('structure_highlight_1');
        }
    }
    if (el_type !== 'root' && el_type !== 'ground' && el_type !== 'row' && el_type !== 'column') {
        $('#highlight_' + el_type + '_' + el_idx)
            .css({
                opacity: '0'
            });
    }*/
};

export const onClickElement = (el_type, el_idx) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    structure_store.dispatch({
        type: 'CLICK_ELEMENT',
        data: {
            el_type,
            el_idx,
            row_el_idx: 0,
            column_el_idx: 0
        },
    });
};

export const onRightClickElement = (el_type, el_idx, x, y) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    structure_store.dispatch({
        type: 'RIGHT_CLICK_ELEMENT',
        data: {
            el_type,
            el_idx,
            row_el_idx: 0,
            column_el_idx: 0
        },
    });

    setRightClickToolsPosition(el_type, x, y);
};

export const onClickEye = (el_type, el_idx) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    structure_store.dispatch({
        type: 'TOGGLE_VISIBILITY',
        data: {
            el_type,
            el_idx,
            row_el_idx: 0,
            column_el_idx: 0
        },
    });
};

export const showHideRightClickElementActions = (el_type) => {
    var Subs = getElementActionsSubscriptions(),
        Actions = getElementActions(true);

    var action_amount = 0;
    var sub_action_amount;

    for (var i = 0; i < Subs.length; i++) {
        if (el_type === Subs[i][0]) {
            for (var j = 0; j < Actions.length; j++) {
                if (Subs[i][j + 1].length > 1) {
                    $('#' + 'right_click_action_' + Actions[j][0]).show();
                    action_amount++;
                    sub_action_amount = 0;
                    for (var k = 0; k < Subs[i][j + 1].length; k++) {
                        if (Subs[i][j + 1][k]) {
                            sub_action_amount++;
                            $('#' + 'right_click_action_panel_' + Actions[j][3][k][0]).show();
                        } else {
                            $('#' + 'right_click_action_panel_' + Actions[j][3][k][0]).hide();
                        }
                    }
                    $('#right_click_action_panel_' + Actions[j][0]).data('action_amount', sub_action_amount);
                } else {
                    if (Subs[i][j + 1] && (Actions[j][0] !== 'paste' || checkPasteAllowed()) && ((Actions[j][0] !== 'move_up' && Actions[j][0] !== 'move_down') || checkMoveAllowed(Actions[j][0]))) {
                        $('#' + 'right_click_action_' + Actions[j][0]).show();
                        action_amount++;
                    } else {
                        $('#' + 'right_click_action_' + Actions[j][0]).hide();
                    }
                }
            }
        }
    }

    return action_amount;
};

export const setRightClickToolsPosition = (el_type, x, y) => {
    const action_amount = showHideRightClickElementActions(el_type);
    const viewport = getViewport();
    //TODO: use commented-code to properly align right-click-panel when using this on overlay (not yet implemented)
    const panel_w = 150 + (2 * 1),
        panel_h = (action_amount * 23) + (2 * 1);

    $('#right_click_tools')
        .css({
            left: /* x + panel_w > viewport.w ? x - panel_w : */x,
            top: y + panel_h > viewport.h ? y - panel_h : y
        })
        .show();
};
/*
//TODO: REFACTOR&REMOVE
export const selectElement = (el_type, el_idx) => {
    $('body')
        .data('selected_el_type', el_type)
        .data('selected_el_idx', el_idx);
    $('.structure_' + el_type + '_' + el_idx + ',#structure_' + el_type + '_' + el_idx)
        .removeClass('structure_highlight_1');

    if (el_type === 'column') {
        $('.structure_' + el_type + '_' + el_idx)
            .addClass('structure_select_1')
            .find('.structure_' + el_type + '_txt').css({ 'font-weight': 'bold' });
    } else {
        if (el_type === 'row') {
            $('#structure_' + el_type + '_' + el_idx).addClass('structure_select_1');
        } else if (el_type === 'grid' || el_type === 'sequence' || el_type === 'sequence_element' || el_type === 'slideshow') {
            $('#structure_' + el_type + '_' + el_idx).addClass('structure_select_1');
        } else {
            $('#structure_' + el_type + '_' + el_idx).addClass('structure_select_2');
        }
        $('#structure_txt_' + el_type + '_' + el_idx).css({ 'font-weight': 'bold' });
    }

    if (el_type !== 'root' && el_type !== 'ground' && el_type !== 'row' && el_type !== 'column') {
        $('#select_' + el_type + '_' + el_idx).show();
        $('#highlight_' + el_type + '_' + el_idx)
            .css({
                opacity: '0'
            });
    }
    showHideElementActions(el_type);
};

//TODO: REFACTOR&REMOVE
export const selectGridCell = (el_type, grid_el_idx, row_el_idx, column_el_idx) => {
    $('body')
        .data('selected_el_type', el_type)
        .data('selected_grid_el_idx', grid_el_idx)
        .data('selected_row_el_idx', row_el_idx)
        .data('selected_column_el_idx', column_el_idx);
    $('#grid_cell_' + grid_el_idx + '_' + row_el_idx + '_' + column_el_idx)
        .addClass('structure_select_3');

    showHideElementActions(el_type);
};

//TODO: REFACTOR&REMOVE
export const unselectElement = () => {
    const state = structure_store.getState();
    var el_type = state.selected_el_type;

    if (el_type === 'grid_cell') {
        var grid_el_idx = state.selected_grid_el_idx;
        var row_el_idx = state.selected_row_el_idx;
        var column_el_idx = state.selected_column_el_idx;
        $('#grid_cell_' + grid_el_idx + '_' + row_el_idx + '_' + column_el_idx)
            .removeClass('structure_select_3');
    } else {
        var el_idx = state.selected_el_idx;
        $('.structure_' + el_type + '_' + el_idx + ',#structure_' + el_type + '_' + el_idx)
            .removeClass('structure_select_1')
            .removeClass('structure_select_2');
        $('.structure_' + el_type + '_txt').css({ 'font-weight': 'normal' });
        $('#structure_txt_' + el_type + '_' + el_idx).css({ 'font-weight': 'normal' });
        if (el_type !== 'root' && el_type !== 'ground' && el_type !== 'row' && el_type !== 'column') {
            $('#select_' + el_type + '_' + el_idx).hide();
        }
    }
};
*/
export const showHideElementActions = (el_type) => {
    var Subs = getElementActionsSubscriptions(),
        Actions = getElementActions(true);

    for (var i = 0; i < Subs.length; i++) {
        if (el_type === Subs[i][0]) {
            for (var j = 0; j < Actions.length; j++) {
                if (Subs[i][j + 1].length > 1) {
                    $('#' + 'action_' + Actions[j][0]).show();
                    $('#' + 'action_holder_' + Actions[j][0] + '_action_group').show();
                    for (var k = 0; k < Subs[i][j + 1].length; k++) {
                        if (Subs[i][j + 1][k]) {
                            $('#' + 'main_action_button_holder_' + Actions[j][0] + '_action_group_' + Actions[j][3][k][0]).show();
                        } else {
                            $('#' + 'main_action_button_holder_' + Actions[j][0] + '_action_group_' + Actions[j][3][k][0]).hide();
                        }
                    }
                } else {
                    if (Subs[i][j + 1] && (Actions[j][0] !== 'paste' || checkPasteAllowed()) && ((Actions[j][0] !== 'move_up' && Actions[j][0] !== 'move_down') || checkMoveAllowed(Actions[j][0]))) {
                        $('#' + 'action_' + Actions[j][0]).show();
                        $('#' + 'action_holder_' + Actions[j][0] + '_action_group').show();
                    } else {
                        $('#' + 'action_' + Actions[j][0]).hide();
                        $('#' + 'action_holder_' + Actions[j][0] + '_action_group').hide();
                    }
                }
            }
        }
    }
};

export const getElementActions = (merged) => {
    const Top_Row_Actions = [
        ['move_up', 'move_up', 'grey', 'move_up', 'Move Up'],
        ['move_down', 'move_down', 'grey', 'move_down', 'Move Down'],
        ['cut', 'scissors-blue', 'grey', 'cut', 'Cut'],
        ['copy', 'layers', 'grey', 'copy', 'Copy'],
        ['paste', 'clipboard-paste', 'grey', 'paste', 'Paste'],
        ['add', 'add', 'grey',
            [
                ['add_grid', 'Grid', 'element_grid'],
                ['add_sequence', 'Sequence', 'element_sequence'],
                ['add_ground', 'Background', 'element_ground'],
                ['add_box', 'Box', 'element_box'],
                ['add_text', 'Text', 'element_text'],
                ['add_icon', 'Icon', 'element_icon'],
                ['add_image', 'Image', 'element_image'],
                ['add_video', 'Video', 'element_video'],
                ['add_map', 'Map', 'element_map'],
                ['add_menu', 'Menu', 'element_menu'],
                ['add_form', 'Form', 'element_form'],
                ['add_twitter', 'Twitter', 'element_twitter'],
                ['add_slideshow', 'SlideShow', 'element_slideshow']
            ],
            'Add'
        ],
        ['form_add', 'add', 'grey',
            [
                ['add_form_col_section', 'Section', 'element_form_col_section'],
                ['add_form_col_grid', 'Grid', 'element_form_col_grid'],
                ['add_form_col_row', 'Row', 'element_form_col_row'],
                ['add_form_col_button', 'Button', 'element_form_col_button'],
                ['add_form_col_image_button', 'Button With Image', 'element_form_col_image_button'],
                ['add_form_col_field', 'Simple Field', 'element_form_col_field'],
                ['add_form_col_textarea', 'Textarea', 'element_form_col_textarea'],
                ['add_form_col_color_field', 'Color Field', 'element_form_col_color_field'],
                ['add_form_col_date_field', 'Date Field', 'element_form_col_date_field'],
                ['add_form_col_slider_field', 'Slider', 'element_form_col_slider_field'],
                ['add_form_col_dropdown', 'Dropdown', 'element_form_col_dropdown'],
                ['add_form_col_checkbox', 'Checkbox', 'element_form_col_checkbox'],
                ['add_form_col_radio_select', 'Radio Select', 'element_form_col_radio_select'],
                ['add_form_col_image_text_select', 'Custom Select', 'element_form_col_image_text_select'],
                ['add_form_col_box_text', 'Box With Text', 'element_form_col_box_text'],
                ['add_form_col_text', 'Text', 'element_form_col_text'],
                ['add_form_col_image', 'Image', 'element_form_col_image'],
                ['add_form_col_icon_link', 'Icon', 'element_form_col_icon_link'],
                ['add_form_col_space', 'Space', 'element_form_col_space'],
                ['add_form_col_line', 'Line', 'element_form_col_line'],
                ['add_form_col_hidden', 'Hidden Field', 'element_form_col_hidden']
            ],
            'Add'
        ],
        ['grid_add', 'grid_add', 'grey',
            [
                ['add_row', 'Row', 'element_row'],
                ['add_column', 'Column', 'element_column']
            ],
            'Add'
        ],
        ['delete', 'delete', 'grey', 'remove_element', 'Delete']
    ],
        Bottom_Row_Actions = [
            ['scope', 'scope', 'grey', 'edit_element_scope', 'Display Scope'],
            ['options', 'wrench', 'grey', 'edit_element_options', 'Settings'],
            ['behavior', 'behavior', 'grey', 'edit_element_behavior', 'Behavior'],
            ['image_select', 'image_select', 'grey', 'image_select', 'Select Image'],
            ['video_select', 'video_select', 'grey', 'video_select', 'Select Video'],
            ['menu_structure', 'applications-stack', 'grey', 'menu_structure', 'Reorder Menu'],
            ['layout', 'layers-ungroup', 'grey', 'edit_layout', 'Layout'],
            ['row_size', 'row_size', 'grey', 'edit_row_size', 'Size'],
            ['column_size', 'column_size', 'grey', 'edit_column_size', 'Size'],
            ['edit', 'pencil_blue', 'grey', 'edit_text', 'Edit Text'],
            ['background', 'background', 'grey', 'edit_element_background', 'Settings'],
            ['style', 'palette', 'grey',
                [
                    ['edit_element_background', 'Background', 'background'],
                    ['edit_element_border', 'Border', 'border'],
                    ['edit_element_corner', 'Corner', 'corner'],
                    ['edit_element_shadow', 'Shadow', 'shadow']
                ],
                'Styling'
            ],
            ['edit_element_style', 'palette', 'grey', 'edit_element_style', 'Styling']
        ];

    return merged ?
        Top_Row_Actions.concat(Bottom_Row_Actions) :
        [Top_Row_Actions, Bottom_Row_Actions];
};

export const getElementActionsSubscriptions = () => {
    return [
        ['root', 0, 0, 0, 0, 1, [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['grid', 1, 1, 1, 1, 0, 0, 0, [1, 1], 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['row', 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
        ['grid_cell', 0, 0, 0, 0, 1, [1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['column', 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
        ['sequence', 1, 1, 1, 1, 1, [1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['sequence_element', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['ground', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
        ['box', 1, 1, 1, 1, 1, [1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 0, 1, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, [1, 1, 1, 1], 0],
        ['form', 1, 1, 1, 1, 1, 0, [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, [1, 1, 1, 1], 0],
        ['icon', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['image', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['video', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0],
        ['map', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['menu', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1],
        ['slideshow', 1, 1, 1, 1, 1, [1, 1, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0], 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['text', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0],
        ['twitter', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
        ['form_col_section', 1, 1, 1, 1, 1, 0, [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_grid', 1, 1, 1, 1, 1, 0, [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_row', 1, 1, 1, 1, 1, 0, [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_button', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_image_button', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_field', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_textarea', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_color_field', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_date_field', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_slider_field', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_dropdown', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_checkbox', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_radio_select', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_image_text_select', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_box_text', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_text', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_image', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_icon_link', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_space', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_line', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ['form_col_hidden', 1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ];
};

export const checkPasteAllowed = () => {
    const state = structure_store.getState();
    return true;
    /*function checkHolder(allowed, holder_el_type, holder_el_idx, el_type, el_idx, Element_Holder_Map) {
        if (holder_el_type === el_type && holder_el_idx === el_idx) {
            allowed = false;
        } else if (typeof(Element_Holder_Map[holder_el_type + '_' + holder_el_idx]) !== 'undefined') {
            allowed = checkHolder(
                allowed,
                Element_Holder_Map[holder_el_type + '_' + holder_el_idx][0],
                Element_Holder_Map[holder_el_type + '_' + holder_el_idx][1],
                el_type,
                el_idx,
                Element_Holder_Map
            );
        }

        return allowed;
    }
    

    var allowed = true;
    var holder_el_type = state.selected_el_type;
    var el_type = state.cut_copy_element;

    if (
        (holder_el_type !== 'root' && el_type === 'ground') ||
        (holder_el_type === 'form' && !['form_col_grid', 'form_col_row', 'form_col_box_text', 'form_col_button', 'form_col_checkbox', 'form_col_color_field', 'form_col_date_field', 'form_col_dropdown', 'form_col_field', 'form_col_hidden', 'form_col_image', 'form_col_image_button', 'form_col_icon_link', 'form_col_image_text_select', 'form_col_line', 'form_col_radio_select', 'form_col_slider_field', 'form_col_space', 'form_col_text', 'form_col_textarea'].include(el_type)) ||
        (holder_el_type !== 'form' && ['form_col_grid', 'form_col_row', 'form_col_box_text', 'form_col_button', 'form_col_checkbox', 'form_col_color_field', 'form_col_date_field', 'form_col_dropdown', 'form_col_field', 'form_col_hidden', 'form_col_image', 'form_col_image_button', 'form_col_icon_link', 'form_col_image_text_select', 'form_col_line', 'form_col_radio_select', 'form_col_slider_field', 'form_col_space', 'form_col_text', 'form_col_textarea'].include(el_type))
    ) {
        allowed = false;
    }

    if (state.cut_copy_action === 'cut' || state.cut_copy_action === 'copy') {
        if (state.cut_copy_action === 'cut') {
            var Element_Holder_Map = state.Element_Holder_Map;
            var el_idx = parseInt(state.cut_copy_id, 10);

            var holder_el_idx;

            if (holder_el_type === 'grid_cell') {
                holder_el_type = 'grid';
                holder_el_idx = state.selected_grid_el_idx;
            } else {
                holder_el_idx = parseInt(state.selected_el_idx, 10);
            }

            if (holder_el_type !== 'root') {
                allowed = checkHolder(
                    allowed,
                    holder_el_type,
                    holder_el_idx,
                    el_type,
                    el_idx,
                    Element_Holder_Map
                );
            }
        }
    } else {
        allowed = false;
    }

    return allowed;*/
};

export const checkMoveAllowed = (move_type) => {
    const state = structure_store.getState();
    return true;
    /*var allowed = false,
        el_type = state.selected_el_type,
        el_idx = parseInt(state.selected_el_idx, 10),
        Content = state.Content,
        Data = false;

    function getHolderTypeContentAmountElementPosition(el_type, el_idx, Content) {
        var Data, Result;

        function tryGetObjectPosition(el_type, el_idx, Content) {
            var el_pos = false;
            for (var i = 0; i < Content.length; i++) {
                if (Content[i].el_type === el_type && parseInt(Content[i].el_idx) === parseInt(el_idx)) {
                    el_pos = i;
                    break;
                }
            }
            return el_pos;
        }
        

        function tryGetInSubContent(el_type, el_idx, Content) {
            var Result = {'found': false};
            for (var i = 0; i < Content.length; i++) {
                if (isContentElement(Content[i].el_type) && Content[i].Content) {
                    Result = getHolderTypeContentAmountElementPosition(Content[i].el_type, el_type, el_idx, Content[i].Content);
                    if (Result.found) {
                        break;
                    }
                }
            }
            return Result;
        }
        

        function getHolderTypeContentAmountElementPosition(holder_el_type, el_type, el_idx, Content) {
            var Result;
            var el_pos = holder_el_type !== 'grid' ? tryGetObjectPosition(el_type, el_idx, Content) : false;
            if (el_pos !== false) {
                Result = {
                    found: true,
                    Data: {
                        holder_el_type: holder_el_type,
                        content_amount: Content.length,
                        el_pos: el_pos
                    }
                };
            } else {
                if (holder_el_type === 'grid') {
                    for (var i = 0; i < Content.length; i++) {
                        for (var j = 0; j < Content[i].length; j++) {
                            Result = tryGetInSubContent(el_type, el_idx, Content[i][j]);
                            if (Result.found) {
                                break;
                            }
                        }
                    }
                } else {
                    Result = tryGetInSubContent(el_type, el_idx, Content);
                }
            }
            return Result;
        }
        

        Result = getHolderTypeContentAmountElementPosition('root', el_type, el_idx, Content);
        if (Result.found) {
            Data = {
                holder_el_type: Result.Data.holder_el_type,
                content_amount: Result.Data.content_amount,
                el_pos: Result.Data.el_pos
            };
        }

        return Data;
    }
    

    if (el_type !== 'grid_cell' && el_type !== 'root' && el_type !== 'sequence_element') {
        Data = getHolderTypeContentAmountElementPosition(el_type, el_idx, Content);
        if (Data && Data.holder_el_type !== 'grid') {
            if (move_type === 'move_up' && Data.el_pos > 0) {
                allowed = true;
            } else if (move_type === 'move_down' && Data.el_pos < Data.content_amount - 1) {
                allowed = true;
            }
        }
    }

    return allowed;*/
};