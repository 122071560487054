import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class IconSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Icon: {
                    color: '000000',
                    font_size: 100,
                    line_height: 100,
                    type: 'glass'
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Icon: {
                    color: String
                    font_size
                    line_height
                    type: String
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Icon', [
                [
                    { type: 'color', id: 'color', label: 'Color' },
                    { value: Current_Data.Icon.color }
                ],
                [
                    { type: 'number', id: 'font_size', label: 'Size' },
                    { value: Current_Data.Icon.font_size, min_val: 1, max_val: 999, unit: '%' }
                ],
                [
                    { type: 'number', id: 'line_height', label: 'Line height' },
                    { value: Current_Data.Icon.line_height, min_val: 1, max_val: 999, unit: '%' }
                ],
                [
                    { type: 'dropdown', id: 'type', label: 'Type' },
                    {
                        value: Current_Data.Icon.type,
                        Options: [/*
                            ['glass','Glass'],['music','Music'],['search','Search'],['envelope-o','Envelope-o'],
                            ['heart','Heart'],['star','Star'],['star-o','Star-o'],['user','User'],['film','Film'],
                            ['th-large','Th-large'],['th','Th'],['th-list','Th-list'],['check','Check'],
                            ['remove','Remove'],['close','Close'],['times','Times'],['search-plus','Search-plus'],
                            ['search-minus','Search-minus'],['power-off','Power-off'],['signal','Signal'],
                            ['gear','Gear'],['cog','Cog'],['trash-o','Trash-o'],['home','Home'],['file-o','File-o'],
                            ['clock-o','Clock-o'],['road','Road'],['download','Download'],
                            ['arrow-circle-o-down','Arrow-circle-o-down'],['arrow-circle-o-up','Arrow-circle-o-up'],
                            ['inbox','Inbox'],['play-circle-o','Play-circle-o'],['rotate-right','Rotate-right'],
                            ['repeat','Repeat'],['refresh','Refresh'],['list-alt','List-alt'],['lock','Lock'],
                            ['flag','Flag'],['headphones','Headphones'],['volume-off','Volume-off'],
                            ['volume-down','Volume-down'],['volume-up','Volume-up'],['qrcode','Qrcode'],
                            ['barcode','Barcode'],['tag','Tag'],['tags','Tags'],['book','Book'],['bookmark','Bookmark'],
                            ['print','Print'],['camera','Camera'],['font','Font'],['bold','Bold'],['italic','Italic'],
                            ['text-height','Text-height'],['text-width','Text-width'],['align-left','Align-left'],
                            ['align-center','Align-center'],['align-right','Align-right'],['align-justify','Align-justify'],
                            ['list','List'],['dedent','Dedent'],['outdent','Outdent'],['indent','Indent'],
                            ['video-camera','Video-camera'],['photo','Photo'],['image','Image'],['picture-o','Picture-o'],
                            ['pencil','Pencil'],['map-marker','Map-marker'],['adjust','Adjust'],['tint','Tint'],
                            ['edit','Edit'],['pencil-square-o','Pencil-square-o'],['share-square-o','Share-square-o'],
                            ['check-square-o','Check-square-o'],['arrows','Arrows'],['step-backward','Step-backward'],
                            ['fast-backward','Fast-backward'],['backward','Backward'],['play','Play'],['pause','Pause'],
                            ['stop','Stop'],['forward','Forward'],['fast-forward','Fast-forward'],
                            ['step-forward','Step-forward'],['eject','Eject'],['chevron-left','Chevron-left'],
                            ['chevron-right','Chevron-right'],['plus-circle','Plus-circle'],['minus-circle','Minus-circle'],
                            ['times-circle','Times-circle'],['check-circle','Check-circle'],
                            ['question-circle','Question-circle'],['info-circle','Info-circle'],['crosshairs','Crosshairs'],
                            ['times-circle-o','Times-circle-o'],['check-circle-o','Check-circle-o'],['ban','Ban'],
                            ['arrow-left','Arrow-left'],['arrow-right','Arrow-right'],['arrow-up','Arrow-up'],
                            ['arrow-down','Arrow-down'],['mail-forward','Mail-forward'],['share','Share'],['expand','Expand'],
                            ['compress','Compress'],['plus','Plus'],['minus','Minus'],['asterisk','Asterisk'],
                            ['exclamation-circle','Exclamation-circle'],['gift','Gift'],['leaf','Leaf'],['fire','Fire'],
                            ['eye','Eye'],['eye-slash','Eye-slash'],['warning','Warning'],
                            ['exclamation-triangle','Exclamation-triangle'],['plane','Plane'],['calendar','Calendar'],
                            ['random','Random'],['comment','Comment'],['magnet','Magnet'],['chevron-up','Chevron-up'],
                            ['chevron-down','Chevron-down'],['retweet','Retweet'],['shopping-cart','Shopping-cart'],
                            ['folder','Folder'],['folder-open','Folder-open'],['arrows-v','Arrows-v'],['arrows-h','Arrows-h'],
                            ['bar-chart-o','Bar-chart-o'],['bar-chart','Bar-chart'],['twitter-square','Twitter-square'],
                            ['facebook-square','Facebook-square'],['camera-retro','Camera-retro'],['key','Key'],
                            ['gears','Gears'],['cogs','Cogs'],['comments','Comments'],['thumbs-o-up','Thumbs-o-up'],
                            ['thumbs-o-down','Thumbs-o-down'],['star-half','Star-half'],['heart-o','Heart-o'],
                            ['sign-out','Sign-out'],['linkedin-square','Linkedin-square'],['thumb-tack','Thumb-tack'],
                            ['external-link','External-link'],['sign-in','Sign-in'],['trophy','Trophy'],
                            ['github-square','Github-square'],['upload','Upload'],['lemon-o','Lemon-o'],['phone','Phone'],
                            ['square-o','Square-o'],['bookmark-o','Bookmark-o'],['phone-square','Phone-square'],
                            ['twitter','Twitter'],['facebook-f','Facebook-f'],['facebook','Facebook'],['github','Github'],
                            ['unlock','Unlock'],['credit-card','Credit-card'],['feed','Feed'],['rss','Rss'],['hdd-o','Hdd-o'],
                            ['bullhorn','Bullhorn'],['bell','Bell'],['certificate','Certificate'],
                            ['hand-o-right','Hand-o-right'],['hand-o-left','Hand-o-left'],['hand-o-up','Hand-o-up'],
                            ['hand-o-down','Hand-o-down'],['arrow-circle-left','Arrow-circle-left'],
                            ['arrow-circle-right','Arrow-circle-right'],['arrow-circle-up','Arrow-circle-up'],
                            ['arrow-circle-down','Arrow-circle-down'],['globe','Globe'],['wrench','Wrench'],['tasks','Tasks'],
                            ['filter','Filter'],['briefcase','Briefcase'],['arrows-alt','Arrows-alt'],['group','Group'],
                            ['users','Users'],['chain','Chain'],['link','Link'],['cloud','Cloud'],['flask','Flask'],
                            ['cut','Cut'],['scissors','Scissors'],['copy','Copy'],['files-o','Files-o'],
                            ['paperclip','Paperclip'],['save','Save'],['floppy-o','Floppy-o'],['square','Square'],
                            ['navicon','Navicon'],['reorder','Reorder'],['bars','Bars'],['list-ul','List-ul'],
                            ['list-ol','List-ol'],['strikethrough','Strikethrough'],['underline','Underline'],
                            ['table','Table'],['magic','Magic'],['truck','Truck'],['pinterest','Pinterest'],
                            ['pinterest-square','Pinterest-square'],['google-plus-square','Google-plus-square'],
                            ['google-plus','Google-plus'],['money','Money'],['caret-down','Caret-down'],
                            ['caret-up','Caret-up'],['caret-left','Caret-left'],['caret-right','Caret-right'],
                            ['columns','Columns'],['unsorted','Unsorted'],['sort','Sort'],['sort-down','Sort-down'],
                            ['sort-desc','Sort-desc'],['sort-up','Sort-up'],['sort-asc','Sort-asc'],['envelope','Envelope'],
                            ['linkedin','Linkedin'],['rotate-left','Rotate-left'],['undo','Undo'],['legal','Legal'],
                            ['gavel','Gavel'],['dashboard','Dashboard'],['tachometer','Tachometer'],['comment-o','Comment-o'],
                            ['comments-o','Comments-o'],['flash','Flash'],['bolt','Bolt'],['sitemap','Sitemap'],
                            ['umbrella','Umbrella'],['paste','Paste'],['clipboard','Clipboard'],['lightbulb-o','Lightbulb-o'],
                            ['exchange','Exchange'],['cloud-download','Cloud-download'],['cloud-upload','Cloud-upload'],
                            ['user-md','User-md'],['stethoscope','Stethoscope'],['suitcase','Suitcase'],['bell-o','Bell-o'],
                            ['coffee','Coffee'],['cutlery','Cutlery'],['file-text-o','File-text-o'],
                            ['building-o','Building-o'],['hospital-o','Hospital-o'],['ambulance','Ambulance'],
                            ['medkit','Medkit'],['fighter-jet','Fighter-jet'],['beer','Beer'],['h-square','H-square'],
                            ['plus-square','Plus-square'],['angle-double-left','Angle-double-left'],
                            ['angle-double-right','Angle-double-right'],['angle-double-up','Angle-double-up'],
                            ['angle-double-down','Angle-double-down'],['angle-left','Angle-left'],
                            ['angle-right','Angle-right'],['angle-up','Angle-up'],['angle-down','Angle-down'],
                            ['desktop','Desktop'],['laptop','Laptop'],['tablet','Tablet'],['mobile-phone','Mobile-phone'],
                            ['mobile','Mobile'],['circle-o','Circle-o'],['quote-left','Quote-left'],
                            ['quote-right','Quote-right'],['spinner','Spinner'],['circle','Circle'],
                            ['mail-reply','Mail-reply'],['reply','Reply'],['github-alt','Github-alt'],['folder-o','Folder-o'],
                            ['folder-open-o','Folder-open-o'],['smile-o','Smile-o'],['frown-o','Frown-o'],['meh-o','Meh-o'],
                            ['gamepad','Gamepad'],['keyboard-o','Keyboard-o'],['flag-o','Flag-o'],
                            ['flag-checkered','Flag-checkered'],['terminal','Terminal'],['code','Code'],
                            ['mail-reply-all','Mail-reply-all'],['reply-all','Reply-all'],
                            ['star-half-empty','Star-half-empty'],['star-half-full','Star-half-full'],
                            ['star-half-o','Star-half-o'],['location-arrow','Location-arrow'],['crop','Crop'],
                            ['code-fork','Code-fork'],['unlink','Unlink'],['chain-broken','Chain-broken'],
                            ['question','Question'],['info','Info'],['exclamation','Exclamation'],
                            ['superscript','Superscript'],['subscript','Subscript'],['eraser','Eraser'],
                            ['puzzle-piece','Puzzle-piece'],['microphone','Microphone'],
                            ['microphone-slash','Microphone-slash'],['shield','Shield'],['calendar-o','Calendar-o'],
                            ['fire-extinguisher','Fire-extinguisher'],['rocket','Rocket'],['maxcdn','Maxcdn'],
                            ['chevron-circle-left','Chevron-circle-left'],['chevron-circle-right','Chevron-circle-right'],
                            ['chevron-circle-up','Chevron-circle-up'],['chevron-circle-down','Chevron-circle-down'],
                            ['html5','Html5'],['css3','Css3'],['anchor','Anchor'],['unlock-alt','Unlock-alt'],
                            ['bullseye','Bullseye'],['ellipsis-h','Ellipsis-h'],['ellipsis-v','Ellipsis-v'],
                            ['rss-square','Rss-square'],['play-circle','Play-circle'],['ticket','Ticket'],
                            ['minus-square','Minus-square'],['minus-square-o','Minus-square-o'],['level-up','Level-up'],
                            ['level-down','Level-down'],['check-square','Check-square'],['pencil-square','Pencil-square'],
                            ['external-link-square','External-link-square'],['share-square','Share-square'],
                            ['compass','Compass'],['toggle-down','Toggle-down'],['caret-square-o-down','Caret-square-o-down'],
                            ['toggle-up','Toggle-up'],['caret-square-o-up','Caret-square-o-up'],
                            ['toggle-right','Toggle-right'],['caret-square-o-right','Caret-square-o-right'],['euro','Euro'],
                            ['eur','Eur'],['gbp','Gbp'],['dollar','Dollar'],['usd','Usd'],['rupee','Rupee'],['inr','Inr'],
                            ['cny','Cny'],['rmb','Rmb'],['yen','Yen'],['jpy','Jpy'],['ruble','Ruble'],['rouble','Rouble'],
                            ['rub','Rub'],['won','Won'],['krw','Krw'],['bitcoin','Bitcoin'],['btc','Btc'],['file','File'],
                            ['file-text','File-text'],['sort-alpha-asc','Sort-alpha-asc'],
                            ['sort-alpha-desc','Sort-alpha-desc'],['sort-amount-asc','Sort-amount-asc'],
                            ['sort-amount-desc','Sort-amount-desc'],['sort-numeric-asc','Sort-numeric-asc'],
                            ['sort-numeric-desc','Sort-numeric-desc'],['thumbs-up','Thumbs-up'],['thumbs-down','Thumbs-down'],
                            ['youtube-square','Youtube-square'],['youtube','Youtube'],['xing','Xing'],
                            ['xing-square','Xing-square'],['youtube-play','Youtube-play'],['dropbox','Dropbox'],
                            ['stack-overflow','Stack-overflow'],['instagram','Instagram'],['flickr','Flickr'],['adn','Adn'],
                            ['bitbucket','Bitbucket'],['bitbucket-square','Bitbucket-square'],['tumblr','Tumblr'],
                            ['tumblr-square','Tumblr-square'],['long-arrow-down','Long-arrow-down'],
                            ['long-arrow-up','Long-arrow-up'],['long-arrow-left','Long-arrow-left'],
                            ['long-arrow-right','Long-arrow-right'],['apple','Apple'],['windows','Windows'],
                            ['android','Android'],['linux','Linux'],['dribbble','Dribbble'],['skype','Skype'],
                            ['foursquare','Foursquare'],['trello','Trello'],['female','Female'],['male','Male'],
                            ['gittip','Gittip'],['gratipay','Gratipay'],['sun-o','Sun-o'],['moon-o','Moon-o'],
                            ['archive','Archive'],['bug','Bug'],['vk','Vk'],['weibo','Weibo'],['renren','Renren'],
                            ['pagelines','Pagelines'],['stack-exchange','Stack-exchange'],
                            ['arrow-circle-o-right','Arrow-circle-o-right'],['arrow-circle-o-left','Arrow-circle-o-left'],
                            ['toggle-left','Toggle-left'],['caret-square-o-left','Caret-square-o-left'],
                            ['dot-circle-o','Dot-circle-o'],['wheelchair','Wheelchair'],['vimeo-square','Vimeo-square'],
                            ['turkish-lira','Turkish-lira'],['try','Try'],['plus-square-o','Plus-square-o'],
                            ['space-shuttle','Space-shuttle'],['slack','Slack'],['envelope-square','Envelope-square'],
                            ['wordpress','Wordpress'],['openid','Openid'],['institution','Institution'],['bank','Bank'],
                            ['university','University'],['mortar-board','Mortar-board'],['graduation-cap','Graduation-cap'],
                            ['yahoo','Yahoo'],['google','Google'],['reddit','Reddit'],['reddit-square','Reddit-square'],
                            ['stumbleupon-circle','Stumbleupon-circle'],['stumbleupon','Stumbleupon'],
                            ['delicious','Delicious'],['digg','Digg'],['pied-piper','Pied-piper'],
                            ['pied-piper-alt','Pied-piper-alt'],['drupal','Drupal'],['joomla','Joomla'],
                            ['language','Language'],['fax','Fax'],['building','Building'],['child','Child'],['paw','Paw'],
                            ['spoon','Spoon'],['cube','Cube'],['cubes','Cubes'],['behance','Behance'],
                            ['behance-square','Behance-square'],['steam','Steam'],['steam-square','Steam-square'],
                            ['recycle','Recycle'],['automobile','Automobile'],['car','Car'],['cab','Cab'],['taxi','Taxi'],
                            ['tree','Tree'],['spotify','Spotify'],['deviantart','Deviantart'],['soundcloud','Soundcloud'],
                            ['database','Database'],['file-pdf-o','File-pdf-o'],['file-word-o','File-word-o'],
                            ['file-excel-o','File-excel-o'],['file-powerpoint-o','File-powerpoint-o'],
                            ['file-photo-o','File-photo-o'],['file-picture-o','File-picture-o'],
                            ['file-image-o','File-image-o'],['file-zip-o','File-zip-o'],['file-archive-o','File-archive-o'],
                            ['file-sound-o','File-sound-o'],['file-audio-o','File-audio-o'],['file-movie-o','File-movie-o'],
                            ['file-video-o','File-video-o'],['file-code-o','File-code-o'],['vine','Vine'],
                            ['codepen','Codepen'],['jsfiddle','Jsfiddle'],['life-bouy','Life-bouy'],['life-buoy','Life-buoy'],
                            ['life-saver','Life-saver'],['support','Support'],['life-ring','Life-ring'],
                            ['circle-o-notch','Circle-o-notch'],['ra','Ra'],['rebel','Rebel'],['ge','Ge'],
                            ['empire','Empire'],['git-square','Git-square'],['git','Git'],
                            ['y-combinator-square','Y-combinator-square'],['yc-square','Yc-square'],
                            ['hacker-news','Hacker-news'],['tencent-weibo','Tencent-weibo'],['qq','Qq'],['wechat','Wechat'],
                            ['weixin','Weixin'],['send','Send'],['paper-plane','Paper-plane'],['send-o','Send-o'],
                            ['paper-plane-o','Paper-plane-o'],['history','History'],['circle-thin','Circle-thin'],
                            ['header','Header'],['paragraph','Paragraph'],['sliders','Sliders'],['share-alt','Share-alt'],
                            ['share-alt-square','Share-alt-square'],['bomb','Bomb'],['soccer-ball-o','Soccer-ball-o'],
                            ['futbol-o','Futbol-o'],['tty','Tty'],['binoculars','Binoculars'],['plug','Plug'],
                            ['slideshare','Slideshare'],['twitch','Twitch'],['yelp','Yelp'],['newspaper-o','Newspaper-o'],
                            ['wifi','Wifi'],['calculator','Calculator'],['paypal','Paypal'],['google-wallet','Google-wallet'],
                            ['cc-visa','Cc-visa'],['cc-mastercard','Cc-mastercard'],['cc-discover','Cc-discover'],
                            ['cc-amex','Cc-amex'],['cc-paypal','Cc-paypal'],['cc-stripe','Cc-stripe'],
                            ['bell-slash','Bell-slash'],['bell-slash-o','Bell-slash-o'],['trash','Trash'],
                            ['copyright','Copyright'],['at','At'],['eyedropper','Eyedropper'],['paint-brush','Paint-brush'],
                            ['birthday-cake','Birthday-cake'],['area-chart','Area-chart'],['pie-chart','Pie-chart'],
                            ['line-chart','Line-chart'],['lastfm','Lastfm'],['lastfm-square','Lastfm-square'],
                            ['toggle-off','Toggle-off'],['toggle-on','Toggle-on'],['bicycle','Bicycle'],['bus','Bus'],
                            ['ioxhost','Ioxhost'],['angellist','Angellist'],['cc','Cc'],['shekel','Shekel'],
                            ['sheqel','Sheqel'],['ils','Ils'],['meanpath','Meanpath'],['buysellads','Buysellads'],
                            ['connectdevelop','Connectdevelop'],['dashcube','Dashcube'],['forumbee','Forumbee'],
                            ['leanpub','Leanpub'],['sellsy','Sellsy'],['shirtsinbulk','Shirtsinbulk'],
                            ['simplybuilt','Simplybuilt'],['skyatlas','Skyatlas'],['cart-plus','Cart-plus'],
                            ['cart-arrow-down','Cart-arrow-down'],['diamond','Diamond'],['ship','Ship'],
                            ['user-secret','User-secret'],['motorcycle','Motorcycle'],['street-view','Street-view'],
                            ['heartbeat','Heartbeat'],['venus','Venus'],['mars','Mars'],['mercury','Mercury'],
                            ['intersex','Intersex'],['transgender','Transgender'],['transgender-alt','Transgender-alt'],
                            ['venus-double','Venus-double'],['mars-double','Mars-double'],['venus-mars','Venus-mars'],
                            ['mars-stroke','Mars-stroke'],['mars-stroke-v','Mars-stroke-v'],['mars-stroke-h','Mars-stroke-h'],
                            ['neuter','Neuter'],['genderless','Genderless'],['facebook-official','Facebook-official'],
                            ['pinterest-p','Pinterest-p'],['whatsapp','Whatsapp'],['server','Server'],
                            ['user-plus','User-plus'],['user-times','User-times'],['hotel','Hotel'],['bed','Bed'],
                            ['viacoin','Viacoin'],['train','Train'],['subway','Subway'],['medium','Medium'],['yc','Yc'],
                            ['y-combinator','Y-combinator'],['optin-monster','Optin-monster'],['opencart','Opencart'],
                            ['expeditedssl','Expeditedssl'],['battery-4','Battery-4'],['battery-full','Battery-full'],
                            ['battery-3','Battery-3'],['battery-three-quarters','Battery-three-quarters'],
                            ['battery-2','Battery-2'],['battery-half','Battery-half'],['battery-1','Battery-1'],
                            ['battery-quarter','Battery-quarter'],['battery-0','Battery-0'],['battery-empty','Battery-empty'],
                            ['mouse-pointer','Mouse-pointer'],['i-cursor','I-cursor'],['object-group','Object-group'],
                            ['object-ungroup','Object-ungroup'],['sticky-note','Sticky-note'],
                            ['sticky-note-o','Sticky-note-o'],['cc-jcb','Cc-jcb'],['cc-diners-club','Cc-diners-club'],
                            ['clone','Clone'],['balance-scale','Balance-scale'],['hourglass-o','Hourglass-o'],
                            ['hourglass-1','Hourglass-1'],['hourglass-start','Hourglass-start'],['hourglass-2','Hourglass-2'],
                            ['hourglass-half','Hourglass-half'],['hourglass-3','Hourglass-3'],
                            ['hourglass-end','Hourglass-end'],['hourglass','Hourglass'],['hand-grab-o','Hand-grab-o'],
                            ['hand-rock-o','Hand-rock-o'],['hand-stop-o','Hand-stop-o'],['hand-paper-o','Hand-paper-o'],
                            ['hand-scissors-o','Hand-scissors-o'],['hand-lizard-o','Hand-lizard-o'],
                            ['hand-spock-o','Hand-spock-o'],['hand-pointer-o','Hand-pointer-o'],
                            ['hand-peace-o','Hand-peace-o'],['trademark','Trademark'],['registered','Registered'],
                            ['creative-commons','Creative-commons'],['gg','Gg'],['gg-circle','Gg-circle'],
                            ['tripadvisor','Tripadvisor'],['odnoklassniki','Odnoklassniki'],
                            ['odnoklassniki-square','Odnoklassniki-square'],['get-pocket','Get-pocket'],
                            ['wikipedia-w','Wikipedia-w'],['safari','Safari'],['chrome','Chrome'],['firefox','Firefox'],
                            ['opera','Opera'],['internet-explorer','Internet-explorer'],['tv','Tv'],
                            ['television','Television'],['contao','Contao'],['500px','500px'],['amazon','Amazon'],
                            ['calendar-plus-o','Calendar-plus-o'],['calendar-minus-o','Calendar-minus-o'],
                            ['calendar-times-o','Calendar-times-o'],['calendar-check-o','Calendar-check-o'],
                            ['industry','Industry'],['map-pin','Map-pin'],['map-signs','Map-signs'],['map-o','Map-o'],
                            ['map','Map'],['commenting','Commenting'],['commenting-o','Commenting-o'],['houzz','Houzz'],
                            ['vimeo','Vimeo'],['black-tie','Black-tie'],['fonticons','Fonticons'],
                            ['reddit-alien','Reddit-alien'],['edge','Edge'],['credit-card-alt','Credit-card-alt'],
                            ['codiepie','Codiepie'],['modx','Modx'],['fort-awesome','Fort-awesome'],['usb','Usb'],
                            ['product-hunt','Product-hunt'],['mixcloud','Mixcloud'],['scribd','Scribd'],
                            ['pause-circle','Pause-circle'],['pause-circle-o','Pause-circle-o'],['stop-circle','Stop-circle'],
                            ['stop-circle-o','Stop-circle-o'],['shopping-bag','Shopping-bag'],
                            ['shopping-basket','Shopping-basket'],['hashtag','Hashtag'],['bluetooth','Bluetooth'],
                            ['bluetooth-b','Bluetooth-b'],['percent','Percent']*/
                            ['500px', '500px'],
                            ['adjust', 'Adjust'],
                            ['adn', 'Adn'],
                            ['align-center', 'Align-center'],
                            ['align-justify', 'Align-justify'],
                            ['align-left', 'Align-left'],
                            ['align-right', 'Align-right'],
                            ['amazon', 'Amazon'],
                            ['ambulance', 'Ambulance'],
                            ['anchor', 'Anchor'],
                            ['android', 'Android'],
                            ['angellist', 'Angellist'],
                            ['angle-double-down', 'Angle-double-down'],
                            ['angle-double-left', 'Angle-double-left'],
                            ['angle-double-right', 'Angle-double-right'],
                            ['angle-double-up', 'Angle-double-up'],
                            ['angle-down', 'Angle-down'],
                            ['angle-left', 'Angle-left'],
                            ['angle-right', 'Angle-right'],
                            ['angle-up', 'Angle-up'],
                            ['apple', 'Apple'],
                            ['archive', 'Archive'],
                            ['area-chart', 'Area-chart'],
                            ['arrow-circle-down', 'Arrow-circle-down'],
                            ['arrow-circle-left', 'Arrow-circle-left'],
                            ['arrow-circle-o-down', 'Arrow-circle-o-down'],
                            ['arrow-circle-o-left', 'Arrow-circle-o-left'],
                            ['arrow-circle-o-right', 'Arrow-circle-o-right'],
                            ['arrow-circle-o-up', 'Arrow-circle-o-up'],
                            ['arrow-circle-right', 'Arrow-circle-right'],
                            ['arrow-circle-up', 'Arrow-circle-up'],
                            ['arrow-down', 'Arrow-down'],
                            ['arrow-left', 'Arrow-left'],
                            ['arrow-right', 'Arrow-right'],
                            ['arrows', 'Arrows'],
                            ['arrows-alt', 'Arrows-alt'],
                            ['arrows-h', 'Arrows-h'],
                            ['arrows-v', 'Arrows-v'],
                            ['arrow-up', 'Arrow-up'],
                            ['asterisk', 'Asterisk'],
                            ['at', 'At'],
                            ['automobile', 'Automobile'],
                            ['backward', 'Backward'],
                            ['balance-scale', 'Balance-scale'],
                            ['ban', 'Ban'],
                            ['bank', 'Bank'],
                            ['bar-chart', 'Bar-chart'],
                            ['bar-chart-o', 'Bar-chart-o'],
                            ['barcode', 'Barcode'],
                            ['bars', 'Bars'],
                            ['battery-0', 'Battery-0'],
                            ['battery-1', 'Battery-1'],
                            ['battery-2', 'Battery-2'],
                            ['battery-3', 'Battery-3'],
                            ['battery-4', 'Battery-4'],
                            ['battery-empty', 'Battery-empty'],
                            ['battery-full', 'Battery-full'],
                            ['battery-half', 'Battery-half'],
                            ['battery-quarter', 'Battery-quarter'],
                            ['battery-three-quarters', 'Battery-three-quarters'],
                            ['bed', 'Bed'],
                            ['beer', 'Beer'],
                            ['behance', 'Behance'],
                            ['behance-square', 'Behance-square'],
                            ['bell', 'Bell'],
                            ['bell-o', 'Bell-o'],
                            ['bell-slash', 'Bell-slash'],
                            ['bell-slash-o', 'Bell-slash-o'],
                            ['bicycle', 'Bicycle'],
                            ['binoculars', 'Binoculars'],
                            ['birthday-cake', 'Birthday-cake'],
                            ['bitbucket', 'Bitbucket'],
                            ['bitbucket-square', 'Bitbucket-square'],
                            ['bitcoin', 'Bitcoin'],
                            ['black-tie', 'Black-tie'],
                            ['bluetooth', 'Bluetooth'],
                            ['bluetooth-b', 'Bluetooth-b'],
                            ['bold', 'Bold'],
                            ['bolt', 'Bolt'],
                            ['bomb', 'Bomb'],
                            ['book', 'Book'],
                            ['bookmark', 'Bookmark'],
                            ['bookmark-o', 'Bookmark-o'],
                            ['briefcase', 'Briefcase'],
                            ['btc', 'Btc'],
                            ['bug', 'Bug'],
                            ['building', 'Building'],
                            ['building-o', 'Building-o'],
                            ['bullhorn', 'Bullhorn'],
                            ['bullseye', 'Bullseye'],
                            ['bus', 'Bus'],
                            ['buysellads', 'Buysellads'],
                            ['cab', 'Cab'],
                            ['calculator', 'Calculator'],
                            ['calendar', 'Calendar'],
                            ['calendar-check-o', 'Calendar-check-o'],
                            ['calendar-minus-o', 'Calendar-minus-o'],
                            ['calendar-o', 'Calendar-o'],
                            ['calendar-plus-o', 'Calendar-plus-o'],
                            ['calendar-times-o', 'Calendar-times-o'],
                            ['camera', 'Camera'],
                            ['camera-retro', 'Camera-retro'],
                            ['car', 'Car'],
                            ['caret-down', 'Caret-down'],
                            ['caret-left', 'Caret-left'],
                            ['caret-right', 'Caret-right'],
                            ['caret-square-o-down', 'Caret-square-o-down'],
                            ['caret-square-o-left', 'Caret-square-o-left'],
                            ['caret-square-o-right', 'Caret-square-o-right'],
                            ['caret-square-o-up', 'Caret-square-o-up'],
                            ['caret-up', 'Caret-up'],
                            ['cart-arrow-down', 'Cart-arrow-down'],
                            ['cart-plus', 'Cart-plus'],
                            ['cc', 'Cc'],
                            ['cc-amex', 'Cc-amex'],
                            ['cc-diners-club', 'Cc-diners-club'],
                            ['cc-discover', 'Cc-discover'],
                            ['cc-jcb', 'Cc-jcb'],
                            ['cc-mastercard', 'Cc-mastercard'],
                            ['cc-paypal', 'Cc-paypal'],
                            ['cc-stripe', 'Cc-stripe'],
                            ['cc-visa', 'Cc-visa'],
                            ['certificate', 'Certificate'],
                            ['chain', 'Chain'],
                            ['chain-broken', 'Chain-broken'],
                            ['check', 'Check'],
                            ['check-circle', 'Check-circle'],
                            ['check-circle-o', 'Check-circle-o'],
                            ['check-square', 'Check-square'],
                            ['check-square-o', 'Check-square-o'],
                            ['chevron-circle-down', 'Chevron-circle-down'],
                            ['chevron-circle-left', 'Chevron-circle-left'],
                            ['chevron-circle-right', 'Chevron-circle-right'],
                            ['chevron-circle-up', 'Chevron-circle-up'],
                            ['chevron-down', 'Chevron-down'],
                            ['chevron-left', 'Chevron-left'],
                            ['chevron-right', 'Chevron-right'],
                            ['chevron-up', 'Chevron-up'],
                            ['child', 'Child'],
                            ['chrome', 'Chrome'],
                            ['circle', 'Circle'],
                            ['circle-o', 'Circle-o'],
                            ['circle-o-notch', 'Circle-o-notch'],
                            ['circle-thin', 'Circle-thin'],
                            ['clipboard', 'Clipboard'],
                            ['clock-o', 'Clock-o'],
                            ['clone', 'Clone'],
                            ['close', 'Close'],
                            ['cloud', 'Cloud'],
                            ['cloud-download', 'Cloud-download'],
                            ['cloud-upload', 'Cloud-upload'],
                            ['cny', 'Cny'],
                            ['code', 'Code'],
                            ['code-fork', 'Code-fork'],
                            ['codepen', 'Codepen'],
                            ['codiepie', 'Codiepie'],
                            ['coffee', 'Coffee'],
                            ['cog', 'Cog'],
                            ['cogs', 'Cogs'],
                            ['columns', 'Columns'],
                            ['comment', 'Comment'],
                            ['commenting', 'Commenting'],
                            ['commenting-o', 'Commenting-o'],
                            ['comment-o', 'Comment-o'],
                            ['comments', 'Comments'],
                            ['comments-o', 'Comments-o'],
                            ['compass', 'Compass'],
                            ['compress', 'Compress'],
                            ['connectdevelop', 'Connectdevelop'],
                            ['contao', 'Contao'],
                            ['copy', 'Copy'],
                            ['copyright', 'Copyright'],
                            ['creative-commons', 'Creative-commons'],
                            ['credit-card', 'Credit-card'],
                            ['credit-card-alt', 'Credit-card-alt'],
                            ['crop', 'Crop'],
                            ['crosshairs', 'Crosshairs'],
                            ['css3', 'Css3'],
                            ['cube', 'Cube'],
                            ['cubes', 'Cubes'],
                            ['cut', 'Cut'],
                            ['cutlery', 'Cutlery'],
                            ['dashboard', 'Dashboard'],
                            ['dashcube', 'Dashcube'],
                            ['database', 'Database'],
                            ['dedent', 'Dedent'],
                            ['delicious', 'Delicious'],
                            ['desktop', 'Desktop'],
                            ['deviantart', 'Deviantart'],
                            ['diamond', 'Diamond'],
                            ['digg', 'Digg'],
                            ['dollar', 'Dollar'],
                            ['dot-circle-o', 'Dot-circle-o'],
                            ['download', 'Download'],
                            ['dribbble', 'Dribbble'],
                            ['dropbox', 'Dropbox'],
                            ['drupal', 'Drupal'],
                            ['edge', 'Edge'],
                            ['edit', 'Edit'],
                            ['eject', 'Eject'],
                            ['ellipsis-h', 'Ellipsis-h'],
                            ['ellipsis-v', 'Ellipsis-v'],
                            ['empire', 'Empire'],
                            ['envelope', 'Envelope'],
                            ['envelope-o', 'Envelope-o'],
                            ['envelope-square', 'Envelope-square'],
                            ['eraser', 'Eraser'],
                            ['eur', 'Eur'],
                            ['euro', 'Euro'],
                            ['exchange', 'Exchange'],
                            ['exclamation', 'Exclamation'],
                            ['exclamation-circle', 'Exclamation-circle'],
                            ['exclamation-triangle', 'Exclamation-triangle'],
                            ['expand', 'Expand'],
                            ['expeditedssl', 'Expeditedssl'],
                            ['external-link', 'External-link'],
                            ['external-link-square', 'External-link-square'],
                            ['eye', 'Eye'],
                            ['eyedropper', 'Eyedropper'],
                            ['eye-slash', 'Eye-slash'],
                            ['facebook', 'Facebook'],
                            ['facebook-f', 'Facebook-f'],
                            ['facebook-official', 'Facebook-official'],
                            ['facebook-square', 'Facebook-square'],
                            ['fast-backward', 'Fast-backward'],
                            ['fast-forward', 'Fast-forward'],
                            ['fax', 'Fax'],
                            ['feed', 'Feed'],
                            ['female', 'Female'],
                            ['fighter-jet', 'Fighter-jet'],
                            ['file', 'File'],
                            ['file-archive-o', 'File-archive-o'],
                            ['file-audio-o', 'File-audio-o'],
                            ['file-code-o', 'File-code-o'],
                            ['file-excel-o', 'File-excel-o'],
                            ['file-image-o', 'File-image-o'],
                            ['file-movie-o', 'File-movie-o'],
                            ['file-o', 'File-o'],
                            ['file-pdf-o', 'File-pdf-o'],
                            ['file-photo-o', 'File-photo-o'],
                            ['file-picture-o', 'File-picture-o'],
                            ['file-powerpoint-o', 'File-powerpoint-o'],
                            ['files-o', 'Files-o'],
                            ['file-sound-o', 'File-sound-o'],
                            ['file-text', 'File-text'],
                            ['file-text-o', 'File-text-o'],
                            ['file-video-o', 'File-video-o'],
                            ['file-word-o', 'File-word-o'],
                            ['file-zip-o', 'File-zip-o'],
                            ['film', 'Film'],
                            ['filter', 'Filter'],
                            ['fire', 'Fire'],
                            ['fire-extinguisher', 'Fire-extinguisher'],
                            ['firefox', 'Firefox'],
                            ['flag', 'Flag'],
                            ['flag-checkered', 'Flag-checkered'],
                            ['flag-o', 'Flag-o'],
                            ['flash', 'Flash'],
                            ['flask', 'Flask'],
                            ['flickr', 'Flickr'],
                            ['floppy-o', 'Floppy-o'],
                            ['folder', 'Folder'],
                            ['folder-o', 'Folder-o'],
                            ['folder-open', 'Folder-open'],
                            ['folder-open-o', 'Folder-open-o'],
                            ['font', 'Font'],
                            ['fonticons', 'Fonticons'],
                            ['fort-awesome', 'Fort-awesome'],
                            ['forumbee', 'Forumbee'],
                            ['forward', 'Forward'],
                            ['foursquare', 'Foursquare'],
                            ['frown-o', 'Frown-o'],
                            ['futbol-o', 'Futbol-o'],
                            ['gamepad', 'Gamepad'],
                            ['gavel', 'Gavel'],
                            ['gbp', 'Gbp'],
                            ['ge', 'Ge'],
                            ['gear', 'Gear'],
                            ['gears', 'Gears'],
                            ['genderless', 'Genderless'],
                            ['get-pocket', 'Get-pocket'],
                            ['gg', 'Gg'],
                            ['gg-circle', 'Gg-circle'],
                            ['gift', 'Gift'],
                            ['git', 'Git'],
                            ['github', 'Github'],
                            ['github-alt', 'Github-alt'],
                            ['github-square', 'Github-square'],
                            ['git-square', 'Git-square'],
                            ['gittip', 'Gittip'],
                            ['glass', 'Glass'],
                            ['globe', 'Globe'],
                            ['google', 'Google'],
                            ['google-plus', 'Google-plus'],
                            ['google-plus-square', 'Google-plus-square'],
                            ['google-wallet', 'Google-wallet'],
                            ['graduation-cap', 'Graduation-cap'],
                            ['gratipay', 'Gratipay'],
                            ['group', 'Group'],
                            ['hacker-news', 'Hacker-news'],
                            ['hand-grab-o', 'Hand-grab-o'],
                            ['hand-lizard-o', 'Hand-lizard-o'],
                            ['hand-o-down', 'Hand-o-down'],
                            ['hand-o-left', 'Hand-o-left'],
                            ['hand-o-right', 'Hand-o-right'],
                            ['hand-o-up', 'Hand-o-up'],
                            ['hand-paper-o', 'Hand-paper-o'],
                            ['hand-peace-o', 'Hand-peace-o'],
                            ['hand-pointer-o', 'Hand-pointer-o'],
                            ['hand-rock-o', 'Hand-rock-o'],
                            ['hand-scissors-o', 'Hand-scissors-o'],
                            ['hand-spock-o', 'Hand-spock-o'],
                            ['hand-stop-o', 'Hand-stop-o'],
                            ['hashtag', 'Hashtag'],
                            ['hdd-o', 'Hdd-o'],
                            ['header', 'Header'],
                            ['headphones', 'Headphones'],
                            ['heart', 'Heart'],
                            ['heartbeat', 'Heartbeat'],
                            ['heart-o', 'Heart-o'],
                            ['history', 'History'],
                            ['home', 'Home'],
                            ['hospital-o', 'Hospital-o'],
                            ['hotel', 'Hotel'],
                            ['hourglass', 'Hourglass'],
                            ['hourglass-1', 'Hourglass-1'],
                            ['hourglass-2', 'Hourglass-2'],
                            ['hourglass-3', 'Hourglass-3'],
                            ['hourglass-end', 'Hourglass-end'],
                            ['hourglass-half', 'Hourglass-half'],
                            ['hourglass-o', 'Hourglass-o'],
                            ['hourglass-start', 'Hourglass-start'],
                            ['houzz', 'Houzz'],
                            ['h-square', 'H-square'],
                            ['html5', 'Html5'],
                            ['i-cursor', 'I-cursor'],
                            ['ils', 'Ils'],
                            ['image', 'Image'],
                            ['inbox', 'Inbox'],
                            ['indent', 'Indent'],
                            ['industry', 'Industry'],
                            ['info', 'Info'],
                            ['info-circle', 'Info-circle'],
                            ['inr', 'Inr'],
                            ['instagram', 'Instagram'],
                            ['institution', 'Institution'],
                            ['internet-explorer', 'Internet-explorer'],
                            ['intersex', 'Intersex'],
                            ['ioxhost', 'Ioxhost'],
                            ['italic', 'Italic'],
                            ['joomla', 'Joomla'],
                            ['jpy', 'Jpy'],
                            ['jsfiddle', 'Jsfiddle'],
                            ['key', 'Key'],
                            ['keyboard-o', 'Keyboard-o'],
                            ['krw', 'Krw'],
                            ['language', 'Language'],
                            ['laptop', 'Laptop'],
                            ['lastfm', 'Lastfm'],
                            ['lastfm-square', 'Lastfm-square'],
                            ['leaf', 'Leaf'],
                            ['leanpub', 'Leanpub'],
                            ['legal', 'Legal'],
                            ['lemon-o', 'Lemon-o'],
                            ['level-down', 'Level-down'],
                            ['level-up', 'Level-up'],
                            ['life-bouy', 'Life-bouy'],
                            ['life-buoy', 'Life-buoy'],
                            ['life-ring', 'Life-ring'],
                            ['life-saver', 'Life-saver'],
                            ['lightbulb-o', 'Lightbulb-o'],
                            ['line-chart', 'Line-chart'],
                            ['link', 'Link'],
                            ['linkedin', 'Linkedin'],
                            ['linkedin-square', 'Linkedin-square'],
                            ['linux', 'Linux'],
                            ['list', 'List'],
                            ['list-alt', 'List-alt'],
                            ['list-ol', 'List-ol'],
                            ['list-ul', 'List-ul'],
                            ['location-arrow', 'Location-arrow'],
                            ['lock', 'Lock'],
                            ['long-arrow-down', 'Long-arrow-down'],
                            ['long-arrow-left', 'Long-arrow-left'],
                            ['long-arrow-right', 'Long-arrow-right'],
                            ['long-arrow-up', 'Long-arrow-up'],
                            ['magic', 'Magic'],
                            ['magnet', 'Magnet'],
                            ['mail-forward', 'Mail-forward'],
                            ['mail-reply', 'Mail-reply'],
                            ['mail-reply-all', 'Mail-reply-all'],
                            ['male', 'Male'],
                            ['map', 'Map'],
                            ['map-marker', 'Map-marker'],
                            ['map-o', 'Map-o'],
                            ['map-pin', 'Map-pin'],
                            ['map-signs', 'Map-signs'],
                            ['mars', 'Mars'],
                            ['mars-double', 'Mars-double'],
                            ['mars-stroke', 'Mars-stroke'],
                            ['mars-stroke-h', 'Mars-stroke-h'],
                            ['mars-stroke-v', 'Mars-stroke-v'],
                            ['maxcdn', 'Maxcdn'],
                            ['meanpath', 'Meanpath'],
                            ['medium', 'Medium'],
                            ['medkit', 'Medkit'],
                            ['meh-o', 'Meh-o'],
                            ['mercury', 'Mercury'],
                            ['microphone', 'Microphone'],
                            ['microphone-slash', 'Microphone-slash'],
                            ['minus', 'Minus'],
                            ['minus-circle', 'Minus-circle'],
                            ['minus-square', 'Minus-square'],
                            ['minus-square-o', 'Minus-square-o'],
                            ['mixcloud', 'Mixcloud'],
                            ['mobile', 'Mobile'],
                            ['mobile-phone', 'Mobile-phone'],
                            ['modx', 'Modx'],
                            ['money', 'Money'],
                            ['moon-o', 'Moon-o'],
                            ['mortar-board', 'Mortar-board'],
                            ['motorcycle', 'Motorcycle'],
                            ['mouse-pointer', 'Mouse-pointer'],
                            ['music', 'Music'],
                            ['navicon', 'Navicon'],
                            ['neuter', 'Neuter'],
                            ['newspaper-o', 'Newspaper-o'],
                            ['object-group', 'Object-group'],
                            ['object-ungroup', 'Object-ungroup'],
                            ['odnoklassniki', 'Odnoklassniki'],
                            ['odnoklassniki-square', 'Odnoklassniki-square'],
                            ['opencart', 'Opencart'],
                            ['openid', 'Openid'],
                            ['opera', 'Opera'],
                            ['optin-monster', 'Optin-monster'],
                            ['outdent', 'Outdent'],
                            ['pagelines', 'Pagelines'],
                            ['paint-brush', 'Paint-brush'],
                            ['paperclip', 'Paperclip'],
                            ['paper-plane', 'Paper-plane'],
                            ['paper-plane-o', 'Paper-plane-o'],
                            ['paragraph', 'Paragraph'],
                            ['paste', 'Paste'],
                            ['pause', 'Pause'],
                            ['pause-circle', 'Pause-circle'],
                            ['pause-circle-o', 'Pause-circle-o'],
                            ['paw', 'Paw'],
                            ['paypal', 'Paypal'],
                            ['pencil', 'Pencil'],
                            ['pencil-square', 'Pencil-square'],
                            ['pencil-square-o', 'Pencil-square-o'],
                            ['percent', 'Percent'],
                            ['phone', 'Phone'],
                            ['phone-square', 'Phone-square'],
                            ['photo', 'Photo'],
                            ['picture-o', 'Picture-o'],
                            ['pie-chart', 'Pie-chart'],
                            ['pied-piper', 'Pied-piper'],
                            ['pied-piper-alt', 'Pied-piper-alt'],
                            ['pinterest', 'Pinterest'],
                            ['pinterest-p', 'Pinterest-p'],
                            ['pinterest-square', 'Pinterest-square'],
                            ['plane', 'Plane'],
                            ['play', 'Play'],
                            ['play-circle', 'Play-circle'],
                            ['play-circle-o', 'Play-circle-o'],
                            ['plug', 'Plug'],
                            ['plus', 'Plus'],
                            ['plus-circle', 'Plus-circle'],
                            ['plus-square', 'Plus-square'],
                            ['plus-square-o', 'Plus-square-o'],
                            ['power-off', 'Power-off'],
                            ['print', 'Print'],
                            ['product-hunt', 'Product-hunt'],
                            ['puzzle-piece', 'Puzzle-piece'],
                            ['qq', 'Qq'],
                            ['qrcode', 'Qrcode'],
                            ['question', 'Question'],
                            ['question-circle', 'Question-circle'],
                            ['quote-left', 'Quote-left'],
                            ['quote-right', 'Quote-right'],
                            ['ra', 'Ra'],
                            ['random', 'Random'],
                            ['rebel', 'Rebel'],
                            ['recycle', 'Recycle'],
                            ['reddit', 'Reddit'],
                            ['reddit-alien', 'Reddit-alien'],
                            ['reddit-square', 'Reddit-square'],
                            ['refresh', 'Refresh'],
                            ['registered', 'Registered'],
                            ['remove', 'Remove'],
                            ['renren', 'Renren'],
                            ['reorder', 'Reorder'],
                            ['repeat', 'Repeat'],
                            ['reply', 'Reply'],
                            ['reply-all', 'Reply-all'],
                            ['retweet', 'Retweet'],
                            ['rmb', 'Rmb'],
                            ['road', 'Road'],
                            ['rocket', 'Rocket'],
                            ['rotate-left', 'Rotate-left'],
                            ['rotate-right', 'Rotate-right'],
                            ['rouble', 'Rouble'],
                            ['rss', 'Rss'],
                            ['rss-square', 'Rss-square'],
                            ['rub', 'Rub'],
                            ['ruble', 'Ruble'],
                            ['rupee', 'Rupee'],
                            ['safari', 'Safari'],
                            ['save', 'Save'],
                            ['scissors', 'Scissors'],
                            ['scribd', 'Scribd'],
                            ['search', 'Search'],
                            ['search-minus', 'Search-minus'],
                            ['search-plus', 'Search-plus'],
                            ['sellsy', 'Sellsy'],
                            ['send', 'Send'],
                            ['send-o', 'Send-o'],
                            ['server', 'Server'],
                            ['share', 'Share'],
                            ['share-alt', 'Share-alt'],
                            ['share-alt-square', 'Share-alt-square'],
                            ['share-square', 'Share-square'],
                            ['share-square-o', 'Share-square-o'],
                            ['shekel', 'Shekel'],
                            ['sheqel', 'Sheqel'],
                            ['shield', 'Shield'],
                            ['ship', 'Ship'],
                            ['shirtsinbulk', 'Shirtsinbulk'],
                            ['shopping-bag', 'Shopping-bag'],
                            ['shopping-basket', 'Shopping-basket'],
                            ['shopping-cart', 'Shopping-cart'],
                            ['signal', 'Signal'],
                            ['sign-in', 'Sign-in'],
                            ['sign-out', 'Sign-out'],
                            ['simplybuilt', 'Simplybuilt'],
                            ['sitemap', 'Sitemap'],
                            ['skyatlas', 'Skyatlas'],
                            ['skype', 'Skype'],
                            ['slack', 'Slack'],
                            ['sliders', 'Sliders'],
                            ['slideshare', 'Slideshare'],
                            ['smile-o', 'Smile-o'],
                            ['soccer-ball-o', 'Soccer-ball-o'],
                            ['sort', 'Sort'],
                            ['sort-alpha-asc', 'Sort-alpha-asc'],
                            ['sort-alpha-desc', 'Sort-alpha-desc'],
                            ['sort-amount-asc', 'Sort-amount-asc'],
                            ['sort-amount-desc', 'Sort-amount-desc'],
                            ['sort-asc', 'Sort-asc'],
                            ['sort-desc', 'Sort-desc'],
                            ['sort-down', 'Sort-down'],
                            ['sort-numeric-asc', 'Sort-numeric-asc'],
                            ['sort-numeric-desc', 'Sort-numeric-desc'],
                            ['sort-up', 'Sort-up'],
                            ['soundcloud', 'Soundcloud'],
                            ['space-shuttle', 'Space-shuttle'],
                            ['spinner', 'Spinner'],
                            ['spoon', 'Spoon'],
                            ['spotify', 'Spotify'],
                            ['square', 'Square'],
                            ['square-o', 'Square-o'],
                            ['stack-exchange', 'Stack-exchange'],
                            ['stack-overflow', 'Stack-overflow'],
                            ['star', 'Star'],
                            ['star-half', 'Star-half'],
                            ['star-half-empty', 'Star-half-empty'],
                            ['star-half-full', 'Star-half-full'],
                            ['star-half-o', 'Star-half-o'],
                            ['star-o', 'Star-o'],
                            ['steam', 'Steam'],
                            ['steam-square', 'Steam-square'],
                            ['step-backward', 'Step-backward'],
                            ['step-forward', 'Step-forward'],
                            ['stethoscope', 'Stethoscope'],
                            ['sticky-note', 'Sticky-note'],
                            ['sticky-note-o', 'Sticky-note-o'],
                            ['stop', 'Stop'],
                            ['stop-circle', 'Stop-circle'],
                            ['stop-circle-o', 'Stop-circle-o'],
                            ['street-view', 'Street-view'],
                            ['strikethrough', 'Strikethrough'],
                            ['stumbleupon', 'Stumbleupon'],
                            ['stumbleupon-circle', 'Stumbleupon-circle'],
                            ['subscript', 'Subscript'],
                            ['subway', 'Subway'],
                            ['suitcase', 'Suitcase'],
                            ['sun-o', 'Sun-o'],
                            ['superscript', 'Superscript'],
                            ['support', 'Support'],
                            ['table', 'Table'],
                            ['tablet', 'Tablet'],
                            ['tachometer', 'Tachometer'],
                            ['tag', 'Tag'],
                            ['tags', 'Tags'],
                            ['tasks', 'Tasks'],
                            ['taxi', 'Taxi'],
                            ['television', 'Television'],
                            ['tencent-weibo', 'Tencent-weibo'],
                            ['terminal', 'Terminal'],
                            ['text-height', 'Text-height'],
                            ['text-width', 'Text-width'],
                            ['th', 'Th'],
                            ['th-large', 'Th-large'],
                            ['th-list', 'Th-list'],
                            ['thumbs-down', 'Thumbs-down'],
                            ['thumbs-o-down', 'Thumbs-o-down'],
                            ['thumbs-o-up', 'Thumbs-o-up'],
                            ['thumbs-up', 'Thumbs-up'],
                            ['thumb-tack', 'Thumb-tack'],
                            ['ticket', 'Ticket'],
                            ['times', 'Times'],
                            ['times-circle', 'Times-circle'],
                            ['times-circle-o', 'Times-circle-o'],
                            ['tint', 'Tint'],
                            ['toggle-down', 'Toggle-down'],
                            ['toggle-left', 'Toggle-left'],
                            ['toggle-off', 'Toggle-off'],
                            ['toggle-on', 'Toggle-on'],
                            ['toggle-right', 'Toggle-right'],
                            ['toggle-up', 'Toggle-up'],
                            ['trademark', 'Trademark'],
                            ['train', 'Train'],
                            ['transgender', 'Transgender'],
                            ['transgender-alt', 'Transgender-alt'],
                            ['trash', 'Trash'],
                            ['trash-o', 'Trash-o'],
                            ['tree', 'Tree'],
                            ['trello', 'Trello'],
                            ['tripadvisor', 'Tripadvisor'],
                            ['trophy', 'Trophy'],
                            ['truck', 'Truck'],
                            ['try', 'Try'],
                            ['tty', 'Tty'],
                            ['tumblr', 'Tumblr'],
                            ['tumblr-square', 'Tumblr-square'],
                            ['turkish-lira', 'Turkish-lira'],
                            ['tv', 'Tv'],
                            ['twitch', 'Twitch'],
                            ['twitter', 'Twitter'],
                            ['twitter-square', 'Twitter-square'],
                            ['umbrella', 'Umbrella'],
                            ['underline', 'Underline'],
                            ['undo', 'Undo'],
                            ['university', 'University'],
                            ['unlink', 'Unlink'],
                            ['unlock', 'Unlock'],
                            ['unlock-alt', 'Unlock-alt'],
                            ['unsorted', 'Unsorted'],
                            ['upload', 'Upload'],
                            ['usb', 'Usb'],
                            ['usd', 'Usd'],
                            ['user', 'User'],
                            ['user-md', 'User-md'],
                            ['user-plus', 'User-plus'],
                            ['users', 'Users'],
                            ['user-secret', 'User-secret'],
                            ['user-times', 'User-times'],
                            ['venus', 'Venus'],
                            ['venus-double', 'Venus-double'],
                            ['venus-mars', 'Venus-mars'],
                            ['viacoin', 'Viacoin'],
                            ['video-camera', 'Video-camera'],
                            ['vimeo', 'Vimeo'],
                            ['vimeo-square', 'Vimeo-square'],
                            ['vine', 'Vine'],
                            ['vk', 'Vk'],
                            ['volume-down', 'Volume-down'],
                            ['volume-off', 'Volume-off'],
                            ['volume-up', 'Volume-up'],
                            ['warning', 'Warning'],
                            ['wechat', 'Wechat'],
                            ['weibo', 'Weibo'],
                            ['weixin', 'Weixin'],
                            ['whatsapp', 'Whatsapp'],
                            ['wheelchair', 'Wheelchair'],
                            ['wifi', 'Wifi'],
                            ['wikipedia-w', 'Wikipedia-w'],
                            ['windows', 'Windows'],
                            ['won', 'Won'],
                            ['wordpress', 'Wordpress'],
                            ['wrench', 'Wrench'],
                            ['xing', 'Xing'],
                            ['xing-square', 'Xing-square'],
                            ['yahoo', 'Yahoo'],
                            ['yc', 'Yc'],
                            ['y-combinator', 'Y-combinator'],
                            ['y-combinator-square', 'Y-combinator-square'],
                            ['yc-square', 'Yc-square'],
                            ['yelp', 'Yelp'],
                            ['yen', 'Yen'],
                            ['youtube', 'Youtube'],
                            ['youtube-play', 'Youtube-play'],
                            ['youtube-square', 'Youtube-square']
                        ],
                        width: 260
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_icon_settings',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save')
        ];
    }
}