import React, { Component, Fragment } from 'react';
import { buildCSSTextStyle } from '../styling/text';
import { buildEvents } from '../utils/events';
import text_editor_store from '../api/text_editor_store';

export default class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false
        };
    }

    componentDidMount() {
        this.unsubscribes = [
            text_editor_store.subscribe(this.handleTextEditorStoreChange().bind(this))
        ];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleTextEditorStoreChange() {
        return () => {
            const { el_idx } = this.props;
            const state = text_editor_store.getState();

            if (state.active_idx === el_idx) {
                this.setState({
                    isEditing: state.isEditing
                });
            }
        };
    }

    render() {
        const { isEditing } = this.state

        return isEditing ? (
            <Fragment>
                <div className="element_overlay_window">
                    <div className="element_overlay_window_1">
                    </div>
                </div>
                {this.renderText()}
            </Fragment>
        ) : this.renderText();
    }

    renderText() {
        const { isEditing } = this.state
        const { el_idx, Element } = this.props;
        const { El_Data, Text_Links } = Element;
        const { Text_Model, ps_type } = El_Data;

        return (
            <div
                id={'text_' + el_idx}
                className="text_element"
                contentEditable={isEditing}
            >
                {this.buildHTMLTextString(Text_Model, Text_Links, ps_type)}
            </div>
        );
    }

    sanitizeLinks(Model) {
        //Removes links, if there's an previous unclosed link
        //Remove close_link, if there's no opened link
        //Add close_link, if link still open and end of Model is reached.
        let link_opened = false;
        let i = 0;
        do {
            if (
                (Model[i][0] === 'link_close' && !link_opened) ||
                (Model[i][0] === 'link' && link_opened)
            ) {
                Model.splice(i, 1);
            } else {
                if (Model[i][0] === 'link') {
                    link_opened = true;
                }
                if (Model[i][0] === 'link_close') {
                    link_opened = false;
                }
                i++;
            }
        }
        while (i < Model.length);

        if (link_opened) {
            Model.push(['link_close']);
        }

        return Model;
    }

    sanitizeSections(Model) {
        //Add close_section if encountering {hr} while section is opened
        //Add close_section if encountering new section while other section is opened
        //Add section if encountering {settings, t, br, link, link_close} before section is opened
        //Add close_section if at end of model
        let section_opened = false;
        let i = 0;
        do {
            if (Model[i][0] === 'section') {
                if (section_opened) {
                    Model.splice(i, 0, ['section_close']);
                    i++;
                }
                section_opened = true;
            } else if (Model[i][0] === 'hr' && section_opened) {
                Model.splice(i, 0, ['section_close']);
                i++;
            } else if (['settings', 't', 'br', 'link', 'link_close'].includes(Model[i][0]) && !section_opened) {
                Model.splice(i, 0, ['section']);
                i++;
            }
            i++;
        }
        while (i < Model.length);

        if (section_opened) {
            Model.push(['section_close']);
        }

        return Model;
    }

    getLinkForId(id, Text_Links) {
        const Text_Links_Filtered = Text_Links.filter(Text_Link => 'text_behavior_' + Text_Link.idx === id);
        return Text_Links_Filtered.length > 0 ? Text_Links_Filtered[0] : null;
    }

    integrateLinks(Model, Text_Links) {
        let i = 0;
        do {
            if (Model[i][0] === 'link') {
                const Text_Link = this.getLinkForId(Model[i][1].id, Text_Links);

                if (Text_Link) {
                    Model[i][1].Behavior = Text_Link.Behavior;
                }
            }
            i++;
        }
        while (i < Model.length);

        return Model;
    }

    integrateSettings(Model) {
        //Integrate settings with preceding section if: direct preceding, "has_settings"
        //Integrate settings with preceding link if: direct preceding, "has_settings"
        //Convert loose settings to spans
        let i = 0;
        do {
            if (Model[i][0] === 'settings') {
                if (Model[i - 1]) {
                    if (Model[i - 1][0] === 'section' && Model[i - 1][1] === 'has_settings') {
                        Model[i - 1][3] = Model[i][1];
                    } else if (Model[i - 1][0] === 'link' && Model[i - 1][2] === 'has_settings') {
                        Model[i - 1][3] = Model[i][1];
                    } else {
                        Model.splice(i, 0, ['span', Model[i][1]]);
                        i++;
                    }
                }
                Model.splice(i, 1);
            } else {
                i++;
            }
        }
        while (i < Model.length);

        return Model;
    }

    addCloseSpans(Model) {
        //Add close_span if encountering {section, hr, span, 2nd t, link, link_close, section_close} while span is opened
        let span_opened = false;
        let text_present = false;
        let i = 0;
        do {
            if (Model[i][0] === 'span') {
                if (span_opened) {
                    Model.splice(i, 0, ['span_close']);
                    i++;
                }
                span_opened = true;
            } else if (['section', 'section_close', 'hr', 'link', 'link_close'].includes(Model[i][0]) && span_opened) {
                span_opened = false;
                Model.splice(i, 0, ['span_close']);
                i++;
            } else if (Model[i][0] === 't') {
                if (text_present && span_opened) {
                    span_opened = false;
                    text_present = false;//Sure? No need to add new "span"? How about styling(settings) that should apply to next text? Does it have its own settings?
                    Model.splice(i + 1, 0, ['span_close']);
                    i++;
                }
                text_present = true;
            }
            i++;
        }
        while (i < Model.length);

        return Model;
    }

    /*setMandatoryProperties(Model) {
        let i=0;
        do {
            if (Model[i][0] === 'section') {
                if (typeof(Model[i][1]) === 'undefined') {
                    
                }
            } else if (Model[i][0] === 'link') {
            } else if (Model[i][0] === 'span') {
            }
            i++;
        }
        while (i < Model.length);
        
        return Model;
    }*/

    getClosingIndexOfType(Model, type) {
        let i = 0;
        let found = false;
        let amount_open = 1;

        do {
            if (Model[i][0] === type) {
                amount_open++;
            }
            if (Model[i][0] === type + '_close') {
                amount_open--;
            }
            if (amount_open === 0) {
                found = true;
            }
            i++;
        }
        while (i < Model.length && !found);

        return i;
    }

    buildNestedModel(Model) {
        let NestedModel = [];
        let i = 0;

        do {
            if (Model[i]) {
                if (['hr', 'br', 't'].includes(Model[i][0])) {
                    NestedModel.push(Model[i]);
                } else if (['section', 'link', 'span'].includes(Model[i][0])) {
                    let closing_index = this.getClosingIndexOfType(Model.slice(i + 1), Model[i][0]);
                    Model[i][4] = this.buildNestedModel(Model.slice(i + 1, i + closing_index));
                    NestedModel.push(Model[i]);
                    i = i + closing_index;
                }
            }
            i++;
        }
        while (i < Model.length);

        return NestedModel;
    }

    buildHTMLTextString(Model, Text_Links, section_type) {
        section_type = section_type || 'p';
        Model = Model.filter(Part => typeof (Part[0]) !== 'undefined');

        if (Model.length === 0) {
            return (<Fragment />);
        }
        Model = this.sanitizeLinks(Model);
        Model = this.sanitizeSections(Model);
        Model = this.integrateLinks(Model, Text_Links);
        Model = this.integrateSettings(Model);
        Model = this.addCloseSpans(Model);
        //Model = this.setMandatoryProperties(Model);

        return this.buildParts(section_type, this.buildNestedModel(Model));
    }

    getHTMLStyleAttributes(Text_Style_With_Shadows, text_align) {
        const { Font_Families } = this.props;

        if (Text_Style_With_Shadows && typeof (Text_Style_With_Shadows.font_family_id) !== 'undefined') {
            Text_Style_With_Shadows.Font = Font_Families[parseInt(Text_Style_With_Shadows.font_family_id)];
        }

        let s = Text_Style_With_Shadows ? buildCSSTextStyle(Text_Style_With_Shadows) : {};

        if (text_align && text_align !== 'left' /*TODO: the default text-align should be based on the language. When default should be "right", apply "text-align:right;" on "p, h1, h2, h3, h4, h5, h6, .body_font_size" */) {
            s.textAlign = text_align;
        }

        return Object.keys(s).length > 0 ? s : null;
    }

    buildParts(section_type, Parts) {
        return Parts.map((Part, index) => {
            if (Part[0] === 'section') {
                const Tag = `${section_type}`;
                const HTMLStyleAttributes = this.getHTMLStyleAttributes(Part[3], Part[2]);
                return (
                    <Tag
                        key={index}
                        className={Part[3] ? Part[3].class : null}
                        style={HTMLStyleAttributes}
                    >
                        {this.buildParts(section_type, Part[4])}
                    </Tag>
                );
            }
            if (Part[0] === 'span') {
                const HTMLStyleAttributes = this.getHTMLStyleAttributes(Part[1]);
                return (
                    <span
                        key={index}
                        className={Part[1] ? Part[1].class : null}
                        style={HTMLStyleAttributes}
                    >
                        {this.buildParts(section_type, Part[4])}
                    </span>
                );
            }
            if (Part[0] === 'hr') {
                return (<hr key={index} />);
            }
            if (Part[0] === 't') {
                return (<Fragment key={index}>{Part[1]}</Fragment>);
            }
            if (Part[0] === 'br') {
                return (<br key={index} />);
            }
            if (Part[0] === 'link' && Part[1].Behavior) {
                const { Navigation_Data, signed_in, navigate } = this.props;
                const HTMLStyleAttributes = this.getHTMLStyleAttributes(Part[3]);

                const Events = buildEvents(Part[1].Behavior, Navigation_Data, navigate);

                return (Events.href ?
                    <a
                        key={index}
                        className={Part[3] ? Part[3].class : null}
                        id={Part[1].id || null}
                        style={HTMLStyleAttributes}
                        href={Events.href}
                        target={Events.new_tab ? '_blank' : null}
                        title={Events.tooltip && !signed_in ? Events.tooltip : null}
                        onClick={signed_in ? e => e.preventDefault() : (
                            Events.onClick ? e => { e.preventDefault(); Events.onClick(); } : null
                        )}
                    >
                        {this.buildParts(section_type, Part[4])}
                    </a> :
                    <span
                        key={index}
                        className={Part[3] ? Part[3].class : null}
                        id={Part[1].id || null}
                        style={HTMLStyleAttributes}
                        title={Events.tooltip && !signed_in ? Events.tooltip : null}
                        onClick={Events.onClick && !signed_in ? Events.onClick : null}
                    >
                        {this.buildParts(section_type, Part[4])}
                    </span>
                );
            }
            return (<Fragment key={index} />);
        });
    }
}