import { createStore } from 'redux';

//TODO: get rid of this class... need to get rid of form_int_id... but actually need to get completely rid of use of html id-attributes...

const initialState = {
    action: '',
    Indexes: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD_FORM_INDEX':
            // TODO: move "ADD_FORM_INDEX" to new class... which doesn't use redux or somehow solve change state during state-change
            state.Indexes[action.form_action_data_type] = Object.keys(state.Indexes).length + 1;
            return state;
        default:
            return state;
    }
});

export default store;