export const buildURL = (default_language_type, language_type, device_type, base_url, site_url, Page_URLs, filename) => {
    language_type = language_type === default_language_type ? '' : language_type;
    device_type = device_type === 'desktop' ? '' : device_type;
    filename = filename || '';
    const page_url_string = Page_URLs.filter(page_url => page_url).join('/');

    return base_url +
        (site_url ? site_url + '/' : '') +
        (page_url_string ? page_url_string + '/' : '') +
        filename +
        (language_type || device_type ? '?' : '') +
        (language_type ? ('language=' + language_type) : '') +
        (device_type ? ((language_type ? '&' : '') + 'device=' + device_type) : '');
};

export const removeURLParam = (key, url) => {
    var url_base = url.split('?')[0],
        parameter,
        Parameters = [],
        query = (url.indexOf('?') !== -1) ? url.split('?')[1] : '';
    if (query !== '') {
        Parameters = query.split('&');
        for (var i = Parameters.length - 1; i >= 0; i -= 1) {
            parameter = Parameters[i].split('=')[0];
            if (parameter === key) {
                Parameters.splice(i, 1);
            }
        }
        url_base = url_base + (Parameters.length > 0 ? '?' + Parameters.join('&') : '');
    }
    return url_base;
};

const getPublicFilesPath = (project, use_service) => {
    return buildPath(
        [
            'projects',
            project,
            'files',
            (use_service ? 'service' : 'user')
        ]
    );
};

const getCacheFilesPath = (project, use_service) => {
    return buildPath(
        [
            'projects',
            project,
            'cache',
            (use_service ? 'service' : 'user')
        ]
    );
};

const buildPublicSitePath = (project, site_id, use_service) => {
    return getPublicFilesPath(project, use_service) +
        buildPath([site_id]);
};

const buildCacheSitePath = (project, site_id, use_service) => {
    return getCacheFilesPath(project, use_service) +
        buildPath([site_id]);
};

export const buildIconPath = (base_url, project) => {
    return buildPath(
        [
            base_url,
            'projects',
            project,
            'img',
            'icon'
        ]
    );
};

export const buildOriginImagePath = (project, site_id, use_service) => {
    return buildPublicSitePath(project, site_id, use_service) +
        buildPath(['img', 'upload']);
};

export const buildCustomImagePath = (project, site_id, use_service) => {
    return buildCacheSitePath(project, site_id, use_service) +
        buildPath(['img', 'custom']);
};

export const buildDesktopSizeImagePath = (project, site_id, use_service) => {
    return buildCacheSitePath(project, site_id, use_service) +
        buildPath(['img', 'w750h600']);
};

export const buildThumbnailImagePath = (project, site_id, use_service) => {
    return buildCacheSitePath(project, site_id, use_service) +
        buildPath(['img', 'w150h100']);
};

export const buildFilePathFile = (project, site_id, use_service) => {
    return buildPublicSitePath(project, site_id, use_service) +
        buildPath(['docs']);
};

export const buildFontPathFile = (project, site_id, use_service) => {
    return buildPublicSitePath(project, site_id, use_service) +
        buildPath(['fonts']);
};

export const buildVideoThumbnailImagePathFile = (project, site_id, use_service) => {
    return buildCacheSitePath(project, site_id, use_service) +
        buildPath(['img', 'w150h100']);
};

export const buildVideoPathFile = (project, site_id, use_service) => {
    return buildPublicSitePath(project, site_id, use_service) +
        buildPath(['video']);
};

export const buildVideoPlaceHolderImagePathFile = (project, site_id, use_service) => {
    return buildVideoPathFile(project, site_id, use_service) +
        buildPath(['placeholder']);
};

const buildPath = (Folders) => {
    return Folders.join('/') + '/';
};