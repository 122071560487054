import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveSite {
    getQuery() {
        return null;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', h_align: 'center' },
                    { text: 'Warning!', style: 'bold' }
                ],
                [
                    { type: 'text', h_align: 'center' },
                    { text: 'You are about to delete this entire website!', style: 'normal_grey' }
                ],
                [
                    { type: 'text', h_align: 'center' },
                    { text: 'Are you sure?', style: 'normal_grey' }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'remove_site',
                        Function_Data,
                        process_tag: 'Removing...',
                        onSubmitted: Result_Data => {
                            alert('Site has been deleted!');
                            navigate(
                                '',
                                '',
                                '',
                                Function_Data.base_url,
                                '',
                                []
                            );
                        }
                    }
                });
            }, 'Delete')
        ];
    }
}