import deepmerge from 'deepmerge';
import custom_font_store from '../../api/custom_font_store';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';
import { getSafeUrl } from '../../utils/string';

export default class CustomFontSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            deepmerge(
                Load_Data,
                {
                    Custom_Font: {
                        id: '',
                        source: Action_Data.source,
                        name: '',
                        external_url: '',
                        external_url_bold: '',
                        external_url_italic: '',
                        external_url_bold_italic: ''
                    }
                }
            ) :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Custom_Font {
                    id
                    name
                    external_url
                    external_url_bold
                    external_url_italic
                    external_url_bold_italic
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Custom_Font', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Custom_Font.name || '', max: 2 }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'text' : 'hidden', colspan: 2 },
                    { text: 'Normal', style: 'bold' }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'field' : 'hidden', id: 'external_url', label: 'URL' },
                    { value: getSafeUrl(Current_Data.Custom_Font.external_url || ''), max: 2, Style: [350] }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'text' : 'hidden', colspan: 2 },
                    { text: 'Bold Variant (optional)', style: 'bold' }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'field' : 'hidden', id: 'external_url_bold', label: 'URL' },
                    { value: getSafeUrl(Current_Data.Custom_Font.external_url_bold || ''), max: 2, Style: [350] }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'text' : 'hidden', colspan: 2 },
                    { text: 'Italic Variant (optional)', style: 'bold' }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'field' : 'hidden', id: 'external_url_italic', label: 'URL' },
                    { value: getSafeUrl(Current_Data.Custom_Font.external_url_italic || ''), max: 2, Style: [350] }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'text' : 'hidden', colspan: 2 },
                    { text: 'Bold Italic Variant (optional)', style: 'bold' }
                ],
                [
                    { type: Current_Data.Custom_Font.source === 'external_host' ? 'field' : 'hidden', id: 'external_url_bold_italic', label: 'URL' },
                    { value: getSafeUrl(Current_Data.Custom_Font.external_url_bold_italic || ''), max: 2, Style: [350] }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'create_custom_font' : 'save_custom_font',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            if (Result_Data) {
                                const Changed_Custom_Font = deepmerge(
                                    Current_Data.Custom_Font,
                                    Result_Data
                                );
                                if (Action_Data.data_action_type === 'create') {
                                    custom_font_store.dispatch({
                                        type: 'ADD',
                                        New_Custom_Font: Changed_Custom_Font
                                    });
                                } else {
                                    custom_font_store.dispatch({
                                        type: 'UPDATE',
                                        Updated_Custom_Font: Changed_Custom_Font
                                    });
                                }
                            }
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}