import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import { hot } from 'react-hot-loader';
import function_data_store from './api/function_data_store';
import layer_store from './api/layer_store';
import layout_store from './api/layout_store';
import { loadMainIntoCache } from './api/main_service';
import main_store from './api/main_store';
import user_store from './api/user_store';
import Main from './element/main';
import Layers from './layer/layers';
import { getViewport, runScrollEvents } from './utils/browser';
import { clone } from './utils/object';
import { buildURL, removeURLParam } from './utils/path';

class App extends Component {
    constructor(props) {
        super(props);
        this.data = null;
        this.oldData = null;
        this.isNavigating = false;
        this.justSettingOld = false;
        this.loadInSecond = false;
        this.historyUrls = {};
        const that = this;
        this.cssRules = [];
        this.Navigation_Data = clone(props.Navigation_Data);
        window.onpopstate = event => {
            if (that.historyUrls[document.location.href]) {
                const {
                    base_url,
                    site_url,
                    default_language_type,
                    Page_URLs,
                    language_type,
                    device_type,
                } = that.historyUrls[document.location.href];

                that.navigate(
                    default_language_type,
                    language_type,
                    device_type,
                    base_url,
                    site_url,
                    Page_URLs
                );
            }
        };
        this.storePageHistory(this.Navigation_Data);
        this.initPublicKeys();
        layout_store.dispatch({
            type: 'INIT_SCROLLBAR'
        });
        this.app_el = document.getElementById('app');

        //TODO: check all addEventListener, for the use of ",true" at the end... it will make the event trigger on capture, not bubbling phase, thus e.stoppropagation doesn't work.
        //TODO: move all events on document to listen to a central store.
        this.EventListeners = [];
        this.resizeTimer = null;
        function_data_store.dispatch({
            type: 'CACHE_DATA',
            data: deepmerge(
                this.Navigation_Data,
                {
                    project: props.project,
                }
            )
        });

        this.state = {
            isLoading: true,
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        setTimeout(() => {
            document.location = 'https://www.rootfirm.com';
        }, 2000);
        this.unsubscribes = [
            main_store.subscribe(this.handleMainStoreChange.bind(this))
        ];
        loadMainIntoCache(this.Navigation_Data, result => {
            this.data = result;
            this.setState({
                isLoading: false
            }, () => {
                this.onCompleted();
            });
        });

        history.replaceState(
            {},
            '',
            removeURLParam('nocache', window.location.href)
        );

        /* TODO: decide if good idea to always disallow
        * contextmenu (e.g. standard right-click-browser-tools)
        * when "showtools", or maybe only when right-click on toolbar.
        */
        //window.addEventListener('contextmenu', e => e.preventDefault());

        for (let i = 0; i < document.styleSheets.length; i++) {
            if (document.styleSheets[i].href.endsWith('page.css')) {
                for (let j = 0; j < document.styleSheets[i].cssRules.length; j++) {
                    this.cssRules.push(document.styleSheets[i].cssRules[j].cssText);
                }
            }
        }

        this.resizeWindowEvent();
        this.EventListeners = [
            [window, 'resize', this.resizeWindowEvent.bind(this)]
        ];

        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
    }

    refetch() {
        loadMainIntoCache(this.Navigation_Data, result => {
            this.data = result;
            this.onCompleted();
            this.forceUpdate();
        });
    }

    handleMainStoreChange() {
        const { action } = main_store.getState();
        if (action === 'CACHE_MAIN') {
            this.forceUpdate();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.justSettingOld) {
            this.justSettingOld = false;
            return false;
        }
        return true;
    }

    resizeWindowEvent() {
        if (this.resizeTimer) {
            clearTimeout(this.resizeTimer);
        }

        this.resizeTimer = setTimeout(() => {
            this.trySwitchDevice();

            const { scrollBarSize } = layout_store.getState();
            layout_store.dispatch({
                type: 'WINDOW_RESIZE'
            });
        }, 5);
    }

    navigateAndStore(default_language_type, language_type, device_type, base_url, site_url, Page_URLs) {
        this.navigate(default_language_type, language_type, device_type, base_url, site_url, Page_URLs);
        this.storePageHistory({ default_language_type, language_type, device_type, base_url, site_url, Page_URLs });
    }

    navigate(default_language_type, language_type, device_type, base_url, site_url, Page_URLs) {
        this.isNavigating = true;
        this.justSettingOld = true;
        this.oldData = clone(this.data);
        this.data = null;
        this.Navigation_Data = Object.assign({}, this.Navigation_Data, {
            Page_URLs,
            language_type,
            device_type,
        });
        this.refetch();

        /*
        * It would be better if only the missing styles specific for the device/page are loaded...
        * all shared styles should be loaded if not already loaded
        * (react should know which shared styles are loaded)
        * improve api request points for -> page.css
        * possibly do something smart with presets, device/page-scopes
        *
        * Or... start including css into react components...
        * I didn't do that before...
        * because of the refactoring...
        * and because fear of performace...
        * and not having separate css but all these inline style (which sometimes doesn't support things...
        * possibly not too big of problem... still...
        */
        const req = new XMLHttpRequest();

        req.open(
            'GET',
            buildURL(default_language_type, language_type, device_type, base_url, site_url, Page_URLs, 'page.css')
        );
        req.setRequestHeader('Content-Type', 'text/css;charset=UTF-8');
        // req.withCredentials = true; // CORS
        req.send();
        req.onreadystatechange = () => {
            if (req.readyState == XMLHttpRequest.DONE) {
                let newStyles = '';
                const newRules = this.rulesForCssText(req.responseText);
                for (let j = 0; j < newRules.length; j++) {
                    if (!this.cssRules.includes(newRules[j].cssText)) {
                        this.cssRules.push(newRules[j].cssText);
                        newStyles = newStyles + newRules[j].cssText;
                    }
                }
                this.addStyle(newStyles);
            }
        };
    }

    rulesForCssText(styleContent) {
        let doc = document.implementation.createHTMLDocument(''),
            styleElement = document.createElement('style');
        styleElement.textContent = styleContent;
        doc.body.appendChild(styleElement);
        return styleElement.sheet.cssRules;
    }

    storePageHistory(Navigation_Data) {
        const { default_language_type, language_type, device_type, base_url, site_url, Page_URLs, } = Navigation_Data;
        const pageTitle = 'temp title';
        const pageHtml = '';
        const urlPath = buildURL(default_language_type, language_type, device_type, base_url, site_url, Page_URLs);
        this.historyUrls[urlPath] = { default_language_type, language_type, device_type, base_url, site_url, Page_URLs, };
        window.history.pushState(
            {
                html: pageHtml,
                pageTitle,
            },
            '',
            urlPath
        );
    }

    setBaseFontSize() {
        document.getElementById('base-font-size').remove();
        if (this.data.font_base_window_width_size > 0) {
            this.addStyle(
                `p, h1, h2, h3, h4, h5, h6, .body_font_size {font-size: ${16 * (
                    (this.data.show_tools ? this.app_el.offsetWidth : document.body.scrollWidth) /
                    this.data.font_base_window_width_size
                )
                }px;}`,
                'base-font-size'
            );
        }
    }

    addStyle(styles, id) {
        const style = document.createElement('style');
        document.head.appendChild(style);
        if (id) {
            style.id = id;
        }
        style.appendChild(document.createTextNode(styles));
    }

    trySwitchDevice() {
        if (!this.isNavigating && this.data && !this.data.show_tools && this.data.Devices) {
            const
                state = layout_store.getState(),
                { viewport, scrollBarSize } = state,
                { base_url, site_url, default_language_type, Page_URLs, language_type, device_type, } = this.Navigation_Data,
                offset = document.body.scrollHeight <= document.body.clientHeight ? scrollBarSize : 0,
                Devices = clone(this.data.Devices)
                    .filter(Device => !Device.minimum_width || viewport.w > Device.minimum_width + offset)
                    .sort((a, b) =>
                        b.minimum_width === null || (a.minimum_width !== null && a.minimum_width > b.minimum_width) ? 1 : -1
                    ),
                new_device_type = Devices[Devices.length - 1].type;

            if (device_type !== new_device_type) {
                this.navigateAndStore(default_language_type, language_type, new_device_type, base_url, site_url, Page_URLs);
            }
        }
    }

    initScrollZonesMechanism() {
        const { show_tools, Scroll_Zones } = this.data;

        if (!show_tools && Scroll_Zones) {
            window.addEventListener('scroll', () => {
                runScrollEvents(getViewport().t, Scroll_Zones);
            });
            runScrollEvents(getViewport().t, Scroll_Zones);
        }
    }

    initPublicKeys() {
        window.addEventListener('keydown', ({ key }) => {
            if (key === 'Escape') {
                layer_store.dispatch({
                    type: 'CLOSE_PANEL',
                });
            }
        }, true);
    }

    initToolsKeys() {
        window.addEventListener('keydown', ({ ctrlKey, key }) => {
            if (key === 'Delete') {
                if (
                    document.getElementsByClassName('glass').length === 0 &&
                    document.getElementsByClassName('pop_overlay_toolbar').length === 0 &&
                    Boolean(document.getElementById('action_delete:visible'))
                ) {
                    document.getElementById('action_delete:visible').click();
                    //buildClickToolsAction
                }
            } else if (ctrlKey && key === 'c') {
                if (
                    document.getElementsByClassName('glass').length === 0 &&
                    document.getElementsByClassName('pop_overlay_toolbar').length === 0 &&
                    Boolean(document.getElementById('action_copy:visible'))
                ) {
                    document.getElementById('action_copy:visible').click();
                }
            } else if (ctrlKey && key === 'x') {
                if (
                    document.getElementsByClassName('glass').length === 0 &&
                    document.getElementsByClassName('pop_overlay_toolbar').length === 0 &&
                    Boolean(document.getElementById('action_cut:visible'))
                ) {
                    document.getElementById('action_cut:visible').click();
                }
            } else if (ctrlKey && key === 'v') {
                if (
                    document.getElementsByClassName('glass').length === 0 &&
                    document.getElementsByClassName('pop_overlay_toolbar').length === 0 &&
                    Boolean(document.getElementById('action_paste:visible'))
                ) {
                    document.getElementById('action_paste:visible').click();
                }
            }
        }, true);
    }

    packFunctionData(data) {
        const { Navigation_Data, project, user_role, Site, Page, use_service } = data;

        return deepmerge(
            Navigation_Data,
            {
                project,
                user_role,
                site_id: Site && Site.id ? Site.id : undefined,
                page_id: Page && Page.id ? Page.id : undefined,
                use_service: Boolean(use_service)
            }
        );
    }

    setData() {
        if (this.data) {
            this.initScrollZonesMechanism();
            if (this.data.show_tools) {

                /*
                this.initToolsKeys();
                Right_Click_Actions = App.Tools.Toolbar.RightClickActions.build(Function_Data);
                document.body.append(Right_Click_Actions.html);
                document.body.click(() => {
                        $('#right_click_tools').hide();
                    });
                */
            }
        }
        if (this.oldData) {
            this.oldData = null;
            this.forceUpdate(() => {
                window.scrollTo(0, 0);
                if (
                    this.data &&
                    this.data.Site &&
                    this.data.Site.page_transition_effect === 'slideLeft'
                ) {
                    this.loadInSecond = !this.loadInSecond;
                }

                /*for (let i = 0; i < document.styleSheets.length - 1; i++) {
                    if (document.styleSheets[i].title === 'generated_page_css') {
                        document.styleSheets[i].disabled = true;
                    }
                }*/
            });
        }
    }

    onCompleted() {
        if (this.data && this.data.Site && this.data.Site.page_transition_effect === 'none') {
            this.setData.bind(this)();
        } else {
            setTimeout(this.setData.bind(this), 900, this);
        }
    }

    renderPage() {
        this.isNavigating = false;
        this.trySwitchDevice();
        this.setBaseFontSize.bind(this);
        const Function_Data = this.data ? this.packFunctionData(this.data) : {};
        const minimum_device_width = this.data && this.data.Devices ?
            this.data.Devices.find(Device => Device.type === this.Navigation_Data.device_type).minimum_width :
            0;

        if (this.data && this.data.User) {
            user_store.dispatch({
                type: 'INIT',
                User: this.data.User
            });
        }

        return (
            <Main
                Function_Data={Function_Data}
                data={this.data}
                oldData={this.oldData}
                navigate={this.navigateAndStore.bind(this)}
                loadInSecond={this.loadInSecond}
                minimum_device_width={minimum_device_width}
            />
        );
    }

    render() {
        const { isLoading } = this.state;
        return (
            <Fragment>
                {!isLoading && this.renderPage()}
                <Layers
                    navigate={this.navigateAndStore.bind(this)}
                    Navigation_Data={this.Navigation_Data}
                />
            </Fragment>
        );
    }
}

// eslint-disable-next-line no-undef
export default hot(module)(App);
