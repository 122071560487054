export const distillFontFamilyId = (Fonts, value) => {
    var font_family_id = 'unknown';
    value = value.split(',')[0].replace(/[^0-9a-z- ]/g, '');

    for (var i = 0; i < Fonts.length; i++) {
        if (value === Fonts[i]['main']) {
            font_family_id = Fonts[i]['id'];
            break;
        }
    }

    return font_family_id;
};