import { createStore } from 'redux';

const initialState = {
    action: '',
    Function_Data: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'CACHE_DATA':
            state.Function_Data = action.data;
            return state;
        default:
            return state;
    }
});

export default store;
