import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { upload } from '../../api/upload_query';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils';

//TODO: merge this class with "image_management" !!!!

export default class ImageSelect {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return deepmerge(Load_Data, {
            Selected_Image: {
                resource_id: Action_Data.data_action_type === 'create' ? 0 : Load_Data.resource_id,
                file_type: Action_Data.data_action_type === 'create' ? 0 : Load_Data.image_origin_file_type
            }
        });
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Images
            image_origin_file_type
            resource_id
            total_amount
            max_file_uploads
            upload_max_filesize
            post_max_size
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', full_width: true, id: 'Selected_Image' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [
                        [
                            { type: 'row' },
                            {
                                Content: [
                                    [
                                        { type: 'button_image', id: 'upload_new_image', h_align: 'left', v_align: 'center' },
                                        {
                                            name: 'upload_new_image',
                                            button_style: [110, 32, 0, 4, 0, 4, 8],
                                            text: 'Upload',
                                            img_url: getImageURL('rootflex', 'choose_file'),
                                            img_style: [0, 37, 35, 0],
                                            file_upload: true,
                                            filetype_restriction: 'image/*',
                                            onChange: Files => {
                                                upload(
                                                    Function_Data,
                                                    frm_id,
                                                    Data_Groups,
                                                    'upload_new_image',
                                                    Files,
                                                    {},
                                                    'upload_images',
                                                    Current_Data.max_file_uploads,
                                                    Current_Data.upload_max_filesize,
                                                    Current_Data.post_max_size,
                                                    Result_Data => {
                                                        const new_total = Current_Data.total_amount + Result_Data.Uploaded_Images.length;
                                                        changeCurrentData(Action_Data.amount > Current_Data.Images.length ?
                                                            {
                                                                Images: clone(Current_Data.Images).concat(
                                                                    Result_Data.Uploaded_Images
                                                                        .slice(0, Action_Data.amount - Current_Data.Images.length)
                                                                        .map(Image =>
                                                                            Object.assign({}, Image, {
                                                                                site_id: Function_Data.site_id
                                                                            })
                                                                        )
                                                                ),
                                                                total_amount: new_total
                                                            } :
                                                            { total_amount: new_total }
                                                        );
                                                    }
                                                );
                                            }
                                        }
                                    ],
                                    [
                                        { type: 'text', padding_left: 10, h_align: 'left', v_align: 'center' },
                                        { text: 'The uploads will be added to the collection below.<br>Supported file-extensions: jpg, jpeg, jfif, png, gif.', style: 'bold' }
                                    ],
                                    [
                                        {
                                            type: 'image',
                                            id: 'resource_id_selected_preview',
                                            colspan: 2,
                                            h_align: 'right',
                                            padding_left: 10,
                                            padding_right: 10,
                                            padding_top: 3,
                                            padding_bottom: 3
                                        },
                                        {
                                            src: parseInt(Current_Data.resource_id, 10) === 0 ?
                                                'projects/rootflex/img/form/none_selected.jpg' :
                                                'projects/rootflex/cache/' +
                                                (Function_Data.use_service ? 'service' : 'user') +
                                                '/' + Function_Data.site_id + '/img/w150h100/' +
                                                Current_Data.resource_id + '.' +
                                                Current_Data.image_origin_file_type,
                                            show_transparency_background: true
                                        }
                                    ]
                                ]
                            }
                        ]
                    ]
                }
            ],
            wrapSectionSubs('Selected_Image', [
                [
                    { type: 'text' },
                    { text: 'Click on an image to select', style: 'bold' }
                ],
                [
                    { type: 'media_selector', id: 'resource_id' },
                    {
                        Items: Current_Data.Images,
                        Selected_Item: {
                            id: Current_Data.resource_id,
                            file_type: Current_Data.image_origin_file_type
                        },
                        media_type: 'image',
                        lightbox_path: 'projects/rootflex/cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/img/w750h600/',
                        thumbnail_path: 'projects/rootflex/cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/img/w150h100/',
                        width: 150,
                        height: 100,
                        start: 1,
                        total_amount: Current_Data.total_amount,
                        column_amount: 5
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                const resource_id = parseInt(Current_Data.Selected_Image.resource_id, 10); //TODO: check if neccesary to parseInt

                if ((isNaN(resource_id) || resource_id === 0)) {
                    alert('Cannot save, because no image was selected.');
                } else {
                    if (Action_Data.data_action_type === 'create') {
                        //submit_action = 'add_element';
                        // TODO store form values to be used in later post-submit to back-end
                        updateActionData({
                            frm_name: 'layout_settings',
                            resource_id: resource_id
                        });
                    } else if (Action_Data.el_type !== 'image') {
                        //submit_action = 'select_image';
                        //TODO: use selected image in other panel
                        /*$('body').trigger('reload_image_fields', {
                            action: 'select',
                            selected_resource_id: resource_id,
                            selected_file_type: Current_Data.Selected_Image.file_type
                        });*/

                        layer_store.dispatch({
                            type: 'CLOSE_PANEL',
                        });
                    } else {
                        form_data_store.dispatch({
                            type: 'SUBMIT',
                            submitData: {
                                frm_id,
                                submit_action: 'switch_image',
                                Function_Data,
                                process_tag: 'Saving...',
                                onSubmitted: Result_Data => {
                                }
                            }
                        });
                    }
                }
            },
                Action_Data.data_action_type === 'create' ? 'Next' :
                    (Action_Data.el_type !== 'image' ? 'Select' : 'Save')
            )
        ];
    }
}