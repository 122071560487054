import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class SlideshowSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Slideshow: {
                    effect: 'fade',
                    timeout: 4000,
                    speed: 1000,
                    randomize: 'true'
                }
            } :
            clone(Load_Data);
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Slideshow {
                effect: String
                timeout
                speed
                randomize: String
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Slideshow', [
                [
                    { type: 'dropdown', id: 'effect', label: 'Effect' },
                    {
                        value: Current_Data.Slideshow.effect,
                        Options: [
                            ['none', 'None'],
                            ['blindX', 'BlindX'],
                            ['blindY', 'BlindY'],
                            ['blindZ', 'BlindZ'],
                            ['cover', 'Cover'],
                            ['curtainX', 'CurtainX'],
                            ['curtainY', 'CurtainY'],
                            ['fade', 'Fade'],
                            ['fadeZoom', 'Fade Zoom'],
                            ['growX', 'GrowX'],
                            ['growY', 'GrowY'],
                            ['scrollUp', 'Scroll Up'],
                            ['scrollDown', 'Scroll Down'],
                            ['scrollLeft', 'Scroll Left'],
                            ['scrollRight', 'Scroll Right'],
                            ['scrollHorz', 'Scroll Horizontal'],
                            ['scrollVert', 'Scroll Vertical'],
                            ['shuffle', 'Shuffle'],
                            ['slideX', 'SlideX'],
                            ['slideY', 'SlideY'],
                            ['toss', 'Toss'],
                            ['turnUp', 'Turn Up'],
                            ['turnDown', 'Turn Down'],
                            ['turnLeft', 'Turn Left'],
                            ['turnRight', 'Turn Right'],
                            ['uncover', 'Uncover'],
                            ['wipe', 'Wipe'],
                            ['zoom', 'Zoom']
                        ]
                    }
                ],
                [
                    { type: 'slider', id: 'timeout', label: 'Time between transition (ms)' },
                    { value: Current_Data.Slideshow.timeout, min_val: 100, max_val: 10000 }
                ],
                [
                    { type: 'slider', id: 'speed', label: 'Transition speed (ms)' },
                    { value: Current_Data.Slideshow.speed, min_val: 100, max_val: 10000 }
                ],
                [
                    { type: 'checkbox', id: 'randomize', label: 'Randomize' },
                    { value: Current_Data.Slideshow.randomize, styling_type: 'switch' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        access: 'authorized',
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_slideshow_settings',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save')
        ];
    }
}