import cloneDeep from 'clone-deep';

export const clone = (obj) => {
    let isDeep = false;

    if (obj === null) {
        return null;
    }

    if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
            return obj.map(subobj => clone(subobj));
        } else {
            for (const key in obj) {
                if (obj[key] !== null && !['number', 'string', 'boolean', 'undefined'].indexOf(typeof obj[key]) > -1) {
                    isDeep = true;
                    break;
                }
            }
        }
    }

    return isDeep ? cloneDeep(obj) : (typeof obj === 'object' ? Object.assign({}, obj) : obj);
};
