import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import media_item_store from '../../api/media_item_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class MediaProperties {
    getQuery() {
        return `query DataQuery($urls: String!) {
            resource_id: Int
            Media_Properties {
                description: String
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Media_Properties', [
                [
                    { type: 'field', id: 'description', label: 'Image description' },
                    { value: Current_Data.Media_Properties.description, max: 2 }
                ],
                [
                    { type: 'text', colspan: 2 },
                    {
                        text:
                            'The image description is placed in the HTML "alt" attribute<br>' +
                            'of the image tag. This tag is meant as alternative content<br>' +
                            'in case the image fails to load. It\'s also good for SEO.',
                        style: 'bold'
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'save_media_properties',
                        Function_Data,
                        process_tag: 'Saving...',
                        onSubmitted: Result_Data => {
                            if (Result_Data) {
                                media_item_store.dispatch({
                                    type: 'UPDATE',
                                    resource_id: Current_Data.resource_id,
                                    Updated_Media_Properties: deepmerge(
                                        Current_Data.Media_Properties,
                                        Result_Data.Media_Properties
                                    )
                                });
                            }
                        }
                    }
                });
            })
        ];
    }
}