import React, { Component } from 'react';
import Checkbox from '../layer/column/checkbox';
import Dropdown from '../layer/column/dropdown';
import ImagePicker from '../layer/column/image_picker';
import Radio from '../layer/column/radio';
import { buildOriginImagePath } from '../utils/path';

//SHOULD GET RID OF THIS FILE, USE CLASSES INSIDE "LAYER/COLUMN" DIRECTLY

export class DropdownColumn extends Component {
    render() {
        const { frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, changeCurrentData, parent_disabled } = this.props;
        const { Options, readonly, onChange, value, margin_top, width, width_type, styling_type } = El_Data;

        return (
            <Dropdown
                El_Data={{
                    Options: Options.map(Option => [Option.value, Option.label]),
                    value: value || '',
                    disabled: show_tools ? true : readonly === 'true',
                    onChange: onChange || null,
                    margin_top: margin_top,
                    width: width,
                    width_type: width_type,
                    size: styling_type
                }}
                tab={tab}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                fld_id={name}
                changeCurrentData={changeCurrentData}
                Col_Settings={Col_Settings}
                parent_disabled={parent_disabled}
            />
        );
    }
}

export class CheckboxColumn extends Component {
    render() {
        const
            { frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, changeCurrentData, parent_disabled } = this.props,
            { value, styling_type, readonly, onClick } = El_Data;

        return (
            <Checkbox
                El_Data={{
                    readonly: show_tools ? true : readonly === 'true',
                    value: value,
                    styling_type: styling_type,
                    onClick: onClick
                }}
                tab={tab}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                fld_id={name}
                changeCurrentData={changeCurrentData}
                Col_Settings={Col_Settings}
                parent_disabled={parent_disabled}
            />
        );
    }
}

export class RadioColumn extends Component {
    render() {
        const { frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, changeCurrentData, parent_disabled } = this.props;
        const { Options, readonly, onChange, value } = El_Data;

        return (
            <Radio
                El_Data={{
                    Options: Options.map(Option => [Option.value, Option.label]),
                    direction: 'hor', //TODO
                    readonly: show_tools ? true : readonly === 'true',
                    value: value || '',
                    onChange: onChange || null
                }}
                tab={tab}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                fld_id={name}
                changeCurrentData={changeCurrentData}
                Col_Settings={Col_Settings}
                parent_disabled={parent_disabled}
            />
        );
    }
}

export class ImageTextSelectColumn extends Component {
    render() {
        const { project, site_id, frm_id, name, Data_Groups, El_Data, Col_Settings, tab, show_tools, changeCurrentData, parent_disabled, use_service } = this.props;
        const { Options, width, height, column_amount_max, readonly, onChange, value } = El_Data;

        return (
            <ImagePicker
                El_Data={{
                    Options: Options.map(Option => ({
                        top: Option.top,
                        left: Option.left,
                        value: Option.value,
                        label: Option.label,
                        filename: buildOriginImagePath(project, site_id, use_service, false, true) + Option.resource_id + '.' + Option.file_type
                    })),
                    width: width,
                    height: height,
                    column_amount_max: column_amount_max,
                    readonly: show_tools ? true : readonly === 'true',
                    value: value || '',
                    onChange: onChange || null
                }}
                Function_Data={{
                    base_url: ''
                }}
                tab={tab}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                fld_id={name}
                changeCurrentData={changeCurrentData}
                Col_Settings={Col_Settings}
                parent_disabled={parent_disabled}
            />
        );
    }
}