export const isMobile = () => {
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)) ? true : false;
};

export const getScrollBarSize = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    const scrollbarSize = 100 - inner.offsetWidth;
    outer.remove();
    return scrollbarSize;
};

export const getViewport = () => {
    const c = /*document.documentElement || */document.compatMode === 'CSS1Compat';
    //What about window.scrollX  & window.scrollY  ???

    return {
        l: window.pageXOffset || (c ? document.documentElement.scrollLeft : document.body.scrollLeft),
        t: window.pageYOffset || (c ? document.documentElement.scrollTop : document.body.scrollTop),
        w: window.innerWidth || (c ? document.documentElement.clientWidth : document.body.clientWidth),
        h: window.innerHeight || (c ? document.documentElement.clientHeight : document.body.clientHeight)
    };
};

export const getPageSize = () => {
    const viewport = getViewport();
    const isBiggerThanViewport = document.body.scrollHeight > document.body.offsetHeight;
    const xScroll = isBiggerThanViewport ? document.body.scrollWidth : document.body.offsetWidth;
    const yScroll = isBiggerThanViewport ? document.body.scrollHeight : document.body.offsetHeight;

    return {
        pw: xScroll < viewport.w ? xScroll : viewport.w,
        ph: yScroll < viewport.h ? viewport.h : yScroll
    };
};

export const getOffset = (el, isRelativeToWindow) => {
    const rect = el.getBoundingClientRect();
    const offset = {
        //neccesary to normalize as object, otherwise the properties are "getters" which can't be reassigned.
        t: rect.top,
        l: rect.left
    };

    if (!isRelativeToWindow) {
        const viewport = getViewport();
        return {
            t: offset.t + viewport.t,
            l: offset.l + viewport.l
        };
    }

    return offset;
};

export const getInnerSize = el => {
    //e.g. size without padding, borders, margins
    const dim = getDimensions(el);
    return {
        w: dim.w,
        h: dim.h
    };
};

export const getSize = (el, isBordersRequired = true) => {
    //TODO: could improve with:
    //https://stackoverflow.com/questions/21064101/understanding-offsetwidth-clientwidth-scrollwidth-and-height-respectively
    //e.g. size with padding, without margins (optionally exclude borders)
    return {
        w: isBordersRequired ? el.offsetWidth : el.clientWidth,
        h: isBordersRequired ? el.offsetHeight : el.clientHeight,
        sw: el.scrollWidth,
        sh: el.scrollHeight
    };
};

export const getDecimalSize = (el) => {
    const rect = el.getBoundingClientRect();
    return {
        w: rect.width,
        h: rect.height
    };
};

export const getOuterSize = el => {
    //e.g. size with padding, borders, margins
    const dim = getDimensions(el);

    return {
        w: dim.w + dim.p.l + dim.p.r + dim.b.l + dim.b.r + dim.m.l + dim.m.r,
        h: dim.h + dim.p.t + dim.p.b + dim.b.t + dim.b.b + dim.m.t + dim.m.b
    };
};

export const getDimensions = el => {
    const s = el.currentStyle || window.getComputedStyle(el, null);
    const val = k => {
        const propVal = s.getPropertyValue(k).replace('px', '');
        return /^\d+$/.test(propVal) ? parseInt(propVal, 10) : 0;
    };

    return {
        w: val('width'),
        h: val('height'),
        p: {
            l: val('paddingLeft'),
            r: val('paddingRight'),
            t: val('paddingTop'),
            b: val('paddingBottom')
        },
        b: {
            l: val('borderLeftWidth'),
            r: val('borderRightWidth'),
            t: val('borderTopHeight'),
            b: val('borderBottomHeight')
        },
        m: {
            l: val('marginLeft'),
            r: val('marginRight'),
            t: val('marginTop'),
            b: val('marginBottom')
        }
    };
};

export const runScrollEvents = (scroll_pos, Scroll_Zones) => {
    Scroll_Zones.forEach(Scroll_Zone => {
        if (
            (!Scroll_Zone.min_position || scroll_pos >= parseInt(Scroll_Zone.min_position, 10)) &&
            (!Scroll_Zone.max_position || scroll_pos <= parseInt(Scroll_Zone.max_position, 10))
        ) {
            document.body.classList.add('scroll_zone_' + Scroll_Zone.id);
        } else {
            document.body.classList.remove('scroll_zone_' + Scroll_Zone.id);
        }
    });
};

export const toggleBodyOverflow = (isHidden) => {
    if (isHidden) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
};