import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import resolution_store from '../../api/resolution_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class ResolutionSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Resolution: {
                    id: '',
                    use_min_width: 'true',
                    min_width: '',
                    use_max_width: 'true',
                    max_width: '',
                    use_min_height: 'true',
                    min_height: '',
                    use_max_height: 'true',
                    max_height: ''
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Resolution {
                    id
                    use_min_width
                    min_width
                    use_max_width
                    max_width
                    use_min_height
                    min_height
                    use_max_height
                    max_height
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs(
                'Resolution',
                [
                    [
                        { type: 'space', colspan: 2, height: 23 }
                    ],
                    [
                        { type: 'grid' },
                        {
                            Content: [[
                                [
                                    { type: 'text' },
                                    { text: 'Width', style: 'bold' }
                                ]
                            ], [
                                [
                                    { type: 'checkbox', id: 'use_min_width', label: 'Minimum' },
                                    { value: Current_Data.Resolution.use_min_width, styling_type: 'switch' }
                                ],
                                [
                                    { type: Current_Data.Resolution.use_min_width === 'true' ? 'number' : 'hidden', id: 'min_width' },
                                    { value: Current_Data.Resolution.min_width, min_val: 0, max_val: 9999, unit: 'px' }
                                ]
                            ], [
                                [
                                    { type: 'checkbox', id: 'use_max_width', label: 'Maximum' },
                                    { value: Current_Data.Resolution.use_max_width, styling_type: 'switch' }
                                ],
                                [
                                    { type: Current_Data.Resolution.use_max_width === 'true' ? 'number' : 'hidden', id: 'max_width' },
                                    { value: Current_Data.Resolution.max_width, min_val: 0, max_val: 9999, unit: 'px' }
                                ]
                            ], [
                                [
                                    { type: 'text' },
                                    { text: 'Height', style: 'bold' }
                                ]
                            ], [
                                [
                                    { type: 'checkbox', id: 'use_min_height', label: 'Minimum' },
                                    { value: Current_Data.Resolution.use_min_height, styling_type: 'switch' }
                                ],
                                [
                                    { type: Current_Data.Resolution.use_min_height === 'true' ? 'number' : 'hidden', id: 'min_height' },
                                    { value: Current_Data.Resolution.min_height, min_val: 0, max_val: 9999, unit: 'px' }
                                ]
                            ], [
                                [
                                    { type: 'checkbox', id: 'use_max_height', label: 'Maximum' },
                                    { value: Current_Data.Resolution.use_max_height, styling_type: 'switch' }
                                ],
                                [
                                    { type: Current_Data.Resolution.use_max_height === 'true' ? 'number' : 'hidden', id: 'max_height' },
                                    { value: Current_Data.Resolution.max_height, min_val: 0, max_val: 9999, unit: 'px' }
                                ]
                            ]]
                        }
                    ]
                ]
            ),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_resolution' : 'save_resolution',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Adding...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            if (Result_Data) {
                                const New_Resolution = deepmerge(
                                    Current_Data.Resolution,
                                    Result_Data
                                );
                                if (Action_Data.data_action_type === 'create') {
                                    resolution_store.dispatch({
                                        type: 'ADD',
                                        New_Resolution: New_Resolution
                                    });
                                } else {
                                    resolution_store.dispatch({
                                        type: 'UPDATE',
                                        Updated_Resolution: New_Resolution
                                    });
                                }
                            }
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Add' : 'Save')
        ];
    }
}