import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import text_styling_store from '../../api/text_styling_store';
import { getImageURL, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findArray, findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';
import { ucWords } from '../../utils/string';

export default class TextStylingManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [text_styling_store.subscribe(this.handleTextStyleStoreChange(changeCurrentData, getCurrentData))];
    }

    handleTextStyleStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = text_styling_store.getState();
            if (state.action === 'remove') {
                const Current_Data = getCurrentData();
                const index = findIndexArray(
                    Current_Data.Text_Styles,
                    Text_Style => parseInt(Text_Style.id, 10),
                    parseInt(state.removed_id, 10)
                );

                let Changed_Text_Styles = clone(Current_Data.Text_Styles);
                Changed_Text_Styles.splice(index, 1);
                changeCurrentData(this.getDefaultData(Changed_Text_Styles, Current_Data.Font_Families, null));
            }
        };
    }

    convertLoadDataToCurrentData(Load_Data) {
        return this.getDefaultData(Load_Data.Text_Styles, Load_Data.Font_Families, null);
    }

    getDefaultData(Text_Styles, Font_Families, given_id) {
        let status = 'creating';
        let Active_Text_Style = {
            id: '',
            name: '',
            font_family_id: Font_Families.length > 0 ? Font_Families[0].id : '',
            color: '000',
            opacity: 100,
            font_size: 100,
            line_height: 135,
            letter_spacing: 0,
            font_weight: false,
            font_style: false,
            text_decoration: false,
            Shadows: []
        };

        if (Text_Styles.length > 0) {
            status = 'browsing';
            const Found_Text_Style = findArray(
                Text_Styles,
                Text_Style => parseInt(Text_Style.id, 10) === parseInt(given_id, 10)
            );
            Active_Text_Style = clone(Found_Text_Style ? Found_Text_Style : Text_Styles[0]);
        }

        return {
            status,
            Active_Text_Style,
            Text_Styles,
            Font_Families,
            Tools_Text_Style: {
                new_text_style: status === 'creating' ? 'true' : 'false',
                text_style_id: Active_Text_Style.id,
                name: Active_Text_Style.name,
            }
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Font_Families
            Text_Styles
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', id: 'Tools_Text_Style' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [
                        [
                            { type: Current_Data.status === 'browsing' ? 'row' : 'hidden', id: 'show_text_style_view' },
                            {
                                Content: [
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'add_text_style',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'open_add_text_style_panel',
                                            button_style: [92, 33, 2],
                                            text: 'Create',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 32, 32, 125],
                                            onClick: () => {
                                                changeCurrentData({
                                                    Active_Text_Style: {
                                                        name: '',
                                                        id: ''
                                                    },
                                                    status: 'creating',
                                                    Tools_Text_Style: {
                                                        new_text_style: 'true',
                                                        text_style_id: '',
                                                        name: '',
                                                    },
                                                });
                                            }
                                        }
                                    ],
                                    [
                                        { type: 'dropdown', id: 'text_style_id' },
                                        {
                                            value: Current_Data.Tools_Text_Style ? Current_Data.Tools_Text_Style.text_style_id : null,
                                            Options: Current_Data.Text_Styles ?
                                                Current_Data.Text_Styles.map(Text_Style => [
                                                    Text_Style.id,
                                                    Text_Style.name
                                                ]) :
                                                [],
                                            margin_top: 8,
                                            onChange: value => {
                                                const Active_Text_Style = clone(findArray(
                                                    Current_Data.Text_Styles,
                                                    Text_Style => parseInt(Text_Style.id, 10) === parseInt(value, 10)
                                                ));
                                                return {
                                                    Active_Text_Style,
                                                    Tools_Text_Style: {
                                                        text_style_id: Active_Text_Style.id,
                                                        name: Active_Text_Style.name,
                                                    },
                                                };
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'edit_text_style',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'enter_text_style_editing_mode',
                                            button_style: [79, 33, 2],
                                            text: 'Edit',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 33, 32, 166],
                                            onClick: () => {
                                                changeCurrentData({
                                                    status: 'editing',
                                                    Tools_Text_Style: {
                                                        new_text_style: 'false',
                                                    },
                                                });
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'delete_text_style',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'open_delete_text_style_panel',
                                            button_style: [90, 33, 2],
                                            text: 'Delete',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 32, 32, 207],
                                            onClick: () => {
                                                layer_store.dispatch({
                                                    type: 'OPEN_PANEL',
                                                    panel_data: {
                                                        Function_Data,
                                                        Action_Data: deepmerge(
                                                            Action_Data,
                                                            {
                                                                access: 'authorized',
                                                                panel_type: 'remove_text_styling_panel',
                                                                frm_name: 'remove_text_styling',
                                                                text_style_id: parseInt(Current_Data.Active_Text_Style.id, 10),
                                                                parent_frm_id: frm_id
                                                            }
                                                        )
                                                    },
                                                });
                                            }
                                        }
                                    ]
                                ]
                            }
                        ],
                        [
                            {
                                type: Current_Data.status === 'creating' ||
                                    Current_Data.status === 'editing' ?
                                    'row' :
                                    'hidden',
                                id: 'edit_text_style_view'
                            },
                            {
                                Content: [
                                    [
                                        { type: 'field', id: 'name', label: 'Style name' },
                                        {
                                            value: Current_Data.Tools_Text_Style ? Current_Data.Tools_Text_Style.name : null,
                                            onChange: value => {
                                                return {
                                                    Active_Text_Style: {
                                                        name: value
                                                    },
                                                };
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'save_text_style',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'save_text_style',
                                            button_style: [79, 33, 2],
                                            text: 'Save',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 32, 32, 249],
                                            onClick: () => {
                                                form_data_store.dispatch({
                                                    type: 'SUBMIT',
                                                    submitData: {
                                                        frm_id,
                                                        submit_action: 'save_text_style',
                                                        Function_Data,
                                                        process_tag: 'Saving...',
                                                        onSubmitted: Result_Data => {
                                                            let New_Text_Style = deepmerge(
                                                                Current_Data.Active_Text_Style,
                                                                {
                                                                    site_id: Function_Data.site_id,
                                                                    name: Result_Data.name,
                                                                    Shadows: Result_Data.Shadows,
                                                                    id: parseInt(Result_Data.text_style_id, 10)
                                                                },
                                                                { arrayMerge: (dest, src) => src }//Necessary to overwrite Shadows
                                                            );
                                                            let Changed_Text_Styles = clone(Current_Data.Text_Styles);
                                                            if (Current_Data.status === 'creating') {
                                                                Changed_Text_Styles = Changed_Text_Styles.concat(New_Text_Style);
                                                            } else {
                                                                const index = findIndexArray(
                                                                    Changed_Text_Styles,
                                                                    Text_Style => parseInt(Text_Style.id, 10),
                                                                    parseInt(Result_Data.text_style_id, 10)
                                                                );
                                                                Changed_Text_Styles[index] = New_Text_Style;
                                                            }
                                                            changeCurrentData({
                                                                Text_Styles: Changed_Text_Styles,
                                                                Active_Text_Style: New_Text_Style,
                                                                status: 'browsing',
                                                                Tools_Text_Style: {
                                                                    new_text_style: 'false',
                                                                    text_style_id: New_Text_Style.id,
                                                                    name: New_Text_Style.name,
                                                                },
                                                            });
                                                        },
                                                        isPanelRequiredToStayOpen: true
                                                    }
                                                });
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'cancel_text_style',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'exit_text_style_editing_mode',
                                            button_style: [88, 33, 2],
                                            text: 'Cancel',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 29, 32, 290],
                                            onClick: () => {
                                                changeCurrentData(
                                                    this.getDefaultData(
                                                        Current_Data.Text_Styles,
                                                        Current_Data.Font_Families,
                                                        parseInt(Current_Data.Active_Text_Style.id, 10)
                                                    )
                                                );
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    ]
                }
            ],
            wrapSectionSubs('Active_Text_Style',
                [
                    [
                        {
                            type: 'text_style',
                            id: 'text_style_preview',
                            label: ' ',
                            h_align: 'left',
                            padding_top: 3,
                            padding_bottom: 3
                        },
                        {
                            Data: Current_Data.Active_Text_Style,
                            Fonts: Current_Data.Font_Families,
                            width: 531,
                            height: 80,
                            preview_text: 'Example',
                            show_transparency_background: true
                        }
                    ],
                    [
                        { type: 'dropdown', id: 'font_family_id', label: 'Font family' },
                        {
                            value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.font_family_id : null,
                            Options: Current_Data.Font_Families ?
                                Current_Data.Font_Families.map(Font_Family => [
                                    Font_Family.id,
                                    ucWords(Font_Family.main)
                                ]) :
                                [],
                            width: 260
                        }
                    ],
                    [
                        { type: 'color', id: 'color', label: 'Color' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.color : null }
                    ],
                    [
                        { type: 'number', id: 'color_opacity', label: 'Opacity' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.color_opacity : null, min_val: 0, max_val: 100, unit: '%' }
                    ],
                    [
                        { type: 'number', id: 'font_size', label: 'Size' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.font_size : null, min_val: 1, max_val: 9999, unit: '%' }
                    ],
                    [
                        { type: 'number', id: 'line_height', label: 'Line height' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.line_height : null, min_val: 1, max_val: 9999, unit: '%' }
                    ],
                    [
                        { type: 'number', id: 'letter_spacing', label: 'Letter spacing' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.letter_spacing : null, min_val: -100, max_val: 100, unit: '%' }
                    ],
                    [
                        { type: 'checkbox', id: 'font_weight', label: 'Bold' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.font_weight : null, styling_type: 'switch' }
                    ],
                    [
                        { type: 'checkbox', id: 'font_style', label: 'Italic' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.font_style : null, styling_type: 'switch' }
                    ],
                    [
                        { type: 'checkbox', id: 'text_decoration', label: 'Underline' },
                        { value: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.text_decoration : null, styling_type: 'switch' }
                    ],
                    [
                        { type: 'text' },
                        { text: 'Shadows', style: 'bold' }
                    ],
                    [
                        { type: 'shadow_editor', id: 'shadows', label: ' ' },
                        { Shadows: Current_Data.Active_Text_Style ? Current_Data.Active_Text_Style.Shadows : [] }
                    ]
                ],
                {},
                Current_Data.status === 'browsing',
                'You cannot edit here!\nTo edit the text style, click the button "Edit Style".\n' +
                'To create a text style with the current settings as a starting point, click "Create".'
            ),
            getSingleSubmitSet(frm_id,)
        ];
    }
}
