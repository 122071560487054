export const getPanelData = (Action_Data) => {
    const panel_type = Action_Data.panel_type
        .substring(0, Action_Data.panel_type.length - '_panel'.length);
    const el_type = Action_Data.el_type || '';

    return {
        title: getTitle(panel_type, el_type),
        Side_Options: getSideOptions(panel_type, el_type)
    };
};

const getTitle = (stripped_panel_type, el_type) => {
    return {
        user: 'User Management',
        language: 'Language Management',
        view: 'View Management',
        site: 'Website Management',
        create_site: 'Create New Website',
        remove_site: 'Remove Website',
        remove_file: 'Delete File',
        add_font_family: 'Add Font Family',
        edit_font_family: 'Edit Font Family',
        remove_font_family: 'Delete Font Family',
        custom_font_management: 'Manage Custom Fonts',
        add_custom_font: 'Create Custom Font',
        edit_custom_font: 'Edit Custom Font',
        remove_custom_font: 'Delete Custom Font',
        add_resolution: 'Create Resolution',
        edit_resolution: 'Edit Resolution',
        remove_resolution: 'Delete Resolution',
        add_scroll_zone: 'Create Scroll Zone',
        edit_scroll_zone: 'Edit Scroll Zone',
        remove_scroll_zone: 'Delete Scroll Zone',
        add_language: 'Create Language',
        edit_language: 'Edit Language',
        remove_language: 'Delete Language',
        module_variant: 'Conditional Style Variant',
        add_scope_group: 'Create Scope Group',
        scope_settings_group: 'Edit Scope Group',
        remove_scope_group: 'Delete Scope Group',
        page: 'Page Management',
        add_element: 'Create ' + el_type.charAt(0).toUpperCase() + el_type.substr(1),
        text_editor_behavior: 'Text link',
        element_options: el_type.charAt(0).toUpperCase() + el_type.substr(1) + ' Options',
        element_edit: el_type.charAt(0).toUpperCase() + el_type.substr(1) + ' Edit',
        element_layout: 'Edit Element Layout',
        row_column_size: 'Edit ' + el_type.charAt(0).toUpperCase() + el_type.substr(1) + ' Size',
        element_style: el_type.charAt(0).toUpperCase() + el_type.substr(1) + ' Styling',
        menu_styling_item: 'Style Menu',
        remove_element: 'Remove ' + el_type.charAt(0).toUpperCase() + el_type.substr(1),
        preset_settings: 'Create Preset',
        remove_preset: 'Delete Preset',
        remove_text_styling: 'Delete Text Style',
        remove_gradient: 'Delete Gradient',
        manage_gradients: 'Manage Gradients',
        background_switch_upload: 'Switch / Upload Background',
        media_properties: 'Edit Image Description',
        remove_origin_image: 'Delete Image',
        remove_origin_video: 'Delete Video',
        add_map_marker: 'Create Map Marker',
        edit_map_marker: 'Edit Map Marker',
        remove_map_marker: 'Delete Map Marker',
        error: 'Error'
    }[stripped_panel_type];
};

const getSideOptions = (panel_type, el_type) => {
    var Side_Options = {
        user: [
            ['User Settings', 'user', 'edit_user']/*,
            ['User Management', 'user', 'manage_users']*/
        ],
        language: [
            ['Languages', 'languages', 'manage_languages']
        ],
        view: [
            ['Devices', 'device_management', 'manage_devices'],
            ['Resolutions', 'resolution_management', 'manage_resolutions'],
            ['Scroll Zones', 'scroll_zone', 'manage_scroll_zones']
        ],
        site: [
            ['Website', 'globe', 'edit_site'],
            ['Images', 'manage_image', 'manage_images'],
            ['Videos', 'manage_video', 'manage_videos'],
            ['Files', 'manage_pages', 'manage_files'],
            ['Gradients', 'manage_gradient', 'manage_gradients'],
            ['Text Styles', 'text_styling', 'manage_text_style_presets'],
            ['Font-Families', 'font_family_management', 'manage_fonts']/*,
            ['Presets', 'settings', 'manage_presets'],
            ['Elements', 'settings', 'manage_elements']*/
        ],
        page: [
            ['Create Page', 'add_page', 'create_page'],
            ['Copy Page', 'copy_page', 'copy_page'],
            ['Page Options', 'settings', 'edit_page'],
            ['Remove Page', 'delete', 'remove_page']/*,
            ['Manage Pages', 'settings', 'manage_pages']*/,
            ['Scope Groups', 'scopes', 'manage_scopes']
        ],
        element_options:
            [['Display Scope', 'scope', 'edit_element_scope']]
                .concat(['box', 'icon', 'image', 'video'].includes(el_type) ?
                    [['Behavior', 'behavior', 'edit_element_behavior']] :
                    []
                ).concat(el_type === 'image' ?
                    [['Select Image', 'switch_image', 'image_select']] :
                    []
                ).concat(el_type === 'video' ?
                    [['Select Video', 'switch_video', 'video_select']] :
                    []
                ).concat(el_type === 'menu' ?
                    [['Reorder Menu', 'menu_structure', 'menu_structure']] :
                    []
                ).concat(['form', 'icon', 'map', 'menu', 'sequence', 'slideshow', 'text', 'twitter', 'video', 'form_col_section', 'form_col_grid', 'form_col_row', 'form_col_box_text', 'form_col_button', 'form_col_checkbox', 'form_col_color_field', 'form_col_date_field', 'form_col_dropdown', 'form_col_field', 'form_col_hidden', 'form_col_image', 'form_col_image_button', 'form_col_icon_link', 'form_col_image_text_select', 'form_col_line', 'form_col_radio_select', 'form_col_slider_field', 'form_col_space', 'form_col_text', 'form_col_textarea'].includes(el_type) ?
                    [['Settings', 'settings', 'edit_element_options']] :
                    []
                ),
        element_edit: [
            ['Edit', 'edit_text', 'edit_text'],
            ['Text Presets', 'text_style', 'manage_text_style_presets'],
            ['Manage Fonts', 'font_family_management', 'manage_fonts']
        ],
        row_column_size: [['Size', el_type + '_row_column_size', 'edit_' + el_type + '_size']],
        element_style:
            (el_type === 'box' || el_type === 'form' ?
                [
                    ['Background', 'box_background', 'edit_element_background'],
                    ['Border', 'box_border', 'edit_element_border'],
                    ['Corners', 'box_corner', 'edit_element_corner'],
                    ['Shadow', 'box_shadow', 'edit_element_shadow']
                ] :
                []
            ).concat(el_type === 'ground' ?
                [['Background', 'box_background', 'edit_element_background']] :
                []
            ),
        menu_styling_item: [
            ['Background', 'box_background', 'edit_element_background'],
            ['Border', 'box_border', 'edit_element_border'],
            ['Corners', 'box_corner', 'edit_element_corner'],
            ['Shadow', 'box_shadow', 'edit_element_shadow'],
            ['Box Layout', 'box_size', 'edit_layout'],
            ['Text Layout', 'pos_txt', 'edit_layout']
        ]
    };

    return (Side_Options[panel_type] || []).map(Side_Option => {
        return {
            label: Side_Option[0],
            icon: Side_Option[1],
            action: Side_Option[2]
        };
    });
};