import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_id: '',
    replacement_id: '',
    New_Custom_Font: {},
    Updated_Custom_Font: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD':
            state.New_Custom_Font = action.New_Custom_Font;
            return state;
        case 'UPDATE':
            state.Updated_Custom_Font = action.Updated_Custom_Font;
            return state;
        case 'REMOVE':
            state.removed_id = action.removed_id;
            state.replacement_id = action.replacement_id;
            return state;
        default:
            return state;
    }
});

export default store;