import Button from './column/button';
import ButtonImage from './column/button_image';
import Checkbox from './column/checkbox';
import Color from './column/color';
import DateEl from './column/date';
import Dropdown from './column/dropdown';
import ExternalFontsManagement from './column/external_fonts_management';
import Field from './column/field';
import FieldQ from './column/field_q';
import FieldS from './column/field_s';
import FilesManagement from './column/files_management';
import FontFamiliesManagement from './column/font_families_management';
import Gradient from './column/gradient';
import GradientEditor from './column/gradient_editor';
import Grid from './column/grid';
import IconLink from './column/icon_link';
import Image from './column/image';
import ImagePicker from './column/image_picker';
import LanguageManagement from './column/language_management';
import LayoutEditor from './column/layout_editor';
import Line from './column/line';
import MapMarkersManagement from './column/map_markers_management';
import MediaSelector from './column/media_selector';
import MenuStructure from './column/menu_structure';
import NumberEl from './column/number';
import OsFontsManagement from './column/os_fonts_management';
import Radio from './column/radio';
import ResolutionManagement from './column/resolution_management';
import RichDropdown from './column/rich_dropdown';
import Row from './column/row';
import ScopeManagement from './column/scope_management';
import ScrollZoneManagement from './column/scroll_zone_management';
import Section from './column/section';
import SelectorOptionsManagement from './column/selector_options_management';
import ShadowEditor from './column/shadow_editor';
import Slider from './column/slider';
import StyleAndText from './column/style_and_text';
import Text from './column/text';
import TextEditor from './column/text_editor';
import TextField from './column/text_field';
import TextStyle from './column/text_style';
import UploadedFontsManagement from './column/uploaded_fonts_management';

//TODO: use dynamic import, to support different projects using their own custom columns.

export const getColumnBuilder = (type) => {
    if (type === 'button_image') {
        return ButtonImage;
    } else if (type === 'button') {
        return Button;
    } else if (type === 'checkbox') {
        return Checkbox;
    } else if (type === 'color') {
        return Color;
    } else if (type === 'date') {
        return DateEl;
    } else if (type === 'dropdown') {
        return Dropdown;
    } else if (type === 'external_fonts_management') {
        return ExternalFontsManagement;
    } else if (type === 'field_q') {
        return FieldQ;
    } else if (type === 'field_s') {
        return FieldS;
    } else if (type === 'field') {
        return Field;
    } else if (type === 'files_management') {
        return FilesManagement;
    } else if (type === 'font_families_management') {
        return FontFamiliesManagement;
    } else if (type === 'gradient_editor') {
        return GradientEditor;
    } else if (type === 'gradient') {
        return Gradient;
    } else if (type === 'grid') {
        return Grid;
    } else if (type === 'icon_link') {
        return IconLink;
    } else if (type === 'image_picker') {
        return ImagePicker;
    } else if (type === 'image') {
        return Image;
    } else if (type === 'language_management') {
        return LanguageManagement;
    } else if (type === 'layout_editor') {
        return LayoutEditor;
    } else if (type === 'line') {
        return Line;
    } else if (type === 'map_markers_management') {
        return MapMarkersManagement;
    } else if (type === 'media_selector') {
        return MediaSelector;
    } else if (type === 'menu_structure') {
        return MenuStructure;
    } else if (type === 'number') {
        return NumberEl;
    } else if (type === 'os_fonts_management') {
        return OsFontsManagement;
    } else if (type === 'radio') {
        return Radio;
    } else if (type === 'resolution_management') {
        return ResolutionManagement;
    } else if (type === 'rich_dropdown') {
        return RichDropdown;
    } else if (type === 'row') {
        return Row;
    } else if (type === 'scope_management') {
        return ScopeManagement;
    } else if (type === 'scroll_zone_management') {
        return ScrollZoneManagement;
    } else if (type === 'section') {
        return Section;
    } else if (type === 'selector_options_management') {
        return SelectorOptionsManagement;
    } else if (type === 'shadow_editor') {
        return ShadowEditor;
    } else if (type === 'slider') {
        return Slider;
    } else if (type === 'style_and_text') {
        return StyleAndText;
    } else if (type === 'text_editor') {
        return TextEditor;
    } else if (type === 'text_style') {
        return TextStyle;
    } else if (type === 'text') {
        return Text;
    } else if (type === 'textarea') {
        return TextField;
    } else if (type === 'uploaded_fonts_management') {
        return UploadedFontsManagement;
    }

    return null;
};
