export const inArray = (value, array) => {
    return array.includes(value);
};

export const findArray = (array, method) => {
    return array ? array.filter(method)[0] : null;
};

export const findIndexArray = (array, method, value) => {
    return array.map(method).indexOf(value);
};

export const divideArrayInChunks = (array, chunk_size) => {
    let temp = [];

    for (let i = 0; i < array.length; i = i + chunk_size) {
        temp.push(array.slice(i, i + chunk_size));
    }

    return temp;

    /*
    // ALTERNATIVE
    return array.reduce((Chunks, Part, index) => {
        const group_index = Math.floor(index / chunk_size);

        if  (!Chunks[group_index]) {
            Chunks[group_index] = [];
        }

        Chunks[group_index].push(Part);

        return Chunks;
    }, []);
    */
};

export const shuffle = array => {
    return array
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map((a) => a[1]);
};

export const flatten = (array, isRecursive = false) => {
    return isRecursive ?
        recursiveFlat(array) :
        [].concat.apply([], array);
};

export const recursiveFlatten = array => {
    /* e.g. flatten deep*/
    return array.reduce((flat, toFlatten) =>
        flat.concat(Array.isArray(toFlatten) ? recursiveFlatten(toFlatten) : toFlatten), []
    );
};
