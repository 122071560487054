import { createStore } from 'redux';

const initialState = {
    action: '',
    data: {},
    data2: {},
};

const store = createStore((state = initialState, action) => {
    state.action = action.type;

    switch (action.type) {
        case 'CACHE_CRAP':
            state.data = action.data;
            return state;
        case 'CACHE_CRAP2':
            state.data2 = action.data;
            return state;
        default:
            return state;
    }
});

export default store;
