import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import language_store from '../../api/language_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class LanguageSettings {
    convertLoadDataToCurrentData(Load_Data) {
        const { Language_Options } = Load_Data;
        return deepmerge(
            Load_Data,
            {
                Language: {
                    language_type: Language_Options && Language_Options.length > 0 ? Language_Options[0].language_type : ''
                }
            }
        );
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Language_Options
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Language', [
                [
                    {
                        type: Current_Data.Language_Options.length > 0 ? 'space' : 'hidden',
                        colspan: 2,
                        height: 23
                    }
                ],
                [
                    {
                        type: Current_Data.Language_Options.length > 0 ? 'dropdown' : 'hidden',
                        id: 'language_type',
                        label: 'Language'
                    },
                    {
                        value: Current_Data.Language.language_type,
                        Options: Current_Data.Language_Options ?
                            Current_Data.Language_Options.map(Language => [Language.language_type, Language.name]) :
                            [],
                    }
                ],
                [
                    { type: Current_Data.Language_Options.length === 0 ? 'text' : 'hidden' },
                    {
                        text: 'All available languages are already added.',
                        style: 'normal_grey'
                    }
                ]
            ]),
            Current_Data.Language_Options.length === 0 ?
                getSingleSubmitSet(frm_id,) :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'add_language',
                            Function_Data,
                            process_tag: 'Adding...',
                            onSubmitted: new_language_id => {
                                if (new_language_id) {
                                    language_store.dispatch({
                                        type: 'ADD',
                                        New_Language: {
                                            name: Current_Data.Language_Options.find(
                                                Language => Language.language_type === Current_Data.Language.language_type
                                            ).name,
                                            site_id: Function_Data.site_id,//neccesary?
                                            default: 'false',
                                            id: new_language_id
                                        }
                                    });
                                }
                            }
                        }
                    });
                }, 'Add')
        ];
    }
}