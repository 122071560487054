import { convertHexToRGB } from '../utils/color';

export const buildCSSTextStyles = (Text_Styles_With_Font) => {
    var s = '';

    var Text_Style_With_Font;
    for (var i = 0; i < Text_Styles_With_Font.length; i++) {
        Text_Style_With_Font = Text_Styles_With_Font[i];
        s +=
            'p.txt_ps_' + Text_Style_With_Font.id + ',' +
            'span.txt_ps_' + Text_Style_With_Font.id + ',' +
            'a.txt_ps_' + Text_Style_With_Font.id + ' {' +
            (convertAndBuildTextStyle(Text_Style_With_Font)) +
            '}' +
            'a.txt_ps_' + Text_Style_With_Font.id + ' {text-decoration: underline;}';
    }

    return s;
};

export const convertAndBuildTextStyle = (Text_Style_With_Font) => {
    return buildCSSTextStyle(convertTextStyle(Text_Style_With_Font));
};

export const buildCSSTextStyle = (Text_Style) => {
    var s = {};
    if (typeof (Text_Style.Font) !== 'undefined') {
        s.fontFamily = buildCSSFontFamily(Text_Style.Font);
    }
    if (/^\d+$/.test(Text_Style.font_size)) {
        s.fontSize = Text_Style.font_size + '%';
    }
    if (/^\d+$/.test(Text_Style.line_height)) {
        s.lineHeight = Text_Style.line_height + '%';
    }
    if (/^\d+$/.test(Text_Style.letter_spacing)) {
        s.letterSpacing = (Text_Style.letter_spacing / 100) + 'em';
    }
    if (typeof (Text_Style.font_weight) !== 'undefined' && Text_Style.font_weight !== 'normal') {
        s.fontWeight = Text_Style.font_weight;
    }
    if (typeof (Text_Style.font_style) !== 'undefined' && Text_Style.font_style !== 'normal') {
        s.fontStyle = Text_Style.font_style;
    }
    if (typeof (Text_Style.text_decoration) !== 'undefined' && Text_Style.text_decoration !== 'none') {
        s.textDecoration = Text_Style.text_decoration;
    }
    if (typeof (Text_Style.Color) === 'object') {
        s.color = buildCSSColor(Text_Style.Color);
    }
    if (typeof (Text_Style.Background_Color) === 'object') {
        s.backgroundColor = buildCSSColor(Text_Style.Background_Color);
    }

    if (typeof (Text_Style.Shadows) !== 'undefined' && Text_Style.Shadows.length > 0) {
        var Text_Shadow, text_shadows = '';
        for (var i = 0; i < Text_Style.Shadows.length; i++) {
            Text_Shadow = Text_Style.Shadows[i];
            text_shadows += (text_shadows ? ',' : '') + (Text_Shadow.right / 100) + 'em ' +
                (Text_Shadow.bottom / 100) + 'em ' +
                (Text_Shadow.fuzziness / 100) + 'em ' +
                buildCSSColor({ value: Text_Shadow.color, opacity: Text_Shadow.opacity });
        }
        s.textShadow = text_shadows;
    }

    return s;
};

const convertTextStyle = (Text_Style_With_Font) => {
    var Style = {
        Font: Text_Style_With_Font.Font,
        Shadows: Text_Style_With_Font.Shadows,
        font_size: parseInt(Text_Style_With_Font.font_size, 10),
        line_height: parseInt(Text_Style_With_Font.line_height, 10),
        letter_spacing: parseInt(Text_Style_With_Font.letter_spacing, 10)
    };

    if (Text_Style_With_Font.font_weight === 'true') {
        Style.font_weight = 'bold';
    }
    if (Text_Style_With_Font.font_style === 'true') {
        Style.font_style = 'italic';
    }
    if (Text_Style_With_Font.text_decoration === 'true') {
        Style.text_decoration = 'underline';
    }
    if (typeof (Text_Style_With_Font.color) !== 'undefined' && (Text_Style_With_Font.color !== '000000' || parseInt(Text_Style_With_Font.color_opacity, 10) < 100)) {
        Style.Color = {
            value: Text_Style_With_Font.color,
            opacity: parseInt(Text_Style_With_Font.color_opacity, 10)
        };
    }
    if (typeof (Text_Style_With_Font.background_color) !== 'undefined') {
        Style.Background_Color = {
            value: Text_Style_With_Font.background_color,
            opacity: parseInt(Text_Style_With_Font.background_color_opacity, 10)
        };
    }

    return Style;
};

const buildCSSFontFamily = (Font) => {
    var white_space_regex = new RegExp('\\s');
    var s = white_space_regex.test(Font.main) ? '\'' + Font.main + '\'' : Font.main;
    var Fallbacks = ['fallback_1', 'fallback_2', 'fallback_3', 'fallback_4', 'generic_type'],
        fallback;
    for (var i = 0; i < Fallbacks.length; i++) {
        fallback = Fallbacks[i];
        if (Font[fallback]) {
            s += Font[fallback].indexOf(' ') > -1 ? ', \'' + Font[fallback] + '\'' : ', ' + Font[fallback];
        }
    }

    return s;
};

const buildCSSColor = (Color) => {
    var s, RGB;
    if (parseInt(Color.opacity, 10) < 100) {
        RGB = convertHexToRGB(Color.value);
        s = 'rgba(' + RGB.r + ',' + RGB.g + ',' + RGB.b + ',' + (parseInt(Color.opacity, 10) / 100) + ')';
    } else {
        s = '#' + Color.value;
    }
    return s;
};