import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class MenuStructure {
    convertLoadDataToCurrentData(Load_Data) {
        return deepmerge(Load_Data, {
            menu_structure: this.getMenuStructureString(Load_Data)
        });
    }

    getMenuStructureString(Current_Data) {
        var Menu_Structure = [];

        for (var i = 0; i < Current_Data.Pages.length; i++) {
            Menu_Structure.push({
                page_id: parseInt(Current_Data.Pages[i].id, 10),
                menu_parent_page_id: 0,
                sequence: i
            });
            for (var j = 0; j < Current_Data.Pages[i].Sub_Pages.length; j++) {
                Menu_Structure.push({
                    page_id: parseInt(Current_Data.Pages[i].Sub_Pages[j].id, 10),
                    menu_parent_page_id: parseInt(Current_Data.Pages[i].Sub_Pages[j].menu_parent_page_id, 10),
                    sequence: j
                });
            }
        }

        return JSON.stringify(Menu_Structure).replace(/"/g, '\'');
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            navigation_schema_id
            Pages
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'menu_structure', id: 'menu_structure_control' },
                    {
                        Pages: Current_Data.Pages, menu_structure_string_fld_id: 'menu_structure'
                        //TODO: onChange: change Current_Data.menu_structure
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'save_menu_structure',
                        Function_Data,
                        process_tag: 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            })
        ];
    }
}