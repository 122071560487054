import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_id: '',
    //replacement_id: '',
    New_Media_Item: {},
    Updated_Media_Item: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD':
            state.New_Media_Item = action.New_Media_Item;
            return state;
        case 'UPDATE':
            state.resource_id = action.resource_id;
            state.Updated_Media_Properties = action.Updated_Media_Properties;
            return state;
        case 'REMOVE':
            state.removed_id = action.removed_id;
            //state.replacement_id = action.replacement_id;
            return state;
        default:
            return state;
    }
});

export default store;