import React, { Component, Fragment } from 'react';
import { buildFormElementID } from '../utils/form';
import { buildOriginImagePath } from '../utils/path';

//SHOULD GET RID OF THIS FILE, USE CLASSES INSIDE "LAYER/COLUMN"

export class TextColumns extends Component {
    render() {
        const { frm_id, Data_Groups, name, El_Data } = this.props;
        const { text, styling_type, required } = El_Data;

        const className =
            (styling_type === 'normal' ? 'grey_txt' : '') +
            (styling_type === 'bold' ? 'bold' : '') +
            (styling_type === 'subtitle' ? 'subtitle' : '') +
            (styling_type === 'grey' ? 'grey_txt' : '') +
            (styling_type === 'response' ? 'red_txt bold large' : '') +
            (styling_type === 'small' ? 'grey_txt' : '');

        return (
            <p
                id={buildFormElementID(frm_id, Data_Groups, name)}
                className={'gp ' + className}
            >
                {text === ' ' ? '\u00A0' : text}
                {required === 'true' && <span className="warning">*</span>}
            </p>
        );
    }
}

export class LineColumns extends Component {
    render() {
        const { El_Data } = this.props;

        return (
            <Fragment>
                <div style={{ height: El_Data.space_1 + 'px' }} />
                <div style={{
                    backgroundColor: '#' + El_Data.color,
                    height: El_Data.thickness + 'px',
                    display: 'block',
                    fontSize: 0,
                    lineHeight: 0
                }} />
                <div style={{ height: El_Data.space_2 + 'px' }} />
            </Fragment>
        );
    }
}

export class SpaceColumn extends Component {
    render() {
        const { el_type, el_idx, El_Data, Col_Settings } = this.props;

        return (
            <td
                id={'col_' + el_type + '_' + el_idx}
                colSpan={Col_Settings.colspan}
                style={{
                    width: El_Data.width + 'px',
                    height: El_Data.height + 'px'
                }}
            />
        );
    }
}

export class ImageColumns extends Component {
    render() {
        const { project, site_id, frm_id, Data_Groups, name, El_Data, use_service } = this.props;
        const style = {};

        if (El_Data.padding) {
            style.paddingTop = El_Data.padding + 'px';
            style.paddingBottom = El_Data.padding + 'px';
        }

        return (
            <div style={style}>
                <img
                    id={buildFormElementID(frm_id, Data_Groups, name)}
                    src={buildOriginImagePath(project, site_id, use_service, false, true) + El_Data.resource_id + '.' + El_Data.file_type}
                    alt={El_Data.alt ? El_Data.alt : null}
                />
            </div>
        );
    }
}

export class BoxAndTextColumn extends Component {
    render() {
        const { frm_id, Data_Groups, name, El_Data } = this.props;
        const className = El_Data.class ? El_Data.class : 'stl_read_only_field';
        const style = El_Data.style ? El_Data.style : '';
        const text = El_Data.text ? El_Data.text : '';
        const text_class = El_Data.text_class ? El_Data.text_class : 'gp grey_txt';
        const text_style =
            (El_Data.text_style ? El_Data.text_style : '') +
            'fontFamily:arial, verdana, helvetica, sans-serif;line-height:100%;';

        return (
            <div
                id={buildFormElementID(frm_id, Data_Groups, name)}
                className={className}
                style={style}
            >
                {text &&
                    <p
                        className={text_class}
                        style={text_style}
                    >
                        {text === ' ' ? '\u00A0' : text}
                    </p>
                }
            </div>
        );
    }
}