import deepmerge from 'deepmerge';
import layer_store from '../../api/layer_store';

export default class MenuStyling {
    getQuery() {
        return `query DataQuery($urls: String!) {
            main_el_idx
            main_hover_el_idx
            main_active_el_idx
            sub_el_idx
            sub_hover_el_idx
            sub_active_el_idx
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            ['Normal', Current_Data.main_el_idx],
            ['Hover', Current_Data.main_hover_el_idx],
            ['Active', Current_Data.main_active_el_idx],
            ['Normal', Current_Data.sub_el_idx],
            ['Hover', Current_Data.sub_hover_el_idx],
            ['Active', Current_Data.sub_active_el_idx]
        ].map(Item => {
            return {
                label: Item[0],
                icon: 'menu_item',
                onClick: () => {
                    layer_store.dispatch({
                        type: 'OPEN_PANEL',
                        panel_data: {
                            Function_Data,
                            Action_Data: deepmerge(
                                Action_Data,
                                {
                                    access: 'authorized',
                                    panel_type: 'menu_styling_item_panel',
                                    frm_name: 'background_settings',
                                    el_type: 'menu_item',
                                    el_idx: Item[1],
                                    tools_type: 'extended'
                                }
                            )
                        },
                    });
                }
            };
        });
    }
}