import React, { Component } from 'react';
import { addJSLibrary } from '../utils/head';

export default class Twitter extends Component {
    constructor(props) {
        super(props);
        addJSLibrary('twitter', '//platform.twitter.com/widgets.js');
    }

    render() {
        const { Site, el_type, el_idx, Element } = this.props;
        const { El_Data } = Element;

        return (
            <div id={[el_type, 'holder', el_idx].join('_')}>
                <div id={[el_type, el_idx].join('_')}>
                    <a
                        className="twitter-timeline"
                        data-dnt="true"
                        href={'https://twitter.com/' + El_Data.name}
                        data-widget-id="703886228698087424"
                        data-tweet-limit={
                            El_Data.limit_amount === 'true' ?
                                El_Data.amount :
                                null
                        }
                        data-theme={El_Data.theme}
                        data-width={El_Data.width}
                        data-height={
                            El_Data.limit_amount === 'false' ?
                                El_Data.height :
                                null
                        }
                    >
                        <img
                            id={[el_type, 'loading', el_idx].join('_')}
                            src="shared/img/twitter/loading.gif"
                        />
                        <p
                            className="twitter"
                            style={{ paddingTop: '30px', textAlign: 'center' }}
                        >
                            {Site.language === 'nl' ? 'Twitter wordt geladen...' : 'Loading Twitter...'}
                        </p>
                    </a>
                </div>
            </div>
        );
    }
}