import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColImageButtonSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            deepmerge(
                Load_Data,
                {
                    Form_Col_Image_Button: {
                        button_class: '',
                        resource_id: 0,
                        img_class: '',
                        name: '',
                        colspan: 1,
                        h_align: 'center',
                        v_align: 'middle',
                        padding_left: 0,
                        padding_right: 0,
                        label: '',
                        width: 115,
                        height: 35,
                        button_padding: 2,
                        button_padding_left: 0,
                        button_padding_right: 0,
                        button_padding_top: 0,
                        button_padding_bottom: 0,
                        text: '',
                        img_custom_size: 'false',
                        img_width: 40,
                        img_height: 33,
                        img_left: 0,
                        img_top: 0,
                        click_script_id: '',
                        submit_script_id: '',
                        button_type: 'submit',
                        submit_action_id: 0
                    }
                }
            ) :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            `query DataQuery($urls: String!) {
                Submit_Actions
            }` :
            `query DataQuery($urls: String!) {
                Form_Col_Image_Button {
                    button_class: String
                    resource_id
                    img_class: String
                    name: String
                    colspan
                    h_align: String
                    v_align: String
                    padding_left
                    padding_right
                    label: String
                    width
                    height
                    button_padding
                    button_padding_left
                    button_padding_right
                    button_padding_top
                    button_padding_bottom
                    text: String
                    img_custom_size: String
                    img_width
                    img_height
                    img_left
                    img_top
                    click_script_id: String
                    submit_script_id: String
                    button_type: String
                    submit_action_id
                }
                Submit_Actions
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Image_Button', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Image_Button.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Image_Button.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'dropdown', id: 'h_align', label: 'Horizontal align' },
                    {
                        value: Current_Data.Form_Col_Image_Button.h_align,
                        Options: [
                            ['left', 'Left'],
                            ['center', 'Center'],
                            ['right', 'Right']
                        ]
                    }
                ],
                [
                    { type: 'dropdown', id: 'v_align', label: 'Vertical align' },
                    {
                        value: Current_Data.Form_Col_Image_Button.v_align,
                        Options: [
                            ['top', 'Top'],
                            ['middle', 'Center'],
                            ['bottom', 'Bottom']
                        ]
                    }
                ],
                [
                    { type: 'number', id: 'padding_left', label: 'Padding left' },
                    { value: Current_Data.Form_Col_Image_Button.padding_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'padding_right', label: 'Padding right' },
                    { value: Current_Data.Form_Col_Image_Button.padding_right, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Image_Button.label, max: 2 }
                ],
                [
                    { type: 'dropdown', id: 'button_type', label: 'Button type' },
                    {
                        value: Current_Data.Form_Col_Image_Button.button_type,
                        Options: [
                            ['button', 'Normal'],
                            ['submit', 'Submit']
                        ]
                    }
                ],
                [
                    {
                        type: Current_Data.Form_Col_Image_Button.button_type === 'submit' ? 'dropdown' : 'hidden',
                        id: 'submit_action_id',
                        label: 'Submit action'
                    },
                    {
                        value: Current_Data.Form_Col_Image_Button.submit_action_id,
                        Options: [{ id: '', name: 'None' }].concat(
                            Current_Data.Submit_Actions.map(Submit_Action => [Submit_Action.id, Submit_Action.name])
                        )
                    }
                ],
                [
                    { type: 'number', id: 'width', label: 'Width' },
                    { value: Current_Data.Form_Col_Image_Button.width, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'height', label: 'Height' },
                    { value: Current_Data.Form_Col_Image_Button.height, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding', label: 'Padding' },
                    { value: Current_Data.Form_Col_Image_Button.button_padding, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_left', label: 'Button padding left' },
                    { value: Current_Data.Form_Col_Image_Button.button_padding_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_right', label: 'Button padding right' },
                    { value: Current_Data.Form_Col_Image_Button.button_padding_right, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_top', label: 'Button padding top' },
                    { value: Current_Data.Form_Col_Image_Button.button_padding_top, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_bottom', label: 'Button padding bottom' },
                    { value: Current_Data.Form_Col_Image_Button.button_padding_bottom, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'field', id: 'text', label: 'Text' },
                    { value: Current_Data.Form_Col_Image_Button.text, max: 2 }
                ],
                [
                    { type: 'row', id: 'image_1', label: ' ' },
                    {
                        Content: [
                            [
                                {
                                    type: 'button_image',
                                    id: 'select_image',
                                    label: ' ',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 5,
                                    padding_right: 5
                                },
                                {
                                    name: 'open_select_panel',
                                    button_style: [115, 35, 2],
                                    text: 'Select',
                                    img_url: getImageURL('rootflex', 'upload_new'),
                                    img_style: [0, 40, 32, 0],
                                    onClick: () => {
                                        layer_store.dispatch({
                                            type: 'OPEN_PANEL',
                                            panel_data: {
                                                Function_Data,
                                                Action_Data: deepmerge(
                                                    Action_Data,
                                                    {
                                                        access: 'authorized',
                                                        panel_type: 'background_switch_upload_panel',
                                                        frm_name: 'image_select',
                                                        new_element: Action_Data.data_action_type === 'create',
                                                        el_type: 'form_col_image_button',
                                                        el_idx: Action_Data.el_idx,
                                                        resource_id: parseInt(Current_Data.Form_Col_Image_Button.resource_id, 10),
                                                        data_action_type: 'select',
                                                        amount: 15,
                                                        offset: 0
                                                    }
                                                )
                                            },
                                        });
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'image',
                                    id: 'thumbnail',
                                    colspan: 2,
                                    h_align: 'center',
                                    padding_top: 3,
                                    padding_bottom: 3
                                },
                                {
                                    src: isNaN(parseInt(Current_Data.Form_Col_Image_Button.resource_id), 10) || parseInt(Current_Data.Form_Col_Image_Button.resource_id, 10) === 0 ?
                                        'projects/rootflex/img/form/none_selected.jpg' :
                                        'projects/' + Function_Data.project + '/cache/' +
                                        (Function_Data.use_service ? 'service' : 'user') + '/' +
                                        Function_Data.site_id + '/img/w150h100/' +
                                        Current_Data.Form_Col_Image_Button.resource_id + '.' +
                                        Current_Data.Form_Col_Image_Button.Image_Origin_Data.file_type,
                                    show_transparency_background: true
                                }
                            ]
                        ]
                    }
                ],
                [
                    { type: 'checkbox', id: 'img_custom_size', label: 'Custom size' },
                    { value: Current_Data.Form_Col_Image_Button.img_custom_size, styling_type: 'switch' }
                ],
                [
                    {
                        type: Current_Data.Form_Col_Image_Button.img_custom_size === 'true' ? 'number' : 'hidden',
                        id: 'img_width',
                        label: 'Image width'
                    },
                    { value: Current_Data.Form_Col_Image_Button.img_width, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    {
                        type: Current_Data.Form_Col_Image_Button.img_custom_size === 'true' ? 'number' : 'hidden',
                        id: 'img_height',
                        label: 'Image height'
                    },
                    { value: Current_Data.Form_Col_Image_Button.img_height, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'img_left', label: 'Image left' },
                    { value: Current_Data.Form_Col_Image_Button.img_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'img_top', label: 'Image top' },
                    { value: Current_Data.Form_Col_Image_Button.img_top, min_val: 0, max_val: 100, unit: 'px' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_image_button_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}