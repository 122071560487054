import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { ucWords } from '../../utils/string';
import { renderGrid, renderRow } from './management_grid';

export default class ExternalFontsManagement extends Component {
    onClickEdit(Font) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'edit_custom_font_panel',
                    frm_name: 'custom_font_settings',
                    custom_font_id: Font.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Font) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_custom_font_panel',
                    frm_name: 'remove_custom_font',
                    custom_font_id: Font.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    renderSuffixVariants(Font) {
        return ['', '_bold', '_italic', '_bold_italic'].map((Suffix_Variant, index) => {
            const style = {};
            if (index === 1 || index === 3) {
                style.fontWeight = 'bold';
            }
            if (index === 2 || index === 3) {
                style.fontStyle = 'italic';
            }

            return (
                <div
                    key={index}
                    style={{ clear: 'both' }}>
                    <div style={{ float: 'left', width: '65px' }}>
                        <p
                            className="gp"
                            style={style}
                        >
                            {ucWords(['Normal', 'Bold', 'Italic', 'Bold Italic'][index])}
                        </p>
                    </div>
                    <div style={{ float: 'left' }}>
                        {Font['external_url' + Suffix_Variant] && Font['external_url' + Suffix_Variant] !== 'null' ?
                            <p className="gp">
                                {Font['external_url' + Suffix_Variant]}
                            </p> :
                            <p className="gp" style={{ color: '#ff0000' }}>
                                {'Not specified'}
                            </p>
                        }
                    </div>
                </div>
            );
        });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.Fonts.length === 0 ?
            (<p className="gp">No external fonts yet added.</p>) :
            renderGrid(
                id + '_font_list',
                [
                    { label: 'Used' },
                    { label: 'Name' },
                    {
                        SubHeads: [
                            { label: 'Style', width: 65 },
                            { label: 'URL' }
                        ]
                    },
                    { label: '' },
                    { label: '' },
                ],
                this.props.El_Data.Fonts.map((Font, index) =>
                    renderRow(
                        id + '_custom_font_row_' + Font.id,
                        index,
                        [
                            { type: 'text', value: (Font.in_use ? 'Yes' : 'No') },
                            { type: 'text', value: ucWords(Font.name) },
                            { type: 'custom', content: this.renderSuffixVariants(Font) },
                            { type: 'action', icon: 'wrench', onClick: this.onClickEdit(Font) },
                            { type: 'action', icon: 'delete', onClick: this.onClickDelete(Font) }
                        ]
                    )
                )
            );
    }
}