
import { Observable } from 'rxjs';
import function_data_store from './function_data_store';

export const getData = (Forms_Post_Data, operationName = 'DataQuery') => {
    const { Function_Data } = function_data_store.getState();

    return new Observable((observer) => {
        const req = new XMLHttpRequest();
        req.open('POST', `${Function_Data.base_url}$/get_data/`);
        // req.withCredentials = true; // CORS
        req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        req.send(JSON.stringify({
            operationName,
            variables: Forms_Post_Data || {}
        }));
        req.onreadystatechange = function () {
            if (req.readyState == XMLHttpRequest.DONE) {
                const hasError = checkPostResultForError(JSON.parse(req.responseText));

                if (!hasError && req.status == 200) {
                    observer.next(JSON.parse(req.response));
                    observer.complete();
                } else {
                    observer.error(req.response);
                }
            }

            return () => {
                req.abort();
            };
        };
    });
};

const checkPostResultForError = (Data) => {
    let error_txt = '';

    if (Data) {
        if (Data.error && typeof (Data.error) === 'string') {//returned by "buildError"
            error_txt = Data.error;
        } else if (typeof (Data) === 'undefined') {
            error_txt = 'Data failure: Possibly cannot connect to the web-server. Is your computer is online?';
        } else if (Data.responseText && Data.responseText.indexOf('xdebug-error') > -1) {
            error_txt = Data.responseText;
        } else if (Data.responseText && Data.responseText.indexOf('{"error"') > -1) {
            error_txt = JSON.parse(Data.responseText).error;
        } else if (Data.responseJSON && Data.responseJSON.error) {
            error_txt = Data.responseJSON.error;
        } else if (Data.errorText) {
            error_txt = Data.errorText;
        }
    } else {
        error_txt = 'No response to API request.';
    }

    if (error_txt) {
        console.log(error_txt);
        return true;
    }

    return false;
};
