import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';
import { convertAndBuildTextStyle } from '../../styling/text';
import { findArray } from '../../utils/array';

export default class TextStyle extends Component {
    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id),
            { Data, Fonts, width, height, show_transparency_background, preview_text } = El_Data;

        Data.Font = findArray(
            Fonts,
            Font => Font.id === Data.font_family_id
        );

        const style = Object.assign(
            Data ? convertAndBuildTextStyle(Data) : {},
            {
                overflow: 'hidden',
                textAlign: 'center',
                lineHeight: height + 'px',
                width: (width || 100) + 'px',
                height: (height || 100) + 'px',
                border: '1px solid #000'
            }
        );

        return (Data.Font ?
            <div className={show_transparency_background ? 'form_transparency_background' : ''}>
                <div
                    id={id ? id : ''}
                    style={style}
                >
                    {preview_text || 'Example'}
                </div>
            </div> :
            <p className="gp red_txt">Cannot find font family, therefore cannot draw preview.</p>
        );
    }
}