import form_data_store from '../../api/form_data_store';
import structure_store from '../../api/structure_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveElement {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return {
            try_remove_sources: false,
            isMultiple: ['box', 'grid', 'column', 'row', 'sequence', 'slideshow',].includes(Action_Data.el_type),
            hasContext: ['box', 'grid', 'column', 'row', 'ground', 'image', 'video', 'map', 'menu', 'text', 'twitter'].includes(Action_Data.el_type)
        };
    }

    getQuery() {
        return null;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: Current_Data.hasContext ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: {
                            box: 'All sub-content inside the box will also be removed.',
                            grid: 'All sub-content inside the grid will also be removed.',
                            column: 'All sub-content inside the column will also be removed.<br>' +
                                'Pay notice to the fact that a column is always present in<br>' +
                                'each row of a grid.',
                            row: 'All sub-content inside the row will also be removed.',
                            ground: 'The background will be removed as an \'layout element\'.',
                            image: 'The image will be removed as an \'layout element\'.',
                            video: 'The video will be removed as an \'layout element\'.',
                            map: 'The map \'layout element\' and all markers will be removed.',
                            menu: 'The menu is only removed as an \'layout element\'<br>' +
                                '(no effect on the pages).',
                            text: 'The text will be removed as an \'layout element\'.',
                            twitter: 'Only the twitter element will be removed<br>' +
                                '(no effect on your twitter account).',
                        }[Action_Data.el_type],
                        style: 'normal_grey',
                    },
                ],
                [
                    {
                        type: Current_Data.isMultiple || Current_Data.hasContext ? 'checkbox' : 'hidden',
                        id: 'try_remove_sources',
                        label: 'Try remove source' + (Current_Data.isMultiple ? 's' : ''),
                    },
                    { value: Current_Data.try_remove_sources, styling_type: 'switch' },
                ],
                [
                    {
                        type: (Current_Data.isMultiple || Current_Data.hasContext) &&
                            Current_Data.try_remove_sources.toString() === 'true' ? 'text' : 'hidden',
                        id: 'try_remove_sources_text',
                        colspan: 2,
                    },
                    {
                        text: 'When trying to remove ' +
                            (Current_Data.isMultiple ?
                                'sources, underlying images, texts and/or videos' :
                                'the source, underlying ' +
                                (Action_Data.el_type === 'ground' ? 'image' : Action_Data.el_type)
                            ) +
                            '<br>' +
                            'will also be removed from the collection, if not<br>' +
                            'used anywhere else.',
                        style: 'normal_grey',
                    },
                ],
                [
                    {
                        type: (Current_Data.isMultiple || Current_Data.hasContext) &&
                            !Current_Data.try_remove_sources.toString() === 'true' ? 'text' : 'hidden',
                        id: 'keep_sources_text',
                        colspan: 2,
                    },
                    {
                        text: 'When not removing ' +
                            (Current_Data.isMultiple ?
                                'sources, underlying images, texts and/or videos' :
                                'the source, underlying ' +
                                (Action_Data.el_type === 'ground' ? 'image' : Action_Data.el_type)) +
                            '<br>' +
                            'will not be removed from the collection, thus will<br>' +
                            'remain available for other elements.',
                        style: 'normal_grey',
                    },
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'remove_element',
                        Function_Data,
                        process_tag: 'Removing...',
                        onSubmitted: Result_Data => {
                            structure_store.dispatch({
                                type: 'REMOVE_ELEMENT',
                                data: {
                                    el_type: Result_Data.el_type,
                                    el_idx: Result_Data.el_idx,
                                },
                            });
                        }
                    }
                });
            }, 'Delete'),
        ];
    }
}
