import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class TwitterSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Twitter: {
                    name: '',
                    theme: 'light',
                    limit_amount: 'false',
                    amount: 4,
                    width: 400,
                    height: 400
                }
            } :
            clone(Load_Data);
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Twitter: {
                name: String
                theme: String
                limit_amount: String
                amount
                width
                height
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Twitter', [
                [
                    { type: 'field', id: 'name', label: 'Twitter name' },
                    { value: Current_Data.Twitter.name, max: 2 }
                ],
                [
                    { type: 'dropdown', id: 'theme', label: 'Theme' },
                    {
                        value: Current_Data.Twitter.theme,
                        Options: [
                            ['light', 'Light'],
                            ['dark', 'Dark']
                        ],
                        margin_top: 2,
                        width: 70
                    }
                ],
                [
                    { type: 'checkbox', id: 'limit_amount', label: 'Limit amount' },
                    { value: Current_Data.Twitter.limit_amount, styling_type: 'switch' }
                ],
                [
                    { type: 'number', id: 'amount', label: 'Amount of tweets' },
                    { value: Current_Data.Twitter.amount, min_val: 1, max_val: 20 }
                ],
                [
                    { type: 'number', id: 'width', label: 'Width' },
                    { value: Current_Data.Twitter.width, min_val: 180, max_val: 520, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'height', label: 'Height' },
                    { value: Current_Data.Twitter.height, min_val: 200, max_val: 999, unit: 'px' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        access: 'authorized',
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_twitter_settings',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save')
        ];
    }
}