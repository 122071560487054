import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import text_styling_store from '../../api/text_styling_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveTextStyling {
    convertLoadDataToCurrentData(Load_Data) {
        const { Text_Styles_List } = Load_Data;
        const Current_Data = deepmerge(
            Load_Data,
            {
                switched_text_style_id: Text_Styles_List && Text_Styles_List.length > 0 ? Text_Styles_List[0].id : 0
            }
        );

        Current_Data.usage_amount = parseInt(Current_Data.usage_amount, 10);

        return Current_Data;
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            usage_amount
            Text_Styles_List
            Text_Style {
                id
                name
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Text_Styles_List.length === 0 ? 'text' : 'hidden',
                        colspan: 2
                    },
                    {
                        text: 'The text style can\'t be deleted, because<br>\
                                    there' + (Current_Data.usage_amount === 1 ?
                                '\'s still 1 place' :
                                ' are still ' + Current_Data.usage_amount + ' places'
                            ) + '<br>where it is used and there aren\'t any<br>\
                                     other text styles to switch to.',
                        style: 'bold'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount === 0 || Current_Data.Text_Styles_List.length > 0 ? 'text' : 'hidden',
                        colspan: 2,
                        h_align: 'center'
                    },
                    { text: 'You are about to delete the text style named: "' + Current_Data.Text_Style.name + '"', style: 'bold' }
                ],
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Text_Styles_List.length > 0 ? 'text' : 'hidden',
                        colspan: 2
                    },
                    {
                        text: 'There ' +
                            (Current_Data.usage_amount === 1 ?
                                'is 1 text that is' :
                                'are ' + Current_Data.usage_amount + ' texts that are'
                            ) +
                            ' still using this text style!<br>' +
                            'Please specify which text-style ' +
                            (Current_Data.usage_amount === 1 ?
                                'this text' :
                                'these texts'
                            ) +
                            ' should switch to.',
                        style: 'normal_grey'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Text_Styles_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'switched_text_style_id'
                    },
                    {
                        value: Current_Data.switched_text_style_id,
                        Options: Current_Data.Text_Styles_List ?
                            Current_Data.Text_Styles_List.map(Text_Style => [Text_Style.id, Text_Style.name]) :
                            [],
                    }
                ],
                [
                    { type: Current_Data.usage_amount === 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: 'This text style isn\'t used by any texts,<br>' +
                            'thereby this text style can be safely deleted.',
                        style: 'normal_grey'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount === 0 || Current_Data.Text_Styles_List.length > 0 ? 'text' : 'hidden',
                        colspan: 2,
                        h_align: 'center'
                    },
                    { text: 'Are you sure?', style: 'bold' }
                ]
            ]),
            Current_Data.usage_amount > 0 && Current_Data.Text_Styles_List.length === 0 ?
                getSingleSubmitSet(frm_id, 'OK') :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_text_styling',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: Result_Data => {
                                if (Result_Data) {
                                    text_styling_store.dispatch({
                                        type: 'REMOVE',
                                        removed_id: Current_Data.Text_Style.id,
                                        replacement_id: Current_Data.switched_text_style_id
                                    });
                                } else {
                                    alert('Some error occured when trying to delete the text styling.')
                                }
                            }
                        }
                    });
                }, 'Delete')
        ];
    }
}