import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_id: ''
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'REMOVE':
            state.removed_id = action.removed_id;
            return state;
        default:
            return state;
    }
});

export default store;