import media_item_store from '../../api/media_item_store';
import { upload } from '../../api/upload_query';
import { getImageURL, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class VideoManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [media_item_store.subscribe(this.handleMediaItemStoreChange(changeCurrentData, getCurrentData))];
    }

    handleMediaItemStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = media_item_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Media_Items = clone(Current_Data.Videos);

            if (state.action === 'add') {
                Changed_Media_Items.push(state.New_Media_Item);
            }

            if (state.action === 'update') {
                Changed_Media_Items = Changed_Media_Items.map(Media_Item => {
                    if (parseInt(Media_Item.id, 10) === parseInt(state.resource_id, 10)) {
                        Media_Item.description = state.Updated_Media_Properties.description;
                    }
                    return Media_Item;
                });
            }

            if (state.action === 'remove') {
                //TODO: also unselect if media-item was selected.
                const index = findIndexArray(
                    Changed_Media_Items,
                    Media_Item => parseInt(Media_Item.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Media_Items.splice(index, 1);
            }

            changeCurrentData({ Videos: Changed_Media_Items });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Videos
            total_amount
            max_file_uploads
            post_max_size
            resource_id
            upload_max_filesize
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', id: 'Selected_Video' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [
                        [
                            { type: 'row' },
                            {
                                Content: [
                                    [
                                        { type: 'button_image', id: 'upload_new_video', h_align: 'left', v_align: 'center' },
                                        {
                                            name: 'upload_new_video',
                                            button_style: [110, 32, 0, 4, 0, 4, 8],
                                            text: 'Upload',
                                            img_url: getImageURL('rootflex', 'choose_file'),
                                            img_style: [0, 37, 35, 0],
                                            file_upload: true,
                                            filetype_restriction: 'video/*',
                                            onChange: Files => {
                                                upload(
                                                    Function_Data,
                                                    frm_id,
                                                    ['Selected_Video'],
                                                    'upload_new_video',
                                                    Files,
                                                    {},
                                                    'upload_videos',
                                                    Current_Data.max_file_uploads,
                                                    Current_Data.upload_max_filesize,
                                                    Current_Data.post_max_size,
                                                    Result_Data => {
                                                        const new_total = Current_Data.total_amount + Result_Data.Uploaded_Videos.length;
                                                        if (Action_Data.amount > Current_Data.Videos.length) {
                                                            const amount_to_add = Action_Data.amount - Current_Data.Videos.length;

                                                            const Changed_Videos = clone(Current_Data.Videos).concat(
                                                                Result_Data.Uploaded_Videos
                                                                    .slice(0, amount_to_add)
                                                                    .map(Video =>
                                                                        Object.assign({}, Video, {
                                                                            site_id: Function_Data.site_id
                                                                        })
                                                                    )
                                                            );

                                                            changeCurrentData({
                                                                Videos: Changed_Videos,
                                                                total_amount: new_total
                                                            });
                                                        } else {
                                                            changeCurrentData({
                                                                total_amount: new_total
                                                            });
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    ],
                                    [
                                        { type: 'text', padding_left: 10 },
                                        { text: 'The uploads will be added to the collection below.<br>The filetype has to be "mp4" and has to be H.264<br>encoded to properly display in all browsers.', style: 'bold' }
                                    ]
                                ]
                            }
                        ]
                    ]
                }
            ],
            wrapSectionSubs('Selected_Video', [
                [
                    { type: 'media_selector', id: 'resource_id' },
                    {
                        Items: Current_Data.Videos,
                        Selected_Item: {
                            id: 0,
                            file_type: ''
                        },
                        media_type: 'video',
                        lightbox_path: 'projects/' + Function_Data.project + '/files/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/video/placeholder/',
                        thumbnail_path: 'projects/' + Function_Data.project + '/cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/video/w150h100/',
                        width: 150,
                        height: 100,
                        start: 1,
                        total_amount: Current_Data.total_amount,
                        column_amount: 5,
                        disable_select: true
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}