import form_data_store from '../../api/form_data_store';
import scope_group_store from '../../api/scope_group_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveScopeGroup {
    convertLoadDataToCurrentData(Load_Data) {
        return {
            usage_amount: parseInt(Load_Data.usage_amount, 10)
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            usage_amount
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: Current_Data.usage_amount > 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: Current_Data.usage_amount === 1 ?
                            'The scope group can\'t be deleted<br>\
                                    from the collection, because there\'s<br>\
                                    still 1 place where this scope group is<br>\
                                    being used.' :
                            'The scope group can\'t be deleted<br>\
                                    from the collection, because there<br>\
                                    are still ' + Current_Data.usage_amount + ' places<br>\
                                    where this scope group is being used.',
                        style: 'bold'
                    }
                ],
                [
                    { type: Current_Data.usage_amount === 0 ? 'text' : 'hidden', colspan: 2, h_align: 'center' },
                    { text: 'Are you sure?', style: 'bold' }
                ],
                [
                    { type: Current_Data.usage_amount === 0 ? 'space' : 'hidden', colspan: 2, height: 23 }
                ]
            ]),
            Current_Data.usage_amount > 0 ?
                getSingleSubmitSet(frm_id, 'OK') :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_scope_group',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: removed_id => {
                                if (removed_id) {
                                    scope_group_store.dispatch({
                                        type: 'REMOVE',
                                        removed_id
                                    });
                                } else {
                                    alert('Some error occured when trying to delete the scope group.');
                                }
                            }
                        }
                    });
                }, 'Delete')
        ];
    }
}