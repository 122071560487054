import layer_store from '../api/layer_store';
import { postData } from '../api/post_query';
import structure_store from '../api/structure_store';
import tools_store from '../api/tools_store';

export const buildClickToolsAction = (Function_Data, action) => {
    if ('cut,copy'.split(',').includes(action)) {
        const state = structure_store.getState();
        var el_type = state.selected_el_type;
        var el_idx = state.selected_el_idx;
        App.Tools.Toolbar.CutCopyPaste.resetCutCopyElementStyling();
        App.Tools.Toolbar.CutCopyPaste.setCutCopyElementStyling(action, el_type, el_idx);

        structure_store.dispatch({
            type: 'CUT_COPY',
            data: {
                cut_copy_action: action,
                cut_copy_element: el_type,
                cut_copy_id: el_idx
            },
        });
    }

    var Action_Data = prepareActionData(action);

    if (Action_Data.click_action_type === 'url_action') {
        postData(
            '',
            '',
            action,
            Function_Data,
            0,
            0,
            () => {
                //TODO: control with conditions, provide alternative: given url, browser-action on returned data...
                window.location.reload();
            },
            0,
            false
        );
    } else if (Action_Data.click_action_type === 'open_panel') {
        layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: { Function_Data, Action_Data },
        });
    } else if (Action_Data.click_action_type === 'open_overlay_toolbar') {
        tools_store.dispatch({
            type: 'OPEN_OVERLAY_TOOLS',
            panel_data: { Function_Data, Action_Data },
        });
    } else if ('cut,copy,paste,move_up,move_down'.split(',').includes(Action_Data.click_action_type)) {
        if ('paste,move_up,move_down'.split(',').includes(Action_Data.click_action_type)) {
            var Forms_Post_Data = [{
                name: 'site_id',
                value: Function_Data.site_id
            }, {
                name: 'page_id',
                value: Function_Data.page_id
            }, {
                name: 'device_type',
                value: Function_Data.device_type
            }];

            for (var key in Action_Data) {
                if (Action_Data.hasOwnProperty(key)) {
                    Forms_Post_Data.push({
                        name: key,
                        value: Action_Data[key]
                    });
                }
            }

            var process_tag = 'Processing...';
            if (Action_Data.cut_copy_action === 'cut' || 'move_up,move_down'.split(',').includes(Action_Data.click_action_type)) {
                process_tag = 'Moving...';
            } else if (Action_Data.cut_copy_action === 'copy') {
                process_tag = 'Copying...';
            }

            postData(
                '',
                '',
                Action_Data.click_action_type,
                Function_Data,
                [{ data: Forms_Post_Data }],
                process_tag,
                (Result_Data) => {
                    window.location.reload();//TODO: remove this when code below is ready.
                    App.Tools.Toolbar.CutCopyPaste.resetCutCopyElementStyling();
                    if (Result_Data.action && (Result_Data.action === 'move_up' || Result_Data.action === 'move_down')) {
                        //TODO: move element in structure & content
                    } else {
                        if (Result_Data.cut_copy_action === 'cut') {
                            App.Tools.Toolbar.CutCopyPaste.tryMoveElement(Result_Data);//Not ready
                        } else if (Result_Data.cut_copy_action === 'copy') {
                            App.Tools.Toolbar.CutCopyPaste.tryCopyElement(Result_Data);//Not ready
                        }
                    }
                },
                0,
                false
            );
        }
    }
};

const getClickActionType = (action) => {
    let click_action_type = 'open_panel';

    if (action === 'sign_out') {
        click_action_type = 'url_action';
    } else if ('cut,copy,paste,move_up,move_down'.split(',').includes(action)) {
        click_action_type = action;
    } else if (
        [
            'edit_text', 'edit_layout', 'edit_row_size', 'edit_column_size', 'edit_element_background',
            'edit_element_border', 'edit_element_corner', 'edit_element_shadow'
        ].includes(action)
    ) {
        click_action_type = 'open_overlay_toolbar';
    }

    return click_action_type;
};

export const prepareActionData = (action) => {
    let
        access = 'authorized',
        click_action_type = getClickActionType(action),
        data_action_type = '',
        tools_type = undefined,
        panel_type = undefined,
        frm_name = undefined,
        amount = 15,
        offset = 0,
        el_type = undefined,
        el_idx = undefined,
        holder_grid_el_idx = undefined,
        holder_row_el_idx = undefined,
        holder_column_el_idx = undefined,
        holder_el_idx = undefined,
        new_element = false,
        get_all = false,
        cut_copy_action = undefined,
        paste_el_type = undefined,
        paste_grid_el_idx = undefined,
        paste_row_el_idx = undefined,
        paste_column_el_idx = undefined,
        paste_el_idx = undefined,
        holder_el_type = undefined;

    if ('edit_user,manage_users'.split(',').includes(action)) {
        panel_type = 'user_panel';

        if (action === 'edit_user') {
            frm_name = 'user_settings';
            access = 'authenticated';
            data_action_type = 'edit';
        } else if (action === 'manage_users') {
            frm_name = 'user_management';
            data_action_type = 'manage';
        }

    } else if ('create_site,edit_site,manage_images,manage_videos,manage_gradients,manage_files,manage_presets,manage_text_style_presets,manage_fonts,manage_elements'.split(',').includes(action)) {
        panel_type = 'site_panel';
        data_action_type = 'manage';
        amount = 15;

        if (action === 'create_site') {
            data_action_type = 'create';
        } else if (action === 'edit_site') {
            data_action_type = 'edit';
        }

        frm_name = {
            create_site: 'site_settings',
            edit_site: 'site_settings',
            manage_images: 'image_management',
            manage_videos: 'video_management',
            manage_gradients: 'gradient_management',
            manage_files: 'file_management',
            manage_presets: 'preset_management',
            manage_text_style_presets: 'text_styling_management',
            manage_fonts: 'font_family_management',
            manage_elements: 'element_management'
        }[action];

    } else if ('manage_languages'.split(',').includes(action)) {
        panel_type = 'language_panel';
        frm_name = 'language_management';
        data_action_type = 'manage';

    } else if ('manage_devices,manage_resolutions,manage_scroll_zones'.split(',').includes(action)) {
        panel_type = 'view_panel';
        data_action_type = 'manage';

        frm_name = {
            manage_devices: 'device_management',
            manage_resolutions: 'resolution_management',
            manage_scroll_zones: 'scroll_zone_management'
        }[action];

    } else if ('create_page,copy_page,edit_page,remove_page,manage_pages,manage_scopes'.split(',').includes(action)) {
        panel_type = 'page_panel';

        frm_name = {
            create_page: 'page_settings',
            copy_page: 'page_settings',
            edit_page: 'page_settings',
            remove_page: 'remove_page',
            manage_pages: 'page_management',
            manage_scopes: 'scope_group_management'
        }[action];

        data_action_type = {
            create_page: 'create',
            copy_page: 'copy',
            edit_page: 'edit',
            remove_page: 'remove',
            manage_pages: 'manage',
            manage_scopes: 'manage'
        }[action];

    } else if (action === 'paste') {
        const state = structure_store.getState();
        cut_copy_action = state.cut_copy_action;
        el_type = state.cut_copy_element;
        el_idx = state.cut_copy_id;
        paste_el_type = state.selected_el_type;
        data_action_type = 'paste';

        if (paste_el_type === 'grid_cell') {
            paste_grid_el_idx = state.selected_el_idx;
            paste_row_el_idx = state.selected_row_el_idx;
            paste_column_el_idx = state.selected_column_el_idx;
        } else {
            paste_el_idx = state.selected_el_idx;
        }

    } else if ('add_grid,add_sequence,add_ground,add_box,add_icon,add_text,add_image,add_video,add_map,add_menu,add_form,add_twitter,add_slideshow,add_row,add_column,add_form_col_section,add_form_col_grid,add_form_col_row,add_form_col_button,add_form_col_image_button,add_form_col_field,add_form_col_textarea,add_form_col_color_field,add_form_col_date_field,add_form_col_slider_field,add_form_col_dropdown,add_form_col_checkbox,add_form_col_radio_select,add_form_col_image_text_select,add_form_col_box_text,add_form_col_text,add_form_col_image,add_form_col_icon_link,add_form_col_space,add_form_col_line,add_form_col_hidden'.split(',').includes(action)) {
        const state = structure_store.getState();
        new_element = true;
        panel_type = 'add_element_panel';
        data_action_type = 'create';
        frm_name = 'scope_settings';
        holder_el_type = state.selected_el_type;

        if (holder_el_type === 'grid_cell') {
            holder_grid_el_idx = state.selected_el_idx;
            holder_row_el_idx = state.selected_row_el_idx;
            holder_column_el_idx = state.selected_column_el_idx;
        } else {
            holder_el_idx = state.selected_el_idx;
        }

        el_type = {
            add_grid: 'grid',
            add_sequence: 'sequence',
            add_ground: 'ground',
            add_box: 'box',
            add_icon: 'icon',
            add_text: 'text',
            add_image: 'image',
            add_video: 'video',
            add_map: 'map',
            add_menu: 'menu',
            add_form: 'form',
            add_twitter: 'twitter',
            add_slideshow: 'slideshow',
            add_row: 'row',
            add_column: 'column',
            add_form_col_section: 'form_col_section',
            add_form_col_grid: 'form_col_grid',
            add_form_col_row: 'form_col_row',
            add_form_col_button: 'form_col_button',
            add_form_col_image_button: 'form_col_image_button',
            add_form_col_field: 'form_col_field',
            add_form_col_textarea: 'form_col_textarea',
            add_form_col_color_field: 'form_col_color_field',
            add_form_col_date_field: 'form_col_date_field',
            add_form_col_slider_field: 'form_col_slider_field',
            add_form_col_dropdown: 'form_col_dropdown',
            add_form_col_checkbox: 'form_col_checkbox',
            add_form_col_radio_select: 'form_col_radio_select',
            add_form_col_image_text_select: 'form_col_image_text_select',
            add_form_col_box_text: 'form_col_box_text',
            add_form_col_text: 'form_col_text',
            add_form_col_image: 'form_col_image',
            add_form_col_icon_link: 'form_col_icon_link',
            add_form_col_space: 'form_col_space',
            add_form_col_line: 'form_col_line',
            add_form_col_hidden: 'form_col_hidden'
        }[action];

    } else if ('edit_element_options,edit_element_scope,edit_element_behavior,image_select,video_select,menu_structure,edit_layout,edit_row_size,edit_column_size,edit_text,edit_element_style,edit_element_background,edit_element_border,edit_element_corner,edit_element_shadow,remove_element'.split(',').includes(action)) {
        const state = structure_store.getState();
        el_type = state.selected_el_type;
        el_idx = state.selected_el_idx;
        data_action_type = 'edit';

        if (['edit_element_options', 'edit_element_scope', 'edit_element_behavior', 'image_select', 'video_select', 'menu_structure'].includes(action)) {
            panel_type = 'element_options_panel';

            if (action === 'image_select' || action === 'video_select') {
                data_action_type = 'select';
                amount = 15;
            } else if (action === 'menu_structure') {
                data_action_type = 'manage';
            }

            frm_name = {
                edit_element_options: el_type + '_settings',
                edit_element_scope: 'scope_settings',
                edit_element_behavior: 'behavior_settings',
                image_select: 'image_select',
                video_select: 'video_select',
                menu_structure: 'menu_structure'
            }[action];

        } else if (action === 'edit_layout') {
            panel_type = 'element_layout_panel';
            frm_name = 'layout_settings';
            data_action_type = 'edit';

        } else if (action === 'edit_row_size' || action === 'edit_column_size') {
            panel_type = 'row_column_size_panel';
            frm_name = 'row_column_size_settings';
            data_action_type = 'edit';

        } else if (action === 'edit_text') {
            panel_type = 'element_edit_panel';
            frm_name = 'text_editor';
            data_action_type = 'edit';

        } else if (['edit_element_background', 'edit_element_border', 'edit_element_corner', 'edit_element_shadow'].includes(action)) {
            panel_type = 'element_style_panel';
            tools_type = 'extended';
            data_action_type = 'edit';
            frm_name = {
                edit_element_background: 'background_settings',
                edit_element_border: 'border_settings',
                edit_element_corner: 'corner_settings',
                edit_element_shadow: 'shadow_settings'
            }[action];

        } else if (action === 'edit_element_style') {
            panel_type = 'element_style_panel';
            frm_name = 'menu_styling';
            data_action_type = 'edit';

        } else if (action === 'remove_element') {
            panel_type = 'remove_element_panel';
            frm_name = 'remove_element';
            data_action_type = 'remove';
        }
    } else if ('move_up,move_down'.split(',').includes(action)) {
        const state = structure_store.getState();
        el_type = state.selected_el_type;
        el_idx = state.selected_el_idx;
    }

    return {
        access,
        click_action_type,
        data_action_type,
        tools_type,
        panel_type,
        frm_name,
        amount,
        offset,
        el_type,
        el_idx,
        holder_grid_el_idx,
        holder_row_el_idx,
        holder_column_el_idx,
        holder_el_idx,
        new_element,
        get_all,
        cut_copy_action,
        paste_el_type,
        paste_grid_el_idx,
        paste_row_el_idx,
        paste_column_el_idx,
        paste_el_idx,
        holder_el_type
    };
};