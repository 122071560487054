import React from 'react';
import { buildFormElementID, buildFormElementName } from '../../utils/form';

export const renderHeader = (Heads) => {
    return (
        <thead>
            <tr style={{
                backgroundColor: '#333',
                textAlign: 'left'
            }}>
                {Heads.map((Head, index) => {
                    if (Head.SubHeads) {
                        return (
                            <th key={index}>
                                {Head.SubHeads.map((SubHead, subindex) => {
                                    const style = { float: 'left' };
                                    if (SubHead.width) {
                                        style.width = SubHead.width + 'px';
                                    }
                                    return (
                                        <div
                                            key={subindex}
                                            style={style}
                                        >
                                            {renderHeadLabel(SubHead.label)}
                                        </div>
                                    );
                                })}
                            </th>
                        );
                    }
                    return (
                        <th key={index}>
                            {renderHeadLabel(Head.label)}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export const renderHeadLabel = (label) => {
    const style = {
        color: '#fff',
        paddingLeft: '8px',
        fontWeight: 'bold'
    };
    return (
        <p
            className="gp"
            style={style}
        >{label}</p>
    );
};

export const renderGrid = (id, Heads, Rows) => {
    const style = {
        float: 'left',
        paddingLeft: '4px'
    };

    return (
        <div
            id={id}
            style={style}
        >
            <table style={{ border: '1px solid #999' }}>
                {
                    renderHeader(Heads)
                }
                <tbody>
                    {Rows}
                </tbody>
            </table>
        </div>
    );
};

const renderAction = (Action, index) => {
    const style = {
        paddingLeft: '2px',
        paddingRight: '10px'
    };
    const style2 = {
        float: 'left',
        width: '16px',
        height: '16px',
        cursor: 'pointer',
    };

    return (
        <td
            key={index}
            style={style}
        >
            <div
                className={Action.icon}
                style={style2}
                onClick={Action.onClick}
            />
        </td>
    );
};

export const renderCells = (Cells) => {
    const style = {
        float: 'left',
        padding: '6px 8px'
    };

    return Cells.map((Cell, index) => {
        if (Cell.type === 'action') {
            return renderAction(Cell, index);
        } else if (Cell.type === 'custom') {
            return (
                <td key={index}>
                    <div
                        style={style}
                        title={Cell.hoverText || null}
                    >
                        {Cell.content}
                    </div>
                </td>
            );
        } else {
            return (
                <td key={index}>
                    <p
                        style={style}
                        className="gp"
                        title={Cell.hoverText || null}
                    >
                        {Cell.value}
                    </p>
                </td>
            );
        }
    });
};

export const renderRow = (id, index, Cells) => {
    const style = {};
    if (index % 2 === 0) {
        style.backgroundColor = '#DDD';
    }
    if (index > 0) {
        style.borderTop = '1px solid #999';
    }

    return (
        <tr
            key={index}
            id={id}
            style={style}
        >
            {renderCells(Cells)}
        </tr>
    );
};

export const renderButton = (submit_action, label, onClick, Style, frm_id, Data_Groups, tab, index) => {
    const style = {
        position: 'relative',
        backgroundColor: '#ddd',
        backgroundImage: 'linear-gradient(to bottom, #f2f2f2, #bebebe)',
        border: '1px solid #aaa',
        borderRadius: '6px',
        margin: '2px'
    };

    if (Style) {
        ['width', 'height', 'paddingRight']
            .map((prop, index) => ({ prop, value: Style[index] }))
            .filter(set => set.value && !isNaN(set.value))
            .forEach(set => {
                style[set.prop] = set.value + 'px';
            });
    }

    return (
        <div style={style}>
            <input
                type="button"
                id={buildFormElementID(frm_id, Data_Groups, submit_action + '_' + index)}
                name={submit_action ? buildFormElementName(Data_Groups, submit_action, 'button') : null}
                value=""
                className="clickable_input fld_input_invisible fld_pointer"
                tabIndex={tab ? tab : null}
                onClick={onClick ? e => { e.preventDefault(); onClick(e); } : null}
            />
            <p style={{ fontSize: '10px', lineHeight: '17px', textAlign: 'center' }}>
                {label}
            </p>
        </div>
    );
};