import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColDropdownSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const Current_Data = Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Dropdown: {
                    value: '',
                    name: '',
                    label: '',
                    styling_type: 'quality',
                    width_type: 'cus',
                    width: 100,
                    margin_top: 0,
                    required: 'false',
                    readonly: 'false',
                    Options: [
                        {
                            value: '',
                            label: '',
                        }
                    ]
                }
            } :
            clone(Load_Data);

        return Current_Data;
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Dropdown {
                    value: String
                    name: String
                    label: String
                    styling_type: String
                    width_type: String
                    width
                    margin_top
                    required: String
                    readonly: String
                    Options {
                        value: String
                        label: String
                    }
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Dropdown', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Dropdown.name, max: 2 },
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Dropdown.label, max: 2 },
                ],
                [
                    { type: 'dropdown', id: 'styling_type', label: 'Size' },
                    {
                        value: Current_Data.Form_Col_Dropdown.styling_type,
                        Options: [['normal', 'Normal'], ['quality', 'Big']],
                    },
                ],
                [
                    { type: 'dropdown', id: 'width_type', label: 'Width type' },
                    {
                        value: Current_Data.Form_Col_Dropdown.width_type,
                        Options: [['cus', 'Custom'], ['100', 'Full']],
                    },
                ],
                [
                    {
                        type: Current_Data.Form_Col_Dropdown.width_type === 'cus' ? 'number' : 'hidden',
                        id: 'width',
                        label: 'Width',
                    },
                    {
                        value: Current_Data.Form_Col_Dropdown.width,
                        min_val: 1,
                        max_val: 999,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'number', id: 'margin_top', label: 'Margin top' },
                    {
                        value: Current_Data.Form_Col_Dropdown.margin_top,
                        min_val: 0,
                        max_val: 999,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'checkbox', id: 'required', label: 'Required' },
                    {
                        value: Current_Data.Form_Col_Dropdown.required,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'checkbox', id: 'readonly', label: 'Readonly' },
                    {
                        value: Current_Data.Form_Col_Dropdown.readonly,
                        styling_type: 'switch',
                    },
                ],
                [{ type: 'text' }, { text: 'Options', style: 'subtitle' }],
                [
                    { type: 'selector_options_management', id: 'options', colspan: 2 },
                    {
                        Options: Current_Data.Form_Col_Dropdown.Options,
                        value: Current_Data.Form_Col_Dropdown.value,
                    },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Current_Data.Form_Col_Dropdown.Options.length < 1) {
                    alert('Cannot proceed, because no options were created.');
                } else if (Current_Data.Form_Col_Dropdown.value === '') {
                    alert('Cannot proceed, because no option was selected.');
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_dropdown_settings',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save'),
        ];
    }
}
