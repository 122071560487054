import { Component, Fragment, default as React } from 'react';
import layout_store from '../api/layout_store';
import structure_store from '../api/structure_store';
import Toolbar from '../tools/toolbar';
import { runScrollEvents } from '../utils/browser';
import Ground from './ground';
import Layer from './layer';

export default class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breakpoint_hor_pos_pixel: layout_store.getState().breakpoint_hor_pos_pixel
        };
        this.unsubscribes = [];
        this.myRef;
        this.isScrollZonesMechanismInitiated = false;
    }

    setRef(el) {
        if (this.props.data && el) {
            this.myRef = el;
            if (this.props.data.show_tools) {
                this.myRef.addEventListener('scroll', this.runScrollStuff.bind(this));
            }
        }
    }

    runScrollStuff() {
        const { Scroll_Zones } = this.props.data;
        const yPos = (this.myRef.scrollY ? this.myRef.scrollY : this.myRef.scrollTop);

        if (Scroll_Zones) {
            runScrollEvents(yPos, Scroll_Zones);
        }

        layout_store.dispatch({
            type: 'SET_CONTENT_HOLDER_SCROLL_POSITION',
            content_holder_pos: yPos
        });
    }

    componentDidMount() {
        this.unsubscribes = [
            structure_store.subscribe(this.handleStructureStoreChange.bind(this)),
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    shouldComponentUpdate(nextProps) {
        return Boolean(nextProps.data);
    }

    handleStructureStoreChange() {
        const state = structure_store.getState();
        //TODO: handle structure actions (remove, paste, create, move) by manipulating the data object (and oldData ??)
    }

    handleLayoutStoreChange() {
        const { action, breakpoint_hor_pos_pixel } = layout_store.getState();

        if (action === 'toolbar_resize') {
            this.setState({
                breakpoint_hor_pos_pixel
            });
        }
    }

    renderContent(data, effectClass, requiresNewEffect) {
        const { navigate, reloadForm, updateActionData, parent_disabled, minimum_device_width } = this.props;

        return (
            <Fragment>{
                data.Content.map(Element =>
                    Element.el_type === 'ground' ?
                        <Ground
                            key={Element.el_idx}
                            minimum_device_width={minimum_device_width}
                            Element={Element}
                            el_type={Element.el_type}
                            el_idx={Element.el_idx}
                            Presets={data.Presets}
                            requiresNewEffect={requiresNewEffect}
                            effectClass={effectClass}
                            show_tools={data.show_tools}
                        /> :
                        <Layer
                            key={Element.el_idx}
                            Site={data.Site}
                            Navigation_Data={data.Navigation_Data}
                            minimum_device_width={minimum_device_width}
                            Presets={data.Presets}
                            Font_Families={data.Font_Families}
                            project={data.project}
                            site_id={data.Site.id}
                            page_id={data.Page.id}
                            show_tools={data.show_tools}
                            use_service={data.use_service}
                            tab="1"
                            holder_element_width_type="full"
                            holder_element_height_type="full"
                            amount_of_elements_in_holder={data.Content.length}
                            Element={Element}
                            signed_in={data.signed_in}
                            navigate={navigate}
                            user_role={data.user_role}
                            requiresNewEffect={requiresNewEffect}
                            level={1}
                            effectClass={effectClass}
                            reloadForm={reloadForm}
                            updateActionData={updateActionData}
                            parent_disabled={parent_disabled}
                        />
                )}
            </Fragment>
        );
    }

    render() {
        const { data, oldData, navigate, loadInSecond, Function_Data } = this.props;
        const effect = data && !data.signed_in && data.Site && data.Site.page_transition_effect ?
            data.Site.page_transition_effect :
            'none';
        const effects = { none: '', slideLeft: 'effectSlideLeft', fadeIn: 'effectFadeIn' };
        const effectClass = effects[effect];
        const mainObj = data && data.Site ? this.renderContent(data, effectClass, Boolean(oldData)) : null;
        const oldObj = oldData ? this.renderContent(oldData, effectClass, Boolean(oldData)) : null;

        const newClass = `appNewContainer ${effectClass}`;

        return (data && data.Site ? (
            data.show_tools ?
                <Fragment>
                    <Toolbar
                        Function_Data={Function_Data}
                        data={data}
                        navigate={navigate}

                    />
                    <div
                        ref={this.setRef.bind(this)}
                        className="content_holder_with_tools_bar"
                        style={{ left: `${this.state.breakpoint_hor_pos_pixel}px`, height: '100%' }}
                    >
                        {mainObj}
                    </div>
                </Fragment> :
                <Fragment>
                    {
                        <div
                            style={oldData || !loadInSecond ? { height: '100%' } : { height: 0, width: 0, opacity: 0 }}
                            className={oldData && !loadInSecond ? newClass : null}
                        >
                            {oldData && !loadInSecond && <div className={`appNewContainerBG ${effectClass}`} />}
                            {!loadInSecond ? mainObj : oldObj}
                        </div>
                    }
                    {
                        <div
                            style={oldData || loadInSecond ? { height: '100%' } : { height: 0, width: 0, opacity: 0 }}
                            className={oldData && loadInSecond ? newClass : null}
                        >
                            {oldData && loadInSecond && <div className={`appNewContainerBG ${effectClass}`} />}
                            {loadInSecond ? mainObj : oldObj}
                        </div>
                    }
                </Fragment>
        ) : <div />
        );
    }
}
