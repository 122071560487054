import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { renderGrid, renderRow } from './management_grid';

export default class ResolutionManagement extends Component {
    onClickEdit(Resolution) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'edit_resolution_panel',
                    frm_name: 'resolution_settings',
                    resolution_scope_id: Resolution.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Resolution) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_resolution_panel',
                    frm_name: 'remove_resolution',
                    resolution_scope_id: Resolution.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.Resolutions.length === 0 ?
            (<p className="gp">No resolutions available.</p>) :
            renderGrid(
                id + '_resolution_list',
                [
                    { label: 'Min Width' },
                    { label: 'Max Width' },
                    { label: 'Min Height' },
                    { label: 'Max Height' },
                    { label: '' },
                    { label: '' }
                ],
                this.props.El_Data.Resolutions.map((Resolution, index) =>
                    renderRow(
                        id + '_resolution_row_' + Resolution.id,
                        index,
                        [
                            { type: 'text', value: Resolution.min_width },
                            { type: 'text', value: Resolution.max_width },
                            { type: 'text', value: Resolution.min_height },
                            { type: 'text', value: Resolution.max_height },
                            { type: 'action', icon: 'wrench', onClick: this.onClickEdit(Resolution) },
                            { type: 'action', icon: 'delete', onClick: this.onClickDelete(Resolution) }
                        ]
                    )
                )
            );
    }
}