import React, { Component } from 'react';
import { Form_Router } from './element_router';

//SHOULD GET RID OF THIS FILE, USE CLASSES INSIDE "LAYER/COLUMN"

export class MultipleColumns extends Component {
    render() {
        const {
            project, site_id, frm_id, Data_Groups, tab, Content, show_tools, use_service, requiresNewEffect, reloadForm,
            updateActionData, changeCurrentData, parent_disabled, Navigation_Data, page_id, user_role
        } = this.props;
        //TODO: scenario where form-element is initially "hidden"

        return (
            <table className="full_width"><tbody>
                <tr>{
                    Content && Content.map((Element, index) =>
                        <Form_Router
                            key={index}
                            project={project}
                            site_id={site_id}
                            frm_id={frm_id}
                            Data_Groups={Data_Groups}
                            tab={tab}
                            Element={Element}
                            show_tools={show_tools}
                            use_service={use_service}
                            requiresNewEffect={requiresNewEffect}
                            reloadForm={reloadForm}
                            updateActionData={updateActionData}
                            changeCurrentData={changeCurrentData}
                            parent_disabled={parent_disabled}
                            Navigation_Data={Navigation_Data}
                            page_id={page_id}
                            user_role={user_role}
                        />
                    )
                }</tr>
            </tbody></table>
        );
    }
}

export class FormGrid extends Component {
    render() {
        const {
            project, site_id, frm_id, Data_Groups, tab, Content, show_tools, use_service, requiresNewEffect, reloadForm,
            updateActionData, changeCurrentData, parent_disabled, Navigation_Data, page_id, user_role
        } = this.props;
        //TODO: scenario where form-element is initially "hidden"

        return (
            <table className="full_width"><tbody>
                {Content && Content.map((Column, rowIndex) =>
                    <tr key={rowIndex}>{
                        Column && Column.map((Element, index) =>
                            <Form_Router
                                key={index}
                                project={project}
                                site_id={site_id}
                                frm_id={frm_id}
                                Data_Groups={Data_Groups}
                                tab={tab}
                                Element={Element}
                                show_tools={show_tools}
                                use_service={use_service}
                                requiresNewEffect={requiresNewEffect}
                                reloadForm={reloadForm}
                                updateActionData={updateActionData}
                                changeCurrentData={changeCurrentData}
                                parent_disabled={parent_disabled}
                                Navigation_Data={Navigation_Data}
                                page_id={page_id}
                                user_role={user_role}
                            />
                        )
                    }</tr>
                )}
            </tbody></table>
        );
    }
}