import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColColorFieldSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Color_Field: {
                    name: '',
                    colspan: 2,
                    label: '',
                    value: 'ffffff',
                    required: 'false',
                    readonly: 'false'
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Color_Field {
                    name: String
                    colspan
                    label: String
                    value: String
                    required: String
                    readonly: String
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Color_Field', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Color_Field.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Color_Field.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Color_Field.label, max: 2 }
                ],
                [
                    { type: 'color', id: 'value', label: 'Initial value' },
                    { value: Current_Data.Form_Col_Color_Field.value }
                ],
                [
                    { type: 'checkbox', id: 'required', label: 'Required' },
                    { value: Current_Data.Form_Col_Color_Field.required, styling_type: 'switch' }
                ],
                [
                    { type: 'checkbox', id: 'readonly', label: 'Readonly' },
                    { value: Current_Data.Form_Col_Color_Field.readonly, styling_type: 'switch' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_color_field_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}