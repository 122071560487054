import React, { Component } from 'react';
import { Parser } from 'html-to-react';
import { buildFormElementID } from '../../utils/form';

export default class Text extends Component {
    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id),
            text = El_Data.text || '',
            text_style = El_Data.style || 'normal_grey',
            el_class = text_style === 'normal_grey_label' || text_style === 'normal_grey' ?
                'grey_txt' :
                (text_style === 'subtitle' ?
                    'subtitle' :
                    (text_style === 'bold' ? 'bold' : '')
                ),
            style = text_style !== 'normal_grey_label' ?
                {
                    width: '100%',
                    paddingTop: '4px',
                    paddingBottom: '4px'
                } :
                null;

        var htmlToReactParser = new Parser();
        var reactElement = htmlToReactParser.parse(text || '');

        return (
            <div
                id={id ? id : null}
                style={style}
            >
                <p className={'gp ' + el_class}>
                    {reactElement}
                    {El_Data.required && <span style={{ color: 'red' }}>*</span>}
                </p>
            </div>
        );
    }
}