import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class PresetSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return {
            Preset: {
                preset_name: ''
            }
        };
    }

    getQuery() {
        return null;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Preset', [
                [
                    { type: 'field', id: 'preset_name', label: 'Preset name' },
                    { value: Current_Data.Preset.preset_name }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'add_preset',
                        Function_Data,
                        process_tag: 'Creating...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, 'Create')
        ];
    }
}