import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class TextEditor extends Component {
    //TODO: check if this class has become obsolete (the editor is now inside the webpage... this was the popup???)
    constructor(props) {
        super(props);
        this.state = { hasFocus: false };
    }

    onBlur() {
        this.setState({ hasFocus: false });
    }

    onFocus() {
        this.setState({ hasFocus: true });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab, changeCurrentData, parent_disabled } = this.props,
            { hasFocus } = this.state,
            { value, max, language, readonly } = El_Data,
            used_max = max || 300,
            used_language = language || 'english',
            amount = value ? value.length : 0,
            Length_Alert_Texts = used_language === 'dutch' ?
                [' tekens resterend', ' tekens teveel'] :
                [' characters left', ' characters too much'],
            length_difference = used_max - amount,
            alert_text = (length_difference < 0 ? -length_difference : length_difference) + Length_Alert_Texts[(length_difference < 0 ? 1 : 0)],
            onChange = e => {
                const New_Data = El_Data.onChange ? El_Data.onChange(e.target.value) : null,
                    New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), e.target.value);
                changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
            };

        const width = 0;//TODO: dynamic? supply from where?
        const height = 0;//TODO: dynamic? supply from where?

        //Everything in-between the tags <textarea></textarea> will be placed in the actual textarea, including spaces
        return (
            <Fragment>
                <div className="form_field_box non">
                    <p className={'gp bold' + (length_difference < 0 ? ' red_txt' : '')}>
                        {alert_text}
                    </p>
                </div>
                <div style={{ display: 'none' }}>
                    <textarea
                        id={buildFormElementID(frm_id, Data_Groups, fld_id)}
                        name={buildFormElementName(Data_Groups, fld_id)}
                        wrap="soft"
                        className={'fld_text' + (hasFocus ? ' fld_text_focus' : '')}
                        style={{
                            width: (width || 300) + 'px',
                            height: (height || 150) + 'px'
                        }}
                        tabIndex={tab}
                        onChange={!readonly && !parent_disabled && onChange ? onChange : () => { }}
                        onBlur={!readonly && !parent_disabled ? this.onBlur.bind(this) : null}
                        onFocus={!readonly && !parent_disabled ? this.onFocus.bind(this) : null}
                        value={value !== null ? value : ''}
                        readOnly={readonly || parent_disabled}
                    />
                </div>
            </Fragment>
        );
    }
}