import { createStore } from 'redux';

const initialState = {
    action: '',
    Overlays: [],
    active_tool_action_panel: ''
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();
    //TODO: change this:
    // the tools should simply come in layers when type=bar... showing only the top layer.
    // when type=panel... it should show all panels, layered in order of opening
    // allowing only top panel to be close, but all to be moved.
    switch (action.type) {
        case 'CLOSE_ALL_TOOL_ACTION_PANELS':
            state.active_tool_action_panel_id = action.group_id;
            return state;
        case 'OPEN_OVERLAY_TOOLS':
            state.Overlays.push(action.panel_data);
            return state;
        case 'CLOSE_OVERLAY_TOOLS':
            state.Overlays.splice(-1, 1);
            return state;
        default:
            return state;
    }
});

export default store;