import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form.js';
import { addRules } from '../../utils/style';

export default class Checkbox extends Component {
    mkInputQualityCheckbox(id, checked, label_true, label_false, isDisabled, onChange) {
        const { Data_Groups, fld_id, tab } = this.props;

        addRules([
            {
                selector: '.onoffswitch-inner-' + id + ':before',
                style: {
                    content: (label_true || '"On"')
                }
            },
            {
                selector: '.onoffswitch-inner-' + id + ':after',
                style: {
                    content: (label_false || '"Off"')
                }
            }
        ]);

        return (
            <div className="onoffswitch unselectable" unselectable="on">
                <input
                    type="checkbox"
                    id={id}
                    name={buildFormElementName(Data_Groups, fld_id)}
                    className="onoffswitch-checkbox"
                    tabIndex={tab}
                    checked={checked}
                    onChange={onChange}
                    disabled={isDisabled}
                    value="true"
                />
                <label className={'onoffswitch-label' + (isDisabled ? ' disabled' : '')} htmlFor={id}>
                    <span
                        className={'onoffswitch-inner onoffswitch-inner-' + id}
                    />
                    <span
                        className="onoffswitch-switch"
                    />
                </label>
            </div>
        );
    }

    mkInputCheckbox(id, checked, box_label, isDisabled, onChange) {
        const { Data_Groups, fld_id, tab } = this.props;
        return (
            <Fragment>
                <input
                    type="checkbox"
                    id={id}
                    name={buildFormElementName(Data_Groups, fld_id)}
                    tabIndex={tab}
                    defaultChecked={checked}
                    onChange={onChange}
                    disabled={isDisabled}
                    value="true"
                />
                <p className="gp grey_txt" style={{ float: 'left', paddingTop: '3px' }}>
                    {box_label || ''}
                </p>
            </Fragment>
        );
    }

    render() {
        const
            { frm_id, Data_Groups, fld_id, El_Data, Col_Settings, changeCurrentData, parent_disabled } = this.props,
            { styling_type, required, readonly, box_label, label_true, label_false, onClick } = El_Data,
            { h_align } = Col_Settings,
            id = buildFormElementID(frm_id, Data_Groups, fld_id),
            isDisabled = parent_disabled || readonly,
            onChange = e => {
                if (!readonly) {
                    const value = e.target.checked ? 'true' : 'false',
                        New_Data = onClick ? onClick(value) : null,
                        New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), value);
                    changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
                }
            };
        // TODO use (required === 'true') option to render a star or something

        return (
            <div
                className={'form_field_box ' + (styling_type === 'switch' ? 'checkbox' : 'slct')}
                style={{
                    float: h_align && h_align !== 'center' ? h_align : undefined
                    //marginTop: '5px'
                }}
            >
                {styling_type === 'switch' ?
                    this.mkInputQualityCheckbox(id, El_Data.value === 'true', label_true, label_false, isDisabled, onChange) :
                    this.mkInputCheckbox(id, El_Data.value === 'true', box_label, isDisabled, onChange)
                }
            </div>
        );
    }
}
