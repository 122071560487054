import deepmerge from 'deepmerge';
import layer_store from '../../api/layer_store';
import scope_group_store from '../../api/scope_group_store';
import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class ScopeGroupManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [scope_group_store.subscribe(this.handleScopeGroupStoreChange(changeCurrentData, getCurrentData))];
    }

    handleScopeGroupStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = scope_group_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Scope_Groups = clone(Current_Data.Scope_Groups);

            if (state.action === 'add') {
                Changed_Scope_Groups.push(deepmerge(
                    {
                        idx: '',
                        page_sub: false,
                        pages_amount: 0,
                        site_id: '1'
                    },
                    state.New_Scope_Group
                ));
            }
            if (state.action === 'update') {
                Changed_Scope_Groups = Changed_Scope_Groups.map(Scope_Group => {
                    if (parseInt(Scope_Group.idx, 10) === parseInt(state.Updated_Scope_Group.idx, 10)) {
                        return deepmerge(
                            Scope_Group,
                            state.Updated_Scope_Group
                        );
                    }
                    return Scope_Group;
                });
            }
            if (state.action === 'remove') {
                const index = findIndexArray(
                    Changed_Scope_Groups,
                    Scope_Group => parseInt(Scope_Group.idx, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Scope_Groups.splice(index, 1);
            }

            changeCurrentData({ Scope_Groups: Changed_Scope_Groups });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Scope_Groups
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text' },
                    { text: 'Scope Groups', style: 'subtitle' }
                ],
                [
                    { type: 'text' },
                    {
                        text:
                            'Scope groups can be used to display elements only on a certain group<br>' +
                            'of pages. Per element one can specify to display it either any one<br>' +
                            'specific page, on a group of pages or on the whole site.<br>',
                        style: 'normal_grey'
                    }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'scope_management', id: 'scope_group_id' },
                    {
                        Scope_Groups: Current_Data.Scope_Groups,
                        page_id: Function_Data.page_id
                    }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'add_new_scope_group',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [114, 16],
                        text: 'Add Scope Group',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_scope_group_panel',
                                            frm_name: 'scope_group_settings',
                                            scope_group_id: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'create'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}