export const ucFirst = (s) => {
    s = s || '';
    return s.substring(0, 1).toUpperCase() + s.substring(1, s.length);
};

export const ucWords = (s, underscore) => {
    s = s || '';
    let r = '';
    s = s.split(underscore ? '_' : ' ');
    for (let i = 0; i < s.length; i++) {
        r = r + (s[i].substring(0, 1).toUpperCase() + s[i].substring(1, s[i].length));
        r = r + (i + 1 < s.length ? (underscore ? '_' : ' ') : '');
    }
    return r;
};

export const snakeCaseToPascalCase = (s) => {
    return ucWords(s, true).replace(/_/g, '');
};

export const encodeSpecialHTML = (s) => {
    return s.replace(/[&<>"']/g, (m) => {
        return { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', '\'': '&#039;' }[m];
    });
};

export const stripHTMLTags = (s) => {
    return s.replace(/(<([^>]+)>)/ig, '');
};

export const replaceDiacritics = (s) => {
    let diacritics = [
        /[\300-\306]/g, /[\340-\346]/g,
        /[\310-\313]/g, /[\350-\353]/g,
        /[\314-\317]/g, /[\354-\357]/g,
        /[\322-\330]/g, /[\362-\370]/g,
        /[\331-\334]/g, /[\371-\374]/g,
        /[\321]/g, /[\361]/g,
        /[\307]/g, /[\347]/g
    ];

    for (let i = 0; i < diacritics.length; i++) {
        s = s.replace(diacritics[i], ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'][i]);
    }

    return s;
};

export const formatStringLetters = (title, letter_format) => {
    return title = letter_format === 'all_lowercase' ? title.toLowerCase() : (
        letter_format === 'first_uppercase' ? ucFirst(title) : (
            letter_format === 'all_uppercase' ? title.toUpperCase() : title
        )
    );
};

export const getSafeUrl = (url) => {
    return replaceDiacritics(url)
        .replace(/[^A-Za-z0-9@#%&_,:;=!~'`\/\?\-\.\$\*\+\(\)\[\]]/g, '');
};
