import React, { Component } from 'react';
import { Tools_Action_Paneled } from './tools_action';

export default class Language_Tools extends Component {
    handleChange(event) {
        const { Function_Data, navigate } = this.props;
        navigate(
            Function_Data.default_language_type,
            event.target.value,
            Function_Data.device_type,
            Function_Data.base_url,
            Function_Data.site_url,
            Function_Data.Page_URLs,
        );
    }

    render() {
        const { Function_Data, Languages, tab } = this.props;

        const Buttons = [
            ['manage_languages', 'Languages', 'languages']
        ];

        return (
            <div className="tools_dropdown_container_holder">
                <div className="main_actions">
                    <Tools_Action_Paneled
                        given_style="button_list"
                        Function_Data={Function_Data}
                        id="language_action_group"
                        icon="languages"
                        color="black"
                        Buttons={Buttons}
                    />
                </div>
                <div className="tools_dropdown_container">
                    <div className="styled_select_holder black tools_dropdown_selector_holder">
                        <div>
                            <select
                                id="switch_language_select"
                                name="switch_language_select"
                                tabIndex={tab}
                                className="tools_dropdown_selector"
                                value={Function_Data.language_type}
                                onChange={(e) => this.handleChange(e)}
                            >
                                {Languages.map(Language => {
                                    return (
                                        <option
                                            key={Language.id}
                                            className="tools_dropdown_selector_option"
                                            value={Language.language_type}
                                        >
                                            {Language.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}