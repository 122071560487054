import deepmerge from 'deepmerge';
import { createStore } from 'redux';

const initialState = {
    action: '',
    //country_id: 0,
    //email: '',
    //date_of_birth: '',
    first_name: '',
    last_name: ''
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'INIT':
            state = action.User;
            return state;
        case 'UPDATE':
            state = deepmerge(state, action.User);
            return state;
        default:
            return state;
    }
});

export default store;