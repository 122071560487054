import media_item_store from '../../api/media_item_store';
import { upload } from '../../api/upload_query';
import { getImageURL, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

//TODO: this class should be a truly media-management class.. thus merge video-management, image-select, video-select

export default class ImageManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [media_item_store.subscribe(this.handleMediaItemStoreChange(changeCurrentData, getCurrentData))];
    }

    handleMediaItemStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = media_item_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Media_Items = clone(Current_Data.Images);

            if (state.action === 'add') {
                Changed_Media_Items.push(state.New_Media_Item);
            }

            if (state.action === 'update') {
                Changed_Media_Items = Changed_Media_Items.map(Media_Item => {
                    if (parseInt(Media_Item.id, 10) === parseInt(state.resource_id, 10)) {
                        Media_Item.description = state.Updated_Media_Properties.description;
                    }
                    return Media_Item;
                });
            }

            if (state.action === 'remove') {
                //TODO: also unselect if media-item was selected.
                const index = findIndexArray(
                    Changed_Media_Items,
                    Media_Item => parseInt(Media_Item.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Media_Items.splice(index, 1);
            }

            changeCurrentData({ Images: Changed_Media_Items });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Images
            image_origin_file_type
            resource_id
            total_amount
            max_file_uploads
            upload_max_filesize
            post_max_size
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', id: 'Selected_Image' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [[
                        { type: 'row' },
                        {
                            Content: [
                                [
                                    { type: 'button_image', id: 'upload_new_image', h_align: 'left', v_align: 'center' },
                                    {
                                        name: 'upload_new_image',
                                        button_style: [110, 32, 0, 4, 0, 4, 8],
                                        text: 'Upload',
                                        img_url: getImageURL('rootflex', 'choose_file'),
                                        img_style: [0, 37, 35, 0],
                                        file_upload: true,
                                        filetype_restriction: 'image/*',
                                        onChange: Files => {
                                            upload(
                                                Function_Data,
                                                frm_id,
                                                ['Selected_Image'],
                                                'upload_new_image',
                                                Files,
                                                {},
                                                'upload_images',
                                                Current_Data.max_file_uploads,
                                                Current_Data.upload_max_filesize,
                                                Current_Data.post_max_size,
                                                Result_Data => {
                                                    const new_total = Current_Data.total_amount + Result_Data.Uploaded_Images.length;
                                                    changeCurrentData(Action_Data.amount > Current_Data.Images.length ?
                                                        {
                                                            Images: clone(Current_Data.Images).concat(
                                                                Result_Data.Uploaded_Images
                                                                    .slice(0, Action_Data.amount - Current_Data.Images.length)
                                                                    .map(Image =>
                                                                        Object.assign({}, Image, {
                                                                            site_id: Function_Data.site_id
                                                                        })
                                                                    )
                                                            ),
                                                            total_amount: new_total
                                                        } :
                                                        { total_amount: new_total }
                                                    );
                                                }
                                            );
                                        }
                                    }
                                ],
                                [
                                    { type: 'text', padding_left: 10 },
                                    {
                                        text: 'The uploads will be added to the collection below.<br>\
                                                    Supported file-extensions: jpg, jpeg, jfif, png, gif.',
                                        style: 'bold'
                                    }
                                ]
                            ]
                        }
                    ]]
                }
            ],
            wrapSectionSubs('Selected_Image', [
                [
                    { type: 'media_selector', id: 'resource_id' },
                    {
                        Items: Current_Data.Images,
                        Selected_Item: {
                            id: 0,
                            file_type: ''
                        },
                        media_type: 'image',
                        lightbox_path: 'projects/' + Function_Data.project + '/cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/img/w750h600/',
                        thumbnail_path: 'projects/' + Function_Data.project + '/cache/' + (Function_Data.use_service ? 'service' : 'user') + '/' + Function_Data.site_id + '/img/w150h100/',
                        width: 150,
                        height: 100,
                        start: 1,
                        total_amount: Current_Data.total_amount,
                        column_amount: 5,
                        disable_select: true
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}