import React, { Component, Fragment } from 'react';
import StructureHolder from './structure_holder';
import { mouseOverElement, mouseOutElement, onClickElement } from './element_events';

export default class StructureSequenceContent extends Component {
    render() {
        const { sequence_id, Content } = this.props;

        return (
            <Fragment>
                {Content.map((Element, index) => {
                    const el_idx = sequence_id + '_' + (index + 1);
                    return (
                        <div
                            id={'structure_sequence_content_' + sequence_id + '_' + (index + 1)}
                            className="structure_sequence_content"
                            key={index}
                        >
                            <div className="structure_column_marker_holder">
                                <div className="structure_sub grid_connector"></div>
                                <div className="structure_column_marker">
                                    <div
                                        id={'structure_sequence_element_' + el_idx}
                                        className="structure_sequence_index"
                                    >
                                        <p className="structure_sequence_item_txt">
                                            {index + 1}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="structure_grid_cell_border1"></div>
                            <div className="structure_grid_cell_border3"></div>
                            <div className="sortable_grid_sequence">
                                {/*
                                <div className="sortable_element_thing">
                                    {Element.Content && Element.Content.length > 0 &&
                                        <div className="structure_element_sub_control"/>
                                    }
                                    <StructureHolder
                                        holder_el_type="sequence"
                                        el_type={Element.el_type}
                                        el_idx={Element.el_idx}
                                        Content={Element.Content}
                                    />
                                </div>
                                */}
                                <StructureHolder
                                    holder_el_type="sequence"
                                    el_type={Element.el_type}
                                    el_idx={Element.el_idx}
                                    Content={Element.Content}
                                />
                            </div>
                        </div>
                    );
                })}
            </Fragment>
        );
    }
}