import form_data_store from '../../api/form_data_store';
import language_store from '../../api/language_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveLanguage {
    getQuery() {
        //TODO: Current_Data.Language_Data is not used... perhaps should check for amount of text in language that will be lost?!?
        return null/*`query DataQuery($urls: String!) {
            Language_Data
        }`*/;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', colspan: 2, h_align: 'center' },
                    { text: 'All the texts for the language will be deleted.<br/>Are you sure?', style: 'bold' }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'remove_language',
                        Function_Data,
                        process_tag: 'Removing...',
                        onSubmitted: removed_id => {
                            if (removed_id) {
                                language_store.dispatch({
                                    type: 'REMOVE',
                                    removed_id
                                });
                            } else {
                                alert('Some error occured when trying to delete the language.')
                            }
                        }
                    }
                });
            }, 'Delete')
        ];
    }
}