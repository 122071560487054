import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';
import { buildURL } from '../../utils/path';
import { replaceDiacritics } from '../../utils/string';

export default class SiteSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const Current_Data = Action_Data.data_action_type === 'create' ?
            {
                Site: {
                    title: '',
                    base_url_on_title: 'true',
                    site_url: '',
                    language: 'en',
                    ip_based_language: 'false',
                    cache: 'true',
                    robot_index: 'false',
                    robot_follow: 'false',
                    description: '',
                    environment: '',
                    copyright: '',
                    root_site: 'false',
                    page_transition_effect: 'none',
                    google_analytics_tracking_id: ''
                }
            } :
            clone(Load_Data);

        Current_Data.Site.is_root_site = Current_Data.Site.root_site.toString() === 'true';

        return Current_Data;
    }

    getSiteUrl(is_root_site, base_url_on_title, title, site_url, user_role) {
        return is_root_site ? '' :
            (base_url_on_title.toString() === 'true' ?
                replaceDiacritics(title)
                    .replace(/[&_,~\\\\\/\-\.\*\+\|]|\s/g, '-')
                    .toLowerCase()
                    .replace(/[^a-z0-9-]/g, '') :
                replaceDiacritics(site_url)
                    .replace(/[&~\\\\\/\.\*\+]|\s/g, '-')
                    .toLowerCase()
                    .replace((user_role === 'service_administrator' ?
                        /[^a-z0-9_,!\-\$\(\)]/g : /[^a-z0-9_,!\-\(\)]/g
                    ), '')
            )
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Site {
                    title
                    base_url_on_title
                    site_url
                    language
                    ip_based_language
                    cache
                    robot_index
                    robot_follow
                    description
                    environment
                    copyright
                    root_site
                    page_transition_effect
                    google_analytics_tracking_id
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: Action_Data.data_action_type === 'edit' ? 'section' : 'hidden', id: '' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [[
                        { type: 'row', id: 'site_management', label: ' ' },
                        {
                            Content: [
                                [
                                    {
                                        type: 'button_image',
                                        id: 'create_new_site',
                                        label: ' ',
                                        h_align: 'right',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'open_create_site_panel',
                                        button_style: [115, 35, 0, 4, 4, 2, 6],
                                        text: 'Create',
                                        img_url: getImageURL('rootflex', 'create_globe'),
                                        img_style: [0, 40, 32, 0],
                                        onClick: () => {
                                            layer_store.dispatch({
                                                type: 'OPEN_PANEL',
                                                panel_data: {
                                                    Function_Data,
                                                    Action_Data: deepmerge(
                                                        Action_Data,
                                                        {
                                                            access: 'authorized',
                                                            panel_type: 'create_site_panel',
                                                            frm_name: 'site_settings',
                                                            data_action_type: 'create'
                                                        }
                                                    )
                                                },
                                            });
                                        }
                                    }
                                ],
                                [
                                    {
                                        type: !Current_Data.Site.is_root_site ? 'button_image' : 'hidden',
                                        id: 'remove_site',
                                        label: ' ',
                                        h_align: 'left',
                                        v_align: 'center',
                                        padding_left: 5
                                    },
                                    {
                                        name: 'open_remove_site_panel',
                                        button_style: [115, 35, 2],
                                        text: 'Remove',
                                        img_url: getImageURL('rootflex', 'remove_globe'),
                                        img_style: [0, 40, 32, 0],
                                        onClick: () => {
                                            layer_store.dispatch({
                                                type: 'OPEN_PANEL',
                                                panel_data: {
                                                    Function_Data,
                                                    Action_Data: deepmerge(
                                                        Action_Data,
                                                        {
                                                            access: 'authorized',
                                                            panel_type: 'remove_site_panel',
                                                            frm_name: 'remove_site',
                                                            data_action_type: 'remove'
                                                        }
                                                    )
                                                },
                                            });
                                        }
                                    }
                                ]
                            ]
                        }
                    ]]
                }
            ],
            wrapSectionSubs('Site', [
                /*[
                {type:'text', colspan:2},
                {text:(Action_Data.data_action_type === 'create' ? 'New website' : (Action_Data.data_action_type === 'copy' ? 'Copy website' : 'Current website')), Style:'bold'}
                ],*/
                [
                    { type: 'field', id: 'title', label: 'Title' },
                    {
                        value: Current_Data.Site.title,
                        max: 2,
                        onChange: value => {
                            return {
                                Site: {
                                    site_url: this.getSiteUrl(
                                        Current_Data.Site.is_root_site,
                                        Current_Data.Site.base_url_on_title,
                                        value,
                                        Current_Data.Site.site_url,
                                        Function_Data.user_role
                                    )
                                }
                            };
                        }
                    }
                ],
                [
                    {
                        type: Current_Data.Site.is_root_site ? 'hidden' : 'checkbox',
                        id: 'base_url_on_title',
                        label: 'Base URL on title'
                    },
                    {
                        value: (Current_Data.Site.base_url_on_title), styling_type: 'switch',
                        onClick: value => {
                            return {
                                Site: {
                                    site_url: this.getSiteUrl(
                                        Current_Data.Site.is_root_site,
                                        value,
                                        Current_Data.Site.title,
                                        Current_Data.Site.site_url,
                                        Function_Data.user_role
                                    )
                                }
                            };
                        }
                    }
                ],
                [
                    {
                        type: Current_Data.Site.is_root_site ? 'hidden' : 'field',
                        id: 'site_url',
                        label: 'Site URL'
                    },
                    {
                        value: Current_Data.Site.site_url,
                        max: 2,
                        readonly: Current_Data.Site.base_url_on_title.toString() === 'true',
                        onClick: value => {
                            return {
                                Site: {
                                    site_url: this.getSiteUrl(
                                        Current_Data.Site.is_root_site,
                                        Current_Data.Site.base_url_on_title,
                                        Current_Data.Site.title,
                                        value,
                                        Function_Data.user_role
                                    )
                                }
                            };
                        }
                    }
                ],
                [
                    { type: 'dropdown', id: 'page_transition_effect', label: 'Page transition effect' },
                    {
                        value: Current_Data.Site.page_transition_effect,
                        Options: [
                            ['none', 'None'],
                            ['slideLeft', 'Slide left'],
                            ['fadeIn', 'Fade in']
                        ]
                    }
                ],
                [
                    { type: 'dropdown', id: 'language', label: 'Language' },
                    {
                        value: Current_Data.Site.language,
                        Options: [
                            ['en', 'English'],
                            ['nl', 'Dutch'],
                            ['fr', 'French'],
                            ['de', 'German'],
                            ['no', 'Norwegian'],
                            ['pt', 'Portuguese'],
                            ['es', 'Spanish'],
                            ['sv', 'Swedish']
                        ]
                    }
                ],
                [
                    { type: 'checkbox', id: 'cache', label: 'Browser cache' },
                    { value: Current_Data.Site.cache, styling_type: 'switch' }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Google Analytics', style: 'bold' }
                ],
                [
                    { type: 'field', id: 'google_analytics_tracking_id', label: 'Tracking ID' },
                    { value: Current_Data.Site.google_analytics_tracking_id, max: 2 }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Search engines', style: 'bold' }
                ],
                [
                    { type: 'checkbox', id: 'robot_index', label: 'Website indexing' },
                    { value: Current_Data.Site.robot_index, styling_type: 'switch' }
                ],
                [
                    { type: 'checkbox', id: 'robot_follow', label: 'Links to website' },
                    { value: Current_Data.Site.robot_follow, styling_type: 'switch' }
                ],
                [
                    { type: 'field', id: 'description', label: 'Description' },
                    { value: Current_Data.Site.description, max: 1 }
                ],
                [
                    { type: 'field', id: 'environment', label: 'Environment' },
                    { value: Current_Data.Site.environment, max: 1 }
                ],
                [
                    { type: 'field', id: 'copyright', label: 'Copyright holder' },
                    { value: Current_Data.Site.copyright, max: 2 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'add_site',
                            Function_Data,
                            process_tag: 'Creating...',
                            onSubmitted: Result_Data => {
                                navigate(
                                    Function_Data.default_language_type,
                                    Function_Data.language_type,
                                    Function_Data.device_type,
                                    Function_Data.base_url,
                                    Result_Data.site_url,
                                    []
                                );
                            }
                        }
                    });
                } else if (Action_Data.data_action_type === 'copy') {
                    //TODO?
                } else if (Action_Data.data_action_type === 'edit') {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_site',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                if (Action_Data.site_url !== Result_Data.site_url) {
                                    history.pushState({}, '', buildURL(
                                        Function_Data.default_language_type,
                                        Function_Data.language_type,
                                        Function_Data.device_type,
                                        Function_Data.base_url,
                                        Result_Data.site_url,
                                        Function_Data.Page_URLs
                                    ));
                                    //TODO: also change page-browse dropdown in tools
                                }
                                document.title = Result_Data.site_title;
                                //TODO: also change the site-browse-dropdown in the tools.
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Create' :
                (Action_Data.data_action_type === 'copy' ? 'Copy' : '')
            )
        ];
    }
}