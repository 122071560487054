import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';

export default class RichDropdown extends Component {
    constructor(props) {
        super(props);
        const { frm_id, Data_Groups, fld_id } = props;
        this.id = buildFormElementID(frm_id, Data_Groups, fld_id);
        this.state = {
            showOptions: false,
            selectedIndex: null
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.EventListeners = [
            [document, 'keydown', () => {
                layer_store.dispatch({
                    type: 'HIDE_ALL_DROPDOWNS',
                });
            }],
            [document, 'mousedown', () => {
                layer_store.dispatch({
                    type: 'HIDE_ALL_DROPDOWNS',
                });
            }]
        ];

        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));
    }

    componentWillUnmount() {
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
    }

    handleStoreChange() {
        const isActive = layer_store.getState().Dropdowns.includes(this.id);
        if (isActive && !this.state.showOptions) {
            this.setState({ showOptions: true });
        }
        if (!isActive && this.state.showOptions) {
            this.setState({ showOptions: false });
        }
    }

    onMouseDownOption(onClick) {
        return () => {
            //e.stopPropagation();
            onClick();
            layer_store.dispatch({
                type: 'HIDE_ALL_DROPDOWNS',
            });
        };
    }

    onMouseOverOption(index) {
        if (this.state.selectedIndex !== index) {
            this.setState({ selectedIndex: index });
        }
    }

    onMouseOutOption(index) {
        if (this.state.selectedIndex === index) {
            this.setState({ selectedIndex: null });
        }
    }

    onMouseDownTextEditor() {
        //e.stopPropagation();
        if (this.state.showOptions) {
            layer_store.dispatch({
                type: 'HIDE_ALL_DROPDOWNS',
            });
        } else {
            layer_store.dispatch({
                type: 'SHOW_DROPDOWN',
                name: this.id,
            });
        }
    }

    renderOptions() {
        const { Options, height } = this.props.El_Data;
        const style2 = {
            top: (height ? (height + 2) : '22') + 'px',
            width: '100%',
            padding: '0 2px 0 2px',
            boxSizing: 'border-box'
        };

        const style3 = {
            padding: 0,
            borderTop: 0,
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc',
            borderBottom: 0
        };

        return (
            <div
                className="text-editor-pane-contain"
                style={style2}
                onMouseDown={this.onMouseDownTextEditor}
            >
                <div
                    className="text-editor-pane"
                    style={style3}
                >
                    {Options.map((Option, index) => {
                        const isSelected = this.state.selectedIndex === index;
                        const optionStyle = {
                            backgroundColor: '#' + (isSelected ? 'ccc' : 'eee')
                            //TODO: IMPROVE TO MAKE WORK IN ANY CASE BY DETERMINING REQUIRED CONTRAST!
                        };
                        return (
                            <div
                                key={index}
                                className="text-editor-select-item-contain"
                                onMouseDown={this.onMouseDownOption(Option[2])}
                                onMouseOver={this.onMouseOverOption(index)}
                                onMouseOut={this.onMouseOutOption(index)}
                                style={optionStyle}
                            >
                                <div
                                    id={Option[0]}
                                    className="text-editor-select-item"
                                >
                                    {Option[1]}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        const { El_Data, parent_disabled } = this.props;
        const { height } = El_Data;

        const style = {
            position: 'relative',
            width: '100%',
        };

        if (height) {
            style.height = (height + 2) + 'px';
        }

        return (
            <div
                id={this.id}
                className="styled_select_holder"
                style={style}
            >
                <div>
                    <p>None</p>
                    <div></div>
                </div>
                {this.state.showOptions && this.renderOptions()}
            </div>
        );
    }
}