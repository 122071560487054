export const convertHexToRGB = hex => {
    hex = parseInt(((hex.indexOf('#') > -1) ? hex.substring(1) : hex), 16);
    return { r: hex >> 16, g: (hex & 0x00FF00) >> 8, b: (hex & 0x0000FF) };
};

export const convertHexToHSL = hex => {
    return convertRGBToHSL(convertHexToRGB(hex));
};

export const convertHexToHSB = hex => {
    return convertRGBToHSB(convertHexToRGB(hex));
};

export const convertRGBToHex = RGB => {
    return [
        parseInt(RGB.r, 10).toString(16),
        parseInt(RGB.g, 10).toString(16),
        parseInt(RGB.b, 10).toString(16)
    ]
        .map(hex => hex.length === 1 ? '0' + hex : hex)
        .join('');
};

export const convertHSLToHex = HSL => {
    return convertRGBToHex(convertHSLToRGB(HSL));
};

export const convertHSBToHex = HSB => {
    return convertRGBToHex(convertHSBToRGB(HSB));
};

export const convertHSBToHSL = HSB => {
    return convertRGBToHSL(convertHSBToRGB(HSB));
};

export const convertHSLToHSB = HSL => {
    return convertRGBToHSB(convertHSLToRGB(HSL));
};

export const convertRGBToHSL = RGB => {
    RGB.r = parseInt(RGB.r, 10);
    RGB.g = parseInt(RGB.g, 10);
    RGB.b = parseInt(RGB.b, 10);
    RGB.r /= 255, RGB.g /= 255, RGB.b /= 255;
    var max = Math.max(RGB.r, RGB.g, RGB.b), min = Math.min(RGB.r, RGB.g, RGB.b);
    var h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case RGB.r:
                h = (RGB.g - RGB.b) / d + (RGB.g < RGB.b ? 6 : 0);
                break;
            case RGB.g:
                h = (RGB.b - RGB.r) / d + 2;
                break;
            case RGB.b:
                h = (RGB.r - RGB.g) / d + 4;
                break;
        }
        h /= 6;
    }

    return { h: h * 360, s: s * 100, l: l * 100 };
};

export const convertRGBToHSB = RGB => {
    RGB.r = parseInt(RGB.r, 10);
    RGB.g = parseInt(RGB.g, 10);
    RGB.b = parseInt(RGB.b, 10);
    var h = 0,
        s = 0,
        b = 0,
        min = Math.min(RGB.r, RGB.g, RGB.b),
        max = Math.max(RGB.r, RGB.g, RGB.b),
        delta = max - min;

    b = max;
    s = max !== 0 ? 255 * delta / max : 0;
    if (s !== 0) {
        if (RGB.r === max) {
            h = (RGB.g - RGB.b) / delta;
        } else if (RGB.g === max) {
            h = 2 + (RGB.b - RGB.r) / delta;
        } else {
            h = 4 + (RGB.r - RGB.g) / delta;
        }
    } else {
        h = -1;
    }
    h *= 60;
    if (h < 0) {
        h += 360;
    }
    s *= 100 / 255;
    b *= 100 / 255;
    return { h: h, s: s, b: b };
};

export const convertHSLToRGB = HSL => {
    HSL.h = (parseFloat(HSL.h, 10) / 360);
    HSL.s = (parseFloat(HSL.s, 10) / 100);
    HSL.l = (parseFloat(HSL.l, 10) / 100);
    var r, g, b;

    if (HSL.s === 0) {
        r = g = b = HSL.l;
    } else {
        var hue2rgb = (p, q, t) => {
            if (t < 0) {
                t += 1;
            }
            if (t > 1) {
                t -= 1;
            }
            if (t < 1 / 6) {
                return p + (q - p) * 6 * t;
            }
            if (t < 1 / 2) {
                return q;
            }
            if (t < 2 / 3) {
                return p + (q - p) * (2 / 3 - t) * 6;
            }
            return p;
        };

        var q = HSL.l < 0.5 ? HSL.l * (1 + HSL.s) : HSL.l + HSL.s - HSL.l * HSL.s;
        var p = 2 * HSL.l - q;
        r = hue2rgb(p, q, HSL.h + 1 / 3);
        g = hue2rgb(p, q, HSL.h);
        b = hue2rgb(p, q, HSL.h - 1 / 3);
    }

    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
};

export const convertHSBToRGB = HSB => {
    HSB.h = parseFloat(HSB.h, 10);
    HSB.s = parseFloat(HSB.s, 10);
    HSB.b = parseFloat(HSB.b, 10);
    var RGB = {},
        h = Math.round(HSB.h),
        s = Math.round(HSB.s * 255 / 100),
        v = Math.round(HSB.b * 255 / 100);

    if (s === 0) {
        RGB.r = RGB.g = RGB.b = v;
    } else {
        var t1 = v,
            t2 = (255 - s) * v / 255,
            t3 = (t1 - t2) * (h % 60) / 60;

        if (h === 360) {
            h = 0;
        }
        if (h < 60) {
            RGB.r = t1;
            RGB.b = t2;
            RGB.g = t2 + t3;
        } else if (h < 120) {
            RGB.g = t1;
            RGB.b = t2;
            RGB.r = t1 - t3;
        } else if (h < 180) {
            RGB.g = t1;
            RGB.r = t2;
            RGB.b = t2 + t3;
        } else if (h < 240) {
            RGB.b = t1;
            RGB.r = t2;
            RGB.g = t1 - t3;
        } else if (h < 300) {
            RGB.b = t1;
            RGB.g = t2;
            RGB.r = t2 + t3;
        } else if (h < 360) {
            RGB.r = t1;
            RGB.g = t2;
            RGB.b = t1 - t3;
        } else {
            RGB.r = 0;
            RGB.g = 0;
            RGB.b = 0;
        }
    }
    return { r: Math.round(RGB.r), g: Math.round(RGB.g), b: Math.round(RGB.b) };
};

export const distillColorFromCSSString = value => {
    //s_str = raw style string
    let color_hex, RGB, HSL, opacity = 100;

    if (
        (value.substr(0, 5) === 'rgba(' && value.substr(value.length - 1, 1) === ')') ||
        (value.substr(0, 4) === 'rgb(' && value.substr(value.length - 1, 1) === ')')
    ) {
        RGB = value.substr(value.indexOf('(') + 1, value.length - 1).split(',');
        if (Array.isArray(RGB) &&
            parseInt(RGB[0], 10) > -1 &&
            parseInt(RGB[0], 10) < 256 &&
            parseInt(RGB[1], 10) > -1 &&
            parseInt(RGB[1], 10) < 256 &&
            parseInt(RGB[2], 10) > -1 &&
            parseInt(RGB[2], 10) < 256
        ) {
            color_hex = convertRGBToHex({ r: RGB[0], g: RGB[1], b: RGB[2] });
            opacity = typeof (RGB[3]) !== 'undefined' && parseInt(RGB[3], 10) < 1 && parseInt(RGB[3], 10) > 0 ?
                (parseInt(RGB[3], 10) * 100) :
                opacity;
        }
    } else if (
        (value.substr(0, 5) === 'hsla(' && value.substr(value.length - 1, 1) === ')') ||
        (value.substr(0, 4) === 'hsl(' && value.substr(value.length - 1, 1) === ')')
    ) {
        HSL = value.substr(value.indexOf('(') + 1, value.length - 1).split(',');
        if (Array.isArray(HSL) &&
            parseInt(HSL[0], 10) > -1 &&
            parseInt(HSL[0], 10) < 361 &&
            parseInt(HSL[1], 10) > -1 &&
            parseInt(HSL[1], 10) < 101 &&
            parseInt(HSL[2], 10) > -1 &&
            parseInt(HSL[2], 10) < 101
        ) {
            color_hex = convertHSLToHex({ h: HSL[0], s: HSL[1], l: HSL[2] });
            opacity = typeof (HSL[3]) !== 'undefined' && parseInt(HSL[3], 10) < 1 && parseInt(HSL[3], 10) > 0 ?
                (parseInt(HSL[3], 10) * 100) :
                opacity;
        }
    } else if (
        value.substr(0, 1) === '#' &&
        (value.length === 4 || value.length === 7) &&
        /^[0-9a-f]+$/.test(value.substr(1, 6))
    ) {
        color_hex = value.substr(1, 6);
    } else {
        color_hex = tryDistillColorName(value);
    }

    return [color_hex, opacity];
};
const tryDistillColorName = value => {
    var Color_Names = {
        aliceblue: 'f0f8ff',
        antiquewhite: 'faebd7',
        aqua: '00ffff',
        aquamarine: '7fffd4',
        azure: 'f0ffff',
        beige: 'f5f5dc',
        bisque: 'ffe4c4',
        black: '000000',
        blanchedalmond: 'ffebcd',
        blue: '0000ff',
        blueviolet: '8a2be2',
        brown: 'a52a2a',
        burlywood: 'deb887',
        cadetblue: '5f9ea0',
        chartreuse: '7fff00',
        chocolate: 'd2691e',
        coral: 'ff7f50',
        cornflowerblue: '6495ed',
        cornsilk: 'fff8dc',
        crimson: 'dc143c',
        cyan: '00ffff',
        darkblue: '00008b',
        darkcyan: '008b8b',
        darkgoldenrod: 'b8860b',
        darkgray: 'a9a9a9',
        darkgreen: '006400',
        darkkhaki: 'bdb76b',
        darkmagenta: '8b008b',
        darkolivegreen: '556b2f',
        darkorange: 'ff8c00',
        darkorchid: '9932cc',
        darkred: '8b0000',
        darksalmon: 'e9967a',
        darkseagreen: '8fbc8f',
        darkslateblue: '483d8b',
        darkslategray: '2f4f4f',
        darkturquoise: '00ced1',
        darkviolet: '9400d3',
        deeppink: 'ff1493',
        deepskyblue: '00bfff',
        dimgray: '696969',
        dodgerblue: '1e90ff',
        firebrick: 'b22222',
        floralwhite: 'fffaf0',
        forestgreen: '228b22',
        fuchsia: 'ff00ff',
        gainsboro: 'dcdcdc',
        ghostwhite: 'f8f8ff',
        gold: 'ffd700',
        goldenrod: 'daa520',
        gray: '808080',
        green: '008000',
        greenyellow: 'adff2f',
        honeydew: 'f0fff0',
        hotpink: 'ff69b4',
        indianred: 'cd5c5c',
        indigo: '4b0082',
        ivory: 'fffff0',
        khaki: 'f0e68c',
        lavender: 'e6e6fa',
        lavenderblush: 'fff0f5',
        lawngreen: '7cfc00',
        lemonchiffon: 'fffacd',
        lightblue: 'add8e6',
        lightcoral: 'f08080',
        lightcyan: 'e0ffff',
        lightgoldenrodyellow: 'fafad2',
        lightgray: 'd3d3d3',
        lightgreen: '90ee90',
        lightpink: 'ffb6c1',
        lightsalmon: 'ffa07a',
        lightseagreen: '20b2aa',
        lightskyblue: '87cefa',
        lightslategray: '778899',
        lightsteelblue: 'b0c4de',
        lightyellow: 'ffffe0',
        lime: '00ff00',
        limegreen: '32cd32',
        linen: 'faf0e6',
        magenta: 'ff00ff',
        maroon: '800000',
        mediumaquamarine: '66cdaa',
        mediumblue: '0000cd',
        mediumorchid: 'ba55d3',
        mediumpurple: '9370db',
        mediumseagreen: '3cb371',
        mediumslateblue: '7b68ee',
        mediumspringgreen: '00fa9a',
        mediumturquoise: '48d1cc',
        mediumvioletred: 'c71585',
        midnightblue: '191970',
        mintcream: 'f5fffa',
        mistyrose: 'ffe4e1',
        moccasin: 'ffe4b5',
        navajowhite: 'ffdead',
        navy: '000080',
        oldlace: 'fdf5e6',
        olive: '808000',
        olivedrab: '6b8e23',
        orange: 'ffa500',
        orangered: 'ff4500',
        orchid: 'da70d6',
        palegoldenrod: 'eee8aa',
        palegreen: '98fb98',
        paleturquoise: 'afeeee',
        palevioletred: 'db7093',
        papayawhip: 'ffefd5',
        peachpuff: 'ffdab9',
        peru: 'cd853f',
        pink: 'ffc0cb',
        plum: 'dda0dd',
        powderblue: 'b0e0e6',
        purple: '800080',
        rebeccapurple: '663399',
        red: 'ff0000',
        rosybrown: 'bc8f8f',
        royalblue: '4169e1',
        saddlebrown: '8b4513',
        salmon: 'fa8072',
        sandybrown: 'f4a460',
        seagreen: '2e8b57',
        seashell: 'fff5ee',
        sienna: 'a0522d',
        silver: 'c0c0c0',
        skyblue: '87ceeb',
        slateblue: '6a5acd',
        slategray: '708090',
        snow: 'fffafa',
        springgreen: '00ff7f',
        steelblue: '4682b4',
        tan: 'd2b48c',
        teal: '008080',
        thistle: 'd8bfd8',
        tomato: 'ff6347',
        turquoise: '40e0d0',
        violet: 'ee82ee',
        wheat: 'f5deb3',
        white: 'ffffff',
        whitesmoke: 'f5f5f5',
        yellow: 'ffff00',
        yellowgreen: '9acd32'
    };

    return Color_Names[value] ? Color_Names[value] : '';
};