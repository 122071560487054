import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import { divideArrayInChunks } from '../../utils/array';
import { buildFormElementID, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class ImagePicker extends Component {
    renderOptions(value) {
        const { Function_Data, frm_id, Data_Groups, fld_id, El_Data, parent_disabled } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id),
            { width, height, column_amount_max, readonly } = El_Data,
            amount_max = column_amount_max ? parseInt(column_amount_max, 10) : 2;

        const OptionGroups = divideArrayInChunks(El_Data.Options, amount_max);

        let option_index = 0;

        return OptionGroups.map((Options, group_index) =>
            <div
                key={group_index}
                className="form_image_selection_row"
            >
                {Options.map((Option, index) => {
                    const url = Function_Data.base_url + Option.filename;
                    const pos = (Option.left ? (0 - Option.left) : '0') + 'px ' +
                        (Option.top ? (0 - Option.top) : '0') + 'px';
                    const style = {
                        width: width + 'px',
                        height: height + 'px',
                        backgroundImage: 'url(' + url + ')',
                        backgroundPosition: pos,
                        backgroundRepeat: 'no-repeat'
                    };
                    option_index++;
                    const isSelected = Option.value === value;

                    return (
                        <div
                            key={index}
                            id={id + '_' + option_index}
                            datavalue={Option.value}
                            className={
                                'form_image_selection_box' +
                                (isSelected ? ' selected' : '') +
                                (!parent_disabled && !readonly ? ' form_image_selection_box_is_hoverable' : '')
                            }
                            onClick={!parent_disabled && !isSelected && !readonly ? this.onClick(Option.value) : null}
                        >
                            {Option.label &&
                                <p className="form_image_selection_label">
                                    {Option.label}
                                </p>
                            }
                            <div style={style} />
                        </div>
                    );
                })}
            </div>
        );
    }

    onClick(value) {
        return () => {
            const { Data_Groups, fld_id, El_Data, changeCurrentData } = this.props;
            const New_Data = El_Data.onChange ? El_Data.onChange(value) : null,
                New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), value);
            changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
        };
    }

    render() {
        const { frm_id, Data_Groups, fld_id, Col_Settings, El_Data } = this.props,
            { value } = El_Data,
            h_align = Col_Settings.h_align,
            id = buildFormElementID(frm_id, Data_Groups, fld_id);

        const style = {};
        if (h_align && h_align !== 'center') {
            style.float = h_align;
        }

        return (
            <Fragment>
                <div
                    id={id + '_image_picker'}
                    style={style}
                >
                    <div id={id + '_options'}>
                        {this.renderOptions(value)}
                    </div>
                </div>
            </Fragment>
        );
    }
}