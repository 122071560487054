import React, { Component } from 'react';
import { buildFormElementID, buildFormElementName } from '../../utils/form';

export default class IconLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false
        };
    }

    onMouseOverButton() {
        this.setState({ isHovering: true });//Neccesary?
    }

    onMouseOutButton() {
        this.setState({ isHovering: false });//Neccesary?
    }

    onClickButton(e) {
        const { El_Data } = this.props;
        var onClick = El_Data.onClick || 0;
        onClick.call(this);
    }

    onChangeFilesHandler(e) {
        const { onChange } = this.props.El_Data;
        if (onChange) {
            onChange(e.target.files);
        }
    }

    render() {
        const { Function_Data, frm_id, Data_Groups, fld_id, El_Data, tab, parent_disabled } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id);
        const onClick = El_Data.onClick || false,
            isDisabled = parent_disabled || El_Data.disabled || false;

        let total_width = 100;
        let total_height = 16;
        if (El_Data.Style) {
            if (El_Data.Style[0]) {
                total_width = El_Data.Style[0];
            }
            if (El_Data.Style[1]) {
                total_width = El_Data.Style[1];
            }
        }

        const style3 = {
            float: 'left',
            cursor: 'pointer'
            //width: total_width + 'px',
            //height: total_height + 'px'
        };

        const style2 = {};
        if (El_Data.img_style) {
            if (El_Data.img_style[1]) {
                style2.width = El_Data.img_style[1] + 'px';
            }
            if (El_Data.img_style[2]) {
                style2.height = El_Data.img_style[2] + 'px';
            }
            if (El_Data.img_style[3] || El_Data.img_style[4]) {
                style2.backgroundPosition = '-' + El_Data.img_style[3] + 'px -' + El_Data.img_style[4] + 'px';
            }
        }
        if (El_Data.img_name) {
            style2.backgroundImage = 'url(' +
                Function_Data.base_url +
                'projects/' + Function_Data.project +
                '/img/icon/' +
                El_Data.img_name + '.png' +
                ')';
        }

        const className = 'clickable_input fld_input_invisible fld_pointer' +
            (isDisabled ? ' fld_input_disabled' : '');

        return (
            <div
                className="relative"
                style={style3}
                onMouseOver={!isDisabled ? this.onMouseOverButton.bind(this) : null}
                onMouseOut={!isDisabled ? this.onMouseOutButton.bind(this) : null}
                onClick={!isDisabled && onClick ? this.onClickButton.bind(this) : null}
            >
                {El_Data.file_upload ?
                    <input
                        type="file"
                        id={id}
                        name={buildFormElementName(Data_Groups, El_Data.name) + '[]'}
                        tabIndex={tab}
                        className={className}
                        style={{
                            width: total_width + 'px',
                            height: total_height + 'px'
                        }}
                        accept={El_Data.filetype_restriction ? El_Data.filetype_restriction : ''}
                        multiple={true}
                        onChange={!isDisabled ? this.onChangeFilesHandler.bind(this) : null}
                    /> :
                    <input
                        type="button"
                        id={id}
                        name={El_Data.name ? buildFormElementName(Data_Groups, El_Data.name, 'button') : null}
                        value=""
                        className={className}
                        style={{ width: total_width + 'px', height: total_height + 'px' }}
                        tabIndex={tab ? tab : null}
                        disabled={isDisabled}
                        onClick={onClick ? e => { e.preventDefault(); onClick(e); } : null}
                    />
                }
                <div
                    className={El_Data.img_class || 'icon_link_image'}
                    style={style2}
                />
                {El_Data.text &&
                    <p className={'icon_link_text' + (this.state.isHovering ? ' hover' : '')}>
                        {El_Data.text || ''}
                    </p>
                }
            </div>
        );
    }
}