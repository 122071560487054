import React, { Component } from 'react';
import { shuffle } from '../utils/array';
import { getViewport } from '../utils/browser';
import Element_Router from './element_router';
import layout_store from '../api/layout_store';

export default class Slideshow extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.Content = this.getContent();
        this.state = {
            step: 0,
            active_index: 0,
            windowWidth: getViewport().w
        };
        this.renderTime = 10;
        this.unsubscribes = [];
    }

    componentDidMount() {
        if (this.Content.length) {
            this.hookEvents();
        }
        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleLayoutStoreChange() {
        const { action, viewport } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.setState({ windowWidth: viewport.w });
        }
    }

    getContent() {
        const { El_Data, Content = [] } = this.props.Element;
        return (El_Data.randomize === 'true' ? shuffle(Content) : Content)
    }

    hookEvents() {
        const { timeout, speed } = this.props.Element.El_Data;

        let time = 0;
        const moveStepLimit = parseInt(speed) / this.renderTime;
        const timeoutStepLimit = parseInt(timeout) / this.renderTime;

        this.timer = setInterval(() => {
            time++;
            const { active_index, step } = this.state
            const changeSet = {};

            if (time === timeoutStepLimit) {
                changeSet.active_index = active_index === this.Content.length - 1 ? 0 : active_index + 1;
            }

            if (time >= timeoutStepLimit) {
                changeSet.step = step + 1;
            }

            if (time > timeoutStepLimit + moveStepLimit) {
                time = 0;
                changeSet.step = 0;
            }

            if (Object.keys(changeSet).length) {
                this.setState(changeSet);
            }
        }, this.renderTime);
    }

    render() {
        const {
            project, site_id, Navigation_Data, page_id, el_type, el_idx, Element,
            Site, Presets, show_tools, tab, Font_Families, use_service,
            signed_in, navigate, Corrections, user_role, requiresNewEffect, level,
            effectClass, reloadForm, updateActionData, parent_disabled
        } = this.props;

        const { El_Data, Layout } = Element;
        const totalSteps = parseInt(El_Data.speed) / this.renderTime;
        const { width_type, height_type } = Layout;

        const style = Corrections && Corrections.full_height_correction ?
            { minHeight: Corrections.full_height_correction.height } :
            null;

        return (
            <div
                id={[el_type, el_idx].join('_')}
                style={style}
            >
                { this.Content.map((SubElement, index) => {
                    //TODO: use "El_Data.effect" for other slide-effects (left, right, top, bottom, etc)
                    const isActive = this.state.active_index === index;
                    const wasJustActive = this.state.step > 0 && (
                        this.state.active_index === index + 1 ||
                        (this.state.active_index === 0 && index === this.Content.length - 1)
                    );
                    const slide_style = {
                        display: isActive || wasJustActive ? 'block' : 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 10,
                        opacity: 1
                    };

                    if (width_type === '100') {
                        slide_style.width = '100%';
                    }

                    if (height_type === '100') {
                        slide_style.height = '100%';
                    }

                    const { el_type, el_idx } = SubElement;

                    if (isActive) {
                        slide_style.zIndex = 11;
                        slide_style.left = this.state.step > 0 ?
                            slide_style.left = ((this.state.step / totalSteps) * this.state.windowWidth) - this.state.windowWidth :
                            0;
                    } else if (wasJustActive) {
                        slide_style.left = (this.state.step / totalSteps) * this.state.windowWidth;
                    }

                    return (
                        <div
                            key={index}
                            style={slide_style}
                        >
                            <div
                                id={['element', el_type, el_idx].join('_')}
                                className={['icon', 'text', 'menu', 'form'].includes(el_type) ? 'body_font_size' : null}
                            >
                                <Element_Router
                                    project={project}
                                    site_id={site_id}
                                    Navigation_Data={Navigation_Data}
                                    page_id={page_id}
                                    Element={SubElement}
                                    Site={Site}
                                    Presets={Presets}
                                    Font_Families={Font_Families}
                                    show_tools={show_tools}
                                    use_service={use_service}
                                    tab={tab}
                                    signed_in={signed_in}
                                    navigate={navigate}
                                    Corrections={Corrections}
                                    user_role={user_role}
                                    requiresNewEffect={requiresNewEffect}
                                    level={level + 1}
                                    effectClass={effectClass}
                                    reloadForm={reloadForm}
                                    updateActionData={updateActionData}
                                    parent_disabled={parent_disabled}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}