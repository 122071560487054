import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class Field extends Component {
    constructor(props) {
        super(props);
        const { El_Data, show_tools } = this.props;
        var readonly = El_Data.readonly || '',
            autocomplete = El_Data.autocomplete === 'true' ? El_Data.autocomplete : '';

        this.state = {
            hasFocus: false,
            readonlyToBlockAutocomplete: !show_tools && autocomplete === 'false' && readonly === 'false'
        };
    }

    componentDidMount() {
        if (this.state.readonlyToBlockAutocomplete) {
            /* NOTE: to prevent autocomplete, which has messy implementation in
             * browsers, often ignoring designated attribute (autocomplete="off"),
             * sometimes filling in username/password in any 2 fields where the
             * second has attribute setting: (type="password"),
             * the input is set readonly, which block browser's autocomplete,
             * then javascript makes it readable 500ms after "window load"
             */
            const that = this;
            setTimeout(() => {
                that.setState({ readonlyToBlockAutocomplete: false });
            }, 500);
        }
    }

    onBlur() {
        this.setState({ hasFocus: false });
    }

    onFocus() {
        this.setState({ hasFocus: true });
    }

    onKeyUp(e) {
        const { trigger_click_button_name } = this.props.El_Data;

        if (trigger_click_button_name && e.which === 13) {//on "enter" key
            document.getElementById('' + trigger_click_button_name).click();
        }
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, Col_Settings, tab, changeCurrentData, parent_disabled } = this.props,
            { hasFocus, readonlyToBlockAutocomplete } = this.state,
            id = buildFormElementID(frm_id, Data_Groups, fld_id),
            value = El_Data.value || (El_Data.value === 0 ? 0 : ''),
            maxlength = El_Data.max && El_Data.max === 1 ? 300 : (El_Data.max && El_Data.max === 2 ? 100 : 30),
            isReadonly = El_Data.readonly || readonlyToBlockAutocomplete || parent_disabled,
            field_type = El_Data.field_type || 'text',
            isDisabled = El_Data.disabled || parent_disabled,
            autocomplete = null,
            direction = El_Data.direction || 'hor',
            onChange = e => {
                const New_Data = El_Data.onChange ? El_Data.onChange(e.target.value) : null,
                    New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), e.target.value);
                changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
            };

        const style = {};
        if (Col_Settings.h_align === 'right') {
            style.float = 'right';
        }

        const style2 = {};

        if (El_Data.Style) {
            ['width', 'height', 'paddingRight']
                .map((prop, index) => ({ prop, value: Style[index] }))
                .filter(set => set.value && !isNaN(set.value))
                .forEach(set => {
                    style2[set.prop] = set.value + 'px';
                });
        }

        const className = 'fld_input' +
            (hasFocus ? ' fld_input_focus' : '') +
            (isReadonly ? ' fld_input_readonly' : '') +
            (isDisabled ? ' fld_input_disabled' : '');

        return (
            <div
                className={
                    'form_field_box inpt' +
                    (direction === 'ver' ? ' inpt_ver' : '')
                }
                style={style}
            >
                <input
                    type={field_type}
                    id={id}
                    name={fld_id ? buildFormElementName(Data_Groups, fld_id, field_type) : null}
                    value={value !== null ? value : ''}
                    className={className}
                    style={style2}
                    maxLength={maxlength && (field_type === 'text' || field_type === 'password') ? maxlength : null}
                    tabIndex={tab ? tab : null}
                    readOnly={isReadonly}
                    disabled={isDisabled}
                    autoComplete={autocomplete && ['text', 'password', 'number'].includes(field_type) ? 'on' : null}
                    onChange={!isReadonly && !isDisabled && onChange ? onChange : () => { }}
                    onKeyUp={!isReadonly && !isDisabled ? this.onKeyUp.bind(this) : null}
                    onBlur={!isReadonly && !isDisabled ? this.onBlur.bind(this) : null}
                    onFocus={!isReadonly && !isDisabled ? this.onFocus.bind(this) : null}
                />
            </div>
        );
    }
}