import { convertBytesToFileSizeString } from '../utils/file';
import { buildFormElementName } from '../utils/form';

export const upload = (Function_Data, frm_id, Data_Groups, id, Files, Post_Data, submit_action, max_file_uploads, upload_max_filesize, post_max_size, onUploadedEvent) => {
    var error = '';
    if (Files.length > parseInt(max_file_uploads, 10)) {
        error = 'Too many files, maximum allowed amount of files is: ' + max_file_uploads + ' !';
    }

    var total_post_size = 0;
    for (var i = 0; i < Files.length; i++) {
        total_post_size += Files[i].size;
        if (Files[i].size > upload_max_filesize) {
            error = 'File "' + Files[i].name + '" is too big, max allowed filesize is: ' + convertBytesToFileSizeString(upload_max_filesize) + '.';
        }
    }
    if (total_post_size > post_max_size) {
        error = 'Total size of the files is too big, max allowed size for one upload is: ' + convertBytesToFileSizeString(post_max_size) + '.';
    }

    if (!error) {
        new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();

            //Perhaps the progress indicators are better to make for each file separately.
            //In that case, one has to move the
            //    Array.from(Files).forEach(file => { }
            //up to a higher level. Perhaps around the upload() function
            // (to prevent problem with Current_Data changes). Or around the 
            //    new Promise((resolve, reject) => { }
            // But in that case one has to keep track of the "onreadystate".
            // Once all files around done, then run "onUploadedEvent" and provide
            // parsed responseText of each file-upload.
            req.upload.addEventListener('progress', event => {
                if (event.lengthComputable) {
                    /*const copy = { ...this.state.uploadProgress };
                    copy[file.name] = {
                        state: 'pending',
                        percentage: (event.loaded / event.total) * 100
                    };*/
                    //this.setState({ uploadProgress: copy });
                }
            });

            req.upload.addEventListener('load', event => {
                /*const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: 'done', percentage: 100 };*/
                //this.setState({ uploadProgress: copy });
                resolve(req.response);
            });

            req.upload.addEventListener('error', event => {
                /*const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: 'error', percentage: 0 };*/
                //this.setState({ uploadProgress: copy });
                reject(req.response);
            });

            const formData = new FormData();
            Array.from(Files).forEach(file => {
                formData.append(buildFormElementName(Data_Groups, id) + '[]', file, file.name);
            });
            formData.append('site_id', Function_Data.site_id);
            formData.append('frm_id', frm_id);
            formData.append('submit_action', submit_action);
            formData.append('project', Function_Data.project);
            formData.append('upload', 'true');
            Object.keys(Post_Data)
                .forEach(key => {
                    formData.append(key, Post_Data[key]);
                });

            req.open('POST', Function_Data.base_url + '$/post_upload/');
            //req.setRequestHeader('Content-Type', 'multipart/form-data');//????
            req.send(formData);
            req.onreadystatechange = () => {
                if (req.readyState == XMLHttpRequest.DONE) {
                    const Data = JSON.parse(req.responseText);
                    if (Data.error && typeof (Data.error) === 'string') {//returned by "buildError"
                        console.log(Data.error);
                    } else {
                        if (onUploadedEvent !== '' && typeof (onUploadedEvent) === 'string') {
                            eval(onUploadedEvent);
                        }
                        if (typeof (onUploadedEvent) === 'function') {
                            onUploadedEvent(Data);
                        }
                    }
                }
            };
        });
    } else {
        console.log(error);
    }
};
