import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColFieldSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            deepmerge(Load_Data, {
                Form_Col_Field: {
                    hidden: 'false',
                    non_prefixed_id: 'false',
                    name: '',
                    label: '',
                    value: '',
                    h_align: 'center',
                    responsive: 'false',
                    width_type: 'cus',
                    width: 200,
                    styling_type: 'quality',
                    placeholder: '',
                    field_type: 'text',
                    maxlength: 2,
                    required: 'false',
                    readonly: 'false',
                    autocomplete: 'false',
                    trigger_click_button_idx: '',
                },
            }) :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Field {
                    hidden: String
                    non_prefixed_id: String
                    name: String
                    label: String
                    value: String
                    h_align: String
                    responsive: String
                    width_type: String
                    width
                    styling_type: String
                    placeholder: String
                    field_type: String
                    maxlength
                    required: String
                    readonly: String
                    autocomplete: String
                    trigger_click_button_idx: String
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Field', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Field.name, max: 2 },
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Field.label, max: 2 },
                ],
                [
                    { type: 'field', id: 'value', label: 'Initial value' },
                    { value: Current_Data.Form_Col_Field.value, max: 2 },
                ],
                [
                    { type: 'dropdown', id: 'h_align', label: 'Horizontal align' },
                    {
                        value: Current_Data.Form_Col_Field.h_align,
                        Options: [
                            ['left', 'Left'],
                            ['center', 'Center'],
                            ['right', 'Right'],
                        ],
                    },
                ],
                [
                    { type: 'checkbox', id: 'responsive', label: 'Responsive' },
                    {
                        value: Current_Data.Form_Col_Field.responsive,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'dropdown', id: 'width_type', label: 'Width type' },
                    {
                        value: Current_Data.Form_Col_Field.width_type,
                        Options: [['cus', 'Custom'], ['100', 'Full']],
                    },
                ],
                [
                    {
                        type: Current_Data.Form_Col_Field.width_type === 'cus' ? 'number' : 'hidden',
                        id: 'width',
                        label: 'Width',
                    },
                    {
                        value: Current_Data.Form_Col_Field.width,
                        min_val: 20,
                        max_val: 999,
                        unit: 'px',
                    },
                ],
                [
                    { type: 'dropdown', id: 'styling_type', label: 'Size' },
                    {
                        value: Current_Data.Form_Col_Field.styling_type,
                        Options: [
                            ['small', 'Small'],
                            ['normal', 'Normal'],
                            ['quality', 'Big'],
                        ],
                    },
                ],
                [
                    { type: 'field', id: 'placeholder', label: 'Placeholder' },
                    {
                        value: Current_Data.Form_Col_Field.placeholder,
                        max: 2,
                    },
                ],
                [
                    { type: 'dropdown', id: 'field_type', label: 'Field type' },
                    {
                        value: Current_Data.Form_Col_Field.field_type,
                        Options: [['text', 'Normal'], ['password', 'Password']],
                    },
                ],
                [
                    { type: 'number', id: 'maxlength', label: 'Maximum length' },
                    {
                        value: Current_Data.Form_Col_Field.maxlength,
                        min_val: 1,
                        max_val: 100,
                    },
                ],
                [
                    { type: 'checkbox', id: 'required', label: 'Required' },
                    {
                        value: Current_Data.Form_Col_Field.required,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'checkbox', id: 'readonly', label: 'Readonly' },
                    {
                        value: Current_Data.Form_Col_Field.readonly,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'checkbox', id: 'autocomplete', label: 'Autocomplete' },
                    {
                        value: Current_Data.Form_Col_Field.autocomplete,
                        styling_type: 'switch',
                    },
                ],
                [
                    { type: 'checkbox', id: 'hidden', label: 'Initially hide' },
                    {
                        value: Current_Data.Form_Col_Field.hidden,
                        styling_type: 'switch',
                    },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_field_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save'),
        ];
    }
}
