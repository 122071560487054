import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColTextSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Text: {
                    name: '',
                    colspan: 2,
                    styling_type: 'normal',
                    text: '',
                    h_align: 'center',
                    v_align: 'middle',
                    padding_left: 0,
                    padding_right: 0,
                    padding_top: 0,
                    padding_bottom: 0
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Text {
                    name: String
                    colspan
                    styling_type: String
                    text: String
                    h_align: String
                    v_align: String
                    padding_left
                    padding_right
                    padding_top
                    padding_bottom
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Text', [
                [
                    { type: 'dropdown', id: 'h_align', label: 'Horizontal align' },
                    {
                        value: Current_Data.Form_Col_Text.h_align,
                        Options: [
                            ['left', 'Left'],
                            ['center', 'Center'],
                            ['right', 'Right']
                        ]
                    }
                ],
                [
                    { type: 'dropdown', id: 'v_align', label: 'Vertical align' },
                    {
                        value: Current_Data.Form_Col_Text.v_align,
                        Options: [
                            ['top', 'Top'],
                            ['middle', 'Center'],
                            ['bottom', 'Bottom']
                        ]
                    }
                ],
                [
                    { type: 'number', id: 'padding_left', label: 'Padding left' },
                    { value: Current_Data.Form_Col_Text.padding_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'padding_right', label: 'Padding right' },
                    { value: Current_Data.Form_Col_Text.padding_right, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'padding_top', label: 'Padding top' },
                    { value: Current_Data.Form_Col_Text.padding_top, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'padding_bottom', label: 'Padding bottom' },
                    { value: Current_Data.Form_Col_Text.padding_bottom, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Text.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Text.name, max: 2 }
                ],
                [
                    { type: 'dropdown', id: 'styling_type', label: 'Style' },
                    {
                        value: Current_Data.Form_Col_Text.styling_type,
                        Options: [
                            ['label', 'Label'],
                            ['normal', 'Normal'],
                            ['bold', 'Bold'],
                            ['subtitle', 'Subtitle'],
                            ['grey', 'Grey'],
                            ['response', 'Response'],
                            ['small', 'Small']
                        ]
                    }
                ],
                [
                    { type: 'field', id: 'text', label: 'Text' },
                    { value: Current_Data.Form_Col_Text.text, max: 2 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_text_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}