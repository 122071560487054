import deepmerge from 'deepmerge';
import form_data_store from '../../../api/form_data_store';

export default class Border {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return deepmerge(Load_Data,
            {
                Shadow: Action_Data.data_action_type === 'create' || Load_Data.Mod === 0 ? {
                    opacity: 0,
                    color: '000000',
                    h_offset: 0,
                    v_offset: 0,
                    blur: 0
                } : Load_Data.Mod
            }
        );
    }

    getQueryFragment(Action_Data) {
        return `Mod {
            opacity
            color
            h_offset
            v_offset
            blur
        }`;
    }

    getQuery(Action_Data) {
        return null;
        /*return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
            ` + this.getQueryFragment(Action_Data) + `
        }`;*/
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        const { color, opacity, blur, h_offset, v_offset } = Current_Data.Shadow;

        return [
            [
                { type: 'color', id: 'color', label: 'Color' },
                { value: color }
            ],
            [
                { type: 'number', id: 'opacity', label: 'Opacity' },
                { value: opacity, min_val: 0, max_val: 100, unit: '%' }
            ],
            [
                { type: 'number', id: 'blur', label: 'Size (blur)' },
                { value: blur, min_val: 0, max_val: 999, unit: 'px' }
            ],
            [
                { type: 'text' },
                { text: 'Offset', style: 'bold' }
            ],
            [
                { type: 'number', id: 'h_offset', label: 'Horizontal' },
                { value: h_offset, min_val: 0, max_val: 999, unit: 'px' }
            ],
            [
                { type: 'number', id: 'v_offset', label: 'Vertical' },
                { value: v_offset, min_val: 0, max_val: 999, unit: 'px' }
            ]
        ];
    }

    getSubmitEvent(frm_id, submit_action, Function_Data, Action_Data, frm_id_int, Current_Data, updateActionData) {
        return holder_type => {
            form_data_store.dispatch({
                type: 'SUBMIT',
                submitData: {
                    frm_id,
                    holder_type,
                    submit_action,
                    Function_Data,
                    process_tag: 'Saving...',
                    onSubmitted: Result_Data => {
                        //TODO
                    }
                }
            });
        };
    }
}