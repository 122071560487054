import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils.js';

export default class RowColumnSizeSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const row_column_sizes = Action_Data.el_type === 'row' ?
            Load_Data.Grid_Layout.row_heights :
            Load_Data.Grid_Layout.column_widths;
        return deepmerge(
            deepmerge(Load_Data, { Row_Column_Size: { mod_type: 'row_column_size', } }),
            {
                Row_Column_Size: Action_Data.data_action_type === 'create' || Load_Data.Mod === 0 ?
                    {
                        size_type: '100',
                        size: 1,
                        percentage: 10,
                    } :
                    Load_Data.Mod,
                row_column_sizes: row_column_sizes,
                isSizeFixed: !['specific_pixels', 'specific_percentages'].includes(row_column_sizes)
            },
        );
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            mod_idx
            Mod {
                size_type: String
                size
                percentage
            }
            Grid_Layout {
                row_heights
                column_widths
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Row_Column_Size',
                [
                    [
                        {
                            type: !Current_Data.isSizeFixed &&
                                Current_Data.row_column_sizes === 'specific_pixels' ?
                                'image_picker' : 'hidden',
                            id: 'size_type',
                            label: ' '
                        },
                        {
                            value: Current_Data.Row_Column_Size.size_type,
                            Options: [
                                {
                                    label: 'Minimize',
                                    filename: 'projects/rootflex/img/form/size_style.png',
                                    value: 'fit',
                                    left: 0,
                                    top: Action_Data.el_type === 'row' ? 60 : 0,
                                },
                                {
                                    label: 'Fixed pixel amount',
                                    filename: 'projects/rootflex/img/form/size_style.png',
                                    value: 'cus',
                                    left: 100,
                                    top: Action_Data.el_type === 'row' ? 60 : 0,
                                },
                                {
                                    label: 'Maximize',
                                    filename: 'projects/rootflex/img/form/size_style.png',
                                    value: '100',
                                    left: 200,
                                    top: Action_Data.el_type === 'row' ? 60 : 0,
                                },
                            ],
                            width: 100,
                            height: Action_Data.el_type === 'row' ? 72 : 60,
                            column_amount_max: 3,
                        },
                    ],
                    [
                        {
                            type: !Current_Data.isSizeFixed &&
                                Current_Data.row_column_sizes === 'specific_pixels' &&
                                Current_Data.Row_Column_Size.size_type === 'cus' ?
                                'number' : 'hidden',
                            id: 'size',
                            label: 'Pixels',
                        },
                        {
                            value: Current_Data.Row_Column_Size.size,
                            min_val: 1,
                            max_val: 9999,//TODO: max depends on Grid size
                            unit: 'px',
                        },
                    ],
                    [
                        {
                            type: !Current_Data.isSizeFixed &&
                                Current_Data.row_column_sizes === 'specific_percentages' ?
                                'slider' : 'hidden',
                            id: 'percentage',
                            label: 'Percentage',
                        },
                        {
                            value: Current_Data.Row_Column_Size.percentage,
                            min_val: 0, //TODO: should be linked to other rows/columns' percentages
                            max_val: 100, //TODO: should be linked to other rows/columns' percentages
                        },
                    ],
                    [
                        { type: Current_Data.isSizeFixed ? 'text' : 'hidden' },
                        { text: 'The size is fixed, due to the setting in grid size.', style: 'bold' },
                    ]
                ]
            ),
            Current_Data.isSizeFixed ? getSingleSubmitSet(frm_id,) : getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_row_column_size',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}
