import React, { Component } from 'react';
import layer_store from '../api/layer_store';
import layout_store from '../api/layout_store';
import { getOffset, getSize, getViewport } from '../utils/browser';
import {
    convertHexToHSB, convertHSBToHex, convertHSBToHSL, convertHSBToRGB,
    convertHSLToHSB, convertRGBToHSB
} from '../utils/color';

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pos: this.getPosition(getViewport())
        }

        this.color = convertHexToHSB(this.fixHex(props.value));
        this.origColor = this.copyHex(this.color);
        this.selectorMouseDown = false;
        this.hueMouseDown = false;
        this.selectorOffset = { t: 0, l: 0 };
        this.hueOffset = { t: 0, l: 0 };
        this.myRef;
        this.EventListeners = [];
        this.unsubscribes = [];
    }

    setRef(el) {
        this.myRef = el;
    }

    componentDidMount() {
        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];

        this.EventListeners = [
            [this.myRef, 'mousedown', e => e.isInsideColorPicker = true],
            [document, 'mousedown', e => {
                if (!e.isInsideColorPicker) {
                    layer_store.dispatch({
                        type: 'HIDE_COLORPICKER',
                    });
                }
            }]
        ];

        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
    }

    handleLayoutStoreChange() {
        const { action, viewport } = layout_store.getState();

        if (action === 'window_resize') {
            this.setState({
                pos: this.getPosition(viewport),
            });
        }
    }

    getPosition(viewPort) {
        const { el } = this.props;
        const picker = {
            w: 356,
            h: 176
        };
        const offset = getOffset(el);
        const size = getSize(el);

        return {
            top: offset.t + size.h + picker.h > viewPort.t + viewPort.h ?
                viewPort.t + viewPort.h - picker.h - size.h :
                offset.t + size.h,
            left: offset.l + size.w + picker.w > viewPort.l + viewPort.w ?
                viewPort.l + viewPort.w - picker.w - size.w :
                offset.l + size.w
        };
    }

    keyDown(e) {//TODO: fix
        var pressedKey = e.charCode || e.keyCode || -1;
        if ((pressedKey > 65 && pressedKey <= 90) || pressedKey === 32) {
            return false;
        }
        this.change();
    }

    changeHex(e) {
        if (e.target.value.length === 6) {
            this.color = convertHexToHSB(this.fixHex(e.target.value));
            this.change();
        }
    }

    changeRgb(type, e) {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value <= 255 && value >= 0) {
            const RGB = convertHSBToRGB(this.color);
            RGB[type] = value;

            this.color = convertRGBToHSB(RGB);
            this.change();
        }
    }

    changeHsl(type, e) {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value <= (type === 'h' ? 360 : 100) && value >= 0) {
            const HSL = convertHSBToHSL(this.color);
            HSL[type] = value;

            this.color = convertHSLToHSB(HSL);
            this.change();
        }
    }

    change() {
        const { onChange } = this.props;
        onChange(convertHSBToHex(this.color));
        this.forceUpdate();//TODO: use setState
    }

    onAddToCollection() {
        //TODO: used this, and make some sort of color-collection tooling on top of the picker
    }

    /*downIncrement(e) { // TODO: change this to somehow use it for the RGB,HSL fields to use mousescroll to change values.
        var field = $(this).parent().find('input').focus();
        var current = {
            el: $(this).parent().addClass('colorpicker_slider'),
            max: this.parentNode.className.indexOf('_hsl_h') > 0 ?
                360 :
                (this.parentNode.className.indexOf('_hsl') > 0 ? 100 : 255),
            y: e.pageY,
            field: field,
            val: parseInt(field.val(), 10)
        };
        $(document).bind('mouseup', current, this.upIncrement);
        $(document).bind('mousemove', current, this.moveIncrement);
    }

    moveIncrement(e) { // TODO: change this to somehow use it for the RGB,HSL fields to use mousescroll to change values.
        e.data.field.val(Math.max(0, Math.min(e.data.max, parseInt(e.data.val + e.pageY - e.data.y, 10))));
        this.change.apply(e.data.field.get(0), [true]);
        return false;
    }

    upIncrement(e) { // TODO: change this to somehow use it for the RGB,HSL fields to use mousescroll to change values.
        this.change.apply(e.data.field.get(0), [true]);
        e.data.el.removeClass('colorpicker_slider').find('input').focus();
        $(document).unbind('mouseup', this.upIncrement);
        $(document).unbind('mousemove', this.moveIncrement);
        return false;
    }*/

    downHue(e) {
        this.hueMouseDown = true;
        this.hueOffset = getOffset(e.currentTarget);
        this.setByHue(e);
    }

    moveHue(e) {
        if (this.hueMouseDown) {
            this.setByHue(e);
        }
    }

    setByHue(e) {
        this.color.h = parseInt(
            360 * (150 -
                Math.max(0,
                    Math.min(150,
                        (e.pageY - this.hueOffset.t)
                    )
                )
            ) / 150,
            10
        );
        this.change();
    }

    upHue() {
        this.hueMouseDown = false;
    }

    downSelector(e) {
        this.selectorMouseDown = true;
        this.selectorOffset = getOffset(e.currentTarget);
        this.setBySelector(e);
    }

    moveSelector(e) {
        if (this.selectorMouseDown) {
            this.setBySelector(e);
        }
    }

    setBySelector(e) {
        this.color.s = parseInt(100 * (Math.max(0, Math.min(150, (e.pageX - this.selectorOffset.l)))) / 150, 10);
        this.color.b = parseInt(100 * (150 - Math.max(0, Math.min(150, (e.pageY - this.selectorOffset.t)))) / 150, 10);
        this.change();
    }

    upSelector() {
        this.selectorMouseDown = false;
    }

    fixHex(hex) {
        var len = 6 - hex.length;
        if (len > 0) {
            var o = [];
            for (var i = 0; i < len; i++) {
                o.push('0');
            }
            o.push(hex);
            hex = o.join('');
        }
        return hex;
    }

    copyHex(HSB) {
        return {
            h: HSB.h,
            s: HSB.s,
            b: HSB.b
        };
    }

    restoreOriginal() {
        this.color = this.copyHex(this.origColor);
        this.change();
    }

    render() {
        const { id } = this.props;
        const { top, left } = this.state.pos;
        /*var cal = $().each(function() {
            $(this)
                .attr('unselectable', 'on')
                .addClass('unselectable')
                .find('*:not(input,textarea)')
                .attr('unselectable', 'on');
        });*/

        var RGB = convertHSBToRGB(this.color);
        var HSL = convertHSBToHSL(this.color);
        const prime_hex = convertHSBToHex({ h: this.color.h, s: 100, b: 100 });
        const hex = convertHSBToHex(this.color);
        const orighex = convertHSBToHex(this.origColor);
        const area_style = {
            left: parseInt(150 * this.color.s / 100, 10),
            top: parseInt(150 * (100 - this.color.b) / 100, 10)
        };
        const hue_style = {
            top: parseInt(150 - 150 * this.color.h / 360, 10)
        };

        return (
            <div
                className="colorpicker"
                id={id + '_colorpicker'}
                ref={this.setRef.bind(this)}
                style={{ left: left + 'px', top: top + 'px' }}
            >
                <div
                    className="colorpicker_color"
                    style={{ backgroundColor: '#' + prime_hex }}
                    onMouseDown={this.downSelector.bind(this)}
                    onMouseUp={this.upSelector.bind(this)}
                    onMouseMove={this.moveSelector.bind(this)}
                >
                    <div>
                        <div style={area_style} />
                    </div>
                </div>
                <div
                    className="colorpicker_hue"
                    onMouseDown={this.downHue.bind(this)}
                    onMouseUp={this.upHue.bind(this)}
                    onMouseMove={this.moveHue.bind(this)}
                >
                    <div />
                    <div style={hue_style} />
                </div>
                <div className="colorpicker_inputs">
                    {
                        [
                            ['rgb', 'r'],
                            ['hsl', 'h'],
                            ['rgb', 'g'],
                            ['hsl', 's'],
                            ['rgb', 'b'],
                            ['hsl', 'l']
                        ].map(set => {
                            const onChange = set[0] === 'rgb' ?
                                this.changeRgb.bind(this, set[1]) :
                                this.changeHsl.bind(this, set[1]);

                            return (
                                <div key={set[0] + set[1]} className="colorpicker_field">
                                    <div>{set[1].toUpperCase()}</div>
                                    <input
                                        type="text"
                                        maxLength="3"
                                        size="3"
                                        onKeyUp={this.keyDown.bind(this)}
                                        onChange={onChange}
                                        value={set[0] === 'rgb' ? RGB[set[1]] : parseInt(HSL[set[1]], 10)}
                                    />
                                </div>
                            );
                        })
                    }
                    <div className="colorpicker_hex colorpicker_field">
                        <div>#</div>
                        <input
                            type="text"
                            maxLength="6"
                            size="6"
                            onKeyUp={this.keyDown.bind(this)}
                            onChange={this.changeHex.bind(this)}
                            value={hex}
                        />
                    </div>
                    <div
                        className="colorpicker_new_color"
                        style={{ backgroundColor: '#' + hex }}
                    ></div>
                    <div
                        className="colorpicker_current_color"
                        style={{ backgroundColor: '#' + orighex }}
                        onClick={this.restoreOriginal.bind(this)}
                    ></div>
                </div>
            </div>
        );
    }
}