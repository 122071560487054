import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemovePreset {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return deepmerge(Load_Data, {
            switch_use_preset: 'custom',
            switch_mod_idx: Load_Data.Presets_List.length ? Load_Data.Presets_List[0].idx : 0,
            mod_idx: Action_Data.mod_idx // Action_Data?
        });
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            mod_type
            elements_amount
            Preset {
                site_id
                idx
                name
            }
            Presets_List {
                idx
                name
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', colspan: 2 },
                    {
                        text: 'You are about to delete the preset named: "' + Current_Data.Preset.name + '"',
                        style: 'bold',
                    },
                ], [
                    { type: 'text', colspan: 2 },
                    {
                        text: Current_Data.elements_amount > 0 ? (
                            Current_Data.elements_amount === 1 ?
                                'There is 1 element that is still using this preset!<br>' +
                                (Current_Data.Presets_List.length > 0 ?
                                    'Please specify how this element setting should be changed.' :
                                    'Therefore the element\'s settings will be switched to custom.'
                                ) :
                                'There are ' +
                                Current_Data.elements_amount +
                                ' elements that are still using this preset!<br>' +
                                (Current_Data.Presets_List.length > 0 ?
                                    'Please specify how these element settings should be changed.' :
                                    'Therefore the elements\'s settings will be switched to custom.'
                                )) :
                            'This preset isn\'t used by any elements,<br>thereby this preset can be safely deleted.',
                        style: 'normal_grey',
                    },
                ],
                [
                    {
                        type: Current_Data.elements_amount > 0 &&
                            Current_Data.Presets_List.length > 0 ?
                            'image_picker' : 'hidden',
                        id: 'switch_use_preset',
                        colspan: 2
                    },
                    {
                        value: Current_Data.switch_use_preset,
                        Options: [
                            {
                                label: 'Custom',
                                filename: 'projects/rootflex/img/form/custom_preset_all.png',
                                value: 'custom',
                                left: 0,
                                top: 0,
                            },
                            {
                                label: 'Presets',
                                filename: 'projects/rootflex/img/form/custom_preset_all.png',
                                value: 'preset',
                                left: 41,
                                top: 0,
                            },
                        ],
                        width: 40,
                        height: 32,
                    },
                ],
                [
                    {
                        type: Current_Data.elements_amount > 0 &&
                            Current_Data.Presets_List.length > 0 &&
                            Current_Data.switch_use_preset === 'preset' ?
                            'dropdown' : 'hidden',
                        id: 'switch_mod_idx',
                    },
                    {
                        value: Current_Data.switch_mod_idx,
                        Options: Current_Data.Presets_List.map(Preset => [
                            Preset.idx,
                            Preset.name,
                        ]),
                    },
                ],
                [
                    { type: 'text', colspan: 2, h_align: 'center' },
                    { text: 'Are you sure?', style: 'bold' },
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'remove_preset',
                        Function_Data,
                        process_tag: 'Removing...',
                        onSubmitted: Result_Data => {
                            //TODO: keep all presets in a store... on this remove action, remove the preset from the store.
                        }
                    }
                });
            }, 'Delete'),
        ];
    }
}
