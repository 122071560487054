import React, { Component } from 'react';
import tools_store from '../api/tools_store';
import { buildClickToolsAction } from './tools_events';

export class Tools_Action_Simple extends Component {
    render() {
        const { Function_Data, id, action, label, color, icon } = this.props;

        return (
            <div
                id={'action_' + id}//remove after refactoring element_events.js
                title={label}
                className="main_action_holder"
                onClick={() => buildClickToolsAction(Function_Data, action)}
            >
                <div
                    id={'button_' + id}//remove after refactoring element_events.js
                    className={'main_action ' + color}
                >
                    <div className={'main_action_icon ' + icon}></div>
                </div>
            </div>
        );
    }
}

export class Tools_Action_Paneled extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
            isPanelVisible: false
        };
        this.timer = null;
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [tools_store.subscribe(this.handleStoreChange.bind(this))];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    handleStoreChange() {
        const { id } = this.props;
        const tools_state = tools_store.getState();

        if (
            tools_state.action === 'close_all_tool_action_panels' &&
            tools_state.active_tool_action_panel_id !== id &&
            this.state.isPanelVisible
        ) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.setState({
                isHovering: false,
                isPanelVisible: false
            });
        }
    }

    buildToolsActionPanelButton(Button) {
        const { Function_Data, id, given_style, color } = this.props;
        const group_id = id + '_action_group';
        const action = Button[0] || '';
        const tag = Button[1] || '';
        const icon_type = Button[2] || '';

        return (
            <div
                key={group_id + '_' + action}
                id={'main_action_button_holder_' + group_id + '_' + action}//remove after refactoring element_events.js
                className={'main_action_button_holder ' + given_style}
            >
                <div
                    id={'button_' + group_id + '_' + action}//remove after refactoring element_events.js
                    className={'main_action_button ' + color + ' ' + given_style}
                    onClick={() => buildClickToolsAction(Function_Data, action)}
                >
                    <div className={'main_action_button_icon ' + icon_type}></div>
                    <div className="relativeleft">
                        <p
                            id={'label_' + group_id + '_' + action}//remove after refactoring element_events.js
                            className={'main_action_button_label ' + color}
                        >
                            {tag}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    onMouseEnter() {
        const { id } = this.props;
        if (this.timer) {
            clearTimeout(this.timer);
        }
        //$('.main_action_panel').hide();
        tools_store.dispatch({
            type: 'CLOSE_ALL_TOOL_ACTION_PANELS',
            group_id: id
        });
        this.setState({
            isHovering: true,
            isPanelVisible: true
        });
    }

    onMouseLeave() {
        this.setState({ isHovering: false });
        this.timer = setTimeout(() => {
            this.setState({
                isPanelVisible: false
            });
        }, 500);
    }

    render() {
        const { id, given_style, color, icon, Buttons } = this.props;
        const group_id = id + '_action_group';

        return (
            <div
                id={'action_holder_' + group_id}//remove after refactoring element_events.js
                className={'main_action_holder' + (this.state.isHovering ? ' hover' : '')}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
            >
                <div className="relativeleft">
                    {this.state.isPanelVisible &&
                        <div
                            id={'main_action_panel_' + group_id}//remove after refactoring element_events.js
                            className={'main_action_panel ' + color}
                        >
                            <div className={'main_action_panel_actions ' + given_style}>
                                {Buttons.map(Button => this.buildToolsActionPanelButton(Button))}
                            </div>
                        </div>
                    }
                </div>
                <div
                    id={'button_' + group_id}//remove after refactoring element_events.js
                    className={'main_action ' + color + (this.state.isHovering ? ' hover' : '')}
                >
                    <div className={'main_action_icon ' + icon}></div>
                </div>
            </div>
        );
    }
}