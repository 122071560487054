import deepmerge from 'deepmerge';
import form_data_store from '../../../api/form_data_store';

export default class Border {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return deepmerge(
            Load_Data,
            {
                Border: Action_Data.data_action_type === 'create' || Load_Data.Mod === 0 ?
                    {
                        symmetric: 'true',
                        size_all: 0,
                        size_left: 0,
                        size_right: 0,
                        size_top: 0,
                        size_bottom: 0,
                        color_all: 'ffffff',
                        color_left: 'ffffff',
                        color_right: 'ffffff',
                        color_top: 'ffffff',
                        color_bottom: 'ffffff',
                        style_all: 'solid',
                        style_left: 'solid',
                        style_right: 'solid',
                        style_top: 'solid',
                        style_bottom: 'solid'
                    } :
                    Load_Data.Mod
            }
        );
    }

    getQueryFragment(Action_Data) {
        return `Mod {
            symmetric
            size_all
            size_left
            size_right
            size_top
            size_bottom
            color_all
            color_left
            color_right
            color_top
            color_bottom
            style_all
            style_left
            style_right
            style_top
            style_bottom
        }`;
    }

    getQuery(Action_Data) {
        return null;
        /*return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
            ` + this.getQueryFragment(Action_Data) + `
            }`;*/
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        const {
            symmetric,
            color_all, style_all, size_all,
            color_top, style_top, size_top,
            color_left, style_left, size_left,
            color_right, style_right, size_right,
            color_bottom, style_bottom, size_bottom
        } = Current_Data.Border;

        return [
            [
                { type: 'text' },
                { text: 'Style the borders similar on all sides or specific for each?', style: 'bold' }
            ],
            [
                { type: 'image_picker', id: 'symmetric', colspan: 2 },
                {
                    value: symmetric,
                    Options: [
                        { label: 'Similar', filename: 'projects/rootflex/img/form/border_symmetry.png', value: 'true', left: 0, top: 0 }, //Similar
                        { label: 'Specific', filename: 'projects/rootflex/img/form/border_symmetry.png', value: 'false', left: 57, top: 0 }//Dissimilar
                    ],
                    width: 56,
                    height: 40,
                    onChange: value => value === 'false' ? {
                        Border: {
                            color_top: color_all,
                            color_left: color_all,
                            color_right: color_all,
                            color_bottom: color_all,
                            style_top: style_all,
                            style_left: style_all,
                            style_right: style_all,
                            style_bottom: style_all,
                            size_top: size_all,
                            size_left: size_all,
                            size_right: size_all,
                            size_bottom: size_all,
                        }
                    } : {}
                }
            ],
            [
                { type: 'space', colspan: 2, height: 23 }
            ],
            [
                { type: 'grid' },
                {
                    Content: [
                        '', [
                            [
                                { type: 'space', colspan: 1, height: 35 }
                            ],
                            [
                                { type: 'text' },
                                { text: 'Color', style: 'bold' }
                            ],
                            [
                                { type: 'text' },
                                { text: 'Style', style: 'bold' }
                            ],
                            [
                                { type: 'text' },
                                { text: 'Size', style: 'bold' }
                            ]
                        ],
                        'all', symmetric === 'true' ? [
                            [
                                { type: 'color', id: 'color_all', label: ' ' },
                                {
                                    value: color_all,
                                    onChange: value => ({
                                        Border: {
                                            color_top: value,
                                            color_left: value,
                                            color_right: value,
                                            color_bottom: value,
                                        }
                                    })
                                }
                            ],
                            [
                                { type: 'dropdown', id: 'style_all' },
                                {
                                    value: style_all,
                                    Options: [
                                        ['solid', 'Solid'],
                                        ['dotted', 'Dotted'],
                                        ['dashed', 'Dashed'],
                                        ['double', 'Double'],
                                        ['groove', 'Groove'],
                                        ['ridge', 'Ridge'],
                                        ['inset', 'Inset'],
                                        ['outset', 'Outset']
                                    ],
                                    margin_top: 2,
                                    width: 70,
                                    onChange: value => ({
                                        Border: {
                                            style_top: value,
                                            style_left: value,
                                            style_right: value,
                                            style_bottom: value,
                                        }
                                    })
                                }
                            ],
                            [
                                { type: 'number', id: 'size_all' },
                                {
                                    value: size_all, min_val: 0, max_val: 999, unit: 'px',
                                    onChange: value => ({
                                        Border: {
                                            size_top: value,
                                            size_left: value,
                                            size_right: value,
                                            size_bottom: value,
                                        }
                                    })
                                }
                            ]
                        ] : [],
                        'top', symmetric === 'false' ? [
                            [
                                { type: 'color', id: 'color_top', label: 'Top' },
                                { value: color_top }
                            ],
                            [
                                { type: 'dropdown', id: 'style_top' },
                                {
                                    value: style_top,
                                    Options: [
                                        ['solid', 'Solid'],
                                        ['dotted', 'Dotted'],
                                        ['dashed', 'Dashed'],
                                        ['double', 'Double'],
                                        ['groove', 'Groove'],
                                        ['ridge', 'Ridge'],
                                        ['inset', 'Inset'],
                                        ['outset', 'Outset']
                                    ],
                                    margin_top: 2,
                                    width: 70
                                }
                            ],
                            [
                                { type: 'number', id: 'size_top' },
                                { value: size_top, min_val: 0, max_val: 999, unit: 'px' }
                            ]
                        ] : [],
                        'left', symmetric === 'false' ? [
                            [
                                { type: 'color', id: 'color_left', label: 'Left' },
                                { value: color_left }
                            ],
                            [
                                { type: 'dropdown', id: 'style_left' },
                                {
                                    value: style_left,
                                    Options: [
                                        ['solid', 'Solid'],
                                        ['dotted', 'Dotted'],
                                        ['dashed', 'Dashed'],
                                        ['double', 'Double'],
                                        ['groove', 'Groove'],
                                        ['ridge', 'Ridge'],
                                        ['inset', 'Inset'],
                                        ['outset', 'Outset']
                                    ],
                                    margin_top: 2,
                                    width: 70
                                }
                            ],
                            [
                                { type: 'number', id: 'size_left' },
                                { value: size_left, min_val: 0, max_val: 999, unit: 'px' }
                            ]
                        ] : [],
                        'right', symmetric === 'false' ? [
                            [
                                { type: 'color', id: 'color_right', label: 'Right' },
                                { value: color_right }
                            ],
                            [
                                { type: 'dropdown', id: 'style_right' },
                                {
                                    value: style_right,
                                    Options: [
                                        ['solid', 'Solid'],
                                        ['dotted', 'Dotted'],
                                        ['dashed', 'Dashed'],
                                        ['double', 'Double'],
                                        ['groove', 'Groove'],
                                        ['ridge', 'Ridge'],
                                        ['inset', 'Inset'],
                                        ['outset', 'Outset']
                                    ],
                                    margin_top: 2,
                                    width: 70
                                }
                            ],
                            [
                                { type: 'number', id: 'size_right' },
                                { value: size_right, min_val: 0, max_val: 999, unit: 'px' }
                            ]
                        ] : [],
                        'bottom', symmetric === 'false' ? [
                            [
                                { type: 'color', id: 'color_bottom', label: 'Bottom' },
                                { value: color_bottom }
                            ],
                            [
                                { type: 'dropdown', id: 'style_bottom' },
                                {
                                    value: style_bottom,
                                    Options: [
                                        ['solid', 'Solid'],
                                        ['dotted', 'Dotted'],
                                        ['dashed', 'Dashed'],
                                        ['double', 'Double'],
                                        ['groove', 'Groove'],
                                        ['ridge', 'Ridge'],
                                        ['inset', 'Inset'],
                                        ['outset', 'Outset']
                                    ],
                                    margin_top: 2,
                                    width: 70
                                }
                            ],
                            [
                                { type: 'number', id: 'size_bottom' },
                                { value: size_bottom, min_val: 0, max_val: 999, unit: 'px' }
                            ]
                        ] : []
                    ]
                }
            ]
        ];
    }

    getSubmitEvent(frm_id, submit_action, Function_Data, Action_Data, frm_id_int, Current_Data, updateActionData) {
        return holder_type => {
            form_data_store.dispatch({
                type: 'SUBMIT',
                submitData: {
                    frm_id,
                    holder_type,
                    submit_action,
                    Function_Data,
                    process_tag: 'Saving...',
                    onSubmitted: Result_Data => {
                        //TODO
                    }
                }
            });
        };
    }
}