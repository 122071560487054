import React, { Component } from 'react';
import layout_store from '../api/layout_store';
import { getViewport } from '../utils/browser';
import { buildEvents } from '../utils/events';
import { buildVideoPathFile } from '../utils/path';

export default class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: getViewport()
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        const { width_type } = this.props.Element.Layout;

        if (width_type === '100'/* && height_type === 'fit'*/) {
            this.unsubscribes = [
                layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
            ];
        }
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleLayoutStoreChange() {
        const { action, viewport } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.setState({ viewport: viewport });
        }
    }

    renderVideo(Events) {
        const { project, site_id, el_type, el_idx, Element, use_service, signed_in, Corrections } = this.props;
        const style = { overflow: 'hidden', pointerEvents: 'all' };
        if (Corrections && Corrections.full_height_correction) {
            style.minHeight = Corrections.full_height_correction.height;
        }
        const { El_Data, Layout } = Element;
        const { resource_id, autoplay, controls, loop, sound, preload, Origin_Data } = El_Data;
        const src = buildVideoPathFile(project, site_id, use_service, false, true);
        const { width, height } = Origin_Data;

        //TODO: improve this, now the size is always based on the window.
        //Should also be possible to base on any holder-element.
        //Only if holder-element=root should be based on window.
        const video_style = Layout.width_type === '100'/* && height_type === 'fit'*/ ?
            (
                this.viewport.w / this.viewport.h > width / height ?
                    {
                        width: this.viewport.w + 'px',
                        marginLeft: 0,
                        marginTop: (-1 * ((this.viewport.w / (width / height)) - this.viewport.h) / 2) + 'px'
                    } :
                    {
                        width: ((width / height) * this.viewport.h) + 'px',
                        marginLeft: (-1 * (((width / height) * this.viewport.h) - this.viewport.w) / 2) + 'px',
                        marginTop: 0
                    }
            ) :
            {
                width: Layout.width_type === 'cus' ? (
                    Layout.hor_amount_type === 'px' ?
                        Layout.width_pixel + 'px' :
                        Layout.width_percentage + '%'
                ) : undefined
            };

        return (
            <div
                style={style}
                title={!Events.href && Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={!Events.href && Events.onClick && !signed_in ? Events.onClick : null}
            >
                <video
                    id={[el_type, el_idx].join('_')}
                    style={video_style}
                    src={src + resource_id + '.mp4'}
                    autoPlay={!signed_in && autoplay === 'true' ? 'true' : null}
                    controls={controls === 'true' ? 'true' : null}
                    loop={loop === 'true' ? 'true' : null}
                    muted={sound === 'false' ? 'true' : null}
                    preload={preload === 'false' ? 'none' : null}
                    poster={Origin_Data.has_placeholder_image === 'true' ?
                        src + 'placeholder/' + resource_id + '.jpg' :
                        null
                    }
                />
            </div>
        );
    }

    render() {
        const { Navigation_Data, Element, signed_in, navigate } = this.props;
        const Events = buildEvents(Element.Behavior, Navigation_Data, navigate);

        return Events.href ?
            <a
                href={Events.href}
                target={Events.new_tab ? '_blank' : ''}
                title={Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={signed_in ? e => e.preventDefault() : (
                    Events.onClick ? e => { e.preventDefault(); Events.onClick(); } : null
                )}
            >
                {this.renderVideo(Events)}
            </a> :
            this.renderVideo(Events);
    }
}