import React, { Component } from 'react';
import { Tools_Action_Paneled } from './tools_action';

export default class View_Tools extends Component {
    handleChange(event) {
        const { Function_Data, navigate } = this.props;
        navigate(
            Function_Data.default_language_type,
            Function_Data.language_type,
            event.target.value,
            Function_Data.base_url,
            Function_Data.site_url,
            Function_Data.Page_URLs,
        );
    }

    render() {
        const { Function_Data, Devices, tab } = this.props;

        const Buttons = [
            ['manage_devices', 'Devices', 'devices'],
            ['manage_resolutions', 'Resolutions', 'resolutions'],
            ['manage_scroll_zones', 'Scroll Zones', 'scroll_zones']
        ];

        return (
            <div className="tools_dropdown_container_holder">
                <div className="main_actions">
                    <Tools_Action_Paneled
                        given_style="button_list"
                        Function_Data={Function_Data}
                        id="view_action_group"
                        icon="view"
                        color="black"
                        Buttons={Buttons}
                    />
                </div>
                <div className="tools_dropdown_container">
                    <div className="styled_select_holder black tools_dropdown_selector_holder">
                        <div>
                            <select
                                id="open_device_select"
                                name="open_device_select"
                                tabIndex={tab}
                                className="tools_dropdown_selector"
                                value={Function_Data.device_type}
                                onChange={(e) => this.handleChange(e)}
                            >
                                {Devices.map(Device => {
                                    return (
                                        <option
                                            key={Device.type}
                                            className="tools_dropdown_selector_option"
                                            value={Device.type}
                                        >
                                            {Device.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}