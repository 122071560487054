import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFocus: false
        };
    }

    handleBlur() {
        this.setState({ hasFocus: false });
    }

    handleFocus() {
        this.setState({ hasFocus: true });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, Col_Settings, tab, changeCurrentData, parent_disabled } = this.props,
            { h_align } = Col_Settings,
            { Options, value, disabled, margin_top, width, width_type, size, onChange } = El_Data,
            onChangeHandler = (e) => {
                const New_Data = onChange ? onChange(e.target.value) : null,
                    New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), e.target.value);
                changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
            };

        const OptionsFiltered = Options ? Options.filter(Option =>
            typeof (Option[0]) !== 'undefined' && Option[0] !== '' && typeof (Option[1]) !== 'undefined'
        ) : [];
        const selectstyle = {
            width: width_type === 'cus' && width ? width + 'px' : (width_type === '100' ? '100%' : undefined)
        };

        return (
            <div
                className={size === 'quality' ? 'q_fld_holder' : 'form_field_box slct'}
                style={{
                    marginTop: margin_top || 2 + 'px',
                    float: h_align && h_align !== 'center' ? h_align : undefined,
                    width: width_type === '100' ? '100%' : undefined
                }}
            >
                <select
                    id={buildFormElementID(frm_id, Data_Groups, fld_id)}
                    name={buildFormElementName(Data_Groups, fld_id)}
                    className={
                        (size === 'quality' ? ' q_fld' : 'fld_select') +
                        (this.state.hasFocus ? ' ' + (size === 'quality' ? 'q_fld-focus' : 'fld_select_focus') : '')
                    }
                    style={Object.keys(selectstyle).length > 0 ? selectstyle : null}
                    tabIndex={tab}
                    disabled={disabled || parent_disabled ? 'disabled' : null}
                    value={value || 0}
                    onChange={onChangeHandler}
                    onBlur={this.handleBlur.bind(this)}
                    onFocus={this.handleFocus.bind(this)}
                >
                    {OptionsFiltered.length ?
                        OptionsFiltered.map((Option, index) =>
                            <option
                                key={index}
                                value={Option[0] || ''}
                            >
                                {Option[1]}
                            </option>
                        ) :
                        <option value="">-</option>
                    }
                </select>
            </div>
        );
    }
}