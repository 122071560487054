import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class ScopeSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data, Function_Data) {
        const {
            Restricting_Holder_Device_Page_Scope, nearest_resolution_scope_id, nearest_scroll_zone_id,
            Device_Page_Scope, resolution_scope_id, scroll_zone_id, inherit_scope, Layout, Scope_Groups_Options
        } = Load_Data;

        const Active_Scope = inherit_scope === 'true' ? Restricting_Holder_Device_Page_Scope : Device_Page_Scope;
        const isNextFormRequired = Action_Data.data_action_type === 'create' &&
            (
                (Action_Data.el_type !== 'row' && Action_Data.el_type !== 'column') ||
                (Action_Data.el_type === 'column' &&
                    ['specific_pixels', 'specific_percentages'].includes(Load_Data.Layout.column_widths)
                ) ||
                (Action_Data.el_type === 'row' &&
                    ['specific_pixels', 'specific_percentages'].includes(Load_Data.Layout.row_heights)
                )
            );

        return deepmerge(Load_Data, {
            inherit_scope: undefined,
            resolution_scope_id: undefined,
            scroll_zone_id: undefined,
            nearest_resolution_scope_id: undefined,
            nearest_scroll_zone_id: undefined,
            isNextFormRequired: isNextFormRequired,
            Scope_Tools: { inherit_scope: inherit_scope, },
            Old_Device_Page_Scope: deepmerge(Device_Page_Scope, {
                resolution_scope_id: resolution_scope_id || 0,
                scroll_zone_id: scroll_zone_id || 0,
                scope_type: Device_Page_Scope.index_scope_page_id !== null ? 'page' :
                    (Device_Page_Scope.index_scope_pages_group_id !== null ? 'group' : 'site')
            }),
            Restricting_Holder_Device_Page_Scope: deepmerge(Restricting_Holder_Device_Page_Scope, {
                resolution_scope_id: nearest_resolution_scope_id || 0,
                scroll_zone_id: nearest_scroll_zone_id || 0,
                scope_type: Restricting_Holder_Device_Page_Scope.index_scope_page_id !== null ? 'page' :
                    (Restricting_Holder_Device_Page_Scope.index_scope_pages_group_id !== null ? 'group' : 'site')
            }),
            Row_Column_Size: Action_Data.data_action_type === 'create' && (
                (Action_Data.el_type === 'column' && Layout.column_widths === 'minimal') ||
                (Action_Data.el_type === 'row' && Layout.row_heights === 'minimal')
            ) ? { size_type: 'fit', size: '0', percentage: '0' } : undefined,
            Device_Page_Scope: {
                resolution_scope_id: inherit_scope === 'true' ? (nearest_resolution_scope_id || 0) : (resolution_scope_id || 0),
                scroll_zone_id: inherit_scope === 'true' ? (nearest_scroll_zone_id || 0) : (scroll_zone_id || 0),
                scope_groups: Active_Scope.index_scope_pages_group_id !== null ? Active_Scope.index_scope_pages_group_id :
                    (Scope_Groups_Options[0] ? Scope_Groups_Options[0][0] : 0),
                scope_id: Active_Scope.index_scope_page_id !== null ? Active_Scope.index_scope_page_id : Function_Data.page_id,
                scope_type: inherit_scope === 'true' ?
                    (Restricting_Holder_Device_Page_Scope.index_scope_page_id !== null ? 'page' :
                        (Restricting_Holder_Device_Page_Scope.index_scope_pages_group_id !== null ? 'group' : 'site')
                    ) :
                    (Device_Page_Scope.index_scope_page_id !== null ? 'page' :
                        (Device_Page_Scope.index_scope_pages_group_id !== null ? 'group' : 'site')
                    ),
                mobile: Restricting_Holder_Device_Page_Scope.mobile === 'false' ? 'false' : Active_Scope.mobile,
                tablet: Restricting_Holder_Device_Page_Scope.tablet === 'false' ? 'false' : Active_Scope.tablet,
                desktop: Restricting_Holder_Device_Page_Scope.desktop === 'false' ? 'false' : Active_Scope.desktop
            }
        });
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            holder_el_type
            inherit_scope
            scroll_zone_id
            Scroll_Zone_Options
            resolution_scope_id
            Resolution_Options
            nearest_resolution_scope_id
            nearest_scroll_zone_id
            Scope_Groups_Options
            Device_Page_Scope {
                index_scope_page_id
                index_scope_pages_group_id
                mobile
                tablet
                desktop
            }
            Restricting_Holder_Device_Page_Scope {
                index_scope_page_id
                index_scope_pages_group_id
                mobile
                tablet
                desktop
            }
            Layout {
                column_widths
                row_heights
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', id: 'Scope_Tools' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10,
                    },
                    Content: [
                        [{ type: 'text' }, { text: 'Display Scope', style: 'subtitle' }],
                        [
                            {
                                type: Current_Data.holder_el_type === 'root' ? 'hidden' : 'checkbox',
                                id: 'inherit_scope',
                                label: 'Inherit scope from holder',
                            },
                            {
                                value: Current_Data.Scope_Tools.inherit_scope,
                                styling_type: 'switch',
                                onClick: value => {
                                    const Active_Scope = value === 'true' ? Current_Data.Restricting_Holder_Device_Page_Scope : Current_Data.Old_Device_Page_Scope;

                                    return {
                                        scope_groups: Active_Scope.index_scope_pages_group_id !== null ? Active_Scope.index_scope_pages_group_id :
                                            (Current_Data.Scope_Groups_Options[0] ? Current_Data.Scope_Groups_Options[0][0] : 0),
                                        scope_id: Active_Scope.index_scope_page_id !== null ? Active_Scope.index_scope_page_id : Function_Data.page_id,
                                        scope_type: Active_Scope.scope_type,
                                        resolution_scope_id: Active_Scope.resolution_scope_id,
                                        scroll_zone_id: Active_Scope.scroll_zone_id,
                                        mobile: Current_Data.Restricting_Holder_Device_Page_Scope.mobile === 'false' ? 'false' : Active_Scope.mobile,
                                        tablet: Current_Data.Restricting_Holder_Device_Page_Scope.tablet === 'false' ? 'false' : Active_Scope.tablet,
                                        desktop: Current_Data.Restricting_Holder_Device_Page_Scope.desktop === 'false' ? 'false' : Active_Scope.desktop
                                    };
                                }
                            },
                        ],
                        //TODO: show element from which the settings are inherit,
                        //provide a button which leads to a panel with the settings of that "holding element"
                    ],
                },
            ],
            wrapSectionSubs('Device_Page_Scope',
                [
                    [
                        { type: 'dropdown', id: 'scope_type', label: 'Show on pages' },
                        {
                            value: Current_Data.Device_Page_Scope.scope_type,
                            Options: Current_Data.Scope_Options
                        },
                    ],
                    [
                        {
                            type: Current_Data.Device_Page_Scope.scope_type === 'group' ? 'dropdown' : 'hidden',
                            id: 'scope_groups',
                            label: ' ',
                        },
                        {
                            value: Current_Data.Device_Page_Scope.scope_groups,
                            Options: Current_Data.Scope_Groups_Options,
                        },
                    ],
                    [
                        { type: 'checkbox', id: 'mobile', label: 'Mobile' },
                        {
                            value: Current_Data.Device_Page_Scope.mobile,
                            styling_type: 'switch',
                            readonly: Current_Data.Restricting_Holder_Device_Page_Scope.mobile === 'false' ||
                                (
                                    Current_Data.Old_Device_Page_Scope.tablet === 'false' &&
                                    Current_Data.Old_Device_Page_Scope.desktop === 'false'
                                )
                        },
                    ],
                    [
                        { type: 'checkbox', id: 'tablet', label: 'Tablet' },
                        {
                            value: Current_Data.Device_Page_Scope.tablet,
                            styling_type: 'switch',
                            readonly: Current_Data.Restricting_Holder_Device_Page_Scope.tablet === 'false' || (
                                Current_Data.Old_Device_Page_Scope.mobile === 'false' &&
                                Current_Data.Old_Device_Page_Scope.desktop === 'false'
                            ),
                        },
                    ],
                    [
                        { type: 'checkbox', id: 'desktop', label: 'Desktop' },
                        {
                            value: Current_Data.Device_Page_Scope.desktop,
                            styling_type: 'switch',
                            readonly: Current_Data.Restricting_Holder_Device_Page_Scope.desktop === 'false' ||
                                (
                                    Current_Data.Old_Device_Page_Scope.mobile === 'false' &&
                                    Current_Data.Old_Device_Page_Scope.tablet === 'false'
                                ),
                        },
                    ],
                    [{ type: 'line', id: 'separator', colspan: 2 }, {}],
                    [
                        {
                            type: Current_Data.Resolution_Options.length ? 'dropdown' : 'hidden',
                            id: 'resolution_scope_id',
                            label: 'Resolutions',
                        },
                        {
                            value: Current_Data.Device_Page_Scope.resolution_scope_id,
                            Options: Current_Data.Resolution_Options,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Scroll_Zone_Options.length ? 'dropdown' : 'hidden',
                            id: 'scroll_zone_id',
                            label: 'Scroll zone'
                        },
                        {
                            value: Current_Data.Device_Page_Scope.scroll_zone_id,
                            Options: Current_Data.Scroll_Zone_Options,
                        },
                    ],
                ],
                {},
                Current_Data.Scope_Tools.inherit_scope === 'true',
                'You cannot edit the scope because it\'s set to inherit the settings\nfrom its holding element(-s).'
            ),
            getDoubleSubmitSet(frm_id, () => {
                if (Current_Data.isNextFormRequired) {
                    const Next_Forms = {
                        grid: 'grid_settings',
                        ground: 'background_settings',
                        box: 'behavior_settings',
                        image: 'behavior_settings',
                        icon: 'behavior_settings',
                        video: 'behavior_settings',
                        row: 'row_column_size_settings',
                        column: 'row_column_size_settings'
                    };

                    updateActionData({
                        frm_name: Next_Forms[Action_Data.el_type] || Action_Data.el_type + '_settings',
                        tools_type: Action_Data.el_type === 'ground' ? 'extended' : 'simple'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_scope',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Current_Data.isNextFormRequired ? 'Next' :
                (Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
            ),
        ];
    }
}
