import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColImageTextSelectSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const Current_Data = Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Image_Text_Select: {
                    value: '',
                    name: '',
                    colspan: 2,
                    label: '',
                    column_amount_max: 3,
                    width: 30,
                    height: 30,
                    required: 'false',
                    Options: [{
                        value: '',
                        image_origin_id: '',
                        filetype: '',
                        label: ''
                    }]
                }
            } :
            clone(Load_Data);

        return Current_Data;
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Image_Text_Select {
                    value: String
                    name: String
                    colspan
                    label: String
                    column_amount_max
                    width
                    height
                    required: String
                    Options {
                        value: String
                        image_origin_id
                        filetype: String
                        label: String
                    }
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Image_Text_Select', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Image_Text_Select.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Image_Text_Select.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Image_Text_Select.label, max: 2 }
                ],
                [
                    { type: 'number', id: 'column_amount_max', label: 'Column amount max' },
                    { value: Current_Data.Form_Col_Image_Text_Select.column_amount_max, min_val: 1, max_val: 99 }
                ],
                [
                    { type: 'number', id: 'width', label: 'Width' },
                    { value: Current_Data.Form_Col_Image_Text_Select.width, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'height', label: 'Height' },
                    { value: Current_Data.Form_Col_Image_Text_Select.height, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'checkbox', id: 'required', label: 'Required' },
                    { value: Current_Data.Form_Col_Image_Text_Select.required, styling_type: 'switch' }
                ],
                [
                    { type: 'text' },
                    { text: 'Options', style: 'subtitle' }
                ],
                [
                    { type: 'selector_options_management', id: 'options', colspan: 2 },
                    {
                        Options: Current_Data.Form_Col_Image_Text_Select.Options,
                        value: Current_Data.Form_Col_Image_Text_Select.value
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Current_Data.Form_Col_Image_Text_Select.Options.length < 1) {
                    alert('Cannot proceed, because no options were created.');
                } else if (Current_Data.Form_Col_Image_Text_Select.value === '') {
                    alert('Cannot proceed, because no option was selected.');
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_image_text_select_settings',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}