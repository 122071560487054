import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class LayoutSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const
            isSingular = ['root', 'box'].includes(Load_Data.holder_el_type) &&
                parseInt(Load_Data.holder_sub_amount, 10) < 2,
            isSingularFitWidth = isSingular && Load_Data.holder_width_type === 'fit',
            isSingularFitHeight = isSingular && Load_Data.holder_height_type === 'fit';

        let Current_Data = deepmerge(Load_Data, {
            mod_type: 'layout',
            mod_idx: Load_Data.mod_idx || 0,
            total_width: Load_Data.total_width || 0,
            total_height: Load_Data.total_height || 0,
            isSingularFitWidth: isSingular && Load_Data.holder_width_type === 'fit',
            isSingularFitHeight: isSingular && Load_Data.holder_height_type === 'fit',
            Layout: Action_Data.data_action_type === 'create' || !Load_Data.Mod ? {
                aspect_ratio: 'true',
                hor_amount_type: 'px',
                ver_amount_type: 'px',
                vertical_percentages_bound_to_width: 'true',
                scroll: 'true',
                width_type: '100',
                width_pixel: 100,
                width_percentage: 10,
                height_type: '100',
                height_pixel: 100,
                height_percentage: 10,
                column_widths: 'equal_division',
                column_width: 50,
                row_heights: 'equal_division',
                row_height: 20,
                space_outside_hor_pixel_1: 0,
                space_outside_hor_pixel_2: 0,
                space_outside_hor_percentage_1: 0,
                space_outside_hor_percentage_2: 0,
                space_outside_ver_pixel_1: 0,
                space_outside_ver_pixel_2: 0,
                space_outside_ver_percentage_1: 0,
                space_outside_ver_percentage_2: 0,
                space_inside_hor_pixel_1: 0,
                space_inside_hor_pixel_2: 0,
                space_inside_hor_percentage_1: 0,
                space_inside_hor_percentage_2: 0,
                space_inside_ver_pixel_1: 0,
                space_inside_ver_pixel_2: 0,
                space_inside_ver_percentage_1: 0,
                space_inside_ver_percentage_2: 0
            } : Load_Data.Mod
        });

        let align = Load_Data.Mod.align || 'cc';

        Current_Data.Layout.align = Action_Data.el_type === 'text' && !isSingularFitHeight ?
            (['ct', 'rt'].includes(align) ? 'lt' :
                (['cc', 'rc'].includes(align) ? 'lc' :
                    (['cb', 'rb'].includes(align) ? 'lb' : align)
                )
            ) :
            (Current_Data.total_width === 'full' || isSingularFitWidth ?
                (['lt', 'rt'].includes(align) ? 'ct' :
                    (['lc', 'rc'].includes(align) ? 'cc' :
                        (['lb', 'rb'].includes(align) ? 'cb' : align)
                    )
                ) :
                (Current_Data.total_height === 'full' || isSingularFitHeight ?
                    (['lt', 'lb'].includes(align) ? 'lc' :
                        (['ct', 'cb'].includes(align) ? 'cc' :
                            (['rt', 'rb'].includes(align) ? 'rc' : align)
                        )
                    ) :
                    align)
            );

        if (Current_Data.holder_el_type !== 'root') {
            Current_Data.Layout.scroll = 'true';
        }

        if (Action_Data.el_type !== 'image') {
            Current_Data.Layout.aspect_ratio = 'false';
        }

        if (
            !['box', 'image', 'sequence', 'slideshow'].includes(Action_Data.el_type) ||
            Current_Data.ver_amount_type !== 'per'
        ) {
            Current_Data.Layout.vertical_percentages_bound_to_width = 'true';
        }

        return Current_Data;
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            holder_el_type
            holder_height_type
            holder_sub_amount
            holder_width_type
            mod_idx
            total_width
            total_height
            Mod {
                align
                aspect_ratio
                hor_amount_type
                ver_amount_type
                vertical_percentages_bound_to_width
                scroll
                width_type
                width_pixel
                width_percentage
                height_type
                height_pixel
                height_percentage
                column_widths
                column_width
                row_heights
                row_height
                space_outside_hor_pixel_1
                space_outside_hor_pixel_2
                space_outside_hor_percentage_1
                space_outside_hor_percentage_2
                space_outside_ver_pixel_1
                space_outside_ver_pixel_2
                space_outside_ver_percentage_1
                space_outside_ver_percentage_2
                space_inside_hor_pixel_1
                space_inside_hor_pixel_2
                space_inside_hor_percentage_1
                space_inside_hor_percentage_2
                space_inside_ver_pixel_1
                space_inside_ver_pixel_2
                space_inside_ver_percentage_1
                space_inside_ver_percentage_2
            }
            Image_Origin_Data {
                width
                height
            }
            El_Data {
                row_amount
                column_amount
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        //TODO: limitations for image:
        //* only 1 "fit" allowed and only in combination with other being "max"
        //* both "max" is allowed, 1 "max" other "cus" is allowed
        //* in case "max" is used, should provide align-options like "background"...
        //* change labels for image:
        //    "max" is "push to max" (same as box),
        //    "min" is actually "not push"; size determined by other (width/height) and fixed ratio (while for box, "min" is actually "shrink on content")
        // Also events for image-size-change need to be fixed: when both "cus", custom-image is used on <img> tag, otherwise upload-image is used on <div> tag

        const {
            total_width, total_height, Layout, holder_el_type, isSingularFitWidth, isSingularFitHeight, El_Data
        } = Current_Data;
        const { column_amount, row_amount } = El_Data;
        const {
            scroll, aspect_ratio, hor_amount_type, ver_amount_type, width_type, height_type, align,
            column_widths, column_width, row_heights, row_height, vertical_percentages_bound_to_width
        } = Layout;

        return [
            wrapSectionSubs('Layout', [
                [
                    { type: holder_el_type === 'root' ? 'text' : 'hidden' },
                    { text: 'Scroll', style: 'bold', colspan: 2 }
                ],
                [
                    { type: holder_el_type === 'root' ? 'checkbox' : 'hidden', id: 'scroll', label: ' ' },
                    { value: scroll, styling_type: 'switch' }
                ],
                [
                    {
                        type: Action_Data.el_type === 'image' ? 'checkbox' : 'hidden',
                        id: 'aspect_ratio',
                        label: 'Lock aspect ratio'
                    },
                    {
                        value: aspect_ratio,
                        styling_type: 'switch'
                    }
                ],
                [
                    { type: 'text' },
                    { text: 'Horizontal', style: 'bold', colspan: 2 }
                ],
                [
                    { type: 'radio', id: 'hor_amount_type', label: 'Size unit' },
                    {
                        value: hor_amount_type,
                        Options: [
                            ['px', 'Pixels'],
                            ['per', 'Percentage']
                        ]
                    }
                ],
                [
                    {
                        type: ['box', 'form', 'grid', 'image', 'map', 'menu_item', 'slideshow', 'video'].includes(Action_Data.el_type) ?
                            'image_picker' : 'hidden',
                        id: 'width_type',
                        label: '',
                        colspan: 2
                    },
                    {
                        value: width_type,
                        Options: [
                            { label: 'Minimize', filename: 'projects/rootflex/img/form/size_style.png', value: 'fit', left: 0 },
                            { label: 'Specify Amount', filename: 'projects/rootflex/img/form/size_style.png', value: 'cus', left: 100 },
                            { label: 'Maximize', filename: 'projects/rootflex/img/form/size_style.png', value: '100', left: 200 }
                        ],
                        width: 100,
                        height: 60,
                        column_amount_max: 3,
                    }
                ],
                [
                    {
                        type: Action_Data.el_type === 'grid' && column_amount > 1 ? 'dropdown' : 'hidden',
                        id: 'column_widths',
                        label: 'Columns'
                    },
                    {
                        value: column_widths,
                        Options: [
                            ['minimal', 'Fit all columns on their content'],
                            ['specific_pixels', 'Specify a width (fit, pixels, max) for each column '],
                            ['fixed_pixels', 'Use one amount of pixels for all columns'],
                            ['equal_division', 'Divide grid\'s width equally over the columns'],
                            ['relative_division', 'Divide grid\'s width relative to each column\'s content'],
                            ['specific_percentages', 'Specify a percentage part for each column']
                        ],
                        width: 362
                    }
                ],
                [
                    {
                        type: Action_Data.el_type === 'grid' && column_amount > 1 &&
                            width_type === 'fit' && column_widths === 'fixed_pixels' ?
                            'number' : 'hidden',
                        id: 'column_width',
                        label: 'Columns width'
                    },
                    { value: column_width, min_val: 1, max_val: 9999, unit: 'px' }//TODO: max depends on Grid size
                ],
                [
                    { type: 'text' },
                    { text: 'Vertical', style: 'bold', colspan: 2 }
                ],
                [
                    { type: 'radio', id: 'ver_amount_type', label: 'Size unit' },
                    {
                        value: ver_amount_type,
                        Options: [
                            ['px', 'Pixels'],
                            ['per', 'Percentage']
                        ],
                        onChange: value => (
                            !['box', 'image', 'sequence', 'slideshow'].includes(Action_Data.el_type) || value !== 'per' ?
                                { Layout: { vertical_percentages_bound_to_width: 'true' } } : {}
                        )
                    }
                ],
                [
                    {
                        type: ['box', 'image', 'sequence', 'slideshow'].includes(Action_Data.el_type) &&
                            ver_amount_type === 'per' ?
                            'radio' : 'hidden',
                        id: 'vertical_percentages_bound_to_width',
                        label: 'Vertical %'
                    },
                    {
                        value: vertical_percentages_bound_to_width,
                        Options: [
                            ['true', 'Bind To Horizontal Size'],
                            ['false', 'Bind To Vertical Size']
                        ]
                    }
                ],
                [
                    {
                        type: ['box', 'grid', 'image', 'map', 'menu_item', 'slideshow', 'video'].includes(Action_Data.el_type) ?
                            'image_picker' : 'hidden',
                        id: 'height_type',
                        label: '',
                        colspan: 2
                    },
                    {
                        value: height_type,
                        Options: [
                            { label: 'Minimize', filename: 'projects/rootflex/img/form/size_style.png', value: 'fit', left: 0, top: 60 },
                            { label: 'Specify Amount', filename: 'projects/rootflex/img/form/size_style.png', value: 'cus', left: 100, top: 60 },
                            { label: 'Maximize', filename: 'projects/rootflex/img/form/size_style.png', value: '100', left: 200, top: 60 }
                        ],
                        width: 100,
                        height: 72,
                        column_amount_max: 3,
                    }
                ],
                [
                    {
                        type: Action_Data.el_type === 'grid' && row_amount > 1 ? 'dropdown' : 'hidden',
                        id: 'row_heights',
                        label: 'Rows'
                    },
                    {
                        value: row_heights,
                        Options: [
                            ['minimal', 'Fit all rows on their content'],
                            ['specific_pixels', 'Specify a height (fit, pixels, max) for each row '],
                            ['fixed_pixels', 'Use one amount of pixels for all rows'],
                            ['equal_division', 'Divide grid\'s height equally over the rows'],
                            ['relative_division', 'Divide grid\'s height relative to each row\'s content'],
                            ['specific_percentages', 'Specify a percentage part for each row']
                        ],
                        width: 362
                    }
                ],
                [
                    {
                        type: Action_Data.el_type === 'grid' && row_amount > 1 &&
                            height_type === 'fit' && row_heights === 'fixed_pixels' ?
                            'number' : 'hidden',
                        id: 'row_height',
                        label: 'Rows height'
                    },
                    { value: row_height, min_val: 1, max_val: 9999, unit: 'px' }//TODO: max depends on Grid size
                ],
                [
                    { type: 'layout_editor', id: 'editor', label: '', colspan: 2 },
                    {
                        Current_Data: Current_Data,
                        changeCurrentData: changeCurrentData,
                        el_type: Action_Data.el_type,
                        w_min: Action_Data.el_type === 'image' ? 10 : 1,
                        w_max: Action_Data.el_type === 'image' ? Current_Data.Image_Origin_Data.width : 9999,
                        h_min: Action_Data.el_type === 'image' ? 10 : 1,
                        h_max: Action_Data.el_type === 'image' ? Current_Data.Image_Origin_Data.height : 9999
                    }
                ],
                [
                    {
                        type: false && //TODO remove "false" if this "align" setting is useful.... or remove the align setting.
                            holder_el_type !== 'sequence' &&
                            (total_width !== 'full' || total_height !== 'full') &&
                            !(isSingularFitWidth && isSingularFitHeight) &&
                            !(Action_Data.el_type === 'text' && isSingularFitHeight) ?
                            'image_picker' : 'hidden',
                        id: 'align',
                        colspan: 2
                    },
                    {
                        value: align,
                        Options: Action_Data.el_type === 'text' && !isSingularFitHeight ?
                            [
                                { filename: 'projects/rootflex/img/form/text_align.png', value: 'lt' },
                                { filename: 'projects/rootflex/img/form/text_align.png', value: 'lc', top: 53 },
                                { filename: 'projects/rootflex/img/form/text_align.png', value: 'lb', top: 106 }
                            ] :
                            (total_width === 'full' || isSingularFitWidth ?
                                [
                                    { filename: 'projects/rootflex/img/form/align.png', value: 'ct', left: 63 },
                                    { filename: 'projects/rootflex/img/form/align.png', value: 'cc', top: 53, left: 63 },
                                    { filename: 'projects/rootflex/img/form/align.png', value: 'cb', top: 106, left: 63 }
                                ] :
                                (total_height === 'full' || isSingularFitHeight ?
                                    [
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'lc', top: 53 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'cc', top: 53, left: 63 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'rc', top: 53, left: 126 }
                                    ] :
                                    [
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'lt' },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'ct', left: 63 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'rt', left: 126 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'lc', top: 53 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'cc', top: 53, left: 63 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'rc', top: 53, left: 126 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'lb', top: 106 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'cb', top: 106, left: 63 },
                                        { filename: 'projects/rootflex/img/form/align.png', value: 'rb', top: 106, left: 126 }
                                    ]
                                )
                            ),
                        width: 63,
                        height: 53,
                        column_amount_max: (Action_Data.el_type === 'text' && !isSingularFitHeight) ||
                            (total_width === 'full' || isSingularFitWidth) ? 1 : 3
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_layout',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}