import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { ucWords } from '../../utils/string';
import { renderGrid, renderRow } from './management_grid';

export default class Map_MarkerManagement extends Component {
    onClickEdit(Map_Marker) {
        const { Function_Data, Action_Data, El_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'edit_map_marker_panel',
                    frm_name: 'map_marker_settings',
                    el_idx: El_Data.map_el_idx,
                    map_marker_el_idx: Map_Marker.el_idx,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Map_Marker) {
        const { Function_Data, Action_Data, El_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_map_marker_panel',
                    frm_name: 'remove_map_marker',
                    el_idx: El_Data.map_el_idx,
                    map_marker_el_idx: Map_Marker.el_idx,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    renderImage(Map_Marker) {
        const { Function_Data } = this.props;
        const img_size = 38;
        const width = Map_Marker.origin_width > Map_Marker.origin_height ?
            img_size :
            (img_size * Map_Marker.origin_width / Map_Marker.origin_height);

        const height = Map_Marker.origin_height > Map_Marker.origin_width ?
            img_size :
            (img_size * Map_Marker.origin_height / Map_Marker.origin_width);

        const style = {
            width: width + 'px',
            height: height + 'px',
            padding: '6px 0 6px 8px'
        };
        const src = (Function_Data.base_url ? Function_Data.base_url : '') +
            'projects/' + Function_Data.project + '/files/user/' +
            Function_Data.site_id + '/img/upload/' +
            Map_Marker.resource_id + '.' + Map_Marker.file_type;

        return (
            <img
                style={style}
                src={src}
            />
        );
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.Map_Markers.length === 0 ?
            (<p className="gp">No map markers added yet.</p>) :
            renderGrid(
                id + '_map_marker_list',
                [
                    { label: 'Latitude' },
                    { label: 'Longitude' },
                    { label: 'Image' },
                    { label: '' },
                    { label: '' }
                ],
                El_Data.Map_Markers.map((Map_Marker, index) =>
                    renderRow(
                        id + '_map_marker_row_' + Map_Marker.id,
                        index,
                        [
                            { type: 'text', value: ucWords(Map_Marker.latitude) },
                            { type: 'text', value: ucWords(Map_Marker.longitude) },
                            { type: 'custom', content: this.renderImage(Map_Marker) },
                            { type: 'action', icon: 'wrench', onClick: this.onClickEdit(Map_Marker) },
                            { type: 'action', icon: 'delete', onClick: this.onClickDelete(Map_Marker) }
                        ]
                    )
                )
            );
    }
}