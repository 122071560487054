import React, { Component, Fragment } from 'react';
import { buildFormElementID } from '../utils/form';
import Text from './column/text';
import { getColumnBuilder } from './column_builder';

export default class ColumnType extends Component {
    renderType(type, fld_id, El_Data, Col_Settings) {
        const {
            Function_Data, Action_Data, frm_id, Data_Groups,
            tab, reloadForm, updateActionData, changeCurrentData, parent_disabled,
            holderSize, form_h, reSizeAndReposition
        } = this.props;

        const ColumnBuilder = getColumnBuilder(type);

        return (ColumnBuilder ?
            <ColumnBuilder
                Function_Data={Function_Data}
                Action_Data={Action_Data}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                fld_id={fld_id}
                tab={tab}
                El_Data={El_Data}
                Col_Settings={Col_Settings}
                reloadForm={reloadForm}
                updateActionData={updateActionData}
                changeCurrentData={changeCurrentData}
                parent_disabled={parent_disabled}
                holderSize={holderSize}
                form_h={form_h}
                reSizeAndReposition={reSizeAndReposition}
            /> : <Fragment />
        );
    }

    renderWrap(frm_id, Data_Groups, id, Col_Settings, inner_html) {
        const style = {};

        if (Col_Settings.padding_left) {
            style.paddingLeft = `${Col_Settings.padding_left}px`;
        }
        if (Col_Settings.padding_right) {
            style.paddingRight = `${Col_Settings.padding_right}px`;
        }
        if (Col_Settings.padding_top) {
            style.paddingTop = `${Col_Settings.padding_top}px`;
        }
        if (Col_Settings.padding_bottom) {
            style.paddingBottom = `${Col_Settings.padding_bottom}px`;
        }
        if (Col_Settings.width) {
            style.width = Col_Settings.width + (isNaN(Col_Settings.width) ? '' : 'px');
        }
        if (Col_Settings.shrinkable) {
            style.height = '100%';
        }
        if (Col_Settings.height) {
            style.height = Col_Settings.height + (isNaN(Col_Settings.height) ? '' : 'px');
        }

        const col_id = id ? buildFormElementID(frm_id, Data_Groups, id) : '';

        return (
            <td
                id={col_id ? (`col_${col_id}`) : null}
                colSpan={Col_Settings.colspan !== 1 ? Col_Settings.colspan : null}
                align={Col_Settings.h_align}
                valign={Col_Settings.v_align}
                style={style}
            >
                {inner_html}
            </td>
        );
    }

    render() {
        const { Function_Data, Action_Data, frm_id, Data_Groups, tab, Data } = this.props;

        const Col_Data = Data[0] ? Data[0] : 0;
        const El_Data = Data[1] ? Data[1] : 0;
        const type = Col_Data.type ? Col_Data.type : 0;
        if (type === 'loadable') {//TODO:  neccesary?
            return <td>{El_Data}</td>;
        }
        Col_Data.id = Col_Data.id ? Col_Data.id : '';
        const fld_id = Col_Data.id;
        Col_Data.label = Col_Data.label ? Col_Data.label : '';
        let label = Col_Data.label;

        const Col_Settings = {
            width: Col_Data.width ? Col_Data.width : (type === 'space' ? 10 : ''),
            height: Col_Data.height ? Col_Data.height : (type === 'space' ? 0 : ''),
            full_width: Col_Data.full_width ? Col_Data.full_width : false,
            colspan: Col_Data.colspan ? Col_Data.colspan : 1,
            h_align: Col_Data.h_align ? Col_Data.h_align : 'left',
            v_align: Col_Data.v_align ? Col_Data.v_align : 'top',
            padding_left: Col_Data.padding_left ? Col_Data.padding_left : 0,
            padding_right: Col_Data.padding_right ? Col_Data.padding_right : 0,
            padding_top: Col_Data.padding_top ? Col_Data.padding_top : 0,
            padding_bottom: Col_Data.padding_bottom ? Col_Data.padding_bottom : 0,
            shrinkable: Boolean(Col_Data.shrinkable)
        };

        const Column_Element = this.renderType(type, fld_id, El_Data, Col_Settings);
        const Column_Element2 = this.renderWrap(frm_id, Data_Groups, fld_id, Col_Settings, Column_Element);

        let Column_Label;
        let label_position = 'left';

        if (label && (type !== 'hidden' && type !== 'text' && type !== 'line' && type !== 'space')) {
            let colspan = 1;
            if (typeof (label) === 'object') {
                colspan = label.colspan;
                label = label.text;
            }
            if (Col_Data.label_position) {
                label_position = Col_Data.label_position;
            }

            const Col_Settings_Label = {
                type: 'text',
                colspan,
                h_align: label_position === 'right' ? 'left' : 'right',
                v_align: 'top',
                padding_left: 0,
                padding_right: label && label !== ' ' ? 5 : 0,
                padding_top: 3,
                padding_bottom: 0
            };
            Column_Label = (
                <Text
                    Function_Data={Function_Data}
                    Action_Data={Action_Data}
                    frm_id={frm_id}
                    Data_Groups={Data_Groups}
                    fld_id={`${fld_id}_label`}
                    tab={tab}
                    El_Data={{
                        text: label,
                        style: 'normal_grey_label',
                        required: El_Data.required ? El_Data.required : false
                    }}
                />
            );
            Column_Label = this.renderWrap(frm_id, Data_Groups, `${fld_id}_label`, Col_Settings_Label, Column_Label);
        }

        if (label_position === 'left') {
            return (
                <Fragment>
                    {Column_Label}
                    {Column_Element2}
                </Fragment>
            );
        }

        return (
            <Fragment>
                {Column_Element2}
                {Column_Label}
            </Fragment>
        );
    }
}
