import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import crap_store from '../api/crap_store.js';
import dirty_store from '../api/dirty_store.js'; //TODO: get rid of this
import form_data_store from '../api/form_data_store';
import layer_store from '../api/layer_store';
import layout_store from '../api/layout_store';
import { loadCrapIntoCache } from '../api/main_service.js';
import { getPanelData } from '../tools/panel_data';
import { prepareActionData } from '../tools/tools_events';
import { getSize, getViewport } from '../utils/browser';
import { clone } from '../utils/object';
import ChoicesForm from './choices_form';
import { getFormModel } from './form_data';
import PanelFormContent from './panel_form_content';

export default class Panel extends Component {
    constructor(props) {
        super(props);
        const { Function_Data, Action_Data } = props.panel_data;
        this.Form_Model = null;

        this.top_layer_index = props.index + 1;
        this.is_public_form = false;//TODO: make dynamic

        this.state = {
            Action_Data: clone(Action_Data), //clone?
            frm_id_int: this.getIndex(Action_Data.frm_name, Action_Data.data_action_type),
            holderLeft: 10,
            holderTop: 10,
            holderSize: undefined,
            form_h: 10000,
            glassload: false,
            isLoading: true,
        };

        form_data_store.dispatch({
            type: 'INIT',
            Function_Data,
            Action_Data
        });

        this.myRefHolder;
        this.myRefTitle;
        this.myRefForm;

        this.isMouseDown = false;
        this.mouseX = 0;
        this.mouseY = 0;
        this.EventListeners = [];
        this.unsubscribes = [];
        this.data = null;
    }

    componentDidMount() {
        this.unsubscribes = [
            crap_store.subscribe(this.handleMainStoreChange.bind(this))
        ];
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        //const query = this.Form_Model.getQuery(Action_Data);
        loadCrapIntoCache(Object.assign({}, Function_Data, Action_Data), result => {
            this.data = result;
            this.setState({
                isLoading: false
            }, () => {
            });
        });

        this.EventListeners = [
            [this.myRefTitle, 'mousedown', this.onMouseDown.bind(this)],
            [this.myRefTitle, 'mouseup', this.onMouseUp.bind(this)],
            [document, 'mousemove', this.onMouseMove.bind(this)]
        ];
        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));

        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];

        this.reSizeAndReposition();

        this.setState({
            glassload: true
        });
    }

    componentWillUnmount() {
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleMainStoreChange() {
        const { action } = crap_store.getState();
        if (action === 'CACHE_CRAP') {
            this.forceUpdate();
        }
    }

    refetch() {
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        //const query = this.Form_Model.getQuery(Action_Data);
        loadCrapIntoCache(Object.assign({}, Function_Data, Action_Data), result => {
            this.data = result;
            this.forceUpdate();
        });
    }

    handleLayoutStoreChange() {
        const { action } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.reSizeAndReposition();
        }
    }

    onMouseDown(e) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY;
        this.isMouseDown = true;
    }

    onMouseUp() {
        this.isMouseDown = false;
    }

    onMouseMove(e) {
        if (this.isMouseDown) {
            const { holderLeft, holderTop } = this.state;

            this.setState({
                holderLeft: holderLeft + e.clientX - this.mouseX,
                holderTop: holderTop + e.clientY - this.mouseY
            });
        }
    }

    reSizeAndReposition() {
        if (this.myRefHolder && this.myRefForm) {
            const viewport = getViewport(),
                hor_offset = (viewport.w - getSize(this.myRefHolder).w) / 2,
                ver_offset = (viewport.h - getSize(this.myRefHolder).h) / 2;

            this.setState({
                holderLeft: hor_offset > 9 ? hor_offset : 10,
                holderTop: /*ver_offset > 9 ? ver_offset : */10,
                holderSize: getSize(this.myRefHolder), //should exclude scrollbarsize... mismatch between measure of shrinkable...
                form_h: getSize(this.myRefForm).h
            });
        }
    }

    setRefHolder(el) {
        this.myRefHolder = el;
    }

    setTitleBar(el) {
        this.myRefTitle = el;
    }

    setRefForm(el) {
        this.myRefForm = el;
    }

    updateActionData(Action_Data) {
        const New_Action_Data = deepmerge(this.state.Action_Data, Action_Data);
        //TODO The data of older frm_id_int's should optionally be discarded or kept.
        //If kept... on closing the form (by cancel, close-button or clicking glass) should then discard it.
        //if kept... on submitting the form, there should be a property that tells which form-data's should be submitted with it, and which should be discarded....
        this.setState({
            Action_Data: New_Action_Data,
            frm_id_int: this.getIndex(Action_Data.frm_name, Action_Data.data_action_type)
        });
    }

    onClickGlass() {
        this.onClickClose();
    }

    onClickClose() {
        layer_store.dispatch({
            type: 'CLOSE_PANEL',
        });

        //TODO: also discard older frm's data
        form_data_store.dispatch({
            type: 'DISCARD',
            frm_id: `${this.is_public_form ? 'public_' : ''}form_${this.state.frm_id_int}`
        });
    }

    isSelected(frm_name2, data_action_type2) {
        const { frm_name, data_action_type } = this.state.Action_Data;
        return frm_name && frm_name === frm_name2 &&
            data_action_type && data_action_type === data_action_type2;
    }

    renderSideElements(Side_Options) {
        const { Function_Data } = this.props.panel_data;
        let project_path = `projects/${Function_Data.project}`;

        return Side_Options.length ? (
            <div className="pop_side_panel unselectable" unselectable="on">
                {Side_Options.map((Side_Option, index) => {
                    const { frm_name, data_action_type } = prepareActionData(Side_Option.action);

                    return (
                        <div
                            key={index}
                            className={`pop_side_option${this.isSelected(frm_name, data_action_type) ? ' selected' : ''}`}
                            onClick={() => this.updateActionData({
                                frm_name,
                                data_action_type,
                                offset: 0
                            })}
                        >
                            <div className="side_el_img_holder">
                                <img
                                    src={`${Function_Data.base_url + project_path}/img/side_panel/${Side_Option.icon}.png`}
                                    alt=""
                                />
                            </div>
                            <div className="side_el_label_holder">
                                <p className="gp black_txt bold">
                                    {Side_Option.label}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : <Fragment />;
    }

    getIndex(frm_name, data_action_type) {//TODO: get rid of this
        const { Indexes } = dirty_store.getState();
        if (Indexes && Indexes[`${frm_name}__${data_action_type}`]) {
            return Indexes[`${frm_name}__${data_action_type}`];
        }
        dirty_store.dispatch({
            type: 'ADD_FORM_INDEX',
            form_action_data_type: `${frm_name}__${data_action_type}`
        });
        return dirty_store.getState().Indexes[`${frm_name}__${data_action_type}`];
    }

    renderToolPanelContent(Load_Data, tab, refetch) {
        const { navigate, panel_data } = this.props;
        const { Function_Data } = panel_data;
        const { Action_Data } = this.state;

        return (Action_Data.panel_type === 'element_style_panel' && Action_Data.el_type === 'menu' ?
            <ChoicesForm
                Form_Model={this.Form_Model}
                Function_Data={Function_Data}
                Action_Data={Action_Data}
                frm_id_int={this.state.frm_id_int}
                Load_Data={Load_Data}
                reSizeAndReposition={this.reSizeAndReposition.bind(this)}
                updateActionData={this.updateActionData.bind(this)}
                reloadForm={refetch}
                navigate={navigate}
            /> :
            <PanelFormContent
                Form_Model={this.Form_Model}
                Function_Data={Function_Data}
                Action_Data={Action_Data}
                frm_id_int={this.state.frm_id_int}
                Load_Data={Load_Data}
                reSizeAndReposition={this.reSizeAndReposition.bind(this)}
                updateActionData={this.updateActionData.bind(this)}
                is_public_form={this.is_public_form}
                tab={tab}
                upload={false}
                reloadForm={refetch}
                navigate={navigate}
                holder_type="panel"
                holderSize={this.state.holderSize}
                form_h={this.state.form_h}
            />
        );
    }

    render() {
        const { isLoading } = this.state;
        const { Action_Data } = this.state;
        const Panel_Settings = getPanelData(Action_Data);
        this.Form_Model = getFormModel(Action_Data.frm_name);
        const tab = 1;

        return (
            <Fragment>
                <div
                    className={`glass${this.state.glassload ? ' glass_load' : ''}`}
                    style={{ zIndex: (this.top_layer_index + 10) }}
                    onClick={this.onClickGlass.bind(this)}
                ></div>
                <div
                    ref={this.setRefHolder.bind(this)}
                    className="panel_container"
                    style={{
                        zIndex: (this.top_layer_index + 10),
                        left: `${this.state.holderLeft}px`,
                        top: `${this.state.holderTop}px`
                    }}
                >
                    <div>
                        <div>
                            <div
                                ref={this.setTitleBar.bind(this)}
                                className="panel_title_bar panel_title_bar_with_corners dark cursor_move unselectable"
                                unselectable="on"
                            >
                                <h1 className="gp large white title_txt">
                                    {Panel_Settings.title}
                                </h1>
                                <div
                                    className="close_icon"
                                    onClick={this.onClickClose.bind(this)}
                                ></div>
                            </div>
                            <div className="panel_content">
                                {this.renderSideElements(Panel_Settings.Side_Options)}
                                <div
                                    ref={this.setRefForm.bind(this)}
                                    id={`panel_form_${Action_Data.panel_type}`}
                                    className={`panel_form${Panel_Settings.Side_Options.length > 0 ?
                                        ' with_side_elements' : ' without_side_elements'}`}
                                >
                                    {!isLoading && this.renderToolPanelContent(
                                        this.data, //Load_Data
                                        tab,
                                        this.refetch
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
