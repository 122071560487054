import deepmerge from 'deepmerge';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils';
import { flatten } from '../../utils/array';
import { ucFirst, ucWords } from '../../utils/string';
import Background from './styling_module/background';
import Border from './styling_module/border';
import Corner from './styling_module/corner';
import Shadow from './styling_module/shadow';

export default class ElementStylingModules {
    convertLoadDataToCurrentData(Load_Data) {
        const Mod_Current_Data = {};
        ['background', 'border', 'corner', 'shadow'].forEach(mod_type => {
            const sectionId = `${'Mod_Tools' + '_'}${ucWords(mod_type, true)}`;
            const { Mods_Presets } = Load_Data;

            Mod_Current_Data[sectionId] = {
                save_type: 'none',
                preset_mod_idx: Mods_Presets[mod_type].length > 0 ?
                    Mods_Presets[mod_type][0].idx :
                    0
            };
        });

        return deepmerge(Load_Data, Mod_Current_Data);
    }
    getQuery() {
        //TODO: don't get all Mods_Preset_Values here, but instead get only what is selected, when it's selected
        return `query DataQuery($urls: String!) {
            Mods_Presets {
                background
                border
                corner
                shadow
            }
            Mods_All_List {
                background
                border
                corner
                shadow
            }
            Mods_Preset_Values {
                background
                border
                corner
                shadow
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', flatten(['background', 'border', 'corner', 'shadow'].map(mod_type => {
                let Presets_List = [];
                const sectionId = `${'Mod_Tools' + '_'}${ucWords(mod_type, true)}`;
                const { Mods_Presets } = Current_Data;
                if (Mods_Presets[mod_type].length > 0) {
                    for (let j = 0; j < Mods_Presets[mod_type].length; j++) {
                        Presets_List.push([
                            Mods_Presets[mod_type][j].idx,
                            Mods_Presets[mod_type][j].name
                        ]);
                    }
                }

                return [
                    [
                        { type: 'text' },
                        { text: ucFirst(mod_type), style: 'subtitle' }
                    ],
                    [
                        { type: 'section', full_width: true, id: sectionId },
                        {
                            Settings: {
                                bg_color: 'f0f0f0',
                                border_left: [1, 'B1B1B1'],
                                border_right: [1, 'B1B1B1'],
                                border_top: [1, 'B1B1B1'],
                                border_bottom: [1, 'B1B1B1'],
                                padding_left: 5,
                                padding_right: 5,
                                padding_top: 5,
                                padding_bottom: 5
                            },
                            Content: [[
                                { type: 'row', id: `module_columns_${mod_type}` },
                                {
                                    Content: [
                                        [
                                            { type: 'image_picker', id: 'save_type', colspan: 2 },
                                            {
                                                value: Current_Data[sectionId].save_type,
                                                Options: [
                                                    { label: 'None', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'none', left: 0, top: 0 },
                                                ].concat(
                                                    Current_Data[sectionId].save_type === 'custom' ? [
                                                        { label: 'Custom', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'custom', left: 41, top: 0 }
                                                    ] : []
                                                ).concat([
                                                    { label: 'Presets', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'preset', left: 81, top: 0 }/*,
                                                        {label: 'Others', filename: 'projects/rootflex/img/form/custom_preset_all.png', value: 'all', left: 322, top: 0}*/
                                                ]),
                                                width: 40,
                                                height: 32,
                                                column_amount_max: 3,
                                            }
                                        ],
                                        [
                                            {
                                                type: Current_Data[sectionId].save_type === 'preset' ? 'dropdown' : 'hidden',
                                                id: 'preset_mod_idx',
                                            },
                                            {
                                                value: Current_Data[sectionId].preset_mod_idx,
                                                Options: Presets_List,
                                                margin_top: 8
                                            }
                                        ],
                                        [
                                            {
                                                type: Current_Data[sectionId].save_type !== 'custom' ? 'button_image' : 'hidden',
                                                id: 'open_more',
                                                h_align: 'right',
                                                v_align: 'center',
                                                padding_left: 5,
                                            },
                                            {
                                                name: `open_set_${mod_type}_panel`,
                                                button_style: [124, 33, 0, 4, 0, 4, 7],
                                                text: 'Customize',
                                                img_url: getImageURL('rootflex', 'custom_preset_all'),
                                                img_style: [0, 32, 32, 327],
                                                onClick: () => {
                                                    changeCurrentData({
                                                        [sectionId]: {
                                                            save_type: 'custom'
                                                        }
                                                    });
                                                },
                                            }
                                        ],
                                        [
                                            {
                                                type: Current_Data[sectionId].save_type === 'custom' ? 'button_image' : 'hidden',
                                                id: 'close_more',
                                                h_align: 'right',
                                                v_align: 'center',
                                                padding_left: 5,
                                            },
                                            {
                                                name: `close_set_${mod_type}_panel`,
                                                button_style: [98, 33, 2],
                                                text: 'Discard',
                                                img_url: getImageURL('rootflex', 'custom_preset_all'),
                                                img_style: [0, 32, 32, 327],
                                                onClick: () => {
                                                    changeCurrentData({
                                                        [sectionId]: {
                                                            save_type: 'none'
                                                        }
                                                    });
                                                },
                                            }
                                        ]
                                    ]
                                }
                            ]]
                        }
                    ]
                ].concat(Current_Data[sectionId].save_type === 'custom' ?
                    [this.getModFormContent(
                        mod_type, Function_Data, Action_Data, frm_id, frm_id_int,
                        Current_Data, updateActionData, changeCurrentData, navigate
                    )] :
                    []
                );
            }))),
            getDoubleSubmitSet(frm_id, () => {
                let isFormValid = true;

                if (Current_Data.Background) {
                    const { type, gradient_idx, resource_id } = Current_Data.Background;

                    if (
                        type === 'gradient' &&
                        (isNaN(gradient_idx) || gradient_idx === 0)
                    ) {
                        alert('Cannot save, because no valid gradient was selected.');
                        isFormValid = false;
                    } else if (
                        (type === 'image' || type === 'tile') &&
                        (isNaN(resource_id) || resource_id === 0)
                    ) {
                        alert('Cannot proceed, because no image was selected.');
                        isFormValid = false;
                    }
                }

                if (isFormValid) {
                    updateActionData({
                        frm_name: 'layout_settings'
                    });
                }
            }, 'Next')
        ];
    }

    getModFormData(mod_type) {
        if (mod_type === 'background') {
            return new Background();
        }
        if (mod_type === 'border') {
            return new Border();
        }
        if (mod_type === 'corner') {
            return new Corner();
        }
        if (mod_type === 'shadow') {
            return new Shadow();
        }
        return {};
    }

    getModFormContent(mod_type, Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        const Form_Data = this.getModFormData(mod_type);
        const query = `query DataQuery($urls: String!) {
            mod_idx
            save_type
            Preset_Values
            All_List
            optional_module
            Module_Variants
            ${Form_Data.getQueryFragment(Action_Data)}
        }`;

        return Current_Data[ucWords(mod_type, true)] ?
            this.getModSection(
                mod_type, Form_Data, Function_Data, Action_Data, frm_id, frm_id_int, Current_Data,
                updateActionData, changeCurrentData, navigate
            ) : [];
    }

    getModSection(mod_type, Form_Data, Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            { type: 'section', full_width: true, id: ucWords(mod_type, true) },
            {
                Settings: {
                    // margin-top: 10,
                    bg_color: 'ffffff',
                    border_left: [1, 'B1B1B1'],
                    border_right: [1, 'B1B1B1'],
                    border_top: [1, 'B1B1B1'],
                    border_bottom: [1, 'B1B1B1'],
                    padding_left: 10,
                    padding_right: 10,
                    padding_top: 10,
                    padding_bottom: 10
                },
                Content: Form_Data.getContent(
                    Function_Data,
                    Action_Data,
                    frm_id,
                    frm_id_int,
                    Current_Data,
                    updateActionData,
                    changeCurrentData,
                    navigate
                )
            }
        ];
    }
}
