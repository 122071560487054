import deepmerge from 'deepmerge';
import custom_font_store from '../../api/custom_font_store';
import font_family_store from '../../api/font_family_store';
import layer_store from '../../api/layer_store';
import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findArray, findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

//TODO: custom file-based fonts should never have to "fall back"... they are included by file.
//OS Fonts could be absent on a user's pc, thus require fallback
//External Fonts... ???? Could they require fallback if the link somehow fails??? NEED TO TEST!
//Thus...
//Perhaps automatically add custom file-based font to a new font-family (without fallbacks options... e.g. without a "settings-wrench icon")

export default class FontFamilyManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [
            font_family_store.subscribe(this.handleFontFamilyStoreChange(changeCurrentData, getCurrentData)),
            custom_font_store.subscribe(this.handleCustomFontStoreChange(changeCurrentData, getCurrentData))
        ];
    }

    convertFontFamilyIdsToNames(Fonts, Font_Family) {
        const {
            main_font_id, fallback_1_id, fallback_2_id, fallback_3_id, fallback_4_id, generic_type, id
        } = Font_Family;

        const getFontName = given_id => {
            if (parseInt(given_id, 10) !== 0) {
                const Font = findArray(
                    Fonts,
                    Font2 => parseInt(Font2[0], 10) === parseInt(given_id, 10)
                );
                if (Font) {
                    return Font[1];
                }
            }
            return '';
        };

        return {
            id: id,
            main: getFontName(main_font_id),
            fallback_1: getFontName(fallback_1_id),
            fallback_2: getFontName(fallback_2_id),
            fallback_3: getFontName(fallback_3_id),
            fallback_4: getFontName(fallback_4_id),
            generic_type: generic_type,
        };
    }

    handleFontFamilyStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = font_family_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Font_Families = clone(Current_Data.Font_Families);

            if (state.action === 'add') {
                Changed_Font_Families.push(state.New_Font_Family);
            }

            if (state.action === 'update') {
                Changed_Font_Families = Changed_Font_Families.map(Font_Family =>
                    parseInt(Font_Family.id, 10) === parseInt(state.Updated_Font_Family.id, 10) ?
                        state.Updated_Font_Family :
                        Font_Family
                );
            }

            if (state.action === 'remove') {
                Changed_Font_Families.splice(findIndexArray(
                    Changed_Font_Families,
                    Font_Family => parseInt(Font_Family.id, 10),
                    parseInt(state.removed_id, 10)
                ), 1);
            }

            changeCurrentData({ Font_Families: Changed_Font_Families });
        };
    }

    handleCustomFontStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = custom_font_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Fonts = clone(Current_Data.Fonts);
            let Changed_Font_Families = clone(Current_Data.Font_Families);

            if (state.action === 'add') {
                Changed_Fonts.push([state.New_Custom_Font.id, state.New_Custom_Font.name]);
            }

            if (state.action === 'update') {
                Changed_Fonts = Changed_Fonts.map(Font => {
                    if (parseInt(Font[0], 10) === parseInt(state.Updated_Custom_Font.id)) {
                        Font[1] = state.Updated_Custom_Font.name;
                    }
                    return Font;
                });
            }

            if (state.action === 'remove') {
                Changed_Font_Families = Changed_Font_Families.map(Font_Family => {
                    ['main_font_id', 'fallback_1_id', 'fallback_2_id', 'fallback_2_id', 'fallback_2_id'].forEach(key => {
                        if (parseInt(Font_Family[key], 10) === parseInt(state.removed_id, 10)) {
                            Font_Family[key] = state.replacement_id;
                        }
                    })
                    return Font_Family;
                });

                Changed_Fonts.splice(findIndexArray(
                    Changed_Fonts,
                    Font => parseInt(Font[0], 10),
                    parseInt(state.removed_id, 10)
                ), 1);
            }

            changeCurrentData({
                Fonts: Changed_Fonts,
                Font_Families: Changed_Font_Families
            });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Font_Families
            Fonts
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'font_families_management', id: 'font_id' },
                    {
                        Font_Families: Current_Data.Font_Families
                            .map(Font_Family => this.convertFontFamilyIdsToNames(Current_Data.Fonts, Font_Family))
                    }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'add_new_font_family',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [114, 16],
                        text: 'Add Font Family',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_font_family_panel',
                                            frm_name: 'font_family_settings',
                                            font_family_id: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'create'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'custom_fonts_management',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [210, 16],
                        text: 'Manage (Add/Remove) Custom Fonts',
                        img_name: 'text_styling',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'custom_font_management_panel',
                                            frm_name: 'custom_font_management',
                                            font_family_id: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'manage'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'os_fonts_management',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [210, 16],
                        text: 'View OS Fonts',
                        img_name: 'obj_text',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'custom_font_management_panel',
                                            frm_name: 'os_font_management',
                                            font_family_id: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'manage'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}