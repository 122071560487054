import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_id: '',
    New_Resolution: {},
    Updated_Resolution: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD':
            state.New_Resolution = action.New_Resolution;
            return state;
        case 'UPDATE':
            state.Updated_Resolution = action.Updated_Resolution;
            return state;
        case 'REMOVE':
            state.removed_id = action.removed_id;
            return state;
        default:
            return state;
    }
});

export default store;