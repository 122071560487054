import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';

export default class Image extends Component {
    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, Col_Settings } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id) || null;
        const style = {};
        const { show_transparency_background, src, alt } = El_Data;

        if (Col_Settings.h_align && ['left', 'right'].includes(Col_Settings.h_align)) {
            style.float = Col_Settings.h_align;
        }

        return (
            <div
                className={show_transparency_background ? 'form_transparency_background' : null}
                style={style}
            >
                <img id={id} src={src} alt={alt} />
            </div>
        );
    }
}