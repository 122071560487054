import { createStore } from 'redux';
import { getScrollBarSize, getViewport } from '../utils/browser';

const initialState = {
    action: '',
    scrollBarSize: 16,
    breakpoint_hor_pos_pixel: 195,
    //content_holder: null,
    content_holder_pos: 0,
    viewport: getViewport()
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'INIT_SCROLLBAR':
            state.scrollBarSize = getScrollBarSize();
            return state;
        case 'TOOLBAR_RESIZE':
            state.breakpoint_hor_pos_pixel = action.breakpoint_hor_pos_pixel;
            return state;
        case 'WINDOW_RESIZE':
            state.viewport = getViewport();
            return state;
        /*case 'SET_CONTENT_HOLDER':
            state.content_holder = action.content_holder;
            return state;*/
        case 'SET_CONTENT_HOLDER_SCROLL_POSITION':
            state.content_holder_pos = action.content_holder_pos;
            return state;
        default:
            return state;
    }
});

export default store;