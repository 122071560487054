import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { renderGrid, renderRow } from './management_grid';

export default class ScrollZoneManagement extends Component {
    onClickEdit(Scroll_Zone) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'edit_scroll_zone_panel',
                    frm_name: 'scroll_zone_settings',
                    scroll_zone_id: Scroll_Zone.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Scroll_Zone) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_scroll_zone_panel',
                    frm_name: 'remove_scroll_zone',
                    scroll_zone_id: Scroll_Zone.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.Scroll_Zones.length === 0 ? (
            <p className="gp">No scroll zones available.</p>
        ) :
            renderGrid(
                id + '_scroll_zone_list',
                [
                    { label: 'Min Position' },
                    { label: 'Max Position' },
                    { label: '' },
                    { label: '' }
                ],
                this.props.El_Data.Scroll_Zones.map((Scroll_Zone, index) =>
                    renderRow(id + '_scroll_zone_row_' + Scroll_Zone.id, index, [
                        { type: 'text', value: Scroll_Zone.min_position },
                        { type: 'text', value: Scroll_Zone.max_position },
                        { type: 'action', icon: 'wrench', onClick: this.onClickEdit(Scroll_Zone) },
                        { type: 'action', icon: 'delete', onClick: this.onClickDelete(Scroll_Zone) }
                    ])
                )
            );
    }
}