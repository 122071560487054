import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { upload } from '../../api/upload_query';
import { buildFormElementID, buildFormElementName } from '../../utils/form';
import { clone } from '../../utils/object';
import { ucWords } from '../../utils/string';
import { renderGrid, renderRow } from './management_grid';

export default class UploadedFontsManagement extends Component {
    onClickEdit(Font) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'edit_custom_font_panel',
                    frm_name: 'custom_font_settings',
                    custom_font_id: Font.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'edit'
                }
            },
        });
    }

    onClickDelete(Font) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_custom_font_panel',
                    frm_name: 'remove_custom_font',
                    custom_font_id: Font.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    onChangeFileSelect(Font, index, variant) {
        const { Function_Data, frm_id, Data_Groups, El_Data, changeCurrentData } = this.props;
        const { max_file_uploads, upload_max_filesize, post_max_size } = El_Data;

        return e => {
            upload(
                Function_Data,
                frm_id,
                Data_Groups,
                'upload_font_file' + '_' + index + (variant ? '_' + variant : ''),
                e.target.files,
                {
                    id: Font.id,
                    index: index,
                    variant: variant
                },
                'upload_font_file',
                max_file_uploads,
                upload_max_filesize,
                post_max_size,
                (Result_Data) => {
                    if (Result_Data) {
                        const Custom_Fonts = clone(El_Data.Custom_Fonts);
                        Result_Data.Files.forEach(File => {
                            Custom_Fonts.map(Font => {
                                if (parseInt(Font.id, 10) === File.id) {
                                    Font['has_' + File.ext + '_file' + (File.variant ? '_' + File.variant : '')] = 'true';
                                }

                                return Font;
                            });
                        });

                        changeCurrentData({
                            Custom_Fonts: Custom_Fonts
                        });
                    }
                }
            );
        };
    }

    renderSuffixVariants(Font) {
        const { frm_id, Data_Groups } = this.props;

        return ['', 'bold', 'italic', 'bold_italic'].map((Suffix_Variant, index) => {
            const style = {};
            if (index === 1 || index === 3) {
                style.fontWeight = 'bold';
            }
            if (index === 2 || index === 3) {
                style.fontStyle = 'italic';
            }

            const style2 = { float: 'left', width: '54px' };
            const style3 = { float: 'left', padding: '6px 8px' };
            const style4 = Object.assign({}, style3);
            const suffix = Suffix_Variant ? '_' + Suffix_Variant : '';
            if (index === 0 && Font['has_woff_file' + suffix] === 'false') {
                style4.color = '#FF0000';
                style4.fontWeight = 'bold';
            }
            const style5 = {
                position: 'relative',
                backgroundColor: '#ddd',
                backgroundImage: 'linear-gradient(to bottom, #f2f2f2, #bebebe)',
                width: '50px',
                border: '1px solid #aaa',
                borderRadius: '6px',
                margin: '2px'
            };

            return (
                <div
                    key={index}
                    style={{ clear: 'both' }}
                >
                    <div style={{ float: 'left', width: '65px' }}>
                        <p
                            className="gp"
                            style={style}
                        >
                            {ucWords(['Normal', 'Bold', 'Italic', 'Bold Italic'][index])}
                        </p>
                    </div>
                    <div style={style2}>
                        <p
                            className="gp"
                            style={style4}
                        >
                            {Font['has_woff_file' + suffix] === 'true' ? 'Yes' : 'No'}
                        </p>
                    </div>
                    <div style={style2}>
                        <p
                            className="gp"
                            style={style3}
                        >
                            {Font['has_woff2_file' + suffix] === 'true' ? 'Yes' : 'No'}
                        </p>
                    </div>
                    <div style={style2}>
                        <p
                            className="gp"
                            style={style3}
                        >
                            {Font['has_ttf_file' + suffix] === 'true' ? 'Yes' : 'No'}
                        </p>
                    </div>
                    <div style={{ float: 'left', width: '60px' }}>
                        <div style={style5}>
                            <input
                                type="file"
                                id={buildFormElementID(frm_id, Data_Groups, 'upload_font_file' + '_' + index + suffix)}
                                name={buildFormElementName(Data_Groups, 'upload_font_file' + '_' + index + suffix) + '[]'}
                                tabIndex="-1"
                                className="clickable_input fld_input_invisible fld_pointer"
                                style={{
                                    width: 50 + 'px',
                                    height: 17 + 'px'
                                }}
                                accept=".woff, .woff2, .ttf"
                                multiple="multiple"
                                onChange={this.onChangeFileSelect(Font, index, Suffix_Variant).bind(this)}
                            />
                            <p style={{ fontSize: '10px', lineHeight: '17px', textAlign: 'center' }}>Upload</p>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);
        const Uploaded_Fonts = El_Data.Custom_Fonts.filter(Custom_Font => Custom_Font.source === 'uploaded');

        if (Uploaded_Fonts.length === 0) {
            return (
                <p className="gp">No fonts yet uploaded.</p>
            );
        }

        const Heads = [
            { label: 'Used' },
            { label: 'Name' },
            {
                SubHeads: [
                    { label: 'Style', width: 65 },
                    { label: 'WOFF', width: 54 },
                    { label: 'WOFF2', width: 54 },
                    { label: 'TTF', width: 54 },
                    { label: '', width: 60 }
                ]
            },
            { label: '' },
            { label: '' },
        ];

        const Rows = Uploaded_Fonts.map((Font, index) => {
            const id = id + '_custom_font_row_' + Font.id;

            const Cells = [
                { type: 'text', value: (Font.in_use ? 'Yes' : 'No') },
                { type: 'text', value: ucWords(Font.name) },
                { type: 'custom', content: this.renderSuffixVariants(Font) },
                { type: 'action', icon: 'wrench', onClick: this.onClickEdit(Font) },
                { type: 'action', icon: 'delete', onClick: this.onClickDelete(Font) }
            ];
            return renderRow(id, index, Cells);
        });

        return renderGrid(
            id + '_font_list',
            Heads,
            Rows
        );
    }
}