import React, { Component } from 'react';
import { Tools_Action_Paneled } from './tools_action';
import user_store from '../api/user_store';

export default class User_Tools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: ''
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [
            user_store.subscribe(this.handleUserStoreChange.bind(this))
        ];
        this.handleUserStoreChange(true);
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleUserStoreChange(isInit) {
        const state = user_store.getState();

        if (isInit || state.action === 'update') {
            this.setState({
                first_name: state.first_name,
                last_name: state.last_name
            });
        }
    }

    render() {
        //TODO: use user_store for values
        const { Function_Data } = this.props;
        const { first_name, last_name } = this.state;

        const Buttons = [
            ['edit_user', 'Settings', 'edit_user'], /*
             ['manage_users', 'Manage Users', 'users'],*/
            ['sign_out', 'Sign Out', 'log_out']
        ];

        return (
            <div className="tools_dropdown_container_holder">
                <div className="main_actions">
                    <Tools_Action_Paneled
                        given_style="button_list"
                        Function_Data={Function_Data}
                        id="user_action_group"
                        icon="user"
                        color="black"
                        Buttons={Buttons}
                    />
                </div>
                <div className="tools_dropdown_container">
                    <div className="user_tools">
                        <p className="user_tools_text">
                            {first_name + ' ' + last_name}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}