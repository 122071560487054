import React, { Component } from 'react';
import { buildFormElementID, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';
import { clone } from '../../utils/object';
import Checkbox from './checkbox';
import Field from './field';
import { renderGrid, renderRow } from './management_grid';

export default class SelectorOptionsManagement extends Component {
    onClickAdd() {
        const { Data_Groups, El_Data, changeCurrentData } = this.props;

        changeCurrentData(convertDataGroupsAndFldIdToChangeSet(Data_Groups, {
            Options: El_Data.Options.concat([
                {
                    id: '', //neccesary?
                    label: '',
                    selector_el_idx: '', //neccesary?
                    value: '',
                },
            ])
        }));
    }

    onClickDelete(option_index) {
        const { Data_Groups, El_Data, changeCurrentData } = this.props;

        return () => {
            const Options = clone(El_Data.Options);
            Options.splice(option_index - 1, 1);
            const changeSet = {
                Options: Options
            };

            if (Options.length === 1) {
                changeSet.value = Options[0].value;
            }

            changeCurrentData(convertDataGroupsAndFldIdToChangeSet(Data_Groups, changeSet));
        };
    }

    changeCurrentData_Customized(New_Data) {
        //TODO: try to get rid of this, perhaps post "isSelected" to back-end instead of "value" on main element
        const { Data_Groups, El_Data, changeCurrentData } = this.props;
        const Options = clone(El_Data.Options);
        const fieldAndIndex = Object.keys(New_Data)[0];
        const splitIndex = fieldAndIndex.lastIndexOf('_');
        const index = parseInt(fieldAndIndex.substr(splitIndex + 1, 10) - 1);
        const field = fieldAndIndex.substr(0, splitIndex);
        const oldValue = Options[index].value;

        const changeSet = {};

        if (field === 'isSelected') {
            changeSet.value = Options[index].value;
        } else {
            Options[index][field] = New_Data[fieldAndIndex];
            changeSet.Options = Options;

            if (field === 'value' && oldValue === El_Data.value) {
                changeSet.value = Options[index].value;
            }
        }

        changeCurrentData(convertDataGroupsAndFldIdToChangeSet(Data_Groups, changeSet));
    }

    render() {
        const { Function_Data, Action_Data, frm_id, Data_Groups, fld_id, El_Data, tab, } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id);

        if (El_Data.Options.length === 0) {
            return <p className="gp">No options created yet.</p>;
        }

        const Heads = [
            { label: '#' },
            { label: 'Selected' },
            { label: 'Value' },
            { label: 'Label' },
            { label: '' }
        ];

        const hasMultipleOptions = El_Data.Options.length > 1;
        const value = El_Data.value;

        const Rows = El_Data.Options
            .map((Option, index) => {
                const option_index = index + 1;

                const Cells = [
                    { type: 'text', value: option_index },
                    {
                        type: 'custom',
                        content: (
                            <Checkbox
                                Function_Data={Function_Data}
                                Action_Data={Action_Data}
                                frm_id={frm_id}
                                Data_Groups={[]}
                                fld_id={'isSelected_' + option_index}
                                tab={tab}
                                El_Data={{
                                    value: Option.value !== '' && Option.value === value ? 'true' : 'false',
                                    styling_type: 'switch',
                                }}
                                Col_Settings={{}}
                                changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                            />
                        ),
                    },
                    {
                        type: 'custom',
                        content: (
                            <Field
                                Function_Data={Function_Data}
                                Action_Data={Action_Data}
                                frm_id={frm_id}
                                Data_Groups={[]}
                                fld_id={'value_' + option_index}
                                tab={tab}
                                El_Data={{
                                    value: Option.value,
                                }}
                                Col_Settings={{}}
                                changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                            />
                        ),
                    },
                    {
                        type: 'custom',
                        content: (
                            <Field
                                Function_Data={Function_Data}
                                Action_Data={Action_Data}
                                frm_id={frm_id}
                                Data_Groups={[]}
                                fld_id={'label_' + option_index}
                                tab={tab}
                                El_Data={{
                                    value: Option.label,
                                }}
                                Col_Settings={{}}
                                changeCurrentData={this.changeCurrentData_Customized.bind(this)}
                            />
                        ),
                    },
                    {
                        type: hasMultipleOptions ? 'action' : 'text',
                        value: '',
                        icon: 'delete',
                        onClick: this.onClickDelete(option_index),
                    },
                ];
                return renderRow(id + '_option_row_' + Option.id, index, Cells);
            })
            .concat(
                <tr className="add_option" key="add">
                    <td colSpan="4" />
                    <td id={id + '_add_option'} style={{ padding: '5px' }}>
                        <div
                            className="add"
                            style={{
                                float: 'left',
                                width: '16px',
                                height: '16px',
                                cursor: 'pointer',
                            }}
                            onClick={this.onClickAdd.bind(this)}
                        ></div>
                    </td>
                </tr>
            );

        return renderGrid(id + '_option_list', Heads, Rows);
    }
}
