import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class Color extends Component {
    constructor(props) {
        super(props);

        const { frm_id, Data_Groups, fld_id, El_Data } = props;
        var readonly = El_Data.readonly || '';

        if (readonly) {
            this.readonly = 'on';
        }
        this.myRef;

        this.id = buildFormElementID(frm_id, Data_Groups, fld_id);
    }

    setRef(el) {
        this.myRef = el;
    }

    onClickPicker() {
        const { Data_Groups, fld_id, El_Data, changeCurrentData } = this.props;
        layer_store.dispatch({
            type: 'SHOW_COLORPICKER',
            options: {
                id: this.id,
                value: El_Data.value || 'ffffff',
                el: this.myRef,
                onChange: value => {
                    const New_Data = El_Data.onChange ? El_Data.onChange(value) : null,
                        New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), value);
                    changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
                }
            }
        });
    }

    render() {
        const { Data_Groups, fld_id, El_Data, Col_Settings, tab, parent_disabled } = this.props;
        const value = El_Data.value || 'ffffff';
        const direction = El_Data.direction || 'hor';
        const isDisabled = parent_disabled || El_Data.disabled;
        const isReadonly = parent_disabled || El_Data.readonly;

        const style = {};

        if (El_Data.Style) {
            ['width', 'height', 'paddingRight']
                .map((prop, index) => ({ prop, value: El_Data.Style[index] }))
                .filter(set => set.value && !isNaN(set.value))
                .forEach(set => {
                    style[set.prop] = set.value + 'px';
                });
        }

        const className = 'color_picker_field' +
            (isReadonly ? ' fld_input_readonly' : '') +
            (isDisabled ? ' fld_input_disabled' : '');

        return (
            <div
                className={'form_field_box inpt' + (direction === 'ver' ? ' inpt_ver' : '') + ' color_picker' + (isDisabled ? '' : ' fld_pointer')}
                style={Col_Settings.h_align === 'right' ? { float: 'right' } : null}
                onClick={!isDisabled ? this.onClickPicker.bind(this) : null}
                ref={this.setRef.bind(this)}
            >
                <input
                    type="text"
                    id={this.id}
                    name={fld_id ? buildFormElementName(Data_Groups, fld_id, 'text') : null}
                    value={value !== null ? value : ''}
                    className={className}
                    style={style}
                    maxLength={6}
                    tabIndex={tab ? tab : null}
                    readOnly={isReadonly}
                    disabled={isDisabled}
                />
                <div className={El_Data.icon || 'palette'} />
                <div style={{ backgroundColor: '#' + value }} />
                <div />
            </div>
        );
    }
}