import React, { Component, Fragment } from 'react';
import Element_Router from './element_router';
import Layer from './layer';
import { buildEvents } from '../utils/events';

export default class Grid extends Component {
    renderColumn(Column) {
        const {
            project, site_id, Site, page_id, Navigation_Data,
            Element, Presets, show_tools, tab, Font_Families,
            use_service, signed_in, navigate, user_role,
            requiresNewEffect, level, effectClass, reloadForm,
            updateActionData, parent_disabled
        } = this.props;

        return (Column.length > 1 ?
            <Fragment>{
                Column.map(Sub_Element =>
                    <Layer
                        key={Sub_Element.el_idx}
                        Site={Site}
                        Navigation_Data={Navigation_Data}
                        Presets={Presets}
                        Font_Families={Font_Families}
                        project={project}
                        site_id={site_id}
                        page_id={page_id}
                        show_tools={show_tools}
                        use_service={use_service}
                        tab={tab}
                        holder_element_width_type={Element.Layout.width_type}
                        holder_element_height_type={Element.Layout.height_type}
                        amount_of_elements_in_holder={Column.length}
                        Element={Sub_Element}
                        signed_in={signed_in}
                        navigate={navigate}
                        user_role={user_role}
                        requiresNewEffect={requiresNewEffect}
                        level={level + 1}
                        effectClass={effectClass}
                        reloadForm={reloadForm}
                        updateActionData={updateActionData}
                        parent_disabled={parent_disabled}
                    />
                )}
            </Fragment> :
            <div
                id={Column[0].el_type !== 'grid_cell' ?
                    'element_' + Column[0].el_type + '_' + Column[0].el_idx :
                    null
                }
                className={['icon', 'text', 'form'].includes(Column[0].el_type) ?
                    'body_font_size' :
                    null
                }
            >
                <Element_Router
                    project={project}
                    site_id={site_id}
                    Navigation_Data={Navigation_Data}
                    page_id={page_id}
                    Element={Column[0]}
                    Site={Site}
                    Presets={Presets}
                    Font_Families={Font_Families}
                    show_tools={show_tools}
                    use_service={use_service}
                    tab={tab}
                    signed_in={signed_in}
                    navigate={navigate}
                    user_role={user_role}
                    level={level + 1}
                    effectClass={effectClass}
                    reloadForm={reloadForm}
                    updateActionData={updateActionData}
                    parent_disabled={parent_disabled}
                />
            </div>
        );
    }

    render() {
        const { Navigation_Data, Element, signed_in, navigate } = this.props;
        const Events = buildEvents(Element.Behavior, Navigation_Data, navigate);

        return Events.href ?
            <a
                href={Events.href}
                target={Events.new_tab ? '_blank' : null}
                title={Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={signed_in ? e => e.preventDefault() : (
                    Events.onClick ? e => { e.preventDefault(); Events.onClick(); } : null
                )}
            >{this.renderGrid(Events)}</a> :
            this.renderGrid(Events);
    }

    renderGrid(Events) {
        /* Layout.column_widths === 'specific_pixels' won't work if fit/cus is used in combination with multiple '100' rows/columns.
        * Therefore this should either be done with several nested elements, or be corrected with javascript.
        * 
        * Nested elements:
        * By using tables, it wil only work if all the '100' rows/columns are next to each other.
        * Then for '100' columns, just nest them inside 1 <td></td>, repeat for each row
        * But fot '100' rows?, or both '100' rows & columns???
        * 
        * By using div's:
        * ? css when only '100' and 'cus' used: calc((100% - 100px)/2) ???
        * 
        * By Javascript:
        * Determine actual percentage for '100' row/column size, by checking pixel size of other rows/columns,
        * then check available size for table, then calculate actual available percentage,
        * then check amount of '100' rows/columns, then calculate actual percentage for these '100' rows/columns
        * 
        * 
        * Only use <table> when multiple rows & columns and at least 1 column has size_type:
        * 'fit' and that column has at least 1 flexible (text somewhere inside) child-element (content) ???
        */
        const { el_type, el_idx, Element, Corrections, signed_in } = this.props;
        const { El_Data, Content = [] } = Element;
        const style = Corrections && Corrections.full_height_correction ?
            { minHeight: Corrections.full_height_correction.height } :
            null;

        if (true/*remove when table->div change is ready*/ || (Content.length > 1 && Content[0].length > 1 && El_Data.has_column_fit)) {
            return (
                <table
                    id={[el_type, el_idx].join('_')}
                    style={style}
                    title={Events.tooltip && !signed_in ? Events.tooltip : null}
                    onClick={!Events.href && Events.onClick && !signed_in ? Events.onClick : null}
                ><tbody>
                        {Content.map(Row => {
                            const row_key = 'grid_' + el_idx + '_row_' + Row[0][0].row_el_idx;
                            return (
                                <tr
                                    key={row_key}
                                    id={row_key}
                                >{
                                        Row.map(Column => {
                                            const align = Column.length > 1 ? 'cc' : (
                                                typeof (Column[0].Layout) !== 'undefined' ? Column[0].Layout.align : 'cc'
                                            );
                                            const column_key = row_key + '_column_' + Column[0].column_el_idx;

                                            return (
                                                <td
                                                    key={column_key}
                                                    id={column_key}
                                                    align={this.buildHAlign(align)}
                                                    valign={this.buildVAlign(align)}
                                                    className={'column_' + Column[0].column_el_idx}
                                                >
                                                    {this.renderColumn(Column)}
                                                </td>
                                            );
                                        })
                                    }</tr>
                            );
                        })}
                    </tbody></table>
            );
        } else {
            return (
                <div id={[el_type, el_idx].join('_')}>
                    {Content.map((Row, rowindex) => {
                        return (
                            <div
                                key={rowindex}
                                id={'grid_' + el_idx + '_row_' + Content[i][0][0].row_el_idx}
                            >
                                {Row.map((Column, index) => {
                                    //const align = Column.length > 1 ? 'cc' : (typeof(Column[0].Layout) !== 'undefined' ? Column[0].Layout.align : 'cc');
                                    return (
                                        <div
                                            key={index}
                                            id={'grid_' + el_idx + '_row_' + Row[0][0].row_el_idx + '_column_' + Column[0].column_el_idx}
                                            className={'column_' + Column[0].column_el_idx}
                                        >
                                            {this.renderColumn(Column)}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    buildHAlign(align) {
        return ['lt', 'lc', 'lb'].includes(align) ?
            'left' : (
                ['ct', 'cc', 'cb'].includes(align) ?
                    'center' : 'right'
            );
    }

    buildVAlign(align) {
        return ['lt', 'ct', 'rt'].includes(align) ?
            'top' : (
                ['lc', 'cc', 'rc'].includes(align) ?
                    'middle' : 'bottom'
            );
    }
}