import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class DeviceManagement {
    convertLoadDataToCurrentData(Load_Data) {
        return {
            Devices: {
                mobile_font_responsive: Load_Data.Devices.mobile.responsive,
                mobile_font_responsive_base_width:
                    Load_Data.Devices.mobile.font_base_window_width_size,
                tablet_font_responsive: Load_Data.Devices.tablet.responsive,
                tablet_font_responsive_base_width:
                    Load_Data.Devices.tablet.font_base_window_width_size,
                pc_font_responsive: Load_Data.Devices.desktop.responsive,
                pc_font_responsive_base_width:
                    Load_Data.Devices.desktop.font_base_window_width_size,
            },
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Devices {
                mobile {
                    id
                    responsive
                    font_base_window_width_size
                }
                tablet {
                    id
                    responsive
                    font_base_window_width_size
                }
                desktop {
                    id
                    responsive
                    font_base_window_width_size
                }
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Devices', [
                [
                    { type: 'text' },
                    { text: 'Automatic Text Scaling', style: 'subtitle' },
                ],
                [
                    { type: 'text' },
                    {
                        text:
                            'When this is enabled for a certain device, the letters of all texts <br>' +
                            'will be resized. The size of the letters will be determine by the <br>' +
                            'current window width, the window width on which the original letter size <br>' +
                            'is based and the given letter size for each specific segment of the texts.<br>',
                        style: 'normal_grey',
                    },
                ],
                [
                    { type: 'grid' },
                    {
                        Content: [
                            [
                                [
                                    { type: 'text', colspan: 2 },
                                    { text: 'Device setting', style: 'bold' },
                                ],
                                [
                                    { type: 'text' },
                                    { text: 'Base window width', style: 'bold' },
                                ],
                            ],
                            [
                                [
                                    {
                                        type: 'checkbox',
                                        id: 'mobile_font_responsive',
                                        label: 'Mobile',
                                    },
                                    {
                                        value: Current_Data.Devices.mobile_font_responsive,
                                        styling_type: 'switch',
                                    },
                                ],
                                [
                                    {
                                        type: Current_Data.Devices.mobile_font_responsive === 'true' ? 'number' : 'hidden',
                                        id: 'mobile_font_responsive_base_width',
                                    },
                                    {
                                        value: Current_Data.Devices.mobile_font_responsive_base_width,
                                        min_val: 0,
                                        max_val: 2000,
                                        unit: 'px',
                                    },
                                ],
                            ],
                            [
                                [
                                    {
                                        type: 'checkbox',
                                        id: 'tablet_font_responsive',
                                        label: 'Tablet',
                                    },
                                    {
                                        value: Current_Data.Devices.tablet_font_responsive,
                                        styling_type: 'switch',
                                    },
                                ],
                                [
                                    {
                                        type: Current_Data.Devices.tablet_font_responsive === 'true' ? 'number' : 'hidden',
                                        id: 'tablet_font_responsive_base_width',
                                    },
                                    {
                                        value: Current_Data.Devices.tablet_font_responsive_base_width,
                                        min_val: 0,
                                        max_val: 2000,
                                        unit: 'px',
                                    },
                                ],
                            ],
                            [
                                [
                                    {
                                        type: 'checkbox',
                                        id: 'pc_font_responsive',
                                        label: 'Desktop',
                                    },
                                    {
                                        value: Current_Data.Devices.pc_font_responsive,
                                        styling_type: 'switch',
                                    },
                                ],
                                [
                                    {
                                        type: Current_Data.Devices.pc_font_responsive === 'true' ? 'number' : 'hidden',
                                        id: 'pc_font_responsive_base_width',
                                    },
                                    {
                                        value: Current_Data.Devices.pc_font_responsive_base_width,
                                        min_val: 0,
                                        max_val: 2000,
                                        unit: 'px',
                                    },
                                ],
                            ],
                        ],
                    },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'save_devices',
                        Function_Data,
                        process_tag: 'Saving...'
                    }
                });
            }, 'Save'),
        ];
    }
}
