export const addRule = (selector, css) => {
    addRules([{
        selector,
        style: css
    }]);
};

export const addRules = rules => {
    const styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
    const sheet = styleEl.sheet;
    rules.forEach(rule => {
        sheet.insertRule(
            rule.selector + '{' + Object.keys(rule.style).map(p => p + ':' + rule.style[p]).join(';') + '}',
            sheet.cssRules.length
        );
    });
};