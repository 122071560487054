import moment from 'moment';
import React, { Component } from 'react';
import uniqid from 'uniqid';
import layer_store from '../api/layer_store';
import layout_store from '../api/layout_store';
import { getOffset, getSize, getViewport } from '../utils/browser';

export default class DatePicker extends Component {
    constructor(props) {
        super(props);

        const { format, value } = props;

        this.state = {
            pos: this.getPosition(getViewport())
        }

        this.format = format && this.isValidFormat(format) ? format : 'DD/MM/YYYY';
        this.WeekdaysSequence = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        this.used_value = value ? moment(value, this.format).toDate() : new Date();
        this.month = parseInt(this.used_value.getMonth(), 10);
        this.year = parseInt(this.used_value.getFullYear(), 10);
        this.myRef;
        this.EventListeners = [];
    }

    setRef(el) {
        this.myRef = el;
    }

    componentDidMount() {
        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];

        this.EventListeners = [
            [this.myRef, 'mousedown', e => e.isInsideDatePicker = true],
            [document, 'mousedown', e => {
                if (!e.isInsideDatePicker) {
                    layer_store.dispatch({
                        type: 'HIDE_DATEPICKER',
                    });
                }
            }]
        ];

        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
    }

    handleLayoutStoreChange() {
        const { action, viewport } = layout_store.getState();

        if (action === 'window_resize') {
            this.setState({
                pos: this.getPosition(viewport),
            });
        }
    }

    isValidFormat(format) {
        const f = format.toUpperCase();
        const Parts = f.replaceAll(/[/\-.]/g, '@').split('@');

        return Parts.filter(part => part === 'YYYY' || part === 'YY').length === 1 &&
            Parts.filter(part => part === 'MM').length === 1 &&
            Parts.filter(part => part === 'DD').length === 1 && (
                f.match(/^(YY|YYYY|MM|DD).(YY|YYYY|MM|DD).(YY|YYYY|MM|DD)$/g) ||
                f.match(/^(YY|YYYY|MM|DD)\/(YY|YYYY|MM|DD)\/(YY|YYYY|MM|DD)$/g) ||
                f.match(/^(YY|YYYY|MM|DD)-(YY|YYYY|MM|DD)-(YY|YYYY|MM|DD)$/g)
            );
    }

    getPosition(viewPort) {
        const { el } = this.props;
        const picker = {
            w: 190,
            h: 140
        };
        const offset = getOffset(el);
        const size = getSize(el);

        return {
            top: offset.t + size.h + picker.h > viewPort.t + viewPort.h ?
                viewPort.t + viewPort.h - picker.h - size.h :
                offset.t + size.h,
            left: offset.l + size.w + picker.w > viewPort.l + viewPort.w ?
                viewPort.l + viewPort.w - picker.w - size.w :
                offset.l + size.w
        };
    }

    writeDate(day) {
        const { onChange } = this.props;

        if (onChange) {
            onChange(day ? moment(new Date(this.year, this.month, day)).format(this.format) : '');
        }

        layer_store.dispatch({
            type: 'HIDE_DATEPICKER'
        });
    }

    getDaysOfMonth(monthNo, year) {
        return (year % 4) !== 0 || ((year % 100) === 0 && (year % 400) !== 0) ?
            [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][monthNo] :
            [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][monthNo];
    }

    getDayClass(vday, dayofweek) {
        const { value } = this.props;
        const cDate = new Date();
        const cDay = cDate.getDate();
        const cMonth = cDate.getMonth();
        const cYear = cDate.getFullYear();
        const sDate = this.used_value;
        const sDay = sDate.getDate();
        const sMonth = sDate.getMonth();
        const sYear = sDate.getFullYear();

        if (vday === sDay && this.month === sMonth && this.year === sYear && value !== '') {
            return 'selected';
        } else if (vday === cDay && this.month === cMonth && this.year === cYear) {
            return 'today';
        } else {
            const realdayofweek = (7 + dayofweek) % 7;
            for (let i = 0; i < 2; i++) {
                if (realdayofweek === 0 || realdayofweek === 6) {
                    return 'ss';
                }
            }
            return 'mtwtf';
        }
    }

    onClickDay(day) {
        return () => {
            this.writeDate(day);
        };
    }

    onClickPrevYear() {
        this.year = this.year - 1;
        this.forceUpdate();
    }

    onClickNextYear() {
        this.year = this.year + 1;
        this.forceUpdate();
    }

    onClickPrevMonth(prevMM, prevYYYY) {
        return () => {
            this.month = prevMM;
            this.year = prevYYYY;
            this.forceUpdate();
        };
    }

    onClickNextMonth(nextMM, nextYYYY) {
        return () => {
            this.month = nextMM;
            this.year = nextYYYY;
            this.forceUpdate();
        };
    }

    onClickRemove() {
        this.writeDate('');
        this.forceUpdate();
    }

    renderCalender() {
        const vDate = new Date();
        vDate.setDate(1);
        vDate.setMonth(this.month);
        vDate.setFullYear(this.year);

        const vFirstDay = vDate.getDay();
        let vDay = 1;
        let done = false;
        let isFirstWeek = true;
        let start_count;
        let calendar_days = [];

        for (let i = 0; i < 6; i++) {//never more then 6 weeks in a month
            let row = [];
            start_count = 0;

            if (isFirstWeek) {
                const prevm = this.month === 0 ? 11 : this.month - 1;
                const prevy = prevm === 11 ? this.year - 1 : this.year;
                const prevmontdays = this.getDaysOfMonth(prevm, prevy);

                for (let j = 0; j < vFirstDay; j++) {
                    row.push({
                        cls: 'prenextm',
                        val: (prevmontdays - vFirstDay + j + 1)
                    });
                }
                start_count = vFirstDay;
                isFirstWeek = false;
            }

            for (let k = start_count; k < 7; k++) {
                row.push({
                    id: 'cday' + vDay,
                    cls: 'date_day ' + this.getDayClass(vDay, k),
                    val: vDay,
                    onClick: this.onClickDay(vDay)
                });

                vDay++;

                if (vDay > this.getDaysOfMonth(this.month, this.year)) {
                    for (let m = 1; m < (7 - k); m++) {
                        row.push({
                            cls: 'prenextm',
                            val: m
                        });
                    }
                    done = true;
                    break;
                }
            }

            calendar_days.push(row);

            if (done) {
                break;
            }
        }

        return calendar_days;
    }

    render() {
        const { top, left } = this.state.pos;
        const prevMM = this.month === 0 ? 11 : this.month - 1;
        const prevYYYY = this.month === 0 ? this.year - 1 : this.year;
        const nextMM = this.month === 11 ? 0 : this.month + 1;
        const nextYYYY = this.month === 11 ? this.year + 1 : this.year;
        const Months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        return (
            <div
                ref={this.setRef.bind(this)}
                className="date-picker"
                style={{ left: left + 'px', top: top + 'px' }}
            >
                <table className="date_box" id="clayer">
                    <tbody>
                        <tr>
                            <td colSpan="7" className="df_top">
                                <table className="df_functions" width="100%">
                                    <tbody>
                                        <tr>
                                            <td
                                                className="df_button"
                                                id="date_prev_year_button"
                                                title={Months[this.month] + ' ' + (this.year - 1)}
                                                onClick={this.onClickPrevYear.bind(this)}
                                            >
                                                <div className="date_fields button_left"></div>
                                            </td>
                                            <td>{this.year}</td>
                                            <td
                                                className="df_button"
                                                id="date_next_year_button"
                                                title={Months[this.month] + ' ' + (this.year + 1)}
                                                onClick={this.onClickNextYear.bind(this)}
                                            >
                                                <div className="date_fields button_right"></div>
                                            </td>
                                            <td
                                                className="df_button"
                                                id="date_prev_month_button"
                                                title={Months[prevMM] + ' ' + prevYYYY}
                                                onClick={this.onClickPrevMonth(prevMM, prevYYYY).bind(this)}
                                            >
                                                <div className="date_fields button_left"></div>
                                            </td>
                                            <td>
                                                <div style={{ width: '62px' }}>
                                                    {Months[this.month]}
                                                </div>
                                            </td>
                                            <td
                                                className="df_button"
                                                id="date_next_month_button"
                                                title={Months[nextMM] + ' ' + nextYYYY}
                                                onClick={this.onClickNextMonth(nextMM, nextYYYY).bind(this)}
                                            >
                                                <div className="date_fields button_right"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            {this.WeekdaysSequence.map((day, index) =>
                                <td
                                    key={index + '_' + uniqid()}
                                    className="days"
                                    width="14%"
                                >{day}</td>
                            )}
                        </tr>
                        {this.renderCalender().map((row, index) =>
                            <tr key={index + '_' + uniqid()}>
                                {row.map(({ id, cls, val, onClick }, jndex) =>
                                    <td
                                        key={jndex + '_' + uniqid()}
                                        id={id}
                                        className={cls}
                                        onClick={onClick}
                                    >
                                        {val}
                                    </td>
                                )}
                            </tr>
                        )}
                        <tr>
                            <td
                                colSpan="7"
                                id="date_remove_button"
                                className="df_button"
                                title="Remove Current Date"
                                onClick={this.onClickRemove.bind(this)}
                            >
                                <div className="date_fields button_delete"></div>
                                <div className="date_fields text_delete">Remove</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}