import deepmerge from 'deepmerge';
import font_family_store from '../../api/font_family_store';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FontFamilySettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            deepmerge(
                Load_Data,
                {
                    Font_Family: {
                        id: '',
                        main_font_id: Load_Data.Available_Main_Fonts_List.length > 0 ? Load_Data.Available_Main_Fonts_List[0][0] : 0,
                        fallback_1_id: 0,
                        fallback_2_id: 0,
                        fallback_3_id: 0,
                        fallback_4_id: 0,
                        generic_type: 'sans-serif'
                    }
                }
            ) :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            `query DataQuery($urls: String!) {
                Available_Main_Fonts_List
                FallBack_Fonts_List
            }` :
            `query DataQuery($urls: String!) {
                Available_Main_Fonts_List
                Font_Family {
                    id
                    main_font_id
                    fallback_1_id
                    fallback_2_id
                    fallback_3_id
                    fallback_4_id
                    generic_type
                }
                FallBack_Fonts_List
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Font_Family', [
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length === 0 ? 'text' : 'hidden',
                        colspan: 2,
                        h_align: 'center'
                    },
                    { text: 'All available fonts are already used in a font family.', style: 'bold' }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'main_font_id',
                        label: 'Main font'
                    },
                    {
                        value: Current_Data.Font_Family.main_font_id,
                        Options: Current_Data.Available_Main_Fonts_List
                    }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'fallback_1_id',
                        label: 'Fallback 1'
                    },
                    {
                        value: Current_Data.Font_Family.fallback_1_id,
                        Options: Current_Data.FallBack_Fonts_List
                    }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'fallback_2_id',
                        label: 'Fallback 2'
                    },
                    {
                        value: Current_Data.Font_Family.fallback_2_id,
                        Options: Current_Data.FallBack_Fonts_List
                    }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'fallback_3_id',
                        label: 'Fallback 3'
                    },
                    {
                        value: Current_Data.Font_Family.fallback_3_id,
                        Options: Current_Data.FallBack_Fonts_List
                    }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'fallback_4_id',
                        label: 'Fallback 4'
                    },
                    {
                        value: Current_Data.Font_Family.fallback_4_id,
                        Options: Current_Data.FallBack_Fonts_List
                    }
                ],
                [
                    {
                        type: Current_Data.Available_Main_Fonts_List.length > 0 ? 'dropdown' : 'hidden',
                        id: 'generic_type',
                        label: 'Generic type'
                    },
                    {
                        value: Current_Data.Font_Family.generic_type,
                        Options: [
                            ['serif', 'Serif'],
                            ['sans-serif', 'Sans-Serif'],
                            ['cursive', 'Cursive'],
                            ['monospace', 'Monospace']
                        ]
                    }
                ]
            ]),
            Current_Data.Available_Main_Fonts_List.length > 0 ?
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_font_family' : 'save_font_family',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Adding...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                if (Result_Data) {
                                    const New_Font_Family = deepmerge(
                                        Current_Data.Font_Family,
                                        Result_Data
                                    );
                                    if (Action_Data.data_action_type === 'create') {
                                        font_family_store.dispatch({
                                            type: 'ADD',
                                            New_Font_Family: New_Font_Family
                                        });
                                    } else {
                                        font_family_store.dispatch({
                                            type: 'UPDATE',
                                            Updated_Font_Family: New_Font_Family
                                        });
                                    }
                                }
                            }
                        }
                    });
                }, Action_Data.data_action_type === 'create' ? 'Add' : 'Save') :
                getSingleSubmitSet(frm_id,)
        ];
    }
}