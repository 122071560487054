import BehaviorSettings from '../tools/form/behavior_settings';
import CustomFontManagement from '../tools/form/custom_font_management';
import CustomFontSettings from '../tools/form/custom_font_settings';
import DeviceManagement from '../tools/form/device_management';
import ElementManagement from '../tools/form/element_management';
import ElementStylingModules from '../tools/form/element_styling_modules';
import FileManagement from '../tools/form/file_management';
import FontFamilyManagement from '../tools/form/font_family_management';
import FontFamilySettings from '../tools/form/font_family_settings';
import FormColBoxTextSettings from '../tools/form/form_col_box_text_settings';
import FormColButtonSettings from '../tools/form/form_col_button_settings';
import FormColCheckboxSettings from '../tools/form/form_col_checkbox_settings';
import FormColColorFieldSettings from '../tools/form/form_col_color_field_settings';
import FormColDateFieldSettings from '../tools/form/form_col_date_field_settings';
import FormColDropdownSettings from '../tools/form/form_col_dropdown_settings';
import FormColFieldSettings from '../tools/form/form_col_field_settings';
import FormColGridSettings from '../tools/form/form_col_grid_settings';
import FormColHiddenSettings from '../tools/form/form_col_hidden_settings';
import FormColIconLinkSettings from '../tools/form/form_col_icon_link_settings';
import FormColImageButtonSettings from '../tools/form/form_col_image_button_settings';
import FormColImageSettings from '../tools/form/form_col_image_settings';
import FormColImageTextSelectSettings from '../tools/form/form_col_image_text_select_settings';
import FormColLineSettings from '../tools/form/form_col_line_settings';
import FormColRadioSelectSettings from '../tools/form/form_col_radio_select_settings';
import FormColRowSettings from '../tools/form/form_col_row_settings';
import FormColSectionSettings from '../tools/form/form_col_section_settings';
import FormColSliderFieldSettings from '../tools/form/form_col_slider_field_settings';
import FormColSpaceSettings from '../tools/form/form_col_space_settings';
import FormColTextSettings from '../tools/form/form_col_text_settings';
import FormColTextareaSettings from '../tools/form/form_col_textarea_settings';
import FormSettings from '../tools/form/form_settings';
import GradientManagement from '../tools/form/gradient_management';
import GridSettings from '../tools/form/grid_settings';
import IconSettings from '../tools/form/icon_settings';
import ImageManagement from '../tools/form/image_management';
import ImageSelect from '../tools/form/image_select';
import LanguageManagement from '../tools/form/language_management';
import LanguageSettings from '../tools/form/language_settings';
import Layout from '../tools/form/layout';
import MapMarkerSettings from '../tools/form/map_marker_settings';
import MapSettings from '../tools/form/map_settings';
import MediaProperties from '../tools/form/media_properties';
import MenuSettings from '../tools/form/menu_settings';
import MenuStructure from '../tools/form/menu_structure';
import MenuStyling from '../tools/form/menu_styling';
import OsFontManagement from '../tools/form/os_font_management';
import PageManagement from '../tools/form/page_management';
import PageSettings from '../tools/form/page_settings';
import PresetManagement from '../tools/form/preset_management';
import PresetSettings from '../tools/form/preset_settings';
import RemoveCustomFont from '../tools/form/remove_custom_font';
import RemoveElement from '../tools/form/remove_element';
import RemoveFile from '../tools/form/remove_file';
import RemoveFontFamily from '../tools/form/remove_font_family';
import RemoveGradient from '../tools/form/remove_gradient';
import RemoveLanguage from '../tools/form/remove_language';
import RemoveMapMarker from '../tools/form/remove_map_marker';
import RemoveOriginImage from '../tools/form/remove_origin_image';
import RemoveOriginVideo from '../tools/form/remove_origin_video';
import RemovePage from '../tools/form/remove_page';
import RemovePreset from '../tools/form/remove_preset';
import RemoveResolution from '../tools/form/remove_resolution';
import RemoveScopeGroup from '../tools/form/remove_scope_group';
import RemoveScrollZone from '../tools/form/remove_scroll_zone';
import RemoveSite from '../tools/form/remove_site';
import RemoveTextStyling from '../tools/form/remove_text_styling';
import ResolutionManagement from '../tools/form/resolution_management';
import ResolutionSettings from '../tools/form/resolution_settings';
import RowColumnSizeSettings from '../tools/form/row_column_size_settings';
import ScopeGroupManagement from '../tools/form/scope_group_management';
import ScopeGroupSettings from '../tools/form/scope_group_settings';
import ScopeSettings from '../tools/form/scope_settings';
import ScrollZoneManagement from '../tools/form/scroll_zone_management';
import ScrollZoneSettings from '../tools/form/scroll_zone_settings';
import SequenceSettings from '../tools/form/sequence_settings';
import SiteSettings from '../tools/form/site_settings';
import SlideshowSettings from '../tools/form/slideshow_settings';
import Background from '../tools/form/styling_module/background';
import Border from '../tools/form/styling_module/border';
import Corner from '../tools/form/styling_module/corner';
import ModForm from '../tools/form/styling_module/mod_form';
import Shadow from '../tools/form/styling_module/shadow';
import StylingModuleVariantSettings from '../tools/form/styling_module_variant_settings';
import TextEditor from '../tools/form/text_editor';
import TextSettings from '../tools/form/text_settings';
import TextStylingManagement from '../tools/form/text_styling_management';
import TwitterSettings from '../tools/form/twitter_settings';
import UserManagement from '../tools/form/user_management';
import UserSettings from '../tools/form/user_settings';
import VideoManagement from '../tools/form/video_management';
import VideoSelect from '../tools/form/video_select';
import VideoSettings from '../tools/form/video_settings';

//TODO: use dynamic import, to support different projects using their own custom forms.

export const getFormModel = (frm_name) => {
    if (frm_name === 'behavior_settings') {
        return new BehaviorSettings();
    }
    if (frm_name === 'custom_font_settings') {
        return new CustomFontSettings();
    }
    if (frm_name === 'custom_font_management') {
        return new CustomFontManagement();
    }
    if (frm_name === 'device_management') {
        return new DeviceManagement();
    }
    if (frm_name === 'element_management') {
        return new ElementManagement();
    }
    if (frm_name === 'element_styling_modules') {
        return new ElementStylingModules();
    }
    if (frm_name === 'file_management') {
        return new FileManagement();
    }
    if (frm_name === 'font_family_management') {
        return new FontFamilyManagement();
    }
    if (frm_name === 'font_family_settings') {
        return new FontFamilySettings();
    }
    if (frm_name === 'form_col_box_text_settings') {
        return new FormColBoxTextSettings();
    }
    if (frm_name === 'form_col_button_settings') {
        return new FormColButtonSettings();
    }
    if (frm_name === 'form_col_checkbox_settings') {
        return new FormColCheckboxSettings();
    }
    if (frm_name === 'form_col_color_field_settings') {
        return new FormColColorFieldSettings();
    }
    if (frm_name === 'form_col_date_field_settings') {
        return new FormColDateFieldSettings();
    }
    if (frm_name === 'form_col_dropdown_settings') {
        return new FormColDropdownSettings();
    }
    if (frm_name === 'form_col_field_settings') {
        return new FormColFieldSettings();
    }
    if (frm_name === 'form_col_grid_settings') {
        return new FormColGridSettings();
    }
    if (frm_name === 'form_col_hidden_settings') {
        return new FormColHiddenSettings();
    }
    if (frm_name === 'form_col_icon_link_settings') {
        return new FormColIconLinkSettings();
    }
    if (frm_name === 'form_col_image_button_settings') {
        return new FormColImageButtonSettings();
    }
    if (frm_name === 'form_col_image_settings') {
        return new FormColImageSettings();
    }
    if (frm_name === 'form_col_image_text_select_settings') {
        return new FormColImageTextSelectSettings();
    }
    if (frm_name === 'form_col_line_settings') {
        return new FormColLineSettings();
    }
    if (frm_name === 'form_col_radio_select_settings') {
        return new FormColRadioSelectSettings();
    }
    if (frm_name === 'form_col_row_settings') {
        return new FormColRowSettings();
    }
    if (frm_name === 'form_col_section_settings') {
        return new FormColSectionSettings();
    }
    if (frm_name === 'form_col_slider_field_settings') {
        return new FormColSliderFieldSettings();
    }
    if (frm_name === 'form_col_space_settings') {
        return new FormColSpaceSettings();
    }
    if (frm_name === 'form_col_text_settings') {
        return new FormColTextSettings();
    }
    if (frm_name === 'form_col_textarea_settings') {
        return new FormColTextareaSettings();
    }
    if (frm_name === 'form_settings') {
        return new FormSettings();
    }
    if (frm_name === 'gradient_management') {
        return new GradientManagement();
    }
    if (frm_name === 'grid_settings') {
        return new GridSettings();
    }
    if (frm_name === 'icon_settings') {
        return new IconSettings();
    }
    if (frm_name === 'media_properties') {
        return new MediaProperties();
    }
    if (frm_name === 'image_management') {
        return new ImageManagement();
    }
    if (frm_name === 'image_select') {
        return new ImageSelect();
    }
    if (frm_name === 'language_management') {
        return new LanguageManagement();
    }
    if (frm_name === 'language_settings') {
        return new LanguageSettings();
    }
    if (frm_name === 'layout_settings') {
        return new Layout();
    }
    if (frm_name === 'map_marker_settings') {
        return new MapMarkerSettings();
    }
    if (frm_name === 'map_settings') {
        return new MapSettings();
    }
    if (frm_name === 'menu_settings') {
        return new MenuSettings();
    }
    if (frm_name === 'menu_structure') {
        return new MenuStructure();
    }
    if (frm_name === 'menu_styling') {
        return new MenuStyling();
    }
    if (frm_name === 'os_font_management') {
        return new OsFontManagement();
    }
    if (frm_name === 'page_management') {
        return new PageManagement();
    }
    if (frm_name === 'page_settings') {
        return new PageSettings();
    }
    if (frm_name === 'preset_management') {
        return new PresetManagement();
    }
    if (frm_name === 'preset_settings') {
        return new PresetSettings();
    }
    if (frm_name === 'remove_custom_font') {
        return new RemoveCustomFont();
    }
    if (frm_name === 'remove_element') {
        return new RemoveElement();
    }
    if (frm_name === 'remove_file') {
        return new RemoveFile();
    }
    if (frm_name === 'remove_font_family') {
        return new RemoveFontFamily();
    }
    if (frm_name === 'remove_gradient') {
        return new RemoveGradient();
    }
    if (frm_name === 'remove_language') {
        return new RemoveLanguage();
    }
    if (frm_name === 'remove_map_marker') {
        return new RemoveMapMarker();
    }
    if (frm_name === 'remove_origin_image') {
        return new RemoveOriginImage();
    }
    if (frm_name === 'remove_origin_video') {
        return new RemoveOriginVideo();
    }
    if (frm_name === 'remove_page') {
        return new RemovePage();
    }
    if (frm_name === 'remove_preset') {
        return new RemovePreset();
    }
    if (frm_name === 'remove_resolution') {
        return new RemoveResolution();
    }
    if (frm_name === 'remove_scope_group') {
        return new RemoveScopeGroup();
    }
    if (frm_name === 'remove_scroll_zone') {
        return new RemoveScrollZone();
    }
    if (frm_name === 'remove_site') {
        return new RemoveSite();
    }
    if (frm_name === 'remove_text_styling') {
        return new RemoveTextStyling();
    }
    if (frm_name === 'resolution_management') {
        return new ResolutionManagement();
    }
    if (frm_name === 'resolution_settings') {
        return new ResolutionSettings();
    }
    if (frm_name === 'row_column_size_settings') {
        return new RowColumnSizeSettings();
    }
    if (frm_name === 'scope_group_management') {
        return new ScopeGroupManagement();
    }
    if (frm_name === 'scope_group_settings') {
        return new ScopeGroupSettings();
    }
    if (frm_name === 'scope_settings') {
        return new ScopeSettings();
    }
    if (frm_name === 'scroll_zone_management') {
        return new ScrollZoneManagement();
    }
    if (frm_name === 'scroll_zone_settings') {
        return new ScrollZoneSettings();
    }
    if (frm_name === 'sequence_settings') {
        return new SequenceSettings();
    }
    if (frm_name === 'site_settings') {
        return new SiteSettings();
    }
    if (frm_name === 'slideshow_settings') {
        return new SlideshowSettings();
    }
    if (frm_name === 'styling_module_variant_settings') {
        return new StylingModuleVariantSettings();
    }
    if (frm_name === 'text_editor') {
        return new TextEditor();
    }
    if (frm_name === 'text_settings') {
        return new TextSettings();
    }
    if (frm_name === 'text_styling_management') {
        return new TextStylingManagement();
    }
    if (frm_name === 'twitter_settings') {
        return new TwitterSettings();
    }
    if (frm_name === 'user_management') {
        return new UserManagement();
    }
    if (frm_name === 'user_settings') {
        return new UserSettings();
    }
    if (frm_name === 'video_management') {
        return new VideoManagement();
    }
    if (frm_name === 'video_select') {
        return new VideoSelect();
    }
    if (frm_name === 'video_settings') {
        return new VideoSettings();
    }

    if (frm_name === 'background_settings') {
        return new ModForm(new Background(), 'background');
    }
    if (frm_name === 'border_settings') {
        return new ModForm(new Border(), 'border');
    }
    if (frm_name === 'corner_settings') {
        return new ModForm(new Corner(), 'corner');
    }
    if (frm_name === 'shadow_settings') {
        return new ModForm(new Shadow(), 'shadow');
    }

    return null;
};
