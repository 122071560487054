import layer_store from '../api/layer_store';
import { getViewport } from './browser';
import { buildURL } from './path';

//TODO: include submitactions like those used in form-buttons

export const buildEvents = (Behavior, Navigation_Data, navigate) => {
    const { default_language_type, language_type, device_type, base_url, site_url } = Navigation_Data;
    let href = '';
    let tooltip = '';
    let new_tab = false;
    let onClick = null;

    if (Behavior) {
        const { on_click, on_hover, src, Page_URLs, link_opens_in } = Behavior;
        if (on_hover === 'show_text') {
            tooltip = Behavior.tooltip;
        }

        if (on_click === 'go_to_top') {
            onClick = goToTop;
        } else if (on_click === 'go_window_height_up') {
            onClick = goWindowHeightUp;
        } else if (on_click === 'go_window_height_down') {
            onClick = goWindowHeightDown;
        } else if (on_click === 'open_mobile_menu') {
            onClick = () => {
                layer_store.dispatch({
                    type: 'SHOW_MOBILE_MENU',
                    options: {
                        Behavior: Behavior
                    }
                });
            };
        } else if (on_click === 'open_panel') {
            onClick = openPanel(Behavior);
        } else if (on_click === 'open_file') {
            //TODO
            /*href = buildURL(
                default_language_type, language_type, device_type, base_url,
                site_url, Page_URLs
            );
            const link = Function_Data.base_url +
                'projects/' +
                Function_Data.project +
                '/files/' +
                (Function_Data.use_service ? 'service' : 'user') + '/' +
                Function_Data.site_id + '/docs/' +
                File.id + '.' + File.ext;*/
        } else if (on_click === 'download_file') {
            //TODO
        } else if (on_click === 'switch_language') {
            onClick = switchLanguage(Behavior, Navigation_Data, navigate);
        } else if (on_click === 'external_link' || on_click === 'link') {//????
            href = src;
            new_tab = link_opens_in === 'new';
        } else if (on_click === 'internal_link') {
            new_tab = link_opens_in === 'new';
            href = buildURL(
                default_language_type, language_type, device_type, base_url,
                site_url, Page_URLs
            );
            onClick = () => {
                navigate(
                    default_language_type, language_type, device_type, base_url,
                    site_url, Behavior.Page_URLs
                );
            };
        }
    }

    return {
        href: href,
        tooltip: tooltip,
        new_tab: new_tab,
        onClick: onClick
    };
};

const animateGoTo = (fromLeft, fromTop, toLeft, toTop, time) => {
    const
        start = new Date().getTime(),
        timer = setInterval(() => {
            const step = Math.min(1, (new Date().getTime() - start) / time);
            window.scrollTo(
                (fromLeft + step * (toLeft - fromLeft)),
                (fromTop + step * (toTop - fromTop))
            );
            if (step == 1) {
                clearInterval(timer);
            }
        }, 25);
    window.scrollTo(fromLeft, fromTop);
};

const goToTop = () => {
    const viewport = getViewport();
    animateGoTo(viewport.l, viewport.t, viewport.l, 0, 600);
};

const goWindowHeightUp = () => {
    const viewport = getViewport();
    animateGoTo(viewport.l, viewport.t, viewport.l, viewport.t - viewport.h, 600);
};

const goWindowHeightDown = () => {
    const viewport = getViewport();
    animateGoTo(viewport.l, viewport.t, viewport.l, viewport.t + viewport.h, 600);
};

const openPanel = (Behavior) => {
    const { panel_id } = Behavior;//TODO: CHECK if panel_id is present!!!!
    const Function_Data = {};//TODO: retrieve from store
    const Action_Data = {//get panel action_data from DB by panel_id
        access: 'public',
        panel_type: 'custom_panel',
        frm_name: 'custom_form',
        panel_id: panel_id,
        parent_panel_type: null,
        data_action_type: ''
    };

    return () => layer_store.dispatch({
        type: 'OPEN_PANEL',
        panel_data: { Function_Data, Action_Data },
    });
};

const switchLanguage = (Behavior, Navigation_Data, navigate) => {
    return () => {
        navigate(
            Navigation_Data.default_language_type,
            Behavior.language_type,
            Navigation_Data.device_type,
            Navigation_Data.base_url,
            Navigation_Data.site_url,
            Navigation_Data.Page_URLs
        );
    };
};