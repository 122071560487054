import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class MenuSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            deepmerge(
                Load_Data,
                {
                    Menu: {
                        navigation_schema_id: 0,
                        depth: 2,
                        direction: 'hor',
                        letter_format: 'first_uppercase'
                    }
                }
            ) :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            `query DataQuery($urls: String!) {
                Navigation_Schemas
            }` :
            `query DataQuery($urls: String!) {
                Menu {
                    navigation_schema_id
                    depth
                    direction: String
                    letter_format: String
                }
                Navigation_Schemas
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Menu', [
                [
                    { type: 'dropdown', id: 'navigation_schema_id', label: 'Navigation schema' },
                    {
                        value: Current_Data.Menu && Current_Data.Navigation_Schemas.length > 0 ?
                            (Current_Data.Menu.navigation_schema_id ? Current_Data.Menu.navigation_schema_id :
                                Current_Data.Navigation_Schemas[0].id) : 0,
                        Options: Current_Data.Navigation_Schemas
                            .map(Navigation_Schema => [Navigation_Schema.id, Navigation_Schema.name])
                    }
                ],
                [
                    {
                        type: Action_Data.data_action_type === 'create' ? 'number' : 'hidden',
                        id: 'depth',
                        label: 'Depth'
                    },
                    { value: Current_Data.Menu.depth, min_val: 1, max_val: 10 }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Directions', style: 'bold' }
                ],
                [
                    { type: 'image_picker', id: 'direction', label: 'Primary Direction' },
                    {
                        value: Current_Data.Menu.direction,
                        Options: [
                            { label: 'Horizontal', filename: 'projects/rootflex/img/form/menu_direction.png', value: 'hor', left: 0 },
                            { label: 'Vertical', filename: 'projects/rootflex/img/form/menu_direction.png', value: 'ver', left: 55 }
                        ],
                        width: 54,
                        height: 46
                    }
                ],
                [
                    { type: 'radio', id: 'letter_format', label: 'Letter format' },
                    {
                        value: Current_Data.Menu.letter_format,
                        Options: [
                            ['all_uppercase', 'UPPERCASE'],
                            ['first_uppercase', 'Normal'],
                            ['all_lowercase', 'lowercase']
                        ]
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_menu_settings',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save'
            )
        ];
    }
}