import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class BehaviorSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const { Behavior, Pages, Panels, Files, Menu_Data, Navigation_Schemas, Languages } = Load_Data;
        const id = Behavior.id || '';
        const on_hover = Behavior.on_hover || 'none';
        const on_click = Behavior.on_click || 'none';
        const link_opens_in = Behavior.link_opens_in || 'current';
        const src = Behavior.src || '';
        const page_id = Behavior && Pages.length > 0 ?
            (Behavior.page_id ? Behavior.page_id : Pages[0].page_id) : 0;
        const panel_id = Behavior && Panels.length > 0 ?
            (Behavior.panel_id ? Behavior.panel_id : Panels[0].id) : 0;
        const file_id = Behavior && Files.length > 0 ?
            (Behavior.file_id ? Behavior.file_id : Files[0].id) : 0;
        const navigation_schema_id = Menu_Data && Navigation_Schemas.length > 0 ?
            (Menu_Data.navigation_schema_id ? Menu_Data.navigation_schema_id : Navigation_Schemas[0].id) : 0;
        const language_id = Behavior && Languages.length > 0 ?
            (Behavior.language_id ? Behavior.language_id : Languages[0].id) : 0;
        const tooltip = Behavior.tooltip || '';

        return deepmerge(Load_Data, {
            Behavior: {
                id,
                on_hover,
                on_click,
                link_opens_in,
                src,
                panel_id,
                navigation_schema_id,
                page_id,
                file_id,
                language_id,
                tooltip
            },
            mod_type: 'behavior'
        });
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create'
            ? `query DataQuery($urls: String!) {
                Pages
                Files
            }`
            : `query DataQuery($urls: String!) {
                Behavior {
                    page_id
                    panel_id
                    file_id
                    navigation_schema_id
                    language_id
                    on_click
                    src
                    link_opens_in
                    on_hover
                    tooltip
                }
                Pages {
                    base_url_on_title
                    id
                    language_id
                    page_id
                    page_url
                    parent_page_id
                    same_title_all_languages
                    site_id
                    status
                    template
                    template_id
                    title
                    type
                }
                Files
                Languages {
                    id
                    name
                    default
                    language_type
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Behavior', [
                [{ type: 'text' }, { text: 'Behavior', style: 'subtitle' }],
                [{ type: 'text' }, { text: 'Mouse Click', style: 'bold' }],
                [
                    { type: 'dropdown', id: 'on_click', label: ' ', colspan: 2 },
                    {
                        value: Current_Data.Behavior.on_click,
                        Options: [
                            ['none', 'Do nothing'],
                            ['link', 'Open external URL'],
                        ].concat(Current_Data.Pages.length > 0 ?
                            [['internal_link', 'Go to internal page']] :
                            []
                        ).concat(Current_Data.Panels.length > 0 ?
                            [['open_panel', 'Open panel']] :
                            []
                        ).concat([
                            ['go_to_top', 'Go to top of window'],
                            ['go_window_height_up', 'Scroll "window height" up'],
                            ['go_window_height_down', 'Scroll "window height" down'],
                        ]).concat(Current_Data.Navigation_Schemas.length > 0 ?
                            [['open_mobile_menu', 'Open menu (mobile)']] :
                            []
                        ).concat(Current_Data.Languages.length > 0 ?
                            [['switch_language', 'Switch langauge']] :
                            []
                        ).concat(Current_Data.Files.length > 0 ?
                            [
                                ['download_file', 'Download file'],
                                ['open_file', 'Open file']
                            ] :
                            []
                        ).concat(
                            Action_Data.el_type === 'image' ?
                                [['enlarge', 'Zoom in on image']] :
                                []
                        ),
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'link' ? 'field' : 'hidden',
                        id: 'src',
                        label: 'URL',
                    },
                    { value: Current_Data.Behavior.src, max: 1 },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'internal_link' ? 'dropdown' : 'hidden',
                        id: 'page_id',
                        label: 'Page',
                    },
                    {
                        value: Current_Data.Behavior.page_id,
                        Options: Current_Data.Pages.map(Page => [
                            Page.page_id,
                            Page.title ? Page.title : '(Home Page)',
                        ]
                        ),
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'open_panel' ? 'dropdown' : 'hidden',
                        id: 'panel_id',
                        label: 'Panel',
                    },
                    {
                        value: Current_Data.Behavior.panel_id,
                        Options: Current_Data.Panels.map(Panel => [Panel.id, Panel.name]),
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'download_file' ||
                            Current_Data.Behavior.on_click === 'open_file' ?
                            'dropdown' : 'hidden',
                        id: 'file_id',
                        label: 'File',
                    },
                    {
                        value: Current_Data.Behavior.file_id,
                        Options: Current_Data.Files.map(File => [
                            File.id,
                            File.name,
                        ]),
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'open_file' ||
                            Current_Data.Behavior.on_click === 'link' ||
                            Current_Data.Behavior.on_click === 'internal_link' ?
                            'dropdown' : 'hidden',
                        id: 'link_opens_in',
                        label: 'Open in',
                    },
                    {
                        value: Current_Data.Behavior.link_opens_in,
                        Options: [['current', 'Current Window'], ['new', 'New Window']],
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'open_mobile_menu' ? 'dropdown' : 'hidden',
                        id: 'navigation_schema_id',
                        label: 'Navigation schema',
                    },
                    {
                        value: Current_Data.Behavior.navigation_schema_id,
                        Options: Current_Data.Navigation_Schemas.map(
                            Navigation_Schema => [
                                Navigation_Schema.id,
                                Navigation_Schema.name,
                            ]
                        ),
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_click === 'switch_language' ? 'dropdown' : 'hidden',
                        id: 'language_id',
                        label: 'Language',
                    },
                    {
                        value: Current_Data.Behavior.language_id,
                        Options: Current_Data.Languages.map(Language => [
                            Language.id,
                            Language.name,
                        ]),
                    },
                ],
                [
                    { type: 'text', id: 'on_hover_label' },
                    { text: 'Mouse hover', style: 'bold' },
                ],
                [
                    { type: 'dropdown', id: 'on_hover', label: ' ', colspan: 2 },
                    {
                        value: Current_Data.Behavior.on_hover,
                        Options: [
                            ['none', 'Do nothing'],
                            [
                                'show_text',
                                'Show text',
                            ] /*,
                                ['link', 'Open external URL'],
                                ['internal_link', 'Go to internal page'],
                                ['open_panel', 'Open panel'],
                                ['go_to_top', 'Go to top of window'],
                                ['go_window_height_up', 'Scroll "window height" up'],
                                ['go_window_height_down', 'Scroll "window height" down'],
                                ['open_mobile_menu', 'Open menu (mobile)'],
                                ['switch_language', 'Switch langauge'],
                                ['download_file', 'Download file'],
                                ['open_file', 'Open file']*/,
                        ] /*.concat(
                                Action_Data.el_type === 'image'
                                    ? [['enlarge', 'Zoom in on image']]
                                    : []
                            )*/, // TODO: allow all these actions for hover behavior
                    },
                ],
                [
                    {
                        type: Current_Data.Behavior.on_hover === 'show_text' ? 'field' : 'hidden',
                        id: 'tooltip',
                        label: 'Hover text',
                    },
                    { value: Current_Data.Behavior.tooltip, max: 1 },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    updateActionData({
                        access: 'authorized',
                        frm_name: {
                            box: 'element_styling_modules',
                            image: 'image_select',
                            video: 'video_select',
                            icon: 'icon_settings',
                        }[Action_Data.el_type],
                        amount: ['image', 'video'].includes(Action_Data.el_type) ? 15 : undefined,
                        offset: ['image', 'video'].includes(Action_Data.el_type) ? 0 : undefined,
                    });
                } else if (Action_Data.panel_type === 'text_editor_behavior_panel') {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'set_text_behavior',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                /*$('#text_' + Action_Data.el_idx)
                                    .trigger(
                                        'set_link',
                                        Result_Data
                                    );*/
                            }
                        }
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_behavior',
                            Function_Data,
                            process_tag: 'Saving...'
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' :
                (Action_Data.panel_type === 'text_editor_behavior_panel' ? 'Set' : 'Save')),
        ];
    }
}
