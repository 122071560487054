import { createStore } from 'redux';

const initialState = {
    action: '',
    data: {},
};

const store = createStore((state = initialState, action) => {
    state.action = action.type;

    switch (action.type) {
        case 'CACHE_MAIN':
            state.data = action.data;
            return state;
        default:
            return state;
    }
});

export default store;
