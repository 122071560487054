import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import gradient_store from '../../api/gradient_store';
import layer_store from '../../api/layer_store';
import { getImageURL, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findArray, findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class GradientManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [gradient_store.subscribe(this.handleGradientStoreChange(changeCurrentData, getCurrentData))];
    }

    handleGradientStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = gradient_store.getState();
            if (state.action === 'remove') {
                const Current_Data = getCurrentData();
                let Changed_Gradients = clone(Current_Data.Gradients);
                const index = findIndexArray(
                    Changed_Gradients,
                    Gradient => parseInt(Gradient.idx, 10),
                    parseInt(state.removed_idx, 10)
                );

                Changed_Gradients.splice(index, 1);
                changeCurrentData(this.getDefaultData(Changed_Gradients, null));
            }
        };
    }

    convertLoadDataToCurrentData(Load_Data) {
        return this.getDefaultData(Load_Data.Gradients, null);
    }

    getDefaultData(Gradients, given_idx) {
        let status = 'creating';
        let Active_Gradient = {
            idx: '',
            name: '',
            direction: 'hor',
            Points: [
                {
                    color: 'ffffff',
                    opacity: '100',
                    coordinate: '0'
                },
                {
                    color: 'ffffff',
                    opacity: '100',
                    coordinate: '100'
                }
            ]
        };

        if (Gradients.length > 0) {
            status = 'browsing';
            const Found_Gradient = findArray(
                Gradients,
                Gradient => parseInt(Gradient.idx, 10) === parseInt(given_idx, 10)
            );
            Active_Gradient = clone(Found_Gradient ? Found_Gradient : Gradients[0]);
        }

        return {
            Gradients,
            status,
            Active_Gradient,
            Tools_Gradient: {
                new_gradient: status === 'creating' ? 'true' : 'false',
                gradient_idx: parseInt(Active_Gradient.idx, 10),
                name: Active_Gradient.name
            }
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Gradients
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            [
                { type: 'section', id: 'Tools_Gradient' },
                {
                    Settings: {
                        bg_color: 'f0f0f0',
                        border_bottom: [1, 'B1B1B1'],
                        padding_left: 15,
                        padding_right: 15,
                        padding_top: 10,
                        padding_bottom: 10
                    },
                    Content: [
                        [
                            {
                                type: Current_Data.status === 'browsing' ? 'row' : 'hidden',
                                id: 'show_gradient_view'
                            },
                            {
                                Content: [
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'add_gradient',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'open_add_gradient_panel',
                                            button_style: [92, 33, 2],
                                            text: 'Create',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 32, 32, 125],
                                            onClick: () => {
                                                changeCurrentData({
                                                    Active_Gradient: {
                                                        name: '',
                                                        idx: ''
                                                    },
                                                    Tools_Gradient: {
                                                        gradient_idx: '',
                                                        new_gradient: 'true',
                                                        name: ''
                                                    },
                                                    status: 'creating'
                                                });
                                            }
                                        }
                                    ],
                                    [
                                        { type: 'dropdown', id: 'gradient_idx' },
                                        {
                                            value: Current_Data.Tools_Gradient ? Current_Data.Tools_Gradient.gradient_idx : null,
                                            Options: Current_Data.Gradients ?
                                                Current_Data.Gradients.map(Gradient => [
                                                    Gradient.idx,
                                                    Gradient.name
                                                ]) :
                                                [],
                                            margin_top: 8,
                                            onChange: value => {
                                                const Active_Gradient = clone(findArray(
                                                    Current_Data.Gradients,
                                                    Gradient => parseInt(Gradient.idx, 10) === parseInt(value, 10)
                                                ));
                                                return {
                                                    Active_Gradient,
                                                    Tools_Gradient: {
                                                        gradient_idx: Active_Gradient.idx,
                                                        name: Active_Gradient.name
                                                    }
                                                };
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'edit_gradient',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'enter_gradient_editing_mode',
                                            button_style: [79, 33, 2],
                                            text: 'Edit',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 33, 32, 166],
                                            onClick: () => {
                                                changeCurrentData({
                                                    status: 'editing'
                                                });
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'delete_gradient',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'open_delete_gradient_panel',
                                            button_style: [90, 33, 2],
                                            text: 'Delete',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 32, 32, 207],
                                            onClick: () => {
                                                layer_store.dispatch({
                                                    type: 'OPEN_PANEL',
                                                    panel_data: {
                                                        Function_Data,
                                                        Action_Data: deepmerge(
                                                            Action_Data,
                                                            {
                                                                access: 'authorized',
                                                                panel_type: 'remove_gradient_panel',
                                                                frm_name: 'remove_gradient',
                                                                gradient_idx: parseInt(Current_Data.Active_Gradient.idx, 10),
                                                                parent_frm_id: frm_id,
                                                                data_action_type: 'remove'
                                                            }
                                                        )
                                                    },
                                                });
                                            }
                                        }
                                    ]
                                ]
                            }
                        ],
                        [
                            {
                                type: Current_Data.status === 'creating' || Current_Data.status === 'editing' ? 'row' : 'hidden',
                                id: 'edit_gradient_view'
                            },
                            {
                                Content: [
                                    [
                                        { type: 'field', id: 'name', label: 'Gradient name' },
                                        {
                                            value: Current_Data.Tools_Gradient ? Current_Data.Tools_Gradient.name : null,
                                            onChange: value => ({
                                                Active_Gradient: {
                                                    name: value
                                                }
                                            })
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'save_gradient',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'save_gradient',
                                            button_style: [77, 33, 2],
                                            text: 'Save',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 32, 32, 249],
                                            onClick: () => {
                                                //TODO: check for respect of the old Forms_Post_Data
                                                /*
                                                    [
                                                        {
                                                            data: [{
                                                                name: 'Tools_Gradient[gradient_idx]',
                                                                value: parseInt(Current_Data.Active_Gradient.idx, 10)
                                                            }]
                                                        }
                                                    ],
                                                 */
                                                form_data_store.dispatch({
                                                    type: 'SUBMIT',
                                                    submitData: {
                                                        frm_id,
                                                        submit_action: 'save_gradient',
                                                        Function_Data,
                                                        process_tag: 'Saving...',
                                                        onSubmitted: Result_Data => {
                                                            const new_idx = parseInt(Result_Data.gradient_idx, 10);
                                                            const New_Gradient = {
                                                                site_id: Function_Data.site_id,
                                                                name: Result_Data.name,
                                                                idx: new_idx,
                                                                direction: Current_Data.Active_Gradient ? Current_Data.Active_Gradient.direction : 'hor',
                                                                Points: clone(Current_Data.Active_Gradient.Points)
                                                            };

                                                            changeCurrentData({
                                                                Gradients: Current_Data.status === 'creating' ?
                                                                    Current_Data.Gradients.concat(New_Gradient) :
                                                                    Current_Data.Gradients.map(Gradient =>
                                                                        parseInt(Gradient.idx, 10) === new_idx ?
                                                                            New_Gradient :
                                                                            Gradient
                                                                    ),
                                                                Active_Gradient: New_Gradient,
                                                                status: 'browsing',
                                                                Tools_Gradient: {
                                                                    new_gradient: 'false',
                                                                    gradient_idx: new_idx,
                                                                    name: New_Gradient.name
                                                                },
                                                            });
                                                        },
                                                        isPanelRequiredToStayOpen: true
                                                    }
                                                });
                                            }
                                        }
                                    ],
                                    [
                                        {
                                            type: 'button_image',
                                            id: 'cancel_gradient',
                                            h_align: 'right',
                                            v_align: 'center',
                                            padding_left: 5
                                        },
                                        {
                                            name: 'exit_gradient_editing_mode',
                                            button_style: [88, 33, 2],
                                            text: 'Cancel',
                                            img_url: getImageURL('rootflex', 'custom_preset_all'),
                                            img_style: [0, 29, 32, 290],
                                            onClick: () => {
                                                changeCurrentData(
                                                    this.getDefaultData(
                                                        Current_Data.Gradients, parseInt(Current_Data.Active_Gradient.idx, 10)
                                                    )
                                                );
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    ]
                }
            ],
            wrapSectionSubs(
                'Active_Gradient',
                [
                    [
                        { type: 'image_picker', id: 'direction', label: 'Direction' },
                        {
                            value: Current_Data.Active_Gradient ? Current_Data.Active_Gradient.direction : 'hor',
                            Options: [
                                { filename: 'projects/rootflex/img/form/gradient_direction.png', value: 'hor', left: 0 },
                                { filename: 'projects/rootflex/img/form/gradient_direction.png', value: 'ver', left: 62 },
                                { filename: 'projects/rootflex/img/form/gradient_direction.png', value: 'dia1', left: 124 },
                                { filename: 'projects/rootflex/img/form/gradient_direction.png', value: 'dia2', left: 183 }
                            ],
                            width: 61,
                            height: 61,
                            column_amount_max: 4,
                        }
                    ],
                    [
                        { type: 'gradient_editor', id: 'gradient_points', label: 'Points' },
                        {
                            Points: Current_Data.Active_Gradient ? Current_Data.Active_Gradient.Points : [],
                            gradient_idx: Current_Data.Active_Gradient ? parseInt(Current_Data.Active_Gradient.idx, 10) : null
                        }
                    ],
                    [
                        {
                            type: 'gradient',
                            id: 'gradient_preview',
                            label: ' ',
                            h_align: 'center',
                            padding_top: 3,
                            padding_bottom: 3
                        },
                        {
                            Data: Current_Data.Active_Gradient,
                            width: 311,
                            height: 311,
                            show_transparency_background: true
                        }
                    ]
                ],
                {},
                Current_Data.status === 'browsing',
                'You cannot edit here!\nTo edit the gradient, click the button "Edit".\n' +
                'To create a gradient with the current settings as a starting point, click "Create".'
            ),
            getSingleSubmitSet(frm_id,)
        ];
    }
}
