import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColBoxTextSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return {
            Form_Col_Box_Text: Action_Data.data_action_type === 'create' ? {
                class: '',
                style: '',
                text_class: '',
                text_style: '',
                name: '',
                colspan: 2,
                label: '',
                text: ''
            } : clone(Load_Data.Form_Col_Box_Text_Data)
        };
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Box_Text_Data {
                    class
                    style
                    text_class
                    text_style
                    name
                    colspan
                    label
                    text
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Box_Text', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Box_Text.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Box_Text.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Box_Text.label, max: 2 }
                ],
                [
                    { type: 'field', id: 'text', label: 'Text' },
                    { value: Current_Data.Form_Col_Box_Text.text, max: 2 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_box_text_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}