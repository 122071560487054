import { createStore } from 'redux';

const initialState = {
    action: '',
    removed_id: '',
    New_Scroll_Zone: {},
    Updated_Scroll_Zone: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD':
            state.New_Scroll_Zone = action.New_Scroll_Zone;
            return state;
        case 'UPDATE':
            state.Updated_Scroll_Zone = action.Updated_Scroll_Zone;
            return state;
        case 'REMOVE':
            state.removed_id = action.removed_id;
            return state;
        default:
            return state;
    }
});

export default store;