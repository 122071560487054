import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import { getSize } from '../../utils/browser';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class NumberEl extends Component {
    constructor(props) {
        super(props);
        this.state = { hasFocus: false };
        this.myRefUnit;
    }
    componentDidMount() {
        if (this.el) {
            this.el.addEventListener('wheel', (e) => { e.preventDefault(); }, { passive: false });
        }
    }

    componentWillUnmount() {
        if (this.el) {
            this.el.removeEventListener('wheel', (e) => { e.preventDefault(); }, { passive: false });
        }
    }

    setRefUnit(el) {
        this.myRefUnit = el;
    }

    onBlur() {
        this.setState({ hasFocus: false });
    }

    onFocus() {
        this.setState({ hasFocus: true });
    }

    onChangeByFieldInput(e) {
        this.onChange(e.target.value);
    }

    onChange(raw_value) {
        const { Data_Groups, fld_id, El_Data, changeCurrentData } = this.props;
        const value = this.tryCheckMinMax(raw_value);
        const New_Data = El_Data.onChange ? El_Data.onChange(value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    onKeyUp(e) {
        const { value } = this.props.El_Data;
        if (e.which === 38) {//cursor up
            this.onChange(parseInt(value, 10) + 1);
        } else if (e.which === 40) {//cursor down
            this.onChange(parseInt(value, 10) - 1);
        }
    }

    onWheel(e) {
        const { value } = this.props.El_Data;
        this.onChange(parseInt(value, 10) + (e.deltaY > 0 ? -1 : 1));
    }

    onClickPlus() {
        const { value, direction = 'hor' } = this.props.El_Data;
        this.onChange(parseInt(value, 10) + (direction === 'hor' ? 1 : -1));
    }

    onClickMinus() {
        const { value, direction = 'hor' } = this.props.El_Data;
        this.onChange(parseInt(value, 10) + (direction === 'hor' ? -1 : 1));
    }

    tryCheckMinMax(value) {
        const { min_val = 0, max_val = 0 } = this.props.El_Data;
        let new_value = parseInt(value, 10);

        if (isNaN(new_value)) {
            new_value = min_val;
        }

        if (new_value < min_val || new_value > max_val) {
            new_value = new_value < min_val ? min_val : max_val;
        }

        return new_value;
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab, Col_Settings, parent_disabled } = this.props;
        const {
            value = 0, readonly, disabled, field_type = 'text', unit,
            min_val = 0, max_val = 0, direction = 'hor'
        } = El_Data;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id);
        const used_value = this.tryCheckMinMax(value);
        const maxlength = (max_val + '').length;
        const isDisabled = parent_disabled || disabled;
        const disableMinus = min_val !== null && used_value === min_val ? true : false;
        const disablePlus = max_val !== null && used_value === max_val ? true : false;

        const style = {};
        if (Col_Settings.h_align === 'right') {
            style.float = 'right';
        }
        if (direction !== 'ver') {
            //style.width = direction === 'ver' ? '42px' : '90px';
        }

        const plusElement = (<div
            className={
                'fld_input_number_plus unselectable' +
                (direction === 'ver' ? ' fld_input_number_plus_ver' : '') +
                (isDisabled || disablePlus ? ' fld_number_disable' : '')
            }
            unselectable="on"
            onClick={readonly || isDisabled ? null : this.onClickPlus.bind(this)}
        >+</div>);

        const minusElement = (<div
            className={
                'fld_input_number_minus unselectable' +
                (direction === 'ver' ? ' fld_input_number_minus_ver' : '') +
                (isDisabled || disableMinus ? ' fld_number_disable' : '')
            }
            unselectable="on"
            onClick={readonly || isDisabled ? null : this.onClickMinus.bind(this)}
        >-</div>);

        const unitElWidth = unit ? (this.myRefUnit ? getSize(this.myRefUnit).w : 11) : 0;
        const inputstyle = {
            width: (maxlength * 8) + 'px',
            paddingRight: unitElWidth + 'px'
        };

        const className = 'fld_input fld_input_number' +
            (direction === 'ver' ? ' fld_input_number_ver' : '') +
            (readonly || parent_disabled ? ' fld_input_readonly' : '') +
            (isDisabled ? ' fld_input_disabled' : '') +
            (this.state.hasFocus ? ' fld_input_focus_number' : '');

        const isFillable = ['text', 'password', 'number'].includes(field_type);

        return (
            <div
                className={
                    'form_field_box inpt' +
                    (direction === 'ver' ? ' inpt_ver' : '')
                }
                style={style}
            >
                {unit &&
                    <div
                        ref={this.setRefUnit.bind(this)}
                        className={'fld_number_unit_' + direction}
                        unselectable="on"
                    >
                        {unit}
                    </div>
                }
                {direction === 'ver' ? plusElement : minusElement}
                <input
                    ref={el => this.el = el}
                    type={field_type}
                    id={id}
                    name={fld_id ? buildFormElementName(Data_Groups, fld_id, field_type) : null}
                    value={used_value !== null ? used_value : ''}
                    className={className}
                    style={inputstyle}
                    maxLength={maxlength && (field_type === 'text' || field_type === 'password') ? maxlength : null}
                    tabIndex={tab ? tab : null}
                    readOnly={readonly && isFillable ? 'readonly' : null}
                    disabled={isDisabled && isFillable ? 'disabled' : null}
                    autoComplete="off"
                    onChange={!readonly && !isDisabled ? this.onChangeByFieldInput.bind(this) : () => { }}
                    onWheel={!readonly && !isDisabled ? this.onWheel.bind(this) : null}
                    onKeyUp={!readonly && !isDisabled ? this.onKeyUp.bind(this) : null}
                    onBlur={!readonly && !isDisabled ? this.onBlur.bind(this) : null}
                    onFocus={!readonly && !isDisabled ? this.onFocus.bind(this) : null}
                />
                {direction === 'ver' ? minusElement : plusElement}
            </div>
        );
    }
}