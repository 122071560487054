import React, { Component, Fragment } from 'react';
import layout_store from '../../api/layout_store';
import { getOuterSize, getSize } from '../../utils/browser';
import { buildFormElementID } from '../../utils/form';
import ColumnType from '../column_type';

export default class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarning: false
        };
        this.myRef;
        this.state = {
            sectionSize: undefined,
            isTooSmallHeight: false
        };
    }

    setRef(el) {
        this.myRef = el;
    }

    componentDidMount() {
        const { Col_Settings, form_h, reSizeAndReposition } = this.props;

        if (Col_Settings.shrinkable) {
            this.setState({
                sectionSize: getSize(this.myRef),
                isTooSmallHeight: form_h && form_h > getOuterSize(this.myRef).h
            });
            reSizeAndReposition();
        }
    }

    resetWarning() {
        this.setState({ showWarning: false });
    }

    render() {
        const {
            Function_Data, Action_Data, frm_id, Data_Groups, fld_id, tab,
            El_Data, Col_Settings, reloadForm, updateActionData, changeCurrentData, parent_disabled,
            holderSize
        } = this.props;

        const Section_Data_Groups = fld_id ? Data_Groups.concat([fld_id]) : Data_Groups;
        const readonly = El_Data.readonly;
        const readonly_alert_text = El_Data.readonly_alert_text ? El_Data.readonly_alert_text : 'This form is readonly';
        const onClick = readonly ?
            () => {
                this.setState({ showWarning: true });
                setTimeout(this.resetWarning.bind(this), 3000);
            } :
            (El_Data.onClick ? El_Data.onClick : null);

        const style = {
            overflow: 'auto'
        };
        if (readonly) {
            style.backgroundColor = '#fdffdf';
        } else if (El_Data.Settings.bg_color) {
            style.backgroundColor = '#' + El_Data.Settings.bg_color;
        }
        if (El_Data.Settings.full_height) {
            style.height = '100%';
        }
        if (El_Data.Settings.padding_left) {
            style.paddingLeft = El_Data.Settings.padding_left + 'px';
        }
        if (El_Data.Settings.padding_right) {
            style.paddingRight = El_Data.Settings.padding_right + 'px';
        }
        if (El_Data.Settings.padding_top) {
            style.paddingTop = El_Data.Settings.padding_top + 'px';
        }
        if (El_Data.Settings.padding_bottom) {
            style.paddingBottom = El_Data.Settings.padding_bottom + 'px';
        }
        if (El_Data.Settings.border_left) {
            style.borderLeft = El_Data.Settings.border_left[0] + 'px solid #' + El_Data.Settings.border_left[1];
        }
        if (El_Data.Settings.border_right) {
            style.borderRight = El_Data.Settings.border_right[0] + 'px solid #' + El_Data.Settings.border_right[1];
        }
        if (El_Data.Settings.border_top) {
            style.borderTop = El_Data.Settings.border_top[0] + 'px solid #' + El_Data.Settings.border_top[1];
        }
        if (El_Data.Settings.border_bottom) {
            style.borderBottom = El_Data.Settings.border_bottom[0] + 'px solid #' + El_Data.Settings.border_bottom[1];
        }
        if (El_Data.Settings.min_width) {
            style.minWidth = El_Data.Settings.min_width + 'px';
        }
        if (El_Data.Settings.corner_br) {
            style.BorderBottomRightRadius = El_Data.Settings.corner_br + 'px';
        }

        const { scrollBarSize, viewport } = layout_store.getState();
        if (this.state.sectionSize && holderSize) {
            const new_max_w = viewport.w - holderSize.w + this.state.sectionSize.w - 20 - scrollBarSize;
            style.maxWidth = (new_max_w > 200 ? new_max_w : 200) + 'px';
        }

        if (this.state.sectionSize && holderSize) {
            const new_max_h = viewport.h - holderSize.h + this.state.sectionSize.h - 20 - scrollBarSize;
            style.maxHeight = (new_max_h > 200 ? new_max_h : 200) + 'px';
        }

        if (this.state.isTooSmallHeight) {
            //Seems not neccesary
            //style.minHeight = '100%';
        }

        return (
            <div
                ref={this.setRef.bind(this)}
                className={Col_Settings.shrinkable ? 'shrinkable' : null}
                style={style}
                onClick={onClick}
            >
                {this.state.showWarning && <div style={{ marginBottom: '10px' }}>
                    <p className="gp red_txt">
                        {readonly_alert_text.split('\n').map((part, index) => <Fragment key={index}><span>{part}</span><br /></Fragment>)}
                    </p>
                </div>}
                <table className={Col_Settings.full_width ? 'full_width' : null}>
                    <tbody>
                        {El_Data.Content.length > 0 ?
                            El_Data.Content
                                .filter(Content_Item => Content_Item.length > 0)
                                .map((Content_Item, index) => {
                                    const id = Content_Item[0].id ?
                                        'row_' + buildFormElementID(frm_id, Section_Data_Groups, Content_Item[0].id) :
                                        null;

                                    return (
                                        <tr
                                            key={index}
                                            id={id}
                                        >
                                            <ColumnType
                                                Function_Data={Function_Data}
                                                Action_Data={Action_Data}
                                                frm_id={frm_id}
                                                Data_Groups={Section_Data_Groups}
                                                tab={tab}
                                                Data={Content_Item}
                                                reloadForm={reloadForm}
                                                updateActionData={updateActionData}
                                                changeCurrentData={changeCurrentData}
                                                parent_disabled={parent_disabled || readonly}
                                            />
                                        </tr>
                                    );
                                }) : null
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}