import moment from 'moment';
import React, { Component } from 'react';
import layer_store from '../../api/layer_store';
import { convertBytesToFileSizeString } from '../../utils/file';
import { buildFormElementID } from '../../utils/form';
import { renderGrid, renderRow } from './management_grid';

export default class FilesManagement extends Component {
    onClickDelete(File) {
        const { Function_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_file_panel',
                    frm_name: 'remove_file',
                    el_type: 'file',
                    id: File.id,
                    data_action_type: 'remove'
                }
            },
        });
    }

    renderLink(File) {
        const { Function_Data } = this.props;
        const link = `${Function_Data.base_url}projects/${Function_Data.project}/files/${Function_Data.use_service ? 'service' : 'user'}/${Function_Data.site_id}/docs/${File.id}.${File.ext}`;

        return (
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div
                    className="link"
                    style={{ float: 'left', width: '14px', height: '14px' }}
                />
            </a>
        );
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        let id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return !El_Data.Files || El_Data.Files.length === 0 ?
            (<p className="gp">No files uploaded yet.</p>) :
            renderGrid(
                `${id}_file_list`,
                [
                    { label: 'Name' },
                    { label: 'Extension' },
                    { label: 'Size' },
                    { label: 'Date' },
                    { label: '' },
                    { label: '' },
                ],
                this.props.El_Data.Files.map((File, index) =>
                    renderRow(
                        `${id}_file_row_${File.id}`,
                        index,
                        [
                            { type: 'text', value: File.name },
                            { type: 'text', value: File.ext },
                            {
                                type: 'text',
                                value: convertBytesToFileSizeString(File.size, 'short'),
                                hoverText: `${File.size} bytes`
                            },
                            {
                                type: 'text',
                                value: moment(File.creation_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY'),
                                hoverText: moment(File.creation_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY hh:mm:ss')
                            },
                            { type: 'custom', content: this.renderLink(File) },
                            { type: 'action', icon: 'delete', onClick: this.onClickDelete(File) }
                        ]
                    )
                )
            );
    }
}
