import React, { Component } from 'react';
import layout_store from '../api/layout_store';
import Element_Router from './element_router';

export default class Layer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content_holder_pos: 0
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
        this.handleLayoutStoreChange(true);
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleLayoutStoreChange() {
        const { action, content_holder_pos } = layout_store.getState();

        if (action === 'set_content_holder_scroll_position') {
            this.setState({
                content_holder_pos: content_holder_pos
            });
        }
    }

    renderContainer(build_top_percentage_push, build_second_position_div, build_bottom_percentage_push) {
        const { Site, Element, show_tools, requiresNewEffect, level, minimum_device_width } = this.props;
        const { el_type, el_idx, Layout } = Element;
        const effect = Site && Site.page_transition_effect ? Site.page_transition_effect : 'none';
        let newClass = null;
        let style = null;
        let middle_style = null;

        if (!show_tools && Layout.scroll === 'false' && effect === 'slideLeft') {
            newClass = requiresNewEffect ? 'fixedLayerNewContainer ' : 'fixedLayerStartContainer ';
        }

        if (el_type === 'sequence' && level === 1) {
            newClass = 'sequence_position_holder';
        }

        if (show_tools && level === 1 && Layout.scroll === 'true') {
            style = {
                minWidth: minimum_device_width + 'px'
            };
        }
        //middle_style -> should be able to get marginTop-style from box "setFitBoxHeightCorrection" method

        return (
            <div
                id={el_type + '_position_' + el_idx}
                className={newClass}
                style={style}
            >
                {build_top_percentage_push && <div id={el_type + '_top_percentage_push_' + el_idx}></div>}
                {build_second_position_div ?
                    <div id={el_type + '_center_middle_' + el_idx} style={middle_style}>
                        {this.renderElement()}
                    </div> :
                    this.renderElement()
                }
                {build_bottom_percentage_push && <div id={el_type + '_bottom_percentage_push_' + el_idx}></div>}
            </div>
        );
    }

    renderElement() {
        const {
            Site, Navigation_Data, Presets, Font_Families, Element, project, site_id, page_id, show_tools,
            use_service, signed_in, navigate, tab, user_role, requiresNewEffect, level, effectClass,
            reloadForm, updateActionData
        } = this.props;
        const { el_type, el_idx } = Element;
        return (
            <div
                id={'element_' + el_type + '_' + el_idx}
                className={['icon', 'text', 'menu', 'form'].includes(el_type) ? 'body_font_size' : null}
            >
                <Element_Router
                    project={project}
                    site_id={site_id}
                    Navigation_Data={Navigation_Data}
                    page_id={page_id}
                    Element={Element}
                    Site={Site}
                    Presets={Presets}
                    Font_Families={Font_Families}
                    show_tools={show_tools}
                    use_service={use_service}
                    tab={tab}
                    signed_in={signed_in}
                    navigate={navigate}
                    user_role={user_role}
                    requiresNewEffect={requiresNewEffect}
                    level={level + 1}
                    effectClass={effectClass}
                    reloadForm={reloadForm}
                    updateActionData={updateActionData}
                />
            </div>
        );
    }

    render() {
        const {
            show_tools, holder_element_width_type, holder_element_height_type, amount_of_elements_in_holder,
            Element, signed_in, minimum_device_width
        } = this.props;

        //TODO: in certain cases, the "element_..." div isn't neccesary (but it's always neccesary when editor is shown, because highlight/select <div>'s ???)
        const css_strategy = 'classic';
        const total_width = Element.total_width;
        const total_height = Element.total_height;
        const Layout = Element.Layout;

        const ignore_h_align = total_width === 'full' || (
            amount_of_elements_in_holder === 1 && holder_element_width_type === 'fit'
        ) ? true : false;
        const ignore_v_align = total_height === 'full' || (
            amount_of_elements_in_holder === 1 && holder_element_height_type === 'fit'
        ) ? true : false;

        const { align } = Layout;

        const position_absolute = (amount_of_elements_in_holder > 1/* && el_type !== 'sequence'*/) ||
            (!signed_in && Layout.scroll === 'false') ? true : false;

        const ignore_h_v_align = ignore_h_align && ignore_v_align;

        /*const align_h_by_display = (
            !ignore_h_v_align &&
                ['ct', 'cc', 'cb'].includes(align) &&
                total_width === 'fit' &&
                !ignore_h_align
        ) ? true : false;*/

        //NOTE: because vertical margins are based on width, vertically centering can't be
        // achieved with "margin-top:50%" etc. Thus in case an element needs to verically
        // centered and "position:absolute" isn't used, the centering has to be done by display.
        const align_v_by_display = !ignore_h_v_align && (
            (['lc', 'cc', 'rc'].includes(align) && total_height === 'fit' && !ignore_v_align) ||
            (['lb', 'cb', 'rb'].includes(align) && !position_absolute && !ignore_v_align) ||
            (['lc', 'cc', 'rc'].includes(align) && !position_absolute && !ignore_v_align)
        ) ? true : false;

        const build_second_position_div = !ignore_h_v_align && (
            (['ct', 'cc', 'cb'].includes(align) && !ignore_h_align) ||
            (['lc', 'cc', 'rc'].includes(align) && !ignore_v_align) ||
            (['lb', 'cb', 'rb'].includes(align) && !position_absolute && !ignore_v_align)
        ) ? true : false;

        /*const align_h_by_position = !ignore_h_align && !align_h_by_display && position_absolute ?
            true : false;*/

        const align_v_by_position = !ignore_v_align && !align_v_by_display && position_absolute ? true : false;
        const offset_t = parseInt(Layout.ver_amount_type === 'per' ?
            Layout.space_outside_ver_percentage_1 :
            Layout.space_outside_ver_pixel_1
        );
        const offset_b = parseInt(Layout.ver_amount_type === 'per' ?
            Layout.space_outside_ver_percentage_2 :
            Layout.space_inside_ver_pixel_2
        );

        const build_top_percentage_push = (
            (
                ignore_v_align && offset_t && Layout.ver_amount_type === 'per' &&
                Layout.vertical_percentages_bound_to_width === 'false'
            ) || (
                !ignore_v_align && ['lt', 'ct', 'rt'].includes(align) &&
                !align_v_by_position && offset_t && Layout.ver_amount_type === 'per' &&
                Layout.vertical_percentages_bound_to_width === 'false'
            )
        ) ? true : false;

        const build_bottom_percentage_push = (
            (
                ignore_v_align &&
                offset_b &&
                Layout.ver_amount_type === 'per' &&
                Layout.vertical_percentages_bound_to_width === 'false'
            ) || (
                !ignore_v_align &&
                ['lb', 'cb', 'rb'].includes(align) &&
                !align_v_by_position &&
                css_strategy === 'classic' &&
                offset_b &&
                Layout.ver_amount_type === 'per' &&
                Layout.vertical_percentages_bound_to_width === 'false'
            ) || (
                total_height !== 'full' &&
                total_height !== 'percentage' &&
                //Layout.vertical_percentages_bound_to_width !== 'false' && //?????
                Layout.vertical_percentages_bound_to_width === 'false' &&
                !ignore_v_align &&
                ['lb', 'cb', 'rb'].includes(align) && css_strategy === 'flex' &&
                offset_b &&
                Layout.ver_amount_type === 'per'
            )
        ) ? true : false;

        return show_tools && Layout.scroll === 'false' ?
            <div
                className="fixed_element_with_tools_bar"
                style={{
                    position: 'absolute',
                    overflow: 'visible',
                    left: 0,
                    top: this.state.content_holder_pos + 'px',
                    height: '100%',
                    minWidth: minimum_device_width + 'px'
                }}
            >
                {this.renderContainer(build_top_percentage_push, build_second_position_div, build_bottom_percentage_push)}
            </div> :
            this.renderContainer(build_top_percentage_push, build_second_position_div, build_bottom_percentage_push);
    }
}