import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class TextSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Text: {
                    ps_type: 'p', //TODO: Default SHOULD BE MADE DYNAMIC FOR "Paragraph" or "Headings"!
                    include_in_search: true
                }
            } :
            clone(Load_Data);
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Text {
                ps_type: String
                include_in_search
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Text', [
                [
                    { type: 'dropdown', id: 'ps_type', label: 'Tag type' },
                    {
                        value: Current_Data.Text.ps_type,
                        Options: [
                            ['p', 'Paragraph'],
                            ['h1', 'Heading 1'],
                            ['h2', 'Heading 2'],
                            ['h3', 'Heading 3'],
                            ['h4', 'Heading 4'],
                            ['h5', 'Heading 5'],
                            ['h6', 'Heading 6']
                        ]
                    }
                ],
                [
                    { type: 'checkbox', id: 'include_in_search', label: 'Include in search' },
                    { value: Current_Data.Text.include_in_search, styling_type: 'switch' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    /*$('#' + (buildFormElementID('form_' + frm_id_int, ['Text_Source'], 'text'))).val(
                        $('#' + (buildFormElementID('form_' + frm_id_int, ['Text_Source'], 'text')) + '_div').html()
                    );*/

                    updateActionData({
                        access: 'authorized',
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_text_settings',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save')
        ];
    }
}