import React, { Component } from 'react';
import { buildClickToolsAction } from './tools_events';

export default class Startup_Site_Tools extends Component {
    render() {
        return (
            <div
                className="tools_dropdown_container_holder site_tools_no_site"
                onClick={() => buildClickToolsAction(this.props.Function_Data, 'create_site')}
            >
                <div className="main_action_button_holder site_tools_no_site">
                    <div
                        id="button_create_site_BIG"
                        className="main_action_button yellow_big"
                    >
                        <div className="relativeleft create_site_button">
                            <p>Create Website</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}