import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class Radio extends Component {
    constructor(props) {
        super(props);
        this.state = { value: props.El_Data.value };
    }

    handleChange(value) {
        const { El_Data, Data_Groups, fld_id, changeCurrentData } = this.props;
        const New_Data = El_Data.onChange ? El_Data.onChange(value) : null,
            New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), value);
        changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, Col_Settings, parent_disabled } = this.props;
        const { Options, direction, value, readonly } = El_Data;
        const isDisabled = parent_disabled || readonly;

        var h_align = Col_Settings.h_align,
            id = buildFormElementID(frm_id, Data_Groups, fld_id);

        const style = {};
        if (h_align && h_align !== 'center') {
            style.float = h_align;
        }
        if (direction === 'ver') {
            style.height = Options.length * 24 + 'px';
        }

        return (
            <div
                className="form_field_box rd"
                style={style}
            >
                {Options
                    .filter(Option => Option[0])
                    .map((Option, index) => {
                        const Input_Element = (
                            <Fragment>
                                <input
                                    type="radio"
                                    id={id + '_' + Option[0]}
                                    name={buildFormElementName(Data_Groups, fld_id)}
                                    value={Option[0] !== null ? Option[0] : null}
                                    checked={Option[0] !== null && value === Option[0]}
                                    onChange={() => this.handleChange(Option[0])}
                                    disabled={isDisabled}
                                />
                                <label
                                    htmlFor={id + '_' + Option[0]}
                                    className="gp grey_txt radio italic"
                                >
                                    {Option[1]}
                                </label>
                            </Fragment>
                        );

                        return (
                            <Fragment key={index}>
                                {direction === 'ver' ?
                                    <div style={{ float: 'left', clear: 'both' }}>
                                        {Input_Element}
                                    </div>
                                    : Input_Element}
                            </Fragment>
                        );
                    })
                }
            </div>
        );
    }
}