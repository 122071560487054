import deepmerge from 'deepmerge';
import custom_font_store from '../../api/custom_font_store';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { ucWords } from '../../utils/string';

export default class RemoveCustomFont {
    convertLoadDataToCurrentData(Load_Data) {
        const { Other_Fonts } = Load_Data;
        const Current_Data = deepmerge(
            Load_Data,
            {
                switched_font_id: Other_Fonts && Other_Fonts.length > 0 ? Other_Fonts[0].id : 0
            }
        );

        Current_Data.usage_amount = parseInt(Current_Data.usage_amount, 10);

        return Current_Data;
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Other_Fonts
            Custom_Font {
                main
            }
            usage_amount
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Other_Fonts.length === 0 ? 'text' : 'hidden',
                        colspan: 2
                    },
                    {
                        text: 'The font can\'t be deleted, because<br>\
                                    there' + (Current_Data.usage_amount === 1 ?
                                '\'s still 1 place' :
                                ' are still ' + Current_Data.usage_amount + ' places'
                            ) + '<br>where it is used and there aren\'t any<br>\
                                     other fonts to switch to.',
                        style: 'bold'
                    }
                ],
                [
                    { type: Current_Data.usage_amount === 0 || Current_Data.Other_Fonts.length > 0 ? 'hidden' : 'text', colspan: 2, h_align: 'center' },
                    {
                        text: 'You are about to delete the custom font named: "' +
                            ucWords(Current_Data.Custom_Font.name) + '"',
                        style: 'bold'
                    }
                ],
                [
                    { type: Current_Data.usage_amount > 0 && Current_Data.Other_Fonts.length > 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: 'There ' +
                            (Current_Data.usage_amount === 1 ?
                                'is 1 font family that is' :
                                'are ' + Current_Data.usage_amount + ' font families that are'
                            ) +
                            ' still using this custom font!',
                        style: 'normal_grey'
                    }
                ],
                [
                    { type: Current_Data.usage_amount > 0 && Current_Data.Other_Fonts.length > 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: 'Please specify which font ' +
                            (Current_Data.usage_amount === 1 ?
                                'this font family' :
                                'these font families'
                            ) +
                            ' should switch to.',
                        style: 'normal_grey'
                    }
                ],
                [
                    { type: Current_Data.usage_amount > 0 && Current_Data.Other_Fonts.length > 0 ? 'dropdown' : 'hidden', id: 'switched_font_id' },
                    {
                        value: Current_Data.switched_font_id,
                        Options: Current_Data.Other_Fonts.map(Font => [Font.id, ucWords(Font.name)]),
                    }
                ],
                [
                    { type: Current_Data.usage_amount === 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: 'This custom font isn\'t used by any font family,<br>' +
                            'thereby this custom font can be safely deleted.',
                        style: 'normal_grey'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount === 0 || Current_Data.Other_Fonts.length > 0 ? 'text' : 'hidden',
                        colspan: 2, h_align: 'center'
                    },
                    { text: 'Are you sure?', style: 'bold' }
                ]
            ]),
            Current_Data.usage_amount > 0 && Current_Data.Other_Fonts.length === 0 ?
                getSingleSubmitSet(frm_id, 'OK') :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_custom_font',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: Result_Data => {
                                if (Result_Data) {
                                    custom_font_store.dispatch({
                                        type: 'REMOVE',
                                        removed_id: Result_Data,
                                        replacement_id: Current_Data.switched_font_id
                                    });
                                } else {
                                    alert('Some error occured when trying to remove the custom font.')
                                }
                            }
                        }
                    });
                }, 'Delete')
        ];
    }
}