import React, { Component } from 'react';
import { Parser } from 'html-to-react';
import { buildFormElementID } from '../../utils/form';

export default class StyleAndText extends Component {
    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props,
            id = buildFormElementID(frm_id, Data_Groups, fld_id),
            { el_class, style, /*text_style,*/ text } = El_Data,
            text_style = { // TODO: process El_Data.text_style
                fontFamily: 'arial, verdana, helvetica, sans-serif'
            },
            htmlToReactParser = new Parser(),
            reactElement = htmlToReactParser.parse(text || '');

        return (
            <div
                id={id || ''}
                className={el_class || 'stl_read_only_field'}
                style={style}
            >
                {text &&
                    <p className={el_class || 'gp grey_txt'}
                        style={text_style}
                    >
                        {reactElement}
                    </p>
                }
            </div>
        );
    }
}