import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import user_store from '../../api/user_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class UserSettings {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [user_store.subscribe(this.handleUserStoreChange(changeCurrentData, getCurrentData))];
    }

    handleUserStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = user_store.getState();
            const Current_Data = getCurrentData();

            changeCurrentData({
                User: deepmerge(Current_Data.User, {
                    first_name: state.first_name,
                    last_name: state.last_name
                })
            });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            User {
                country_id: Int
                email: String
                first_name: String
                last_name: String
                date_of_birth: String
            }
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('User', [
                [
                    { type: 'field', id: 'first_name', label: 'First name' },
                    { value: Current_Data.User.first_name, max: 2 }
                ],
                [
                    { type: 'field', id: 'last_name', label: 'Last name' },
                    { value: Current_Data.User.last_name, max: 2 }
                ],
                [
                    { type: 'field', id: 'email', label: 'Email' },
                    { value: Current_Data.User.email, max: 2 }
                ],
                [
                    { type: 'dropdown', id: 'country_id', label: 'Country' },
                    {
                        value: Current_Data.User.country_id,
                        Options: [
                            ['0', 'Unknown'],
                            ['1', 'Afghanistan'],
                            ['2', 'Albania'],
                            ['3', 'Algeria'],
                            ['4', 'Andorra'],
                            ['5', 'Angola'],
                            ['6', 'Antigua and Barbuda'],
                            ['7', 'Argentina'],
                            ['8', 'Armenia'],
                            ['9', 'Aruba'],
                            ['10', 'Australia'],
                            ['11', 'Austria'],
                            ['12', 'Azerbaijan'],
                            ['13', 'Bahamas, The'],
                            ['14', 'Bahrain'],
                            ['15', 'Bangladesh'],
                            ['16', 'Barbados'],
                            ['17', 'Belarus'],
                            ['18', 'Belgium'],
                            ['19', 'Belize'],
                            ['20', 'Benin'],
                            ['21', 'Bhutan'],
                            ['22', 'Bolivia'],
                            ['23', 'Bosnia and Herzegovina'],
                            ['24', 'Botswana'],
                            ['25', 'Brazil'],
                            ['26', 'Brunei'],
                            ['27', 'Bulgaria'],
                            ['28', 'Burkina Faso'],
                            ['29', 'Burma'],
                            ['30', 'Burundi'],
                            ['31', 'Cambodia'],
                            ['32', 'Cameroon'],
                            ['33', 'Canada'],
                            ['34', 'Cape Verde'],
                            ['35', 'Central African Republic'],
                            ['36', 'Chad'],
                            ['37', 'Chile'],
                            ['38', 'China'],
                            ['39', 'Colombia'],
                            ['40', 'Comoros'],
                            ['41', 'Congo, Democratic Republic of the'],
                            ['42', 'Congo, Republic of the'],
                            ['43', 'Costa Rica'],
                            ['44', 'Cote d\'Ivoire'],
                            ['45', 'Croatia'],
                            ['46', 'Cuba'],
                            ['47', 'Curacao'],
                            ['48', 'Cyprus'],
                            ['49', 'Czech Republic'],
                            ['50', 'Denmark'],
                            ['51', 'Djibouti'],
                            ['52', 'Dominica'],
                            ['53', 'Dominican Republic'],
                            ['54', 'Ecuador'],
                            ['55', 'Egypt'],
                            ['56', 'El Salvador'],
                            ['57', 'Equatorial Guinea'],
                            ['58', 'Eritrea'],
                            ['59', 'Estonia'],
                            ['60', 'Ethiopia'],
                            ['61', 'Fiji'],
                            ['62', 'Finland'],
                            ['63', 'France'],
                            ['64', 'Gabon'],
                            ['65', 'Gambia, The'],
                            ['66', 'Georgia'],
                            ['67', 'Germany'],
                            ['68', 'Ghana'],
                            ['69', 'Greece'],
                            ['70', 'Grenada'],
                            ['71', 'Guatemala'],
                            ['72', 'Guinea'],
                            ['73', 'Guinea-Bissau'],
                            ['74', 'Guyana'],
                            ['75', 'Haiti'],
                            ['76', 'Holy See'],
                            ['77', 'Honduras'],
                            ['78', 'Hong Kong'],
                            ['79', 'Hungary'],
                            ['80', 'Iceland'],
                            ['81', 'India'],
                            ['82', 'Indonesia'],
                            ['83', 'Iran'],
                            ['84', 'Iraq'],
                            ['85', 'Ireland'],
                            ['86', 'Israel'],
                            ['87', 'Italy'],
                            ['88', 'Jamaica'],
                            ['89', 'Japan'],
                            ['90', 'Jordan'],
                            ['91', 'Kazakhstan'],
                            ['92', 'Kenya'],
                            ['93', 'Kiribati'],
                            ['94', 'Korea, North'],
                            ['95', 'Korea, South'],
                            ['96', 'Kosovo'],
                            ['97', 'Kuwait'],
                            ['98', 'Kyrgyzstan'],
                            ['99', 'Laos'],
                            ['100', 'Latvia'],
                            ['101', 'Lebanon'],
                            ['102', 'Lesotho'],
                            ['103', 'Liberia'],
                            ['104', 'Libya'],
                            ['105', 'Liechtenstein'],
                            ['106', 'Lithuania'],
                            ['107', 'Luxembourg'],
                            ['108', 'Macau'],
                            ['109', 'Macedonia'],
                            ['110', 'Madagascar'],
                            ['111', 'Malawi'],
                            ['112', 'Malaysia'],
                            ['113', 'Maldives'],
                            ['114', 'Mali'],
                            ['115', 'Malta'],
                            ['116', 'Marshall Islands'],
                            ['117', 'Mauritania'],
                            ['118', 'Mauritius'],
                            ['119', 'Mexico'],
                            ['120', 'Micronesia'],
                            ['121', 'Moldova'],
                            ['122', 'Monaco'],
                            ['123', 'Mongolia'],
                            ['124', 'Montenegro'],
                            ['125', 'Morocco'],
                            ['126', 'Mozambique'],
                            ['127', 'Namibia'],
                            ['128', 'Nauru'],
                            ['129', 'Nepal'],
                            ['130', 'Netherlands'],
                            ['131', 'Netherlands Antilles'],
                            ['132', 'New Zealand'],
                            ['133', 'Nicaragua'],
                            ['134', 'Niger'],
                            ['135', 'Nigeria'],
                            ['136', 'North Korea'],
                            ['137', 'Norway'],
                            ['138', 'Oman'],
                            ['139', 'Pakistan'],
                            ['140', 'Palau'],
                            ['141', 'Palestinian Territories'],
                            ['142', 'Panama'],
                            ['143', 'Papua New Guinea'],
                            ['144', 'Paraguay'],
                            ['145', 'Peru'],
                            ['146', 'Philippines'],
                            ['147', 'Poland'],
                            ['148', 'Portugal'],
                            ['149', 'Qatar'],
                            ['150', 'Romania'],
                            ['151', 'Russia'],
                            ['152', 'Rwanda'],
                            ['153', 'Saint Kitts and Nevis'],
                            ['154', 'Saint Lucia'],
                            ['155', 'Saint Vincent and the Grenadines'],
                            ['156', 'Samoa'],
                            ['157', 'San Marino'],
                            ['158', 'Sao Tome and Principe'],
                            ['159', 'Saudi Arabia'],
                            ['160', 'Senegal'],
                            ['161', 'Serbia'],
                            ['162', 'Seychelles'],
                            ['163', 'Sierra Leone'],
                            ['164', 'Singapore'],
                            ['165', 'Sint Maarten'],
                            ['166', 'Slovakia'],
                            ['167', 'Slovenia'],
                            ['168', 'Solomon Islands'],
                            ['169', 'Somalia'],
                            ['170', 'South Africa'],
                            ['171', 'South Korea'],
                            ['172', 'South Sudan'],
                            ['173', 'Spain'],
                            ['174', 'Sri Lanka'],
                            ['175', 'Sudan'],
                            ['176', 'Suriname'],
                            ['177', 'Swaziland'],
                            ['178', 'Sweden'],
                            ['179', 'Switzerland'],
                            ['180', 'Syria'],
                            ['181', 'Taiwan'],
                            ['182', 'Tajikistan'],
                            ['183', 'Tanzania'],
                            ['184', 'Thailand'],
                            ['185', 'Timor-Leste'],
                            ['186', 'Togo'],
                            ['187', 'Tonga'],
                            ['188', 'Trinidad and Tobago'],
                            ['189', 'Tunisia'],
                            ['190', 'Turkey'],
                            ['191', 'Turkmenistan'],
                            ['192', 'Tuvalu'],
                            ['193', 'Uganda'],
                            ['194', 'Ukraine'],
                            ['195', 'United Arab Emirates'],
                            ['196', 'United Kingdom'],
                            ['197', 'Uruguay'],
                            ['198', 'Uzbekistan'],
                            ['199', 'Vanuatu'],
                            ['200', 'Venezuela'],
                            ['201', 'Vietnam'],
                            ['202', 'Yemen'],
                            ['203', 'Zambia'],
                            ['204', 'Zimbabwe']
                        ]
                    }
                ],
                [
                    { type: 'date', id: 'date_of_birth', label: 'Date of birth' },
                    { value: Current_Data.User.date_of_birth }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'save_user',
                        Function_Data,
                        process_tag: 'Saving...',
                        onSubmitted: Result_Data => {
                            if (Result_Data.first_name && Result_Data.last_name) {
                                user_store.dispatch({
                                    type: 'UPDATE',
                                    User: Result_Data
                                });
                            }
                        }
                    }
                });
            })
        ];
    }
}