import React, { Component, Fragment } from 'react';
import StructureHolder from './structure_holder';
import {
    mouseOverElement, mouseOutElement, onClickElement, onRightClickElement, setRightClickToolsPosition
} from './element_events.js';
import structure_store from '../api/structure_store';

export default class StructureGridContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            hovering: false
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [structure_store.subscribe(this.handleStructureStoreChange.bind(this))];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleStructureStoreChange() {
        const state = structure_store.getState();
        const { grid_el_idx, Content } = this.props;

        let hasSelected = false;
        if (state.selected_el_type === 'row') {
            hasSelected = Content.filter(Content_Row =>
                parseInt(Content_Row[0][0].row_el_idx, 10) === parseInt(state.selected_el_idx, 10)
            ).length > 0;
        }

        if (state.selected_el_type === 'column') {
            hasSelected = Content[0].filter(Content_Column =>
                parseInt(Content_Column[0].column_el_idx, 10) === parseInt(state.selected_el_idx, 10)
            ).length > 0;
        }

        if (state.selected_el_type === 'grid_cell') {
            hasSelected = Content.filter(Content_Row =>
                Content_Row.filter(Content_Column =>
                    parseInt(grid_el_idx, 10) === parseInt(state.selected_el_idx, 10) &&
                    parseInt(Content_Column[0].row_el_idx, 10) === parseInt(state.selected_row_el_idx, 10) &&
                    parseInt(Content_Column[0].column_el_idx, 10) === parseInt(state.selected_column_el_idx, 10)
                ).length > 0
            ).length > 0;
        }

        let hasHovering = false;
        if (state.hovering_el_type === 'row') {
            hasHovering = Content.filter(Content_Row =>
                parseInt(Content_Row[0][0].row_el_idx, 10) === parseInt(state.hovering_el_idx, 10)
            ).length > 0;
        }

        if (state.hovering_el_type === 'column') {
            hasHovering = Content[0].filter(Content_Column =>
                parseInt(Content_Column[0].column_el_idx, 10) === parseInt(state.hovering_el_idx, 10)
            ).length > 0;
        }

        if (state.hovering_el_type === 'grid_cell') {
            hasHovering = Content.filter(Content_Row =>
                Content_Row.filter(Content_Column =>
                    parseInt(grid_el_idx, 10) === parseInt(state.hovering_el_idx, 10) &&
                    parseInt(Content_Column[0].row_el_idx, 10) === parseInt(state.hovering_row_el_idx, 10) &&
                    parseInt(Content_Column[0].column_el_idx, 10) === parseInt(state.hovering_column_el_idx, 10)
                ).length > 0
            ).length > 0;
        }

        this.setState({
            selected: hasSelected ? {
                el_type: state.selected_el_type,
                el_idx: state.selected_el_idx,
                row_el_idx: state.selected_row_el_idx,
                column_el_idx: state.selected_column_el_idx
            } : null,
            hovering: hasHovering ? {
                el_type: state.hovering_el_type,
                el_idx: state.hovering_el_idx,
                row_el_idx: state.hovering_row_el_idx,
                column_el_idx: state.hovering_column_el_idx
            } : null
        });
    }

    mouseOverGridCell(el_idx, row_el_idx, column_el_idx) {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            structure_store.dispatch({
                type: 'OVER_ELEMENT',
                data: {
                    el_type: 'grid_cell',
                    el_idx,
                    row_el_idx,
                    column_el_idx
                },
            });
        };
    };

    mouseOutGridCell(el_idx, row_el_idx, column_el_idx) {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            structure_store.dispatch({
                type: 'OUT_ELEMENT',
                data: {
                    el_type: 'grid_cell',
                    el_idx,
                    row_el_idx,
                    column_el_idx
                },
            });
        };
    };

    onClickGridCell(el_idx, row_el_idx, column_el_idx) {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            structure_store.dispatch({
                type: 'CLICK_ELEMENT',
                data: {
                    el_type: 'grid_cell',
                    el_idx,
                    row_el_idx,
                    column_el_idx
                },
            });
        };
    };

    onRightClickGridCell(el_idx, row_el_idx, column_el_idx, x, y) {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            structure_store.dispatch({
                type: 'RIGHT_CLICK_ELEMENT',
                data: {
                    el_type: 'grid_cell',
                    el_idx,
                    row_el_idx,
                    column_el_idx
                },
            });

            setRightClickToolsPosition(el_type, x, y);
        };
    };

    render() {
        const { Content, grid_el_idx } = this.props;
        var tab = 0;

        return (
            Content ?
                <div className="grid_content_holder">
                    {Content.map((Content_Row, i) => {
                        const Grid_Content = Content_Row.map((Content_Row_Item, j) => {
                            return [Content_Row_Item, i, j];
                        });
                        return this.renderStructureGridContentPart(Grid_Content, grid_el_idx, tab, i === Content.length - 1);
                    })}
                </div> :
                <Fragment />
        );
    }

    renderStructureGridContentPart(Grid_Content, grid_el_idx, tab, last) {
        let renderHolder = false;
        const { selected, hovering } = this.state;

        return Grid_Content.map((Grid_Cell_Content, i, Grid_Content) => {
            const connect_grid = (last && i === Grid_Content.length - 1) ? 0 : 1;
            const row_el_idx = Grid_Cell_Content[0][0].row_el_idx;
            const column_el_idx = Grid_Cell_Content[0][0].column_el_idx;
            renderHolder = !renderHolder && Grid_Cell_Content[2] === 0;

            return (
                <Fragment key={i}>
                    {renderHolder &&
                        <div
                            className={'structure_row_holder structure_row_holder_' + row_el_idx}
                            title={'Row (el_idx: ' + row_el_idx + ')'}
                        >
                            <div
                                id={'structure_row_' + row_el_idx}
                                className={
                                    'structure_row' +
                                    (selected && selected.el_type === 'row' && parseInt(selected.el_idx, 10) === parseInt(row_el_idx, 10) ? ' structure_select_1' :
                                        (hovering && hovering.el_type === 'row' && parseInt(hovering.el_idx, 10) === parseInt(row_el_idx, 10) ? ' structure_highlight_1' : '')
                                    )
                                }
                                onMouseOver={mouseOverElement('row', row_el_idx)}
                                onMouseOut={mouseOutElement('row', row_el_idx)}
                                onClick={onClickElement('row', row_el_idx)}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    if (e.which === 3) {
                                        onRightClickElement('row', row_el_idx, e.pageX, e.pageY);
                                    }
                                }}
                            >
                                <p
                                    id={'structure_txt_row_' + row_el_idx}
                                    className="structure_row_txt"
                                >
                                    {'Row ' + (Grid_Cell_Content[1] + 1)}
                                </p>
                            </div>
                        </div>
                    }

                    <div className={'structure_column_holder' + (last && i === Grid_Content.length - 1 ? ' last_column' : '') + ' structure_row_holder_' + row_el_idx + ' structure_column_holder_' + column_el_idx}>
                        <div
                            className="structure_column_marker_holder"
                            title={'Column (el_idx: ' + column_el_idx + ')'}
                        >
                            <div className={'structure_sub' + (connect_grid ? ' grid_connector' : '')}></div>
                            <div className="structure_column_marker">
                                <div
                                    id={'structure_column_' + column_el_idx}
                                    className={
                                        'structure_column structure_column_' + column_el_idx +
                                        (selected && selected.el_type === 'column' && parseInt(selected.el_idx, 10) === parseInt(column_el_idx, 10) ? ' structure_select_1' :
                                            (hovering && hovering.el_type === 'column' && parseInt(hovering.el_idx, 10) === parseInt(column_el_idx, 10) ? ' structure_highlight_1' : '')
                                        )
                                    }
                                    onMouseOver={mouseOverElement('column', column_el_idx)}
                                    onMouseOut={mouseOutElement('column', column_el_idx)}
                                    onClick={onClickElement('column', column_el_idx)}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        if (e.which === 3) {
                                            onRightClickElement('column', column_el_idx, e.pageX, e.pageY);
                                        }
                                    }}
                                >
                                    <p
                                        id={'structure_txt_column_' + column_el_idx}
                                        className="structure_column_txt"
                                    >
                                        {(Grid_Cell_Content[2] + 1)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {Grid_Cell_Content[0].length === 1 ?
                            this.renderStructureGridCell(
                                grid_el_idx, row_el_idx,
                                column_el_idx, Grid_Cell_Content[0][0].el_type,
                                Grid_Cell_Content[0][0].el_idx,
                                Grid_Cell_Content[0][0].Content && Grid_Cell_Content[0][0].Content.length > 0 ?
                                    Grid_Cell_Content[0][0].Content :
                                    0
                            ) :
                            Grid_Cell_Content[0].map((Item, i) =>
                                <StructureHolder
                                    key={i}
                                    holder_el_type=""
                                    el_type={Item.el_type}
                                    el_idx={Item.el_idx}
                                    Content={Item.Content}
                                />
                            )
                        }
                    </div>
                </Fragment>
            );
        });
    }

    renderStructureGridCell(grid_el_idx, row_el_idx, column_el_idx, el_type, el_idx, Content) {
        const { selected, hovering } = this.state;

        return (
            <Fragment>
                <div className="structure_grid_cell_border1"></div>
                <div className="structure_grid_cell_border2"></div>
                {el_type !== 'grid' && <div className="structure_grid_cell_border3"></div>}
                <div
                    id={'grid_cell_' + grid_el_idx + '_' + row_el_idx + '_' + column_el_idx}
                    className={
                        'sortable_grid_sequence' +
                        (
                            selected && selected.el_type === 'grid_cell' &&
                                parseInt(selected.el_idx, 10) === parseInt(grid_el_idx, 10) &&
                                parseInt(selected.row_el_idx, 10) === parseInt(row_el_idx, 10) &&
                                parseInt(selected.column_el_idx, 10) === parseInt(column_el_idx, 10) ?
                                ' structure_select_3' :
                                (
                                    hovering && hovering.el_type === 'grid_cell' &&
                                        parseInt(hovering.el_idx, 10) === parseInt(grid_el_idx, 10) &&
                                        parseInt(hovering.row_el_idx, 10) === parseInt(row_el_idx, 10) &&
                                        parseInt(hovering.column_el_idx, 10) === parseInt(column_el_idx, 10) ?
                                        ' structure_highlight_2' : ''
                                )
                        )
                    }

                    onMouseOver={el_type === 'grid_cell' ? this.mouseOverGridCell(grid_el_idx, row_el_idx, column_el_idx) : null}
                    onMouseOut={el_type === 'grid_cell' ? this.mouseOutGridCell(grid_el_idx, row_el_idx, column_el_idx) : null}
                    onClick={el_type === 'grid_cell' ? this.onClickGridCell(grid_el_idx, row_el_idx, column_el_idx) : null}
                    onMouseDown={el_type === 'grid_cell' ? (e) => {
                        e.preventDefault();
                        if (e.which === 3) {
                            this.onRightClickGridCell('grid_cell', grid_el_idx, row_el_idx, column_el_idx, e.pageX, e.pageY);
                        }
                    } : null}
                >
                    {el_type !== 'grid_cell' &&
                        <StructureHolder
                            holder_el_type="grid"
                            el_type={el_type}
                            el_idx={el_idx}
                            Content={Content}
                        />
                    }
                </div>
            </Fragment>
        );
    }
}