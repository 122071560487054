import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveMapMarker {
    getQuery() {
        return `query DataQuery($urls: String!) {
            map_el_idx
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', colspan: 2, h_align: 'center' },
                    { text: 'Are you sure?', style: 'bold' }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (parseInt(Current_Data.map_el_idx, 10) > 0) {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_map_marker',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: Result_Data => {
                                updateActionData(
                                    {
                                        access: 'authorized',
                                        panel_type: Action_Data.parent_panel_type,
                                        frm_name: 'map_settings',
                                        el_type: 'map',
                                        new_element: false
                                    }
                                );
                            }
                        }
                    });
                }
            }, 'Delete')
        ];
    }
}