import React, { Component } from 'react';
import layout_store from '../api/layout_store';
import { getSize } from '../utils/browser';
import { buildEvents } from '../utils/events';
import { getModByPresetOrDirect } from '../utils/mod';
import Layer from './layer';

export default class Box extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundSize: undefined,
            height: undefined
        };
        this.myRef;
        this.unsubscribes = [];

        const { Presets, Element } = props;
        const { Background } = Element;

        this.BackgroundMod = Background ? getModByPresetOrDirect(Background, Presets) : undefined;
        this.isFitBoxHeightCorrectionRequired = false;
        this.Content_Elements = [];
        this.buildFitBoxHeightCorrectionData();
    }

    componentDidMount() {
        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
        this.resizeElementBackground();
        this.setFitBoxHeightCorrection();
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    setRef(el) {
        this.myRef = el;
    }

    handleLayoutStoreChange() {
        const { action } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.resizeElementBackground();
            this.setFitBoxHeightCorrection();
        }
    }

    resizeElementBackground() {
        if (this.BackgroundMod && this.myRef) {
            const { type, width, height, fit } = this.BackgroundMod;
            if (type === 'image' && ['c', 'e'].includes(fit)) {
                var element_ratio = getSize(this.myRef).w / getSize(this.myRef).h;
                var img_ratio = width / height;
                this.setState({
                    backgroundSize: (fit === 'e' && element_ratio < img_ratio) || (fit === 'c' && element_ratio > img_ratio) ?
                        '100% auto' :
                        'auto 100%'
                });
            }
        }
    }

    buildFitBoxHeightCorrectionData() {
        const { Content = [], total_height } = this.props.Element;
        let has_text = false;
        let has_full_height = false;

        if (Content.length > 1 && total_height !== 'full') {
            Content.forEach(Element => {
                if (Element.el_type === 'text') {
                    has_text = true;
                }
                if (Element.total_height === 'full') {
                    has_full_height = true;
                }
                this.Content_Elements.push({
                    'el_type': Element.el_type,
                    'el_idx': Element.el_idx,
                    'Layout': Element.Layout
                });
            });

            if (has_text && !has_full_height) {
                this.isFitBoxHeightCorrectionRequired = true;
            }
        }
    }

    setFitBoxHeightCorrection() {
        if (this.isFitBoxHeightCorrectionRequired) {
            const { el_idx, Element, Presets } = this.props;
            const { Border, Layout } = Element;
            const BorderMod = getModByPresetOrDirect(Border, Presets);
            let new_height = 0;
            let element_height;

            for (let i = 0; i < this.Content_Elements.length; i++) {
                element_height = document.getElementById('element_' + this.Content_Elements[i].el_type + '_' + this.Content_Elements[i].el_idx).offsetHeight +
                    parseInt(this.Content_Elements[i].Layout.space_outside_ver_pixel_1, 10) +
                    parseInt(this.Content_Elements[i].Layout.space_outside_ver_pixel_2, 10);

                new_height = element_height > new_height ? element_height : new_height;
            }

            new_height += (
                Layout.hor_amount_type === 'px' && Layout.ver_amount_type === 'px' ?
                    parseInt(Layout.space_outside_ver_pixel_1, 10) + parseInt(Layout.space_outside_ver_pixel_2, 10) :
                    0
            ) + (BorderMod ?
                (
                    BorderMod.symmetric === 'true' ?
                        2 * parseInt(BorderMod.size_all, 10) :
                        parseInt(BorderMod.size_top, 10) + parseInt(BorderMod.size_bottom, 10)
                ) : 0
                );

            this.setState({
                height: new_height + 'px'
            })
            //TODO: below should work through setState!!! thus this logic should move to common shared method
            // and grid, layer and sequence should use that method to fill states and supply it downstream.
            const el2 = document.getElementById('element_box_' + el_idx);
            el2.style.height = new_height + 'px';

            if (['lc', 'cc', 'rc'].includes(Layout.align)) {
                const el3 = document.getElementById('box_center_middle_' + el_idx);
                if (el3) {
                    el3.style.marginTop = -(new_height / 2) + 'px';
                }
            }
        }
    }

    renderContent() {
        const {
            project, site_id, Navigation_Data, page_id, Element, Site, Presets, show_tools, tab, Font_Families,
            use_service, signed_in, navigate, user_role, requiresNewEffect, level, effectClass, reloadForm,
            updateActionData, parent_disabled
        } = this.props;
        const { Content = [] } = Element;

        return (
            <div>{
                Content && Content.map(El =>
                    <Layer
                        key={El.el_idx}
                        Site={Site}
                        Navigation_Data={Navigation_Data}
                        Presets={Presets}
                        Font_Families={Font_Families}
                        project={project}
                        site_id={site_id}
                        page_id={page_id}
                        show_tools={show_tools}
                        use_service={use_service}
                        tab={tab}
                        holder_element_width_type="full"
                        holder_element_height_type="full"
                        amount_of_elements_in_holder={Content.length}
                        Element={El}
                        signed_in={signed_in}
                        navigate={navigate}
                        user_role={user_role}
                        requiresNewEffect={requiresNewEffect}
                        level={level}
                        effectClass={effectClass}
                        reloadForm={reloadForm}
                        updateActionData={updateActionData}
                        parent_disabled={parent_disabled}
                    />
                )}
            </div>
        );
    }

    render() {
        const { Navigation_Data, el_type, el_idx, Element, signed_in, navigate, Corrections } = this.props;
        const style = {};

        if (Corrections && Corrections.full_height_correction) {
            style.minHeight = Corrections.full_height_correction.height;
        }
        if (this.state.backgroundSize) {
            style.backgroundSize = this.state.backgroundSize;
        }
        if (this.state.height) {
            style.height = this.state.height;
        }

        const { Behavior, Background, Border, Corner, Shadow } = Element;

        const Events = buildEvents(Behavior, Navigation_Data, navigate);
        const cssclass = (Background && typeof (Background) !== 'object' ? 'background_' + Background : '') +
            (Border && typeof (Border) !== 'object' ? ' border_' + Border : '') +
            (Corner && typeof (Corner) !== 'object' ? ' corner_' + Corner : '') +
            (Shadow && typeof (Shadow) !== 'object' ? ' shadow_' + Shadow : '');

        return (
            <div
                id={[el_type, el_idx].join('_')}
                className={cssclass}
                style={Object.keys(style).length ? style : null}
                title={!Events.href && Events.tooltip && !signed_in ? Events.tooltip : null}
                onClick={!Events.href && Events.onClick && !signed_in ? Events.onClick : null}
                ref={this.setRef.bind(this)}
            >
                {Events.href ?
                    <a
                        href={Events.href}
                        target={Events.new_tab ? '_blank' : null}
                        title={Events.tooltip && !signed_in ? Events.tooltip : null}
                        className="noTextDecoration"
                        onClick={signed_in ? e => e.preventDefault() : (
                            Events.onClick ? e => { e.preventDefault(); Events.onClick(); } : null
                        )}
                    >
                        {this.renderContent()}
                    </a> :
                    this.renderContent()
                }
            </div>
        );
    }
}