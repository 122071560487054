import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';
import { buildGradient } from '../../css/gradient';

export default class Gradient extends Component {
    render() {
        const { frm_id, Data_Groups, fld_id, El_Data } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id) || null;
        const style = Object.assign({},
            {
                width: (El_Data.width || 10) + 'px',
                height: (El_Data.height || 10) + 'px',
                border: '1px solid #000'
            },
            (El_Data.Data ? buildGradient(El_Data.Data) : {})
        );

        return (
            <div className={El_Data.show_transparency_background ? 'form_transparency_background' : null}>
                <div id={id} style={style}></div>
            </div>
        );
    }
}