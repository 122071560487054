import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { ucWords } from '../../utils/string';

export default class StylingModuleVariantSettings {
    convertLoadDataToCurrentData(Load_Data) {
        const {
            Module_Variant, Page_Options, Page_Group_Options, Device_Options, Resolution_Options,
            Scroll_Zone_Options, Data_Value_Options
        } = Load_Data;

        const Current_Data = {
            Page_Options: Page_Options
                .map(Page_Option => [Page_Option.page_id, Page_Option.title || 'Home']),
            Page_Group_Options: Page_Group_Options
                .map(Page_Group_Option => [Page_Group_Option.idx, Page_Group_Option.name]),
            Device_Options: Device_Options
                .map(Device_Option => [Device_Option.id, ucWords(Device_Option.type)]),
            Data_Value_Options: Data_Value_Options
                .map(Data_Value_Option => [Data_Value_Option.id, Data_Value_Option.name]),
        };

        if (Module_Variant) {
            Current_Data.Module_Variant = {
                variant_type: 'mouse_over',
                page_id: Page_Options.length > 0 ? Page_Options[0].page_id : '',
                page_group_id: Page_Group_Options.length > 0 ? Page_Group_Options[0].idx : '',
                device_id: Device_Options.length > 0 ? Device_Options[0].id : '',
                resolution_id: Resolution_Options.length > 0 ? Resolution_Options[0][0] : '',
                scroll_zone_id: Scroll_Zone_Options.length > 0 ? Scroll_Zone_Options[0][0] : '',
                data_value_id: Data_Value_Options.length > 0 ? Data_Value_Options[0].id : '',
            };
        }

        return deepmerge(Load_Data, Current_Data);
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Module_Variant {
                variant_type: String
                page_id
                page_group_id
                device_id
                resolution_id
                scroll_zone_id
                data_value_id
            }
            Page_Options
            Page_Group_Options
            Device_Options
            Resolution_Options
            Scroll_Zone_Options
            Data_Value_Options
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs(
                'Module_Variant',
                [
                    [
                        { type: 'dropdown', id: 'variant_type', label: 'Type' },
                        {
                            value: Current_Data.Module_Variant.variant_type,
                            Options: [
                                ['mouse_over', 'Mouse hover'],
                                ['mouse_down', 'Mouse pressed down'],
                                ['page', 'Page'],
                            ]
                                .concat(Current_Data.Page_Group_Options.length > 0 ? [['page_group', 'Page Group']] : [])
                                .concat(Current_Data.Device_Options.length > 0 ? [['device', 'Device']] : [])
                                .concat(Current_Data.Resolution_Options.length > 0 ? [['resolution', 'Resolution']] : [])
                                .concat(Current_Data.Scroll_Zone_Options.length > 0 ? [['scroll_zone', 'Scroll Zone']] : [])
                                .concat(Current_Data.Data_Value_Options.length > 0 ? [['data_value', 'Data Value']] : []),
                            width: 260,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Module_Variant.variant_type === 'page' ? 'dropdown' : 'hidden',
                            id: 'page_id',
                            label: 'Page',
                        },
                        {
                            value: Current_Data.Module_Variant.page_id,
                            Options: Current_Data.Page_Options,
                            width: 260,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Page_Group_Options.length > 0 &&
                                Current_Data.Module_Variant.variant_type === 'page_group' ? 'dropdown' : 'hidden',
                            id: 'page_group_id',
                            label: 'Page group',
                        },
                        {
                            value: Current_Data.Module_Variant.page_group_id,
                            Options: Current_Data.Page_Group_Options,
                            width: 260,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Device_Options.length > 0 &&
                                Current_Data.Module_Variant.variant_type === 'device' ? 'dropdown' : 'hidden',
                            id: 'device_id',
                            label: 'Device',
                        },
                        {
                            value: Current_Data.Module_Variant.device_id,
                            Options: Current_Data.Device_Options,
                            width: 260,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Resolution_Options.length > 0 &&
                                Current_Data.Module_Variant.variant_type === 'resolution' ? 'dropdown' : 'hidden',
                            id: 'resolution_id',
                            label: 'Resolution',
                        },
                        {
                            value: Current_Data.Module_Variant.resolution_id,
                            Options: Current_Data.Resolution_Options,
                            width: 260,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Scroll_Zone_Options.length > 0 &&
                                Current_Data.Module_Variant.variant_type === 'scroll_zone' ? 'dropdown' : 'hidden',
                            id: 'scroll_zone_id',
                            label: 'Scroll zone',
                        },
                        {
                            value: Current_Data.Module_Variant.scroll_zone_id,
                            Options: Current_Data.Scroll_Zone_Options,
                            width: 260,
                        },
                    ],
                    [
                        {
                            type: Current_Data.Data_Value_Options.length > 0 &&
                                Current_Data.Module_Variant.variant_type === 'data_value' ? 'dropdown' : 'hidden',
                            id: 'data_value_id',
                            label: 'Data value',
                        },
                        {
                            value: Current_Data.Module_Variant.data_value_id,
                            Options: Current_Data.Data_Value_Options,
                            width: 260,
                        },
                    ]
                ]
            ),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.new_element) {
                    //TODO: need to make mechanism to use module variant data, when creating new element

                    layer_store.dispatch({
                        type: 'CLOSE_PANEL',
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'add_module_variant',
                            Function_Data,
                            process_tag: 'Adding...',
                            onSubmitted: Result_Data => {
                                updateActionData({
                                    access: 'authorized',
                                    panel_type: Action_Data.parent_panel_type,
                                    frm_name: Action_Data.parent_frm_name,
                                });
                            }
                        }
                    });
                }
            }, 'Add'),
        ];
    }
}
