import { distillColorFromCSSString } from './color';
import { distillFontFamilyId } from './font_family';
import { ucWords } from './string';

export const distillAttributesFromTag = (Fonts, tag_type, tag_state, tag) => {
    var Attributes = 0;

    if (tag_state === 'open' && 'section,span,link,font'.split(',').includes(tag_type)) {//TODO: also add support attributes on for "ol,ul,li,hr"
        var a_str = tryGetAttributesString(tag);
        if (a_str.length > 0) {
            Attributes = processAttributesString(Fonts, tag_type, a_str);
        }
    }

    return Attributes;
};

const tryGetAttributesString = (tag) => {
    return tag.indexOf(' ') > 0 ?
        tag.substr(tag.indexOf(' ') + 1) :
        '';
};

const processAttributesString = (Fonts, tag_type, a_str) => {
    var stop = 0,
        pos = 0,
        i = 0,
        Attributes = {},
        max_iterations = 10000,
        a, type, value, Color, add_attribute, seperator, equals_sign_pos;

    do {
        i++;
        add_attribute = false;
        seperator = a_str.indexOf(' ', pos);
        if (seperator > -1 && seperator > pos && (a_str.substr(seperator - 1, 1) === '\'' || a_str.substr(seperator - 1, 1) === '"')) {
            a = a_str.substr(pos, seperator - pos).trim();
            pos = seperator + 1;
        } else {
            a = a_str.substr(pos, a_str.length - pos + 1).trim();
            stop = 1;
        }

        equals_sign_pos = a.indexOf('=');
        if (
            equals_sign_pos > -1 &&
            equals_sign_pos > 0 &&
            (a[equals_sign_pos + 1] === '\'' || a[equals_sign_pos + 1] === '"') &&
            (a[a.length - 1] === '\'' || a[a.length - 1] === '"')
        ) {
            type = a.substr(0, equals_sign_pos).trim().toLowerCase().replace(/[^A-Za-z0-9_\-[\]]/g, '');
            value = a.substr(equals_sign_pos + 2, a.length - equals_sign_pos - 3).trim().replace(/[^A-Za-z0-9_.,:;!?(){}<>|^~#%@&=+/\-*[\]'\\"]\s+/g, '').trim();
            value = type !== 'title' ? value.toLowerCase() : value;

            if (value.length > 0) {
                if ('section,span,ol,ul,li,hr,link'.split(',').includes(tag_type)) {
                    if (type === 'style') {
                        value = distillStyles(Fonts, value);
                        add_attribute = true;
                    } else if (type === 'class' && /^[A-Za-z0-9_-]+$/.test(value) && value !== 'apple-tab-span') {
                        add_attribute = true;
                    }
                }
                if (tag_type === 'font') {
                    if (type === 'size' && '1,2,3,4,5,6,7'.split(',').includes(value)) {
                        type = 'style';
                        value = {
                            font_size: convertFontSizeToPercentage(value)
                        };
                        add_attribute = true;
                    } else if (type === 'color') {
                        Color = distillColorFromCSSString(value);
                        if (Color[0] !== '') {
                            type = 'style';
                            value = {
                                Color: {
                                    value: Color[0],
                                    opacity: Color[1]
                                }
                            };
                            add_attribute = true;
                        }
                    } else if (type === 'face') {
                        value = distillFontFamilyId(Fonts, value);
                        if (value !== 'unknown') {
                            type = 'style';
                            value = {
                                font_family_id: value
                            };
                            add_attribute = true;
                        }
                    }
                }
                if (tag_type === 'link') {
                    var id_prefix = 'text_behavior_';
                    if (type === 'id' && value.indexOf(id_prefix) === 0 && /^\d+$/.test(value.substr(id_prefix.length))) {
                        add_attribute = true;
                    } else if (type === 'href') {
                        add_attribute = true;
                    } else if (type === 'title') {
                        add_attribute = true;
                    } else if (type === 'target' && '_blank,_parent,_self,_top'.split(',').includes(value)) {
                        add_attribute = true;
                    }
                }
                if (add_attribute) {
                    Attributes[type] = value;
                }
            }
        }
    } while (!stop && i <= max_iterations && pos < a_str.length);
    if (i > max_iterations) {
        alert('Error 00662: Cannot complete process because maximum amount of iteration has been reached. If this error should not occur, contact us: mail@rootflex.com');//Bug in Model or too big Model
    }

    return Attributes;
};

const convertFontSizeToPercentage = (value) => {
    value = parseInt(value, 10);
    var px_value = 16;

    if (value === 1) {
        px_value = 9;
    } else if (value === 2) {
        px_value = 11;
    } else if (value === 3) {
        px_value = 12;
    } else if (value === 4) {
        px_value = 14;
    } else if (value === 5) {
        px_value = 16;
    } else if (value === 6) {
        px_value = 18;
    } else if (value === 7) {
        px_value = 22;
    }

    return (px_value / 16) * 100;
};

const distillStyles = (Fonts, s_str) => {//s_str = raw style string
    var stop = 0,
        pos = 0,
        i = 0,
        Styles = {},
        max_iterations = 10000,
        colon, semicolon, type, value, int_value, unit_of_length, Color;

    do {
        i += 1;
        colon = s_str.indexOf(':', pos);
        semicolon = s_str.indexOf(';', pos);
        if (colon > -1 && semicolon > -1 && semicolon > colon) {
            type = s_str.substr(pos, colon - pos).trim().toLowerCase().replace('-', '_');
            value = s_str.substr(colon + 1, semicolon - colon - 1).trim().toLowerCase();
            if (type === 'font_family') {
                value = distillFontFamilyId(Fonts, value);
                if (value !== 'unknown') {
                    Styles['font_family_id'] = value;
                }
            }
            if ('font_weight,font_style,text_decoration,text_align'.split(',').includes(type)) {
                if (
                    (type === 'font_weight' && 'normal,bold,bolder,lighter,100,200,300,400,500,600,700,800,900'.split(',').includes(value)) ||
                    (type === 'font_style' && 'normal,italic,oblique'.split(',').includes(value)) ||
                    (type === 'text_decoration' && 'none,underline,overline,line-through'.split(',').includes(value)) ||
                    (type === 'text_align' && 'right,center,justify'.split(',').includes(value))//'left' is default (could be improved; check by language default direction?)
                ) {
                    Styles[type] = value;
                }
            }
            if ('font_size,line_height,letter_spacing'.split(',').includes(type)) {
                if (value.substr(value.length - 3, 3) === 'rem') {
                    unit_of_length = value.substr(value.length - 3, 3);
                } else if ('px,em,cm'.split(',').includes(value.substr(value.length - 2, 2))) {
                    unit_of_length = value.substr(value.length - 2, 2);
                } else {
                    unit_of_length = value.substr(value.length - 1, 1);
                }
                int_value = parseInt(value.substr(0, value.length - unit_of_length.length), 10);
                if (
                    (int_value > 0 && int_value < 200 && 'rem,px,em,cm'.split(',').includes(unit_of_length)) ||
                    (int_value > 0 && int_value < 1250 && unit_of_length === '%')
                ) {
                    if (unit_of_length === 'px') {
                        int_value = (int_value / 16) * 100;//TODO: could be improved for line-height, but difficult, probably need to move this unit-conversion-stuff to after whole Model is build, then base not on 16px default, but on actual font-size of text-segment
                    } else if (['em', 'rem'].includes(unit_of_length)) {
                        int_value = int_value * 100;
                    } else if (unit_of_length === 'cm') {
                        int_value = int_value * 236.22047244375;
                    }
                    Styles[type] = int_value;
                }
            }
            if (type === 'color' || type === 'background_color') {
                Color = distillColorFromCSSString(value);
                if (Color[0] !== '') {
                    Styles[ucWords(type, true)] = {
                        value: Color[0],
                        opacity: Color[1]
                    };
                }
            }
            if (type === 'text_shadow') {
                //TODO: Needs multiple shadows

                /*var color_value, opacity, Shadow, h_shadow,
                    h_shadow_unit, v_shadow, v_shadow_unit, blur, blur_unit;

                Shadow = value.split(' ');
                h_shadow = 0;
                h_shadow_unit = Shadow[0].substr(Shadow[0].length - 2, 2);
                if (h_shadow_unit === 'px') {
                    h_shadow = parseInt(Shadow[0], 10);
                } else if (h_shadow_unit === 'em') {
                    h_shadow = parseFloat(Shadow[0]);
                }
                v_shadow = 0;
                v_shadow_unit = Shadow[1].substr(Shadow[1].length - 2, 2);
                if (v_shadow_unit === 'px') {
                    v_shadow = parseInt(Shadow[1], 10);
                } else if (v_shadow_unit === 'em') {
                    v_shadow = parseFloat(Shadow[1]);
                }
                blur = 0;
                blur_unit = 'px';
                if (typeof (Shadow[2]) !== 'undefined') {
                    blur_unit = Shadow[2].substr(Shadow[2].length - 2, 2);
                    if (blur_unit === 'px') {
                        blur = parseInt(Shadow[2], 10);
                    } else if (blur_unit === 'em') {
                        blur = parseFloat(Shadow[2]);
                    }
                }
                color_value = '000';
                if (typeof (Shadow[3]) !== 'undefined') {
                    Color = distillColorFromCSSString(Shadow[3]);
                    if (Color[0] !== '') {
                        color_value = Color[0];
                        opacity = Color[1];
                    }
                }
                if (h_shadow_unit && v_shadow_unit) {
                    Styles['Shadows'] = [{
                        right: h_shadow,
                        bottom: v_shadow,
                        fuzziness: blur,
                        color: color_value,
                        opacity: opacity
                    }];
                }*/
            }
        }
        if (colon !== -1 && semicolon !== -1) {
            pos = semicolon + 1;
        } else {
            stop = 1;
        }
    } while (!stop && i <= max_iterations && pos < s_str.length);

    if (i > max_iterations) {
        alert('Error 00663: Cannot complete process because maximum amount of iteration has been reached. If this error should not occur, contact us: mail@rootflex.com');//Bug in Model or too big Model
    }

    if (Styles.length === 0) {
        Styles = 0;
    }

    return Styles;
};