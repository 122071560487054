import deepmerge from 'deepmerge';
import $ from 'jquery';
import layer_store from './layer_store';
import tools_store from './tools_store';

export const postData = (
    frm_id, Data_Groups, submit_action, Function_Data, Forms_Post_Data, process_tag, onSubmitted, onClick, isPanelRequiredToStayOpen, post_language,
    holder_type
) => {
    Forms_Post_Data = Forms_Post_Data || {};

    if (onClick !== '' && typeof (onClick) === 'string') {
        Function(
            `${'"use strict";\n' +
            'var Data_Groups = arguments[0];\n' +
            'var Function_Data = arguments[1]; \n' +
            'var $ = arguments[2]; \n' +
            'var frm_id = arguments[3]; \n' +
            'var buildFormElementID = (frm_id, Data_Groups, fld_id, non_prefixed_id = false) => {' +
            '   return non_prefixed_id ? fld_id :' +
            '      (frm_id ? [frm_id] : []).concat(Data_Groups).concat(fld_id ? [fld_id] : [])' +
            '      .map(part => part.toLowerCase()).join("_");' +
            '};'}${onClick}`
        ).call(this, Data_Groups, Function_Data, $, frm_id);
    }
    if (typeof (onClick) === 'function') {
        onClick();
    }

    //TODO: filter disabled fields from Current_Data ?

    const req = new XMLHttpRequest();
    const data = {
        site_id: Function_Data.site_id,
        submit_action,
        project: Function_Data.project || undefined,
        default_language_id: post_language ? Function_Data.default_language_id : undefined,
        language_id: post_language ? Function_Data.language_id : undefined
    };

    req.open('POST', `${Function_Data.base_url}$/post_data/`);
    req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    // req.withCredentials = true; // CORS
    req.send(JSON.stringify(deepmerge(data, Forms_Post_Data)));
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            onPostCompleted(req, frm_id, Function_Data, onSubmitted, isPanelRequiredToStayOpen, holder_type);
        }
    };
};

const onPostCompleted = (req, frm_id, Function_Data, onSubmitted, isPanelRequiredToStayOpen, holder_type) => {
    if (!isErrorReturned(req) && req.status === 200 && onSubmitted) {
        const Data = JSON.parse(req.response);
        if (onSubmitted !== '' && typeof (onSubmitted) === 'string') {
            (new Function(
                `${'"use strict";\n' +
                'var Result_Data = arguments[0]; \n' +
                'var Function_Data = arguments[1]; \n' +
                'var $ = arguments[2]; \n' +
                'var frm_id = arguments[3]; \n'}${onSubmitted}`
            )).call(this, Data, Function_Data, $, frm_id);
        }
        if (typeof (onSubmitted) === 'function') {
            onSubmitted(Data, Function_Data);
        }
    }

    if (!isPanelRequiredToStayOpen) {
        if (holder_type === 'overlay') {
            tools_store.dispatch({
                type: 'CLOSE_OVERLAY_TOOLS',
            });
        } else if (holder_type === 'panel') {
            layer_store.dispatch({
                type: 'CLOSE_PANEL',
            });
        }
    }
};

const isErrorReturned = (req) => {
    let error_txt = '';

    if (!req || !req.responseText) {
        error_txt = 'No response to API request.';
    }

    try {
        const Data = JSON.parse(req.responseText);
        if (Data['xdebug-error']) {
            error_txt = Data['xdebug-error'];
        } else if (Data.error) {
            error_txt = String(Data.error);
        }
    } catch (error) {
        error_txt = 'Invalid JSON returned';
    }

    if (error_txt) {
        console.log(error_txt);
        return true;
    }

    return false;
};
