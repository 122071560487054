import deepmerge from 'deepmerge';
import custom_font_store from '../../api/custom_font_store';
import layer_store from '../../api/layer_store';
import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class CustomFontManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [custom_font_store.subscribe(this.handleCustomFontStoreChange(changeCurrentData, getCurrentData))];
    }

    handleCustomFontStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = custom_font_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Custom_Fonts = clone(Current_Data.Custom_Fonts);

            if (state.action === 'add') {
                Changed_Custom_Fonts.push(state.New_Custom_Font);
            }
            if (state.action === 'update') {
                Changed_Custom_Fonts = Changed_Custom_Fonts.map(Custom_Font => {
                    if (parseInt(Custom_Font.id, 10) === parseInt(state.Updated_Custom_Font.id, 10)) {
                        return state.Updated_Custom_Font;
                    }
                    return Custom_Font;
                });
            }
            if (state.action === 'remove') {
                const index = findIndexArray(
                    Changed_Custom_Fonts,
                    Custom_Font => parseInt(Custom_Font.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Custom_Fonts.splice(index, 1);
            }

            changeCurrentData({ Custom_Fonts: Changed_Custom_Fonts });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Custom_Fonts
            max_file_uploads
            upload_max_filesize
            post_max_size
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Custom_Font', [
                [
                    { type: 'text' },
                    { text: 'External Fonts', style: 'bold' }
                ],
                [
                    { type: 'external_fonts_management', id: 'font_overview' },
                    { Fonts: Current_Data.Custom_Fonts.filter(Custom_Font => Custom_Font.source !== 'uploaded') }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'new_external_custom_font',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [185, 16],
                        text: 'Add External Font',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_custom_font_panel',
                                            frm_name: 'custom_font_settings',
                                            custom_font_id: 0,
                                            data_action_type: 'create',
                                            source: 'external_host'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'text' },
                    { text: 'File-Based Fonts', style: 'bold' }
                ],
                [
                    { type: 'uploaded_fonts_management', id: 'font_overview' },
                    {
                        Custom_Fonts: Current_Data.Custom_Fonts,
                        max_file_uploads: Current_Data.max_file_uploads,
                        upload_max_filesize: Current_Data.upload_max_filesize,
                        post_max_size: Current_Data.post_max_size
                    }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'new_custom_uploaded_font',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [185, 16],
                        text: 'Create New File-Based Font',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_custom_font_panel',
                                            frm_name: 'custom_font_settings',
                                            custom_font_id: 0,
                                            data_action_type: 'create',
                                            source: 'uploaded'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}