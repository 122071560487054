export const buildFormElementID = (frm_id, Data_Groups, fld_id, non_prefixed_id = false) => {
    return non_prefixed_id ? fld_id :
        (frm_id ? [frm_id] : [])
            .concat(Data_Groups)
            .concat(fld_id ? [fld_id] : [])
            .map(part => part.toLowerCase())
            .join('_');
};

export const buildFormElementName = (Data_Groups, fld_name, type = '') => {
    if (type === 'submit') {
        return fld_name;
    }
    
    const Parts = (Data_Groups && Data_Groups.length > 0 ? Data_Groups : [])
        .concat([fld_name]);
    
    return Parts
        .slice(0, 1)
        .concat(Parts
            .slice(1)
            .map(part => '[' + part + ']')
        )
        .join('');
};

export const convertDataGroupsAndFldIdToChangeSet = (keys, value) => {
    const New_Data = {};

    let schema = New_Data;
    for(let i = 0; i < keys.length; i++) {
        schema[keys[i]] = i < keys.length - 1 ? {} : value;
        schema = schema[keys[i]];
    }

    return New_Data;
};