import React, { Component, Fragment } from 'react';
import Panel from './panel';
import layer_store from '../api/layer_store';
import ColorPicker from './color';
import Lightbox from './lightbox';
import DatePicker from './date';
import MobileMenu from './mobile_menu';

export default class Layers extends Component {
    constructor(props) {
        super(props);
        this.unsubscribes = [];
        this.PanelsAmount = 0;
        this.hasColorPicker = false;
        this.hasDatePicker = false;
        this.hasMobileMenu = false;
    }

    componentDidMount() {
        this.unsubscribes = [layer_store.subscribe(this.handleStoreChange.bind(this))];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleStoreChange() {
        let shouldUpdate = false;
        const state = layer_store.getState();

        if (state.Panels.length !== this.PanelsAmount) {
            this.PanelsAmount = state.Panels.length;
            shouldUpdate = true;
        }

        if (!this.hasColorPicker && state.ColorPicker) {
            this.hasColorPicker = true;
            shouldUpdate = true;
        }

        if (this.hasColorPicker && !state.ColorPicker) {
            this.hasColorPicker = false;
            shouldUpdate = true;
        }

        if (!this.hasDatePicker && state.DatePicker) {
            this.hasDatePicker = true;
            shouldUpdate = true;
        }

        if (this.hasDatePicker && !state.DatePicker) {
            this.hasDatePicker = false;
            shouldUpdate = true;
        }

        if (!this.hasMobileMenu && state.MobileMenu) {
            this.hasMobileMenu = true;
            shouldUpdate = true;
        }

        if (this.hasMobileMenu && !state.MobileMenu) {
            this.hasMobileMenu = false;
            shouldUpdate = true;
        }

        if (!this.hasLightbox && state.Lightbox) {
            this.hasLightbox = true;
            shouldUpdate = true;
        }

        if (this.hasLightbox && !state.Lightbox) {
            this.hasLightbox = false;
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            this.forceUpdate();
        }
    }

    render() {
        const { navigate, Navigation_Data } = this.props;
        const state = layer_store.getState();
        return (
            <Fragment>
                {state.Panels.map((panel_data, index) =>
                    <Panel
                        key={index}
                        index={index}
                        panel_data={panel_data}
                        navigate={navigate}
                    ></Panel>
                )}
                {this.hasColorPicker &&
                    <ColorPicker
                        id={state.ColorPicker.id}
                        value={state.ColorPicker.value}
                        el={state.ColorPicker.el}
                        onChange={state.ColorPicker.onChange}
                    />
                }
                {this.hasDatePicker &&
                    <DatePicker
                        value={state.DatePicker.value}
                        format={state.DatePicker.format}
                        el={state.DatePicker.el}
                        onChange={state.DatePicker.onChange}
                    />
                }
                {this.hasMobileMenu &&
                    <MobileMenu
                        navigate={navigate}
                        Navigation_Data={Navigation_Data}
                        Behavior={state.MobileMenu.Behavior}
                    />
                }
                {this.hasLightbox &&
                    <Lightbox
                        Navigation_Data={Navigation_Data}
                        active_image={state.Lightbox.active_image}
                    />
                }
            </Fragment>
        );
    }
}