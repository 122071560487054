import React, { Component, Fragment } from 'react';
import layer_store from '../api/layer_store';
import layout_store from '../api/layout_store';
import { getDecimalSize, getViewport, toggleBodyOverflow } from '../utils/browser';
import { clone } from '../utils/object';
import { buildURL } from '../utils/path';
import { ucWords } from '../utils/string';

export default class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.myRef;
        this.unsubscribes = [];

        const { Pages } = this.props.Behavior;

        this.state = {
            Pages: this.buildPageStates(Pages),
            viewport: getViewport(),
            menuElementHeight: 0
        };
        this.myRef;
    }

    setRef(Indexes) {
        return (el) => {
            if (Indexes.length === 1 && Indexes[0] === 0) {
                this.myRef = el;
            }
        };
    }

    buildPageStates(Pages) {
        return Pages.map(Page => ({
            isCollapsed: true,
            Sub_Pages: Page.Sub_Pages ? this.buildPageStates(Page.Sub_Pages) : []
        }))
    }

    componentDidMount() {
        this.setState({
            menuElementHeight: getDecimalSize(this.myRef).h
        });

        toggleBodyOverflow(true);

        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
        ];
    }

    componentWillUnmount() {
        toggleBodyOverflow(false);
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleLayoutStoreChange() {
        const { viewport, action } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.setState({
                viewport: viewport,
                menuElementHeight: getDecimalSize(this.myRef).h
            });
        }
    }

    onClickLink(Page_URLs) {
        const { navigate, Navigation_Data } = this.props;
        const { default_language_type, language_type, device_type, base_url, site_url } = Navigation_Data;

        return (e) => {
            e.preventDefault();
            navigate(default_language_type, language_type, device_type, base_url, site_url, Page_URLs);
            layer_store.dispatch({
                type: 'HIDE_MOBILE_MENU',
            });
        }
    }

    onClickClose() {
        layer_store.dispatch({
            type: 'HIDE_MOBILE_MENU',
        });
    }

    switchCollapseState(Page_States, Indexes) {
        return Page_States.map((Page, i) => {
            if (Indexes.length > 0 && i === Indexes[0]) {
                if (Indexes.length > 1) {
                    Page.Sub_Pages = this.switchCollapseState(
                        Page.Sub_Pages,
                        Indexes.slice(1)
                    );
                } else {
                    Page.isCollapsed = !Page.isCollapsed;
                }
            }
            return Page;
        });
    }

    onClickExpandableButton(Indexes) {
        return () => {
            this.setState({
                Pages: this.switchCollapseState(
                    clone(this.state.Pages),
                    Indexes
                )
            });
        };
    }

    renderItems(Pages, Indexes, Page_States) {
        const { Navigation_Data } = this.props;
        const { default_language_type, language_type, device_type, base_url, site_url } = Navigation_Data;

        return Pages.map((Page, i) => {
            const New_Indexes = Indexes.concat([i]);
            const { isCollapsed } = Page_States[i];

            return (
                <Fragment key={New_Indexes.join('_')}>
                    <div
                        className={'mobile_menu_element_holder ' + (i % 2 ? 'odd' : 'even')}
                        ref={this.setRef(New_Indexes).bind(this)}
                    >
                        <a
                            href={buildURL(default_language_type, language_type, device_type, base_url, site_url, Page.Page_URLs)}
                            target="_self"
                            className="mobile_menu_link middle_by_table"
                            onClick={this.onClickLink(Page.Page_URLs).bind(this)}
                        >
                            <div>
                                <div>{ucWords(Page.title)}</div>
                            </div>
                        </a>
                        {Page.Sub_Pages.length > 0 &&
                            <div
                                className="expandable_menu_element middle_by_table center_text"
                                onClick={this.onClickExpandableButton(New_Indexes).bind(this)}
                            >
                                <div>{isCollapsed ? '+' : '-'}</div>
                            </div>
                        }
                    </div>
                    {Page.Sub_Pages.length > 0 &&
                        <div
                            className="mobile_menu_item_sub_pages"
                            style={isCollapsed ? null : { height: (this.state.menuElementHeight * Page.Sub_Pages.length) + 'px' }}
                        >
                            <div>
                                {this.renderItems(Page.Sub_Pages, New_Indexes, Page_States[i].Sub_Pages)}
                            </div>
                        </div>
                    }
                </Fragment>
            );
        })
    }

    render() {
        const { Pages } = this.props.Behavior;
        const { viewport } = this.state;

        return (
            <div
                id="mobile_menu"
                className={viewport.w > viewport.h ? 'hor' : 'ver'}
                style={{ fontSize: ((viewport.w > viewport.h ? viewport.w : viewport.h) / 100) + 'px' }}
            >
                <div id="mobile_menu_header">
                    <div id="mobile_menu_title" className="middle_by_table center_text">
                        <div>
                            <div>Menu</div>
                        </div>
                    </div>
                    <div
                        id="mobile_menu_close_icon"
                        className="middle_by_table center_text"
                        onClick={this.onClickClose}
                    ><div>
                            <div>X</div>
                        </div>
                    </div>
                </div>
                <div id="mobile_menu_pages">{this.renderItems(Pages, [], this.state.Pages)}</div>
            </div>
        );
    }
}