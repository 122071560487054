import deepmerge from 'deepmerge';
import language_store from '../../api/language_store';
import layer_store from '../../api/layer_store';
import { getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { findIndexArray } from '../../utils/array';
import { clone } from '../../utils/object';

export default class LanguageManagement {
    subscribeToStores(changeCurrentData, getCurrentData) {
        return [language_store.subscribe(this.handleLanguageStoreChange(changeCurrentData, getCurrentData))];
    }

    handleLanguageStoreChange(changeCurrentData, getCurrentData) {
        return () => {
            const state = language_store.getState();
            const Current_Data = getCurrentData();
            let Changed_Languages = clone(Current_Data.Languages);

            if (state.action === 'add') {
                Changed_Languages.push(state.New_Language);
            }
            if (state.action === 'remove') {
                const index = findIndexArray(
                    Changed_Languages,
                    Language => parseInt(Language.id, 10),
                    parseInt(state.removed_id, 10)
                );

                Changed_Languages.splice(index, 1);
            }

            changeCurrentData({ Languages: Changed_Languages });
        };
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Languages
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Language', style: 'subtitle' }
                ],
                [
                    { type: 'text', colspan: 2 },
                    {
                        text:
                            'When multiple languages are enabled, you can<br>' +
                            'write language-variants for each text.',
                        style: 'normal_grey'
                    }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'language_management', id: 'languages', colspan: 2 },
                    { Languages: Current_Data.Languages }
                ],
                [
                    {
                        type: 'icon_link',
                        id: 'add_new_language',
                        colspan: 2,
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5
                    },
                    {
                        Style: [114, 16],
                        text: 'Add Language',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_language_panel',
                                            frm_name: 'language_settings',
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'create'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getSingleSubmitSet(frm_id,)
        ];
    }
}