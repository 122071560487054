import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColIconLinkSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Icon_Link: {
                    button_class: '',
                    resource_id: 0,
                    img_class: '',
                    name: '',
                    colspan: 1,
                    h_align: 'center',
                    v_align: 'middle',
                    padding_left: 0,
                    padding_right: 0,
                    label: '',
                    width: 30,
                    height: 30,
                    button_padding: 0,
                    button_padding_left: 0,
                    button_padding_right: 0,
                    button_padding_top: 0,
                    button_padding_bottom: 0,
                    text: '',
                    img_width: 30,
                    img_height: 30,
                    img_left: 0,
                    img_top: 0
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Icon_Link {
                    button_class: String
                    resource_id
                    img_class: String
                    name: String
                    colspan
                    h_align: String
                    v_align: String
                    padding_left
                    padding_right
                    label: String
                    width
                    height
                    button_padding
                    button_padding_left
                    button_padding_right
                    button_padding_top
                    button_padding_bottom
                    text: String
                    img_width
                    img_height
                    img_left
                    img_top
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Icon_Link', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Icon_Link.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Icon_Link.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'dropdown', id: 'h_align', label: 'Horizontal align' },
                    {
                        value: Current_Data.Form_Col_Icon_Link.h_align,
                        Options: [
                            ['left', 'Left'],
                            ['center', 'Center'],
                            ['right', 'Right']
                        ]
                    }
                ],
                [
                    { type: 'dropdown', id: 'v_align', label: 'Vertical align' },
                    {
                        value: Current_Data.Form_Col_Icon_Link.v_align,
                        Options: [
                            ['top', 'Top'],
                            ['middle', 'Center'],
                            ['bottom', 'Bottom']
                        ]
                    }
                ],
                [
                    { type: 'number', id: 'padding_left', label: 'Padding left' },
                    { value: Current_Data.Form_Col_Icon_Link.padding_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'padding_right', label: 'Padding right' },
                    { value: Current_Data.Form_Col_Icon_Link.padding_right, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Icon_Link.label, max: 2 }
                ],
                [
                    { type: 'number', id: 'width', label: 'Width' },
                    { value: Current_Data.Form_Col_Icon_Link.width, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'height', label: 'Height' },
                    { value: Current_Data.Form_Col_Icon_Link.height, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding', label: 'Button padding' },
                    { value: Current_Data.Form_Col_Icon_Link.button_padding, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_left', label: 'Button padding left' },
                    { value: Current_Data.Form_Col_Icon_Link.button_padding_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_right', label: 'Button padding right' },
                    { value: Current_Data.Form_Col_Icon_Link.button_padding_right, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_top', label: 'Button padding top' },
                    { value: Current_Data.Form_Col_Icon_Link.button_padding_top, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'button_padding_bottom', label: 'Button padding bottom' },
                    { value: Current_Data.Form_Col_Icon_Link.button_padding_bottom, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'field', id: 'text', label: 'Text' },
                    { value: Current_Data.Form_Col_Icon_Link.text, max: 2 }
                ],
                [
                    { type: 'number', id: 'img_width', label: 'Image width' },
                    { value: Current_Data.Form_Col_Icon_Link.img_width, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'img_height', label: 'Image height' },
                    { value: Current_Data.Form_Col_Icon_Link.img_height, min_val: 1, max_val: 999, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'img_left', label: 'Image left' },
                    { value: Current_Data.Form_Col_Icon_Link.img_left, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'img_top', label: 'Image top' },
                    { value: Current_Data.Form_Col_Icon_Link.img_top, min_val: 0, max_val: 100, unit: 'px' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_icon_link_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}