import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import media_item_store from '../../api/media_item_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveOriginImage {
    getQuery() {
        return `query DataQuery($urls: String!) {
            resource_id
            usage_amount
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', colspan: 2 },
                    {
                        text: Current_Data.usage_amount > 0 ?
                            (
                                'The original image can\'t be deleted<br>' +
                                'from the collection, because there\'s<br>' +
                                'still ' +
                                (Current_Data.usage_amount === 1 ? '1 place' : + Current_Data.usage_amount + ' places') +
                                ' where this image is<br>' +
                                'being used.<br>'
                            ) :
                            'Do you really want to delete the original image from the collection?',
                        style: 'bold'
                    }
                ]
            ]),
            Current_Data.usage_amount > 0 ?
                getSingleSubmitSet(frm_id, 'OK') :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_origin_image',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: removed_id => {
                                if (removed_id) {
                                    media_item_store.dispatch({
                                        type: 'REMOVE',
                                        removed_id
                                    });
                                } else {
                                    alert('Some error occured when trying to delete the image.');
                                }
                            }
                        }
                    });
                }, 'Delete')
        ];
    }
}