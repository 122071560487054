import React, { Component } from 'react';
import { buildFormElementID } from '../../utils/form';
import Number from './number';

export default class LayoutEditor extends Component {
    renderSpaceFields(Fields) {
        const { Layout } = this.props.El_Data.Current_Data;
        return this.renderFields(Fields.map(Field => {
            const fld_id = 'space_' + Field[0] + '_' + Field[1] + '_' + Field[3] + '_' + Field[2];
            return {
                cls: 'space_' + Field[0] + '_' + Field[1] + '_' + Field[2] + '_' + Field[3],
                fld_id: fld_id,
                El_Data: {
                    value: Layout[fld_id],
                    min_val: 0,
                    max_val: Field[3] === 'pixel' ? 9999 : 99,
                    direction: 'ver',
                    unit: Field[3] === 'pixel' ? 'px' : '%'
                },
                type: Field[1] + '_' + (Field[3] === 'pixel' ? 'px' : 'per')
            };
        }));
    }

    renderSizeFields(Fields) {
        const { Current_Data, w_min, h_min, w_max, h_max } = this.props.El_Data;
        return this.renderFields(Fields.map(Field => {
            const cls = Field[0] + '_' + Field[2];
            return {
                cls: cls,
                fld_id: cls,
                El_Data: {
                    value: Current_Data.Layout[cls],
                    min_val: (Field[2] === 'pixel' ? (Field[0] === 'width' ? w_min : h_min) : 1),
                    max_val: (Field[2] === 'pixel' ? (Field[0] === 'width' ? w_max : h_max) : 1),
                    direction: 'ver',
                    unit: Field[2] === 'pixel' ? 'px' : '%'
                },
                type: Field[1] + '_' + (Field[2] === 'pixel' ? 'px' : 'per')
            };
        }));
    }

    renderFields(Fields) {
        const { frm_id, Data_Groups, tab, parent_disabled, changeCurrentData } = this.props;
        return Fields.map((Field, index) => {
            const { cls, fld_id, El_Data, type } = Field;
            return (
                <div
                    key={index}
                    id={'col_' + buildFormElementID(frm_id, Data_Groups, cls)}
                    className={'layout_editor_' + cls + ' layout_editor_amount_field layout_editor_amount_field_' + type}
                >
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={fld_id}
                        tab={tab}
                        El_Data={El_Data}
                        Col_Settings={{}}
                        parent_disabled={parent_disabled}
                        changeCurrentData={changeCurrentData}
                    />
                </div>
            );
        });
    }

    onClickAlign(direction) {
        const { changeCurrentData, El_Data } = this.props;
        const { align } = El_Data.Current_Data.Layout;

        changeCurrentData({
            Layout: {
                align: {
                    left: (align[0] === 'l' ? 'c' : 'l') + align[1],
                    right: (align[0] === 'r' ? 'c' : 'r') + align[1],
                    top: align[0] + (align[1] === 't' ? 'c' : 't'),
                    bottom: align[0] + (align[1] === 'b' ? 'c' : 'b')
                }[direction]
            }
        });
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab, parent_disabled, changeCurrentData } = this.props;
        const { el_type, Current_Data, w_min, h_min, w_max, h_max } = El_Data;
        const {
            Layout, holder_el_type, holder_sub_amount,
            holder_width_type, holder_height_type, total_width, total_height
        } = Current_Data;
        const { align, width_type, hor_amount_type, ver_amount_type, height_type } = Layout;

        const style = {
            float: 'left',
            position: 'relative',
            width: '350px',
            paddingLeft: '4px'
        },
            isSingular = ['root', 'box'].includes(holder_el_type) && parseInt(holder_sub_amount, 10) < 2,
            isSingularFitWidth = isSingular && holder_width_type === 'fit',
            isSingularFitHeight = isSingular && holder_height_type === 'fit',
            isAlignEnabled = holder_el_type !== 'sequence' &&
                (total_width !== 'full' || total_height !== 'full') &&
                !(isSingularFitWidth && isSingularFitHeight) &&
                !(el_type === 'text' && isSingularFitHeight),
            isForcedLeftAligned = el_type === 'text' && !isSingularFitHeight,
            isForcedHorizontallyCentered = total_width === 'full' || isSingularFitWidth,
            isForcedVerticallyCentered = total_height === 'full' || isSingularFitHeight;

        return (
            <div
                id={buildFormElementID(frm_id, Data_Groups, fld_id)}
                style={style}
            >
                <div
                    className={
                        'layout_editor_top_align layout_editor_align_button fa fa-angle-up' +
                        (['lt', 'ct', 'rt'].includes(align) ? ' selected' : '') +
                        (!isAlignEnabled || isForcedVerticallyCentered ? ' disabled' : '')
                    }
                    onClick={() => this.onClickAlign('top')}
                ></div>
                <div className="layout_editor_middle_holder">
                    <div
                        className={
                            'layout_editor_left_align layout_editor_align_button fa fa-angle-left' +
                            (['lt', 'lc', 'lb'].includes(align) ? ' selected' : '') +
                            (!isAlignEnabled || isForcedHorizontallyCentered ? ' disabled' : '')
                        }
                        onClick={() => this.onClickAlign('left')}
                    ></div>
                    <div className="layout_editor_sizes_holder">
                        <div className="layout_editor_outside_space">
                            <div className="layout_editor_main_element">
                                {this.renderSpaceFields([
                                    ['inside', 'hor', 1, hor_amount_type == 'px' ? 'pixel' : 'percentage'],
                                    ['inside', 'hor', 2, hor_amount_type == 'px' ? 'pixel' : 'percentage'],
                                    ['inside', 'ver', 1, ver_amount_type == 'px' ? 'pixel' : 'percentage'],
                                    ['inside', 'ver', 2, ver_amount_type == 'px' ? 'pixel' : 'percentage'],
                                ])}
                                <div className="layout_editor_inside_space"></div>
                            </div>
                        </div>
                        {this.renderSpaceFields([
                            ['outside', 'hor', 1, hor_amount_type == 'px' ? 'pixel' : 'percentage'],
                            ['outside', 'hor', 2, hor_amount_type == 'px' ? 'pixel' : 'percentage'],
                            ['outside', 'ver', 1, ver_amount_type == 'px' ? 'pixel' : 'percentage'],
                            ['outside', 'ver', 2, ver_amount_type == 'px' ? 'pixel' : 'percentage'],
                        ])}
                        {width_type === 'cus' &&
                            <div
                                id={'col_' + buildFormElementID(frm_id, Data_Groups, 'width_' + (hor_amount_type === 'px' ? 'pixel' : 'percentage'))}
                                className={
                                    'layout_editor_width_' + (hor_amount_type === 'px' ? 'pixel' : 'percentage') +
                                    ' layout_editor_amount_field layout_editor_amount_field_' +
                                    'hor_' + hor_amount_type
                                }
                            >
                                <Number
                                    frm_id={frm_id}
                                    Data_Groups={Data_Groups}
                                    fld_id={'width_' + (hor_amount_type === 'px' ? 'pixel' : 'percentage')}
                                    tab={tab}
                                    El_Data={{
                                        value: Current_Data.Layout['width_' + (hor_amount_type === 'px' ? 'pixel' : 'percentage')],
                                        min_val: hor_amount_type === 'px' ? w_min : 1,
                                        max_val: hor_amount_type === 'px' ? w_max : 100,
                                        direction: 'ver',
                                        unit: hor_amount_type === 'px' ? 'px' : '%'
                                    }}
                                    Col_Settings={{}}
                                    changeCurrentData={changeCurrentData}
                                    parent_disabled={parent_disabled}
                                    onChange={value => () => {
                                        return el_type === 'image' ?
                                            {
                                                Layout: {
                                                    ['height_' + (hor_amount_type === 'px' ? 'pixel' : 'percentage')]: Math.floor(value / (w_max / h_max))
                                                }
                                            } :
                                            null
                                    }
                                    }
                                />
                            </div>
                        }
                        {height_type === 'cus' &&
                            <div
                                id={'col_' + buildFormElementID(frm_id, Data_Groups, 'height_' + (ver_amount_type === 'px' ? 'pixel' : 'percentage'))}
                                className={
                                    'layout_editor_height_' + (ver_amount_type === 'px' ? 'pixel' : 'percentage') +
                                    ' layout_editor_amount_field layout_editor_amount_field_' +
                                    'ver_' + ver_amount_type
                                }
                            >
                                <Number
                                    frm_id={frm_id}
                                    Data_Groups={Data_Groups}
                                    fld_id={'height_' + (ver_amount_type === 'px' ? 'pixel' : 'percentage')}
                                    tab={tab}
                                    El_Data={{
                                        value: Current_Data.Layout['height_' + (ver_amount_type === 'px' ? 'pixel' : 'percentage')],
                                        min_val: ver_amount_type === 'px' ? h_min : 1,
                                        max_val: ver_amount_type === 'px' ? h_max : 100,
                                        direction: 'ver',
                                        unit: ver_amount_type === 'px' ? 'px' : '%'
                                    }}
                                    Col_Settings={{}}
                                    changeCurrentData={changeCurrentData}
                                    parent_disabled={parent_disabled}
                                    onChange={value => () => {
                                        return el_type === 'image' ?
                                            {
                                                Layout: {
                                                    ['width_' + (ver_amount_type === 'px' ? 'pixel' : 'percentage')]: Math.floor(value / (h_max / w_max))
                                                }
                                            } :
                                            null
                                    }
                                    }
                                />
                            </div>
                        }
                    </div>
                    <div
                        className={
                            'layout_editor_right_align layout_editor_align_button fa fa-angle-right' +
                            (['rt', 'rc', 'rb'].includes(align) ? ' selected' : '') +
                            (!isAlignEnabled || isForcedLeftAligned || isForcedHorizontallyCentered ? ' disabled' : '')
                        }
                        onClick={() => this.onClickAlign('right')}
                    ></div>
                </div>
                <div
                    className={
                        'layout_editor_bottom_align layout_editor_align_button fa fa-angle-down' +
                        (['lb', 'cb', 'rb'].includes(align) ? ' selected' : '') +
                        (!isAlignEnabled || isForcedVerticallyCentered ? ' disabled' : '')
                    }
                    onClick={() => this.onClickAlign('bottom')}
                ></div>
            </div>
        );
    }
}