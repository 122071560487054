import { convertHexToRGB } from '../utils/color';
import { clone } from '../utils/object';

export const buildGradient = Gradient => {
    return {
        backgroundImage: 'linear-gradient(' +
            {
                hor: 'to right',
                ver: 'to bottom',
                dia1: '135deg',
                dia2: '-135deg'
            }[Gradient.direction] +
            ',' +
            clone(Gradient.Points)
                .sort((a, b) => a.coordinate - b.coordinate)
                .map(Point => {
                    const RGB = convertHexToRGB(Point.color);
                    return 'rgba(' +
                        RGB.r + ',' +
                        RGB.g + ',' +
                        RGB.b + ',' +
                        (Point.opacity < 100 ? Point.opacity / 100 : '1.0') +
                        ') ' +
                        Point.coordinate + '%';
                })
                .join(',') +
            ')'
    };
};