import { createStore } from 'redux';

const initialState = {
    action: '',
    Images: []
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'ADD':
            state.Images = state.Images.concat(action.Images);
            return state;
        case 'REMOVE':
            state.Images = state.Images.filter(Image =>
                !action.Images.find(Removal_Image => Removal_Image.href === Image.href)//perhaps better to use el_idx then href
            );
            return state;
        default:
            return state;
    }
});

export default store;