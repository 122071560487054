import file_store from '../../api/file_store';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveFile {
    getQuery() {
        return null;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', h_align: 'center' },
                    { text: 'Are you sure?', style: 'bold' }
                ], [
                    { type: 'space', colspan: 2, height: 23 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'remove_file',
                        Function_Data,
                        process_tag: 'Removing...',
                        onSubmitted: removed_id => {
                            if (removed_id) {
                                file_store.dispatch({
                                    type: 'REMOVE',
                                    removed_id
                                });
                            } else {
                                alert('Some error occured when trying to delete the file.');
                            }
                        }
                    }
                });
            }, 'Delete'
            )
        ];
    }
}