import React, { Component, Fragment } from 'react';
import layout_store from '../api/layout_store';
import tools_store from '../api/tools_store';
import Overlay from '../tools/overlay';
import Element_Tools from './element_tools';
import Language_Tools from './language_tools';
import Page_Tools from './page_tools';
import Site_Tools from './site_tools';
import Startup_Site_Tools from './startup_site_tools';
import Structure from './structure';
import User_Tools from './user_tools';
import View_Tools from './view_tools';

export default class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.toolsEl = React.createRef();
        this.unsubscribes = [];
        this.OverlaysAmount = 0;
        this.toolbar_type = 'bar';//TODO: make dynamic
        this.resizeHandleEl;
        this.isMouseDown = false;
        this.mouseX = 0;
        this.EventListeners = [];
        this.resizeTimer = null;
    }

    componentDidMount() {
        if (this.toolbar_type === 'bar') {
            this.unsubscribes = [tools_store.subscribe(this.handleStoreChange.bind(this))];

            this.EventListeners = [
                [this.resizeHandleEl, 'mousedown', this.onMouseDown.bind(this)],
                [document, 'mouseup', this.onMouseUp.bind(this)],
                [document, 'mousemove', this.onMouseMove.bind(this)]
            ];

            this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));
        }
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
    }

    setResizeHandle(el) {
        this.resizeHandleEl = el;
    }

    onMouseDown(e) {
        this.mouseX = e.clientX;
        this.isMouseDown = true;
    }

    onMouseUp() {
        this.isMouseDown = false;
    }

    onMouseMove(e) {
        if (this.isMouseDown) {
            const old_pos = layout_store.getState().breakpoint_hor_pos_pixel;
            const new_pos = old_pos + e.clientX - this.mouseX;

            if (old_pos !== new_pos && new_pos > 160 && new_pos < 800) {
                if (this.resizeTimer) {
                    clearTimeout(this.resizeTimer);
                }
                this.resizeTimer = setTimeout(() => {
                    const newValue = old_pos + e.clientX - this.mouseX;
                    layout_store.dispatch({
                        type: 'TOOLBAR_RESIZE',
                        breakpoint_hor_pos_pixel: newValue
                    });
                    this.mouseX = newValue;
                }, 5);
            }
        }
    }

    handleStoreChange() {
        let shouldUpdate = false;
        const tools_state = tools_store.getState();

        if (tools_state.Overlays.length !== this.OverlaysAmount) {
            this.OverlaysAmount = tools_state.Overlays.length;
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            this.forceUpdate();
        }
    }

    renderProject() {
        const { Function_Data, data, navigate } = this.props;
        const { Sites, Pages, Languages, Devices } = data;
        const tab = 1;//TODO

        return (
            <Fragment>
                <Site_Tools
                    Function_Data={Function_Data}
                    Sites={Sites}
                    tab={tab}
                    navigate={navigate}
                />
                <div className="grey_line"></div>
                <div className="tools_title dark">
                    <h1 className="gp white small tools_title_text">Language</h1>
                </div>
                <Language_Tools
                    Function_Data={Function_Data}
                    Languages={Languages}
                    tab={tab}
                    navigate={navigate}
                />
                <div className="grey_line"></div>
                <div className="tools_title dark">
                    <h1 className="gp white small tools_title_text">View</h1>
                </div>
                <View_Tools
                    Function_Data={Function_Data}
                    Devices={Devices}
                    tab={tab}
                    navigate={navigate}
                />
                <div className="grey_line"></div>
                <div className="tools_title dark">
                    <h1 className="gp white small tools_title_text">Page</h1>
                </div>
                <Page_Tools
                    Function_Data={Function_Data}
                    Pages={Pages}
                    tab={tab}
                    navigate={navigate}
                />
                <div className="grey_line"></div>
                <Element_Tools
                    Function_Data={Function_Data}
                    tab={tab}
                />
            </Fragment>
        );
    }

    renderTools() {
        const { Function_Data, data } = this.props;
        const { Sites, Pages } = data;
        const tab = 1;

        return (
            <Fragment>
                <div className="tools_title dark">
                    <h1 className="gp white small tools_title_text">Website</h1>
                </div>
                {Sites.length > 0 && Pages && Pages.length > 0 ?
                    this.renderProject() :
                    (<Startup_Site_Tools
                        Function_Data={Function_Data}
                        Sites={Sites}
                        tab={tab}
                    />)
                }
            </Fragment>
        );
    }

    renderMain() {
        const { Function_Data, data } = this.props;
        const { Sites, Pages, User, Content } = data;
        const hasSitesAndPages = Sites.length > 0 && Pages && Pages.length > 0;

        return (
            <div id="form_tools">
                <div>
                    <div>
                        <div className="tools_title dark">
                            <h1 className="gp white small tools_title_text">User</h1>
                        </div>
                        <User_Tools
                            Function_Data={Function_Data}
                            first_name={User.first_name}
                            last_name={User.last_name}
                        />
                        <div className="grey_line"></div>
                        {this.renderTools()}
                    </div>
                </div>
                <div>
                    {hasSitesAndPages && <Structure
                        type={this.toolbar_type}
                        Content={Content}
                    />}
                </div>
            </div>
        );
    }

    render() {
        const { Overlays } = tools_store.getState();
        const { breakpoint_hor_pos_pixel } = layout_store.getState();

        return (
            <Fragment>
                <div
                    id="tools"
                    ref={this.toolsEl}
                    style={{ width: breakpoint_hor_pos_pixel + 'px' }}
                    className={
                        (this.toolbar_type === 'bar' ? 'tools_bar' : 'tools_shadow') +
                        (Overlays.length > 0 ? ' hasOverlay' : '') +
                        ' unselectable'
                    }
                    unselectable="on"
                >
                    {Overlays.length === 0 ?
                        (this.toolbar_type === 'panel' ?
                            <div className="tools_panel">
                                <div className="tools_title drag"></div>
                                {this.renderMain()}
                            </div> :
                            this.renderMain()
                        ) :
                        Overlays.map((panel_data, index) =>
                            <div
                                key={index}
                                id={'pop_overlay_toolbar_' + (index + 1)}
                                className="pop_overlay_toolbar"
                                style={{ zIndex: ((index + 1) + 10) }}
                            >
                                <Overlay
                                    index={index}
                                    panel_data={panel_data}
                                ></Overlay>
                            </div>
                        )}
                </div>
                <div
                    ref={this.setResizeHandle.bind(this)}
                    className="tools_resize_handle"
                    style={{ left: (breakpoint_hor_pos_pixel - 2) + 'px', }}
                ></div>
            </Fragment>
        );
    }
}