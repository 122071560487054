import React, { Component } from 'react';
import layout_store from '../api/layout_store';
import { getSize, getViewport } from '../utils/browser';
import Element_Router from './element_router';

export default class Sequence extends Component {
    constructor(props) {
        super(props);
        const { Content = [] } = props.Element;
        this.childElementHeightCorrectionRequired = Content.filter(El => El.total_height === 'full').length > 0;
        const viewport = getViewport();

        this.state = {
            isResetted: false,
            windowHeight: viewport.h,
            childElementKeysWhichRequireHeightCorrection: []
        };

        this.elements = {};
        this.unsubscribes = [];
    }

    setRef(key) {
        return element => {
            this.elements[key] = {
                element
            };
        };
    }

    componentDidMount() {
        const { isResetted, windowHeight } = this.state;

        if (this.childElementHeightCorrectionRequired && !isResetted) {
            this.setState({
                isResetted: true,
                childElementKeysWhichRequireHeightCorrection: Object
                    .keys(this.elements)
                    .filter(key =>
                        getSize(this.elements[key].element).h <= windowHeight + 1
                    )
            });
        }

        if (this.childElementHeightCorrectionRequired) {
            this.unsubscribes = [
                layout_store.subscribe(this.handleLayoutStoreChange.bind(this))
            ];
        }
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleLayoutStoreChange() {
        const { action, viewport } = layout_store.getState();

        if (action === 'window_resize') {
            this.setState({
                isResetted: false,
                windowHeight: viewport.h
            });
        }
    }

    renderContent(Element) {
        const {
            project, site_id, Site, page_id, Navigation_Data, Presets, show_tools, tab, Font_Families,
            use_service, signed_in, navigate, user_role, requiresNewEffect, level, effectClass,
            reloadForm, updateActionData, parent_disabled
        } = this.props;
        const { isResetted, windowHeight, childElementKeysWhichRequireHeightCorrection } = this.state;
        const { el_type, el_idx, total_height } = Element;

        return (
            <div
                key={el_type + '_' + el_idx}
                id={el_type + '_position_' + el_idx}
                style={total_height === 'full' &&
                    isResetted &&
                    childElementKeysWhichRequireHeightCorrection.includes(el_type + '_' + el_idx) ?
                    { height: windowHeight } :
                    null
                }
                ref={total_height === 'full' ? this.setRef(el_type + '_' + el_idx) : null}
            >
                <div
                    id={'element_' + el_type + '_' + el_idx}
                    className={['icon', 'text', 'menu', 'form'].includes(el_type) ? 'body_font_size' : ''}
                >
                    <Element_Router
                        project={project}
                        site_id={site_id}
                        Navigation_Data={Navigation_Data}
                        page_id={page_id}
                        Element={Element}
                        Site={Site}
                        Presets={Presets}
                        Font_Families={Font_Families}
                        show_tools={show_tools}
                        use_service={use_service}
                        tab={tab}
                        signed_in={signed_in}
                        navigate={navigate}
                        Corrections={total_height === 'full' ? { full_height_correction: { height: windowHeight } } : null}
                        user_role={user_role}
                        requiresNewEffect={requiresNewEffect}
                        level={level + 1}
                        effectClass={effectClass}
                        reloadForm={reloadForm}
                        updateActionData={updateActionData}
                        parent_disabled={parent_disabled}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { el_type, el_idx, Element } = this.props;
        const { Content = [] } = Element;

        return (
            <div id={[el_type, el_idx].join('_')} className="full_width">
                {Content.map(El => this.renderContent(El))}
            </div>
        );
    }
}