import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils.js';
import { clone } from '../../utils/object';

export default class MapMarkerSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        const Current_Data = Action_Data.data_action_type === 'create' ?
            {
                Map_Marker: {
                    latitude: 519000000,
                    longitude: 44500000,
                    custom_size: 'false',
                    width: 20,
                    height: 20,
                    resource_id: 0,
                },
            } :
            clone(Load_Data);

        Current_Data.Map_Marker.el_idx = Load_Data ? Load_Data.el_idx : 0;

        return Current_Data;
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Map_Marker {
                    el_idx
                    latitude
                    longitude
                    custom_size: String
                    width
                    height
                    resource_id
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Map_Marker', [
                [
                    { type: 'field', id: 'latitude', label: 'Latitude' },
                    { value: Current_Data.Map_Marker.latitude },
                ],
                [
                    { type: 'field', id: 'longitude', label: 'Longitude' },
                    { value: Current_Data.Map_Marker.longitude },
                ],
                [
                    { type: 'row', id: 'image_1', label: ' ' },
                    {
                        Content: [
                            [
                                {
                                    type: 'button_image',
                                    id: 'select_image',
                                    label: ' ',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 5,
                                    padding_right: 5,
                                },
                                {
                                    name: 'open_select_panel',
                                    button_style: [115, 35, 2],
                                    text: 'Select',
                                    img_url: getImageURL('rootflex', 'upload_new'),
                                    img_style: [0, 40, 32, 0],
                                    onClick: () => {
                                        layer_store.dispatch({
                                            type: 'OPEN_PANEL',
                                            panel_data: {
                                                Function_Data,
                                                Action_Data: deepmerge(
                                                    Action_Data, {
                                                    access: 'authorized',
                                                    panel_type: 'background_switch_upload_panel',
                                                    frm_name: 'image_select',
                                                    new_element: false,
                                                    el_type: 'map',
                                                    resource_id: parseInt(Current_Data.Map_Marker.resource_id, 10),
                                                    amount: 15,
                                                    offset: 0,
                                                }
                                                ),
                                            },
                                        });
                                    },
                                },
                            ],
                            [
                                {
                                    type: 'image',
                                    id: 'thumbnail',
                                    colspan: 2,
                                    h_align: 'center',
                                    padding_top: 3,
                                    padding_bottom: 3,
                                },
                                {
                                    src: isNaN(parseInt(Current_Data.Map_Marker.resource_id), 10) ||
                                        parseInt(Current_Data.Map_Marker.resource_id, 10) === 0 ?
                                        'projects/rootflex/img/form/none_selected.jpg' :
                                        'projects/' + Function_Data.project +
                                        '/cache/' +
                                        (Function_Data.use_service ? 'service' : 'user') + '/' +
                                        Function_Data.site_id +
                                        '/img/w150h100/' +
                                        Current_Data.Map_Marker.resource_id + '.' +
                                        Current_Data.Map_Marker.file_type,
                                    show_transparency_background: true,
                                },
                            ],
                        ],
                    },
                ],
                [
                    { type: 'checkbox', id: 'custom_size', label: 'Custom size' },
                    {
                        value: Current_Data.Map_Marker.custom_size,
                        styling_type: 'switch',
                    },
                ],
                [
                    {
                        type: Current_Data.Map_Marker.custom_size === 'true' ? 'number' : 'hidden',
                        id: 'width',
                        label: 'Width',
                    },
                    {
                        value: Current_Data.Map_Marker.width,
                        min_val: 1,
                        max_val: 1000,
                        unit: 'px',
                    },
                ],
                [
                    {
                        type: Current_Data.Map_Marker.custom_size === 'true' ? 'number' : 'hidden',
                        id: 'height',
                        label: 'Height',
                    },
                    {
                        value: Current_Data.Map_Marker.height,
                        min_val: 1,
                        max_val: 1000,
                        unit: 'px',
                    },
                ],
            ]),
            getDoubleSubmitSet(frm_id, () => {
                var resource_id = parseInt(Current_Data.Map_Marker.resource_id, 10);

                if (
                    parseInt(Current_Data.map_el_idx, 10) === 0 ||
                    parseInt(Current_Data.Map_Marker.el_idx, 10) === 0
                ) {
                    if (isNaN(resource_id) || resource_id === 0) {
                        alert('Cannot proceed, because no image was selected.');
                    } else {
                        form_data_store.dispatch({
                            type: 'SUBMIT',
                            submitData: {
                                frm_id,
                                submit_action: 'add_map_marker',
                                Function_Data,
                                process_tag: 'Adding...',
                                onSubmitted: Result_Data => {
                                    updateActionData({
                                        access: 'authorized',
                                        panel_type: Action_Data.parent_panel_type,
                                        frm_name: 'map_settings',
                                        el_type: 'map',
                                        new_element: false,
                                    });
                                }
                            }
                        });
                    }
                } else {
                    if (isNaN(resource_id) || resource_id === 0) {
                        alert('Cannot proceed, because no image was selected.');
                    } else {
                        form_data_store.dispatch({
                            type: 'SUBMIT',
                            submitData: {
                                frm_id,
                                submit_action: 'save_map_marker',
                                Function_Data,
                                process_tag: 'Saving...',
                                onSubmitted: Result_Data => {
                                    updateActionData({
                                        access: 'authorized',
                                        panel_type: Action_Data.parent_panel_type,
                                        frm_name: 'map_settings',
                                        el_type: 'map',
                                        new_element: false,
                                    });
                                }
                            }
                        });
                    }
                }
            }, parseInt(Current_Data.Map_Marker.el_idx, 10) === 0 ? 'Add' : 'Save'),
        ];
    }
}
