import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import { getSize } from '../../utils/browser';
import { convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';
import Number from './number';

export default class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasFocus: false,
            sliderLeft: 0,
            sliderTop: 0
        };

        this.myRefHandle;
        this.myRefSlider;
        this.isMouseDown = false;
        this.mouseX = 0;
        this.mouseY = 0;
        this.EventListeners = [];
    }

    componentDidMount() {
        this.EventListeners = [
            [this.myRefHandle, 'mousedown', this.onMouseDown.bind(this)],
            [document, 'mouseup', this.onMouseUp.bind(this)],
            [document, 'mousemove', this.onMouseMove.bind(this)]
        ];

        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));
    }

    componentWillUnmount() {
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
    }

    onMouseDown(e) {
        const
            { El_Data, parent_disabled } = this.props,
            { readonly, disabled } = El_Data;

        if (!readonly && !disabled && !parent_disabled) {
            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
            this.isMouseDown = true;
        }
    }

    onMouseUp() {
        this.isMouseDown = false;
    }

    onMouseMove(e) {
        const
            { Data_Groups, fld_id, El_Data, changeCurrentData } = this.props,
            { value, min_val, max_val, onChange, direction } = El_Data;

        if (this.isMouseDown) {
            const
                sliderSize = getSize(this.myRefSlider),
                sliderLength = direction === 'ver' ? sliderSize.h : sliderSize.w,
                difference_in_pixels = direction === 'ver' ? (e.clientY - this.mouseY) : (e.clientX - this.mouseX),
                value_per_pixel = (max_val - min_val) / sliderLength,
                new_value = parseInt(value, 10) + Math.floor(difference_in_pixels * value_per_pixel),
                New_Data = onChange ? onChange(new_value) : null,
                actual_new_value = new_value > max_val ? max_val : (new_value < min_val ? min_val : new_value),
                Change_Set = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), actual_new_value);

            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
            changeCurrentData(New_Data ? deepmerge(New_Data, Change_Set) : Change_Set);
        }
    }

    setRefSlider(el) {
        this.myRefSlider = el;
    }

    setRefHandle(el) {
        this.myRefHandle = el;
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab, changeCurrentData, parent_disabled } = this.props,
            { value, min_val, max_val, readonly, disabled, direction } = El_Data,
            offsetLeft = 100 * value / (max_val - min_val);

        return (
            <div
                className={'form_field_box inpt' + (direction === 'ver' ? ' inpt_ver' : '')}
                style={{ width: '350px' }}
            >
                <div className="left">
                    <Number
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        fld_id={fld_id}
                        tab={tab}
                        El_Data={{
                            disabled: disabled,
                            readonly: readonly,
                            value: value,
                            min_val: min_val,
                            max_val: max_val,
                            unit: 'ms',
                            onChange: El_Data.onChange
                        }}
                        Col_Settings={{}}
                        changeCurrentData={changeCurrentData}
                        parent_disabled={parent_disabled}
                    />
                </div>
                <div
                    ref={this.setRefSlider.bind(this)}
                    style={{ width: 100 + 'px' }}
                    className="form_field_slider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                >
                    <div
                        className="ui-slider-range ui-corner-all ui-widget-header ui-slider-range-min"
                        style={{ width: offsetLeft + '%' }}
                    />
                    <span
                        ref={this.setRefHandle.bind(this)}
                        tabIndex="0"
                        className="ui-slider-handle ui-corner-all ui-state-default ui-state-hover"
                        style={{ left: offsetLeft + '%' }}
                    />
                </div>
            </div>
        );
    }
}