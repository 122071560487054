import React, { Component, Fragment } from 'react';
import layer_store from '../../api/layer_store';
import lightbox_store from '../../api/lightbox_store';
import { divideArrayInChunks } from '../../utils/array';
import { buildFormElementID } from '../../utils/form';

export default class MediaSelector extends Component {
    constructor(props) {
        super(props);
        const { frm_id, Data_Groups, fld_id } = props;
        this.id = buildFormElementID(frm_id, Data_Groups, fld_id);
        this.updateLightbox = true;
    }

    componentWillUnmount() {
        lightbox_store.dispatch({
            type: 'REMOVE',
            Images: []
        });
    }

    renderBrowseButton(type, setting) {
        const { Action_Data, updateActionData } = this.props;
        return (
            <div
                className={`form_image_browse_button ${type}`}
                onClick={() => {
                    this.updateLightbox = true;
                    updateActionData(Object.assign({}, Action_Data, { offset: setting }));
                }}
            >
                <div />
            </div>
        );
    }

    getPages(current_page, page_amount, load_amount) {
        let Pages = [];
        let start_page = 0;
        let end_page = page_amount;

        if (current_page > 6 && page_amount > 9) {
            Pages.push({
                page: 1,
                offset: 0
            }, {
                dots: true
            });
            start_page = current_page - (
                page_amount - current_page < 4 ?
                    7 - (page_amount - current_page) :
                    4
            );
        }

        if (page_amount > 9 && page_amount > current_page + 5) {
            end_page = current_page < 4 ?
                7 :
                current_page + 3;
        }

        for (let i = start_page; i < end_page; i++) {
            Pages.push({
                page: i + 1,
                offset: (i * load_amount)
            });
        }

        if (page_amount > 9 && page_amount > current_page + 5) {
            Pages.push({
                dots: true
            }, {
                page: page_amount,
                offset: (page_amount - 1) * load_amount
            });
        }

        return Pages;
    }

    onClickItem(Item) {
        const { Data_Groups, changeCurrentData } = this.props;
        return () => {
            let changeSet = {
                Selected_Item: {
                    id: Item.origin_id,
                    file_type: Item.file_type
                }
            };
            Data_Groups.reverse().forEach(Data_Group => {
                changeSet = { Data_Group: changeSet };
            });
            changeCurrentData(changeSet);
        };
    }

    onClickPage(offset) {
        const { Action_Data, updateActionData } = this.props;
        return () => {
            this.updateLightbox = true;
            updateActionData(Object.assign({}, Action_Data, { offset }));
        };
    }

    onClickItemDelete(Item) {
        const { Function_Data, frm_id, El_Data } = this.props;
        const media_type = El_Data.media_type;

        return () => {
            layer_store.dispatch({
                type: 'OPEN_PANEL',
                panel_data: {
                    Function_Data,
                    Action_Data: {
                        access: 'authorized',
                        panel_type: media_type === 'video' ? 'remove_origin_video_panel' : 'remove_origin_image_panel',
                        frm_name: media_type === 'video' ? 'remove_origin_video' : 'remove_origin_image',
                        resource_id: Item.origin_id,
                        parent_frm_id: frm_id,
                        data_action_type: 'remove'
                    }
                },
            });
        };
    }

    onClickItemEdit(Item) {
        const { Function_Data, frm_id } = this.props;
        return () => {
            layer_store.dispatch({
                type: 'OPEN_PANEL',
                panel_data: {
                    Function_Data,
                    Action_Data: {
                        access: 'authorized',
                        panel_type: 'media_properties_panel',
                        frm_name: 'media_properties',
                        resource_id: Item.origin_id,
                        parent_frm_id: frm_id,
                        data_action_type: 'edit'
                    }
                },
            });
        };
    }

    renderItem(Item, index) {
        const { Function_Data, El_Data } = this.props;
        const Selected_Item = El_Data.Selected_Item,
            media_type = El_Data.media_type,
            width = El_Data.width ? El_Data.width : 150,
            readonly = El_Data.readonly || false,
            height = El_Data.height ? El_Data.height : 100,
            disable_select = El_Data.disable_select ? El_Data.disable_select : 0,
            isSelected = parseInt(Selected_Item.id, 10) === Item.origin_id;

        return (
            <div
                key={index}
                id={`${this.id}_${Item.origin_id}`}
                className="form_image_selection_item"
            >
                <div
                    className="button_small button_small_delete"
                    onClick={this.onClickItemDelete(Item)}
                >
                    <div className="delete"></div>
                </div>
                <a
                    href={Function_Data.base_url + Item.lightbox_file}
                    style={{ position: 'absolute', right: '22px' }}
                    rel="lightbox"
                    onClick={e => {
                        e.preventDefault();
                        layer_store.dispatch({
                            type: 'SHOW_LIGHTBOX',
                            active_image: Item.origin_id
                        });
                    }}
                >
                    <div className="button_small">
                        <div className="image-resize"></div>
                    </div>
                </a>
                <div
                    className={`form_image_selection_box${isSelected ? ' selected' : (readonly ? '' : ' form_image_selection_box_is_hoverable')}`}
                    onClick={disable_select || isSelected || readonly ? null : this.onClickItem(Item)}
                >
                    <div className="form_transparency_background">
                        <div
                            style={{
                                width: `${width}px`,
                                height: `${height}px`,
                                backgroundImage: `url(${Function_Data.base_url
                                    }${Item.thumbnail_file
                                    })`,
                                backgroundRepeat: 'no-repeat'
                            }}
                        ></div>
                    </div>
                    {media_type === 'image' &&
                        <div className="form_image_edit_description">
                            <div
                                className="button_small button_inline"
                                onClick={this.onClickItemEdit(Item)}
                            >
                                <div
                                    className="pencil_blue"
                                    style={{ height: '16px' }}
                                ></div>
                            </div>
                            <p style={{ padding: '6px', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '11px' }}>
                                {Item.description}
                            </p>
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderCollection() {
        const { Function_Data, El_Data } = this.props,
            Media_Items = El_Data.Items,
            media_type = El_Data.media_type,
            lightbox_path = El_Data.lightbox_path,
            thumbnail_path = El_Data.thumbnail_path,
            column_amount = El_Data.column_amount ? parseInt(El_Data.column_amount, 10) : 5;

        if (!Media_Items || Media_Items.length === 0) {
            return (
                <div>
                    <p className="gp bold">
                        {`No ${media_type}s uploaded yet.`}
                        <br /><br /><br /><br /><br />
                    </p>
                </div>
            );
        }

        if (this.updateLightbox) {
            lightbox_store.dispatch({
                type: 'ADD',
                Images: Media_Items.map(Media_Item => {
                    return {
                        id: Media_Item.id,
                        href: `${Function_Data.base_url + lightbox_path + Media_Item.id}.${Media_Item.file_type}`,
                        title: Media_Item.description || ''
                    };
                })
            });
            this.updateLightbox = false;
        }

        let Collection = divideArrayInChunks(
            Media_Items.map(Media_Item => {
                return {
                    lightbox_file: `${lightbox_path + Media_Item.id}.${Media_Item.file_type}`,
                    thumbnail_file: `${thumbnail_path + Media_Item.id}.${Media_Item.file_type}`,
                    origin_id: parseInt(Media_Item.id, 10),
                    file_type: Media_Item.file_type,
                    description: Media_Item.description || ''
                };
            }), column_amount
        );

        return (
            <Fragment>
                {Collection.map((Item_Row, rowIndex) => {
                    return (
                        <div
                            key={rowIndex}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {Item_Row.map((Item, index) => this.renderItem(Item, index))}
                        </div>
                    );
                })}
            </Fragment>
        );
    }

    render() {
        const { Action_Data, frm_id, Data_Groups, fld_id, El_Data } = this.props;
        const Selected_Item = El_Data.Selected_Item,
            readonly = El_Data.readonly,
            total_amount = El_Data.total_amount ? parseInt(El_Data.total_amount, 10) : 0,
            load_amount = Action_Data.amount ? parseInt(Action_Data.amount, 10) : 0,
            offset = Action_Data.offset ? parseInt(Action_Data.offset, 10) : 0,
            page_amount = Math.ceil(total_amount / load_amount),
            current_page = (offset / load_amount) + 1,
            Pages = this.getPages(current_page, page_amount, load_amount);

        return (
            <Fragment>
                <div id={`${this.id}_image_picker`}>
                    <div style={{ display: 'table' }}>
                        {offset > 0 && this.renderBrowseButton('prev', (offset - load_amount))}
                        <div className="form_image_selection_holder">
                            {this.renderCollection()}
                        </div>
                        {total_amount > (offset + load_amount) && this.renderBrowseButton('next', (offset + load_amount))}
                    </div>
                    <div
                        className="form_image_picker_pages"
                        style={{ padding: `0 calc(50% - ${(Pages.length * 28) / 2}px)` }}
                    >
                        {Pages.map((Page, index) => {
                            return (
                                Page.dots ?
                                    <div key={index} className="form_image_picker_pages_dots">...</div> :
                                    <div
                                        key={index}
                                        id={`${this.id}_picker_page_${Page.page}`}
                                        className={Page.page === current_page ? 'form_image_picker_pages_current' : null}
                                        onClick={Page.page === current_page ? null : this.onClickPage(Page.offset)}
                                    >
                                        {Page.page}
                                    </div>
                            );
                        })}
                    </div>
                </div>
            </Fragment>
        );
    }
}
