import deepmerge from 'deepmerge';
import React, { Component, Fragment } from 'react';
import form_data_store from '../api/form_data_store';
import { upload } from '../api/upload_query';
import { buildFormElementID, buildFormElementName } from '../utils/form';
import { buildIconPath, buildOriginImagePath } from '../utils/path';

//SHOULD GET RID OF THIS FILE, USE CLASSES INSIDE "LAYER/COLUMN"

const handleClick = (props) => (event) => {
    //TODO: integrate this with "buildEvents" in events.js
    event.preventDefault();

    const {
        frm_id, name, id, Data_Groups, El_Data, Navigation_Data, project,
        user_role, site_id, page_id, changeCurrentData
    } = props;
    const { submit_action, submit_script, click_script, File_Upload } = El_Data;
    let Function_Data = deepmerge(
        Navigation_Data,
        {
            project,
            user_role,
            site_id,
            page_id
        }
    );

    /*TODO instead of upload here... call some sort of SubmitEvent that lives somewhere in the form-element,
     where it knows what other form-data to pick up and use in the posting... perhaps use ", changeCurrentData"
     and perhaps generalize that into "actionOnCurrentData", which then has a property of "action_type", which
     can be "update_data" or "send_data"...*/
    if (File_Upload) {
        upload(
            Function_Data,
            frm_id,
            Data_Groups,
            id,
            event.target.files,
            {},
            name,
            File_Upload.max_file_uploads,
            File_Upload.upload_max_filesize,
            File_Upload.post_max_size,
            submit_script
        );
    } else if (submit_action) {
        form_data_store.dispatch({
            type: 'SUBMIT',
            submitData: {
                frm_id,
                submit_action,
                Function_Data,
                onSubmitted: submit_script,
                onClick: click_script,
                isPanelRequiredToStayOpen: true,
                post_language: true
            }
        });
    }
};

export class ButtonColumn extends Component {
    onChangeFiles(e) {
        const { changeCurrentData } = this.props;
        const { onChange } = this.props;
        if (onChange) {
            onChange(e.target.files);
        }
    }

    render() {
        const { frm_id, name, Data_Groups, El_Data, tab, show_tools, changeCurrentData } = this.props;
        const {
            tag, color, responsive, width_type, width, height,
            margin_top, margin_left, button_type/*TODO make variants for: small, normal, big*/,
            text_size, File_Upload
        } = El_Data;
        const responsiveB = responsive === 'true';
        const filetype_restriction = File_Upload &&
            File_Upload.filetype_restriction ?
            File_Upload.filetype_restriction :
            '';

        const style = { position: 'relative' };
        const buttonStyle = {};
        const buttonInnerStyle = {};
        const buttonTextStyle = {};
        if (width_type === 'cus' && width) {
            style.width = `${parseInt(width) + 2}px`;
            buttonStyle.width = `${width}px`;
            buttonInnerStyle.width = `${parseInt(width) - 2}px`;
        }
        if (!responsiveB && height) {
            style.height = `${parseInt(height) + 2}px`;
            buttonStyle.height = `${height}px`;
            buttonInnerStyle.height = `${parseInt(height) - 2}px`;
            buttonTextStyle.lineHeight = `${parseInt(height) - 2}px`;
        }
        if (margin_top) {
            style.marginTop = `${margin_top}px`;
        }
        if (margin_left) {
            style.marginLeft = `${margin_left}px`;
        }

        const disabled = show_tools; // TODO: make dynamic

        const style2 = {
            width: width_type === 'cus' && width ? `${width}px` : (width_type === '100' ? '100%' : undefined),
            fontSize: responsiveB ? '180%' : (height ? `${height}px` : undefined)
        };

        return (
            <div style={style}>
                {File_Upload ?
                    <input
                        type="file"
                        id={buildFormElementID(frm_id, Data_Groups, id)}
                        name={`${buildFormElementName(Data_Groups, name)}[]`}
                        tabIndex={tab}
                        className="clickable_input fld_input_invisible fld_pointer"
                        style={{
                            width: `${width}px`,
                            height: `${height}px`
                        }}
                        accept={filetype_restriction ? filetype_restriction : null}
                        multiple={true}
                        onChange={disabled ? null : this.onChangeFiles.bind(this)}
                    /> :
                    <input
                        type={button_type}
                        id={buildFormElementID(frm_id, Data_Groups, name)}
                        name={name && buildFormElementName(Data_Groups, name, button_type)}
                        className={`m_button_input${responsiveB ? ' m_button_input_responsive' : ''}${disabled ? ' fld_input_disabled' : ''}`}
                        style={Object.keys(style2).length > 0 ? style2 : null}
                        tabIndex={tab}
                        value=""
                        onClick={disabled ? null : handleClick(this.props)}
                        disabled={disabled}
                    />
                }
                <div
                    id={`button_1_${buildFormElementID(frm_id, Data_Groups, name)}`}
                    className={`m_button_1 ${color}`}
                    style={Object.keys(buttonStyle).length > 0 ? buttonStyle : null}
                >
                    <div
                        id={`button_2_${buildFormElementID(frm_id, Data_Groups, name)}`}
                        className={`m_button_2 ${color}`}
                        style={Object.keys(buttonInnerStyle).length > 0 ? buttonInnerStyle : null}
                    >
                        <span
                            className={`m_button_text white_text ${responsiveB ? 'button_text_responsive ' : (text_size === 'big' ? ' big' : '')}${color}`}
                            style={Object.keys(buttonTextStyle).length > 0 ? buttonTextStyle : null}
                        >
                            {(tag || '')}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export class ImageButtonColumn extends Component {
    onChangeFiles(e) {
        const { changeCurrentData } = this.props;
        const { onChange } = this.props;
        if (onChange) {
            onChange(e.target.files);
        }
    }

    render() {
        const { project, site_id, frm_id, name, Data_Groups, El_Data, tab, show_tools, use_service, changeCurrentData } = this.props;
        const button_class = El_Data.button_class || 'image_button';
        const button_width = El_Data.width;
        const button_height = El_Data.height;
        const button_padding = El_Data.button_padding || 0;
        const button_padding_left = El_Data.button_padding_left || 0;
        const button_padding_right = El_Data.button_padding_right || 0;
        const button_padding_top = El_Data.button_padding_top || 0;
        const button_padding_bottom = El_Data.button_padding_bottom || 0;
        const text = El_Data.text;
        const resource_id = El_Data.resource_id;
        const Image_Data = El_Data.Image_Data;
        const img_custom_size = El_Data.img_custom_size === 'true';
        const img_width = img_custom_size ? El_Data.img_width : Image_Data.width;
        const img_height = img_custom_size ? El_Data.img_height : Image_Data.height;
        const img_class = El_Data.img_class || '';
        const img_left = El_Data.img_left || 0;
        const img_top = El_Data.img_top || 0;
        const button_type = El_Data.button_type;
        const File_Upload = El_Data.File_Upload;
        const filetype_restriction = File_Upload &&
            File_Upload.filetype_restriction ?
            File_Upload.filetype_restriction :
            '';

        /*const border_width = 1;
        let total_width = parseInt(button_width) + (2 * border_width);
        if (button_padding) {
            total_width += 2 * parseInt(button_padding);
        } else {
            if (button_padding_left) {
                total_width += parseInt(button_padding_left);
            }
            if (button_padding_right) {
                total_width += parseInt(button_padding_right);
            }
        }
        const border_height = 1;
        let total_height = parseInt(button_height) + (2 * border_height);
        if (button_padding) {
            total_height += 2 * parseInt(button_padding);
        } else {
            if (button_padding_top) {
                total_height += parseInt(button_padding_top);
            }
            if ( button_padding_bottom) {
                total_height += parseInt(button_padding_bottom);
            }
        }*/

        const style = {};
        if (button_width) {
            style.width = `${button_width}px`;
        }
        if (button_height) {
            style.height = `${button_height}px`;
        }

        const button_style = {
            width: (button_width ? `${button_width}px` : null),
            height: (button_height ? `${button_height}px` : null),
            padding: (button_padding ? `${button_padding}px` : null),
            paddingLeft: (button_padding_left ? `${button_padding_left}px` : null),
            paddingRight: (button_padding_right ? `${button_padding_right}px` : null),
            paddingTop: (button_padding_top ? `${button_padding_top}px` : null),
            paddingBottom: (button_padding_bottom ? `${button_padding_bottom}px` : null)
        };

        const img_style = {
            width: `${img_width}px`,
            height: `${img_height}px`,
            backgroundImage: (`url(${buildOriginImagePath(project, site_id, use_service, false, true)}${resource_id}.${Image_Data.file_type})`),
            backgroundPosition: (`${0 - img_left}px ${0 - img_top}px`),
            backgroundRepeat: 'no-repeat'
        };

        const disabled = show_tools; // TODO: make dynamic

        const style2 = {
            width: button_width ? `${button_width}px` : undefined,
            fontSize: button_height ? `${button_height}px` : undefined
        };

        return (
            <Fragment>
                <div
                    className="relative"
                    style={Object.keys(style).length > 0 ? style : null}
                >
                    {El_Data.File_Upload ?
                        <input
                            type="file"
                            id={buildFormElementID(frm_id, Data_Groups, id)}
                            name={`${buildFormElementName(Data_Groups, name)}[]`}
                            tabIndex={tab}
                            className="clickable_input fld_input_invisible fld_pointer"
                            style={{
                                width: `${button_width}px`,
                                height: `${button_height}px`
                            }}
                            accept={filetype_restriction ? filetype_restriction : null}
                            multiple={true}
                            onChange={disabled ? null : this.onChangeFiles.bind(this)}
                        /> :
                        <input
                            type={button_type}
                            id={buildFormElementID(frm_id, Data_Groups, name)}
                            name={name && buildFormElementName(Data_Groups, name, button_type)}
                            className={`m_button_input${disabled ? ' fld_input_disabled' : ''}`}
                            style={Object.keys(style2).length > 0 ? style2 : null}
                            tabIndex={tab}
                            value=""
                            onClick={disabled ? null : handleClick(this.props)}
                            disabled={disabled && ['text', 'password', 'number'].includes(button_type)}
                        />
                    }
                    <div
                        id={`${buildFormElementID(frm_id, Data_Groups, name)}_button`}
                        className={button_class || null}
                        style={button_style}
                    >
                        <div
                            className={img_class}
                            style={img_style}
                        />
                        <p>{text}</p>
                    </div>
                </div>
                {''/*El_Data.File_Upload &&
                    <FileUploadStatusLayer
                        frm_id={frm_id}
                        id={id}
                        tab={tab}
                    />*/
                }
            </Fragment>
        );
    }
}

export class IconLinkColumn extends Component {
    onChangeFiles(e) {
        const { changeCurrentData } = this.props;
        const { onChange } = this.props;
        if (onChange) {
            onChange(e.target.files);
        }
    }

    render() {
        const { project, frm_id, name, Data_Groups, El_Data, tab, show_tools, Navigation_Data } = this.props;
        const { text, img_name, img_style, file_upload, img_class, filetype_restriction, Style } = El_Data;

        const total_width = Style && Style[0] ? Style[0] : 100;
        const total_height = Style && Style[1] ? Style[1] : 16;

        let imgstyle = {};
        if (img_style[1]) {
            imgstyle.width = `${img_style[1]}px`;
        }
        if (img_style[2]) {
            imgstyle.height = `${img_style[2]}px`;
        }
        if (img_name) {
            imgstyle.backgroundImage = `url(${buildIconPath(Navigation_Data.base_url, project)}${img_name}.png)`;
        }
        if (img_style[3] || img_style[4]) {
            imgstyle.backgroundPosition = `-${img_style[3]}px -${img_style[4]}px`;
        }

        const style2 = {
            width: `${total_width}px`,
            height: `${total_height}px`
        };

        const disabled = show_tools; // TODO: make dynamic

        const style = {
            width: total_width ? `${total_width}px` : undefined,
            fontSize: total_height ? `${total_height}px` : undefined
        };

        return (
            <div className={`relative${disabled ? '' : ' icon_link_enabled'}`} style={style2}>
                {file_upload ?
                    <input
                        type="file"
                        id={buildFormElementID(frm_id, Data_Groups, id)}
                        name={`${buildFormElementName(Data_Groups, name)}[]`}
                        tabIndex={tab}
                        className="clickable_input fld_input_invisible fld_pointer"
                        style={{
                            width: `${total_width}px`,
                            height: `${total_height}px`
                        }}
                        accept={filetype_restriction ? filetype_restriction : null}
                        multiple={true}
                        onChange={disabled ? null : this.onChangeFiles.bind(this)}
                    /> :
                    <input
                        type="button"
                        id={buildFormElementID(frm_id, Data_Groups, 'button')}
                        name={name && buildFormElementName(Data_Groups, name, 'button')}
                        className={`clickable_input fld_input_invisible fld_pointer${disabled ? ' fld_input_disabled' : ''}`}
                        style={Object.keys(style).length > 0 ? style : null}
                        tabIndex={tab}
                        value=""
                        onClick={disabled ? null : handleClick(this.props)}
                        disabled={disabled}
                    />
                }
                <div className={img_class || 'icon_link_image'} style={imgstyle} />
                {text && <p className="icon_link_text">{text}</p>}
            </div>
        );
    }
}

/*
export class FileUploadStatusLayer extends Component {
    render() {
        //NOT USED BECAUSE OF THE NEAR IMPOSSIBILITY TO GET THE UPLOAD-PROGRESS IN A CROSS-BROWSER, NON-SERVER-ADJUST-REQUIRED, PHP 5.2 COMPATIBLE, FASHION.
        //SEE: http://stackoverflow.com/questions/849237/upload-progress-bar-in-php
        const {frm_id, id, tab} = this.props;

        return (
            <div
                id={frm_id + '_' + id + '_status'}
                className="upload_status"
            >
                <div className="upload_status_top">
                    <div className="upload_status_text_left"><p className="gp bold">Filename</p></div>
                    <div className="upload_status_text_right"><p className="gp bold">Status</p></div>
                    <div className="upload_status_text_right"><p className="gp bold">Size</p></div>
                </div>
                <ul id="uploader_filelist" className="upload_status_list"></ul>
                <div className="upload_status_bottom">
                    <div className="upload_status_text_left"><p id="upload_status_total_fil" className="gp bold"></p></div>
                    <div className="upload_status_text_right"><p id="upload_status_total_per" className="gp bold">0%</p></div>
                    <div className="upload_status_text_right"><p id="upload_status_total_siz" className="gp bold"></p></div>
                </div>
            </div>
        );
    }
};*/
