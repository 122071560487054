import React, { Component, Fragment } from 'react';
import Box from './box';
import Form from './form';
import { ButtonColumn, IconLinkColumn, ImageButtonColumn } from './form_button';
import { InputColorColumn, InputColumn, InputDateColumn, InputNumberColumn, InputSliderColumn, TextareaColumn } from './form_field';
import { CheckboxColumn, DropdownColumn, ImageTextSelectColumn, RadioColumn } from './form_selection';
import { FormGrid, MultipleColumns } from './form_structure';
import Grid from './grid';
import Icon from './icon';
import Image from './image';
import Map from './map';
import Menu from './menu';
import Sequence from './sequence';
import Slideshow from './slideshow';
import { BoxAndTextColumn, ImageColumns, LineColumns, SpaceColumn, TextColumns } from './static_form_element';
import Text from './text';
import Twitter from './twitter';
import Video from './video';

export class Form_Router extends Component {
    //SHOULD GET RID OF THIS CLASS, USE CLASSES INSIDE "LAYER/COLUMN"
    render() {
        const { frm_id, Data_Groups, Element } = this.props;
        const { El_Data, el_type, el_idx, Label, Col_Settings } = Element;
        const { label, label_align } = Label;
        const name = El_Data.name ? El_Data.name : '';

        return (
            <Fragment>
                {label !== '' && label_align === 'left' &&
                    this.buildColumnTag(
                        el_type, el_idx + '_label', {
                        'colspan': 1,
                        'h_align': 'right',
                        'v_align': 'top',
                        'padding_left': 0,
                        'padding_right': 5,
                        'padding_top': 2,
                        'padding_bottom': 0
                    },
                        <TextColumns
                            frm_id={frm_id}
                            Data_Groups={Data_Groups}
                            name={name + '_label'}
                            El_Data={{
                                text: label,
                                styling_type: 'normal',
                                required: El_Data.required && El_Data.required === 'true' ? true : false
                            }}
                        />
                    )
                }
                {el_type === 'form_col_space' &&
                    <SpaceColumn
                        el_type={el_type}
                        el_idx={el_idx}
                        frm_id={frm_id}
                        Data_Groups={Data_Groups}
                        name={name}
                        El_Data={El_Data}
                        Col_Settings={Col_Settings}
                    />
                }
                {el_type !== 'form_col_space' &&
                    this.buildColumnTag(
                        el_type, el_idx, Col_Settings,
                        this.buildFormColumn(name)
                    )
                }
                {label !== '' && label_align === 'right' &&
                    this.buildColumnTag(
                        el_type, el_idx + '_label', {
                        'colspan': 1,
                        'h_align': 'left',
                        'v_align': 'top',
                        'padding_left': 5,
                        'padding_right': 0,
                        'padding_top': 2,
                        'padding_bottom': 0
                    },
                        <TextColumns
                            frm_id={frm_id}
                            Data_Groups={Data_Groups}
                            name={name + '_label'}
                            El_Data={{
                                text: label,
                                styling_type: 'small',
                                required: El_Data.required && El_Data.required === 'true' ? true : false
                            }}
                        />
                    )
                }
            </Fragment>
        );
    }

    buildColumnTag(el_type, el_idx, Col_Settings, Content) {
        const style = {};

        if (Col_Settings.padding_left) {
            style.paddingLeft = Col_Settings.padding_left + 'px';
        }

        if (Col_Settings.padding_right) {
            style.paddingRight = Col_Settings.padding_right + 'px';
        }

        /*if (responsive === 'on') {
            style.paddingTop = '0.5%';
            style.paddingBottom = '0.5%';
        } else {*/
        if (Col_Settings.padding_top) {
            style.paddingTop = Col_Settings.padding_top + 'px';
        }
        if (Col_Settings.padding_bottom) {
            style.paddingBottom = Col_Settings.padding_bottom + 'px';
        }
        /*}*/

        return (
            <td
                id={'col_' + el_type + '_' + el_idx}
                colSpan={Col_Settings.colspan}
                align={Col_Settings.h_align}
                valign={Col_Settings.v_align}
                style={Object.keys(style).length > 0 ? style : null}
            >
                {Content}
            </td>
        );
    }

    getFormColumn(type) {
        if (type === 'form_col_row') {
            return MultipleColumns;
        } else if (['form_col_grid', 'form_col_section'].includes(type)) {//TODO: make obsolete -> grid, section (grid, with styling mods)... use el_type=grid with optional styling mods & size,pos,etc...
            return FormGrid
        } else if (type === 'form_col_field') {
            return InputColumn;
        } else if (type === 'form_col_number_field') {
            return InputNumberColumn;
        } else if (type === 'form_col_date_field') {
            return InputDateColumn;
        } else if (type === 'form_col_color_field') {
            return InputColorColumn;
        } else if (type === 'form_col_slider_field') {
            return InputSliderColumn;
        } else if (type === 'form_col_checkbox') {
            return CheckboxColumn;
        } else if (type === 'form_col_dropdown') {
            return DropdownColumn;
        } else if (type === 'form_col_image_text_select') {
            return ImageTextSelectColumn;
        } else if (type === 'form_col_radio_select') {
            return RadioColumn;
        } else if (type === 'form_col_textarea') {
            return TextareaColumn;
        } else if (type === 'form_col_text') {
            return TextColumns;
        } else if (type === 'form_col_button') {
            return ButtonColumn;
        } else if (type === 'form_col_image_button') {
            return ImageButtonColumn;
        } else if (type === 'form_col_icon_link') {
            return IconLinkColumn;
        } else if (type === 'form_col_box_text') {
            return BoxAndTextColumn;
        } else if (type === 'form_col_image') {
            return ImageColumns;
        } else if (type === 'form_col_line') {
            return LineColumns;
        }

        return null;
    }

    buildFormColumn(name) {
        const {
            project, site_id, frm_id, Data_Groups, tab, Element, show_tools, use_service,
            Navigation_Data, page_id, user_role, requiresNewEffect,
            reloadForm, updateActionData, changeCurrentData, parent_disabled
        } = this.props;
        const { El_Data, el_type, Col_Settings, Content = [] } = Element;

        const FormColumnBuilder = this.getFormColumn(el_type);

        return (FormColumnBuilder ?
            <FormColumnBuilder
                project={project}
                site_id={site_id}
                frm_id={frm_id}
                Data_Groups={Data_Groups}
                tab={tab}
                Content={Content}
                show_tools={show_tools}
                use_service={use_service}
                requiresNewEffect={requiresNewEffect}
                reloadForm={reloadForm}
                updateActionData={updateActionData}
                changeCurrentData={changeCurrentData}
                parent_disabled={parent_disabled}
                name={name}
                El_Data={El_Data}
                Col_Settings={Col_Settings}
                Navigation_Data={Navigation_Data}
                page_id={page_id}
                user_role={user_role}
            /> : <Fragment />
        );
    }
}

export default class Element_Router extends Component {
    getElement(type) {
        if (type === 'box') {
            return Box;
        } else if (type === 'form') {
            return Form;
        } else if (type === 'grid') {
            return Grid;
        } else if (type === 'icon') {
            return Icon;
        } else if (type === 'image') {
            return Image;
        } else if (type === 'map') {
            return Map;
        } else if (type === 'menu') {
            return Menu;
        } else if (type === 'sequence') {
            return Sequence;
        } else if (type === 'slideshow') {
            return Slideshow;
        } else if (type === 'text') {
            return Text;
        } else if (type === 'twitter') {
            return Twitter;
        } else if (type === 'video') {
            return Video;
        }

        return null;
    }

    render() {
        const {
            project, site_id, Navigation_Data, page_id, Element, Site, Presets,
            Font_Families, show_tools, use_service, signed_in, navigate, tab,
            Corrections, user_role, requiresNewEffect, level, effectClass,
            reloadForm, updateActionData, parent_disabled
        } = this.props;
        const { el_type, el_idx } = Element;

        const ElementBuilder = this.getElement(el_type);

        return (ElementBuilder ?
            <ElementBuilder
                project={project}
                site_id={site_id}
                Navigation_Data={Navigation_Data}
                page_id={page_id}
                el_type={el_type}
                el_idx={el_idx}
                Element={Element}
                Site={Site}
                Presets={Presets}
                show_tools={show_tools}
                tab={tab}
                Font_Families={Font_Families}
                use_service={use_service}
                signed_in={signed_in}
                navigate={navigate}
                Corrections={Corrections}
                user_role={user_role}
                requiresNewEffect={requiresNewEffect}
                level={level}
                effectClass={effectClass}
                reloadForm={reloadForm}
                updateActionData={updateActionData}
                parent_disabled={parent_disabled}
            /> : <Fragment />
        );
    }
}