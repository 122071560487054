import React, { Component, Fragment } from 'react';
import structure_store from '../api/structure_store';
import { ucFirst } from '../utils/string';
import { isHidden, isHovering, isSelected, mouseOutElement, mouseOverElement, onClickElement, onClickEye, onRightClickElement } from './element_events';
import StructureGridContent from './structure_grid_content';
import StructureSequenceContent from './structure_sequence_content';

export default class StructureHolder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelected: false,
            isHovering: false,
            isHidden: false
        };
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [structure_store.subscribe(this.handleStructureStoreChange.bind(this))];
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleStructureStoreChange() {
        const { el_type, el_idx } = this.props;
        const state = structure_store.getState();

        this.setState({
            isSelected: isSelected(el_type, el_idx, state),
            isHovering: isHovering(el_type, el_idx, state),
            isHidden: isHidden(el_type, el_idx, state)
        });
    }

    render() {
        const { holder_el_type, el_type, el_idx, Content } = this.props;

        return (
            <div
                id={'structure_element_' + el_type + '_' + el_idx}
                className={
                    'structure_holder sortable_element' +
                    (el_type === 'grid' || el_type === 'sequence' || el_type === 'slideshow' ? ' sortable_holder' : '')
                }
            >
                <div className="structure_holder_thing">
                    <div className="structure_holder_thing2"></div>
                    <div className="structure_holder_connect">
                        <div className="structure_holder_dot"></div>
                    </div>
                    <div id={'structure_holder_' + el_type + '_' + el_idx} className="structure_holder_space">
                        {el_type === 'grid' || el_type === 'sequence' || el_type === 'slideshow' ?
                            this.renderStructureGridSequenceElement(el_type, el_idx, Content, (holder_el_type === 'grid' || holder_el_type === 'sequence' || holder_el_type === 'slideshow')) :
                            (
                                el_type === 'ground' ?
                                    this.renderStructureGroundElement(el_type, el_idx) :
                                    this.renderStructureAbsoluteElement(el_type, el_idx, Content)
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderStructureGridSequenceElement(el_type, el_idx, Content, nested) {
        return (
            <Fragment>
                <div className={'structure_grid_sequence_holder' + (!Content || Content.length === 0 ? ' structure_grid_sequence_holder_no_sub' : '')}>
                    {Content && Content.length > 0 &&
                        <div className="structure_element_sub_control"></div>
                    }
                    {this.buildStructureElement(el_type, el_idx, 0)}
                </div>
                {el_type === 'grid' ?
                    <StructureGridContent Content={Content} grid_el_idx={el_idx} /> :
                    <StructureSequenceContent Content={Content} sequence_id={el_idx} nested={nested} />
                }
            </Fragment>
        );
    }

    renderStructureGroundElement(el_type, el_idx) {
        return (
            <div className="structure_grounds">
                {this.buildStructureElement(el_type, el_idx, 0)}
            </div>
        );
    }

    renderStructureAbsoluteElement(el_type, el_idx, Content) {
        const has_sub = Content && Content.length > 0 ? true : false;

        return (
            <Fragment>
                <div className="structure_absolute_element">
                    {has_sub && <div className="structure_element_sub_control"></div>}
                    {this.buildStructureElement(el_type, el_idx, has_sub)}
                </div>
                {has_sub && this.buildStructureSub(Content, el_type, el_idx)}
            </Fragment>
        );
    }

    buildStructureSub(Content, el_type, el_idx) {
        return (
            <div className="structure_sub sortable_solo">
                {el_type === 'grid' || el_type === 'sequence' || el_type === 'slideshow' ?
                    (
                        <div
                            id={'structure_handle_' + el_type + '_' + el_idx}
                            style={{ marginLeft: '-1px' }}
                        >
                            {el_type === 'grid' ?
                                <StructureGridContent Content={Content} grid_el_idx={el_idx} /> :
                                <StructureSequenceContent Content={Content} sequence_id={el_idx} />
                            }
                        </div>
                    ) :
                    Content.map((Item, i) =>
                        <StructureHolder
                            key={i}
                            holder_el_type=""
                            el_type={Item.el_type}
                            el_idx={Item.el_idx}
                            Content={Item.Content}
                        />
                    )
                }
            </div>
        );
    }

    buildStructureElement(el_type, el_idx, has_sub) {
        const { isSelected, isHovering, isHidden } = this.state;


        var icon = 'empty',
            el_title = '',
            hover_text = 'Unknown Element Type',
            hide_eye = el_type === 'grid_cell';

        if (el_type === 'grid_cell') {
            hover_text = 'Empty Grid Cell (with row id: ' + el_idx.split('_')[0] + ' and column id: ' + el_idx.split('_')[1] + ' )';
        } else {
            if (['box', 'form', 'grid', 'ground', 'icon', 'image', 'map', 'menu', 'sequence', 'slideshow', 'text', 'twitter', 'video'].indexOf(el_type) > -1) {
                icon = el_type;
                el_title = el_type === 'ground' ? 'Background' : ucFirst(el_type);
                hover_text = ucFirst(el_type) + ' Element (el_idx: ' + el_idx + ')';
            } else if (['form_col_section', 'form_col_grid', 'form_col_row', 'form_col_box_text', 'form_col_button', 'form_col_checkbox', 'form_col_color_field', 'form_col_date_field', 'form_col_dropdown', 'form_col_field', 'form_col_hidden', 'form_col_image', 'form_col_image_button', 'form_col_icon_link', 'form_col_image_text_select', 'form_col_line', 'form_col_radio_select', 'form_col_slider_field', 'form_col_space', 'form_col_text', 'form_col_textarea'].indexOf(el_type) > -1) {
                //icon = el_type;
                el_title = ucFirst(el_type.replace('form_col_', '').replace(/_/g, ' '));
                hover_text = 'Form Column ' + el_title + ' Element (el_idx: ' + el_idx + ')';
            }
        }

        return (
            <Fragment>
                <div
                    id={'structure_' + el_type + '_' + el_idx}
                    className={
                        'structure_element' +
                        (has_sub ? ' sub' : '') +
                        (hide_eye ? ' no_eye' : '') +
                        (el_type === 'grid' || el_type === 'sequence' || el_type === 'slideshow' || el_type === 'box' ? ' structure_element_can_hold_sub' : '') +
                        (isSelected ? (
                            el_type === 'grid' || el_type === 'sequence' || el_type === 'sequence_element' || el_type === 'slideshow' ?
                                ' structure_select_1' :
                                ' structure_select_2'
                        ) : (
                                isHovering ? ' structure_highlight_1' : '')
                        )

                    }
                    title={hover_text}
                    onMouseOver={mouseOverElement(el_type, el_idx)}
                    onMouseOut={mouseOutElement(el_type, el_idx)}
                    onClick={onClickElement(el_type, el_idx)}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        if (e.which === 3) {
                            onRightClickElement(el_type, el_idx, e.pageX, e.pageY);
                        }
                    }}
                >
                    <div className={'structure_icon_element element_' + icon}></div>
                    <p
                        id={'structure_txt_' + el_type + '_' + el_idx}
                        className="structure_txt_element"
                    >
                        {el_title}
                    </p>
                </div>
                {!hide_eye &&
                    <div
                        id={'structure_' + el_type + '_' + el_idx + '_eye_holder'}
                        className="structure_eye_holder"
                        onClick={onClickEye(el_type, el_idx)}
                    >
                        <div
                            id={'structure_eye_' + el_type + '_' + el_idx}
                            className="structure_eye"
                            style={isHidden ? { backgroundPosition: '0px -16px' } : null}
                        />
                    </div>
                }
            </Fragment>
        );
    }
}