import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class MapSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Map: {
                    idx: '',
                    latitude: 519000000,
                    longitude: 44500000,
                    zoom: 10
                },
                Map_Markers: []
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Map {
                    idx
                    latitude
                    longitude
                    zoom
                },
                Map_Markers
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Map', [
                [
                    { type: 'text' },
                    { text: 'Location Center', style: 'bold' }
                ],
                [
                    { type: 'field', id: 'latitude', label: 'Latitude' },
                    { value: Current_Data.Map.latitude }
                ],
                [
                    { type: 'field', id: 'longitude', label: 'Longitude' },
                    { value: Current_Data.Map.longitude }
                ],
                [
                    { type: 'number', id: 'zoom', label: 'Zoom' },
                    { value: Current_Data.Map.zoom, min_val: 1, max_val: 30 }
                ],
                [
                    { type: 'text' },
                    { text: 'Map Markers', style: 'bold' }
                ],
                [
                    { type: 'map_markers_management', id: 'map_markers_management', colspan: 2 },
                    { Map_Markers: Current_Data.Map_Markers, map_el_idx: Current_Data.Map.idx }
                ],
                [
                    {
                        type: Action_Data.data_action_type !== 'create' ? 'icon_link' : 'hidden',
                        id: 'add_new_map_marker',
                        h_align: 'left',
                        v_align: 'center',
                        padding_left: 10,
                        padding_right: 0,
                        padding_top: 5,
                        colspan: 2
                    },
                    {
                        Style: [114, 16],
                        text: 'Add Map Marker',
                        img_name: 'add',
                        onClick: () => {
                            layer_store.dispatch({
                                type: 'OPEN_PANEL',
                                panel_data: {
                                    Function_Data,
                                    Action_Data: deepmerge(
                                        Action_Data,
                                        {
                                            access: 'authorized',
                                            panel_type: 'add_map_marker_panel',
                                            frm_name: 'map_marker_settings',
                                            el_idx: map_el_idx,
                                            map_marker_el_idx: 0,
                                            parent_panel_type: Action_Data.panel_type,
                                            data_action_type: 'create'
                                        }
                                    )
                                },
                            });
                        }
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        frm_name: 'layout_settings'
                    });
                } else {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_map_settings',
                            Function_Data,
                            process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                            onSubmitted: Result_Data => {
                                //TODO
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save'
            )
        ];
    }
}