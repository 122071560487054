import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class GridSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return {
            Grid: Action_Data.data_action_type === 'create' ?
                {
                    row_amount: 1,
                    column_amount: 1
                } :
                clone(Load_Data.Grid_Data)
        };
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Grid {
                    row_amount
                    column_amount
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Grid', [
                [
                    { type: 'number', id: 'row_amount', label: 'Amount of rows' },
                    { value: Current_Data.Grid.row_amount, min_val: 1, max_val: 100 }
                ],
                [
                    { type: 'number', id: 'column_amount', label: 'Amount of columns' },
                    { value: Current_Data.Grid.column_amount, min_val: 1, max_val: 100 }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                // TODO store form values to be used in later post-submit to back-end
                updateActionData({
                    frm_name: 'layout_settings'
                });
            }, 'Next')
        ];
    }
}