import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, getImageURL, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColImageSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Image: {
                    resource_id: '',
                    file_type: 'png',
                    name: '',
                    colspan: 2,
                    label: '',
                    alt: '',
                    padding: 3
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Image {
                    resource_id: String
                    file_type: String
                    name: String
                    colspan
                    label: String
                    alt: String
                    padding
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Image', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Image.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Image.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'field', id: 'label', label: 'Label' },
                    { value: Current_Data.Form_Col_Image.label, max: 2 }
                ],
                [
                    { type: 'field', id: 'alt', label: 'Description' },
                    { value: Current_Data.Form_Col_Image.alt, max: 2 }
                ],
                [
                    { type: 'number', id: 'padding', label: 'Padding' },
                    { value: Current_Data.Form_Col_Image.padding, min_val: 0, max_val: 100, unit: 'px' }
                ],
                [
                    { type: 'row', id: 'image_1', label: ' ' },
                    {
                        Content: [
                            [
                                {
                                    type: 'button_image',
                                    id: 'select_image',
                                    label: ' ',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 5,
                                    padding_right: 5
                                },
                                {
                                    name: 'open_select_panel',
                                    button_style: [115, 35, 2],
                                    text: 'Select',
                                    img_url: getImageURL('rootflex', 'upload_new'),
                                    img_style: [0, 40, 32, 0],
                                    onClick: () => {
                                        layer_store.dispatch({
                                            type: 'OPEN_PANEL',
                                            panel_data: {
                                                Function_Data,
                                                Action_Data: deepmerge(
                                                    Action_Data,
                                                    {
                                                        access: 'authorized',
                                                        panel_type: 'background_switch_upload_panel',
                                                        frm_name: 'image_select',
                                                        new_element: Action_Data.data_action_type === 'create',
                                                        el_type: 'form_col_image',
                                                        el_idx: Action_Data.el_idx,
                                                        resource_id: parseInt(Current_Data.Form_Col_Image.resource_id, 10),
                                                        data_action_type: 'select',
                                                        amount: 15,
                                                        offset: 0
                                                    }
                                                )
                                            },
                                        });
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'image',
                                    id: 'thumbnail',
                                    colspan: 2,
                                    h_align: 'center',
                                    padding_top: 3,
                                    padding_bottom: 3
                                },
                                {
                                    src: isNaN(parseInt(Current_Data.Form_Col_Image.resource_id), 10) || parseInt(Current_Data.Form_Col_Image.resource_id, 10) === 0 ?
                                        'projects/rootflex/img/form/none_selected.jpg' :
                                        'projects/' + Function_Data.project + '/cache/' +
                                        (Function_Data.use_service ? 'service' : 'user') + '/' +
                                        Function_Data.site_id + '/img/w150h100/' +
                                        Current_Data.Form_Col_Image.resource_id + '.' +
                                        Current_Data.Form_Col_Image.file_type,
                                    show_transparency_background: true
                                }
                            ]
                        ]
                    }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_image_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}