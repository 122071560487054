import deepmerge from 'deepmerge';
import React, { Component } from 'react';
import { buildFormElementID, buildFormElementName, convertDataGroupsAndFldIdToChangeSet } from '../../utils/form';

export default class FieldQ extends Component {
    constructor(props) {
        super(props);
        const { El_Data, show_tools } = this.props;
        var readonly = El_Data.readonly || '',
            autocomplete = El_Data.autocomplete === 'true' ? El_Data.autocomplete : '';

        this.state = {
            hasFocus: false,
            readonlyToBlockAutocomplete: !show_tools && autocomplete === 'false' && readonly === 'false'
        };
    }

    componentDidMount() {
        if (this.state.readonlyToBlockAutocomplete) {
            /* NOTE: to prevent autocomplete, which has messy implementation in
             * browsers, often ignoring designated attribute (autocomplete="off"),
             * sometimes filling in username/password in any 2 fields where the
             * second has attribute setting: (type="password"),
             * the input is set readonly, which block browser's autocomplete,
             * then javascript makes it readable 500ms after "window load"
             */
            const that = this;
            setTimeout(() => {
                that.setState({ readonlyToBlockAutocomplete: false });
            }, 500);
        }
    }

    onBlur() {
        this.setState({ hasFocus: false });
    }

    onFocus() {
        this.setState({ hasFocus: true });
    }

    onKey(e) {
        const { trigger_click_button_name } = this.props.El_Data;

        if (trigger_click_button_name && e.which === 13) {//on "enter" key
            document.getElementById('' + trigger_click_button_name).click();
        }
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab, show_tools, changeCurrentData, parent_disabled } = this.props;
        var special_el_id = buildFormElementID(frm_id, Data_Groups, fld_id, non_prefixed_id),
            value = El_Data.value ? El_Data.value : (El_Data.value === 0 ? 0 : ''),
            maxlength = El_Data.max && El_Data.max === 1 ? 300 : (El_Data.max && El_Data.max === 2 ? 100 : 30),
            inner_label = El_Data.inner_label || '',
            readonly = show_tools || El_Data.readonly || false,
            field_type = El_Data.field_type || 'text',
            Style = El_Data.Style || '',
            autocomplete = El_Data.autocomplete === 'true' ? El_Data.autocomplete : '',
            non_prefixed_id = El_Data.non_prefixed_id || '',
            disabled = false,
            onClick = () => { },
            onKeyUp = () => { },
            onChange = e => {
                const New_Data = El_Data.onChange ? El_Data.onChange(e.target.value) : null,
                    New_Value = convertDataGroupsAndFldIdToChangeSet(Data_Groups.concat([fld_id]), e.target.value);
                changeCurrentData(New_Data ? deepmerge(New_Data, New_Value) : New_Value);
            };

        const { hasFocus, readonlyToBlockAutocomplete } = this.state;
        const isFillable = ['text', 'password', 'number'].includes(field_type);

        const style = {};
        if (Style && Style[0]) {
            style.width = Style[0] + 'px';
            style.float = Style[1];
        }

        return (
            <div className="q_fld_holder">
                <input
                    type={field_type ? field_type : 'text'}
                    id={special_el_id}
                    name={fld_id ? buildFormElementName(Data_Groups, fld_id, field_type) : null}
                    value={value !== null ? value : ''}
                    className={
                        'q_fld' +
                        (hasFocus ? ' q_fld-focus' : '')
                    }
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    onKey={this.onKey}
                    style={style}
                    maxLength={maxlength || 30}
                    tabIndex={tab ? tab : null}
                    readOnly={readonly || readonlyToBlockAutocomplete || parent_disabled ? 'readonly' : null}
                    disabled={disabled && isFillable ? 'disabled' : null}
                    autoComplete={autocomplete && isFillable ? 'on' : null}
                    onClick={onClick ? onClick : null}
                    onChange={!readonly && !disabled && onChange ? onChange : () => { }}
                    onKeyUp={!readonly && !disabled && onKeyUp ? onKeyUp : null}
                    placeHolder={inner_label ? inner_label : null}
                />
            </div>
        );
    }
}