import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemovePage {
    convertLoadDataToCurrentData() {
        return {
            try_remove_sources: false
        };
    }

    getQuery() {
        return null;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text' },
                    { text: 'Remove current page', style: 'subtitle' }
                ],
                [
                    { type: 'checkbox', id: 'try_remove_sources', label: 'Try remove sources' },
                    { value: Current_Data.try_remove_sources, styling_type: 'switch' }
                ],
                [
                    { type: Current_Data.try_remove_sources ? 'text' : 'hidden', id: 'try_remove_sources_text', colspan: 2 },
                    {
                        text: 'When trying to delete sources, underlying images, texts and/or videos<br>' +
                            'will also be deleted from the collection, if not used<br>' +
                            'anywhere else.', style: 'normal_grey'
                    }
                ],
                [
                    { type: Current_Data.try_remove_sources ? 'hidden' : 'text', id: 'keep_sources_text', colspan: 2 },
                    {
                        text: 'When not removing sources, underlying images, texts and/or videos<br>' +
                            'will not be deleted from the collection, thus will remain available<br>' +
                            'for elements on other pages.', style: 'normal_grey'
                    }
                ],
                [
                    { type: 'space', colspan: 2, height: 23 }
                ],
                [
                    { type: 'text', h_align: 'center', colspan: 2 },
                    { text: 'Are you sure?', style: 'bold' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: 'remove_page',
                        Function_Data,
                        process_tag: 'Removing...',
                        onSubmitted: Result_Data => {
                            navigate(
                                Function_Data.default_language_type,
                                Function_Data.language_type,
                                Function_Data.device_type,
                                Function_Data.base_url,
                                Function_Data.site_url,
                                []
                            );
                        }
                    }
                });
            }, 'Delete')
        ];
    }
}