import React, { Component } from 'react';
import { buildFormElementID, buildFormElementName } from '../../utils/form';

export default class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false
        };
    }

    render() {
        const { frm_id, Data_Groups, fld_id, tab, El_Data, parent_disabled } = this.props;
        var id = buildFormElementID(frm_id, Data_Groups, fld_id),
            name = El_Data.name,
            tag = El_Data.tag,
            submit_action = El_Data.value,
            color = El_Data.color,
            Style = El_Data.Style,
            onClick = El_Data.onClick,
            disabled = El_Data.disabled || false,
            responsive = false, //TODO
            text_size = '';

        const style = { position: 'relative' },
            substyle = {},
            substyle2 = {},
            textstyle = {};

        if (Style) {
            ['width', 'height', 'marginTop', 'marginLeft', 'marginRight', 'marginBottom']
                .map((prop, index) => ({ prop, value: Style[index] }))
                .filter(set => set.value && !isNaN(set.value))
                .forEach(set => {
                    style[set.prop] = set.value + 'px';
                });

            if (Style[6] === 'cus' && Style[0]) {
                substyle.width = Style[0] + 'px';
                substyle2.width = (Style[0] - 2) + 'px';
            }

            if (!responsive && Style[1]) {
                substyle.height = Style[1] + 'px';
                substyle2.height = (Style[1] - 2) + 'px';
                substyle.lineHeight = (Style[1] - 2) + 'px';
            }
        }

        return (
            <div
                id={id}
                style={style}
            >
                <input
                    type="button"
                    id={id}
                    name={name ? buildFormElementName(Data_Groups, name, 'button') : null}
                    value={submit_action}
                    tabIndex={tab ? tab : null}
                    disabled={disabled}
                    onClick={!disabled && !parent_disabled && onClick ? (e => { e.preventDefault(); onClick(e); }).bind(this) : null}
                />
                <div
                    id={'button_1_' + id}
                    className={'m_button_1 ' + color}
                    style={substyle}
                >
                    <div
                        id={'button_2_' + id}
                        className={'m_button_2 ' + color}
                        style={substyle2}
                    >
                        <span
                            className={'m_button_text ' + color + ' white_text' +
                                (responsive ?
                                    ' button_text_responsive' :
                                    (text_size === 'big' ? ' big' : '')
                                )
                            }
                            style={textstyle}
                        >
                            {tag}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}