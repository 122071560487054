import React, { Component } from 'react';
import { Tools_Action_Paneled } from './tools_action';
import { findArray } from '../utils/array';

export default class Page_Tools extends Component {
    handleChange(depth_index, event) {
        const { Function_Data, navigate } = this.props;
        var Page_URLs = [];
        for (var j = 0; j < depth_index + 1; j++) {
            if (j < depth_index || event.target.value) {
                Page_URLs.push(
                    j === depth_index ?
                        event.target.value :
                        Function_Data.Page_URLs[j]
                );
            }
        }

        navigate(
            Function_Data.default_language_type,
            Function_Data.language_type,
            Function_Data.device_type,
            Function_Data.base_url,
            Function_Data.site_url,
            Page_URLs
        );
    }

    renderSelector(Page_Options, depth_index) {
        const { Function_Data, tab } = this.props;
        const Parent_Page_Option = {
            type: 'regular',
            title: '--- Parent Page ---',
            page_url: ''
        };
        const Options = (Page_Options.length > 0 && depth_index > 0 ?
            [Parent_Page_Option] :
            []
        ).concat(Page_Options);

        return (
            <div
                key={'open_page_select_' + depth_index}
                className="styled_select_holder black tools_dropdown_selector_holder"
            >
                <div>
                    <select
                        id={'open_page_select_' + depth_index}
                        name={'open_page_select_' + depth_index}
                        tabIndex={tab}
                        className="tools_dropdown_selector"
                        value={Function_Data.Page_URLs[depth_index] || ''}
                        onChange={(e) => this.handleChange(depth_index, e)}
                    >
                        {Options.map(Option => this.renderOption(Option))}
                    </select>
                    <div></div>
                </div>
            </div>
        );
    }

    renderOption(Option) {
        return (
            <option
                key={Option.page_url}
                className="tools_dropdown_selector_option"
                value={Option.page_url}
            >
                {Option.type === 'home_page' ? '--- Home page ---' : Option.title}
            </option>
        );
    }

    getPageSelectors() {
        const { Function_Data, Pages } = this.props;
        let Selectors = [];
        let Sub_Pages = Pages;
        let Page;
        let hasChildPages;
        let i = 0;

        do {
            Selectors.push(Sub_Pages.map(Sub_Page => ({
                page_url: Sub_Page.page_url,
                title: Sub_Page.title,
                type: Sub_Page.type
            })));
            Page = findArray(
                Sub_Pages,
                Sub_Page => Function_Data.Page_URLs[i] === Sub_Page.page_url
            );
            hasChildPages = Page && Page.Sub_Pages && Page.Sub_Pages.length;
            if (hasChildPages) {
                Sub_Pages = Page.Sub_Pages;
                i++;
            }
        } while (hasChildPages);

        return Selectors;
    }

    render() {
        const { Function_Data } = this.props;

        const Buttons = [
            ['create_page', 'New', 'new_page'],
            ['copy_page', 'Copy To New', 'copy_page'],
            ['edit_page', 'Settings', 'page_options'],
            ['remove_page', 'Remove', 'remove_page']/*,
             ['manage_pages', 'Manage Pages', 'pages']*/,
            ['manage_scopes', 'Scope Groups', 'scopes']
        ];

        const Selectors = this.getPageSelectors();

        var style = { height: (7 + (Selectors.length * 24)) + 'px' };
        return (
            <div
                className="tools_dropdown_container_holder"
                style={style}
            >
                <div className="main_actions">
                    <Tools_Action_Paneled
                        given_style="button_list"
                        Function_Data={Function_Data}
                        id="file_action_group"
                        icon="file"
                        color="black"
                        Buttons={Buttons}
                    />
                </div>
                <div className="tools_dropdown_container">
                    {Selectors.map((Options, index) =>
                        this.renderSelector(Options, index))
                    }
                </div>
            </div>
        );
    }
}