import React, { Component } from 'react';
import crap_store from '../api/crap_store.js';
import dirty_store from '../api/dirty_store.js'; //TODO: get rid of this
import form_data_store from '../api/form_data_store';
import { loadCrapIntoCache } from '../api/main_service.js';
import tools_store from '../api/tools_store';
import ChoicesForm from '../layer/choices_form';
import { getFormModel } from '../layer/form_data';
import PanelFormContent from '../layer/panel_form_content';
import { getPanelData } from '../tools/panel_data';

export default class Overlay extends Component {
    constructor(props) {
        super(props);
        this.Form_Model = null;

        const { Function_Data, Action_Data } = props.panel_data;

        this.state = {
            Action_Data: Object.assign({}, Action_Data),
            isLoading: true,
        };

        form_data_store.dispatch({
            type: 'INIT',
            Function_Data,
            Action_Data
        });
        this.EventListeners = [];
        this.unsubscribes = [];
    }

    componentDidMount() {
        this.unsubscribes = [
            crap_store.subscribe(this.handleMainStoreChange.bind(this))
        ];
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        //const query = this.Form_Model.getQuery(Action_Data);
        loadCrapIntoCache(Object.assign({}, Function_Data, Action_Data), result => {
            this.data = result;
            this.setState({
                isLoading: false
            }, () => {
            });
        });
    }

    componentWillUnmount() {
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    handleMainStoreChange() {
        const { action } = crap_store.getState();
        if (action === 'CACHE_CRAP') {
            this.forceUpdate();
        }
    }

    refetch() {
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        //const query = this.Form_Model.getQuery(Action_Data);
        loadCrapIntoCache(Object.assign({}, Function_Data, Action_Data), result => {
            this.data = result;
            this.forceUpdate();
        });
    }

    updateActionData(Action_Data) {
        this.setState({ Action_Data: Object.assign({}, this.state.Action_Data, Action_Data) });
    }

    getIndex(frm_name, data_action_type) {//TODO: get rid of this
        const { Indexes } = dirty_store.getState();
        if (Indexes && Indexes[`${frm_name}__${data_action_type}`]) {
            return Indexes[`${frm_name}__${data_action_type}`];
        }
        dirty_store.dispatch({
            type: 'ADD_FORM_INDEX',
            form_action_data_type: `${frm_name}__${data_action_type}`
        });
        return dirty_store.getState().Indexes[`${frm_name}__${data_action_type}`];
    }

    renderToolPanelContent(Load_Data, tab, refetch) {
        const { Function_Data } = this.props.panel_data;
        const { Action_Data } = this.state;
        const reSizeAndReposition = () => { };//TODO: refactor so this dummy method isn't required.
        const navigate = () => { };//TODO: refactor so this dummy method isn't required.
        const is_public_form = false;//TODO: make dynamic
        const frm_id_int = this.getIndex(Action_Data.frm_name, Action_Data.data_action_type);

        return (Action_Data.panel_type === 'element_style_panel' && Action_Data.el_type === 'menu' ?
            <ChoicesForm
                Form_Model={this.Form_Model}
                Function_Data={Function_Data}
                Action_Data={Action_Data}
                frm_id_int={frm_id_int}
                Load_Data={Load_Data}
                updateActionData={this.updateActionData.bind(this)}
                reSizeAndReposition={reSizeAndReposition}
                reloadForm={refetch}
                navigate={navigate}
            /> :
            <PanelFormContent
                Form_Model={this.Form_Model}
                Function_Data={Function_Data}
                Action_Data={Action_Data}
                frm_id_int={frm_id_int}
                Load_Data={Load_Data}
                updateActionData={this.updateActionData.bind(this)}
                is_public_form={is_public_form}
                tab={tab}
                upload={false}
                reSizeAndReposition={reSizeAndReposition}
                reloadForm={refetch}
                navigate={navigate}
                holder_type="overlay"
            />
        );
    }

    closeOverlay() {
        tools_store.dispatch({
            type: 'CLOSE_OVERLAY_TOOLS',
        });
    }

    render() {
        const { isLoading } = this.state;
        const { Action_Data } = this.state;
        const Panel_Settings = getPanelData(Action_Data);
        this.Form_Model = getFormModel(Action_Data.frm_name);
        const tab = 1;

        return (
            <div style={{ height: '100%', display: 'table', width: '100%' }}>
                <div className="panel_title_bar unselectable dark" unselectable="on" style={{ display: 'table-row' }}>
                    <h1 className="gp white large title_txt"> {Panel_Settings.title}</h1>
                    <div className="close_icon" onClick={this.closeOverlay}></div>
                </div>
                {!isLoading && this.renderToolPanelContent(
                    this.data, //Load_Data
                    tab,
                    this.refetch
                )}
            </div>
        );
    }
}
