import React, { Component } from 'react';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { buildFormElementID } from '../../utils/form';
import { clone } from '../../utils/object';
import { ucWords } from '../../utils/string';
import { renderButton, renderGrid, renderRow } from './management_grid';

export default class LanguageManagement extends Component {
    onClickDelete(Language) {
        const { Function_Data, Action_Data } = this.props;
        return () => layer_store.dispatch({
            type: 'OPEN_PANEL',
            panel_data: {
                Function_Data,
                Action_Data: {
                    access: 'authorized',
                    panel_type: 'remove_language_panel',
                    frm_name: 'remove_language',
                    language_id: Language.id,
                    parent_panel_type: Action_Data.panel_type,
                    data_action_type: 'remove'
                }
            },
        });
    }

    onClickSelectAsDefaultButton(Selected_Language) {
        const { frm_id, El_Data, Function_Data, changeCurrentData } = this.props;

        return () => {
            changeCurrentData({
                language_id: Selected_Language.id
            });

            form_data_store.dispatch({
                type: 'SUBMIT',
                submitData: {
                    frm_id,
                    submit_action: 'set_as_default_language',
                    Function_Data,
                    process_tag: 'Saving...',
                    onSubmitted: default_id => {
                        changeCurrentData({
                            Languages: clone(El_Data.Languages).map(Language => {
                                Language.default = parseInt(Language.id, 10) === default_id ? 'true' : 'false';
                                return Language;
                            })
                        });
                    },
                    isPanelRequiredToStayOpen: true
                }
            });
        };
    }

    render() {
        const { frm_id, Data_Groups, fld_id, El_Data, tab } = this.props;
        const id = buildFormElementID(frm_id, Data_Groups, fld_id);

        return El_Data.Languages.length === 0 ?
            (<p className="gp">No languages available.</p>) :
            renderGrid(
                id + '_language_list',
                [
                    { label: 'Language' },
                    { label: 'Default' },
                    { label: '' },
                ],
                this.props.El_Data.Languages.map((Language, index) =>
                    renderRow(
                        id + '_language_row_' + Language.id,
                        index,
                        [
                            { type: 'text', value: ucWords(Language.name) },
                            {
                                type: (Language.default === 'true' ? 'text' : 'custom'),
                                value: 'Selected',
                                content: (Language.default === 'true' ?
                                    '' :
                                    renderButton(
                                        'set_as_default_language', 'Select',
                                        this.onClickSelectAsDefaultButton(Language),
                                        [48, 17], frm_id, Data_Groups, tab, index
                                    )
                                )
                            },
                            {
                                type: (Language.default === 'true' ? 'text' : 'action'),
                                value: '',
                                icon: 'delete',
                                onClick: this.onClickDelete(Language)
                            }
                        ]
                    )
                )
            );
    }
}