import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColGridSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Grid: {
                    name: ''
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Grid {
                    name: String
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Grid', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Grid.name, max: 2, required: true }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_grid_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}