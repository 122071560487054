import deepmerge from 'deepmerge';
import { createStore } from 'redux';
import { postData } from '../api/post_query';

const initialState = {
    action: '',
    Current_Form_Data: {},
    Current_Form_Data_Groups: {}
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'INIT':
            state.Current_Form_Data.Function_Data = action.Function_Data;
            state.Current_Form_Data.Action_Data = action.Action_Data;
            return state;
        case 'UPDATE':
            state.Current_Form_Data[action.frm_id] = action.Current_Form_Data;
            state.Current_Form_Data_Groups[action.frm_id] = action.Data_Groups;
            return state;
        case 'UPDATE_FORM_FIELD_VALUE':
            state.Current_Form_Data[action.frm_id][action.fld_id] = action.fld_value;
            return state;
        case 'DISCARD':
            resetData(state, action.frm_id);
            return state;
        case 'SUBMIT':
            submitData(
                state,
                action.submitData,
                action.submitData.frm_id ? state.Current_Form_Data_Groups[action.submitData.frm_id] : []
            );
            return state;
        default:
            return state;
    }
});

const resetData = (state, frm_id) => {
    state.Current_Form_Data[frm_id] = {};
    state.Current_Form_Data_Groups[frm_id] = undefined;
};

const submitData = (state, submitData, Data_Groups) => {
    const {
        frm_id, submit_action, Function_Data, process_tag = 'Processing...',
        onSubmitted, onClick, isPanelRequiredToStayOpen, post_language, holder_type = 'panel'
    } = submitData;

    //TODO: only use specific frm_id keys... should be supplied by action (or not frm_ids,
    // but make Current_Form_Data more generic (not frm_id keys) and on submit, the action
    // should simply specify what kind of Data it wants from the Current_Form_Data...)
    // CURRENT METHOD CAN CAUSE ERRORS, BECAUSE SIMILAR PROPERTIES OVERRIDE EACHOTHER...
    let Current_Data = {};
    Object.keys(state.Current_Form_Data).forEach(key => {
        Current_Data = deepmerge(Current_Data, state.Current_Form_Data[key]);
    });

    postData(
        frm_id, Data_Groups, submit_action, Function_Data, Current_Data, process_tag, onSubmitted,
        onClick, isPanelRequiredToStayOpen, post_language, holder_type
    );

    if (!isPanelRequiredToStayOpen) {
        //TODO: in case the form was preceded by other forms (e.g. "Next" button) those have to be discard as well
        resetData(state, frm_id);
    }
};

export default store;