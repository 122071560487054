import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { clone } from '../../utils/object';

export default class FormColLineSettings {
    convertLoadDataToCurrentData(Load_Data, Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            {
                Form_Col_Line: {
                    name: '',
                    colspan: 2,
                    color: 'ffffff',
                    thickness: 1,
                    space_1: 0,
                    space_2: 0
                }
            } :
            clone(Load_Data);
    }

    getQuery(Action_Data) {
        return Action_Data.data_action_type === 'create' ?
            null :
            `query DataQuery($urls: String!) {
                Form_Col_Line {
                    name: String
                    colspan
                    color: String
                    thickness
                    space_1
                    space_2
                }
            }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('Form_Col_Line', [
                [
                    { type: 'field', id: 'name', label: 'Name' },
                    { value: Current_Data.Form_Col_Line.name, max: 2 }
                ],
                [
                    { type: 'number', id: 'colspan', label: 'Colspan' },
                    { value: Current_Data.Form_Col_Line.colspan, min_val: 1, max_val: 2 }
                ],
                [
                    { type: 'color', id: 'color', label: 'Color' },
                    { value: Current_Data.Form_Col_Line.color }
                ],
                [
                    { type: 'number', id: 'thickness', label: 'Thickness' },
                    { value: Current_Data.Form_Col_Line.thickness, min_val: 1, max_val: 99 }
                ],
                [
                    { type: 'number', id: 'space_1', label: 'Space top' },
                    { value: Current_Data.Form_Col_Line.space_1, min_val: 0, max_val: 99, unit: 'px' }
                ],
                [
                    { type: 'number', id: 'space_2', label: 'Space bottom' },
                    { value: Current_Data.Form_Col_Line.space_2, min_val: 0, max_val: 99, unit: 'px' }
                ]
            ]),
            getDoubleSubmitSet(frm_id, () => {
                form_data_store.dispatch({
                    type: 'SUBMIT',
                    submitData: {
                        frm_id,
                        submit_action: Action_Data.data_action_type === 'create' ? 'add_element' : 'save_form_col_line_settings',
                        Function_Data,
                        process_tag: Action_Data.data_action_type === 'create' ? 'Creating...' : 'Saving...',
                        onSubmitted: Result_Data => {
                            //TODO
                        }
                    }
                });
            }, Action_Data.data_action_type === 'create' ? 'Create' : 'Save')
        ];
    }
}