import deepmerge from 'deepmerge';
import font_family_store from '../../api/font_family_store';
import form_data_store from '../../api/form_data_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { ucWords } from '../../utils/string';

export default class RemoveFontFamily {
    convertLoadDataToCurrentData(Load_Data) {
        const { Font_Families, usage_amount } = Load_Data;
        return deepmerge(
            Load_Data,
            {
                usage_amount: parseInt(usage_amount, 10),
                switched_font_family_id: Font_Families && Font_Families.length > 0 ? Font_Families[0].id : 0
            }
        );
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Font_Families
            Font_Family {
                id
                main
            }
            usage_amount
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    { type: 'text', colspan: 2 },
                    {
                        text: Current_Data.usage_amount > 0 && Current_Data.Font_Families.length === 0 ?
                            ('The font family can\'t be deleted, because<br>\
                                    there' + (Current_Data.usage_amount === 1 ?
                                    '\'s still 1 place' :
                                    ' are still ' + Current_Data.usage_amount + ' places'
                                ) + '<br>where it is used and there aren\'t any<br>\
                                    other font families to switch to.'
                            ) :
                            'You are about to delete the font family named: "' +
                            ucWords(Current_Data.Font_Family.main) + '"',
                        style: 'bold'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount === 0 || Current_Data.Font_Families.length > 0 ? 'text' : 'hidden',
                        colspan: 2
                    },
                    {
                        text: Current_Data.usage_amount > 0 ?
                            ('There ' + (Current_Data.usage_amount === 1 ?
                                'is 1 text that is' :
                                'are ' + Current_Data.usage_amount + ' texts that are'
                            ) +
                                ' still using this font family!<br>' +
                                'Please specify which font family ' +
                                (Current_Data.usage_amount === 1 ? 'this text' : 'these texts') +
                                ' should switch to.'
                            ) :
                            'This font family isn\'t used by any text,<br>' +
                            'thereby this font family can be safely deleted.',
                        style: 'normal_grey'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Font_Families.length > 0 ? 'dropdown' : 'hidden',
                        id: 'switched_font_family_id'
                    },
                    {
                        value: Current_Data.switched_font_family_id,
                        Options: Current_Data.Font_Families
                            .map(Font_Family => [Font_Family.id, ucWords(Font_Family.main)]),
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount === 0 || Current_Data.Font_Families.length > 0 ? 'text' : 'hidden',
                        colspan: 2,
                        h_align: 'center'
                    },
                    { text: 'Are you sure?', style: 'bold' }
                ]
            ]),
            Current_Data.usage_amount > 0 && Current_Data.Font_Families.length === 0 ?
                getSingleSubmitSet(frm_id, 'OK') :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_font_family',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: Result_Data => {
                                if (Result_Data) {
                                    font_family_store.dispatch({
                                        type: 'REMOVE',
                                        removed_id: Current_Data.Font_Family.id,
                                        replacement_id: Current_Data.switched_font_family_id
                                    });
                                }
                            }
                        }
                    });
                }, 'Delete')
        ];
    }
}