import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

window.initReact = (show_tools, Navigation_Data) => {
    ReactDOM.render(
        <App
            show_tools={show_tools}
            Navigation_Data={Navigation_Data}
        />,
        document.getElementById('app')
    );
};
