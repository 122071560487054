import deepmerge from 'deepmerge';
import form_data_store from '../../api/form_data_store';
import gradient_store from '../../api/gradient_store';
import { getDoubleSubmitSet, getSingleSubmitSet, wrapSectionSubs } from '../../form/utils';

export default class RemoveGradient {
    convertLoadDataToCurrentData(Load_Data) {
        const { Gradients, usage_amount } = Load_Data;
        return deepmerge(
            Load_Data,
            {
                switched_gradient_idx: Gradients && Gradients.length > 0 ? Gradients[0].idx : 0,
                usage_amount: parseInt(usage_amount, 10)
            }
        );
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Gradients
            Gradient {
                idx
                name
            }
            usage_amount
        }`;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        return [
            wrapSectionSubs('', [
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Gradients.length === 0 ? 'text' : 'hidden',
                        colspan: 2
                    },
                    {
                        text: 'The gradient can\'t be deleted, because<br>\
                                    there' + (Current_Data.usage_amount === 1 ?
                                '\'s still 1 place' :
                                ' are still ' + Current_Data.usage_amount + ' places'
                            ) + '<br>where it is used and there aren\'t any<br>\
                                     other gradients to switch to.',
                        style: 'bold'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount === 0 || Current_Data.Gradients.length > 0 ? 'text' : 'hidden',
                        colspan: 2,
                        h_align: 'center'
                    },
                    {
                        text: 'You are about to delete the gradient named: "' + Current_Data.Gradient.name + '"',
                        style: 'bold'
                    }
                ],
                [
                    { type: Current_Data.usage_amount > 0 && Current_Data.Gradients.length > 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: 'There ' +
                            (Current_Data.usage_amount === 1 ?
                                'is 1 background that is' :
                                'are ' + Current_Data.usage_amount + ' backgrounds that are'
                            ) +
                            ' still using this gradient!<br>' +
                            'Please specify which gradient ' +
                            (Current_Data.usage_amount === 1 ? 'this background' : 'these backgrounds') +
                            ' should switch to.',
                        style: 'normal_grey'
                    }
                ],
                [
                    {
                        type: Current_Data.usage_amount > 0 && Current_Data.Gradients.length > 0 ? 'dropdown' : 'hidden',
                        id: 'switched_gradient_idx'
                    },
                    {
                        value: Current_Data.switched_gradient_idx,
                        Options: Current_Data.Gradients ?
                            Current_Data.Gradients.map(Gradient => [Gradient.idx, Gradient.name]) :
                            [],
                    }
                ],
                [
                    { type: Current_Data.usage_amount === 0 ? 'text' : 'hidden', colspan: 2 },
                    {
                        text: 'This gradient isn\'t used by any backgrounds,<br>' +
                            'thereby this gradient can be safely deleted.',
                        style: 'normal_grey'
                    }
                ]
            ]),
            Current_Data.usage_amount > 0 && Current_Data.Gradients.length === 0 ?
                getSingleSubmitSet(frm_id, 'OK') :
                getDoubleSubmitSet(frm_id, () => {
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'remove_gradient',
                            Function_Data,
                            process_tag: 'Removing...',
                            onSubmitted: Result_Data => {
                                if (Result_Data) {
                                    //TODO: use Result_Data to check if deletion was successful
                                    gradient_store.dispatch({
                                        type: 'REMOVE',
                                        removed_idx: Current_Data.Gradient.idx,
                                        replacement_idx: Current_Data.switched_gradient_idx
                                    });
                                } else {
                                    alert('Some error occured when trying to delete the gradient.')
                                }
                            }
                        }
                    });
                }, 'Delete')
        ];
    }
}