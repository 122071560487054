import { createStore } from 'redux';

const initialState = {
    action: '',
    Panels: [],
    Overlays: [],
    Dropdowns: [],
    ColorPicker: null,
    DatePicker: null,
    Lightbox: null,
    MobileMenu: null
};

const store = createStore((state = initialState, action) => {
    state.action = action.type.toLowerCase();

    switch (action.type) {
        case 'OPEN_PANEL':
            state.Panels.push(action.panel_data);
            return state;
        case 'CLOSE_PANEL':
            state.Panels.splice(-1, 1);
            return state;
        case 'SHOW_DROPDOWN':
            state.Dropdowns.push(action.name);
            return state;
        case 'HIDE_ALL_DROPDOWNS':
            state.Dropdowns = [];
            return state;
        case 'SHOW_COLORPICKER':
            state.ColorPicker = action.options;
            return state;
        case 'HIDE_COLORPICKER':
            state.ColorPicker = null;
            return state;
        case 'SHOW_DATEPICKER':
            state.DatePicker = action.options;
            return state;
        case 'HIDE_DATEPICKER':
            state.DatePicker = null;
            return state;
        case 'SHOW_MOBILE_MENU':
            state.MobileMenu = action.options;
            return state;
        case 'HIDE_MOBILE_MENU':
            state.MobileMenu = null;
            return state;
        case 'SHOW_LIGHTBOX':
            state.Lightbox = {
                active_image: action.active_image
            };
            return state;
        case 'HIDE_LIGHTBOX':
            state.Lightbox = null;
            return state;
        default:
            return state;
    }
});

export default store;