import deepmerge from 'deepmerge';
import $ from 'jquery';
import form_data_store from '../../api/form_data_store';
import layer_store from '../../api/layer_store';
import { getDoubleSubmitSet, wrapSectionSubs } from '../../form/utils';
import { buildCSSTextStyle } from '../../styling/text';
import { distillAttributesFromTag } from '../../utils/attributes';
import { distillColorFromCSSString } from '../../utils/color';
import { distillFontFamilyId } from '../../utils/font_family';
import { buildFormElementID } from '../../utils/form';
import { clone } from '../../utils/object';
import { buildURL } from '../../utils/path';
import { ucWords } from '../../utils/string';
import text_editor_store from '../../api/text_editor_store';

export default class TextEditor {
    convertLoadDataToCurrentData(Load_Data) {
        text_editor_store.dispatch({
            type: 'CHANGE_STATE',
            isEditing: true,
            active_idx: Load_Data.Text.el_idx
        });

        return deepmerge(Load_Data, {
            max_length: 40000,
            language: 'english'
        });
    }

    getQuery() {
        return `query DataQuery($urls: String!) {
            Languages
            Font_Families
            Text {
                el_idx
                include_in_search
                ps_layout
                ps_type
                site_id
                text: String
                text_source_groud_id
            }
            Text_Styles
            new_language_variant
            language_id
        }`;
    }

    processTextEditorCommand(Current_Data, $text_el, command, Arguments) {
        var selection = $('#text_editor_iframe')[0].contentWindow.getSelection();
        var section_type = 'p';//TODO: get section_type from Text Settings.

        if (!selection.isCollapsed) {
            if (Current_Data.Text.text.replace(/(<([^>]+)>)/ig, '').length > Current_Data.max_length) {
                alert('Cannot process command, because the text has too much characters.');
            } else {
                var Selection_Pos = this.getSelectionPosition(selection, $text_el);

                if (Selection_Pos[0] < Selection_Pos[1]) {
                    var anchor = 0,
                        focus = 0,
                        focus_found = false,
                        anchor_found = false,
                        dig = (selection, el) => {
                            $(el).contents().each((i, content_el) => {
                                if (content_el.nodeType === 1) {
                                    dig(selection, content_el);
                                } else {
                                    if (!focus_found && (content_el === selection.focusNode || content_el.isSameNode(selection.focusNode))) {
                                        focus_found = true;
                                        focus += selection.focusOffset;
                                    }
                                    if (!anchor_found && (content_el === selection.anchorNode || content_el.isSameNode(selection.anchorNode))) {
                                        anchor_found = true;
                                        anchor += selection.anchorOffset;
                                    }
                                    if (!focus_found) {
                                        focus += content_el.length;
                                    }
                                    if (!anchor_found) {
                                        anchor += content_el.length;
                                    }
                                }
                                if (focus_found && anchor_found) {
                                    return false;
                                }
                            });
                        };

                    dig(selection, $text_el[0]);

                    var Text_Pos = anchor < focus ? [anchor, focus] : [focus, anchor];
                    var Sections_Wrap_Position = this.determinePositionOfSectionsWrappingSelection(
                        Current_Data.Text.text, Selection_Pos
                    );
                    var Result = this.convertTextHTMLToTextModel(
                        Current_Data.Font_Families, Current_Data.Text.text, Selection_Pos, Sections_Wrap_Position
                    );

                    if (command) {
                        Result['Text_Model'] = this.applyCmd(
                            Current_Data.Font_Families,
                            Result['start_selection_index_new_text'],
                            Result['end_selection_index_new_text'],
                            Result['Text_Model'],
                            command,
                            Arguments
                        );
                    }

                    var Fonts_By_IDs = {};
                    for (var i = 0; i < Current_Data.Font_Families.length; i++) {
                        Fonts_By_IDs[Current_Data.Font_Families[i]['id']] = Current_Data.Font_Families[i];
                    }

                    $text_el.html(
                        Current_Data.Text.text.substring(0, Sections_Wrap_Position[0]) +
                        this.buildHTMLTextString(
                            Fonts_By_IDs,
                            this.optimize(
                                Current_Data.Text_Styles,
                                Result['Text_Model']
                            ),
                            section_type
                        ) +
                        Current_Data.Text.text.substring(Sections_Wrap_Position[1], Current_Data.Text.text.length)
                    );

                    var start = Text_Pos[0],
                        end = Text_Pos[1],
                        pos = 0,
                        range = document.createRange(),
                        start_found = false,
                        end_found = false,
                        dig2 = (el) => {
                            $(el).contents().each((i, content_el) => {
                                if (content_el.nodeType === 1) {
                                    dig2(content_el);
                                } else {
                                    if (pos <= start && pos + content_el.length >= start) {
                                        range.setStart(content_el, start - pos);
                                        start_found = true;
                                    }
                                    if (pos < end && pos + content_el.length >= end) {
                                        range.setEnd(content_el, end - pos);
                                        end_found = true;
                                    }
                                    pos = pos + content_el.length;
                                }
                                if (start_found && end_found) {
                                    return false;
                                }
                            });
                        };

                    dig2($text_el[0]);
                    $('#text_editor_iframe')[0].contentWindow.getSelection().removeAllRanges();

                    if (!start_found) {
                        range.setStart($text_el[0], 0);
                    }
                    if (end_found) {
                        $('#text_editor_iframe')[0].contentWindow.focus();
                        $('#text_editor_iframe')[0].contentWindow.getSelection().addRange(range);
                    }
                }
            }
        }
    }

    getSelectionPosition(selection, $text_el) {
        var start_pos = -1,
            end_pos = -1,
            $start_el = $('<div>', {
                id: 'sel_start',
                class: 'sel_position'
            }),
            $end_el = $('<div>', {
                id: 'sel_end',
                class: 'sel_position'
            });

        if (selection.getRangeAt) {
            var range = selection.getRangeAt(0);
            var clonedRange = range.cloneRange();
            range.insertNode($start_el[0]);
            range.collapse(false);
            range.insertNode($end_el[0]);

            var text_with_sel_tags = $text_el.html(),
                start_div = $('<div>').append($start_el).html(),
                end_div = $('<div>').append($end_el).html();

            start_pos = text_with_sel_tags.indexOf(start_div);
            end_pos = text_with_sel_tags.indexOf(end_div) - start_div.length;
            $text_el.find('.sel_position').remove();
            selection.removeAllRanges();
            selection.addRange(clonedRange);
        }

        if (start_pos === -1 || end_pos === -1 || start_pos > end_pos) {
            alert('Error 00663: Something went wrong when processing the text. Keep getting this error? Contact us: mail@rootflex.com');//Can't determine selection position
        }

        return [start_pos, end_pos];
    }

    determinePositionOfSectionsWrappingSelection(text, Selection_Position) {
        var amount_of_tags_open = 0,
            process_position = 0,
            max_iterations = 40000,
            i = 0,
            Tag_Position = [0, 0],
            Sections_Wrap_Position = [0, 0];

        do {
            i++;
            Tag_Position[0] = text.indexOf('<', process_position);
            Tag_Position[1] = text.indexOf('>', Tag_Position[0]);
            if (Tag_Position[0] !== -1 && Tag_Position[1] !== -1) {
                if (Tag_Position[0] >= Selection_Position[1] && amount_of_tags_open === 0) {
                    process_position = Tag_Position[0];
                } else {
                    if (Tag_Position[0] < Selection_Position[0] && amount_of_tags_open === 0) {
                        Sections_Wrap_Position[0] = Tag_Position[0];
                    }
                    var tag = text.substr(Tag_Position[0] + 1, Tag_Position[1] - Tag_Position[0] - 1),
                        tag_state = this.determineState(tag),
                        tag_type = this.identifyType(tag, tag_state);

                    if (tag_state === 'open' && !['br', 'hr'].includes(tag_type)) {
                        amount_of_tags_open++;
                    } else if (tag_state === 'close') {
                        amount_of_tags_open--;
                    }
                    process_position = Tag_Position[1];
                    if (process_position < Selection_Position[0] && amount_of_tags_open === 0) {
                        Sections_Wrap_Position[0] = process_position + 1;
                    }
                    process_position++;
                }
            } else {//No remaining '<...>' pair found, so no remaining tag, so add remaining text segment & stop
                if (process_position < Selection_Position[0]) {
                    Sections_Wrap_Position[0] = process_position;
                }
                process_position = text.length;
            }
        } while (
            Tag_Position[0] !== -1 && Tag_Position[1] !== -1 &&
            i <= max_iterations &&
            process_position < text.length &&
            (
                amount_of_tags_open !== 0 ||
                process_position <= Selection_Position[1]
            )
        );

        if (i > max_iterations) {
            alert('Error 00661: Cannot complete process because maximum amount of iteration has been reached. If this error should not occur, contact us: mail@rootflex.com');//Bug in Model or too big Model
        }

        Sections_Wrap_Position[1] = process_position;

        return Sections_Wrap_Position;
    }

    resetOutput() {
        return {
            Status: {
                section_active: 0,
                setting_just_updated: 0,
                setting_active: 0,
                link_active: 0,
                link_was_closed: 0,
                amount_of_tags_open: 0,
                text_cut: 0
            },
            Model: [],
            Settings: [],
            Link: 0,
            text_align: 0
        };
    }

    convertTextHTMLToTextModel(Fonts, text, Selection_Pos, Sections_Wrap_Position) {
        var
            Output = this.resetOutput(),
            Segment,
            pos = Sections_Wrap_Position[0],
            max_iterations = 40000,
            i = 0,
            begin_tag,
            end_tag,
            start_selection_index_new_text = 0,
            end_selection_index_new_text = 0,
            end_selection_index_found = false;

        do {
            i++;
            begin_tag = text.indexOf('<', pos);
            end_tag = text.indexOf('>', begin_tag);
            if (begin_tag !== -1 && end_tag !== -1) {
                Segment = this.processSegment(
                    begin_tag,
                    pos,
                    Selection_Pos,
                    Output,
                    text,
                    start_selection_index_new_text,
                    end_selection_index_new_text,
                    end_selection_index_found
                );
                Output = Segment['Output'];
                start_selection_index_new_text = Segment['start_selection_index_new_text'];
                end_selection_index_new_text = Segment['end_selection_index_new_text'];
                end_selection_index_found = Segment['end_selection_index_found'];

                Output = this.tryConstructTag(Fonts, begin_tag, end_tag, text, Output);
                pos = end_tag + 1;
            } else {//No remaining '<...>' pair found, so no remaining tag, so add remaining text segment & stop
                if (pos < Selection_Pos[0]) {
                    Output = this.tryConstructTextSegment(pos, text, Output, Selection_Pos[0]);
                    Output['Status']['text_cut'] = 1;
                    start_selection_index_new_text = Output['Model'].length;
                    Output = this.tryConstructTextSegment(Selection_Pos[0], text, Output, Selection_Pos[1]);
                    pos = Selection_Pos[1];
                    end_selection_index_new_text = Output['Model'].length;
                } else {
                    Output = this.tryConstructTextSegment(pos, text, Output, Selection_Pos[1]);
                    Output['Status']['text_cut'] = 1;
                    end_selection_index_new_text = Output['Model'].length;
                    Output = this.tryConstructTextSegment(Selection_Pos[1], text, Output, text.length);
                    pos = text.length;
                }
            }
            if (pos < Selection_Pos[0] && Output['Status']['amount_of_tags_open'] === 0) {
                Output = this.resetOutput();
            }
        } while (
            begin_tag !== -1 && end_tag !== -1 &&
            i <= max_iterations &&
            pos < text.length &&
            pos < Sections_Wrap_Position[1] &&
            !(
                Output['Status']['amount_of_tags_open'] === 0 &&
                pos > Selection_Pos[1]
            )
        );

        if (i > max_iterations) {
            alert('Error 00661: Cannot complete process because maximum amount of iteration has been reached. If this error should not occur, contact us: mail@rootflex.com');//Bug in Model or too big Model
        }

        if (Output['Status']['link_active']) {
            Output['Model'].push(['link_close']);
        }

        return {
            Text_Model: Output['Model'],
            start_selection_index_new_text: start_selection_index_new_text,
            end_selection_index_new_text: end_selection_index_new_text
        };
    }

    processSegment(begin_tag, pos, Selection_Pos, Output, text, start_selection_index_new_text, end_selection_index_new_text, end_selection_index_found) {
        if (begin_tag > pos) {
            if (begin_tag > Selection_Pos[0] && Selection_Pos[0] >= pos) {
                if (Selection_Pos[0] > pos) {
                    Output = this.tryConstructTextSegment(pos, text, Output, Selection_Pos[0]);
                    Output['Status']['text_cut'] = 1;
                }
                start_selection_index_new_text = Output['Model'].length;
                if (begin_tag > Selection_Pos[0]) {
                    if (begin_tag >= Selection_Pos[1] && Selection_Pos[1] >= pos) {
                        if (Selection_Pos[1] > pos) {
                            Output = this.tryConstructTextSegment(Selection_Pos[0], text, Output, Selection_Pos[1]);
                            Output['Status']['text_cut'] = 1;
                        }
                        end_selection_index_found = true;
                        end_selection_index_new_text = Output['Model'].length;
                        if (begin_tag > Selection_Pos[1]) {
                            Output = this.tryConstructTextSegment(Selection_Pos[1], text, Output, begin_tag);
                            Output['Status']['text_cut'] = 0;
                        }
                    } else {
                        Output = this.tryConstructTextSegment(Selection_Pos[0], text, Output, begin_tag);
                        Output['Status']['text_cut'] = 0;
                    }
                }
            } else if (begin_tag > Selection_Pos[1] && Selection_Pos[1] >= pos) {
                if (Selection_Pos[1] > pos) {
                    Output = this.tryConstructTextSegment(pos, text, Output, Selection_Pos[1]);
                    Output['Status']['text_cut'] = 1;
                }
                end_selection_index_found = true;
                end_selection_index_new_text = Output['Model'].length;
                if (begin_tag > Selection_Pos[1]) {
                    Output = this.tryConstructTextSegment(Selection_Pos[1], text, Output, begin_tag);
                    Output['Status']['text_cut'] = 0;
                }
            } else {
                Output = this.tryConstructTextSegment(pos, text, Output, begin_tag);
            }
        }
        if (!end_selection_index_found && begin_tag >= Selection_Pos[1] && Selection_Pos[1] >= pos) {
            end_selection_index_new_text = Output['Model'].length;
        }

        return {
            Output: Output,
            start_selection_index_new_text: start_selection_index_new_text,
            end_selection_index_new_text: end_selection_index_new_text,
            end_selection_index_found: end_selection_index_found
        };
    }

    tryConstructTag(Fonts, begin_tag, end_tag, text, Output) {
        var tag = text.substr(begin_tag + 1, end_tag - begin_tag - 1),
            tag_state = this.determineState(tag),
            tag_type = this.identifyType(tag, tag_state),
            Attributes = distillAttributesFromTag(Fonts, tag_type, tag_state, tag);

        if (tag_state === 'open' && !['br', 'hr'].includes(tag_type)) {
            Output['Status']['amount_of_tags_open']++;
        } else if (tag_state === 'close') {
            Output['Status']['amount_of_tags_open']--;
        }
        if (tag_type === 'section' && tag_state === 'open') {
            Output['Settings'] = [];//Clear Settings because a new section is opened, any previous style settings should be discarded.
        }
        if ('section,span,bold,italic,underline,strike,sup,sub,br,ol,ul,li,hr,link,font'.split(',').includes(tag_type)) {
            if ('section,span,bold,italic,underline,strike,sup,sub,font'.split(',').includes(tag_type)) {
                Output = this.tryAddOrRemoveSettingsSubSetData(Output, tag_type, tag_state, Attributes);
            } else if (tag_type === 'br') {
                Output = this.constructBreak(tag_type, Output);
            } else if (tag_type === 'hr') {
                Output = this.constructLine(tag_type, Output);
            } else if ('ol,ul,li'.split(',').includes(tag_type)) {
                Output = this.tryConstructList(tag_type, tag_state, Output, Attributes);
            } else if (tag_type === 'link') {
                Output = this.tryAddOrRemoveLinkData(tag_state, Output, Attributes);
            }
        }

        if (
            (tag_type === 'section' && tag_state === 'close') ||
            (tag_type === 'section' && tag_state === 'open' && Output['Status']['section_active'])
        ) {
            Output['Status']['section_active'] = 0;
        }

        return Output;
    }

    tryConstructTextSegment(start_pos, text, Output, end_pos) {
        var text_segment;
        if (end_pos) {
            text_segment = text.substr(start_pos, end_pos - start_pos);
        } else {
            text_segment = text.substr(start_pos);
        }
        text_segment = this.tryRemoveHTMLTextErrors(text_segment);

        if (text_segment.length > 0 && text_segment !== ' ') {
            Output = this.ensureSettingsActivation(
                this.ensureLinkActivation(
                    this.ensureSectionActivation(
                        this.ensureFontSizeLineHeight(Output)
                    )
                )
            );

            Output['Model'] = this.tryAddTextSegment(Output['Model'], text_segment, Output['Status']['text_cut']);
            Output['Status']['setting_just_updated'] = 0;
            Output['Status']['link_was_closed'] = 0;
        }

        return Output;
    }

    ensureFontSizeLineHeight(Output) {
        var has_font_size = false,
            has_line_height = false;

        if (Output['Status']['setting_active'] || Output['Status']['setting_just_updated']) {
            for (var i = 0; i < Output['Settings'].length; i++) {
                var Setting = Output['Settings'][i];
                if (typeof (Setting['class']) !== 'undefined') {
                    has_font_size = true;
                    has_line_height = true;
                }
                has_font_size = typeof (Setting['font_size']) !== 'undefined' ? true : has_font_size;
                has_line_height = typeof (Setting['line_height']) !== 'undefined' ? true : has_line_height;
                if (has_font_size && has_line_height) {
                    break;
                }
            }
        }

        if (!has_font_size || !has_line_height) {
            var font_size = 100,
                line_height = 135;
            if (Output['Status']['setting_just_updated']) {
                if (!has_font_size) {
                    Output['Settings'][Output['Settings'].length - 1]['font_size'] = font_size;
                }
                if (!has_line_height) {
                    Output['Settings'][Output['Settings'].length - 1]['line_height'] = line_height;
                }
            } else {
                var Data = {};
                if (!has_font_size) {
                    Data['font_size'] = font_size;
                }
                if (!has_line_height) {
                    Data['line_height'] = line_height;
                }
                Output['Settings'].push(Data);
                Output['Status']['setting_just_updated'] = 1;
            }
        }

        return Output;
    }

    tryAddTextSegment(Model, text_segment, text_cut) {//TODO: Check!!!!!!!
        if (!text_cut && Model.length > 0 && typeof (Model[Model.length - 1][0]) !== 'undefined' && Model[Model.length - 1][0] === 't') {//last element in Model was text, thus should be used
            Model[Model.length - 1][1] += text_segment; //add text to last text-segment
        } else {
            Model.push(['t', text_segment]); //add as new text-segment
        }
        return Model;
    }

    tryRemoveHTMLTextErrors(t) {
        /* NOTE: "good" browsers should have fixed these html text errors before they reach javascript
             * Removes newlines
             * Converts "textual brackets" (e.g. not used for valid HTML-tags) to
             * a HTML-encoded character
             * Converts duplicate spaces to single space*/
        var max_iterations = 10000;
        t = t.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/(?:\r\n|\r|\n)/g, '');

        var i = 0;
        do {
            i++;
            t = t.replace('  ', ' ');
        } while (i <= max_iterations && t.indexOf('  ') > -1);

        if (i > max_iterations) {
            alert('Error 00660: Cannot complete process because maximum amount of iteration has been reached. If this error should not occur, contact us: mail@rootflex.com');//Bug in Model or too big Model
        }

        return t;
    }

    tryAddOrRemoveLinkData(tag_state, Output, Attributes) {
        Output = this.tryAddOrRemoveSettingsSubSetData(Output, 'link', tag_state, Attributes);

        if (tag_state === 'open' && (typeof (Attributes['href']) !== 'undefined' || typeof (Attributes['id']) !== 'undefined')) {
            Output['Link'] = {
                index: Output['Link'] ? Output['Link']['index'] + 1 : 1
            };
            if (typeof (Attributes['id']) !== 'undefined') {
                Output['Link']['id'] = Attributes['id'];
            }
            if (typeof (Attributes['href']) !== 'undefined') {
                Output['Link']['href'] = Attributes['href'];
            }
            if (typeof (Attributes['title']) !== 'undefined') {
                Output['Link']['title'] = Attributes['title'];
            }
            if (typeof (Attributes['target']) !== 'undefined') {
                Output['Link']['target'] = Attributes['target'];
            }
        } else if (tag_state === 'close') {
            Output['Link'] = 0;
            Output['Status']['link_was_closed'] = 1;
        }

        return Output;
    }

    ensureSettingsActivation(Output) {
        return !Output['Status']['setting_active'] || Output['Status']['setting_just_updated'] || Output['Status']['text_cut'] ? this.constructSettings(Output) : Output;
    }

    ensureLinkActivation(Output) {
        if (
            Output['Status']['link_active'] &&
            (
                !Output['Link'] ||
                Output['Status']['link_was_closed'] ||
                Output['Status']['link_active'] !== Output['Link']['index']//just in case the html is botched (missing close tag), e.g. link within link. No need to throw error.
            )
        ) {
            Output = this.constructCloseLink(Output);
        }
        if (!Output['Status']['link_active'] && Output['Link']) {
            Output = this.constructLink(Output);
        }

        return Output;
    }

    ensureSectionActivation(Output) {
        return !Output['Status']['section_active'] ? this.constructSection(Output) : Output;
    }

    constructSettings(Output) {
        //should only be executed if an new content-item (text, list(?), br) is
        //about to be added to Model, thus Settings should be applied to that new
        //content-item
        if (Output['Model'].length > 0 && Output['Model'][Output['Model'].length - 1][0] === 'settings') {
            Output['Model'][Output['Model'].length - 1][1] = Output['Model'][Output['Model'].length - 1][1].concat(Output['Settings']);
        } else {
            Output['Model'].push(['settings', Output['Settings']]);
            Output['Status']['setting_active'] = 1;
        }

        return Output;
    }

    constructLink(Output) {
        var Link_Data = {};

        if (typeof (Output['Link']['id']) !== 'undefined') {
            Link_Data['id'] = Output['Link']['id'];
        }
        if (typeof (Output['Link']['href']) !== 'undefined') {
            Link_Data['href'] = Output['Link']['href'];
        }
        if (typeof (Output['Link']['title']) !== 'undefined') {
            Link_Data['title'] = Output['Link']['title'];
        }
        if (typeof (Output['Link']['target']) !== 'undefined') {
            Link_Data['target'] = Output['Link']['target'];
        }
        Output['Model'].push(['link', Link_Data]);
        Output['Status']['link_active'] = Output['Link']['index'];

        return Output;
    }

    constructCloseLink(Output) {
        Output['Model'].push(['link_close']);
        Output['Status']['link_active'] = 0;

        return Output;
    }

    constructSection(Output) {
        if (Output['Status']['link_active']) {
            Output = this.constructCloseLink(Output);
        }
        if (Output['text_align']) {
            Output['Model'].push(['section', false, Output['text_align']]);
        } else {
            Output['Model'].push(['section']);
        }
        Output['Status']['section_active'] = 1;
        Output['Status']['setting_active'] = 0;

        return Output;
    }

    constructBreak(tag_type, Output) {
        Output = this.ensureSettingsActivation(this.ensureLinkActivation(this.ensureSectionActivation(Output)));
        Output['Model'].push([tag_type]);

        return Output;
    }

    constructLine(tag_type, Output) {
        Output = this.tryAddOrRemoveSettingsSubSetData(Output, tag_type, 'close', 0);
        Output['Status']['setting_active'] = 0;

        if (Output['Status']['link_active']) {
            Output = this.constructCloseLink(Output);
        }

        Output['Status']['section_active'] = 0;

        Output['Model'].push([tag_type]);

        return Output;
    }

    tryConstructList(tag_type, tag_state, Output, Attributes) {
        //TODO

        //Output['Model'].push([tag_type]);

        return Output;
    }

    applyCmd(Fonts, start_selection_index_new_text, end_selection_index_new_text, Model, command, Arguments) {
        if (start_selection_index_new_text > Model.length || end_selection_index_new_text > Model.length) {
            alert('Error 00656: Something went wrong when processing the text. Keep getting this error? Contact us: mail@rootflex.com');//Bug in "determining where selection is located in Model"
        }

        if (command === 'Bold' || command === 'Italic' || command === 'Underline' || command === 'strikeThrough') {
            Arguments = this.tryConstructArguments(start_selection_index_new_text, end_selection_index_new_text, Model, command);
        }

        if (command === 'justifyleft' || command === 'justifycenter' || command === 'justifyright' || command === 'justifyfull') {
            Model = this.applyTextAlign(command, Model, end_selection_index_new_text);
        } else if (command === 'createlink') {
            Model = this.applyLink(Model, Arguments, start_selection_index_new_text, end_selection_index_new_text);
        } else if (command === 'unlink') {
            Model = this.applyUnLink(Model, start_selection_index_new_text, end_selection_index_new_text);
        } else {
            Model = this.applyStyle(Fonts, command, Arguments, Model, start_selection_index_new_text, end_selection_index_new_text);
        }

        return Model;
    }

    applyTextAlign(cmd, Model, end_selection_index_new_text) {
        for (var i = 0; i < Model.length; i++) {
            if (i > end_selection_index_new_text) {
                break;
            }
            if (Model[i][0] === 'section') {
                if (cmd === 'justifyleft') {
                    Model[i].splice(2, 1);
                } else if (cmd === 'justifycenter') {
                    Model[i][2] = 'center';
                } else if (cmd === 'justifyright') {
                    Model[i][2] = 'right';
                } else if (cmd === 'justifyfull') {
                    Model[i][2] = 'justify';
                }
            }
        }

        return Model;
    }

    applyLink(Model, Arguments, start_selection_index_new_text, end_selection_index_new_text) {
        Model = this.applyUnLink(Model, start_selection_index_new_text, end_selection_index_new_text);
        //TODO: apply link over br
        var settings_on_t;

        for (var i = start_selection_index_new_text; i < end_selection_index_new_text; i++) {
            if (Model[i][0] === 't'/* || Model[i][0] === 'br'*/) {
                settings_on_t = false;
                if (Model[i - 1] && Model[i - 1][0] && Model[i - 1][0] === 'settings') {
                    i--;
                    settings_on_t = true;
                }
                Model.splice(i, 0, ['link', Arguments]);
                end_selection_index_new_text++;
                i = settings_on_t ? i + 3 : i + 2;
                Model.splice(i, 0, ['link_close']);
                i++;
                end_selection_index_new_text++;
            }
        }

        return Model;
    }

    applyUnLink(Model, start_selection_index_new_text, end_selection_index_new_text) {
        //TODO: apply unlink over br
        var link_open = false,
            Link;

        for (var i = 0; i < end_selection_index_new_text; i++) {
            if (i === start_selection_index_new_text && link_open) {//if the selection start and a link is still open a new "link_close" should be added right before the selection
                Model.splice(i, 0, ['link_close']);
                end_selection_index_new_text++;
            } else if (Model[i][0] === 'link') {
                Link = clone(Model[i]);
                if (i >= start_selection_index_new_text) {//link should only be removed if it is inside the selection
                    Model.splice(i, 1);
                    end_selection_index_new_text--;
                }
                link_open = true;
            } else if (Model[i][0] === 'link_close') {
                if (link_open) {
                    if (i >= start_selection_index_new_text) {//link_close should only be removed if it is inside the selection
                        Model.splice(i, 1);
                        end_selection_index_new_text--;
                        link_open = false;
                    }
                } else {
                    alert('Error 00657: Something went wrong when processing a link in the text. Keep getting this error? Contact us: mail@rootflex.com');//Bug in Model: link_close without link_open. Model always has whole paragraph, thus must have link starting tag.
                }
            }
        }

        if (Model[end_selection_index_new_text] && Model[end_selection_index_new_text][0] && Model[end_selection_index_new_text][0] === 'link_close') {
            /*If selection ends at exact end of link, the selection end is placed on
             * just before the link_close (where it should be)
             * But this link_close should be removed*/
            Model.splice(i, 1);
            link_open = false;
        }
        if (link_open) {//link started before selection, should continue after selection
            Model.splice(end_selection_index_new_text, 0, Link);
        }

        return Model;
    }

    applyStyle(Fonts, command, value, Model, start_selection_index_new_text, end_selection_index_new_text) {
        var Sub_Set = this.constructCommandSettingsSubSet(Fonts, command, value),
            temp;

        for (var i = start_selection_index_new_text; i < end_selection_index_new_text; i++) {
            if (Model[i][0] === 't' || Model[i][0] === 'br') {
                if (Model[i - 1][0] !== 'settings') {
                    Model.splice(i, 0, ['settings', [Sub_Set]]);
                    end_selection_index_new_text++;
                    i++;
                } else if (Model[i - 1][0] === 'settings') {
                    temp = clone(Model[i - 1]);
                    if (command === 'formatBlock') {
                        temp[1] = [Sub_Set];//NOTE: all existing settings on text segment are overwritten by "text style"
                    } else {
                        temp[1].push(Sub_Set);
                    }
                    Model[i - 1] = temp;
                }
            }
        }

        return Model;
    }

    constructCommandSettingsSubSet(Fonts, command, value) {
        /* Text Styles can be overwritten with other styles, but are not decoupled.
         * Only when explicitly changing a selected text to another preset, or 
         * removing the preset:
         * 
         * When setting a selected text to use a preset, all custom
         * styling is removed from that selected text.
         * 
         * When reapplying any custom styling to a selected text that uses preset(-s),
         * the preset(-s) are not decoupled (class(-es) not removed), only the 
         * used custom properties are overwritten. (this way, change a preset, can still
         * affect text that use the preset only partly (with certain properties
         * overwritten with custom properties)
         */

        var Attributes = {},
            styles, class_name, Color;

        value = typeof value === 'string' ? value.toLowerCase() : value;

        if (command === 'formatBlock') {
            if (value === 'none') {
                styles = {
                    font_size: 100,
                    line_height: 135
                };
            } else {
                class_name = value;
            }
        } else if (command === 'fontname') {
            value = distillFontFamilyId(Fonts, value);
            if (value !== 'unknown') {
                styles = {
                    font_family_id: value
                };
            }
        } else if (command === 'fontsize') {
            styles = {
                font_size: parseInt(value, 10)
            };
        } else if (command === 'lineheight') {
            styles = {
                line_height: parseInt(value, 10)
            };
        } else if (command === 'letterspacing') {
            styles = {
                letter_spacing: parseInt(value, 10)
            };
        } else if (command === 'foreColor') {
            Color = distillColorFromCSSString(value);//TODO: although this does support alpha-channel (rgba, hsla), the interface doesn't offer options to set it
            if (Color[0] !== '') {
                styles = {
                    Color: {
                        value: Color[0],
                        opacity: Color[1]
                    }
                };
            }
        } else if (command === 'hiliteColor') {
            Color = distillColorFromCSSString(value);//TODO: although this does support alpha-channel (rgba, hsla), the interface doesn't offer options to set it
            if (Color[0] !== '') {
                styles = {
                    Background_Color: {
                        value: Color[0],
                        opacity: Color[1]
                    }
                };
            }
        } else if (command === 'Bold') {
            styles = {
                font_weight: value
            };
        } else if (command === 'Italic') {
            styles = {
                font_style: value
            };
        } else if (command === 'Underline' || command === 'strikeThrough') {
            styles = {
                text_decoration: value
            };
        }

        if (class_name) {
            Attributes = {
                class: class_name
            };
        }
        if (styles) {
            Attributes = {
                style: styles
            };
        }

        return this.tryAddSettingsSubSetData([], 'span', Attributes)['Settings'][0];
    }

    tryConstructArguments(start_selection_index_new_text, end_selection_index_new_text, Model, command) {
        var property, value;

        if (command === 'Bold') {
            property = 'font_weight';
            value = 'bold';
        } else if (command === 'Italic') {
            property = 'font_style';
            value = 'italic';
        } else if (command === 'Underline') {
            property = 'text_decoration';
            value = 'underline';
        } else if (command === 'strikeThrough') {
            property = 'text_decoration';
            value = 'line-through';
        }

        if (this.selectionHasStyle(start_selection_index_new_text, end_selection_index_new_text, Model, property, value)) {
            if (command === 'Bold' || command === 'Italic') {
                value = 'normal';
            } else if (command === 'Underline' || command === 'strikeThrough') {
                value = 'none';
            }
        }

        return value;
    }

    selectionHasStyle(start_selection_index_new_text, end_selection_index_new_text, Model, property, value) {
        var has_style = false;

        for (var i = start_selection_index_new_text; i < end_selection_index_new_text; i++) {
            if (Model[i][0] === 'settings') {
                for (var j = 0; j < Model[i][1].length; j++) {
                    if (Model[i][1][j].hasOwnProperty(property)) {
                        if (Model[i][1][j][property] === value) {
                            has_style = true;
                        } else {
                            has_style = false;
                        }
                    }
                }
                if (has_style) {
                    break;
                }
            }
        }

        return has_style;
    }

    buildHTMLTextString(Fonts, Model, section_type) {
        section_type = section_type || 'p';
        var s = '',
            section_open = false,
            link_open = false,
            style_span_open = false,
            skip_first_setting_add = false,
            Text_Style_With_Shadows, text_align, Link_Settings, has_settings;

        for (var i = 0; i < Model.length; i++) {
            if (typeof (Model[i][0]) !== 'undefined') {
                if (Model[i][0] === 'section') {
                    skip_first_setting_add = false;
                    if (style_span_open) {
                        s += '</span>';
                        style_span_open = false;
                    }
                    if (section_open) {
                        s += '</' + section_type + '>';
                        section_open = false;
                    }
                    s += '<' + section_type;
                    section_open = true;
                    Text_Style_With_Shadows = false;
                    if (typeof (Model[i][1]) !== 'undefined' && Model[i][1] === 'has_settings' && typeof (Model[i + 1]) !== 'undefined' && typeof (Model[i + 1][0]) !== 'undefined' && Model[i + 1][0] === 'settings') {
                        skip_first_setting_add = true;
                        Text_Style_With_Shadows = Model[i + 1][1];
                    }
                    text_align = typeof (Model[i][2]) !== 'undefined' ? Model[i][2] : false;
                    if (Text_Style_With_Shadows || text_align) {
                        s += this.buildHTMLStringClassStyleAttributes(Fonts, Text_Style_With_Shadows, text_align);
                    }
                    s += '>';
                } else if (Model[i][0] === 'hr') {
                    if (section_open) {
                        s += '</' + section_type + '>';
                        section_open = false;
                    }
                    s += '<hr>';
                } else if (section_open) {
                    if (Model[i][0] === 'settings') {
                        if (skip_first_setting_add) {
                            skip_first_setting_add = false;
                        } else {
                            if (style_span_open) {
                                s += '</span>';
                                style_span_open = false;
                            }
                            style_span_open = true;
                            s += '<span';
                            s += this.buildHTMLStringClassStyleAttributes(Fonts, Model[i][1]);
                            s += '>';
                        }
                    } else if (Model[i][0] === 't') {
                        s += Model[i][1];
                        if (style_span_open && (typeof (Model[i + 1]) === 'undefined' || (typeof (Model[i + 1][0]) !== 'undefined' && Model[i + 1][0] !== 'link' && Model[i + 1][0] !== 'link_close' && Model[i + 1][0] !== 'br' && Model[i + 1][0] !== 'hr'))) {
                            s += '</span>';
                            style_span_open = false;
                        }
                    } else if (Model[i][0] === 'br') {
                        s += '<br>';
                    } else if (Model[i][0] === 'link' && typeof (Model[i][1]) !== 'undefined' && !link_open) {
                        if (style_span_open) {
                            s += '</span>';
                            style_span_open = false;
                        }
                        s += '<a';
                        Link_Settings = Model[i][1];
                        if (typeof (Link_Settings['id']) !== 'undefined') {
                            s += ' id="' + Link_Settings['id'] + '"';
                        }
                        if (typeof (Link_Settings['href']) !== 'undefined') {
                            s += ' href="' + Link_Settings['href'] + '"';
                            if (typeof (Link_Settings['target']) !== 'undefined') {
                                s += ' target="' + Link_Settings['target'] + '"';
                            }
                            if (typeof (Link_Settings['title']) !== 'undefined') {
                                s += ' title="' + Link_Settings['title'] + '"';
                            }
                        }
                        has_settings = typeof (Model[i][2]) !== 'undefined' && Model[i][2] === 'has_settings' && typeof (Model[i + 1]) !== 'undefined' && typeof (Model[i + 1][0]) !== 'undefined' && Model[i + 1][0] === 'settings' ? true : false;
                        if (has_settings) {
                            skip_first_setting_add = true;
                            s += this.buildHTMLStringClassStyleAttributes(Fonts, Model[i + 1][1], 0);
                        }
                        s += '>';
                        link_open = true;
                    } else if (Model[i][0] === 'link_close' && link_open) {
                        s += '</a>';
                        link_open = false;
                    }
                }
            }
        }

        if (style_span_open) {
            s += '</span>';
        }
        if (section_open) {
            s += '</' + section_type + '>';
        }

        return s;
    }

    buildHTMLStringClassStyleAttributes(Fonts, Text_Style_With_Shadows, text_align) {
        text_align = text_align || false;
        var s = '';

        if (text_align) {
            s += 'text-align:' + text_align + ';';
        }
        if (typeof (Text_Style_With_Shadows['font_family_id']) !== 'undefined') {
            Text_Style_With_Shadows['Font'] = Fonts[Text_Style_With_Shadows['font_family_id']];
        }
        s += buildCSSTextStyle(Text_Style_With_Shadows);

        return (typeof (Text_Style_With_Shadows['class']) !== 'undefined' ? ' class="' + Text_Style_With_Shadows['class'] + '"' : '') +
            (s ? ' style="' + s + '"' : '');
    }

    optimize(Text_Styles, Model) {
        return this.determineSettingsScopes(
            this.tryMergeTexts(
                this.removeEmptySections(
                    this.removeSettingsWithoutProperties(
                        this.removeEmptySettingsSets(
                            this.tryTransformStylesIntoClasses(
                                Text_Styles,
                                this.removeObsoleteCustomPropertyValues(
                                    Text_Styles,
                                    this.removeUselessPropertyValues(this.compressSettingsSubSetsIntoSettings(
                                        this.removeUselessBreaks(Model)
                                    ))
                                )
                            )
                        )
                    )
                )
            )
        );
    }

    compressSettingsSubSetsIntoSettings(Model) {
        var Settings;
        for (var i = 0; i < Model.length; i++) {
            if (Model[i][0] === 'settings' && typeof (Model[i][1][0]) !== 'undefined') {
                Settings = {};
                for (var j = 0; j < Model[i][1].length; j++) {
                    for (var key in Model[i][1][j]) {
                        Settings[key] = clone(Model[i][1][j][key]);
                    }
                }
                Model[i][1] = clone(Settings);
            }
        }
        return Model;
    }

    removeUselessBreaks(Model) {
        for (var i = 0; i < Model.length; i++) {
            if (Model[i][0] && Model[i][0] === 'br') {//is br
                if (Model[i - 1] && Model[i - 1][0] && Model[i - 1][0] !== 'br' && (Model[i - 1][0] !== 'settings' || Model[i - 2] && Model[i - 2][0] && Model[i - 2][0] !== 'br')) {//is not directly preceded by br and not directly preceded by settings which directly preceded by br)
                    if (Model[i - 1][0] !== 'section' && //is not directly preceded by a section, and...
                        (Model[i - 1][0] !== 'link' || (Model[i - 2] && Model[i - 2][0] && Model[i - 2][0] !== 'section')) && //is not directly preceded by a link that is directly preceded by a section, and...
                        (Model[i - 1][0] !== 'settings' || (Model[i - 2] && Model[i - 2][0] && (Model[i - 2][0] !== 'section' && (Model[i - 2][0] !== 'link' || (Model[i - 3] && Model[i - 3][0] && Model[i - 3][0] !== 'section')))))//is not directly preceded by settings that are directly preceded by a section (or a link which is not directly preceded by a section)
                    ) {
                        if (!Model[i + 1] || (//is at end of model, or...
                            Model[i + 1] && Model[i + 1][0] && (
                                Model[i + 1][0] === 'section' || (//is followed by section, or...
                                    Model[i + 1][0] === 'link' && (//is followed by link, and...
                                        !Model[i + 2] || (//link is at end of model, or...
                                            Model[i + 2] && Model[i + 2][0] && Model[i + 2][0] === 'section'//link is followed by section
                                        )
                                    )
                                )
                            )
                        )
                        ) {
                            Model.splice(i, 1);
                            //TODO: check if Model.length is correctly changed (the for loop is now 1 i shorter you know)
                        }
                    }
                }
            }
        }

        return Model;
    }

    removeUselessPropertyValues(Model) {
        var match,
            Useless_Property_Values = [
                ['font_weight', 'string', 'normal'],
                ['font_style', 'string', 'normal'],
                ['text_decoration', 'string', 'none'],
                ['Color', 'array', {
                    value: '000000',
                    opacity: 100
                }]
            ];

        for (var i = 0; i < Model.length; i++) {
            if (typeof (Model[i][0]) !== 'undefined' && Model[i][0] === 'settings') {
                for (var j = 0; j < Useless_Property_Values.length; j++) {
                    if (Model[i][1].hasOwnProperty(Useless_Property_Values[j][0])) {
                        if (Array.isArray(Useless_Property_Values[j][1])) {
                            match = true;
                            for (var prop in Useless_Property_Values[j][2]) {
                                if (Model[i][1][Useless_Property_Values[j][0]][prop] !== Useless_Property_Values[j][2][prop]) {
                                    match = false;
                                }
                            }
                        } else if (typeof (Useless_Property_Values[j][1]) === 'string') {
                            if (Model[i][1][Useless_Property_Values[j][0]] === Useless_Property_Values[j][2]) {
                                match = true;
                            }
                        }
                        if (match) {
                            delete Model[i][1][Useless_Property_Values[j][0]];
                            match = false;
                        }
                    }
                }
            }
        }
        return Model;
    }

    tryTransformStylesIntoClasses(Text_Styles, Model) {
        //TODO: if no preset (e.g. class, e.g. text_style) is used, try match certain preset with used custom settings, if match do transform

        return Model;
    }

    removeObsoleteCustomPropertyValues(Text_Styles, Model) {
        //TODO: if a preset is used and a custom property is used with the exact same value, remove the custom property
        /*var match;
         
         for (var i=0; i<Model.length; i++) {
         if (typeof(Model[i][0]) !== 'undefined' && Model[i][0] === 'settings') {
         for (var j=0; j<Obsolete_Property_Values.length; j++) {
         
         }
         }
         }*/
        return Model;
    }

    removeEmptySettingsSets(Model) {//TODO: check this!!!!
        var New_Model = [],
            max_iterations = 10000,
            i = 0,
            skip,
            skips;

        do {
            Model = New_Model.length > 0 ? New_Model.slice() : Model.slice();
            New_Model = [];
            i++;
            skips = 0;
            for (var j = 0; j < Model.length; j++) {
                skip = false;
                if (typeof (Model[j][0]) !== 'undefined' && (Model[j][0] === 'section' || Model[j][0] === 'settings') && (typeof (Model[j + 1]) === 'undefined' || (Model[j + 1] && typeof (Model[j + 1][0]) !== 'undefined' && Model[j + 1][0] && Model[j + 1][0] === 'section'))) {
                    skip = true;
                    skips += 1;
                } else if (typeof (Model[j][0]) !== 'undefined' && (Model[j][0] === 'settings' || Model[j][0] === 't') && (typeof (Model[j][1]) === 'undefined' || (Model[j][1] && Model[j][1].length === 0))) {
                    skip = true;
                    skips += 1;
                }
                if (Model[j][0] === 'settings' && Model[j][1].length === 0) {
                    skip = true;
                    skips += 1;
                }
                if (!skip) {
                    New_Model.push(Model[j]);
                }
            }
        } while (skips > 0 && i <= max_iterations);
        if (i > max_iterations) {
            alert('Error 00664: Cannot complete process because maximum amount of iteration has been reached. If this error should not occur, contact us: mail@rootflex.com');//Bug in Model or too big Model
        }

        return New_Model;
    }

    removeSettingsWithoutProperties(Model) {//TODO: check if these cases can be prevented in the first place
        for (var i = 0; i < Model.length; i++) {
            if (Model[i][0] === 'settings' && Model[i][1] && Object.getOwnPropertyNames(Model[i][1]).length === 0) {
                Model.splice(i, 1);
            }
        }

        return Model;
    }

    removeEmptySections(Model) {//TODO: check if these cases can be prevented in the first place
        var New_Model = [],
            skip;

        for (var j = 0; j < Model.length; j++) {
            skip = false;
            if (Model[j][0] === 'section') {
                if (!Model[j + 1]) {
                    skip = true;
                } else if (Model[j + 1][0] === 'section') {
                    skip = true;
                } else if (Model[j + 1][0] === 'hr') {
                    for (var k = j + 1; k < Model.length; k++) {
                        if (Model[k][0] === 'section') {
                            skip = true;
                        }
                        if (Model[k][0] !== 'hr') {
                            break;
                        }
                    }
                }
            }
            if (!skip) {
                New_Model.push(Model[j]);
            }
        }

        return New_Model;
    }

    tryMergeTexts(Model) {
        /*
         * If settings on text are equal (or no settings at all), merge texts and their settings
         * If links are equal, merge texts and their links */
        for (var i = 0; i < Model.length; i++) {
            if (
                Model[i][0] === 'settings' &&
                Model.length > i + 2 &&
                Model[i + 1][0] === 't' &&
                Model[i + 2][0] === 'settings' &&
                Model[i + 3][0] === 't' &&
                JSON.stringify(Model[i][1]) === JSON.stringify(Model[i + 2][1])
            ) {
                Model[i + 1][1] = Model[i + 1][1] + Model[i + 3][1];
                Model.splice(i + 2, 2);
                Model = this.tryMergeTexts(Model);
                break;
            }
        }

        return Model;
    }

    determineSettingsScopes(Model) {//TODO: Maybe merge functions below into 1 procedure
        Model = this.tryForceSettingsOntoSectionTag(Model);
        Model = this.tryForceSettingsOntoLinkTag(Model);
        //Model = tryForceSettingsOntoBrTag(Model);//TODO: ???
        //Model = tryForceSettingsOntoTextTag(Model);//TODO: ???

        return Model;
    }

    tryForceSettingsOntoSectionTag(Model) {
        // If a style-setting is directly used after the beginning of a section,
        // and no other style-setting is found inside that section (including no unstyled "t")
        // a parameter (single_set) on the section is turn on,
        // so the HTML builder knows the styling should be used directly on the tag (<p>, <h1>, etc) instead of on a "span"
        // 
        // when background-color is used, it should always be place on a <span>, thus add condition that will fail this forcing

        //TODO check for <br>... maybe switch to mechanism where whole section is scanned for amount of "settings" (only 1), "t" (0 or 1) & "br" (?) occurances
        var single_set;
        for (var i = 0; i < Model.length; i++) {
            if (Model[i][0] === 'section' && ((Model[i + 1] && Model[i + 1][0] === 'settings') || (Model[i + 1] && Model[i + 1][0] === 'link' && Model[i + 2] && Model[i + 2][0] === 'settings'))) {
                single_set = 1;
                for (var j = i + 2; j < Model.length; j++) {
                    if (Model[j][0] === 'section') {
                        break;
                    } else if (Model[j][0] === 'settings' || (Model[j][0] === 't' && Model[j - 1] && Model[j - 1][0] && Model[j - 1][0] !== 'settings')) {
                        single_set = 0;
                        break;
                    }
                }
                if (single_set) {
                    Model[i][1] = 'has_settings';
                }
            }
        }

        return Model;
    }

    tryForceSettingsOntoLinkTag(Model) {
        // If a style-setting is directly used after the beginning of a link,
        // and no other style-setting is found inside that link (including no unstyled "t")
        // a parameter (single_set) on the section is turn on,
        // so the HTML builder knows the styling should be used directly on the tag (<a>) instead of on a "span"
        var single_set;
        for (var i = 0; i < Model.length; i++) {
            if (Model[i][0] === 'link' && Model[i + 1] && Model[i + 1][0] === 'settings') {
                single_set = 1;
                for (var j = i + 2; j < Model.length; j++) {
                    if (Model[j][0] === 'link_close') {
                        break;
                    } else if (Model[j][0] === 'settings' || (Model[j][0] === 't' && Model[j - 1] && Model[j - 1][0] && Model[j - 1][0] !== 'settings')) {
                        single_set = 0;
                        break;
                    }
                }
                if (single_set) {
                    Model[i][2] = 'has_settings';
                }
            }
        }

        return Model;
    }

    determineState(tag) {
        return tag.substr(0, 1) === '/' ? 'close' : 'open';
    }

    identifyType(t, tag_state) {//t is tag
        if (tag_state === 'close') {
            t = t.substr(1, t.length - 1);
        }
        var type = 'unidentified';

        if (t.length > 0) {
            if ((t[0] === 'p' && (t.length === 1 || t.length > 1 && t[1] === ' ')) ||
                (t[0] === 'h' && (t.length > 1 && '1,2,3,4,5,6'.split(',').includes(t[1])) && (t.length === 2 || (t.length > 2 && t[2] === ' '))) ||
                (t.substr(0, 3) === 'div' && (t.length === 3 || t.length > 3 && t[3] === ' '))
            ) {
                type = 'section';
            } else if (t.length === 1 && 'b,u,i,s'.split(',').includes(t[0])) {
                if (t === 'b') {
                    type = 'bold';
                } else if (t === 'u') {
                    type = 'underline';
                } else if (t === 'i') {
                    type = 'italic';
                } else if (t === 's') {
                    type = 'strike';
                }
            } else if (t[0] === 'a' && (t.length === 1 || (t.length > 2 && t[1] === ' '))) {
                type = 'link';

            } else if (t.length > 1 && t.substr(0, 2) === 'br' && (t.length === 2 || (t.length === 3 && t[2] === '/') || (t.length > 2 && t[2] === ' '))) {
                type = 'br';
            } else if ('hr,ol,ul,li'.split(',').includes(t.substr(0, 2)) && (t.length === 2 || (t.length > 2 && t[2] === ' '))) {
                type = t.substr(0, 2);
            } else if ('sup,sub,del'.split(',').includes(t.substr(0, 3)) && t.length === 3) {
                if (t === 'sup') {
                    type = 'sup';
                } else if (t === 'sub') {
                    type = 'sub';
                } else if (t === 'del') {
                    type = 'strike';
                }
            } else if (t.substr(0, 4) === 'span' && (t.length === 4 || (t.length > 4 && t[4] === ' '))) {
                type = 'span';
            } else if (t.substr(0, 4) === 'font' && (t.length === 4 || (t.length > 4 && t[4] === ' '))) {
                type = 'font';
            } else if ('strike,strong'.split(',').includes(t.substr(0, 3)) && t.length === 6) {
                if (t === 'strike') {
                    type = 'strike';
                } else if (t === 'strong') {
                    type = 'bold';
                }
            } else if (t.length === 10 && t.substr(0, 10) === 'blockquote') {
                type = 'blockquote';
            }
        }
        return type;
    }

    tryAddOrRemoveSettingsSubSetData(Output, tag_type, tag_state, Attributes) {
        var Result;

        if (tag_state === 'open') {
            Result = this.tryAddSettingsSubSetData(Output['Settings'].slice(), tag_type, Attributes, Output['text_align']);
            Output['text_align'] = Result['text_align'];
            Output['Settings'] = Result['Settings'];
        } else if (tag_state === 'close') {
            Output['Settings'] = this.tryRemoveSettingsSubSetData(Output['Settings'].slice(), tag_type);
        }

        Output['Status']['setting_just_updated'] = 1;

        return Output;
    }

    tryAddSettingsSubSetData(Settings, tag_type, Attributes, text_align) {
        var Result;

        if (typeof (Settings) === 'undefined' || !Array.isArray(Settings)) {
            alert('Error 00654: Something went wrong when processing the text. Keep getting this error? Contact us: mail@rootflex.com');//Bug in Construction: Settings should always be an array (but it can be an empty array).
        }
        if (typeof (Attributes['class']) !== 'undefined' || typeof (Attributes['style']) !== 'undefined') {
            Result = this.tryAddSettingsSubSetDataByClassAndOrStyle(Settings, tag_type, Attributes, text_align);
            text_align = Result['text_align'];
            Settings = Result['Settings'];
        } else {
            Settings = this.tryAddSettingsSubSetDataBySpecialTag(Settings, tag_type);
        }

        return {
            text_align: text_align,
            Settings: Settings
        };
    }

    tryAddSettingsSubSetDataByClassAndOrStyle(Settings, tag_type, Attributes, text_align) {
        var class_name = typeof (Attributes['class']) !== 'undefined' ? Attributes['class'] : 0,
            Styles = typeof (Attributes['style']) !== 'undefined' ? Attributes['style'] : 0;

        if (tag_type === 'section') {
            Settings.push(this.constructSettingsSubSetData(class_name, Styles));
            text_align = typeof (Styles['text_align']) !== 'undefined' ? Styles['text_align'] : text_align;
        } else if ('span,link,font'.split(',').includes(tag_type)) {
            Settings.push(this.constructSettingsSubSetData(class_name, Styles));
        }

        return {
            text_align: text_align,
            Settings: Settings
        };
    }

    tryAddSettingsSubSetDataBySpecialTag(Settings, tag_type) {
        var SubSet = {};

        if (tag_type === 'bold') {
            SubSet['font_weight'] = 'bold';
        } else if (tag_type === 'italic') {
            SubSet['font_style'] = 'italic';
        } else if (tag_type === 'underline') {
            SubSet['text_decoration'] = 'underline';
        } else if (tag_type === 'strike') {
            SubSet['text_decoration'] = 'line-through';
        } else if ('sup,sub'.split(',').includes(tag_type)) {
            SubSet['level'] = tag_type;
        } else if (tag_type === 'blockquote') {
            SubSet['blockquote'] = 1;
        }

        Settings.push(SubSet);

        return Settings;
    }

    constructSettingsSubSetData(class_name, Styles) {
        var Sub_Set = {}, parameters, p;

        if (class_name) {
            Sub_Set['class'] = class_name;
        }
        if (Styles) {
            parameters = [
                'font_family_id',
                'font_size',
                'font_style',
                'font_weight',
                'line_height',
                'letter_spacing',
                'Background_Color',
                'Color',
                'text_decoration',
                'Shadows'
            ];

            for (var i = 0; i < parameters.length; i++) {
                p = parameters[i];
                if (typeof (Styles[p]) !== 'undefined') {
                    Sub_Set[p] = Styles[p];
                }
            }
        }

        return Sub_Set;
    }

    tryRemoveSettingsSubSetData(Settings, tag_type) {
        if (typeof (Settings) === 'undefined' || !Array.isArray(Settings)) {//Not really neccesary, just to ensure
            alert('Error 00655: Something went wrong when processing the text. Keep getting this error? Contact us: mail@rootflex.com');//Bug in Construction: Settings should always be an array (but it can be an empty array).
        }
        if (tag_type === 'section' || tag_type === 'hr') {
            Settings = []; //because a section or line (hr) is closed, all Settings should be reset
        } else {
            /* The Settings that were added the latest don't apply any longer,
             * because they belong to the text-feature-group that is now closed,
             * thus the related Settings should be removed as well*/
            Settings.pop();
        }

        return Settings;
    }

    getContent(Function_Data, Action_Data, frm_id, frm_id_int, Current_Data, updateActionData, changeCurrentData, navigate) {
        this.Active_Style_State = {//TODO use this
            text_style_id: false,
            font_family_id: 1,
            font_size: 100,
            line_height: 135,
            letter_spacing: 0,
            forecolor: '000000',
            backcolor: 'ffffff',
            bold: false,
            italic: false,
            underline: false,
            strikethrough: false,
            textalign: 'left',
            link: false
        };

        const $text_el = $('#' + (Action_Data.data_action_type === 'create' ? '' : 'text_' + Action_Data.el_idx)).clone();
        var { default_language_type, language_type, device_type, base_url, site_url, Page_URLs } = Function_Data;

        /*$('#' + (Action_Data.data_action_type === 'create' ? '' : 'text_' + Action_Data.el_idx))
            .parent().append(
                $('<div>', {
                    class: 'element_overlay_window'
                }).append(
                    $('<div>', {
                        class: 'element_overlay_window_1'
                    })
                )
            );

        var $iframe_contents = $('<iframe>', {
            id: 'text_editor_iframe',
            css: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 3,
                'pointer-events': 'all'
            }
        })
            .appendTo($('.element_overlay_window_1')).contents();

        $iframe_contents
            .find('html')
            .css({
                height: '100%',
                width: '100%',
                position: 'fixed'
            });*/

        /*$iframe_contents
            .find('body')
            .append(
                $('<div>', {
                    id: 'text_editor_editable_holder',
                    css: {
                        position: 'relative'
                    }
                })
                    .append(
                        $text_el
                            .attr({
                                contentEditable: 'true'
                            })
                            .html($text_el.html().replace(/\r?\n|\r/g, ''))
                            .keyup(() => {
                                var length = Current_Data.max_length - (
                                    this.outerHTML ? this.outerHTML.replace(/(<([^>]+)>)/ig, '').length : 0);

                                $('#' + buildFormElementID('form_' + frm_id_int, ['Text_Source'], 'text') + '_counter')
                                    .removeClass('red_txt')
                                    .addClass(length < 0 ? 'red_txt' : '')
                                    .html(
                                        (length < 0 ? -length : length) + [
                                            (Current_Data.language === 'dutch' ? ' tekens resterend' : ' characters left'),
                                            (Current_Data.language === 'dutch' ? ' tekens teveel' : ' characters too much')
                                        ][(length < 0 ? 1 : 0)]
                                    );
                            })
                            .keydown(
                                $.proxy((e) => {
                                    if (e.ctrlKey && (e.which === 66 || e.which === 73 || e.which === 85)) {
                                        e.preventDefault();
                                        this.processTextEditorCommand(Current_Data,
                                            $text_el,
                                            e.which === 66 ? 'Bold' : (e.which === 73 ? 'Italic' : 'Underline'),
                                            null
                                        );
                                    }
                                }, this)
                            )
                            .trigger('keyup')
                            .focus()
                    )
            );*/

        /*$(document).click(() => {
            if ($('#text_editor_iframe').length > 0) {
                if (!['input', 'textarea', 'select'].includes(document.activeElement.tagName.toLowerCase())) {
                    $('#text_editor_iframe')[0].contentWindow.focus();
                }
            }
        });*/

        return [
            getDoubleSubmitSet(frm_id, () => {
                if (Action_Data.data_action_type === 'create') {
                    Current_Data.Text = {
                        text: '',
                        language_id: Current_Data.language_id,
                        new_language_variant: false
                    };
                    $('#' + (buildFormElementID('form_' + frm_id_int, ['Text_Source'], 'text'))).val(
                        $('#' + (buildFormElementID('form_' + frm_id_int, ['Text_Source'], 'text')) + '_div').html()
                    );
                    // TODO store form values to be used in later post-submit to back-end
                    updateActionData({
                        access: 'authorized',
                        frm_name: 'layout_settings'
                    });
                } else {
                    //TODO: before submitting, update the value that is in memory of
                    // the text-editor (e.g. Text Source) ... is this really neccesary in the new architecture?
                    form_data_store.dispatch({
                        type: 'SUBMIT',
                        submitData: {
                            frm_id,
                            submit_action: 'save_text_source',
                            Function_Data,
                            process_tag: 'Saving...',
                            onSubmitted: () => {
                                //TODO: update text-element itself by using the Result_Data.
                            }
                        }
                    });
                }
            }, Action_Data.data_action_type === 'create' ? 'Next' : 'Save'),
            wrapSectionSubs('Text_Source', [
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Language', style: 'bold' }
                ],
                [
                    { type: 'dropdown', id: 'language_id' },
                    {
                        value: Current_Data.language_id,
                        Options: Current_Data.Languages.map(Language => ([
                            Language.id, Language.name
                        ]))
                    }
                ],
                [
                    { type: Current_Data.new_language_variant ? 'text' : 'hidden', colspan: 2 },
                    { text: 'No text variant has yet been made for the<br>currently selected language. Until a text<br>variant is made, the default language variant<br>will automatically be used when loading the<br>page.', style: 'normal_grey' }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Font Family', style: 'bold' }
                ],
                [
                    { type: 'rich_dropdown', id: 'font_family', colspan: 2 },
                    {
                        Options: Current_Data.Font_Families.map(Font_Family => ([
                            Font_Family.main,
                            '<span style="font-family:' + Font_Family.main + ';">' + ucWords(Font_Family.main) + '</span>',
                            () => {
                                this.processTextEditorCommand(Current_Data, $text_el, Font_Family.main, 'fontname');
                            }
                        ]))
                    }
                ],
                [
                    { type: 'grid', id: '' },
                    {
                        Content: [
                            [
                                [
                                    { type: 'text', colspan: 2 },
                                    { text: 'Font Size', style: 'bold' }
                                ],
                                [
                                    { type: 'text', colspan: 2 },
                                    { text: 'Line Height', style: 'bold' }
                                ]
                            ], [
                                [
                                    { type: 'number', id: 'font_size', colspan: 2 },
                                    {
                                        value: 100, min_val: 1, max_val: 9999, unit: '%', onChange: value => {
                                            this.processTextEditorCommand(Current_Data, $text_el, 'fontsize', value);
                                        }
                                    }
                                ],
                                [
                                    { type: 'number', id: 'line_height', colspan: 2 },
                                    {
                                        value: 135, min_val: 100, max_val: 9999, unit: '%', onChange: value => {
                                            this.processTextEditorCommand(Current_Data, $text_el, 'lineheight', value);
                                        }
                                    }
                                ]
                            ]
                        ]
                    }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Letter Spacing', style: 'bold' }
                ],
                [
                    { type: 'number', id: 'letter_spacing', colspan: 2 },
                    {
                        value: 0, min_val: 0, max_val: 999, unit: '%', onChange: value => {
                            this.processTextEditorCommand(Current_Data, $text_el, 'letterspacing', value);
                        }
                    }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Style', style: 'bold' }
                ],
                [
                    { type: 'row', id: '' },
                    {
                        Content: [
                            [
                                {
                                    type: 'icon_link',
                                    id: 'bold',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_bold',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'Bold', null);
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'italic',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_italic',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'Italic', null);
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'underline',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_underline',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'Underline', null);
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'strikethrough',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_strikethrough',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'strikeThrough', null);
                                    }
                                }
                            ],
                            [
                                { type: 'color', id: 'forecolor' },
                                {
                                    value: 'ffffff', icon: 'color_text',
                                    onChange: value => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'foreColor', '#' + value);
                                    }
                                }
                            ],
                            [
                                { type: 'color', id: 'bgcolor' },
                                {
                                    value: 'ffffff', icon: 'mark_text',
                                    onChange: value => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'hiliteColor', '#' + value);
                                    }
                                }
                            ]
                        ]
                    }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Alignment', style: 'bold' }
                ],
                [
                    { type: 'row', id: '' },
                    {
                        Content: [
                            [
                                {
                                    type: 'icon_link',
                                    id: 'left',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_align_left',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'justifyleft', null);
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'center',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_align_center',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'justifycenter', null);
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'right',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_align_right',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'justifyright', null);
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'justify',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'text_align_justify',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'justifyfull', null);
                                    }
                                }
                            ]
                        ]
                    }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Behavior', style: 'bold' }
                ],
                [
                    { type: 'row', id: '' },
                    {
                        Content: [
                            [
                                {
                                    type: 'icon_link',
                                    id: 'link',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'link_add',
                                    onClick: () => {
                                        var selection = $('#text_editor_iframe')[0].contentWindow.getSelection(),
                                            Selection_Pos = this.getSelectionPosition(selection, $text_el),
                                            text = $text_el.html(),
                                            Wrapping_Position = this.determinePositionOfSectionsWrappingSelection(text, Selection_Pos),
                                            selected_sections = text.substr(Wrapping_Position[0], Wrapping_Position[1] - Wrapping_Position[0]),
                                            selector = 'text_behavior_',
                                            behavior_id_begin_pos = selected_sections.indexOf(selector),
                                            behavior_id = 0;

                                        if (behavior_id_begin_pos > 0) {
                                            var behavior_id_end_pos = selected_sections.substr(behavior_id_begin_pos + selector.length).indexOf('"');
                                            if (behavior_id_end_pos > 0) {
                                                var behavior_id_string = selected_sections.substr(behavior_id_begin_pos + selector.length, behavior_id_end_pos);
                                                if (/^\d+$/.test(behavior_id_string)) {
                                                    behavior_id = parseInt(behavior_id_string, 10);
                                                }
                                            }
                                        }

                                        layer_store.dispatch({
                                            type: 'OPEN_PANEL',
                                            panel_data: {
                                                Function_Data,
                                                Action_Data: {
                                                    access: 'authorized',
                                                    panel_type: 'text_editor_behavior_panel',
                                                    frm_name: 'behavior_settings',
                                                    new_element: behavior_id ? false : true,
                                                    el_type: 'text',
                                                    el_idx: Action_Data.el_idx,
                                                    mod_idx: behavior_id
                                                }
                                            },
                                        });

                                        $('#' + (Action_Data.data_action_type === 'create' ? '' : 'text_' + Action_Data.el_idx))
                                            .off('set_link')
                                            .on('set_link', $.proxy((e, Data) => {
                                                var Attributes = {
                                                    id: 'text_behavior_' + Data.mod_idx
                                                };
                                                if (Data.Behavior && Data.Behavior.on_click && Data.Behavior.on_click !== 'none') {
                                                    if (Data.Behavior.on_click === 'link') {
                                                        Attributes.href = Data.Behavior.src;
                                                        if (Data.Behavior.on_hover === 'show_text') {
                                                            Attributes.title = Data.Behavior.tooltip;
                                                        }
                                                        Attributes.target = Data.Behavior.link_opens_in === 'new' ? '_blank' : '_self';
                                                    }
                                                }
                                                this.processTextEditorCommand(Current_Data, $text_el, 'createlink', Attributes);
                                            }, this));
                                    }
                                }
                            ],
                            [
                                {
                                    type: 'icon_link',
                                    id: 'unlink',
                                    h_align: 'left',
                                    v_align: 'center',
                                    padding_left: 0,
                                    padding_right: 0,
                                    padding_top: 0
                                },
                                {
                                    Style: [16, 16],
                                    text: '',
                                    img_name: 'link_break',
                                    onClick: () => {
                                        this.processTextEditorCommand(Current_Data, $text_el, 'unlink', null);
                                    }
                                }
                            ]
                        ]
                    }
                ],
                [
                    { type: 'text', colspan: 2 },
                    { text: 'Text Style Presets', style: 'bold' }
                ],
                [
                    { type: 'rich_dropdown', id: 'text_styles', colspan: 2 },
                    {
                        Options: [{ id: 'none', name: 'None' }]
                            .concat(Current_Data.Text_Styles)
                            .map(Text_Style => ([
                                'txt_ps_' + Text_Style.id,
                                '<p class="txt_ps_' + Text_Style.id + '" style="padding: 0px; margin: 0px;">' + ucWords(Text_Style.name) + '</p>',
                                () => {
                                    this.processTextEditorCommand(Current_Data, $text_el, 'txt_ps_' + Text_Style.id, 'formatBlock');
                                }
                            ])),
                        height: 88
                    }
                ],
                [
                    { type: 'text_editor', id: 'text', colspan: 2 },
                    {
                        value: Current_Data.Text.text,
                        max: 40000
                    }
                ]
            ], { full_height: true })
        ];
    }
}