import React, { Component, Fragment } from 'react';
import layer_store from '../api/layer_store';
import layout_store from '../api/layout_store';
import lightbox_store from '../api/lightbox_store';
import { getViewport } from '../utils/browser';

export default class Lightbox extends Component {
    constructor(props) {
        super(props);
        const { base_url } = props;
        this.unsubscribes = [];
        this.settings = {
            overlayBgColor: '#000',
            overlayOpacity: 0.8,
            containerBorderSize: 10,
            containerResizeSpeed: 400,
            imageBtnNext: base_url + 'projects/rootflex/img/lightbox/nextlabel.gif',
            imageBtnPrev: base_url + 'projects/rootflex/img/lightbox/prevlabel.gif'
        };
        this.EventListeners = [];

        const Images = lightbox_store.getState().Images;

        this.activeImageIndex = Images.map(Image => parseInt(Image.id, 10)).indexOf(parseInt(props.active_image, 10));

        this.state = {
            isLoading: true,
            Images: lightbox_store.getState().Images,
            ActiveImage: {}
        };
    }

    componentDidMount() {
        this.loadImage();
        this.EventListeners = [
            [document, 'keydown', this.keyDown.bind(this)]
        ];
        this.EventListeners.forEach(l => l[0].addEventListener(l[1], l[2], l[3]));

        this.unsubscribes = [
            layout_store.subscribe(this.handleLayoutStoreChange.bind(this)),
            lightbox_store.subscribe(this.handleStoreChange.bind(this))
        ];

        const fadeInSpeed = 400;
        const refreshRate = 20;
        let step = 0;
        const that = this;
        this.interval = setInterval(() => {
            step++;
            that.setState({
                opacity: this.settings.overlayOpacity * step * (refreshRate / fadeInSpeed)
            });
            if (step === refreshRate / fadeInSpeed) {
                clearInterval(this.interval);
            }
        }, refreshRate);
    }

    keyDown(e) {
        const keycode = e.which || e.keyCode || 0;
        if (keycode == 27) {
            this.closeLightbox();
        }
        if (keycode == 37 && this.activeImageIndex != 0) {
            this.onClickPrev();
        }
        if (keycode == 39 && this.activeImageIndex != this.state.Images.length - 1) {
            this.onClickNext();
        }
    }

    componentWillUnmount() {
        this.EventListeners.forEach(l => l[0].removeEventListener(l[1], l[2], l[3]));
        this.unsubscribes.forEach(unsubscribe => unsubscribe());

        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    handleLayoutStoreChange() {
        const { action } = layout_store.getState();

        if (action === 'toolbar_resize' || action === 'window_resize') {
            this.forceUpdate();
        }
    }

    handleStoreChange() {
        this.setState({
            Images: lightbox_store.getState().Images
        });
    }

    closeLightbox() {
        layer_store.dispatch({
            type: 'HIDE_LIGHTBOX'
        });
    }

    onClickPrev() {
        this.activeImageIndex = this.activeImageIndex - 1;
        this.loadImage();
    }

    onClickNext() {
        this.activeImageIndex = this.activeImageIndex + 1;
        this.loadImage();
    }

    loadImage() {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            });
        }

        var objImagePreloader = new Image();
        objImagePreloader.onload = () => {
            this.setState({
                isLoading: false,
                ActiveImage: this.state.Images[this.activeImageIndex],
                intWidth: objImagePreloader.width + (this.settings.containerBorderSize * 2),
                intHeight: objImagePreloader.height + (this.settings.containerBorderSize * 2)
            });
        };
        objImagePreloader.src = this.state.Images[this.activeImageIndex].href;
    }

    render() {
        const
            { Navigation_Data } = this.props,
            { base_url } = Navigation_Data,
            viewPort = getViewport();

        return (
            <Fragment>
                <div
                    id="jquery-overlay"
                    style={{
                        backgroundColor: this.settings.overlayBgColor,
                        opacity: this.settings.overlayOpacity
                    }}
                    onClick={this.closeLightbox.bind(this)}
                ></div>
                <div
                    id="jquery-lightbox"
                    style={{
                        top: viewPort.t + ((viewPort.h - this.state.intHeight - 38) / 2),
                        left: viewPort.l + ((viewPort.w - this.state.intWidth) / 2)
                    }}
                >
                    <div
                        id="lightbox-container-image-box"
                        style={{
                            width: this.state.intWidth + 'px',
                            height: this.state.intHeight + 'px'
                        }}
                    >
                        <div id="lightbox-container-image">
                            {!this.state.isLoading && <img
                                id="lightbox-image"
                                src={this.state.ActiveImage.href}
                            />}
                            <div id="lightbox-nav">
                                {this.activeImageIndex != 0 && <div
                                    id="lightbox-nav-btnPrev"
                                    //onMouseOver={this.hoverPrev.bind(this)}// Perhaps can show/hide an arrow when hovering.
                                    onClick={this.onClickPrev.bind(this)}
                                    style={{
                                        background: 'transparent url(' + base_url + 'projects/rootflex/img/lightbox/blank.gif' + ') no-repeat',
                                        cursor: 'pointer'
                                    }}
                                ></div>}
                                {this.activeImageIndex != this.state.Images.length - 1 && <div
                                    id="lightbox-nav-btnNext"
                                    //onMouseOver={this.hoverPrev.bind(this)}// Perhaps can show/hide an arrow when hovering.
                                    onClick={this.onClickNext.bind(this)}
                                    style={{
                                        background: 'transparent url(' + base_url + 'projects/rootflex/img/lightbox/blank.gif' + ') no-repeat',
                                        cursor: 'pointer'
                                    }}
                                ></div>}
                            </div>
                            {this.state.isLoading && <div
                                id="lightbox-loading"
                                onClick={this.closeLightbox.bind(this)}
                            >
                                <a href="#" id="lightbox-loading-link">
                                    <img src={base_url + 'projects/rootflex/img/lightbox/loading.gif'} />
                                </a>
                            </div>}
                        </div>
                    </div>
                    <div
                        id="lightbox-container-image-data-box"
                        style={{
                            width: this.state.intWidth + 'px'
                        }}
                    >
                        <div id="lightbox-container-image-data">
                            {!this.state.isLoading && <div id="lightbox-image-details">
                                <span id="lightbox-image-details-caption">{this.state.ActiveImage.title}</span>
                                <span id="lightbox-image-details-currentNumber">{this.activeImageIndex + 1} / {this.state.Images.length}</span>
                            </div>}
                            <div id="lightbox-secNav">
                                <a
                                    href="#"
                                    id="lightbox-secNav-btnClose"
                                    onClick={this.closeLightbox.bind(this)}
                                >
                                    <img src={base_url + 'projects/rootflex/img/lightbox/closelabel.gif'} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}